import { ReactNode, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import intl from 'react-intl-universal';
import { Col, ModalBody, ModalHeader, Row } from 'reactstrap';

import Status from 'shared/enums/Status';

import styles from './gettingStartedStatusHandler.module.scss';
import GettingStartedHandlerProps from './GettingStartedStatusHandlerProps';

const GettingStartedStatusHandler: ReactFC<GettingStartedHandlerProps> = (
  props: GettingStartedHandlerProps
) => {
  const { loading, status, silent, children } = props;

  /**
   * When silent do not show any loading action
   */
  const renderChildren =
    (!loading && status === Status.Success) || silent === true;

  /**
   * Renders correct status for getting started modal
   *
   * @returns {ReactNode} JSX snippet with component based on status
   */
  const renderStatus = (): ReactNode => {
    if (loading) {
      return (
        <span className={styles.circleLoading}>
          <i className="icon-refresh" />
        </span>
      );
    }
    if (status === Status.Error) {
      return <span className={styles.circleErr} />;
    }
    return null;
  };

  /**
   * Renders correct modal header and title based on status
   *
   * @returns {ReactNode} JSX snippet with appropriate modal header
   */
  const renderTitle = (): ReactNode => {
    if (!loading && status === Status.Error) {
      return (
        <ModalHeader className="increase-font text-center">
          {intl.get('OOPS_SOMETHING_WENT_WRONG_TITLE')}
        </ModalHeader>
      );
    }
    return (
      <ModalHeader
        style={{ borderBottom: '0px' }}
        className="increase-font text-center"
      />
    );
  };

  return (
    <BlockUi tag="div" blocking={loading}>
      {renderChildren ? (
        <>{children}</>
      ) : (
        <>
          {renderTitle()}
          <ModalBody>
            <Row>
              <Col
                xs="12"
                className="d-flex justify-content-center mt-3 mb-3 p-3"
              >
                {renderStatus()}
              </Col>
            </Row>
            <Row className="justify-content-center mt-3 pt-3 pb-3 mb-3" />
          </ModalBody>
        </>
      )}
    </BlockUi>
  );
};

export default GettingStartedStatusHandler;
