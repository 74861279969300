const ResourceKeys = {
  SettingMyOrgBillingAndPayment: 'settings.myorg.billingandpayment',
  SettingsMyOrgMyPlan: 'settings.myorg.myplan',
  SettingsMyOrgOrgName: 'settings.myorg.orgname',
  SettingsMyOrgLinkedSavingsGroups: 'settings.myorg.linkedsavingsgroups',
  SettingsMyOrgDefaultCurrency: 'settings.myorg.defaultcurrency',
  SettingsMyOrgImplementingPartners: 'settings.myorg.implementingpartners',
  SettingsMyOrgGroupStatus: 'settings.myorg.groupstatus',
  SettingsPermissionsAddNewLevel: 'settings.permissions.addnewlevel',
  SettingsPermissionsItemEdit: 'settings.permissions.item.edit',
  SettingsActivityFeed: 'settings.activityfeed',

  DashboardGettingStartedSettingUpGroupData:
    'dashboard.gettingstarted.settingupgroupdata',

  DashboardGettingStartedInviteUsers: 'dashboard.gettingstarted.inviteusers',

  SettingsUsers: 'settings.users',
  SettingsUsersInviteUsers: 'settings.users.inviteusers',
  UserProfileEdit: 'userprofile.edit',

  ProjectsUsersEdit: 'projects.users.edit',
  UserProfileDeactivate: 'userprofile.deactivate',
  DashboardGettingStartedCreateNewProject:
    'dashboard.gettingstarted.createnewproject',

  ProjectsView: 'projects.view',
  ProjectsCreateNewProject: 'projects.createnewproject',
  ProjectsProjectCodeEdit: 'projects.projectcode.edit',
  ProjectsItemEdit: 'projects.item.edit',

  ProjectsItemAddUsers: 'projects.item.addusers',
  ProjectsItemAddFacilitators: 'projects.item.addfacilitators',
  FacilitatorProfileEdit: 'facilitatorprofile.edit',

  ProjectsFacilitatorsEdit: 'projects.facilitators.edit',
  FacilitatorProfileDeactivate: 'facilitatorprofile.deactivate',
  GroupsAssignToFacilitator: 'groups.assigntofacilitator',
  GroupAssignGroupStatus: 'groups.assigngroupstatus',

  GroupsGroupStatus: 'groups.groupstatus',

  ExportCSV: 'export.csv',
  ExportPDF: 'export.pdf',

  MessagingCompose: 'messaging.compose',
  SurveysCreate: 'surveys.create',
  SurveysCollectAdditionalID: 'surveys.collectadditionalid',
};

export default ResourceKeys;
