import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

class DummyFacilitator {
  disabled?: boolean;

  image: string;

  constructor(public id: string, public name: string) {
    this.disabled = true;
    this.image = blankAvatar;
  }
}

export default DummyFacilitator;
