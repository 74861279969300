import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Col, ModalHeader, ModalBody, Row, Button } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';
import PendingStateAnim from 'shared/static/img/animations/pending-state.gif';

import styles from './forgotPasswordPending.module.scss';
import ForgotPasswordPendingProps from './ForgotPasswordPendingProps';

const ForgotPasswordPending: ReactFC<ForgotPasswordPendingProps> = (
  props: ForgotPasswordPendingProps
) => {
  const { email, status, expiryTimeString, onResendEmailClick } = props;
  return (
    <>
      <ModalHeader className="increase-font text-center">
        {intl.get('LBL_PASSWORD_RETRIEVAL_PENDING_TITLE')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col
            xs="12"
            className="d-flex justify-content-center mb-3 px-3 pb-3 pt-1"
          >
            <img src={PendingStateAnim} alt="Success" className={styles.anim} />
          </Col>
        </Row>
        <Row className="justify-content-center mt-3 mb-3">
          <Col xs="12" className="mb-3 pb-3">
            <p className="form-title w-50 text-gray text-center d-block m-auto">
              {intl.get('LBL_PASSWORD_RETRIEVAL_PENDING_DESCRIPTION', {
                email,
                expiry: expiryTimeString,
              })}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3 mb-3">
          <Col xs="auto">
            <InsLink
              to={`${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`}
              className="btn btn-primary"
              disabled={status === Status.Loading}
              disabledClass="disabled"
            >
              {intl.get('BTN_OK')}
            </InsLink>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3 mb-3">
          <Col xs="auto">
            <Button
              className="btn btn-secondary"
              tabIndex={0}
              onClick={(): void => onResendEmailClick(email)}
              disabled={status === Status.Loading}
            >
              {intl.get('BTN_FORGOT_PASSWORD_PENDING_RESEND_EMAIL')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="text-center pt-3 mt-3">
            <small className="text-muted">
              {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
              <a
                href="https://support.dreamstartlabs.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {intl.get('LBL_CA_CONTACT_SUPPORT')}
              </a>
            </small>
          </Col>
        </Row>
      </ModalBody>
    </>
  );
};

export default ForgotPasswordPending;
