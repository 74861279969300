import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import styles from '../../users-data-table/usersDataTable.module.scss';
import DummyRowInvitedProps from './DummyRowInvitedProps';

const DummyRowInvited: ReactFC<DummyRowInvitedProps> = (
  props: DummyRowInvitedProps
) => {
  const { headers, isError } = props;

  const headersList = headers[0].headers;

  return (
    <tr>
      {headersList.map((cell) => {
        if (cell.id === 'email') {
          return (
            <td
              key={cell.id}
              data-title={cell.dataTitle}
              className={cell.className}
            >
              <span className={`wrap-content left-align ${styles.wrapContent}`}>
                <span
                  className="table-content text-14-semibold"
                  style={{ overflow: 'visible' }}
                >
                  {isError
                    ? intl.get('LBL_NA')
                    : intl.get('LBL_NO_USERS_ADDED')}
                </span>
              </span>
            </td>
          );
        }

        if (cell.id === 'resendInvite') {
          return (
            <td
              key={cell.id}
              className={cell.className}
              data-title={cell.dataTitle}
            />
          );
        }

        return (
          <td
            key={cell.id}
            className={cell.className}
            data-title={cell.dataTitle}
          >
            <span className={`wrap-content ${styles.wrapContent}`}>
              <span className="table-content text-14-semibold">
                {isError ? intl.get('LBL_NA') : '—'}
              </span>
            </span>
          </td>
        );
      })}
    </tr>
  );
};

export default DummyRowInvited;
