/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { LocationDescriptorObject } from 'history';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';

import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';

import InsLinkProps from './InsLinkProps';

const InsLink: ReactFC<InsLinkProps> = (props: InsLinkProps) => {
  const {
    children,
    className,
    tabIndex,
    disabled = false,
    disabledClass = '',
    hide = false,
    to,
    ...rest
  } = props;
  const location = useLocation();
  const tabIndexCustom = disabled ? -1 : tabIndex || 0;
  const classNameCustom = disabled
    ? `${String(className)} ${disabledClass}`
    : `${String(className)}`;

  const search = getGlobalFiltersQuery(location.search);
  const { gf = '{}' } = qs.parse(search);

  let toLink: LocationDescriptorObject<unknown>;
  if (typeof to === 'string') {
    const existingSearch = qs.parse(qs.extract(to));
    const newSearch = qs.stringify({ gf, ...existingSearch });
    const pathname = to.split('?')[0];
    toLink = {
      pathname,
      search: newSearch,
    };
  } else {
    const toObj = to as LocationDescriptorObject<unknown>; // nosonar
    if (toObj.pathname) {
      const existingSearch = qs.parse(qs.extract(toObj.pathname));
      const newSearch = qs.stringify({ gf, ...existingSearch });
      const pathname = toObj.pathname?.split('?')[0];
      toLink = {
        ...toObj,
        pathname,
        search: newSearch,
      };
    } else {
      toLink = toObj;
    }
  }

  if (hide) {
    return <></>;
  }

  return (
    <Link
      {...rest}
      to={toLink}
      className={classNameCustom}
      tabIndex={tabIndexCustom}
      aria-disabled={disabled}
    >
      {children}
    </Link>
  );
};

export default InsLink;
