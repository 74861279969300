import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';

import styles from './surveysTabPane.module.scss';

const SurveysTabPane: ReactFC = () => {
  const renderSurveysTabs = (path: string): JSX.Element => (
    <ul>
      <li>
        <InsNavLink
          className={`btn btn-secondary bg-transparent text-14-semibold ${styles.tab}`}
          to={`${ModulePaths.SurveysPath}${ModulePaths.SurveysDraftPath}`}
          isActive={(): boolean => path === ModulePaths.SurveysDraftPath}
        >
          {intl.get('LBL_SURVEYS_TAB_CREATE_NEW_SURVEY')}
        </InsNavLink>
      </li>
      <li>
        <InsNavLink
          className={`btn btn-secondary text-14-semibold bg-transparent ${styles.tab}`}
          to={`${ModulePaths.SurveysPath}${ModulePaths.SurveysHistoryPath}`}
          isActive={(): boolean => path === ModulePaths.SurveysHistoryPath}
        >
          {intl.get('LBL_SURVEYS_TAB_SURVEY_HISTORY')}
        </InsNavLink>
      </li>
    </ul>
  );

  return (
    <div className={`content-container ${styles.surveysTabPane}`}>
      <Row className="justify-content-start mb-2 pb-4">
        <Col xs="auto" className="pl-0">
          <div className="insight-tab smaller">
            <Switch>
              <Route
                path={`${ModulePaths.SurveysPath}${ModulePaths.SurveysDraftPath}`}
              >
                {(): JSX.Element =>
                  renderSurveysTabs(ModulePaths.SurveysDraftPath)
                }
              </Route>
              <Route
                path={`${ModulePaths.SurveysPath}${ModulePaths.SurveysHistoryPath}`}
              >
                {(): JSX.Element =>
                  renderSurveysTabs(ModulePaths.SurveysHistoryPath)
                }
              </Route>
            </Switch>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SurveysTabPane;
