/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { components, MenuListComponentProps } from 'react-select';
import { Button, Col } from 'reactstrap';

import PermissionOption from '../PermissionOption';
import styles from './permissionMenuList.module.scss';

const PermissionMenuList: ReactFC<MenuListComponentProps<PermissionOption>> = (
  props: MenuListComponentProps<PermissionOption>
) => {
  const { MenuList } = components;
  const { selectProps } = props;
  return (
    <>
      <MenuList {...props} />
      <Col xs="12" className="pb-4">
        <div className="my-2">
          <Button
            className={`btn-sm text-left align-items-start btn-secondary border-0 pl-0 mb-0 shadow-none ${styles.noTransform}`}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={selectProps.togglePermissionsModal}
          >
            <i className="icon-plus" />
            {intl.get('BTN_SETTINGS_USERS_INVITE_USERS_CREATE_CUSTOM_LEVEL')}
          </Button>
        </div>
      </Col>
    </>
  );
};

export default PermissionMenuList;
