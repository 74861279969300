import * as intl from 'react-intl-universal';
import * as yup from 'yup';

import LoginFormValues from './LoginFormValues';

class LoginFormValidation {
  /**
   * Defines a Yup validation schema for the login form
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema<Partial<LoginFormValues>> = yup
      .object()
      .shape({
        email: yup
          .string()
          .trim()
          .email(intl.get('ERR_FORM_EMAIL_INVALID_MESSAGE'))
          .required(intl.get('ERR_FORM_EMAIL_REQUIRED_MESSAGE')),
        password: yup
          .string()
          .trim()
          .required(intl.get('ERR_FORM_PASSWORD_REQUIRED_MESSAGE')),
      });

    return validationSchema;
  }
}

export default LoginFormValidation;
