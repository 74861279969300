import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import { getColorFor } from 'helpers/ColorHelper';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';
import VerticalBar from 'shared/components/ins-charts/vertical-bar/VerticalBar';

import WidgetValueGroup from '../widget/WidgetValueGroup';

const MAX_DEPENDENT_LABEL_VALUE = 5;

const DependentsWidget: ReactFC<{ data: WidgetViewModel }> = (props) => {
  const maxItemCount = 5;
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const oneDependentData = values?.find(
    (value) => value.group === WidgetValueGroup.OneDependent
  );
  const twoDependentsData = values?.find(
    (value) => value.group === WidgetValueGroup.TwoDependents
  );
  const threeDependentsData = values?.find(
    (value) => value.group === WidgetValueGroup.ThreeDependents
  );
  const fourDependentsData = values?.find(
    (value) => value.group === WidgetValueGroup.FourDependents
  );
  const fiveOrMoreDependentsData = values?.find(
    (value) => value.group === WidgetValueGroup.FiveOrMoreDependents
  );

  const verticalBarValues = Array<InsChartValue>();

  if (
    oneDependentData &&
    twoDependentsData &&
    threeDependentsData &&
    fourDependentsData &&
    fiveOrMoreDependentsData
  ) {
    verticalBarValues.push({
      name: oneDependentData.title,
      count: oneDependentData.count,
      percentage: oneDependentData.percentage,
      group: oneDependentData.group,
    });
    verticalBarValues.push({
      name: twoDependentsData.title,
      count: twoDependentsData.count,
      percentage: twoDependentsData.percentage,
      group: twoDependentsData.group,
    });
    verticalBarValues.push({
      name: threeDependentsData.title,
      count: threeDependentsData.count,
      percentage: threeDependentsData.percentage,
      group: threeDependentsData.group,
    });
    verticalBarValues.push({
      name: fourDependentsData.title,
      count: fourDependentsData.count,
      percentage: fourDependentsData.percentage,
      group: fourDependentsData.group,
    });
    verticalBarValues.push({
      name: fiveOrMoreDependentsData.title,
      count: fiveOrMoreDependentsData.count,
      percentage: fiveOrMoreDependentsData.percentage,
      group: fiveOrMoreDependentsData.group,
    });
  }

  return (
    <div className="gender-widget">
      <div className="chart-container x-axis-label">
        <VerticalBar
          isLoading={false}
          values={verticalBarValues}
          dimension={dimensionSettings}
        />
        <div className="vertical-bar-x-axis">
          {intl.get('LBL_WID_DEPENDENTS_LEGEND')}
        </div>
      </div>
      {verticalBarValues.length === maxItemCount && (
        <div className="chart-legend">
          <div className="chart-legend-inner">
            {verticalBarValues.map((value) => (
              <div key={value.name} className="item">
                <div className="item-col color-container">
                  <span
                    className="color"
                    style={{
                      backgroundColor: getColorFor(value.group),
                    }}
                  />
                </div>
                <div className="item-col label">
                  <span className="name">
                    {`${value.name} ${intl.get('LBL_WID_DEPENDENTS_LABEL', {
                      count: Number(
                        value.name === '5+'
                          ? MAX_DEPENDENT_LABEL_VALUE
                          : value.name
                      ),
                    })}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DependentsWidget;
