/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import ProtectedRoute from 'shared/components/hoc/protected-route/ProtectedRoute';

import SurveysTabPane from './components/surveys-tab-pane/SurveysTabPane';
import SurveysDraftView from './containers/surveys-draft-view/SurveysDraftView';
import SurveysViewProps from './containers/surveys-draft-view/SurveysDraftViewProps';
import SurveysHistoryView from './containers/surveys-history-view/SurveysHistoryView';

const SurveysRoutes: ReactFC<SurveysViewProps> = (props: SurveysViewProps) => {
  const { match, location } = props;
  const path = match && match.path;

  return (
    <>
      <SurveysTabPane />
      <Switch>
        <ProtectedRoute
          exact
          resource={[
            ResourceKeys.SurveysCreate,
            ResourceKeys.SurveysCollectAdditionalID,
          ]}
          redirectTo={ModulePaths.DashboardPath}
          path={`${path === null ? '' : String(path)}${
            ModulePaths.SurveysDraftPath
          }`}
        >
          {(): JSX.Element => <SurveysDraftView {...props} />}
        </ProtectedRoute>

        <ProtectedRoute
          exact
          resource={[
            ResourceKeys.SurveysCreate,
            ResourceKeys.SurveysCollectAdditionalID,
          ]}
          redirectTo={ModulePaths.DashboardPath}
          path={`${path === null ? '' : String(path)}${
            ModulePaths.SurveysHistoryPath
          }`}
        >
          {(): JSX.Element => <SurveysHistoryView {...props} />}
        </ProtectedRoute>

        {/* No match for this sub-route; could show a 404 */}
        <Route path="*">
          <Redirect
            to={{
              pathname: `${ModulePaths.SurveysPath}${ModulePaths.SurveysDraftPath}`,
              search: getGlobalFiltersQuery(location.search),
            }}
          />
          ;
        </Route>
      </Switch>
    </>
  );
};

export default SurveysRoutes;
