import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import styles from '../projectUsersDataTable.module.scss';
import DummyRowProjectUserProps from './DummyRowProjectUserProps';

const DummyRowProjectUser: ReactFC<DummyRowProjectUserProps> = (
  props: DummyRowProjectUserProps
) => {
  const { headers, isError } = props;

  const headersList = headers[0].headers;

  return (
    <tr>
      {headersList.map((cell) => {
        if (cell.id === 'name') {
          return (
            <td
              key={cell.id}
              className={cell.className}
              data-title={cell.dataTitle}
            >
              <span className={`wrap-content left-align ${styles.wrapContent}`}>
                <span
                  className="table-content text-14-semibold"
                  style={{ overflow: 'visible' }}
                >
                  {isError
                    ? intl.get('LBL_NA')
                    : intl.get('LBL_NO_USERS_ADDED')}
                </span>
              </span>
            </td>
          );
        }
        if (cell.id === 'selection') {
          return (
            <td
              key={cell.id}
              className={cell.className}
              data-title={cell.dataTitle}
            >
              <span className={`wrap-content ${styles.wrapContent}`}>
                <span className="table-content text-14-semibold">
                  <label className="insight-checkbox disabled-checkbox">
                    <input
                      type="checkbox"
                      name="dummy-checkbox"
                      checked={false}
                      disabled
                    />
                    <i className="icon-check" />
                  </label>
                </span>
              </span>
            </td>
          );
        }
        return (
          <td
            key={cell.id}
            className={cell.className}
            data-title={cell.dataTitle}
          >
            <span className={`wrap-content ${styles.wrapContent}`}>
              <span className="table-content text-14-semibold">
                {isError ? intl.get('LBL_NA') : '—'}
              </span>
            </span>
          </td>
        );
      })}
    </tr>
  );
};

export default DummyRowProjectUser;
