import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import MessagingFilter from 'shared/enums/MessagingFilter';

import { groupAges } from '../../../../containers/messaging-view/MessagingConstants';
import GroupsFilter from '../groups-filter/GroupsFilter';
import GroupAgeFilterProps from './GroupAgeFilterProps';

const GroupAgeFilter: ReactFC<GroupAgeFilterProps> = (
  props: GroupAgeFilterProps
) => {
  const { isDisabled, selected, onChange } = props;

  const groupAgesTranslated = groupAges.map(({ value, label }) => ({
    value,
    label: intl.get(label),
  }));

  return (
    <GroupsFilter
      title={intl.get('LBL_GROUP_AGE')}
      data={groupAgesTranslated}
      onChange={(e, label: string): void =>
        onChange(e, MessagingFilter.GroupAge, label)
      }
      isDisabled={isDisabled}
      selected={selected}
    />
  );
};

export default GroupAgeFilter;
