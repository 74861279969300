/* eslint-disable no-nested-ternary */
import { useEffect, useRef, FC as ReactFC } from 'react';

import ReactTooltip from 'react-tooltip';

import { getFormattedNumber } from 'helpers/NumberFormat';

import styles from '../collectIdsHistoryDataTable.module.scss';
import CollectIdStatusGuidePopover from '../status-guide-popover/CollectIdStatusGuidePopover';
import CollectIdsHistoryHeaderCellProps from './CollectIdsHistoryHeaderCellProps';

const CollectIdsHistoryHeaderCell: ReactFC<CollectIdsHistoryHeaderCellProps> =
  ({ column, meta }: CollectIdsHistoryHeaderCellProps) => {
    const { id, dataTitle, helpText } = column;
    const statusHeaderRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [column, meta]);

    return (
      <span
        className="table-label"
        ref={id === 'activeCollectId' ? statusHeaderRef : null}
      >
        <CollectIdStatusGuidePopover
          elementRef={statusHeaderRef}
          containerRef={meta.containerRef}
          popoverPlacement="top"
          popoverBodyText="LBL_SURVEYS_CREATE_SURVEY_SELECT_RECIPIENT_TYPE_GUIDE"
          showCollectIdGuideTooltip={meta.showCollectIdGuideTooltip}
        />
        {dataTitle}
        {helpText && (
          <span className="help">
            <i
              className="icon-help"
              data-for="insTooltip"
              data-tip={helpText}
            />
          </span>
        )}
        {id === 'name' && (
          <span className={`ml-1 ${styles.countColor}`}>{`(${getFormattedNumber(
            meta.total,
            false
          )})`}</span>
        )}
        {meta.total > 1 && (
          <span
            data-cy="collect-id-chevron"
            className={
              column.isSorted
                ? column.isSortedDesc
                  ? 'icon-chevron-down'
                  : 'icon-chevron-up'
                : ''
            }
          />
        )}
      </span>
    );
  };

export default CollectIdsHistoryHeaderCell;
