import * as intl from 'react-intl-universal';

import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import CyclePhase from 'shared/enums/CyclePhase';
import Frequency from 'shared/enums/Frequency';
import timeUnit from 'shared/enums/TimeUnit';

class FinancialMetricsFilter {
  cyclePhase: SelectFieldOption;

  timeUnit: SelectFieldOption;

  frequency: SelectFieldOption;

  constructor() {
    this.frequency = {
      value: Frequency.Monthly,
      label: 'Monthly',
      isDisabled: false,
    };
    this.cyclePhase = {
      value: CyclePhase.None,
      label: intl.get(`LBL_CP_${CyclePhase.None}`),
      isDisabled: false,
    };
    this.timeUnit = {
      value: timeUnit.PreviousYear,
      label: 'Previous Year',
      isDisabled: false,
    };
  }
}

export default FinancialMetricsFilter;
