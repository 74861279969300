import Status from 'shared/enums/Status';

export enum StatusError {
  InvalidEmail = 11006,
  AlreadyCreated = 11009,
  InvalidCode = 11010,
  ExpiredCode = 11011,
  DefaultError = -10000,
}
interface VerifyAccountErrorProps {
  resendStatus: Status;
  errorCode: number | null;
  expiryTimeString: string;
  onResendEmail: () => void;
}

export default VerifyAccountErrorProps;
