import { ReactNode, useRef, FC as ReactFC } from 'react';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer } from 'react-laag';

import GlobalFilters from 'constants/GlobalFilters';
import { formatDate, getDateDiff } from 'helpers/DateFormat';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import DateRangeFilterSelectProps from './DateRangeFilterSelectProps';

const DateRangeFilterSelect: ReactFC<DateRangeFilterSelectProps> = (
  props: DateRangeFilterSelectProps
) => {
  const { appContext, dateRange, globalFilters, onClose, setDateRange } = props;

  const { dashboardSetupInProgress /* globalFilters */ } = appContext;

  const parentRef = useRef<HTMLDivElement>(null);

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
    close,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <div
        ref={layerProps.ref}
        className="layer insight-dropdown"
        style={{
          ...layerProps.style,
          left: '50%',
          transform: 'translateX(-50%)',
          top: '10px',
        }}
      >
        <Arrow
          style={arrowStyle}
          layerSide={layerSide}
          backgroundColor="#ffffff"
          borderWidth={1}
          borderColor="#dbdce1"
          roundness={0.7}
        />
        <DateRangePicker
          className="insight-date-picker"
          onChange={setDateRange}
          value={dateRange}
          calendarIcon={null}
          isOpen
          onCalendarClose={(): void => onClose(close)}
          closeCalendar
        />
      </div>
    );

  const getDateRangeLabel = (): string => {
    if (!globalFilters.fromDate && !globalFilters.toDate) {
      return intl.get('LBL_ALL');
    }
    const { fromDate, toDate } = globalFilters;
    if (fromDate && toDate) {
      const format1 = formatDate(fromDate);
      const format2 = formatDate(toDate);

      const invalidDates =
        format1 === GlobalFilters.InvalidDate ||
        format2 === GlobalFilters.InvalidDate;

      const invalidRange = invalidDates || getDateDiff(fromDate, toDate) <= 0;

      if (invalidRange) {
        return '';
      }
      return `${format1} - ${format2}`;
    }
    return '';
  };

  return (
    <ToggleLayer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={parentRef.current!} // nosonar
      closeOnOutsideClick
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => {
        const dateRangeLabel = getDateRangeLabel();
        return (
          <>
            <label>{intl.get('LBL_DATE_RANGE')}</label>
            <EllipsisTooltip
              ref={triggerRef}
              className={`toggle-btn dropdown-btn ${
                isOpen ? 'dropdown-open' : ''
              }`}
              onClick={toggle}
              disabled={dashboardSetupInProgress}
              tag="button"
              data-cy="date-gf"
              data-place="bottom"
              data-for="insTooltip"
              data-tip={dateRangeLabel}
              data-class="overflow-wrap"
            >
              <span>{dateRangeLabel}</span>
            </EllipsisTooltip>
            <div
              ref={parentRef}
              className="position-relative"
              id="date-range-parent"
            />
          </>
        );
      }}
    </ToggleLayer>
  );
};

export default DateRangeFilterSelect;
