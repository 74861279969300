import { ReactNode, useRef, useState, FC as ReactFC } from 'react';

import { FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import {
  Button,
  Col,
  Fade,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';
import Status from 'shared/enums/Status';
import successAnimation from 'shared/static/img/animations/success.gif';

import AddExistingFacilitators from './add-existing-facilitators/AddExistingFacilitators';
import styles from './addFacilitatorsModal.module.scss';
import AddFacilitatorsModalProps from './AddFacilitatorsModalProps';
import AddFacilitatorsType from './AddFacilitatorsType';
import InviteProjectFacilitators from './invite-project-facilitators/InviteProjectFacilitators';
import { InviteProjectFacilitatorsFormValues } from './invite-project-facilitators/InviteProjectFacilitatorsFormValue';

const AddFacilitatorsModal: ReactFC<AddFacilitatorsModalProps> = (
  props: AddFacilitatorsModalProps
) => {
  const {
    appContext,
    projectId,
    projectName,
    existingFacilitators,
    addExistingStatus,
    addExistingError,
    isOpen,
    createFacilitatorStatus,
    imageStatus,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    fetchOrgFacilitators,
    onAddExistingFacilitators,
    onToggle,
    onUploadImage,
    onSuccess,
    onCreateFacilitators,
    onFetchPermissionLevels,
    onFetchSupervisors,
  } = props;

  const [selectedTab, setSelectedTab] = useState<AddFacilitatorsType>(
    AddFacilitatorsType.AddExistingFacilitators
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [displayWarningPrompt, setDisplayWarningPrompt] =
    useState<boolean>(false);
  const [showSuccessState, setShowSuccessState] = useState<boolean>(false);
  const [addedCount, setAddedCount] = useState<number>(0);
  const [addedFacilitatorName, setAddedFacilitatorName] = useState<
    string | null
  >(null);

  const innerFormikRef =
    useRef<FormikProps<InviteProjectFacilitatorsFormValues> | null>(null);

  /**
   * Handles switching the tab between adding existing users and inviting new users
   *
   * @param tab The tab to be switched to
   */
  const onTabSwitch = (tab: AddFacilitatorsType): void => {
    /**
     * here InviteProjectUsers form dirty status is directly
     * checked off of a formik ref @innerFormikRef , @hasUnsavedChanges
     * is used for AddExistingUsers dirty status check; room to improve
     */
    const formikData = innerFormikRef.current;
    if (hasUnsavedChanges) {
      setDisplayWarningPrompt(true);
    } else if (formikData && formikData.dirty) {
      /**
       * here we explicitly compare initial facilitators array and most recent
       * facilitators array specifically without {id} property; because id is a
       * dynamic property and not a piece of actual form data it will also be counted
       *  as a change making dirty check not enough to determine this; this issue
       * occurs specially when a users add multiple form items and removes the top
       * form item; leaving only one form item, making it practically identical top
       * initial values; except for the changed {id} property which the user knows
       * nothing about
       */
      // prettier-ignore
      const initial = formikData.initialValues?.facilitators?.map(({ id, ...rest }) => rest) ?? [];
      // prettier-ignore
      const current = formikData.values?.facilitators?.map(({ id, ...rest }) => rest) ?? [];

      if (isEqual(initial, current)) {
        setSelectedTab(tab);
      } else {
        setDisplayWarningPrompt(true);
      }
    } else {
      setSelectedTab(tab);
    }
  };

  /**
   * Handles switching the tab when user confirms that existing work can be reset
   */
  const onConfirmTabSwitch = (): void => {
    setHasUnsavedChanges(false);
    setDisplayWarningPrompt(false);
    if (selectedTab === AddFacilitatorsType.AddExistingFacilitators) {
      setSelectedTab(AddFacilitatorsType.InviteNewFacilitators);
    } else {
      setSelectedTab(AddFacilitatorsType.AddExistingFacilitators);
    }
  };

  /**
   * Handles closing the warning prompt
   */
  const onCancelPrompt = (): void => {
    setDisplayWarningPrompt(false);
  };

  /**
   * reset component state on exit
   */
  const handleClosed = (): void => {
    setSelectedTab(AddFacilitatorsType.AddExistingFacilitators);
    setDisplayWarningPrompt(false);
    setHasUnsavedChanges(false);
    setShowSuccessState(false);
    setAddedCount(0);
    setAddedFacilitatorName(null);
  };

  /**
   * Toggle success state
   */
  const onShowSuccessState = (
    count: number,
    facilitatorName?: string
  ): void => {
    setAddedCount(count);
    setAddedFacilitatorName(facilitatorName ?? null);
    setShowSuccessState(true);
  };

  /**
   * Renders add/invite facilitators success state
   *
   * @returns {JSX.Element}
   */
  const renderSuccessState = (): JSX.Element => (
    <>
      <ModalHeader className="increase-font text-center">
        {addedCount === 1 ? (
          <>
            {intl.get('LBL_PROJECTS_FACILITATORS_SINGLE_ADD_INVITE_CONGRATS')}
            <EllipsisTooltip
              tag="span"
              data-place="bottom"
              data-for="insTooltip"
              data-class="overflow-wrap bring-it-up"
              data-tip={addedFacilitatorName ?? ''}
              className="truncate shorten"
            >
              {addedFacilitatorName ?? ''}
            </EllipsisTooltip>
            {intl.get('LBL_PROJECTS_FACILITATORS_SINGLE_ADD_INVITE_ADDED')}
          </>
        ) : (
          intl.get('LBL_PROJECTS_FACILITATORS_MULTI_ADD_INVITE_SUCCESS', {
            facilitatorCount: addedCount,
          })
        )}
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-class="overflow-wrap bring-it-up"
          data-tip={projectName}
          className="truncate shorten"
        >
          {`${projectName}!`}
        </EllipsisTooltip>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col
            xs="12"
            className="d-flex justify-content-center mb-3 px-3 pb-3 pt-1"
          >
            <img src={successAnimation} alt="Success" className={styles.anim} />
          </Col>
        </Row>
        <Row className="justify-content-center mt-3 mb-3">
          <Col xs="auto">
            <Button color="primary" tabIndex={0} onClick={onToggle}>
              {intl.get('BTN_PROJECTS_FACILITATORS_OKAY')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </>
  );

  /**
   * Renders a warning prompt when the user attempts to switch the tab with existing changes
   *
   * @returns {ReactNode} JSX snippet for the warning prompt
   */
  const renderWarningPrompt = (): ReactNode => (
    <Fade
      mountOnEnter
      unmountOnExit
      in={displayWarningPrompt}
      className={styles.overlay}
    >
      <TrapFocus autoFocus>
        {({ firstFocus, lastFocus }): JSX.Element => (
          <div className={styles.confirmation}>
            <div className="text-14-medium text-center">
              {intl.get('LBL_PROJECTS_FACILITATORS_ADD_UNSAVED_WARNING')}
            </div>
            <Button
              innerRef={firstFocus}
              className="btn btn-warning btn-sm"
              type="button"
              onClick={onConfirmTabSwitch}
            >
              {intl.get('BTN_PROJECT_FACILITATORS_WARNING_CONTINUE')}
            </Button>
            <Button
              innerRef={lastFocus}
              className="btn btn-light btn-sm"
              onClick={onCancelPrompt}
            >
              {intl.get('BTN_CANCEL')}
            </Button>
          </div>
        )}
      </TrapFocus>
    </Fade>
  );

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={onToggle}
      backdrop="static"
      centered
      autoFocus={false}
      trapFocus
      onClosed={handleClosed}
      keyboard={false}
      id="warningPromptTarget"
    >
      {showSuccessState ? (
        renderSuccessState()
      ) : (
        <>
          <BlockUi
            tag="div"
            blocking={
              addExistingStatus === Status.Loading ||
              createFacilitatorStatus === Status.Loading ||
              imageStatus === Status.Loading
            }
          >
            <ModalHeader className="increase-font truncate text-center border-0 pb-0">
              {projectName && (
                <>
                  {intl.get('LBL_PROJECT_FACILITATORS_ADD_MODAL_TITLE')}
                  <EllipsisTooltip
                    tag="span"
                    data-place="bottom"
                    data-for="insTooltip"
                    data-class="overflow-wrap bring-it-up"
                    data-tip={projectName}
                    className="truncate"
                  >
                    {`'${projectName}'`}
                  </EllipsisTooltip>
                </>
              )}
            </ModalHeader>
            <ModalBody>
              <Row className="insight-tab">
                <ul>
                  <li>
                    <Button
                      className="text-18-semibold"
                      onClick={(): void =>
                        onTabSwitch(AddFacilitatorsType.AddExistingFacilitators)
                      }
                      active={
                        selectedTab ===
                        AddFacilitatorsType.AddExistingFacilitators
                      }
                    >
                      {intl.get(
                        'LBL_PROJECT_FACILITATORS_ADD_EXISTING_TAB_TITLE'
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="text-18-semibold"
                      onClick={(): void =>
                        onTabSwitch(AddFacilitatorsType.InviteNewFacilitators)
                      }
                      active={
                        selectedTab ===
                        AddFacilitatorsType.InviteNewFacilitators
                      }
                    >
                      {intl.get('LBL_PROJECT_FACILITATORS_INVITE_NEW_TITLE')}
                    </Button>
                  </li>
                </ul>
              </Row>
              <TabContent activeTab={selectedTab}>
                <TabPane
                  className={styles.wrapper}
                  tabId={AddFacilitatorsType.AddExistingFacilitators}
                >
                  {selectedTab ===
                    AddFacilitatorsType.AddExistingFacilitators && (
                    <AddExistingFacilitators
                      existingFacilitators={existingFacilitators}
                      status={addExistingStatus}
                      error={addExistingError}
                      warningPromptOpen={displayWarningPrompt}
                      fetchOrgFacilitators={fetchOrgFacilitators}
                      onAddExistingFacilitators={onAddExistingFacilitators}
                      onAddFacilitatorsSuccess={onSuccess}
                      hasUnsavedChanges={hasUnsavedChanges}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      onShowSuccessState={onShowSuccessState}
                      onToggle={onToggle}
                    />
                  )}
                </TabPane>
                <TabPane
                  className={styles.wrapper}
                  tabId={AddFacilitatorsType.InviteNewFacilitators}
                >
                  {selectedTab ===
                    AddFacilitatorsType.InviteNewFacilitators && (
                    <InviteProjectFacilitators
                      innerFormikRef={innerFormikRef}
                      isOpen={isOpen}
                      projectId={projectId}
                      onToggle={onToggle}
                      onCreateFacilitators={onCreateFacilitators}
                      supervisors={supervisors}
                      supervisorsFiltered={supervisorsFiltered}
                      supervisorsStatus={supervisorsStatus}
                      onUploadImage={onUploadImage}
                      onFetchPermissionLevels={onFetchPermissionLevels}
                      onFetchSupervisors={onFetchSupervisors}
                      appContext={appContext}
                      onShowSuccessState={onShowSuccessState}
                      onAddFacilitatorsSuccess={onSuccess}
                    />
                  )}
                </TabPane>
              </TabContent>
            </ModalBody>
          </BlockUi>
          {renderWarningPrompt()}
        </>
      )}
    </Modal>
  );
};

export default AddFacilitatorsModal;
