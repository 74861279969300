/* eslint-disable no-nested-ternary */
import { useEffect, FC as ReactFC } from 'react';

import ReactTooltip from 'react-tooltip';

import UsersHeaderCellProps from './UsersHeaderCellProps';

const UsersHeaderCell: ReactFC<UsersHeaderCellProps> = ({
  column,
  meta,
}: UsersHeaderCellProps) => {
  const { dataTitle, helpText } = column;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [column, meta]);

  return (
    <span className="table-label">
      {dataTitle}
      {helpText && (
        <span className="help">
          <i className="icon-help" data-for="insTooltip" data-tip={helpText} />
        </span>
      )}
      {meta.total > 1 && (
        <span
          className={
            column.isSorted
              ? column.isSortedDesc
                ? 'icon-chevron-down'
                : 'icon-chevron-up'
              : ''
          }
        />
      )}
    </span>
  );
};

export default UsersHeaderCell;
