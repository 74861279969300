import { CSSProperties, useEffect, useRef, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';

import CustomBarItemProps from './CustomBarItemProps';

const CustomBarItem: ReactFC<CustomBarItemProps> = (
  props: CustomBarItemProps
) => {
  const {
    data,
    x,
    y,
    width,
    height,
    borderRadius,
    color,
    borderWidth,
    borderColor,
    label,
    shouldRenderLabel,
    labelColor,
    showTooltip,
    hideTooltip,
    onClick,
    onMouseEnter,
    onMouseLeave,
    tooltip,
    theme,
  } = props;

  const rectRef = useRef<SVGRectElement>(null);
  const handleTooltip = (e: React.MouseEvent): void => showTooltip(tooltip, e);

  const handleMouseEnter = (e: React.MouseEvent): void => {
    onMouseEnter(data, e);
    showTooltip(tooltip, e);
    if (rectRef.current) ReactTooltip.show(rectRef.current);
  };

  const handleMouseLeave = (e: React.MouseEvent): void => {
    onMouseLeave(data, e);
    hideTooltip();
    if (rectRef.current) ReactTooltip.hide(rectRef.current);
  };

  const tipElement = `<div style=${String(
    theme.tooltip?.container
  )} class="sidebar-tooltip">
     <div style=${String(theme.tooltip?.basic)}>${intl.get(
    `LBL_${data.id}_TMP`.toUpperCase()
  )}:&nbsp;
      <span style="color:${color}">${data.value}%</span>
      </div> 
    </div>`;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  const labelStyle: CSSProperties = {
    ...(theme?.labels?.text ?? {}),
    pointerEvents: 'none',
    fill: labelColor,
  };

  const handleClick = (event): void => {
    onClick(data, event);
  };

  return (
    <>
      <g transform={`translate(${x}, ${y})`}>
        <rect
          ref={rectRef}
          data-class="nivo-tooltip"
          data-for="svgTooltipLeftNav"
          data-tip={tipElement}
          data-place="right"
          width={width}
          height={height}
          rx={borderRadius}
          ry={borderRadius}
          fill={data.fill ? data.fill : color}
          strokeWidth={borderWidth}
          stroke={borderColor}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleTooltip}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        />
        {shouldRenderLabel && (
          <text
            x={width / 2}
            y={height / 2}
            textAnchor="middle"
            dominantBaseline="central"
            style={labelStyle}
          >
            {label}
          </text>
        )}
      </g>
    </>
  );
};

export default CustomBarItem;
