import { FC as ReactFC } from 'react';

import noop from 'lodash/noop';
import * as intl from 'react-intl-universal';

import styles from '../collectIdsHistoryDataTable.module.scss';
import DummyRowProps from './DummyRowProps';

const DummyRow: ReactFC<DummyRowProps> = (props: DummyRowProps) => {
  const { headers } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const headersList = headers[0].headers; // nosonar

  /**
   * Renders action buttons
   *
   * @param row row props from react-table
   * @returns JSX.ELement action buttons
   */
  const renderActions = (): JSX.Element => (
    <div className={`${styles.actions} justify-content-between`}>
      <button className="ml-auto mr-3 justify-content-start" disabled>
        <i className="icon-export" />
        {intl.get('BTN_MESSAGING_HISTORY_EXPORT')}
      </button>
      <a
        target="_blank"
        className={`btn btn-secondary ${styles.disabledLink} justify-content-end`}
        rel="noopener noreferrer"
        href={`${String(process.env.REACT_APP_KOBO_URL)}`}
      >
        {intl.get('BTN_SURVEYS_SURVEY_HISTORY_VIEW_MORE')}
      </a>
    </div>
  );

  /**
   * Renders the status toggle
   *
   * @returns {JSX.Element} JSX snippet containing the status toggle
   */
  const renderStatus = (): JSX.Element => (
    <label className={`${styles.switch} ${styles.disable}`}>
      <input type="checkbox" checked={false} onChange={noop} />
      <span className={`${styles.slider} ${styles.disable}`}>
        {intl.get('LBL_SURVEYS_SURVEY_HISTORY_COLLECT_ID_STATUS_OFF')}
      </span>
      <span className={styles.track} />
    </label>
  );

  /**
   * Renders the dummy row content conditionally
   *
   * @returns {JSX.Element} JSX snippet containing the dummy row
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderDummyRowContent = (cell: any): JSX.Element => {
    if (cell.id === 'actions') {
      return renderActions();
    }
    if (cell.id === 'activeCollectId') {
      return renderStatus();
    }
    return <>{intl.get('LBL_NA')}</>;
  };

  return (
    <tr>
      {headersList.map((cell) => (
        <td
          key={cell.id}
          className={cell.className}
          data-title={cell.dataTitle}
        >
          <span
            className={`table-content text-14-semibold extend-length ${styles.wrapContent}`}
          >
            {renderDummyRowContent(cell)}
          </span>
        </td>
      ))}
    </tr>
  );
};

export default DummyRow;
