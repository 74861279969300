import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import GroupAge from 'shared/enums/GroupAge';
import MemberAge from 'shared/enums/MemberAge';
import MemberEducation from 'shared/enums/MemberEducation';
import MemberGender from 'shared/enums/MemberGender';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveysViewpoint from 'shared/enums/SurveysViewPoint';
import SurveyType from 'shared/enums/SurveyType';

const surveyTypes: Array<SelectFieldOption> = [
  {
    value: SurveyType.Survey,
    label: 'LBL_SURVEYS_SURVEY_TYPE_SURVEY',
  },
  {
    value: SurveyType.CollectId,
    label: 'LBL_SURVEYS_SURVEY_TYPE_COLLECT_IDS',
  },
];

const recipientTypes: Array<SelectFieldOption> = [
  {
    value: SurveyRecipientType.Groups,
    label: 'LBL_SURVEYS_RECIPIENT_TYPE_GROUPS',
  },
  {
    value: SurveyRecipientType.Members,
    label: 'LBL_SURVEYS_RECIPIENT_TYPE_MEMBERS',
  },
];

const allMembers: Array<SelectFieldOption> = [
  {
    value: 'ALL_MEMBERS',
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_ALL_MEMBERS',
  },
];

const memberGenders: Array<SelectFieldOption> = [
  {
    value: MemberGender.Women,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_GENDER_WOMEN',
  },
  {
    value: MemberGender.Men,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_GENDER_MEN',
  },
];

const memberAges: Array<SelectFieldOption> = [
  {
    value: MemberAge.ThirteenToSeventeen,
    label:
      'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_AGE_THIRTEEN_TO_SEVENTEEN',
  },
  {
    value: MemberAge.EighteenToThirtyFour,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_AGE_EIGHTEEN_THIRTY_FOUR',
  },
  {
    value: MemberAge.ThirtyFiveToFortyFour,
    label:
      'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_AGE_THIRTY_FIVE_FORTY_FOUR',
  },
  {
    value: MemberAge.OverFortyFive,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_AGE_OVER_FORTY_FIVE',
  },
];

const memberEducation: Array<SelectFieldOption> = [
  {
    value: MemberEducation.NoFormalEducation,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_EDUCATION_NONE',
  },
  {
    value: MemberEducation.PrimarySchool,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_EDUCATION_PRIMARY',
  },
  {
    value: MemberEducation.SecondarySchool,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_EDUCATION_SECONDARY',
  },
  {
    value: MemberEducation.College,
    label: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_EDUCATION_COLLEGE',
  },
];

const groupAges = [
  {
    value: GroupAge.One,
    label: 'LBL_GF_FIRST_CYCLE',
  },
  {
    value: GroupAge.Two,
    label: 'LBL_GF_SECOND_CYCLE',
  },
  {
    value: GroupAge.ThreeOrMore,
    label: 'LBL_GF_THREE_OR_MORE_CYCLE',
  },
];

const MappedViewpoint = new Map<string, string>([
  [SurveyRecipientType.Groups, SurveysViewpoint.OPENING_SUMMARY],
  [SurveyRecipientType.Members, SurveysViewpoint.MEMBER_SAVING],
]);

export {
  surveyTypes,
  recipientTypes,
  allMembers,
  memberGenders,
  memberAges,
  memberEducation,
  groupAges,
  MappedViewpoint,
};
