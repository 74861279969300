import { useEffect, useRef, FC as ReactFC } from 'react';

import { ResizeObserver } from '@juggle/resize-observer';

import PopperResizeContentProps from './PopperResizeContentProps';

/**
 * Resize Observer higher order component for @reactstrap Popover (@popper-js)
 * which will run a given call back on resize of a given component
 * @param props PopperResizeContentProps
 */
const PopperResizeContent: ReactFC<PopperResizeContentProps> = (
  props: PopperResizeContentProps
) => {
  const { observeElement, onResize, children } = props;
  const current = observeElement && observeElement.current;
  const observer = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const element = observeElement;
    if (observer && observer.current && current) {
      observer.current.unobserve(current);
    }
    observer.current = new ResizeObserver(onResize);

    if (element && element.current && observer.current) {
      observer.current.observe(element.current);
    }
    return (): void => {
      if (element && element.current && observer.current) {
        observer.current.unobserve(element.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, observeElement]);

  return <>{children}</>;
};

export default PopperResizeContent;
