/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import DashboardView from 'modules/private/dashboard/containers/dashboard-view/DashboardView';

import DashboardViewProps, {
  ModalType,
} from './containers/dashboard-view/DashboardViewProps';

const DashboardRoutes: ReactFC<DashboardViewProps> = (
  props: DashboardViewProps
) => {
  const { match, location } = props;
  const path = match && match.path;

  return (
    <Switch>
      <Route exact path={`${path}${ModulePaths.DashboardGettingStartedPath}`}>
        {(): JSX.Element => (
          <DashboardView {...props} modal={ModalType.GettingStarted} />
        )}
      </Route>

      <Route exact path={`${path}/`}>
        <DashboardView {...props} modal={ModalType.None} />
      </Route>

      <Route path="*">
        <Redirect
          to={{
            pathname: ModulePaths.DashboardPath,
            search: getGlobalFiltersQuery(location.search),
          }}
        />
        ;
      </Route>
    </Switch>
  );
};

export default DashboardRoutes;
