import { useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Col, Popover, PopoverBody, Row } from 'reactstrap';

import PopperResizeContent from 'shared/components/hoc/popper-resize-content/PopperResizeContent';
import clearIcon from 'shared/static/img/ic_x.svg';

import DraftedSurveyGuidePopoverProps from './DraftedSurveyGuidePopoverProps';

const DraftedSurveyGuidePopover: ReactFC<DraftedSurveyGuidePopoverProps> = ({
  elementRef,
  popoverPlacement,
  popoverBodyText,
  popoverClass,
}) => {
  const [showPopover, setShowPopover] = useState(true);
  return (
    <Popover
      isOpen={showPopover}
      placement={popoverPlacement}
      container={elementRef}
      hideArrow
      modifiers={{
        preventOverflow: { enabled: false },
        flip: { enabled: false },
      }}
      popperClassName={`survey-popper ${String(popoverClass)}`}
      target={elementRef}
      delay={{ show: 500, hide: 100 }}
    >
      {({ scheduleUpdate }): JSX.Element => (
        <PopperResizeContent
          observeElement={elementRef}
          onResize={scheduleUpdate}
        >
          <PopoverBody>
            <div>
              <div className="close-container">
                <button
                  className="insight-close-button enlarge-icon"
                  type="button"
                  onClick={(): void => {
                    setShowPopover(false);
                  }}
                >
                  <img src={clearIcon} alt="Close" />
                </button>
              </div>
              <Row>
                <Col className="text-14-medium text-gray text-center py-2 px-0">
                  {intl.getHTML(`${popoverBodyText}`)}
                </Col>
              </Row>
            </div>
          </PopoverBody>
        </PopperResizeContent>
      )}
    </Popover>
  );
};

export default DraftedSurveyGuidePopover;
