import { useEffect, FC as ReactFC } from 'react';

import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import usePrevious from 'shared/hooks/use-previous/UsePrevious';

import InviteProjectFacilitatorsFormValue from '../InviteProjectFacilitatorsFormValue';
import InviteFacilitatorsFormItem from './invite-facilitators-form-item/InviteFacilitatorsFormItem';
import styles from './inviteFacilitatorsFieldArray.module.scss';
import InviteFacilitatorsFieldArrayProps from './InviteFacilitatorsFieldArrayProps';

const InviteFacilitatorsFieldArray: ReactFC<InviteFacilitatorsFieldArrayProps> =
  (props: InviteFacilitatorsFieldArrayProps) => {
    const {
      errors,
      fieldArrayProps,
      onAddNewItem,
      setCommonError,
      isSubmitting,
      validationSchema,
      canInviteFacilitators,
      supervisors,
      supervisorsFiltered,
      supervisorsStatus,
    } = props;

    const { form, name, push, handleRemove } = fieldArrayProps;

    const { length } = form.values[name];
    const isAddDisabled = length >= Constraints.MaxFormItemsInviteUsers;

    const prevSubmitting = usePrevious(isSubmitting);

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [isAddDisabled]);

    useEffect(() => {
      if (isSubmitting !== prevSubmitting && isSubmitting === false) {
        if (!isEmpty(errors)) {
          setCommonError(
            intl.get('ERR_TOAST_INVITE_USER_FORM_VALIDATION_ERRORS')
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting, prevSubmitting]);

    /**
     * Sets date-picker value to @formik
     *
     * @param fieldName Name of the @formik field
     * @param value Selected date
     */
    const setDatePickerValue = (fieldName: string, value: Date): void => {
      form.setFieldValue(fieldName, value ? value.toString() : '');
    };

    /**
     * Sets field value to @formik
     *
     * @param fieldName Name of the @formik field
     * @param value Selected image file
     * @param shouldValidate If true, the field is validated when the value is set
     */
    const setFieldValue = (
      fieldName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean
    ): void => {
      form.setFieldValue(fieldName, value, shouldValidate);
    };

    /**
     * Curried function to set @formik field touched
     *
     * @param fieldName name of the @formik field
     */
    const setFieldTouched = (
      fieldName: string,
      touched: boolean,
      shouldValidate?: boolean
    ): void => form.setFieldTouched(fieldName, touched, shouldValidate);

    /**
     * Adds new form item
     *
     * @param addCallback Callback function triggered when a new form item is added
     */
    const handleAddItem = (addCallback) => (): void => {
      const newFacilitator = onAddNewItem();
      addCallback(newFacilitator);
    };

    const { setFieldError } = form;

    return (
      <>
        {form.values[name].map(
          (_item: InviteProjectFacilitatorsFormValue, index: number) => (
            <InviteFacilitatorsFormItem
              key={_item.id}
              index={index}
              values={_item}
              length={length}
              status={form.status}
              handleRemove={handleRemove}
              validationSchema={validationSchema}
              setFieldTouched={setFieldTouched}
              setDatePickerValue={setDatePickerValue}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              supervisors={supervisors}
              supervisorsFiltered={supervisorsFiltered}
              supervisorsStatus={supervisorsStatus}
            />
          )
        )}
        {canInviteFacilitators && (
          <span
            className={styles.buttonWrap}
            data-for="insTooltip"
            data-class="error bring-it-up"
            data-tip={
              isAddDisabled
                ? intl.get('ERR_PROJECTS_FACILITATORS_ONLY_X_FACILITATORS', {
                    number: Constraints.MaxFormItemsInviteUsers,
                  })
                : ''
            }
          >
            <Button
              disabled={isAddDisabled}
              type="button"
              data-tip-disable={false}
              className="btn btn-secondary btn-sm"
              onClick={handleAddItem(push)}
            >
              <i className="icon-plus" />
              {intl.get('BTN_PROJECT_FACILITATORS_ADD_ANOTHER_FACILITATOR')}
            </Button>
          </span>
        )}
      </>
    );
  };
export default InviteFacilitatorsFieldArray;
