const barChartEmptyDummyData = {
  data: [
    {
      key: 0,
      value: 0,
    },
    {
      key: 5000,
      value: 0,
    },
    {
      key: 10000,
      value: 0,
    },
    {
      key: 15000,
      value: 0,
    },
    {
      key: 20000,
      value: 0,
    },
    {
      key: 25000,
      value: 0,
    },
    {
      key: 30000,
      value: 0,
    },
  ],
  meta: {
    minX: 0,
    minY: 0,
    maxY: 600000,
    maxX: 35000,
    quartiles: [],
    yAxisTickValues: [0, 100000, 200000, 300000, 400000, 500000, 600000],
    yAxisGridValues: [0, 100000, 200000, 300000, 400000, 500000, 600000],
    xAxisGridValues: [0, 5000, 10000, 15000, 20000, 25000, 30000],
  },
};

export default barChartEmptyDummyData;
