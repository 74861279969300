/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { createElement, ReactNode } from 'react';

import { Location } from 'history';
import { Redirect, Route } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import AuthStorageService from 'services/storage-services/AuthStorageService';

import PublicRouteProps from './PublicRouteProps';

const PublicRoute = (props: PublicRouteProps): JSX.Element => {
  const { component, children, render, ...rest } = props;

  /**
   * Render the children component
   *
   * @param childProps Route props
   * @returns {ReactNode} JSX snippet containing the child components
   */
  const renderChildren = (childProps): ReactNode =>
    component
      ? createElement(component, childProps)
      : children && typeof children === 'function'
      ? children(childProps)
      : render
      ? render(childProps)
      : null;

  /**
   * Render the redirect component
   *
   * @param from Previous location
   * @returns {ReactNode} JSX snippet containing the redirect component
   */
  const renderRedirect = (from: Location): ReactNode => (
    <Redirect
      to={{
        pathname: `${ModulePaths.DashboardPath}`,
        state: { from },
      }}
    />
  );
  /**
   * Render the redirect component
   *
   * @param routeProps Route props
   * @returns {ReactNode} JSX snippet containing the redirect component or child component
   */
  const renderReactNode = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    routeProps: any
  ): ReactNode => {
    if (AuthStorageService.IsLoggedIn()) {
      // Redirect to dashboard if logged in
      return renderRedirect(routeProps.location);
    }

    // Return the accessing component if it can be accessed
    return renderChildren(routeProps);
  };

  return (
    <Route
      {...rest}
      render={(routeProps): ReactNode => renderReactNode(routeProps)}
    />
  );
};

export default PublicRoute;
