enum WidgetType {
  Age = 'AGE',
  AverageSavings = 'AVERAGE_SAVINGS_PER_MEMBER',
  CumulativeSavings = 'CUMULATIVE_SAVINGS',
  Education = 'EDUCATION',
  Gender = 'GENDER',
  PersonalGoalAchievement = 'PERSONAL_GOAL_ACHIEVEMENT',
  Groups = 'GROUPS_FORMED',
  LoanFund = 'LOAN_FUND_UTILIZATION',
  PersonalGoalType = 'PERSONAL_GOAL_TYPES',
  LoanType = 'LOAN_TYPES',
  SavingsLeaderBoard = 'SAVINGS_LEADER_BOARD',
  DreamStartLeaderBoard = 'DREAM_STAR_LEADER_BOARD',
  Dependents = 'DEPENDENTS',
  MemberChildren = 'MEMBER_CARING',
  AdditionalFunds = 'ADDITIONAL_FUND',
  Placeholder = 'PLACEHOLDER', // Used internally in frontend.
}

export default WidgetType;
