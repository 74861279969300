/* eslint-disable react/jsx-no-bind */
import { useFormikContext, useField } from 'formik';
import Select from 'react-select';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const SelectField = ({ options, ...props }) => {
  const { name, onChange } = props;

  const [field, meta] = useField(name);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { setFieldValue, setFieldTouched } = useFormikContext();

  /**
   * Handle option select change event
   *
   * @param selection Selected options
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleOptionChange(selection: any): void {
    setFieldValue(props.name, selection);

    // the calling component call pass a custom handler.
    if (onChange) {
      onChange(setFieldValue, selection);
    }
  }

  /**
   * Handle field blur
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function updateBlur() {
    setFieldTouched(props.name, true);
  }

  return (
    <>
      <Select
        options={options}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onBlur={updateBlur}
        onChange={handleOptionChange}
        className="insight-from-select-container"
        classNamePrefix="insight-from-select"
      />
      {meta.error && <span className="custom-input-error">{meta.error}</span>}
    </>
  );
};

export default SelectField;
