/* eslint-disable react/jsx-props-no-spreading */
import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
  FC as ReactFC,
} from 'react';

import BlockUi from 'react-block-ui';
import { Arrow, RenderLayerProps, ToggleLayer } from 'react-laag';
import PerfectScrollBar from 'react-perfect-scrollbar';
import ReactTooltip from 'react-tooltip';
import { Alert, Form } from 'reactstrap';

import Status from 'shared/enums/Status';

import AssignGroupStatusMenuProps from './AssignGroupStatusMenuProps';
import GroupStatusMenuItem from './group-status-menu-item/GroupStatusMenuItem';

const AssignGroupStatusMenu: ReactFC<AssignGroupStatusMenuProps> = (
  props: AssignGroupStatusMenuProps
) => {
  const {
    groupStatusOpen,
    assignGroupsStatusStatus,
    assignGroupsStatusError,
    groupStatusData,
    hideBackdrop,
    getGroupStatuses,
    onCloseGroupStatus,
    onAssignGroupsStatus,
    setAssignGroupsStatusStatus,
    children,
  } = props;

  const {
    placement = {},
    tooltipPlacement = {},
    currentGroupStatus = null,
  } = props;

  const [selectedStatus, setSelectedStatus] = useState<string | null>(
    currentGroupStatus
  );

  useEffect(() => {
    if (groupStatusOpen === true) {
      getGroupStatuses();
      // fetchGroupStatuses();
    } else {
      setAssignGroupsStatusStatus(Status.Idle);
    }
    setSelectedStatus(currentGroupStatus);
    ReactTooltip.rebuild();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupStatusOpen]);

  /**
   * Update local selectedGroupStatus from user changes
   *
   * @param event ChangeEvent input
   */
  const handleUpdateGroupStatus = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    onAssignGroupsStatus(event.target.value);
    setSelectedStatus(event.target.value);
  };

  const groupStatusesEmpty = Object.values(groupStatusData.data).length <= 0;

  const groupStatusesModded =
    groupStatusData.status !== Status.Loading && groupStatusesEmpty
      ? []
      : Object.values(groupStatusData.data);

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <>
        <div
          ref={layerProps.ref}
          style={{ ...layerProps.style }}
          className="layer insight-dropdown"
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            backgroundColor="#ffffff"
            borderWidth={1}
            borderColor="#dbdce1"
            roundness={0.7}
          />
          {groupStatusData.status !== Status.Error && (
            <BlockUi
              blocking={
                groupStatusData.status === Status.Loading ||
                assignGroupsStatusStatus === Status.Loading
              }
            >
              <PerfectScrollBar style={{ maxHeight: '250px' }}>
                <Form>
                  {groupStatusesModded.map((groupStatus) => (
                    <GroupStatusMenuItem
                      data={groupStatus}
                      key={groupStatus.id}
                      selected={selectedStatus}
                      placement={tooltipPlacement}
                      onUpdateGroupStatus={handleUpdateGroupStatus}
                    />
                  ))}
                </Form>
              </PerfectScrollBar>
            </BlockUi>
          )}
          {(groupStatusData.error || assignGroupsStatusError) && (
            <Alert
              isOpen={
                assignGroupsStatusStatus === Status.Error ||
                groupStatusData.status === Status.Error
              }
              tag="div"
              fade
              className="alert alert-danger mt-2"
              role="alert"
            >
              {groupStatusData.error || assignGroupsStatusError}
            </Alert>
          )}
        </div>
        {hideBackdrop ? null : <div className="backdrop" />}
      </>
    );

  return (
    <>
      <ToggleLayer
        isOpen={groupStatusOpen}
        closeOnOutsideClick
        onOutsideClick={onCloseGroupStatus}
        placement={{
          anchor: 'TOP_CENTER',
          possibleAnchors: ['TOP_CENTER'],
          triggerOffset: 30,
          ...placement,
        }}
        renderLayer={renderLayer}
      >
        {children}
      </ToggleLayer>
    </>
  );
};

export default AssignGroupStatusMenu;
