import * as intl from 'react-intl-universal';

/**
 * This function needs to be called inside a react component,
 * possibly in a life-cycle method ie: ComponentDidMount or mount
 * effect in a functional component, intl.get would not work if not.
 *
 * @param title Optional custom title for page
 */
function setPageTitle(title?: string): void {
  const base = intl.get('PAGE_TITLE_BASE') || 'DreamSave - Insights';
  document.title = title ? `${title} | ${base}` : `${base}`;
}

export default setPageTitle;
