/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { useEffect, FC as ReactFC } from 'react';

import * as d3 from 'd3';
import ReactTooltip from 'react-tooltip';

import { getColorFor } from 'helpers/ColorHelper';
import { getFormattedNumber } from 'helpers/NumberFormat';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

import VerticalBarProps from './VerticalBarProps';
import VerticalBarValue from './VerticalBarValue';

const VerticalBar: ReactFC<VerticalBarProps> = (props) => {
  const { values, dimension } = props;
  const { ref, computedDimension } = useDimension(dimension);
  const { width, height } = computedDimension;

  const xAxisTextHeight = 18;
  const xAxisMargin = xAxisTextHeight + 10;
  const valueLabelMargin = 15;
  const barTopRadius = 4;

  useEffect(() => {
    const draw = (): void => {
      const defaultOpacity = 1;
      const selectedOpacity = 0.6;
      const animationDuration = 300;
      const chartBarHeight =
        (height > 150 ? height : 150) - xAxisMargin - valueLabelMargin;

      const xValues = values.map((d) => d.name);

      const x: d3.ScaleBand<string> = d3
        .scaleBand()
        .range([0, width])
        .domain(xValues)
        .padding(0.5);

      const y: d3.ScaleLinear<number, number> = d3
        .scaleLinear()
        .domain([-2.5, 100 + valueLabelMargin])
        .rangeRound([0, chartBarHeight]);

      const xAxis: d3.Axis<string> = d3.axisBottom(x).tickValues(xValues);

      const svg = d3
        .select(ref.current)
        .select('svg')
        .html('')
        .attr('width', width)
        .attr('height', chartBarHeight + xAxisMargin + valueLabelMargin)
        .style('margin', '0px auto')
        .style('display', 'block');

      const svgg = svg.append('g').attr('transform', `translate(0,0)`);

      const xAxisG = svgg
        .append('g')
        .attr('class', 'vertical-bar-x-axis')
        .attr(
          'transform',
          `translate(0,${chartBarHeight + xAxisMargin - xAxisTextHeight})`
        )
        .call(xAxis);

      xAxisG.select('path').style('display', 'none');
      xAxisG.selectAll('g').select('line').style('display', 'none ');

      const tooltipGenerator = (val: VerticalBarValue): string => {
        const { percentage } = val;
        return `
        <div>
          <span class="tool-tip-value">${getFormattedNumber(
            percentage,
            true
          )}%</span>
        </div>
        `;
      };

      const showTooltip = function (this: any): void {
        d3.select(this)
          .transition()
          .duration(animationDuration)
          .attr('opacity', selectedOpacity);
      };

      const hideTooltip = function (this: any): void {
        d3.select(this)
          .transition()
          .duration(animationDuration)
          .attr('opacity', defaultOpacity);
      };

      const slice = svgg
        .selectAll<SVGGElement, VerticalBarValue>('.slice')
        .data(values)
        .enter()
        .append('g')
        .attr('class', 'g');

      slice
        .append('rect')
        .attr('class', 'rect-g')
        .attr('data-for', 'svgTooltip2')
        .attr('data-tip', (d) => tooltipGenerator(d))
        .on('mouseenter', showTooltip)
        .on('mouseleave', hideTooltip)
        .style('fill', (d): string => getColorFor(d.group))
        .attr('height', (d) => y(d.percentage))
        .attr('width', x.bandwidth())
        .attr('x', (d) => x(d.name) || 0)
        .attr('y', (d) => chartBarHeight - y(d.percentage) || 0)
        .attr('clip-path', (d) => `url(#round-corner-${d.name})`);

      // Clipping path to get rounded top corners
      slice
        .append('defs')
        .append('clipPath')
        .attr('id', (d) => `round-corner-${d.name}`)
        .append('rect')
        .attr('width', x.bandwidth() * 0.8)
        .attr('x', (d) => x(d.name) || 0)
        .attr('y', (d) => chartBarHeight - (y(d.percentage) || 0))
        .attr('height', (d) => y(d.percentage) + barTopRadius)
        .attr('rx', barTopRadius)
        .attr('ry', barTopRadius);

      ReactTooltip.rebuild();
    };

    if (values && ref != null) {
      draw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, ref, values, width]);

  return (
    <div ref={ref} className="ins-custom-chart-wrapper vertical-chart-div">
      <ReactTooltip type="light" id="svgTooltip2" html />
      <svg width={width} />
    </div>
  );
};

export default VerticalBar;
