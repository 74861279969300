/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useEffect, useState, FC as ReactFC } from 'react';

import { Label } from 'reactstrap';
import { reach, ValidationError } from 'yup';

import usePrevious from 'shared/hooks/use-previous/UsePrevious';

import BlurInputProps from './BlurInputProps';

/**
 * This component is a performance optimization for @formik performance
 * issue withing large forms. it maintains input value and error in
 * local state and only sets to @formik state when input blurs, ps: this
 * component cannot be used with formik validations, since it does not update
 * formik state on input onChange, formik errors will update only on blur;
 * it will validate against the same schema section passed down as props
 * and assumes formik uses yup @ObjectSchema for validation
 * @param props BlurInputProps
 */
const BlurInput: ReactFC<BlurInputProps> = (props: BlurInputProps) => {
  const { field, form, validationSchema, customError, ...rest } = props;
  const { label, disabled, className } = props;

  const [value, setValue] = useState(field.value ?? '');
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);

  const submitting = form.isSubmitting;
  const prevSubmitting = usePrevious(submitting);

  useEffect(() => {
    setValue(field.value ?? '');
  }, [field.value]);

  useEffect(() => {
    if (submitting && submitting !== prevSubmitting) {
      setTouched(true);
    }
  }, [submitting, prevSubmitting]);

  useEffect(() => {
    /**
     * Runs yup validate on input value asynchronously and set
     * error to state
     * @param inputValue string input value
     */
    const validateField = async (inputValue: string): Promise<void> => {
      const schema = reach(validationSchema, field.name);
      if (!schema) {
        return;
      }
      try {
        await schema.validate(inputValue);
        setError('');
      } catch (err) {
        if (err instanceof ValidationError) {
          setError(err.message);
        }
      }
    };
    if (touched) {
      validateField(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.name, touched, JSON.stringify(validationSchema), value]);

  /**
   * Change handler sets current value to state and calls
   * validate on current value
   * @param event React.ChangeEvent<HTMLInputElement>
   */
  const handleChange = (event): void => {
    const { value: inputValue } = event.target;
    setValue(inputValue);
  };

  /**
   * Handle blur event handler which will set the value to
   * formik and calls formik blur triggering validation on
   * formik level
   *
   * @param event React.FocusEvent<HTMLInputElement>
   */
  const handleBlur = (event): void => {
    form.setFieldValue(field.name, value);
    field.onBlur(event);
    setTouched(true);
  };

  /**
   * Renders field error; local error gets priority over
   * optional customError passed as a prop
   */
  const renderError = (): ReactNode => {
    if (touched && error) {
      return <label className="form-error">{error}</label>;
    }
    if (customError) {
      if (typeof customError === 'function') {
        const custom = customError(field, form, value);
        if (custom) {
          return <label className="form-error">{custom}</label>;
        }
        return null;
      }
      return <label className="form-error">{customError}</label>;
    }
    return null;
  };

  const isError = !!renderError();

  const newClassName = `${String(className)} ${isError ? 'error' : ''}`;

  return (
    <>
      {label && (
        <Label disabled={disabled} htmlFor={field.name}>
          {label}
        </Label>
      )}
      <input
        {...rest}
        {...field}
        className={newClassName}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
      {renderError()}
    </>
  );
};

BlurInput.defaultProps = {
  className: undefined,
  customError: undefined,
};

export default BlurInput;
