/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { components } from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';
import { Col, Row } from 'reactstrap';

import ProjectUserOption from '../ProjectUserOption';

const { NoOptionsMessage } = components;

const SearchBarNoOptionsMessage: ReactFC<NoticeProps<ProjectUserOption>> = (
  props: NoticeProps<ProjectUserOption>
) => {
  const { selectProps } = props;
  return (
    <NoOptionsMessage {...props}>
      <Row className="d-flex m-0 justify-content-center p-3">
        <Col>
          <h4 className="text-14-semibold text-primary">
            {selectProps.hasPermission
              ? intl.get('LBL_PROJECTS_USERS_SEARCH_RESULTS_EMPTY_TITLE')
              : intl.get('ERR_NO_PERMISSION_MESSAGE')}
          </h4>
        </Col>
      </Row>
    </NoOptionsMessage>
  );
};

export default SearchBarNoOptionsMessage;
