import { ReactNode, useCallback, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { RenderLayerProps, ToggleLayer } from 'react-laag';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import { recipientTypes } from 'modules/private/surveys/containers/SurveyConstants';

import RecipientTypeSelectProps from './RecipientTypeSelectProps';

const RecipientTypeSelect: ReactFC<RecipientTypeSelectProps> = (
  props: RecipientTypeSelectProps
) => {
  const { surveyId, selectedRecipientTypes, disabled, onChange } = props;

  const [recipientTypeRef, setRecipientTypeRef] =
    useState<HTMLDivElement | null>(null);

  const setCallbackRef = useCallback((element) => {
    setRecipientTypeRef(element);
  }, []);

  const interactedSurveys = Object.keys(selectedRecipientTypes);
  const surveyRowExists = interactedSurveys.includes(surveyId);
  let selectedTypeLabel = '';

  if (surveyRowExists) {
    const selectedTypeValue = selectedRecipientTypes[surveyId];
    const moddedTypes = recipientTypes.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.value]: currentValue.label,
      }),
      {}
    );
    selectedTypeLabel = intl.get(moddedTypes[selectedTypeValue]);
  }

  const renderLayer = ({
    isOpen,
    layerProps,
    close,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <>
        <div
          ref={layerProps.ref}
          style={{
            ...layerProps.style,
            left: '50%',
            minWidth: '100%',
            transform: 'translateX(-50%)',
            top: '10px',
            zIndex: 90,
          }}
          className="layer insight-dropdown"
        >
          <Form>
            {recipientTypes.map((recipientType) => {
              let isSelected = false;
              if (surveyRowExists) {
                isSelected =
                  selectedRecipientTypes[surveyId] === recipientType.value;
              }
              return (
                <FormGroup
                  key={recipientType.value}
                  className="insight-radio-group"
                >
                  <Label className="surveys-select-label">
                    <span className="insight-radio">
                      <Input
                        type="radio"
                        tabIndex={0}
                        name="recipientType"
                        value={recipientType.value}
                        onChange={(e): void => onChange(e, close)}
                        className={isSelected ? 'active-radio' : ''}
                      />
                      <i className="icon-radio" />
                    </span>
                    {intl.get(recipientType.label)}
                  </Label>
                </FormGroup>
              );
            })}
          </Form>
        </div>
      </>
    );

  const container = recipientTypeRef;

  return (
    <ToggleLayer
      container={container || undefined}
      closeOnOutsideClick
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => (
        <>
          <button
            ref={triggerRef}
            className={`calendar-button surveys-toggle text-gray ${
              isOpen ? 'calendar-open' : ''
            }`}
            data-cy="status-gf"
            onClick={toggle}
            disabled={disabled}
          >
            {selectedTypeLabel ||
              intl.get('LBL_SURVEYS_SURVEY_TYPE_PLACEHOLDER')}
          </button>
          <div
            ref={setCallbackRef}
            className="position-relative"
            id="recipient-type-select-parent"
          />
        </>
      )}
    </ToggleLayer>
  );
};

export default RecipientTypeSelect;
