import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { Col, Row, Button, Collapse } from 'reactstrap';

import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import history from 'router-history';

import CreateViewProjectsProps from './CreateViewProjectsProps';

const CreateViewProjects: ReactFC<CreateViewProjectsProps> = (
  props: CreateViewProjectsProps
) => {
  const { order, stepCompleted, active, toggle, projectPermission, onClosed } =
    props;
  const location = useLocation();
  /**
   * Handles viewing/creating a project through the getting started modal
   */
  const handleProjectViewCreate = (): void => {
    /** onClose needs to be called on navigation out since the getting
     * started tooltip need to render again give that getting started modal
     * is closed
     */
    onClosed();
    history.push({
      pathname: ModulePaths.ProjectsPath,
      search: getGlobalFiltersQuery(location.search),
    });
    handleGATracking();
  };

  /**
   * Handles tracking for viewing/creating a new project for analytics
   */
  const handleGATracking = (): void => {
    switch (projectPermission) {
      case 'VIEW':
        sendEventGA(
          CategoryKeysGA.GettingStartedViewProjects,
          ActionKeysGA.ViewProject
        );
        break;
      case 'CREATE':
        sendEventGA(
          CategoryKeysGA.GettingStartedCreateProject,
          ActionKeysGA.CreateProject
        );
        break;
      default:
        break;
    }
  };

  return (
    <Col xs="12" data-cy={`gs-collapse-${order}`}>
      {active !== order && (
        <Row
          onClick={(): void => toggle(order)}
          className="collapse-header"
          data-cy={`gs-head-${order}`}
        >
          <Col xs="auto">
            {stepCompleted ? (
              <span className="check">
                <i className="icon-check" />
              </span>
            ) : (
              <span className="number">{order}</span>
            )}
          </Col>
          <Col className="text-14-semibold">
            {projectPermission === 'CREATE'
              ? intl.get('LBL_GSG_CREATE_NEW_PROJECT')
              : intl.get('LBL_GSG_VIEW_YOUR_PROJECTS')}
          </Col>
        </Row>
      )}
      <Collapse isOpen={active === order}>
        <Row className="collapse-body">
          <Col xs="auto">
            {stepCompleted ? (
              <span className="check">
                <i className="icon-check" />
              </span>
            ) : (
              <span className="number">{order}</span>
            )}
          </Col>
          <Col className="col-9">
            {active === order && (
              <p className="text-14-semibold">
                {projectPermission === 'CREATE'
                  ? intl.get('LBL_GSG_CREATE_NEW_PROJECT')
                  : intl.get('LBL_GSG_VIEW_YOUR_PROJECTS')}
              </p>
            )}
            <p className="text-14-medium mb-0">
              {projectPermission === 'CREATE'
                ? intl.get('LBL_GSG_CREATE_PROJECTS_TEXT')
                : intl.get('LBL_GSG_VIEW_PROJECTS_TEXT')}
            </p>
          </Col>
          <Col className="col-2">
            <Button
              size="sm"
              color="primary"
              data-cy="gs-btn-proj"
              onClick={handleProjectViewCreate}
            >
              {projectPermission === 'CREATE'
                ? intl.get('BTN_GSG_CREATE_PROJECTS_CREATE')
                : intl.get('BTN_GSG_CREATE_PROJECTS_GO')}
            </Button>
          </Col>
        </Row>
      </Collapse>
    </Col>
  );
};

export default CreateViewProjects;
