import { FC as ReactFC } from 'react';

import isNil from 'lodash/isNil';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';

import { getFormattedNumber } from 'helpers/NumberFormat';
import CommonError from 'modules/private/finances/components/key-metrics/common-error/CommonError';

import KeyRatiosProps from './KeyRatiosProps';

const KeyRatios: ReactFC<KeyRatiosProps> = (props: KeyRatiosProps) => {
  const { data } = props;
  const { error, loading } = props;

  /**
   * Formats numbers for display
   *
   * @param value Value to be formatted
   * @returns {string} Formatted value
   */
  const prepareValue = (value?: number | null): string => {
    if (error) {
      return intl.get('LBL_NA');
    }
    if (!isNil(value)) {
      return `${getFormattedNumber(value, false)}%`;
    }
    return intl.get('LBL_NA');
  };

  return (
    <BlockUi blocking={loading} tag="div" className="content-box">
      <CommonError error={error} />
      <div className="content-box-title">
        <h3 className="main-title">
          {intl.get('LBL_KM_KEY_RATIOS')}
          <span className="help">
            <i
              className="icon-help"
              data-for="insTooltip"
              data-tip={intl.get('LBL_KM_KEY_RATIOS_HINT')}
            />
          </span>
        </h3>
      </div>
      <div className="content-section">
        <table className="content-table">
          <thead>
            <tr>
              <th>{intl.get('LBL_KM_DESCRIPTION')}</th>
              <th className="value">{intl.get('LBL_KM_VALUE')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {intl.get('LBL_KM_LOAN_FUND_UTILIZATION')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_KM_LOAN_FUND_UTILIZATION_HINT')}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.loanFundUtilization)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_RETURN_ON_SAVINGS')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_KM_RETURN_ON_SAVINGS_HINT')}
                  />
                </span>
              </td>
              <td className="value">{prepareValue(data.returnOnSavings)}</td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_PORTFOLIO_AT_RISK')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_KM_PORTFOLIO_AT_RISK_HINT')}
                  />
                </span>
              </td>
              <td className="value">{prepareValue(data.portfolioAtRisk)}</td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_LOAN_TO_SAVINGS_RATIO')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_KM_LOAN_TO_SAVINGS_RATIO_HINT')}
                  />
                </span>
              </td>
              <td className="value">{prepareValue(data.loanToSavingsRatio)}</td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_LOAN_REPAYMENT_RATE')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_KM_LOAN_REPAYMENT_RATE_HINT')}
                  />
                </span>
              </td>
              <td className="value">{prepareValue(data.loanRepaymentRate)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </BlockUi>
  );
};

export default KeyRatios;
