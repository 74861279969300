enum GroupMetricType {
  GroupsFormed = 'GROUPS_FORMED',
  MemberCount = 'MEMBER_COUNT',
  AverageMembers = 'AVG_MEMBERS',
  AttendanceRate = 'ATTENDANCE_RATE',
  RepRate = 'REP_RATE',
  DropoutRate = 'DROPOUT_RATE',
}

export default GroupMetricType;
