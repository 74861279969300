/* eslint-disable no-plusplus */
import { FC as ReactFC } from 'react';

import { insightsColors } from 'helpers/ColorHelper';

import StepDotsProps from './StepDotsProps';

const StepDots: ReactFC<StepDotsProps> = ({ count, step }: StepDotsProps) => {
  /**
   * Renders the wizard step dots
   *
   * @returns {JSX.Element[]} JSX snippet list containing the step dots
   */
  const renderDots = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    for (let index = 1; index <= count; index++) {
      const active = index <= step;
      elements.push(
        <div
          className="m-1"
          key={index}
          style={{
            height: '10px',
            width: '10px',
            backgroundColor: `${
              active ? insightsColors.insightSecondary : 'white'
            }`,
            borderRadius: '50%',
            border: `0.5px solid ${insightsColors.insightSecondary}`,
          }}
        />
      );
    }
    return elements;
  };

  return <div className="d-flex justify-content-center">{renderDots()}</div>;
};

export default StepDots;
