const GrantKeys = {
  OrgRegisterId: 'ORG_REGISTER_ID',
  BillingAndPaymentViewEdit: 'BILLING_AND_PAYMENT_VIEW_EDIT',
  MyPlanViewEdit: 'MY_PLAN_VIEW_EDIT',
  OrgNameView: 'ORG_NAME_VIEW',
  OrgSavingsGroupView: 'ORG_SAVINGS_GROUP_VIEW',
  OrgDefaultCurrencyEdit: 'ORG_DEFAULT_CURRENCY_EDIT',
  OrgImplementingPartnerViewEdit: 'ORG_IMPLEMENTING_PARTNER_VIEW_EDIT',
  OrgGroupStatusEdit: 'ORG_GROUP_STATUS_VIEW_EDIT',
  PermissionAdd: 'PERMISSION_ADD',
  PermissionEdit: 'PERMISSION_EDIT',
  ActivityLogView: 'ACTIVITY_LOG_VIEW',
  SetUpManualView: 'SET_UP_MANUAL_VIEW',
  UserView: 'USER_VIEW',
  UserInvite: 'USER_INVITE',
  UserEdit: 'USER_EDIT',
  UserDeactivate: 'USER_DEACTIVATE',
  ProjectCreate: 'PROJECT_CREATE',
  ProjectCodeEdit: 'PROJECT_CODE_EDIT',
  ProjectDetailsEdit: 'PROJECT_DETAILS_EDIT',
  ProjectGoalsEdit: 'PROJECT_GOALS_EDIT',
  ProjectUsersAssign: 'PROJECT_USERS_ASSIGN',
  ProjectView: 'PROJECT_VIEW',
  FacilitatorCreate: 'FACILITATOR_CREATE',
  FacilitatorUpdate: 'FACILITATOR_UPDATE',
  FacilitatorDeactivate: 'FACILITATOR_DEACTIVATE',
  FacilitatorGroupsAssign: 'FACILITATOR_GROUPS_ASSIGN',

  GroupAssignGroupStatus: 'GROUP_ASSIGN_GROUP_STATUS',

  DataViewAllCountries: 'VIEW_DATA_ALL_COUNTRIES',
  DataViewAssignedCountries: 'VIEW_DATA_ASSIGNED_COUNTRIES_ONLY',
  DataViewAllProjects: 'VIEW_DATA_ALL_PROJECTS',
  DataViewAssignedProjects: 'VIEW_DATA_ASSIGNED_PROJECTS_ONLY',
  DataViewAllGroups: 'VIEW_DATA_ALL_GROUPS',
  DataViewAssignedGroups: 'VIEW_DATA_ASSIGNED_GROUPS_ONLY',

  ExportCSV: 'EXPORT_CSV',
  ExportPDF: 'EXPORT_PDF',

  MessagingCompose: 'COMPOSE_APP_MESSAGE',
  SurveysCreate: 'CREATE_SURVEY',
  SurveysCollectAdditionalID: 'COLLECT_ADDITIONAL_ID',
};

export default GrantKeys;
