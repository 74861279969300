/* eslint-disable react/jsx-props-no-spreading */
import { useRef, FC as ReactFC } from 'react';

import { LocationDescriptorObject } from 'history';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';

import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';

import InsLinkButtonBlurProps from './InsLinkButtonBlurProps';

const InsLinkButtonBlur: ReactFC<InsLinkButtonBlurProps> = (
  props: InsLinkButtonBlurProps
) => {
  const { children, onClick, to } = props;

  const location = useLocation();

  const linkRef = useRef<HTMLAnchorElement>(null);

  const handleClick = (eventProps): void => {
    if (onClick) onClick(eventProps);
    if (linkRef.current) linkRef.current.blur();
  };

  const search = getGlobalFiltersQuery(location.search);
  const { gf = '{}' } = qs.parse(search);

  let toLink: LocationDescriptorObject<unknown>;
  if (typeof to === 'string') {
    const existingSearch = qs.parse(qs.extract(to));
    const newSearch = qs.stringify({ gf, ...existingSearch });
    const pathname = to.split('?')[0];
    toLink = {
      pathname,
      search: newSearch,
    };
  } else {
    const toObj = to as LocationDescriptorObject<unknown>; // nosonar
    if (toObj.pathname) {
      const existingSearch = qs.parse(qs.extract(toObj.pathname));
      const newSearch = qs.stringify({ gf, ...existingSearch });
      const pathname = toObj.pathname?.split('?')[0];
      toLink = {
        ...toObj,
        pathname,
        search: newSearch,
      };
    } else {
      toLink = toObj;
    }
  }

  return (
    <Link ref={linkRef} {...props} to={toLink} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default InsLinkButtonBlur;
