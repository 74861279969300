/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Redirect, RedirectProps } from 'react-router-dom';

import RedirectHandlerProps from './RedirectHandlerProps';

const RedirectHandler: ReactFC<RedirectHandlerProps> = (
  props: RedirectHandlerProps
) => {
  const { redirect, children, ...rest } = props;
  if (redirect) {
    const redirectProps = rest as RedirectProps;
    return <Redirect {...redirectProps} />;
  }
  return <>{children}</>;
};

export default RedirectHandler;
