import { useEffect, FC as ReactFC } from 'react';

import intl from 'react-intl-universal';
import { Button, Col, ModalBody, ModalHeader, Row } from 'reactstrap';

import DashboardApi from 'api/dashboard/DashboardApi';
import successAnimation from 'shared/static/img/animations/success.gif';

import styles from './getting-started-completed.module.scss';
import GettingStartedCompletedProps from './GettingStartedCompletedProps';

const GettingStartedCompleted: ReactFC<GettingStartedCompletedProps> = (
  props: GettingStartedCompletedProps
) => {
  const { updateGettingStartedStates, onToggle, setShowGSComplete } = props;

  useEffect(() => {
    const setGettingStartedStatus = async (): Promise<void> => {
      try {
        await DashboardApi.SetGettingStartedStatus();
        await updateGettingStartedStates(true);
      } catch (error) {
        // error handling here doesn't make sense
        // console.log('error:', error);
      } finally {
        setShowGSComplete(true);
      }
    };

    setGettingStartedStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalHeader className="increase-font text-center">
        {intl.get('LBL_GSG_GETTING_STARTED_COMPLETED_TITLE')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col
            xs="12"
            className="d-flex justify-content-center mb-3 px-3 pb-3 pt-1"
          >
            <img src={successAnimation} alt="Success" className={styles.anim} />
          </Col>
        </Row>
        <Row className="justify-content-center mt-3 pt-3 pb-3 mb-3">
          <Col xs="auto">
            <Button color="primary" onClick={onToggle}>
              {intl.get('BTN_CA_LETS_GET_STARTED')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </>
  );
};

export default GettingStartedCompleted;
