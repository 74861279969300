export type ProjectSupervisor = {
  id: string;
  name: string;
};

export type JobRole = {
  id: string;
  role: string;
};

class ProjectUsersViewModel {
  userId: string;

  name: string;

  image: string;

  permissionLevel: string;

  countryDataAccess: Array<string>;

  userNumber?: string;

  lastLogin?: string;

  jobRole?: JobRole;

  supervisor?: ProjectSupervisor;

  constructor(
    userId: string,
    name: string,
    image: string,
    permissionLevel: string,
    countryDataAccess: Array<string>,
    userNumber?: string,
    lastLogin?: string,
    jobRole?: JobRole,
    supervisor?: ProjectSupervisor
  ) {
    this.userId = userId;
    this.name = name;
    this.image = image;
    this.permissionLevel = permissionLevel;
    this.countryDataAccess = countryDataAccess;
    this.userNumber = userNumber;
    this.lastLogin = lastLogin;
    this.jobRole = jobRole;
    this.supervisor = supervisor;
  }
}

export default ProjectUsersViewModel;
