/* eslint-disable react/jsx-props-no-spreading */
import { CSSProperties, FC as ReactFC } from 'react';

import styles from '../../phoneNumberField.module.scss';
import CountrySelectDropdownProps from './CountrySelectDropdownProps';

const CountrySelectDropdown: ReactFC<CountrySelectDropdownProps> = (
  props: CountrySelectDropdownProps
) => {
  const { children, isOpen, target, onClose } = props;
  const handleKeyPress = (event): void => {
    if (event.key === 'Enter') {
      onClose();
    }
  };

  const blanketStyles: CSSProperties = {
    bottom: 0,
    left: 0,
    top: 0,
    right: 0,
    position: 'fixed',
    zIndex: 1,
  };

  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  const menuStyles: CSSProperties = {
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
    marginTop: 32,
    position: 'absolute',
    zIndex: 2,
  };

  return (
    <div>
      {target}
      {isOpen ? <div style={menuStyles}>{children}</div> : null}
      {isOpen ? (
        <div
          tabIndex={-1}
          role="button"
          aria-label="blanket"
          className={styles.blanket}
          style={blanketStyles}
          onKeyPress={handleKeyPress}
          onClick={onClose}
        />
      ) : null}
    </div>
  );
};

export default CountrySelectDropdown;
