/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC as ReactFC } from 'react';

import clone from 'lodash/clone';
import * as intl from 'react-intl-universal';

import ModulePaths from 'constants/ModulePaths';
import WidgetTargetType from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetType';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import FinancialMetricsChartTypes from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartTypes';
import CurrencyStorageService from 'services/storage-services/CurrencyStorageService';
import DonutArea from 'shared/components/ins-charts/donut-area/DonutArea';
import GaugeWidget from 'shared/components/ins-charts/gauge/GaugeWidget';
import InsLink from 'shared/components/ins-link/InsLink';
import InsNumberFormat from 'shared/components/ins-number-format/InsNumberFormat';

import styles from '../widgets.module.scss';

const AverageSavingsWidget: ReactFC<{
  data: WidgetViewModel;
  setupInProgress: boolean;
}> = (props) => {
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { setupInProgress } = props;
  const { data } = clone(props);
  const { value, targetData } = data;

  let savingsData;

  if (value) {
    savingsData = {
      name: value.title,
      count: value.count,
      percentage: value.percentage,
      group: value.group,
    };
  }

  if (targetData) {
    savingsData.percentageRange = targetData.percentageRange;
    savingsData.fromTarget = targetData.targetFrom;
    savingsData.toTarget = targetData.targetTo;
  }

  const selectedCurrency = CurrencyStorageService.GetSelectedCurrency();
  const showTargetHint =
    data.targetData &&
    (data.targetData.target === 0 ||
      data.targetData.targetFrom === 0 ||
      data.targetData.targetTo === 0);

  return (
    <div className="gender-widget">
      <div className="chart-container">
        {!data.targetData ||
          (data.targetData &&
            data.targetData.targetType !== WidgetTargetType.Range && (
              <DonutArea
                isLoading={false}
                value={savingsData}
                dimension={dimensionSettings}
              />
            ))}
        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.Range && (
            <GaugeWidget
              isLoading={false}
              isCurrency
              value={savingsData}
              dimension={dimensionSettings}
            />
          )}
      </div>
      <div className="chart-details">
        <span className="sub-title">{intl.get('LBL_CURRENT_SAVINGS')}</span>
        {savingsData && (
          <span className="value">
            <InsNumberFormat
              value={savingsData.count}
              type="currency"
              precise={false}
            />
          </span>
        )}
      </div>
      <div className="chart-details separator">
        <span className="sub-title target-hint">
          {intl.get('LBL_PROJECT_TARGET')}
          {showTargetHint && (
            <span className="help">
              <i
                className="icon-help"
                data-for="insTooltipTargetHint"
                data-tip={intl.get('LBL_WID_TARGET_ZERO_HINT', {
                  currencyCode: selectedCurrency.currencyCode,
                })}
              />
            </span>
          )}
        </span>
        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.None && (
            <span className="value">not set</span>
          )}
        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.Value &&
          data.targetData.target !== null && (
            <span className="value">
              <InsNumberFormat
                value={data.targetData.target}
                type="currency"
                precise={false}
              />
            </span>
          )}
        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.Range &&
          data.targetData.targetFrom !== null &&
          data.targetData.targetTo !== null && (
            <span className="value">
              <InsNumberFormat
                value={data.targetData.targetFrom}
                type="currency"
                precise={false}
              />
              <span> - </span>
              <InsNumberFormat
                value={data.targetData.targetTo}
                type="currency"
                precise={false}
              />
            </span>
          )}
        {setupInProgress === false && (
          <InsLink
            disabledClass="disabled"
            to={{
              pathname: ModulePaths.FinancesPath,
              state: {
                chartType: FinancialMetricsChartTypes.AverageSavingsPerMember,
              },
            }}
            className={`btn btn-secondary btn-sm d-inline-flex ${styles.viewMoreButton}`}
          >
            <i className="icon-arrow-right" /> {intl.get('BTN_VIEW_MORE')}
          </InsLink>
        )}
      </div>
    </div>
  );
};

export default AverageSavingsWidget;
