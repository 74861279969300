/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import {
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import {
  Align,
  ListOnItemsRenderedProps,
  ListOnScrollProps,
  ListProps,
  VariableSizeList as List,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Col, Row } from 'reactstrap';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import AppContext from 'context/AppContext';
import mergeRefs from 'helpers/mergeRefs';
import PermissionUtil from 'helpers/PermissionUtil';
import UserProfilePermissionHelper from 'helpers/UserProfilePermissionHelper';
import ImageComponent from 'shared/components/image/ImageComponent';
import Status from 'shared/enums/Status';
import EmptyStateIllustration from 'shared/static/img/supervisees-empty-state.svg';
import EmptyStateThumb from 'shared/static/img/thumbs/supervisees-empty-state.png';

import UserProfileFieldWrapper from '../user-profile-left-panel/user-profile-field-wrapper/UserProfileFieldWrapper';
import { UserProfileFields } from '../user-profile-left-panel/UserProfileLeftPanelProps';
import UserProfileSuperviseeItem from './user-profile-supervisee-item/UserProfileSuperviseeItem';
import styles from './userProfileSupervisees.module.scss';
import UserProfileSuperviseesHandle from './UserProfileSuperviseesHandle';
import UserProfileSuperviseesProps from './UserProfileSuperviseesProps';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height)}px`,
    }}
    {...rest}
  />
));

const UserProfileSupervisees: React.ForwardRefRenderFunction<
  UserProfileSuperviseesHandle,
  UserProfileSuperviseesProps
> = (props: UserProfileSuperviseesProps, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: superviseesData,
    restrictions,
    status,
    superviseesStatus,
    page,
    pageCount,
    pageSize,
    loadNextPage,
    scrolling,
    toggleUnassignModal,
  } = props;

  const MIN_SUPERVISEE_ELEMENT_COUNT = 3;
  const { canEditProfile, isOwnProfile, isOwner } = restrictions;

  const { userProfileSetupInProgress, permissionsData } =
    useContext(AppContext);

  const canEditUserProfile = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.UserProfileEdit
  );
  const canDeactivateUsers = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.UserProfileDeactivate
  );
  const { permissionLevel } = permissionsData;

  const canEditField =
    UserProfilePermissionHelper.CanEditUserProfileField(
      UserProfileFields.UNASSIGN_SUPERVISEE,
      canEditProfile,
      isOwnProfile,
      isOwner,
      permissionLevel ?? '',
      canEditUserProfile,
      canDeactivateUsers
    ) && userProfileSetupInProgress;

  const infiniteLoaderRef = useRef<InfiniteLoader>();
  const listRef = useRef<List>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listOuterRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listInnerRef = useRef<any>(null);

  const [listScrollOffset, setListScrollOffset] = useState<number>(0);

  const maxHeight =
    (listInnerRef.current &&
      listInnerRef.current.style.height.replace('px', '')) ||
    900;
  const minHeight = 0;
  const pageOffset = 30;

  const [pageUp, pageDown, arrowUp, arrowDown] = [33, 34, 38, 40];

  const scrollKeys = {
    [pageUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [pageDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
    [arrowUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [arrowDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
  };

  /**
   * Handles key down events on the list wrapper for scrolling purposes
   *
   * @param event Key down event
   */
  const handleKeyDown = ({ keyCode }: React.KeyboardEvent): void => {
    if (scrollKeys[keyCode]) {
      setListScrollOffset(scrollKeys[keyCode]);
    }
  };

  /**
   * Handles onScroll event for list
   *
   * @param {ListOnScrollProps}
   */
  const handleListScroll = ({ scrollOffset }: ListOnScrollProps): void =>
    setListScrollOffset(scrollOffset);

  useLayoutEffect(() => {
    if (listOuterRef && listOuterRef.current) {
      listOuterRef.current.scrollTo({
        left: 0,
        top: listScrollOffset,
        behavior: 'auto',
      });
    }
  });

  useImperativeHandle(ref, () => ({
    resetloadMoreItemsCache(autoReload?: boolean): void {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(autoReload);
      }
    },
    scrollToItem(index: number, align?: Align): void {
      if (listRef.current) {
        listRef.current.scrollToItem(index, align);
      }
    },
  }));

  // pageCount - 1 is max page number; since pages start from 0
  const hasNextPage = page < pageCount - 1;

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    if (superviseesStatus === Status.Loading) {
      return Promise.resolve();
    }
    return loadNextPage(startIndex, stopIndex);
  };

  const firstTimeLoading =
    superviseesStatus === Status.Loading && superviseesData.length <= 0;

  const showEmpty =
    superviseesStatus === Status.Success && superviseesData.length <= 0;

  const itemCount = hasNextPage
    ? Number(superviseesData.length) + 1
    : superviseesData.length;

  const isItemLoaded = useCallback(
    (index: number): boolean => !hasNextPage || index < superviseesData.length,
    [hasNextPage, superviseesData.length]
  );

  const handleItemsRendered =
    (onItemsRenderedIL: ListProps['onItemsRendered']) =>
    (listItemProps: ListOnItemsRenderedProps): void => {
      if (onItemsRenderedIL) {
        onItemsRenderedIL(listItemProps);
      }
    };

  /**
   * Renders the empty state for the supervisees list
   *
   * @returns {ReactNode} ReactNode containing the empty state icon and message
   */
  const renderEmptyState = (): ReactNode =>
    status === Status.Loading ? (
      <div />
    ) : (
      <div className={`d-flex justify-content-center ${styles.empty}`}>
        <Row>
          <Col xs="12" className="text-center p-4">
            <div className="text-16-semibold">
              {intl.get('LBL_USER_PROFILE_SUPERVISEES_EMPTY_TITLE')}
            </div>
          </Col>
          <Col xs="12" className="text-center">
            <ImageComponent
              loading="eager"
              src={EmptyStateIllustration}
              alt="No Existing Users"
              thumb={EmptyStateThumb}
            />
          </Col>
          <Col xs="12" className="text-center p-4">
            <p className="text-12-medium text-gray">
              {intl.getHTML('LBL_USER_PROFILE_SUPERVISEES_EMPTY_BODY')}
            </p>
          </Col>
        </Row>
      </div>
    );

  return (
    <>
      <BlockUi
        tag="div"
        blocking={status === Status.Loading || firstTimeLoading || scrolling}
        className="row dashboard-row"
      >
        <div className="col">
          <div className="content-box lower-margins">
            <div className="content-box-title pb-4 pt-2 mb-2">
              <h3 className="main-title text-uppercase">
                {intl.get('LBL_USER_PROFILE_SUPERVISEES_TITLE')}
              </h3>
            </div>
            <div>
              {showEmpty ? (
                renderEmptyState()
              ) : (
                <div>
                  <Col xs="12" className="pl-0">
                    <div className={styles.item}>
                      <Row className="header-row text-12-semibold text-uppercase">
                        <Col xs="3" className="header-col">
                          <span>
                            {intl.get(
                              'LBL_USER_PROFILE_SUPERVISEES_TABLE_NAME_HEADER'
                            )}
                          </span>
                        </Col>
                        <Col
                          xs={canEditField ? '2' : '3'}
                          className="header-col"
                        >
                          <span>
                            {intl.get(
                              'LBL_USER_PROFILE_SUPERVISEES_TABLE_JOB_ROLE_HEADER'
                            )}
                          </span>
                        </Col>
                        <Col xs="3" className="header-col">
                          <span>
                            {intl.get(
                              'LBL_USER_PROFILE_SUPERVISEES_TABLE_PROJECT_HEADER'
                            )}
                          </span>
                        </Col>
                        <Col
                          xs={canEditField ? '2' : '3'}
                          className="header-col"
                        >
                          <span>
                            {intl.get(
                              'LBL_USER_PROFILE_SUPERVISEES_TABLE_EMAIL_HEADER'
                            )}
                          </span>
                        </Col>
                        <UserProfileFieldWrapper
                          value=""
                          fieldName={UserProfileFields.UNASSIGN_SUPERVISEE}
                          canEditProfile={canEditProfile}
                          isOwnProfile={isOwnProfile}
                          isOwner={isOwner}
                          isNotInLeftPanel
                        >
                          <Col xs="2" className="header-col" />
                        </UserProfileFieldWrapper>
                      </Row>
                    </div>
                  </Col>
                  <div className={styles.scroll}>
                    <InfiniteLoader
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      ref={infiniteLoaderRef}
                      threshold={pageSize}
                      minimumBatchSize={pageSize}
                      isItemLoaded={isItemLoaded}
                      loadMoreItems={loadMoreItems}
                      itemCount={itemCount}
                    >
                      {({ onItemsRendered, ref: innerRef }): JSX.Element => (
                        <div role="list" tabIndex={0} onKeyDown={handleKeyDown}>
                          <List
                            ref={mergeRefs([innerRef, listRef])}
                            innerRef={listInnerRef}
                            outerRef={listOuterRef}
                            className="List"
                            height={230}
                            width="100%"
                            itemCount={itemCount}
                            innerElementType={innerElementType}
                            useIsScrolling
                            itemSize={(): number => 75}
                            itemData={{
                              list: superviseesData,
                              restrictions,
                              isItemLoaded,
                              toggleUnassignModal,
                            }}
                            itemKey={(index, data): string =>
                              data?.[index]?.id ?? index
                            }
                            onItemsRendered={handleItemsRendered(
                              onItemsRendered
                            )}
                            onScroll={handleListScroll}
                          >
                            {UserProfileSuperviseeItem}
                          </List>
                        </div>
                      )}
                    </InfiniteLoader>
                    {itemCount > MIN_SUPERVISEE_ELEMENT_COUNT ? (
                      <span className={styles.track} />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </BlockUi>
    </>
  );
};

export default forwardRef(UserProfileSupervisees);
