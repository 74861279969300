import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react/dist/errorboundary';
import * as intl from 'react-intl-universal';
import { Col, Row } from 'reactstrap';

import InsightsLogo from 'shared/static/img/logo-icon.svg';

import styles from './appErrorFallback.module.scss';

const AppErrorFallback: FallbackRender = ({
  error,
  eventId,
  componentStack,
}) => (
  <div className={`d-flex align-items-center ${styles.container}`} role="alert">
    <Col xs="12">
      <div className="content-box">
        <div className="content-box-title align-items-center">
          <h2 className="text-center">
            <img
              src={InsightsLogo}
              alt="insights logo"
              className={styles.logo}
            />
            {intl.get('OOPS_SOMETHING_WENT_WRONG_TITLE')}
          </h2>
          <div className="text-16-semibold text-center">
            {intl.get('ERR_YOU_HAVE_ENCOUNTERED_AN_ERROR')}
          </div>
        </div>
        {process.env.NODE_ENV === 'development' && (
          <>
            <div className={styles.errorBody}>
              <Row>
                <div className="text-16-medium">{error.toString()}</div>
              </Row>
              <Row>
                <div className={`text-14-medium ${styles.componentStack}`}>
                  {componentStack}
                </div>
              </Row>
            </div>
          </>
        )}
        <Row className="d-flex align-items-center">
          <Col xs="12" className="w-100 mt-4 justify-content-center d-flex">
            <button
              className="btn btn-primary"
              onClick={(): void =>
                Sentry.showReportDialog({ eventId: eventId ?? undefined })
              }
            >
              {intl.get('BTN_REPORT_FEEDBACK')}
            </button>
          </Col>
        </Row>
      </div>
    </Col>
  </div>
);

export default AppErrorFallback;
