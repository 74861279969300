/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Arrow, useTooltip } from 'react-laag';
import { Input } from 'reactstrap';

import { insightsColors } from 'helpers/ColorHelper';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import GroupStatusMenuItemProps from './GroupStatusMenuItemProps';

const GroupStatusMenuItem: ReactFC<GroupStatusMenuItemProps> = (
  props: GroupStatusMenuItemProps
) => {
  const { data, onUpdateGroupStatus, selected } = props;
  const { placement = {} } = props;

  const renderTooltip = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }): JSX.Element => (
    <>
      {isOpen ? (
        <div {...layerProps} className="popover invite-user-permission">
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            backgroundColor="#ffffff"
            borderWidth={1}
            borderColor={insightsColors.insightSecondary}
            roundness={0.7}
          />
          <div className="text-14-medium">
            <div>
              <span>{data.description}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );

  const [tooltipElement, triggerProps] = useTooltip(renderTooltip, {
    delayEnter: 100,
    delayLeave: 100,
    fixed: true,
    placement: {
      anchor: 'LEFT_CENTER',
      possibleAnchors: ['LEFT_CENTER'],
      triggerOffset: 40,
      autoAdjust: true,
      ...placement,
    },
  });

  return (
    <>
      <div key={data.id} className="form-group insight-radio-group">
        <label
          {...triggerProps}
          className={`truncate ${selected === data.id ? 'checked-label' : ''}`}
          style={{ maxWidth: '150px' }}
        >
          {tooltipElement}
          <span className="insight-radio">
            <Input
              type="radio"
              name={data.status}
              value={data.id}
              checked={selected === data.id}
              onChange={onUpdateGroupStatus}
            />
            <i className="icon-radio" />
          </span>
          <EllipsisTooltip
            tag="span"
            data-place="right"
            data-for="insTooltip"
            data-tip={data.status}
            data-class="overflow-wrap"
            className="truncate"
          >
            {data.status}
          </EllipsisTooltip>
        </label>
      </div>
    </>
  );
};

export default GroupStatusMenuItem;
