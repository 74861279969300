const Tables = {
  PageSizes: [10, 25, 50, 100],
  SortDirection: {
    Descending: 'desc',
    Ascending: 'asc',
  },
  InitialQueryBasic: '?page=0&size=10',
};

export default Tables;
