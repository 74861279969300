class ProjectInfoFormValues {
  code: string;

  name: string;

  startDate: string;

  endDate: string;

  isTestProject: string;

  projectId?: string;

  constructor(
    code?: string,
    name?: string,
    startDate?: string,
    endDate?: string,
    isTestProject?: string,
    projectId?: string
  ) {
    this.code = code ?? '';
    this.name = name ?? '';
    this.startDate = startDate ?? '';
    this.endDate = endDate ?? '';
    this.isTestProject = isTestProject ?? 'false';
    this.projectId = projectId ?? '';
  }
}

export type ProjectInfoFormValueTypes = string;

export default ProjectInfoFormValues;
