/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useEffect, useRef, FC as ReactFC } from 'react';

import TrapFocusProps from './TrapFocusProps';

const TrapFocus: ReactFC<TrapFocusProps> = (props: TrapFocusProps) => {
  const { children: renderChildren, autoFocus } = props;

  const firstFocus = useRef<HTMLElement | null>(null);
  const lastFocus = useRef<HTMLElement | null>(null);

  const sentinelStart = useRef<HTMLElement | null>(null);
  const sentinelEnd = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (autoFocus && sentinelStart.current && sentinelStart.current.focus) {
      sentinelStart.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    function trapFocus(event: FocusEvent): void {
      if (event.target === sentinelStart.current) {
        if (lastFocus.current && lastFocus.current.focus) {
          lastFocus.current.focus();
        }
      }
      if (event.target === sentinelEnd.current) {
        if (firstFocus.current && firstFocus.current.focus) {
          firstFocus.current.focus();
        }
      }
    }
    document.addEventListener('focusin', trapFocus);
    return (): void => {
      document.removeEventListener('focusin', trapFocus);
    };
  }, [firstFocus, sentinelEnd]);

  return (
    <>
      <span ref={sentinelStart} tabIndex={0} />
      {renderChildren({ firstFocus, lastFocus })}
      <span ref={sentinelEnd} tabIndex={0} />
    </>
  );
};

export default TrapFocus;
