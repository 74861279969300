/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import ProtectedRoute from 'shared/components/hoc/protected-route/ProtectedRoute';

import MessagingView from './containers/messaging-view/MessagingView';
import MessagingViewProps from './containers/messaging-view/MessagingViewProps';

const MessagingRoutes: ReactFC<MessagingViewProps> = (
  props: MessagingViewProps
) => {
  const { match, location } = props;
  const path = match && match.path;

  return (
    <Switch>
      <ProtectedRoute
        exact
        resource={ResourceKeys.MessagingCompose}
        redirectTo={ModulePaths.DashboardPath}
        path={`${String(path)}`}
      >
        {(): JSX.Element => <MessagingView {...props} />}
      </ProtectedRoute>

      {/* No match for this sub-route; could show a 404 */}
      <Route path="*">
        <Redirect
          to={{
            pathname: ModulePaths.DashboardPath,
            search: getGlobalFiltersQuery(location.search),
          }}
        />
        ;
      </Route>
    </Switch>
  );
};

export default MessagingRoutes;
