/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import { Label } from 'reactstrap';

import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';

import FormikSelectProps, { FormikSelectOption } from './FormikSelectProps';
// import getFormikSelectStyles from './formikSelectStyles';

const FormikSelect: ReactFC<FormikSelectProps> = ({ options, ...props }) => {
  const {
    name,
    label,
    isMulti,
    // styles,
    fullWidth,
    noError,
    isDisabled,
    classNamePrefix,
    customSelect: CustomSelect,
    onBlur,
    onChange,
    renderSecondaryError,
  } = props;

  const { className } = props;

  const [field, meta] = useField(name);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const isError = meta.touched && meta.error;

  /**
   * Handle option selection change event
   *
   * @param selection Selected options
   */
  function handleOptionChange(selection): void {
    let currentValue: string | string[];
    if (isMulti) {
      const selectOptions = selection as FormikSelectOption[];
      currentValue = selectOptions.map((option) => option.value);
    } else {
      const selectOption = selection as FormikSelectOption;
      currentValue = selectOption.value;
    }
    setFieldValue(name, currentValue);

    // the calling component call pass a custom handler.
    if (onChange) {
      onChange(setFieldValue, selection);
    }
  }

  /**
   * Update blur for form field
   */
  function updateBlur(): void {
    setFieldTouched(name, true);
    if (onBlur) {
      onBlur(setFieldTouched, true);
    }
  }
  return (
    <>
      {label && (
        <Label disabled={isDisabled} htmlFor={name}>
          {label}
        </Label>
      )}
      {CustomSelect ? (
        <CustomSelect
          options={options}
          {...field}
          {...props}
          onBlur={updateBlur}
          onChange={handleOptionChange}
          className={className}
          classNamePrefix={classNamePrefix}
          error={isError}
          // styles={getFormikSelectStyles(styles)}
        />
      ) : (
        <Select
          options={options}
          {...field}
          {...props}
          onBlur={updateBlur}
          onChange={handleOptionChange}
          className={className}
          classNamePrefix={classNamePrefix}
          error={isError}
          // styles={getFormikSelectStyles(styles)}
        />
      )}
      {!noError && (
        <EnhancedFormikError
          name={name}
          renderSecondaryError={renderSecondaryError}
          fullWidth={fullWidth}
        />
      )}
    </>
  );
};

export default FormikSelect;
