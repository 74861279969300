/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { OptionProps } from 'react-select';

import TooltipOption from 'shared/components/tooltip-option/TooltipOption';
import TooltipOptionAnchor from 'shared/enums/TooltipOptionAnchor';

import PermissionOption from '../PermissionOption';
import styles from './permissionTooltipOption.module.scss';

const PermissionTooltipOption: ReactFC<OptionProps<PermissionOption>> = (
  props: OptionProps<PermissionOption>
) => {
  const { data, selectProps } = props;

  return (
    <TooltipOption
      {...props}
      anchor={TooltipOptionAnchor.RightCenter}
      tooltipBody={
        <div className={`text-14-medium ${styles.adjustTooltipBody}`}>
          <div className="overflow-wrap">
            <span className={styles.hintColor}>
              {data.description}
              <br />
              <br />
              <button
                type="button"
                className={`text-left btn pl-0 border-0 shadow-none ${styles.noTransform} ${styles.popperButton}`}
                style={{ textDecoration: 'underline' }}
                onClick={selectProps.togglePermissionsModal}
              >
                {intl.get('LBL_HTML_SETTINGS_USERS_PERMISSIONS_HINT')}
              </button>
            </span>
          </div>
        </div>
      }
    />
  );
};

export default PermissionTooltipOption;
