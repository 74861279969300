/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useEffect, useRef, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { ValueType } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';
import { Button, Col, FormGroup, Row } from 'reactstrap';

import ApiError from 'api/common/types/ApiError';
import OrganizationUser from 'api/settings/types/users/OrganizationUser';
import Constraints from 'constants/forms/Constraints';
import { getLocalizedErrorString } from 'helpers/ErrorFormat';
import ProjectUsersViewModel from 'modules/private/projects/containers/projects-view/ProjectUsersViewModel';
import ImageComponent from 'shared/components/image/ImageComponent';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import usePrevious from 'shared/hooks/use-previous/UsePrevious';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import AddExistingUsersProps from './AddExistingUsersProps';
import ExistingUserList from './existing-user-list/ExistingUserList';
import ProjectUserOption from './ProjectUserOption';
import SearchBarNoOptionsMessage from './search-bar/SearchBarNoOptionsMessage';
import SearchBarStyles from './search-bar/SearchBarStyles';
import SearchBarValueContainer from './search-bar/SearchBarValueContainer';

const AddExistingUsers: ReactFC<AddExistingUsersProps> = (
  props: AddExistingUsersProps
) => {
  const {
    projectId,
    selectedUser,
    existingUsers,
    hasUnsavedChanges,
    status,
    error: errorStatus,
    canAddUserToList,
    countryDataWarningOpen,
    fetchOrgUsers,
    onAddUserToListSuccess,
    onFetchUserProjectCompatibility,
    handleAddUsers,
    onAddUsersSuccess,
    setHasUnsavedChanges,
    togglePermissionsModal,
    onToggle,
  } = props;

  const selectRef = useRef<Async<ProjectUserOption> | null>(null);
  const tabRef = useRef<HTMLDivElement>(null);

  const prevCountryWarning = usePrevious(countryDataWarningOpen);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectText, setSelectText] = useState('');
  const [lookupErrorCode, setLookupErrorCode] = useState<number | null>(null);

  const [newProjectUsers, setNewProjectUsers] = useState<
    Array<ProjectUsersViewModel>
  >([]);

  /**
   * focus the search input once the user
   * is prompted with country warning and
   * is proceeded with either cancel or continue
   */
  useEffect(() => {
    if (
      prevCountryWarning === true &&
      countryDataWarningOpen === false &&
      selectRef.current
    ) {
      selectRef.current.focus();
    }
  }, [countryDataWarningOpen, prevCountryWarning]);

  useEffect(() => {
    setHasUnsavedChanges(newProjectUsers.length > 0 ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProjectUsers]);

  useEffect(() => {
    if (!hasUnsavedChanges) {
      setNewProjectUsers([]);
    }
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (canAddUserToList) {
      addUserToList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddUserToList]);

  /**
   * Handles removal of a new organization user from the list
   *
   * @param id ID of the user in question
   */
  const removeNewUserFromList = (id: string): void => {
    setNewProjectUsers(
      newProjectUsers.filter(
        (user: ProjectUsersViewModel) => user.userId !== id
      )
    );
  };

  /**
   * Validate if a specific user option is disabled in the search bar
   *
   * @param userId ID of the user in question
   * @returns {boolean} Returns true if the user option should be disabled
   */
  const isOptionDisabled = (userId: string): boolean =>
    // prettier-ignore
    existingUsers.find((existingUser) => existingUser.userId === userId) !== undefined ||
    newProjectUsers.find((newUser) => newUser.userId === userId) !== undefined;

  /**
   * Fetches and loads the search bar with organization users filtered by the input query
   *
   * @param inputValue Query to filter the organization users by
   * @returns {Promise<Array<ProjectUserOption>>} List of filtered organization user options
   */
  const loadOptions = async (
    inputValue: string
  ): Promise<Array<ProjectUserOption>> => {
    try {
      const orgUsers = await fetchOrgUsers(inputValue);
      const options: Array<ProjectUserOption> = orgUsers.items.map(
        (user: OrganizationUser) => ({
          value: user.userId,
          label: user.name,
          image: user.imageURL,
          email: user.email,
          permissionLevel: user.permissionLevel,
          countries: user.countryDataAccess,
          isDisabled: isOptionDisabled(user.userId),
        })
      );
      return options;
    } catch (error) {
      if (error instanceof ApiError) {
        setLookupErrorCode(error.status ?? null);
      } else {
        setLookupErrorCode(null);
      }
      return [];
    }
  };

  /**
   * Adds a new user to the project users list
   */
  const addUserToList = (): void => {
    if (selectedUser) {
      if (
        newProjectUsers.find(
          (newUser) => newUser.userId === selectedUser.userId
        ) === undefined
      ) {
        setNewProjectUsers([...newProjectUsers, selectedUser]);
      }
    }
    onAddUserToListSuccess();
  };

  /**
   * Handles the onChange event for the organization user search bar
   *
   * @param selected Value of the selected user option
   */
  const handleSelectChange = async (
    selected: ValueType<ProjectUserOption>
  ): Promise<void> => {
    const selectedOption = selected as ProjectUserOption;
    const user = new ProjectUsersViewModel(
      selectedOption.value,
      selectedOption.label,
      selectedOption.image,
      selectedOption.permissionLevel,
      selectedOption.countries
    );
    await onFetchUserProjectCompatibility(user, projectId);
  };

  /**
   * Renders an option for the organization user search bar
   *
   * @param option Data for the search option
   * @returns {JSX.Element} JSX snippet for the search option
   */
  const renderOption = (option: ProjectUserOption): JSX.Element => {
    const { value, label, email, isDisabled, image, permissionLevel } = option;

    return (
      <Row
        className="m-0 pt-1 pb-1"
        style={
          isDisabled
            ? {
                opacity: '0.7',
                pointerEvents: 'none',
              }
            : {}
        }
        id={`user.${String(value)}`}
      >
        <Col xs="auto" className="pt-1">
          <ImageComponent
            className="rounded-circle object-fit-cover border-light"
            ignoreBlur
            src={image}
            alt={image}
            fallbackSrc={blankAvatar}
            width="25"
            height="25"
          />
        </Col>
        <Col className="pl-0">
          <div className="text-14-semibold">
            <span className="text-14-semibold">{label}</span>{' '}
            <span className={`text-14-light ${isDisabled ? '' : 'text-gray'}`}>
              <small>{`(${email})`}</small>
            </span>{' '}
          </div>
          <div className={`text-14-light ${isDisabled ? '' : 'text-gray'}`}>
            <small>{permissionLevel}</small>
          </div>
        </Col>
      </Row>
    );
  };

  /**
   * Renders common API errors for the screen
   *
   * @returns {ReactNode} JSX snippet containing the error message
   */
  const renderCommonErrors = (): ReactNode => {
    if (errorStatus && errorStatus.msg) {
      const fieldKeys = Object.keys(errorStatus.fields);
      const commonFields = ['users'];
      let error: null | string | JSX.Element = null;

      if (fieldKeys.length === 0) {
        error = errorStatus.msg;
      } else if (commonFields.includes(fieldKeys[0])) {
        const errorCode = errorStatus.fields[fieldKeys[0]];
        error = intl.get(getLocalizedErrorString(errorCode));
      } else {
        error = intl.get('ERR_PROJECT_USERS_ADD_FAILURE');
      }

      if (error) {
        return (
          <Row>
            <Col>
              <FormGroup>
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </FormGroup>
            </Col>
          </Row>
        );
      }
    }
    return null;
  };

  /**
   * Handles adding new organization users to the project
   */
  const handleSubmit = async (): Promise<void> => {
    await handleAddUsers(newProjectUsers);
    onAddUsersSuccess(false, true);
  };

  const submitDisabled =
    status === Status.Loading || newProjectUsers.length === 0;

  /**
   * handles menuOpen state depending on weather or not input is empty
   * done to avoid issue: https://github.com/JedWatson/react-select/issues/3819
   * issue of cursor not visible when openMenuOnFocus prop is false after selecting
   * a value. In order to fix; as a workaround openMenuOnFocus has to be set to
   * true and the behavior of that prop should be implemented in a different way
   * hence the need of manually controlled menuIsOpen prop
   * @param text inputValue
   */
  const handleInputChange = (text: string): void => {
    setSelectText(text);
    if (text && text.length > 0) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  };

  const onMenuClose = (): void => setMenuIsOpen(false);

  return (
    <div ref={tabRef}>
      <form>
        <Row>
          <Col xs="12">
            <FormGroup>
              <AsyncSelect
                ref={(ref): void => {
                  selectRef.current = ref;
                }}
                autoFocus
                isSearchable
                inputProps={{ maxLength: Constraints.MaxFieldLength }}
                hasPermission={lookupErrorCode !== HTTP_STATUS.FORBIDDEN}
                value={null}
                defaultOptions={[]}
                loadOptions={loadOptions}
                onChange={handleSelectChange}
                getOptionLabel={(option): string => option.label}
                getOptionValue={(option): string => option.value}
                openMenuOnFocus
                inputValue={selectText}
                onInputChange={handleInputChange}
                openMenuOnClick={false}
                controlShouldRenderValue={false}
                tabSelectsValue={false}
                menuIsOpen={menuIsOpen}
                onMenuClose={onMenuClose}
                components={{
                  ValueContainer: SearchBarValueContainer,
                  NoOptionsMessage: SearchBarNoOptionsMessage,
                  DropdownIndicator: (): null => null,
                  IndicatorSeparator: (): null => null,
                }}
                formatOptionLabel={renderOption}
                isDisabled={status === Status.Loading}
                styles={SearchBarStyles.GetCustomStyles()}
                placeholder={intl.get(
                  'LBL_PROJECTS_USERS_ADD_SEARCH_PLACEHOLDER'
                )}
                name="existing-users"
                classNamePrefix="insight-select-no-border insight-select-no-border-projects"
              />
            </FormGroup>
          </Col>
        </Row>
        {renderCommonErrors()}
        <Row>
          <Col xs="12">
            <ExistingUserList
              existingUsers={existingUsers}
              status={status}
              newUsers={newProjectUsers}
              popoverRef={tabRef}
              togglePermissionsModal={togglePermissionsModal}
              removeNewUserFromList={removeNewUserFromList}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="mt-3 justify-content-between d-flex">
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={onToggle}
            >
              {intl.get('BTN_CANCEL')}
            </Button>
            <Button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={submitDisabled}
            >
              {intl.get('BTN_ADD_TO_PROJECT')}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AddExistingUsers;
