import * as intl from 'react-intl-universal';
import { Col, Row } from 'reactstrap';

import ImageComponent from 'shared/components/image/ImageComponent';
import EmptyStateIllustration from 'shared/static/img/current-projects-empty-state.svg';
import EmptyStateThumb from 'shared/static/img/thumbs/current-projects-empty-state.png';

import UserProfileFieldWrapper from '../user-profile-left-panel/user-profile-field-wrapper/UserProfileFieldWrapper';
import { UserProfileFields } from '../user-profile-left-panel/UserProfileLeftPanelProps';
import styles from '../user-projects-pane/usersProjectsPane.module.scss';
import ProjectEmptyViewProps from './ProjectEmptyViewProps';

const ProjectEmptyView: React.FC<ProjectEmptyViewProps> = ({
  restrictions,
  onAddToProjectClick,
}: ProjectEmptyViewProps) => (
  <div className={`d-flex justify-content-center ${styles.empty}`}>
    <Row>
      <Col xs="12" className="text-center p-4">
        <div className="text-16-semibold">
          {intl.get('LBL_USER_PROFILE_SUPERVISEES_EMPTY_TITLE')}
        </div>
      </Col>
      <Col xs="12" className="text-center">
        <ImageComponent
          loading="eager"
          src={EmptyStateIllustration}
          alt="No Existing Users"
          thumb={EmptyStateThumb}
        />
      </Col>
      <Col xs="12" className="text-center p-4">
        <p className="text-12-medium text-gray">
          {intl.getHTML('LBL_USER_PROFILE_CURRENT_PROJECTS_EMPTY_BODY')}
        </p>
      </Col>
      <UserProfileFieldWrapper
        value=""
        fieldName={UserProfileFields.ADD_TO_PROJECT}
        canEditProfile={restrictions.canEditProfile}
        isOwnProfile={restrictions.isOwnProfile}
        isOwner={restrictions.isOwner}
        isNotInLeftPanel
        isNotProfileEditMode
      >
        <Col xs="12" className="d-flex justify-content-center p-4">
          <button
            className="btn"
            disabled={false}
            onClick={onAddToProjectClick}
          >
            <i className="icon-plus" />
            {intl.get('BTN_ADD_TO_PROJECT')}
          </button>
        </Col>
      </UserProfileFieldWrapper>
    </Row>
  </div>
);

export default ProjectEmptyView;
