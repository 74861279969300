import { CSSProperties } from 'react';

import ProjectItem from 'api/projects/types/ProjectItem';
import AppContextProps from 'context/AppContextProps';
import ProjectStatsViewModel from 'modules/private/projects/containers/projects-view/ProjectStatsViewModel';
import Status from 'shared/enums/Status';

export enum TooltipGuideType {
  USERS = 'users',
  FACILITATORS = 'facilitators',
  GROUPS = 'groups',
}

type ItemData = {
  list: ProjectItem[];
  permissions: AppContextProps['permissionsData'];
  projectStats: ProjectStatsViewModel;
  guideProjectId: string | null;
  projectStatsStatus: Status;
  showGroupsGuide: boolean;
  isItemLoaded: (index: number) => boolean;
  setShowGroupsGuide: (open: boolean) => void;
};

interface ProjectListItemProps {
  style: CSSProperties;
  data: ItemData;
  index: number;
}

export default ProjectListItemProps;
