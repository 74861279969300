/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC as ReactFC } from 'react';

import { ResponsiveBar as NivoResponsiveBar } from '@nivo/bar';

import { insightsColors } from 'helpers/ColorHelper';
import { getCompactNumber, getFormattedNumber } from 'helpers/NumberFormat';
import ChartError from 'shared/components/chart-error/ChartError';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';

import barChartEmptyDummyData from './barChartEmptyDummyData';

const BarChartEmpty: ReactFC = () => {
  const getCurrencyString = useCurrency();
  /** this calculation holds bar width to 25px(@1920x1080) according
   * to number of bars : does not take resolution into consideration */
  const getPadding = (bars: number): number => 0.97 - 0.03 * bars;

  const formatCompactNumber = (number): string =>
    getCompactNumber(Number(number), false);

  const formatCompactCurrency = (number): string =>
    getCurrencyString(Number(number), false, IntlNumberNotation.Compact);

  /**
   * Renders tooltip for chart empty state
   *
   * @param properties Tooltip properties
   * @returns {JSX.Element} JSX snippet containing the tooltip
   */
  const renderTooltip = (properties): JSX.Element => {
    const label = getCurrencyString(Number(properties.data.key), true);
    const value = getFormattedNumber(properties.data.value, false);
    return (
      <div className="item">
        <span style={{ fontWeight: 800 }}>{label}</span>
        :&nbsp;
        <span
          style={{
            fontWeight: 800,
            color: properties.color,
          }}
        >
          {value}
        </span>
      </div>
    );
  };

  const { data, meta } = barChartEmptyDummyData;

  const dataTransformed = data.map((item) => ({
    ...item,
    key: item.key.toString(),
  }));

  return (
    <>
      <NivoResponsiveBar
        // @ts-ignore
        data={dataTransformed}
        keys={['value']}
        maxValue={meta.maxY}
        indexBy="key"
        margin={{ top: 30, right: 0, bottom: 70, left: 75 }}
        padding={getPadding(data.length)}
        colors={insightsColors.insightSecondary}
        axisTop={null}
        axisRight={null}
        enableGridX
        // @ts-ignore
        gridYValues={meta.yAxisGridValues}
        gridXValues={meta.xAxisGridValues}
        axisBottom={{
          tickRotation: 0,
          format: formatCompactCurrency,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 60,
        }}
        axisLeft={{
          tickRotation: 0,
          format: formatCompactNumber,
          legend: '',
          legendPosition: 'middle',
          legendOffset: -70,
          tickValues: meta.yAxisTickValues,
        }}
        isInteractive
        tooltip={renderTooltip}
        enableLabel={false}
        /* in order for the ReactTooltip to work animation must be disabled */
        animate
        motionStiffness={90}
        motionDamping={15}
        theme={{
          background: 'white',
          axis: {
            legend: {
              text: {
                fontSize: '11px',
                fontFamily: 'Montserrat',
                fontWeight: 600,
                fill: insightsColors.insightPrimary,
              },
            },
            ticks: {
              text: {
                fontSize: '11px',
                fontFamily: 'Montserrat',
                fontWeight: 600,
                fill: insightsColors.insightGray,
                fontStyle: 'normal',
                lineHeight: '18px',
              },
              line: {
                stroke: insightsColors.insightLightGray,
              },
            },
          },
          tooltip: {
            container: {
              background: '#ffffff',
              border: `solid 1px ${insightsColors.insightLightGray}`,
              borderRadius: '4px',
              fontFamily: 'Montserrat',
              color: insightsColors.insightGray,
              padding: '8px 24px',
              fontSize: '11px',
              lineHeight: '18px',
            },
          },
          grid: {
            line: {
              stroke: insightsColors.insightLightGray,
              strokeWidth: 1,
              strokeDasharray: '4 4',
            },
          },
        }}
      />
      <ChartError error={new Error()} styles={{ top: '45%' }} />
    </>
  );
};

export default BarChartEmpty;
