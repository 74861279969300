const LabelKeysGA = {
  Projects: 'Projects',
  GroupAge: 'GroupAge',
  BackupStatus: 'BackupStatus',
  DateRange: 'DateRange',
  GroupStatus: 'GroupStatus',

  Count: 'Count',
  Type: 'Type',
  Success: 'Success',
  Fail: 'Fail',

  GETTING_STARTED_HAVE_GROUPS: 'HaveGroupsWithDreamsaveApp',
  GETTING_STARTED_NO_GROUPS: 'NoGroupsWithDreamsaveApp',

  GROUP_AGE_NONE: 'All',
  GROUP_AGE_ONE: '1stCycle',
  GROUP_AGE_TWO: '2ndCycle',
  GROUP_AGE_THREE_OR_MORE: '3+Cycle',

  BACKUP_STATUS_NONE: 'All',
  BACKUP_STATUS_NEVER: 'Never',
  BACKUP_STATUS_ALL_MEETINGS: 'AllMeetingsBackedUp',
  BACKUP_STATUS_ONE_MEETING_NOT_BACKED_UP: '1MeetingNotBackedUp',
  BACKUP_STATUS_TWO_OR_MORE_MEETINGS_NOT_BACKED_UP: '2+MeetingsNotBackedUp',

  PRE_LENDING: 'PreLending',
  ACTIVELY_LENDING: 'ActivelyLending',
  POST_LENDING: 'PostLending',
  NONE: 'AllGroups',

  PREVIOUS_MONTH: 'PreviousMonth',
  PREVIOUS_QUARTER: 'PreviousQuarter',
  PREVIOUS_YEAR: 'PreviousYear',

  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',

  cyclePhase: 'CyclePhase',
  timeUnit: 'TimeUnit',
  frequency: 'Frequency',

  CUMULATIVE_SAVINGS: 'CumulativeSavings',
  AVERAGE_SAVINGS_PER_MEMBER: 'AverageSavingsPerMember',
  LOAN_FUND_UTILIZATION: 'LoanFundUtilizationRate',

  GROUP_METRICS_CUMULATIVE_SAVINGS_ANNUALIZED: 'CumulativeSavingsAnnualized',
  GROUP_METRICS_AVERAGE_SAVINGS_PER_MEMBER: 'AverageSavingsPerMemberAnnualized',

  GROUPS: 'GroupsFormed',
  MEMBERS: 'NumberOfMembers',
  ATTENDANCE: 'OverallAttendance',
  DROPOUT: 'DropoutVsInactivityRate',

  WIDGET_AGE: 'Age',
  WIDGET_AVERAGE_SAVINGS_PER_MEMBER: 'AverageSavingsPerMember',
  WIDGET_CUMULATIVE_SAVINGS: 'CumulativeSavings',
  WIDGET_EDUCATION: 'Education',
  WIDGET_GENDER: 'Gender',
  WIDGET_PERSONAL_GOAL_ACHIEVEMENT: 'PersonalGoalAchievement',
  WIDGET_GROUPS_FORMED: 'GroupsFormed',
  WIDGET_LOAN_FUND_UTILIZATION: 'LoanFundUtilization',
  WIDGET_PERSONAL_GOAL_TYPES: 'PersonalGoalTypes',
  WIDGET_LOAN_TYPES: 'LoanTypes',
  WIDGET_SAVINGS_LEADER_BOARD: 'SavingsLeaderBoard',
  WIDGET_DREAM_STAR_LEADER_BOARD: 'DreamStarLeaderBoard',
  WIDGET_PLACEHOLDER: 'Placeholder',

  LOAN_FUND_BALANCE: 'LoanFundBalance',
  LOAN_FUND_OUTSTANDING: 'LoanFundOutstanding',
  RETURN_ON_SAVINGS: 'ReturnOnSavings',
  AVERAGE_NO_OF_LOANS_GIVEN: 'AverageNumOfLoansGiven',
  PORTFOLIO_AT_RISK: 'PortfolioAtRisk',
  PERCENTAGE_OF_MEMBERS_TAKEN_LOANS: 'PercentageOfMembersWhoHaveTakenALoan',
  LOAN_REPAYMENT_RATE: 'LoanRepaymentRate',
  SOCIAL_FUND_BALANCE: 'SocialFundBalance',
  ESTIMATED_GROUP_SHAREOUT: 'EstimatedGroupShareOut',
  NET_PROFIT: 'NetProfit',

  FINANCES_LOAN_FUND_BALANCE: 'LoanFundBalance',
  FINANCES_LOAN_FUND_OUTSTANDING: 'ValueOfLoansOutstanding',
  FINANCES_CUMULATIVE_SAVINGS: 'CumulativeSavings',
  FINANCES_AVERAGE_SAVINGS_PER_MEMBER: 'AverageSavingsPerMember',
  FINANCES_LOAN_FUND_UTILIZATION: 'LoanFundUtilization',
  FINANCES_RETURN_ON_SAVINGS: 'ReturnOnSavings',
  FINANCES_AVERAGE_NO_OF_LOANS_GIVEN: 'Average#OfLoansGiven',
  FINANCES_PORTFOLIO_AT_RISK: 'PortfolioAtRisk',
  FINANCES_PERCENTAGE_OF_MEMBERS_TAKEN_LOANS: '%OfMembersWhoHaveTakenALoan',
  FINANCES_LOAN_REPAYMENT_RATE: 'LoanRepaymentRate',
  FINANCES_SOCIAL_FUND_BALANCE: 'SocialFundBalance',
  FINANCES_ESTIMATED_GROUP_SHAREOUT: 'EstimatedGroupShareOut',
  FINANCES_NET_PROFIT: 'NetProfit',

  name: 'GroupName',
  number: 'GroupId',
  cumulativeSavings: 'CumulativeSavings',
  attendanceRate: 'AttendanceInPerson',
  numberOfMembers: 'NumberOfMembers',
  lastMeeting: 'LastMeeting',
  backupStatus: 'BackupStatus',
  dropoutRate: 'DropoutRate',
  membershipGrowthRate: 'MembershipGrowthRate',
  meetingFrequency: 'MeetingFrequency',
  attendanceRateWithRep: 'AttendanceWithRep',
  personalGoalAchievement: 'PersonalGoalAchievement',
  groupGoalAchievement: 'GroupGoalAchievement',
  inactivityRate: 'InactivityRate',

  GROUP_DETAILS_LOAN_FUND_BALANCE: 'LoanFundBalance',
  GROUP_DETAILS_LOAN_FUND_OUTSTANDING: 'ValueOfLoansOutstanding',
  GROUP_DETAILS_CUMULATIVE_SAVINGS: 'CumulativeSavings',
  GROUP_DETAILS_AVERAGE_SAVINGS_PER_MEMBER: 'AverageSavingsPerMember',
  GROUP_DETAILS_LOAN_FUND_UTILIZATION: 'LoanFundUtilization',
  GROUP_DETAILS_RETURN_ON_SAVINGS: 'ReturnOnSavings',
  GROUP_DETAILS_AVERAGE_NO_OF_LOANS_GIVEN: 'NumberOfLoansGiven',
  GROUP_DETAILS_PORTFOLIO_AT_RISK: 'PortfolioAtRisk',
  GROUP_DETAILS_PERCENTAGE_OF_MEMBERS_TAKEN_LOANS:
    '%OfMembersWhoHaveTakenALoan',
  GROUP_DETAILS_LOAN_REPAYMENT_RATE: 'LoanRepaymentRate',
  GROUP_DETAILS_SOCIAL_FUND_BALANCE: 'SocialFundBalance',
  GROUP_DETAILS_ESTIMATED_GROUP_SHAREOUT: 'EstimatedGroupShareOut',
  GROUP_DETAILS_NET_PROFIT: 'NetProfit',

  PROJECT_TYPE_PRACTICE: 'Practice Project',
  PROJECT_TYPE_REAL: 'Real Project',
};

export default LabelKeysGA;
