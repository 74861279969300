import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import DummyRowProps from './DummyRowProps';

const DummyRow: ReactFC<DummyRowProps> = (props: DummyRowProps) => {
  const { headers, isError } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [checkbox, name, ...headersList] = headers[0].headers; // nosonar

  return (
    <tr>
      <td className="no-bg checkbox-td">
        <label className="insight-checkbox">
          <input type="checkbox" disabled />
          <i className="icon-check" />
        </label>
      </td>
      <td data-title="Groups" className="text-bold">
        {isError ? intl.get('LBL_NA') : intl.get('LBL_NO_GROUPS_ADDED')}
      </td>
      {headersList.map((cell) => (
        <td
          key={cell.id}
          className={cell.className}
          data-title={cell.dataTitle}
        >
          {isError ? (
            intl.get('LBL_NA')
          ) : (
            <>
              {cell.id === 'lastMeeting'
                ? '—'
                : cell.render('Cell', { value: 0 })}
            </>
          )}
        </td>
      ))}
    </tr>
  );
};

export default DummyRow;
