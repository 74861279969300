/* eslint-disable */
import Feature from 'api/auth/types/user/Feature';
import StorageKeys from 'constants/StorageKeys';
import StorageService from './StorageService';

class FeatureStorageService extends StorageService {
  /**
   * Gets currently logged-in user's accessible features from local storage
   *
   * @returns {Array<Feature>} Features list
   */
  static GetUserFeatures = (): Array<Feature> =>
    this.GetItem<Array<Feature>>(StorageKeys.Features);
}

export default FeatureStorageService;
