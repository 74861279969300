/* eslint-disable no-nested-ternary */
import { CSSProperties, useRef, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import Select, { StylesConfig } from 'react-select';

import { insightsColors } from 'helpers/ColorHelper';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ImageComponent from 'shared/components/image/ImageComponent';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import CountriesSelectMenuList from './countries-select-menu-list/CountriesSelectMenuList';
import ExistingUserListItemProps from './ExistingUserListItemProps';

const ExistingUserListItem: ReactFC<ExistingUserListItemProps> = (
  props: ExistingUserListItemProps
) => {
  const { user, isNewlyAdded, removeNewUserFromList } = props;
  const menuListRef = useRef<HTMLDivElement | null>(null);
  const countryOptions: Array<SelectFieldOption> = user.countryDataAccess?.map(
    (country) => ({
      value: country,
      label: country,
      isDisabled: true,
    })
  );

  const customStyles: StylesConfig = {
    option: (base: CSSProperties) => ({
      ...base,
      color: insightsColors.insightGray,
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
      ':hover': {
        backgroundColor: insightsColors.insightMildYellow,
      },
      backgroundColor: 'none',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const handleCloseMenuOnScroll = (event: Event): boolean =>
    event.target !== menuListRef.current;

  const countryPlaceholder =
    user.countryDataAccess.length > 0
      ? user.countryDataAccess.join(', ')
      : intl.get('LBL_NONE');

  const handleRemoveUser = (): void => removeNewUserFromList(user.userId);

  return (
    <tr>
      <td>
        {isNewlyAdded && (
          <button
            className="btn btn-close shadow-none p-0"
            type="button"
            onClick={handleRemoveUser}
          >
            <i className="icon-close" />
          </button>
        )}
      </td>
      <td className="truncate-long-words">
        <div className="d-flex align-items-center">
          <ImageComponent
            className="rounded-circle object-fit-cover"
            ignoreBlur
            src={user.image}
            alt={user.name}
            fallbackSrc={blankAvatar}
            width={25}
            height={25}
          />
          <EllipsisTooltip
            tag="span"
            data-place="bottom"
            data-for="insTooltip"
            data-tip={user.name}
            data-class="overflow-wrap bring-it-up"
            className="table-content extend-length text-12-bold ml-3"
          >
            {user.name}
          </EllipsisTooltip>
        </div>
      </td>
      <td className="truncate-long-words">
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-tip={user.permissionLevel ?? intl.get('LBL_NA')}
          data-class="overflow-wrap bring-it-up"
          className="table-content text-12-bold"
        >
          {user.permissionLevel ?? intl.get('LBL_NA')}
        </EllipsisTooltip>
      </td>
      <td>
        <div className="insight-select-group">
          <Select
            className="expand-height size-down"
            isSearchable={false}
            options={countryOptions}
            menuPortalTarget={document.body}
            placeholder={countryPlaceholder}
            menuPosition="fixed"
            closeMenuOnScroll={handleCloseMenuOnScroll}
            menuListRef={menuListRef}
            isDisabled={!user.countryDataAccess}
            name="countries"
            styles={customStyles}
            classNamePrefix="insight-select insight-select-projects"
            components={{ MenuList: CountriesSelectMenuList }}
          />
        </div>
      </td>
    </tr>
  );
};

export default ExistingUserListItem;
