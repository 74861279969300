class UserCurrentProject {
  projectId: string;

  name: string;

  code: string;

  startDate?: string;

  endDate?: string;

  constructor() {
    this.projectId = '';
    this.name = '';
    this.code = '';
    this.startDate = undefined;
    this.endDate = undefined;
  }
}

export default UserCurrentProject;
