import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

const QuartilesTBodyEmpty: ReactFC = () => (
  <tbody>
    <tr key="UPPER_QUARTILE">
      <td>{intl.get('LBL_UPPER_QUARTILE')}</td>
      <td className="value">{intl.get('LBL_NA')}</td>
      <td className="value">
        <span>{intl.get('LBL_NA')}</span>
      </td>
    </tr>
    <tr key="MEDIAN_QUARTILE">
      <td>{intl.get('LBL_MEDIAN_QUARTILE')}</td>
      <td className="value">{intl.get('LBL_NA')}</td>
      <td className="value">
        <span>{intl.get('LBL_NA')}</span>
      </td>
    </tr>
    <tr key="LOWER_QUARTILE">
      <td>{intl.get('LBL_LOWER_QUARTILE')}</td>
      <td className="value">{intl.get('LBL_NA')}</td>
      <td className="value">
        <span>{intl.get('LBL_NA')}</span>
      </td>
    </tr>
  </tbody>
);

export default QuartilesTBodyEmpty;
