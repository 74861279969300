import { FC as ReactFC } from 'react';

import isEmpty from 'lodash/isEmpty';

import InlineEditJobRoleSelect from '../inline-edit-job-role-select/InlineEditJobRoleSelect';
import JobRoleCellProps from './JobRoleCellProps';

const JobRoleCell: ReactFC<JobRoleCellProps> = (props: JobRoleCellProps) => {
  const {
    editMode,
    selectedRows,
    jobRoles,
    jobRolesStatus,
    createJobRoleStatus,
    lastJobRoleEditedRowId,
    lastCreatedJobRoleId,
    createJobRoleError,
    onCreateJobRole,
    onUpdateUser,
    clearJobRoleError,
    ...cellProps
  } = props;

  if (editMode && isEmpty(selectedRows)) {
    return (
      <InlineEditJobRoleSelect
        cellProps={cellProps}
        jobRoles={jobRoles}
        jobRolesStatus={jobRolesStatus}
        createJobRoleStatus={createJobRoleStatus}
        lastCreatedJobRoleId={lastCreatedJobRoleId}
        lastJobRoleEditedRowId={lastJobRoleEditedRowId}
        createJobRoleError={createJobRoleError}
        onCreateJobRole={onCreateJobRole}
        onUpdateUser={onUpdateUser}
        clearJobRoleError={clearJobRoleError}
      />
    );
  }
  return cellProps.value;
};

export default JobRoleCell;
