import * as localStorage from 'local-storage';

class StorageService {
  static GetItem = <T>(key: string): T => localStorage.get<T>(key);

  static SetItem = <T>(key: string, data: T): boolean =>
    localStorage.set<T>(key, data);

  static GetItemFromSession = (key: string): string | null =>
    sessionStorage.getItem(key);

  static SetItemToSession = (key: string, data: string): void =>
    sessionStorage.setItem(key, data);

  static RemoveItem = (key: string): void => {
    localStorage.remove(key);
  };

  static Clear = (): void => {
    localStorage.clear();
  };
}

export default StorageService;
