import * as intl from 'react-intl-universal';
import * as yup from 'yup';

class ProjectInfoValidations {
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema = yup.object().shape({
      name: yup
        .string()
        .trim()
        .required(intl.get('ERR_PROJECTS_PROJECT_NAME_CANNOT_BE_EMPTY')),
      isTestProject: yup.boolean().required(),
    });

    return validationSchema;
  }
}

export default ProjectInfoValidations;
