class ProjectStatsViewModel {
  userId: string;

  projectCount: number;

  showUserTooltip: boolean;

  showFacilitatorTooltip: boolean;

  showGroupTooltip: boolean;

  constructor(
    assignUser: boolean,
    assignFacilitator: boolean,
    assignGroup: boolean,
    userId?: string,
    projectCount?: number
  ) {
    this.showUserTooltip = !assignUser;
    this.showFacilitatorTooltip = !assignFacilitator;
    this.showGroupTooltip = !assignGroup;
    this.userId = userId ?? '';
    this.projectCount = projectCount ?? -99;
  }
}

export default ProjectStatsViewModel;
