import * as intl from 'react-intl-universal';
import * as yup from 'yup';

import RegisterFormValues from './RegisterFormValues';

class RegisterFormValidation {
  public static GetValidationSchema(
    step: number
  ): yup.ObjectSchema<Partial<RegisterFormValues>> | undefined {
    let validationSchema:
      | yup.ObjectSchema<Partial<RegisterFormValues>>
      | undefined;

    const passwordRegex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      `^(?=.*\\d)(?=.*[!"#$%&'()*+,./:;<=>?@\^_{|}~-])(?=.*[a-z])(?=.*[A-Z]).{4,}$`
    );

    switch (step) {
      case 1:
        validationSchema = yup.object().shape({
          firstName: yup
            .string()
            .trim()
            .required(intl.get('ERR_REG_FORM_FIRST_NAME_CANNOT_BE_EMPTY')),
          lastName: yup
            .string()
            .trim()
            .required(intl.get('ERR_REG_FORM_LAST_NAME_CANNOT_BE_EMPTY')),
          jobTitle: yup
            .string()
            .trim()
            .required(intl.get('ERR_REG_FORM_JOB_TITLE_CANNOT_BE_EMPTY')),
          organizationName: yup
            .string()
            .trim()
            .required(intl.get('ERR_REG_FORM_ORGANIZATION_CANNOT_BE_EMPTY')),
        });
        break;
      case 2:
        validationSchema = yup.object().shape({
          email: yup
            .string()
            .trim()
            .required(intl.get('ERR_FORM_EMAIL_REQUIRED_MESSAGE'))
            .email(intl.get('ERR_FORM_EMAIL_INVALID_MESSAGE')),
          password: yup
            .string()
            .required(intl.get('ERR_FORM_PASSWORD_REQUIRED_MESSAGE'))
            .matches(
              passwordRegex,
              intl.get('ERR_REG_FORM_PASSWORD_STRENGTH_MESSAGE')
            ),
          confirmedPassword: yup
            .string()
            // .oneOf(
            //   [yup.ref('password'), null],
            //   intl.get('ERR_REG_FORM_CONFIRM_PW_MUST_MATCH')
            // )
            .required(intl.get('ERR_REG_FORM_CONFIRM_PW_CANNOT_BE_EMPTY'))
            .test(
              'oneOf',
              intl.get('ERR_REG_FORM_CONFIRM_PW_MUST_MATCH'),
              function oneOf(value): boolean | yup.ValidationError {
                const passwordMatches = passwordRegex.test(
                  this.parent.password
                );
                if (passwordMatches) {
                  return value === this.parent.password || value === null;
                }
                return true;
              }
            ),
          termsAccepted: yup
            .boolean()
            .required(intl.get('ERR_REG_FORM_TERMS_SHOULD_ACCEPT'))
            .oneOf([true], intl.get('ERR_REG_FORM_TERMS_SHOULD_ACCEPT')),
        });
        break;
      case 3:
        validationSchema = yup.object().shape({
          partnerId: yup
            .string()
            .length(4, intl.get('ERR_REG_FORM_PARTNER_ID_LENGTH_MESSAGE'))
            .required(intl.get('ERR_REG_FORM_PARTNER_ID_CANNOT_BE_EMPTY')),
        });
        break;
      default:
        break;
    }
    return validationSchema;
  }
}

export default RegisterFormValidation;
