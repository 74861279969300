import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { Col, Row, Button, Collapse } from 'reactstrap';

import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import history from 'router-history';

import InviteUsersProps from './InviteUsersProps';

const InviteUsers: ReactFC<InviteUsersProps> = (props: InviteUsersProps) => {
  const { order, stepCompleted, active, toggle, onClosed } = props;
  const location = useLocation();
  /**
   * Handles inviting users through the getting started modal
   */
  const handleInviteClick = (): void => {
    /** onClose needs to be called on navigation out since the getting
     * started tooltip need to render again give that getting started modal
     * is closed
     */
    onClosed();
    history.push({
      pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
      search: getGlobalFiltersQuery(location.search),
    });

    sendEventGA(
      CategoryKeysGA.GettingStartedInviteUsers,
      ActionKeysGA.InviteNewUsers
    );
  };

  return (
    <Col xs="12" data-cy={`gs-collapse-${order}`}>
      {active !== order && (
        <Row
          onClick={(): void => toggle(order)}
          className="collapse-header"
          data-cy={`gs-head-${order}`}
        >
          <Col xs="auto">
            {stepCompleted ? (
              <span className="check">
                <i className="icon-check" />
              </span>
            ) : (
              <span className="number">{order}</span>
            )}
          </Col>
          <Col className="text-14-semibold">
            {intl.get('LBL_GSG_INVITE_USERS')}
          </Col>
        </Row>
      )}
      <Collapse isOpen={active === order}>
        <Row className="collapse-body">
          <Col xs="auto">
            {stepCompleted ? (
              <span className="check">
                <i className="icon-check" />
              </span>
            ) : (
              <span className="number">{order}</span>
            )}
          </Col>
          <Col className="col-9">
            {active === order && (
              <p className="text-14-semibold">
                {intl.get('LBL_GSG_INVITE_USERS')}
              </p>
            )}
            <p className="text-14-medium mb-0">
              {intl.get('LBL_GSG_INVITE_USERS_TEXT')}
            </p>
          </Col>
          <Col className="col-2">
            <Button size="sm" color="primary" onClick={handleInviteClick}>
              {intl.get('BTN_GSG_INVITE_USERS_INVITE')}
            </Button>
          </Col>
        </Row>
      </Collapse>
    </Col>
  );
};

export default InviteUsers;
