import { useEffect, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Col, Popover, PopoverBody, Row } from 'reactstrap';

import PopperResizeContent from 'shared/components/hoc/popper-resize-content/PopperResizeContent';
import clearIcon from 'shared/static/img/ic_x.svg';

import CollectIdStatusGuidePopoverProps from './CollectIdStatusGuidePopoverProps';

const CollectIdStatusGuidePopover: ReactFC<CollectIdStatusGuidePopoverProps> =
  ({
    elementRef,
    popoverPlacement,
    popoverClass,
    containerRef,
    showCollectIdGuideTooltip,
  }) => {
    const [showPopover, setShowPopover] = useState(false);

    useEffect(() => {
      if (elementRef && elementRef.current) {
        setShowPopover(!showPopover && showCollectIdGuideTooltip);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCollectIdGuideTooltip, elementRef]);

    return (
      <Popover
        isOpen={showPopover}
        placement={popoverPlacement}
        container={containerRef}
        hideArrow
        modifiers={{
          preventOverflow: { enabled: false },
          flip: { enabled: false },
        }}
        popperClassName={`survey-popper ${String(popoverClass)}`}
        target={elementRef}
        delay={{ show: 500, hide: 100 }}
        boundariesElement="window"
      >
        {({ scheduleUpdate }): JSX.Element => (
          <PopperResizeContent
            observeElement={elementRef}
            onResize={scheduleUpdate}
          >
            <PopoverBody>
              <div>
                <div className="close-container">
                  <button
                    className="insight-close-button enlarge-icon"
                    type="button"
                    onClick={(): void => {
                      setShowPopover(false);
                    }}
                  >
                    <img src={clearIcon} alt="Close" />
                  </button>
                </div>
                <Row>
                  <Col className="text-14-medium text-gray text-center py-2 px-0">
                    <span>
                      {intl.getHTML(
                        'LBL_SURVEYS_SURVEY_HISTORY_SURVEY_STATUS_ON_GUIDE'
                      )}
                    </span>
                    <br />
                    <br />
                    <span>
                      {intl.getHTML(
                        'LBL_SURVEYS_SURVEY_HISTORY_SURVEY_STATUS_OFF_GUIDE'
                      )}
                    </span>
                  </Col>
                </Row>
              </div>
            </PopoverBody>
          </PopperResizeContent>
        )}
      </Popover>
    );
  };

export default CollectIdStatusGuidePopover;
