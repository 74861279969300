const ModulePaths = {
  RootPath: '/',
  /* Dashboard Paths */
  DashboardPath: '/dashboard',
  DashboardGettingStartedPath: '/getting-started',
  /* Finances Paths */
  FinancesPath: '/finances',
  /* Groups Paths */
  GroupsPath: '/groups',
  GroupDetailsPath: '/:groupNumber/details',
  /* Facilitators Paths */
  FacilitatorsPath: '/facilitators',
  /* Projects Paths */
  ProjectsPath: '/projects',
  ProjectAddUsersIdPath: '/:projectId/add-users',
  ProjectAddUsersPath: '/add-users',
  ProjectUsersPath: '/users',
  ProjectUsersIdPath: '/:projectId/users',
  ProjectFacilitatorsPath: '/facilitators',
  ProjectFacilitatorsIdPath: '/:projectId/facilitators',
  ProjectAddFacilitatorsIdPath: '/:projectId/add-facilitators',
  ProjectAddFacilitatorsPath: '/add-facilitators',
  ProjectCreateEditPath: '/:type(create|edit)/:projectId?',
  ProjectCreatePath: '/create',
  ProjectEditPath: '/edit/',
  ProjectEditIdPath: '/edit/:projectId',
  /* Settings Paths */
  SettingsParamPath: '/settings/:settingsTab(org|users|permissions|activity)?',
  SettingsPath: '/settings',
  SettingsOrgPath: '/org',
  SettingsUsersPath: '/users',
  SettingsPermissionsPath: '/permissions',
  SettingsActivityPath: '/activity',
  SettingsUsersInvitePath: '/invite',
  SettingsGettingStartedPath: '/getting-started',
  SettingsUserProfilePath: '/users/:userId',
  /* Messaging Paths */
  MessagingPath: '/messaging',
  MessageComposePath: '/messaging/compose',
  /* Surveys Paths */
  SurveysParamPath: '/surveys/:surveysTab(draft|history)?',
  SurveysPath: '/surveys',
  SurveysDraftPath: '/draft',
  SurveysHistoryPath: '/history',
  /* Auth Paths */
  AuthPath: '/auth',
  AuthLoginPath: '/login',
  AuthCreateProfilePath: '/create-profile',
  AuthForgotPasswordPath: '/forgot-password',
  AuthNewPasswordPath: '/new-password',
  AuthSignUpPath: '/sign-up',
  AuthCreateAccountPath: '/create-account',
  AuthVerifyAccountPath: '/verify-account',
  /* Well known Paths */
  WellKnownChangePasswordPath: '/.well-known/change-password',
};

export default ModulePaths;
