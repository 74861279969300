import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import styles from '../../messageHistory.module.scss';
import stylesRow from '../messageHistoryDataTable.module.scss';
import DummyRowProps from './DummyRowProps';

const DummyRow: ReactFC<DummyRowProps> = (props: DummyRowProps) => {
  const { headers } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const headersList = headers[0].headers; // nosonar

  /**
   * Renders action buttons
   *
   * @param row row props from react-table
   * @returns JSX.ELement action buttons
   */
  const renderActions = (): JSX.Element => (
    <div className={styles.actions}>
      <button disabled>
        <i className="icon-list" />
        {intl.get('BTN_MESSAGING_HISTORY_VIEW')}
      </button>
      <button disabled>
        <i
          className="icon-copy"
          style={{ fontSize: '18px', lineHeight: '24px' }} // TODO: Replace this icon. Get one from the designer
        />
        {intl.get('BTN_MESSAGING_HISTORY_DUPLICATE')}
      </button>
      <button disabled>
        <i className="icon-export" />
        {intl.get('BTN_MESSAGING_HISTORY_EXPORT')}
      </button>
    </div>
  );

  return (
    <tr>
      {headersList.map((cell) => (
        <td
          key={cell.id}
          className={cell.className}
          data-title={cell.dataTitle}
        >
          <span
            className={`table-content text-14-semibold extend-length ${stylesRow.wrapContent}`}
          >
            {cell.id === 'actions' ? renderActions() : intl.get('LBL_NA')}
          </span>
        </td>
      ))}
    </tr>
  );
};

export default DummyRow;
