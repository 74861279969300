const CollectIdsHistorySortableColumns: Set<string> = new Set([
  'name',
  'sendDate',
  'creator',
  'surveyType',
  'status',
  'receiverStats.targetedCount',
  'receiverStats.receivedCount',
]);

const CollectIdsHistoryTableConfigs = {
  defaultSort: 'sendDate',
  sortCols: CollectIdsHistorySortableColumns,
  defaultSortDesc: true,
};

export default CollectIdsHistoryTableConfigs;
