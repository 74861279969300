/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { components, MenuProps } from 'react-select';

import PermissionOption from '../PermissionOption';

const PermissionMenu: ReactFC<MenuProps<PermissionOption>> = (props) => {
  const { children } = props;
  return (
    <div id="select-menu-permission-id">
      <components.Menu {...props}>{children}</components.Menu>
    </div>
  );
};

export default PermissionMenu;
