/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { components, OptionProps } from 'react-select';
import { FormGroup, Input } from 'reactstrap';

import Option from '../NotificationFilterOption';
import styles from './notificationOption.module.scss';

const NotificationOption: ReactFC<OptionProps<Option>> = (
  props: OptionProps<Option>
) => {
  const { data, isSelected } = props;
  const { label, value } = data;

  return (
    <components.Option {...props}>
      <FormGroup className={`insight-checkbox-group ${styles.wrap}`}>
        <label>
          <span className="insight-checkbox mr-3">
            <Input
              type="checkbox"
              name={label}
              value={value}
              defaultChecked={isSelected}
            />
            <i className="icon-check" />
          </span>
          <span className="marquee-inner">{label}</span>
        </label>
      </FormGroup>
    </components.Option>
  );
};

export default NotificationOption;
