import { ReactNode, useRef, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer } from 'react-laag';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import GroupAge from 'shared/enums/GroupAge';

import { GroupAgeConfigs } from '../GlobalFilterConfigs';
import GroupAgeFilterSelectProps from './GroupAgeFilterSelectProps';

const GroupAgeFilterSelect: ReactFC<GroupAgeFilterSelectProps> = (
  props: GroupAgeFilterSelectProps
) => {
  const { appContext, globalFilters, onChange } = props;

  const { dashboardSetupInProgress /* globalFilters */ } = appContext;

  const parentRef = useRef<HTMLDivElement>(null);

  const groupAges = [
    {
      value: GroupAge.None,
      label: 'LBL_ALL',
    },
    {
      value: GroupAge.One,
      label: 'LBL_GF_FIRST_CYCLE',
    },
    {
      value: GroupAge.Two,
      label: 'LBL_GF_SECOND_CYCLE',
    },
    {
      value: GroupAge.ThreeOrMore,
      label: 'LBL_GF_THREE_OR_MORE_CYCLE',
    },
  ];

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
    close,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <div
        ref={layerProps.ref}
        className="layer insight-dropdown"
        style={{
          ...layerProps.style,
          left: '50%',
          transform: 'translateX(-50%)',
          top: '10px',
        }}
      >
        <Arrow
          style={arrowStyle}
          layerSide={layerSide}
          backgroundColor="#ffffff"
          borderWidth={1}
          borderColor="#dbdce1"
          roundness={0.7}
        />
        <Form>
          {groupAges.map(({ value, label }) => (
            <FormGroup key={value} className="insight-radio-group">
              <Label>
                <span className="insight-radio">
                  <Input
                    type="radio"
                    tabIndex={0}
                    name={`group-age-${value}`}
                    value={value}
                    onChange={(e): void => onChange(e, close)}
                    className={
                      globalFilters.groupAge === value ? 'active-radio' : ''
                    }
                  />
                  <i className="icon-radio" />
                </span>
                {intl.get(label)}
              </Label>
            </FormGroup>
          ))}
        </Form>
      </div>
    );

  return (
    <ToggleLayer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={parentRef.current!} // nosonar
      closeOnOutsideClick
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => (
        <>
          <label>{intl.get('LBL_GROUP_AGE')}</label>
          <EllipsisTooltip
            ref={triggerRef}
            className={`toggle-btn dropdown-btn ${
              isOpen ? 'dropdown-open' : ''
            }`}
            data-cy="group-gf"
            onClick={toggle}
            disabled={dashboardSetupInProgress}
            tag="button"
            data-place="bottom"
            data-for="insTooltip"
            data-tip={GroupAgeConfigs[globalFilters.groupAge]}
            data-class="overflow-wrap"
          >
            {GroupAgeConfigs[globalFilters.groupAge]}
          </EllipsisTooltip>
          <div
            ref={parentRef}
            id="group-age-parent"
            className="position-relative"
          />
        </>
      )}
    </ToggleLayer>
  );
};

export default GroupAgeFilterSelect;
