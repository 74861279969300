import enButtons from 'locales/en-US/en.buttons.json';
import enErrors from 'locales/en-US/en.errors.json';
import enTexts from 'locales/en-US/en.texts.json';

const enUS = {
  ...enButtons,
  ...enErrors,
  ...enTexts,
};

export default enUS;
