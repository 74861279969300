export type Feature = {
  name: string;
  text: string;
  enable: boolean;
  groupName: string;
  metaData?: { name: string; selected: boolean; inputType: InputType };
  editable: boolean;
};

export type InputTypeFeature = {
  name: string;
  type: InputType;
  items: Feature[];
};

export enum InputType {
  RADIO = 'radio',
}

export type PermissionItem = {
  features: Feature[];
  description: string;
  title: string;
  key: string;
  isDefault: boolean;
};

interface EditPermissionsListItemProps {
  active: string;
  handleEdit: (string) => void;
  handleCancel: () => void;
  permission: PermissionItem;
  canEditPermissions: boolean;
  defaultClaims: Omit<Feature, 'enable'>[];
  organizationId: string;
  onError: (text: string) => void;
  onEditSuccess: (permissionKey: string) => void;
  isModalView?: boolean;
  removeDummyItem: () => void;
}

export default EditPermissionsListItemProps;
