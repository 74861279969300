import { memo, useRef } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Button, Col, Progress, Row } from 'reactstrap';

import { formatDate, getProjectProgressPercentage } from 'helpers/DateFormat';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import DateFormatType from 'shared/enums/DateFormatType';

import UserProfileFieldWrapper from '../user-profile-left-panel/user-profile-field-wrapper/UserProfileFieldWrapper';
import { UserProfileFields } from '../user-profile-left-panel/UserProfileLeftPanelProps';
import UserCurrentProject from '../user-projects-pane/UserCurrentProject';
import ProjectListItemProps from './ProjectListItemProps';

const ProjectListItem: React.FC<ProjectListItemProps> = (
  props: ProjectListItemProps
) => {
  const {
    data: { list, isItemLoaded, restrictions, onRemoveProject },
    index,
    style,
  } = props;
  const { canEditProfile, isOwnProfile, isOwner } = restrictions;

  const project = list[index];
  const loaded = isItemLoaded(index);

  const rowRef = useRef<HTMLDivElement | null>(null);

  const currentProject: UserCurrentProject =
    project ?? new UserCurrentProject();

  const hasStartAndEndDates =
    currentProject.startDate && currentProject.endDate;

  const percentage = getProjectProgressPercentage(
    currentProject.startDate,
    currentProject.endDate
  );

  const startDateFormatted = formatDate(
    currentProject.startDate,
    DateFormatType.MonthYearDate
  );
  const endDateFormatted = formatDate(
    currentProject.endDate,
    DateFormatType.MonthYearDate
  );

  const maxPercentageReached = percentage === 100;

  const progressBarStyle = maxPercentageReached
    ? 'rgba(104,199,192,1)'
    : `linear-gradient(90deg, rgba(104,199,192,1) 0%, rgba(241,241,243,1) ${String(
        percentage
      )}%)`;

  return (
    <div
      style={{
        ...style,
        top: style.top ?? 0,
        height: hasStartAndEndDates ? style.height : Number(style.height) - 50,
      }}
      ref={rowRef}
    >
      <BlockUi blocking={!loaded} tag="div">
        <Row className="m-0  align-items-center mt-3">
          <Col className="p-0 mr-2">
            <Row
              className="d-flex m-0"
              style={{ display: 'flex', flexFlow: 'row wrap' }}
            >
              <Col xs="6" className="p-0">
                <EllipsisTooltip
                  tag="div"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-tip={
                    `${String(currentProject.name)} (#${String(
                      currentProject.code
                    )})` ?? intl.get('LBL_NA')
                  }
                  data-class="overflow-wrap bring-it-up"
                  className="text-14-medium truncate shorten"
                >
                  {`${String(currentProject.name)} (#${String(
                    currentProject.code
                  )})`}
                </EllipsisTooltip>
              </Col>
              <Col xs="6" className="p-0">
                {hasStartAndEndDates && (
                  <div className="text-12-bold text-uppercase color-secondary-dark">
                    {intl.get('LBL_USER_PROFILE_PROGRESS_COMPLETE', {
                      percentage,
                    })}
                  </div>
                )}
              </Col>
            </Row>

            <Progress
              style={{
                background: progressBarStyle,
                borderRadius: '100px',
                height: '14px',
                marginTop: '7px',
              }}
            />

            <div className="row d-flex justify-content-between m-0 mt-1">
              <EllipsisTooltip
                tag="div"
                data-place="bottom"
                data-for="insTooltip"
                data-tip={
                  currentProject.startDate
                    ? intl.get('LBL_USER_PROFILE_PROGRESS_START_DATE', {
                        startDate: startDateFormatted,
                      })
                    : intl.get('LBL_NA')
                }
                data-class="overflow-wrap bring-it-up"
                className="text-12-bold truncate shorten text-uppercase"
              >
                {currentProject.startDate
                  ? intl.get('LBL_USER_PROFILE_PROGRESS_START_DATE', {
                      startDate: startDateFormatted,
                    })
                  : intl.get('LBL_NA')}
              </EllipsisTooltip>
              <EllipsisTooltip
                tag="div"
                data-place="bottom"
                data-for="insTooltip"
                data-tip={
                  currentProject.endDate
                    ? intl.get('LBL_USER_PROFILE_PROGRESS_END_DATE', {
                        endDate: endDateFormatted,
                      })
                    : intl.get('LBL_NA')
                }
                data-class="overflow-wrap bring-it-up"
                className="text-12-bold truncate shorten text-uppercase"
              >
                {currentProject.endDate
                  ? intl.get('LBL_USER_PROFILE_PROGRESS_END_DATE', {
                      endDate: endDateFormatted,
                    })
                  : intl.get('LBL_NA')}
              </EllipsisTooltip>
            </div>
          </Col>
          <UserProfileFieldWrapper
            value=""
            fieldName={UserProfileFields.REMOVE_FROM_PROJECT}
            canEditProfile={canEditProfile}
            isOwnProfile={isOwnProfile}
            isOwner={isOwner}
            isNotInLeftPanel
          >
            <Col style={{ flexGrow: 0, flexShrink: 0, flexBasis: 100 }}>
              <Button
                type="button"
                className="btn btn-sm"
                onClick={() => onRemoveProject(project.projectId)}
              >
                {intl.get('BTN_REMOVE')}
              </Button>
            </Col>
          </UserProfileFieldWrapper>
        </Row>
      </BlockUi>
    </div>
  );
};

/** suggested by react-window to prevent unnecessary re-renders
 *  https://react-window.now.sh/#/api/FixedSizeList */
export default memo(ProjectListItem);
