import { useRef, useEffect } from 'react';

/**
 * used to compare against the previous version of a variable/props
 * in a way similar to the comparison inside componentDidUpdate
 * hook in a class component
 * @param value value to remember, typically a changing prop
 * @returns previous value
 */
const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as T;
};

export default usePrevious;
