/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { OptionProps } from 'react-select';

import TooltipOption from 'shared/components/tooltip-option/TooltipOption';

import FinancialMetricsChartTypeOption from '../FinancialMetricsChartTypeOption';
import styles from './chartTypeTooltipOption.module.scss';

const ChartTypeTooltipOption: ReactFC<
  OptionProps<FinancialMetricsChartTypeOption>
> = (props: OptionProps<FinancialMetricsChartTypeOption>) => {
  const { data, selectProps } = props;

  const { tooltipAnchor } = selectProps;

  return (
    <TooltipOption
      {...props}
      anchor={tooltipAnchor}
      tooltipBody={
        <div className={`text-14-medium ${styles.adjustTooltipBody}`}>
          <div className="overflow-wrap">
            <span className={styles.hintColor}>{data.hint}</span>
          </div>
        </div>
      }
    />
  );
};

export default ChartTypeTooltipOption;
