import GrantKeys from 'constants/permissions/GrantKeys';
import ResourceKeys from 'constants/permissions/ResourceKeys';

class PermissionUtil {
  private static rules = {
    [ResourceKeys.SettingMyOrgBillingAndPayment]: {
      view: [GrantKeys.BillingAndPaymentViewEdit],
    },
    [ResourceKeys.SettingsMyOrgMyPlan]: { view: [GrantKeys.MyPlanViewEdit] },
    [ResourceKeys.SettingsMyOrgOrgName]: { view: [GrantKeys.OrgNameView] },
    [ResourceKeys.SettingsMyOrgLinkedSavingsGroups]: {
      view: [GrantKeys.OrgSavingsGroupView],
    },
    [ResourceKeys.SettingsMyOrgDefaultCurrency]: {
      view: [GrantKeys.OrgDefaultCurrencyEdit],
    },
    [ResourceKeys.SettingsMyOrgImplementingPartners]: {
      view: [GrantKeys.OrgImplementingPartnerViewEdit],
    },
    [ResourceKeys.SettingsMyOrgGroupStatus]: {
      view: [GrantKeys.OrgGroupStatusEdit],
    },
    [ResourceKeys.SettingsPermissionsAddNewLevel]: {
      view: [GrantKeys.PermissionAdd],
    },
    [ResourceKeys.SettingsPermissionsItemEdit]: {
      view: [GrantKeys.PermissionEdit],
    },
    [ResourceKeys.SettingsActivityFeed]: { view: [GrantKeys.ActivityLogView] },
    [ResourceKeys.DashboardGettingStartedSettingUpGroupData]: {
      view: [GrantKeys.SetUpManualView],
    },
    [ResourceKeys.DashboardGettingStartedInviteUsers]: {
      view: [GrantKeys.UserInvite],
    },
    [ResourceKeys.SettingsUsers]: { view: [GrantKeys.UserView] },
    [ResourceKeys.SettingsUsersInviteUsers]: { view: [GrantKeys.UserInvite] },
    [ResourceKeys.UserProfileEdit]: { view: [GrantKeys.UserEdit] },
    [ResourceKeys.ProjectsUsersEdit]: { view: [GrantKeys.UserEdit] },
    [ResourceKeys.UserProfileDeactivate]: { view: [GrantKeys.UserDeactivate] },
    [ResourceKeys.DashboardGettingStartedCreateNewProject]: {
      view: [GrantKeys.ProjectCreate],
    },
    [ResourceKeys.ProjectsView]: { view: [GrantKeys.ProjectView] },
    [ResourceKeys.ProjectsCreateNewProject]: {
      view: [GrantKeys.ProjectCreate],
    },
    [ResourceKeys.ProjectsProjectCodeEdit]: {
      view: [GrantKeys.ProjectCodeEdit],
    },
    [ResourceKeys.ProjectsItemEdit]: { view: [GrantKeys.ProjectDetailsEdit] },
    [ResourceKeys.ProjectsItemAddUsers]: {
      view: [GrantKeys.ProjectUsersAssign],
    },
    [ResourceKeys.ProjectsItemAddFacilitators]: {
      view: [GrantKeys.FacilitatorCreate],
    },
    [ResourceKeys.FacilitatorProfileEdit]: {
      view: [GrantKeys.FacilitatorUpdate],
    },
    [ResourceKeys.ProjectsFacilitatorsEdit]: {
      view: [GrantKeys.FacilitatorUpdate],
    },
    [ResourceKeys.FacilitatorProfileDeactivate]: {
      view: [GrantKeys.FacilitatorDeactivate],
    },
    [ResourceKeys.GroupsAssignToFacilitator]: {
      view: [GrantKeys.FacilitatorGroupsAssign],
    },
    [ResourceKeys.GroupAssignGroupStatus]: {
      view: [GrantKeys.GroupAssignGroupStatus],
    },
    [ResourceKeys.GroupsGroupStatus]: {
      view: [GrantKeys.GroupAssignGroupStatus],
    },
    [ResourceKeys.ExportCSV]: { view: [GrantKeys.ExportCSV] },
    [ResourceKeys.ExportPDF]: { view: [GrantKeys.ExportPDF] },
    [ResourceKeys.MessagingCompose]: {
      view: [GrantKeys.MessagingCompose],
    },
    [ResourceKeys.SurveysCreate]: { view: [GrantKeys.SurveysCreate] },
    [ResourceKeys.SurveysCollectAdditionalID]: {
      view: [GrantKeys.SurveysCollectAdditionalID],
    },
  };

  /**
   * Check if user has permission to access a resource in the application
   *
   * @param grants List of user's grants
   * @param resource Resource to be accessed
   * @param action Action to be performed on resource. Default is "view"
   * @returns {boolean} Whether permission is granted or not
   */
  // prettier-ignore
  public static Can(grants: string[], resource: string, action = 'view'): boolean {
    try {
      const permission = PermissionUtil.rules[resource][action];
      const checkedGrants = permission.map((grant) => grants.includes(grant));
      return checkedGrants.every((can: boolean) => can === true);
    } catch (error) {
      return false;
    }
  }
}

export default PermissionUtil;
