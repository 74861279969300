/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
import { Component } from 'react';

import axios from 'axios';
import { Formik, FormikHelpers } from 'formik';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Modal } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import UserResponse from 'api/auth/responses/UserResponse';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import ModulePaths from 'constants/ModulePaths';
import TIMEOUTS from 'constants/Timeouts';
import {
  ErrorStatus,
  getErrorStatus,
  getFirstError,
} from 'helpers/ErrorFormat';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import CreateProfileFormBody from 'modules/public/auth/components/create-profile-form-body/CreateProfileFormBody';
import CreateProfileVerification from 'modules/public/auth/components/create-profile-verification/CreateProfileVerification';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import ModalBackground from 'shared/components/hoc/dummy-background/ModalBackground';
import RedirectHandler from 'shared/components/hoc/redirect-handler/RedirectHandler';
import Status from 'shared/enums/Status';

import CreateProfileValidation from './CreateProfileValidation';
import CreateProfileViewModel from './CreateProfileViewModel';
import CreateProfileViewProps from './CreateProfileViewProps';
import CreateProfileViewState from './CreateProfileViewState';
import ProfileFormValues from './ProfileFormValues';

class CreateAccountView extends Component<
  CreateProfileViewProps,
  CreateProfileViewState
> {
  constructor(props: CreateProfileViewProps) {
    super(props);
    this.state = {
      submitLoadingStatus: Status.Idle,
      imageStatus: Status.Idle,
      profileCreateStatus: Status.Idle,
      userStatus: Status.Loading,
      statusErrorCode: null,
      resendEmailStatus: Status.Idle,
    };
  }

  componentDidMount(): void {
    setPageTitle();
    const { location } = this.props;

    const query = new URLSearchParams(location.search);
    this.code = query.get('code') ?? '';
    this.email = query.get('email') ?? '';
    this.expiryTime =
      query.get('expiry_time') ?? TIMEOUTS.AUTH_VERIFICATION_EXPIRY;
    if (this.code && this.email) {
      this.checkStatus();
    } else {
      this.setState({ redirectTo: ModulePaths.DashboardPath });
    }
  }

  email = '';

  code = '';

  expiryTime = '';

  initialValues: ProfileFormValues = new ProfileFormValues();

  /**
   * Check status of the email link and set to state
   */
  checkStatus = async (): Promise<void> => {
    this.setState({ userStatus: Status.Loading });
    try {
      await AuthApiInstance.GetInviteStatus(this.code, this.email, this.source);
      this.setState({ userStatus: Status.Success, statusErrorCode: null });
    } catch (error) {
      const errorCode = getFirstError(error);
      this.setState({ userStatus: Status.Error, statusErrorCode: errorCode });

      sendEventGA(
        CategoryKeysGA.AccountCreateProfile,
        ActionKeysGA.Redirect,
        `${LabelKeysGA.Fail}: ${intl.get(
          `ERR_API_CODE_CREATE_PROFILE_${errorCode}`
        )}`
      );
    }
  };

  /**
   * Re-sends email for expired links
   */
  resendEmail = async (): Promise<void> => {
    if (this.email) {
      this.setState({ resendEmailStatus: Status.Loading });
      try {
        await AuthApiInstance.ResendProfileInvite(this.email, this.source);
        this.setState({ resendEmailStatus: Status.Success });

        sendEventGA(
          CategoryKeysGA.AccountCreateProfile,
          ActionKeysGA.ResendEmail
        );
      } catch (error) {
        const errorCode = getFirstError(error);
        this.setState({
          resendEmailStatus: Status.Error,
          statusErrorCode: errorCode,
        });
      }
    }
  };

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Gets upload URL and uploads image to that URL
   * If success returns filename, else throws error
   *
   * @param image Image file
   * @returns {Promise<string>}
   */
  uploadImage = async (image: File): Promise<string> => {
    this.setState({ imageStatus: Status.Loading });
    try {
      const { item } = await AuthApiInstance.GetImageUploadUrl(
        image.name,
        this.email,
        this.code,
        this.source
      );
      if (item) {
        await AuthApiInstance.UploadProfileImage(
          item.file,
          item.requiredHeaders,
          image,
          this.source
        );
        this.setState({ imageStatus: Status.Success });
        return item.file;
        // eslint-disable-next-line no-else-return
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({ imageStatus: Status.Error });
      throw error;
    }
  };

  /**
   * Updates profile and sets status
   *
   * @param profile Object with user's profile information
   * @returns {Promise<UserResponse>}
   */
  createProfile = async (
    profile: CreateProfileViewModel
  ): Promise<UserResponse> => {
    this.setState({ profileCreateStatus: Status.Loading });
    try {
      const response = await AuthApiInstance.CreateProfile(
        profile,
        this.source
      );
      this.setState({ profileCreateStatus: Status.Success });

      sendEventGA(
        CategoryKeysGA.AccountCreateProfile,
        ActionKeysGA.CreateProfile,
        LabelKeysGA.Success
      );
      return response;
    } catch (error) {
      this.setState({ profileCreateStatus: Status.Error });
      throw error;
    }
  };

  /**
   * Handles form submission
   *
   * @param values Values from form fields
   * @param formikHelpers Formik helpers
   */
  handleSubmit = async (
    values: ProfileFormValues,
    formikHelpers: FormikHelpers<ProfileFormValues>
  ): Promise<void> => {
    const { appContext } = this.props;
    const { firstName, lastName, image, phoneNumber, password, rememberMe } =
      values;
    this.setState({ submitLoadingStatus: Status.Loading });
    // prettier-ignore
    const profile = new CreateProfileViewModel(this.email, this.code, firstName, lastName, phoneNumber, password, rememberMe);
    const onSuccessRedirect = (): void => {
      this.setState({
        redirectTo: `${ModulePaths.DashboardPath}${ModulePaths.DashboardGettingStartedPath}`,
      });
    };
    if (image) {
      try {
        const fileName = await this.uploadImage(image);
        if (fileName) {
          profile.image = image.name;
          const user = await this.createProfile(profile);
          AuthStorageService.OnLogin(user);

          await appContext.onLogin();

          this.setState({ submitLoadingStatus: Status.Done });
          onSuccessRedirect();
        }
      } catch (error) {
        this.setState({ submitLoadingStatus: Status.Done });
        formikHelpers.setStatus(this.handleErrors(error));

        sendEventGA(
          CategoryKeysGA.AccountCreateProfile,
          ActionKeysGA.CreateProfile,
          LabelKeysGA.Fail
        );
      }
    } else {
      try {
        const user = await this.createProfile(profile);
        AuthStorageService.OnLogin(user);

        await appContext.onLogin();

        this.setState({ submitLoadingStatus: Status.Done });
        onSuccessRedirect();
      } catch (error) {
        this.setState({ submitLoadingStatus: Status.Done });
        formikHelpers.setStatus(this.handleErrors(error));

        sendEventGA(
          CategoryKeysGA.AccountCreateProfile,
          ActionKeysGA.CreateProfile,
          LabelKeysGA.Fail
        );
      }
    }
  };

  /**
   * Handles formatting errors
   *
   * @param error Error object
   * @returns {ErrorStatus} Error status object
   */
  handleErrors = (error): ErrorStatus =>
    getErrorStatus(error, intl.get('OOPS_SOMETHING_WENT_WRONG'));

  render(): JSX.Element {
    const { location } = this.props;
    const {
      userStatus,
      statusErrorCode,
      resendEmailStatus,
      redirectTo,
      imageStatus,
      submitLoadingStatus,
    } = this.state;

    const isBlocking =
      userStatus === Status.Loading || submitLoadingStatus === Status.Loading;

    const isVerifyIssue =
      userStatus !== Status.Loading && userStatus === Status.Error;

    return (
      <RedirectHandler
        redirect={!!redirectTo}
        to={{
          pathname: redirectTo,
          search: getGlobalFiltersQuery(location.search),
        }}
      >
        <ModalBackground>
          <Modal autoFocus size="lg" isOpen backdrop="static" centered>
            <Formik
              initialValues={this.initialValues}
              onSubmit={this.handleSubmit}
              validationSchema={CreateProfileValidation.GetValidationSchema()}
            >
              {(formProps): JSX.Element => (
                <BlockUi blocking={isBlocking}>
                  {isVerifyIssue ? (
                    <CreateProfileVerification
                      resendStatus={resendEmailStatus}
                      errorCode={statusErrorCode}
                      expiryTimeString={this.expiryTime}
                      onResendEmail={this.resendEmail}
                    />
                  ) : (
                    <CreateProfileFormBody
                      {...formProps}
                      email={this.email}
                      resendEmail={this.resendEmail}
                      resendEmailStatus={resendEmailStatus}
                      expiryTimeString={this.expiryTime}
                      imageStatus={imageStatus}
                    />
                  )}
                </BlockUi>
              )}
            </Formik>
          </Modal>
        </ModalBackground>
      </RedirectHandler>
    );
  }
}

export default CreateAccountView;
