import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Col, ModalBody, ModalHeader, Row } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import InsLink from 'shared/components/ins-link/InsLink';
import successAnimation from 'shared/static/img/animations/success.gif';

import styles from './setNewPasswordSuccess.module.scss';

const SetNewPasswordSuccess: ReactFC = () => (
  <>
    <ModalHeader className="increase-font text-center">
      {intl.get('LBL_SET_NEW_PASSWORD_SUCCESS_TITLE')}
    </ModalHeader>
    <ModalBody>
      <Row>
        <Col
          xs="12"
          className="d-flex justify-content-center mb-3 px-3 pb-3 pt-1"
        >
          <img src={successAnimation} alt="Success" className={styles.anim} />
        </Col>
      </Row>
      <Row className="justify-content-center mt-3 mb-3">
        <Col xs="auto">
          <InsLink
            to={`${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`}
            className="btn btn-primary"
          >
            {intl.get('BTN_GO_TO_LOGIN')}
          </InsLink>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-center pt-3 mt-3">
          <small className="text-muted">
            {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
            <a
              href="https://support.dreamstartlabs.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {intl.get('LBL_CA_CONTACT_SUPPORT')}
            </a>
          </small>
        </Col>
      </Row>
    </ModalBody>
  </>
);

export default SetNewPasswordSuccess;
