/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import {
  DashBoardApiTags,
  FinanceApiTags,
  GroupApiTags,
} from 'constants/request-tags/RequestTags';
import CurrencyStorageService from 'services/storage-services/CurrencyStorageService';
import ExchangeRate from 'shared/enums/ExchangeRate';

/**
 * This is currently used only for manipulation of currency
 * related query parameters (currency code and the exchange rate mode)
 * passed to API base ExecuteAsync functions
 */

const localCurrencyValue = 'LOCAL';
const GetMethod = 'GET';
const APIMethodArgumentIndex = 1;
const APIQueryParamsArgumentIndex = 7;

class QueryInjector {
  /**
   * Get currency code
   */
  static getCurrencyCode(): string {
    const currencyCode = this.isLocalCurrency()
      ? localCurrencyValue
      : CurrencyStorageService.GetSelectedCurrency()?.currencyCode;

    return currencyCode;
  }

  /**
   * Get currency exchange rate mode (Current or Historic)
   */
  static getCurrencyRateMode(): string {
    const exchangeRateMode = this.isLocalCurrency()
      ? ExchangeRate.Default
      : CurrencyStorageService.GetSelectedCurrency()?.exchangeRateMode;

    return exchangeRateMode;
  }

  /**
   * Check whether local currency is toggled
   */
  static isLocalCurrency(): boolean {
    return (
      CurrencyStorageService.GetSelectedCurrency()?.currencyCode !==
      CurrencyStorageService.GetDefaultCurrency()?.currencyCode
    );
  }

  /**
   * Condition to apply the currency related queries
   */
  static shouldAddCurrencyQueries(args: any[]): boolean {
    const apiTag = args.pop();
    return (
      (Object.values(DashBoardApiTags).includes(apiTag) ||
        Object.values(GroupApiTags).includes(apiTag) ||
        Object.values(FinanceApiTags).includes(apiTag)) &&
      args[APIMethodArgumentIndex] === GetMethod
    );
  }

  inject() {
    // eslint-disable-next-line func-names
    return function (
      target: Record<string, any>,
      key: string | symbol,
      descriptor: PropertyDescriptor
    ): PropertyDescriptor {
      const original = descriptor.value;

      descriptor.value = function (...args: any[]): unknown {
        const newArgs = args;

        if (QueryInjector.shouldAddCurrencyQueries(args)) {
          const updatedQueryParams = {
            ...args[APIQueryParamsArgumentIndex],
            currencyCode: QueryInjector.getCurrencyCode(),
            exchangeRateMode: QueryInjector.getCurrencyRateMode(),
          };
          newArgs[APIQueryParamsArgumentIndex] = updatedQueryParams;
        }

        return original.apply(this, newArgs);
      };

      return descriptor;
    };
  }
}

export default new QueryInjector();
