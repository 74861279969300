import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js/min';
import * as intl from 'react-intl-universal';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import Constraints from 'constants/forms/Constraints';

class UserProfileValidations {
  /**
   * Defines a Yup validation schema
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema = yup.object().shape({
      name: yup
        .string()
        .trim()
        .required(intl.get('ERR_USER_PROFILE_NAME_CANNOT_BE_EMPTY')),
      jobRole: yup
        .string()
        .required(intl.get('ERR_USER_PROFILE_JOB_ROLE_CANNOT_BE_EMPTY')),
      permissionLevel: yup
        .string()
        .required(
          intl.get('ERR_USER_PROFILE_PERMISSION_LEVEL_CANNOT_BE_EMPTY')
        ),
      countryDataAccess: yup
        .array()
        .of(yup.string())
        .min(1, intl.get('ERR_USER_PROFILE_CDA_CANNOT_BE_EMPTY'))
        .required(intl.get('ERR_USER_PROFILE_CDA_CANNOT_BE_EMPTY')),
      email: yup
        .string()
        .trim()
        .email(intl.get('ERR_FORM_EMAIL_INVALID_MESSAGE'))
        .required(intl.get('ERR_USER_PROFILE_EMAIL_CANNOT_BE_EMPTY')),
      phoneNumber: yup
        .string()
        .required(intl.get('ERR_CP_PHONE_NUMBER_EMPTY'))
        .test(
          'isValid',
          intl.get('ERR_CP_PHONE_NUMBER_INVALID'),
          function validNumber(value: string) {
            try {
              if (value) {
                const parsed = parseMin(value);
                if (parsed) {
                  return parsed.isValid();
                }
                return false;
              }
              return true;
            } catch (error) {
              return false;
            }
          }
        ),
      image: yup
        .mixed()
        .test(
          'fileSize',
          intl.get('ERR_CP_FILE_TOO_LARGE', {
            mb: Constraints.MaxImageSizeMb,
          }),
          function testSize(value): boolean | ValidationError {
            if (value) {
              if (value.size) {
                return value.size <= Constraints.MaxImageSize;
              }
              return new ValidationError(
                intl.get('ERR_CP_FILE_TOO_LARGE', {
                  mb: Constraints.MaxImageSizeMb,
                }),
                value,
                this.path
              );
            }
            return true;
          }
        )
        .test(
          'fileFormat',
          intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'),
          function testFormat(value): boolean | ValidationError {
            if (value) {
              if (value.name) {
                const imageNameParts = (value.name as string).split('.');
                const extension = imageNameParts.pop();
                return extension
                  ? Constraints.SupportedImageFormats.includes(extension)
                  : true;
              }
              if (value.type) {
                return Constraints.SupportedImageFormats.includes(value.type);
              }
              return new ValidationError(
                intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'),
                value,
                this.path
              );
            }
            return true;
          }
        ),
    });

    return validationSchema;
  }
}

export default UserProfileValidations;
