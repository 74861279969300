import { FC as ReactFC } from 'react';

import { ResponsiveLine } from '@nivo/line';
import * as intl from 'react-intl-universal';

import chartEmptyDummyData from './chartEmptyDummyData';

const ChartEmpty: ReactFC = () => {
  const data = [
    {
      id: 'error-state',
      data: chartEmptyDummyData,
    },
  ];

  return (
    <>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 75, bottom: 50, left: 75 }}
        xScale={{ type: 'time', useUTC: false, format: '%Y-%m-%d' }}
        xFormat="time:%Y-%m-%d"
        axisBottom={{ format: '%b' }}
        theme={{
          background: 'white',
          axis: {
            ticks: {
              text: {
                fill: '#738088',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '11px',
                lineHeight: '18px',
              },
              line: {
                stroke: '#DBDCE1',
              },
            },
          },
          grid: {
            line: {
              stroke: '#DBDCE1',
              strokeWidth: 1,
              strokeDasharray: '4 4',
            },
          },
        }}
      />
      <div className="no-data-message">
        <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
        <div>{intl.get('ERR_CHART_EMPTY_MESSAGE')}</div>
      </div>
    </>
  );
};

export default ChartEmpty;
