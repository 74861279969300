import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveySendViewPoint from 'shared/enums/SurveySendViewPoint';
import SurveyType from 'shared/enums/SurveyType';

const WarningStrings = {
  [SurveyType.Survey]: {
    Intro: 'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_WARNING_POPUP_PART_1',
    [SurveyRecipientType.Groups]: {
      [SurveySendViewPoint.Drafts]: {
        SendCount:
          'LBL_SURVEYS_DRAFTED_SURVEYS_GROUP_SEND_WARNING_POPUP_PART_2',
      },
      [SurveySendViewPoint.History]: {
        SendCount: 'LBL_NA',
      },
      Exclusion: 'LBL_SURVEYS_DRAFTED_SURVEYS_GROUP_SEND_WARNING_POPUP_PART_3',
    },
    [SurveyRecipientType.Members]: {
      [SurveySendViewPoint.Drafts]: {
        SendCount:
          'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_SEND_WARNING_POPUP_PART_2',
      },
      [SurveySendViewPoint.History]: {
        SendCount: 'LBL_NA',
      },
      Exclusion: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_SEND_WARNING_POPUP_PART_3',
    },
    InvalidSendCount: 'ERR_ALERT_INVALID_RECIPIENT_COUNT',
    DuplicateWarning: 'LBL_NA',
    DuplicatePrompt: 'LBL_NA',
    SendButton: 'BTN_SURVEYS_SURVEY_SEND_YES_SEND',
    CancelButton: 'BTN_SURVEYS_SURVEY_SEND_NO_CANCEL',
  },
  [SurveyType.CollectId]: {
    Intro: 'LBL_SURVEYS_DRAFTED_COLLECT_ID_SEND_WARNING_POPUP_PART_1',
    [SurveyRecipientType.Groups]: {
      [SurveySendViewPoint.Drafts]: {
        SendCount:
          'LBL_SURVEYS_DRAFTED_SURVEYS_GROUP_SEND_WARNING_POPUP_PART_2',
      },
      [SurveySendViewPoint.History]: {
        SendCount:
          'LBL_SURVEYS_HISTORY_COLLECT_ID_GROUP_SEND_WARNING_POPUP_PART_2',
      },
      Exclusion: 'LBL_SURVEYS_DRAFTED_SURVEYS_GROUP_SEND_WARNING_POPUP_PART_3',
    },
    [SurveyRecipientType.Members]: {
      [SurveySendViewPoint.Drafts]: {
        SendCount:
          'LBL_SURVEYS_DRAFTED_COLLECT_ID_MEMBER_SEND_WARNING_POPUP_PART_2',
      },
      [SurveySendViewPoint.History]: {
        SendCount:
          'LBL_SURVEYS_HISTORY_COLLECT_ID_MEMBER_SEND_WARNING_POPUP_PART_2',
      },
      Exclusion: 'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_SEND_WARNING_POPUP_PART_3',
    },
    InvalidSendCount: 'ERR_ALERT_INVALID_RECIPIENT_COUNT',
    DuplicateWarning:
      'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_COLLECT_ID_DUPLICATE_WARNING',
    DuplicatePrompt:
      'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_COLLECT_ID_DUPLICATE_WARNING_QUESTION',
    SendButton: 'BTN_SURVEYS_COLLECT_ID_SEND_YES_TURN_ON',
    CancelButton: 'BTN_SURVEYS_COLLECT_ID_SEND_NO_CANCEL',
  },
};

export default WarningStrings;
