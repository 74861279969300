/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useMemo } from 'react';

import { Field } from 'formik';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Button, Form, FormGroup, Label } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import AppContext from 'context/AppContext';
import { getLocalizedErrorString } from 'helpers/ErrorFormat';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import ImageComponent from 'shared/components/image/ImageComponent';
import FileInputField from 'shared/components/ins-form-fields/file-input-field/FileInputField';
import { Option } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import PhoneNumberField from 'shared/components/ins-form-fields/phone-number-field/PhoneNumberField';
import Status from 'shared/enums/Status';
import UserStatus from 'shared/enums/UserStatus';
import placeholderAvatar from 'shared/static/img/il_avatar.svg';

import DefaultPermissionLevel from '../../../../enums/DefaultPermissionLevel';
import CountriesSelect from '../invite-users-field-array/invite-users-form-item/countries-select/CountriesSelect';
import JobRoleSelect from '../invite-users-field-array/invite-users-form-item/job-role-async-select/JobRoleSelect';
import PermissionOption from '../invite-users-field-array/invite-users-form-item/permission-select/PermissionOption';
import PermissionSelect from '../invite-users-field-array/invite-users-form-item/permission-select/PermissionSelect';
import UserProfileFieldWrapper from './user-profile-field-wrapper/UserProfileFieldWrapper';
import { CountryOption } from './UserProfileDetails';
import styles from './userProfileLeftPanel.module.scss';
import UserProfileLeftPanelProps, {
  UserProfileFields,
  SelectOnChangeCurried,
  UserProfileLeftPanelFormFieldError,
} from './UserProfileLeftPanelProps';

const UserProfileLeftPanel: React.FC<UserProfileLeftPanelProps> = (
  props: UserProfileLeftPanelProps
) => {
  const {
    data,
    jobRoles,
    jobRolesStatus,
    createJobRoleStatus,
    createJobRoleError,
    onCreateJobRole,
    clearJobRoleError,
    countries,
    countriesStatus,
    permissionLevels,
    permissionLevelsStatus,
    canDeactivateUsers,
    togglePermissionsModal,
    toggleDeactivateModal,
    setFieldValue,
    setFieldTouched,
    values,
    restrictions,
    status,
  } = props;

  const { userProfileSetupInProgress } = useContext(AppContext);

  /* FIXME Truncate tooltips not working with EllipsisTooltip in left panel */
  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [data]);

  const { canEditProfile, isOwnProfile, isOwner } = restrictions;

  /* ======| COUNTRY FIELD FORMATTING |====== */
  const uniqueCountriesUnion = uniqBy(
    [...data.countryDataAccess, ...countries],
    'value'
  );

  /* Country codes of profile user's accessible countries */
  const dataCountryCodes = data.countryDataAccess.map((cda) => cda.value);
  /* Country codes of logged-in user's accessible countries */
  const countryOptionCodes = countries.map((cda) => cda.value);
  /* Countries that the logged in user does not have access to edit */
  const inaccessibleCountries = dataCountryCodes.filter(
    (cda) => !countryOptionCodes.includes(cda)
  );
  /* Disable countries that the logged-in user does not have access to edit */
  const countriesUnionWithDisabled: Array<CountryOption> =
    uniqueCountriesUnion.map((c) => ({
      ...c,
      isDisabled: inaccessibleCountries.includes(c.value),
      isFixed: inaccessibleCountries.includes(c.value),
    }));

  /* List of country options to be displayed in the select list */
  const countryOptions = useMemo(
    () =>
      /* Disable all other country options if All Countries (*) is selected */
      countriesUnionWithDisabled.map((value) => {
        if (values.countryDataAccess.includes('*') && value.value !== '*') {
          return {
            ...value,
            isDisabled: true,
          };
        }
        return value;
      }),
    [countriesUnionWithDisabled, values.countryDataAccess]
  );

  /* Label of countries for when edit mode is enabled i.e. selected countries */
  const selectedCountries = countriesUnionWithDisabled.filter((item) =>
    values.countryDataAccess.includes(item.value)
  );

  /* Label of countries for when edit mode is disabled */
  const getCountryValue = (): string => {
    let countryString = '';
    data.countryDataAccess.forEach((cda) => {
      countryString += `${String(cda.label.toUpperCase())}\n`;
    });
    return countryString;
  };
  /* ======| END - COUNTRY FIELD FORMATTING |====== */

  /* ======| PERMISSION FIELD FORMATTING |====== */
  const getAccountOwnerLabel = (): PermissionOption => ({
    value: DefaultPermissionLevel.ACCOUNT_OWNER,
    label: intl.get('LBL_ACCOUNT_OWNER_TITLE'),
    description: intl.get('LBL_ACCOUNT_OWNER_DESCRIPTION'),
  });

  let permission =
    find(permissionLevels, { value: values.permissionLevel }) ?? null;
  if (values.permissionLevel === DefaultPermissionLevel.ACCOUNT_OWNER) {
    permission = getAccountOwnerLabel();
  }

  const getPermissionValue = (): string => {
    let permissionString = '';
    let permissionLabels = permissionLevels.filter(
      (p) => p.value === data.permissionLevel
    );
    if (data.permissionLevel === DefaultPermissionLevel.ACCOUNT_OWNER) {
      permissionLabels = [getAccountOwnerLabel()];
    }
    if (!isEmpty(permissionLabels)) {
      permissionString = permissionLabels[0].label;
    }
    return permissionString;
  };
  /* ======| END - PERMISSION FIELD FORMATTING |====== */

  /* ======| JOB ROLE  FIELD FORMATTING |====== */
  const jobRole = find(jobRoles, { value: values.jobRole }) ?? null;
  /* ======| END - JOB ROLE  FIELD FORMATTING |====== */

  /* ======| NAME FIELD FORMATTING |====== */
  let fullName = data.firstName;
  if (data.firstName && data.lastName) {
    fullName = `${String(data.firstName)} ${String(data.lastName)}`;
  } else if (data.lastName) {
    fullName = data.lastName;
  }
  /* ======| END NAME FIELD FORMATTING |====== */

  /**
   * Renders API errors specific to each form field
   *
   * @param fieldName The field under which the error should be rendered
   * @returns {string | null} Error message
   */
  const renderStatusError = (name): string | null => {
    const dotNameArray = name.split('.');
    const fieldName = dotNameArray[dotNameArray.length - 1];

    if (status && status.errors?.length > 0) {
      const fieldError = status.errors.find(
        (error: UserProfileLeftPanelFormFieldError) =>
          error.id === data.id && error.fieldName === fieldName
      );
      if (fieldError !== undefined) {
        const message = intl.get(getLocalizedErrorString(fieldError.errorCode));
        return message;
      }
    }
    return null;
  };

  /**
   * Set selected file to @formik state
   *
   * @param files selected File | Files[]
   * @param event select file change event
   */
  const handleImageSelect = (
    files: File[] | null,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (files) {
      const selectedFile = files[0];
      const name = event.currentTarget.name ?? UserProfileFields.IMAGE;
      try {
        const file = selectedFile;

        /* Does not preview image in the case of an unsupported extension */
        const imageNameParts = file.name.split('.');
        const extension = imageNameParts.pop();
        if (
          (extension &&
            !Constraints.SupportedImageFormats.includes(extension)) ||
          !Constraints.SupportedImageFormats.includes(file.type)
        ) {
          throw new Error(intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'));
        }
        const image = new Image();
        image.src = URL.createObjectURL(file);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        file.image = image;

        setFieldTouched(name, true);
        setFieldValue(name, file);
      } catch (error) {
        setFieldTouched(name, true, false);
        setFieldValue(name, null, false);
      }
    }
  };

  /**
   * Remove selected image file from @formik state
   */
  const handleRemoveImage = (): void => {
    setFieldTouched(UserProfileFields.IMAGE, false);
    setFieldValue(UserProfileFields.IMAGE, null);
  };

  /**
   * Set field value customized function to format values to required format
   *
   * @param fieldName name of the @formik field
   * @param isMulti is multi select
   */
  const setValue =
    (fieldName: string, isMulti?: boolean) =>
    (value: Option | Option[]): void => {
      let currentValue: string | string[];
      if (isMulti) {
        const items = value as Option[];
        currentValue = items.map((option) => option.value);
      } else {
        currentValue = (value as Option).value;
      }
      setFieldValue(fieldName, currentValue);
    };

  const setValueForSelect = setValue as SelectOnChangeCurried;

  /**
   * Curried function to set @formik field touched
   *
   * @param fieldName name of the @formik field
   */
  const setTouched = (fieldName: string) => (): void =>
    setFieldTouched(fieldName);

  const displayDeactivateButton =
    canDeactivateUsers && data.status === UserStatus.Active;

  return (
    <div
      className={`user-profile-item-info ${
        userProfileSetupInProgress ? 'extend-height' : ''
      }`}
    >
      <div className="user-profile-item-info-inner">
        <Form noValidate>
          <div
            className={`input-group mb-0 ${styles.frame} ${
              userProfileSetupInProgress ? styles.alignCenter : styles.alignLeft
            }`}
          >
            <FormGroup className="mb-0">
              <div className={styles.frame}>
                <ImageComponent
                  switcher
                  className={styles.picture}
                  fallbackClassName={styles.placeholder}
                  fallbackSrc={placeholderAvatar}
                  src={values.image?.image?.src}
                  alt="image"
                />
              </div>
              <EnhancedFormikError
                center
                name={UserProfileFields.IMAGE}
                renderSecondaryError={renderStatusError}
              />
              <UserProfileFieldWrapper
                value=""
                fieldName={UserProfileFields.IMAGE}
                canEditProfile={canEditProfile}
                isOwnProfile={isOwnProfile}
                isOwner={isOwner}
                isControl
              >
                <div className={styles.fileInput}>
                  <Label className="btn btn-sm btn-secondary align-middle">
                    <Field
                      type="file"
                      name={UserProfileFields.IMAGE}
                      accept={Constraints.SupportedImageFormats.join(', ')}
                      onChange={handleImageSelect}
                      className="form-control"
                      component={FileInputField}
                    />
                    <i className="icon-export" />
                    {intl.get('BTN_PROJECTS_FACILITATORS_UPLOAD_IMAGE')}
                  </Label>
                  <Button
                    type="button"
                    onClick={handleRemoveImage}
                    disabled={values.image === null}
                    className="btn btn-link btn-sm mx-auto shadow-none"
                  >
                    {intl.get('BTN_CREATE_PROFILE_REMOVE_IMAGE')}
                  </Button>
                  <hr className="divider" />
                </div>
              </UserProfileFieldWrapper>
            </FormGroup>
          </div>
          <div className="select-group heading-input">
            <UserProfileFieldWrapper
              value={fullName}
              fieldName={UserProfileFields.NAME}
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
              classes="text-18-semibold pb-0"
            >
              <FormGroup>
                <Field
                  type="text"
                  name={UserProfileFields.NAME}
                  maxLength={Constraints.MaxFieldLength}
                  className="form-control"
                  tabIndex={0}
                />
                <EnhancedFormikError
                  name={UserProfileFields.NAME}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </UserProfileFieldWrapper>
          </div>
          <div className="select-group">
            <UserProfileFieldWrapper
              value={
                data.number ? `#${String(data.number)}` : intl.get('LBL_NA')
              }
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
              noEdit
              classes="text-12-bold mb-3"
            >
              {data.number ? `#${String(data.number)}` : intl.get('LBL_NA')}
            </UserProfileFieldWrapper>
          </div>
          <div className="select-group break-words">
            <label className="text-14-semibold">
              {intl.get('LBL_USER_PROFILE_ORGANIZATION_LABEL')}
            </label>
            <UserProfileFieldWrapper
              value={data.organization}
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
              classes="pb-0"
              noEdit
            >
              {data.organization ? data.organization : intl.get('LBL_NA')}
            </UserProfileFieldWrapper>
          </div>
          <div className="select-group break-words">
            <label className="text-14-semibold">
              {intl.get('LBL_USER_PROFILE_JOB_ROLE_LABEL')}
            </label>
            <UserProfileFieldWrapper
              value={data.jobRole.role}
              fieldName={UserProfileFields.JOB_ROLE}
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
            >
              <FormGroup className="insight-select-group">
                <JobRoleSelect
                  options={jobRoles}
                  isLoading={
                    jobRolesStatus === Status.Loading ||
                    createJobRoleStatus === Status.Loading
                  }
                  value={jobRole}
                  onCreateJobRole={onCreateJobRole}
                  createJobRoleStatus={createJobRoleStatus}
                  clearJobRoleError={clearJobRoleError}
                  createJobRoleError={createJobRoleError}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_JOB_ROLE_PLACEHOLDER'
                  )}
                  name={UserProfileFields.JOB_ROLE}
                  tabSelectsValue={false}
                  onChange={setValueForSelect(UserProfileFields.JOB_ROLE)}
                  onBlur={setTouched(UserProfileFields.JOB_ROLE)}
                  classNamePrefix="insight-select"
                />
                <EnhancedFormikError
                  name={UserProfileFields.JOB_ROLE}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </UserProfileFieldWrapper>
          </div>
          <div className="select-group break-words">
            <label className="text-14-semibold">
              {intl.get('LBL_USER_PROFILE_PERMISSION_LEVEL_LABEL')}
            </label>
            <UserProfileFieldWrapper
              value={getPermissionValue()}
              fieldName={UserProfileFields.PERMISSION_LEVEL}
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
            >
              <FormGroup className="insight-select-group">
                <PermissionSelect
                  value={permission}
                  onChange={setValueForSelect(
                    UserProfileFields.PERMISSION_LEVEL
                  )}
                  onBlur={setTouched(UserProfileFields.PERMISSION_LEVEL)}
                  options={permissionLevels}
                  isLoading={permissionLevelsStatus === Status.Loading}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_PERMISSION_LEVEL_PLACEHOLDER'
                  )}
                  name={UserProfileFields.PERMISSION_LEVEL}
                  classNamePrefix="insight-select"
                  togglePermissionsModal={togglePermissionsModal}
                />
                <EnhancedFormikError
                  name={UserProfileFields.PERMISSION_LEVEL}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </UserProfileFieldWrapper>
          </div>
          <div className="select-group break-words">
            <label className="text-14-semibold">
              {intl.get('LBL_USER_PROFILE_COUNTRY_DATA_ACCESS_LABEL')}
            </label>
            <UserProfileFieldWrapper
              value={getCountryValue()}
              fieldName={UserProfileFields.COUNTRY_DATA_ACCESS}
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
              isList
            >
              <FormGroup className="insight-select-group">
                <CountriesSelect
                  isMulti
                  value={selectedCountries}
                  isClearable={false}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={setValueForSelect(
                    UserProfileFields.COUNTRY_DATA_ACCESS,
                    true
                  )}
                  onBlur={setTouched(UserProfileFields.COUNTRY_DATA_ACCESS)}
                  options={countryOptions}
                  isLoading={countriesStatus === Status.Loading}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_COUNTRY_DATA_PLACEHOLDER'
                  )}
                  name={UserProfileFields.COUNTRY_DATA_ACCESS}
                  className="expand-height"
                  classNamePrefix="insight-select"
                />
                <EnhancedFormikError
                  name={UserProfileFields.COUNTRY_DATA_ACCESS}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </UserProfileFieldWrapper>
          </div>
          <div className="select-group break-words">
            <label className="text-14-semibold">
              {intl.get('LBL_USER_PROFILE_EMAIL_LABEL')}
            </label>
            <UserProfileFieldWrapper
              value={data.email}
              fieldName={UserProfileFields.EMAIL}
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
              classes="pr-0 pb-0"
            >
              <FormGroup>
                <Field
                  type="email"
                  name={UserProfileFields.EMAIL}
                  maxLength={Constraints.MaxFieldLength}
                  className="form-control"
                  tabIndex={0}
                />
                <EnhancedFormikError
                  name={UserProfileFields.EMAIL}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </UserProfileFieldWrapper>
          </div>
          <div className="select-group break-words">
            <label className="text-14-semibold">
              {intl.get('LBL_USER_PROFILE_PHONE_NUMBER_LABEL')}
            </label>
            <UserProfileFieldWrapper
              value={data.phoneNumber}
              fieldName={UserProfileFields.PHONE_NUMBER}
              canEditProfile={canEditProfile}
              isOwnProfile={isOwnProfile}
              isOwner={isOwner}
              classes="pr-0"
            >
              <FormGroup className="insight-select-group">
                <Field
                  type="tel"
                  name={UserProfileFields.PHONE_NUMBER}
                  autoComplete="tel"
                  maxLength={Constraints.MaxFieldLength}
                  className="form-control"
                  component={PhoneNumberField}
                  tabIndex={0}
                />
                <EnhancedFormikError
                  name={UserProfileFields.PHONE_NUMBER}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </UserProfileFieldWrapper>
          </div>
          {displayDeactivateButton && (
            <div className="input-group justify-content-center">
              <UserProfileFieldWrapper
                value=""
                fieldName={UserProfileFields.DEACTIVATE}
                canEditProfile={canEditProfile}
                isOwnProfile={isOwnProfile}
                isOwner={isOwner}
                isControl
              >
                <Button
                  type="button"
                  onClick={() => toggleDeactivateModal(data.id)}
                  disabled={!canDeactivateUsers}
                  className="btn btn-sm mx-auto shadow-none"
                >
                  <i className="icon-deactivate" />
                  {intl.get('BTN_DEACTIVATE')}
                </Button>
              </UserProfileFieldWrapper>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default UserProfileLeftPanel;
