/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';

import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Button, Col, Row } from 'reactstrap';

import Currency from 'api/settings/types/currency/Currency';
import styles from 'modules/private/settings/containers/my-org-view/myOrgView.module.scss';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ExchangeRate from 'shared/enums/ExchangeRate';
import Status from 'shared/enums/Status';

import DefaultCurrencyMenu from './default-currency-menu/DefaultCurrencyMenu';
import DefaultCurrencyRowProps from './DefaultCurrencyRowProps';

const DefaultCurrencyRow: React.FC<DefaultCurrencyRowProps> = (
  props: DefaultCurrencyRowProps
) => {
  const {
    status,
    error,
    defaultCurrencyData,
    currentDefaultCurrency = null,
    currentExchangeRate = ExchangeRate.Current,
    isCurrencyEditInProgress,
    canEditDefaultCurrency,
    getDefaultCurrencyData,
    onSaveCurrencyInformation,
    setStatus,
    toggleCurrencyEdit,
  } = props;

  const genericCurrency: Currency = {
    name: intl.get('ERR_SETTINGS_NO_CURRENCY_SELECTED'),
    currencyCode: '',
    symbol: '',
  };

  const [isDefaultCurrencyMenuOpen, setIsDefaultCurrencyMenuOpen] =
    useState<boolean>(false);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<
    string | null
  >(currentDefaultCurrency);
  const [selectedExchangeRate, setSelectedExchangeRate] =
    useState<ExchangeRate>(currentExchangeRate);
  const [currentCurrency, setCurrentCurrency] =
    useState<Currency>(genericCurrency);

  /**
   * Find and set the object of the user selected currency
   * to the temporary component state.
   * This exists purely to access the full name of the user
   * selected currency for display purposes
   */
  useEffect(() => {
    setCurrentCurrency(
      defaultCurrencyData.find(
        (currency: Currency) => currency.currencyCode === selectedCurrencyCode
      ) || genericCurrency
    );
  }, [selectedCurrencyCode, defaultCurrencyData]);

  /* Set fetched default currency and exchange rate mode to temporary component state */
  useEffect(() => {
    setSelectedCurrencyCode(currentDefaultCurrency);
    setSelectedExchangeRate(currentExchangeRate);
  }, [currentDefaultCurrency, currentExchangeRate]);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, []);

  /* Set user selected default currency to temporary component state */
  const handleSetDefaultCurrency = (code: string | null): void => {
    if (code && code !== null) {
      setSelectedCurrencyCode(code);
      setIsDefaultCurrencyMenuOpen(false);
    }
  };

  /* Save and update user selected default currency and exchange rate mode */
  const handleSaveSelectedCurrencyInfo = (): void => {
    if (selectedCurrencyCode && selectedExchangeRate) {
      onSaveCurrencyInformation(selectedCurrencyCode, selectedExchangeRate);
    }
  };

  const isSaveButtonDisabled =
    selectedCurrencyCode === currentDefaultCurrency &&
    selectedExchangeRate === currentExchangeRate;

  /**
   * Toggle the edit button
   *
   * @param e Mouse click event
   */
  const toggleEdit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    /* Blur the 'Cancel' and 'Edit' buttons on edit state toggle */
    e.currentTarget.blur();
    /* Revert any newly selected currency info back 
      to the originally fetched currency info */
    setSelectedCurrencyCode(currentDefaultCurrency);
    setSelectedExchangeRate(currentExchangeRate);
    toggleCurrencyEdit();
    setIsDefaultCurrencyMenuOpen(false);
  };

  const displayedCurrency = `${String(currentCurrency.name)} ${
    currentCurrency.currencyCode
      ? `(${String(currentCurrency.currencyCode)})`
      : ''
  }`;

  return (
    <Col xs="12">
      <div className={styles.item}>
        <Row className="align-items-center">
          <Col xs="3">
            <p className="truncate text-16-semibold text-primary">
              {intl.get('LBL_ORG_DEFAULT_CURRENCY')}
              <span className="help">
                <i
                  className="icon-help"
                  data-for="insTooltip"
                  data-tip={intl.get('LBL_ORG_DEFAULT_CURRENCY_HELP_TEXT')}
                />
              </span>
            </p>
          </Col>
          <Col xs="3" className="d-inline-flex select-group">
            <DefaultCurrencyMenu
              isMenuOpen={isDefaultCurrencyMenuOpen}
              hideBackdrop
              status={status}
              defaultCurrencyData={defaultCurrencyData}
              error={error}
              onClose={(): void => setIsDefaultCurrencyMenuOpen(false)}
              onSetDefaultCurrency={handleSetDefaultCurrency}
              getDefaultCurrencyData={getDefaultCurrencyData}
              setStatus={setStatus}
              currentDefaultCurrency={selectedCurrencyCode}
              placement={{
                anchor: 'BOTTOM_CENTER',
                possibleAnchors: ['BOTTOM_CENTER'],
                triggerOffset: 10,
              }}
            >
              {({ triggerRef }): ReactNode => (
                <EllipsisTooltip
                  ref={triggerRef}
                  className={`simple-dropdown-btn extend-width text-14-medium ${
                    isDefaultCurrencyMenuOpen
                      ? 'dropdown-open'
                      : 'dropdown-closed'
                  }`}
                  disabled={
                    !isCurrencyEditInProgress ||
                    !canEditDefaultCurrency ||
                    status === Status.Loading
                  }
                  onClick={() =>
                    setIsDefaultCurrencyMenuOpen(!isDefaultCurrencyMenuOpen)
                  }
                  tag="button"
                  data-cy="default-currency-select"
                  data-place="top"
                  data-for="insTooltip"
                  data-tip={displayedCurrency}
                  data-class="overflow-wrap"
                >
                  {displayedCurrency}
                </EllipsisTooltip>
              )}
            </DefaultCurrencyMenu>
          </Col>
          <Col xs="6" className="d-flex">
            <Col
              xs="6"
              className="d-inline-flex select-group justify-content-start"
            >
              <EllipsisTooltip
                className="extend-width text-14-medium"
                tag="span"
                data-cy="exchange-rate-select"
                data-place="top"
                data-for="insTooltip"
                data-tip={intl.get(
                  `LBL_SETTINGS_MYORG_CURRENCY_${ExchangeRate.Current.toUpperCase()}`
                )}
                data-class="overflow-wrap"
              >
                {intl.get(
                  `LBL_SETTINGS_MYORG_CURRENCY_${ExchangeRate.Current.toUpperCase()}`
                )}
              </EllipsisTooltip>
            </Col>
            <Col xs="6" className="d-inline-flex justify-content-end pr-0">
              {isCurrencyEditInProgress ? (
                <>
                  <Button
                    className="btn btn-warning btn-sm"
                    tabIndex={0}
                    disabled={status === Status.Loading}
                    onClick={toggleEdit}
                  >
                    {intl.get('BTN_CANCEL')}
                  </Button>
                  <Button
                    className="btn btn-primary btn-sm ml-2"
                    tabIndex={0}
                    disabled={status === Status.Loading || isSaveButtonDisabled}
                    onClick={handleSaveSelectedCurrencyInfo}
                  >
                    {intl.get('BTN_SAVE')}
                  </Button>
                </>
              ) : (
                canEditDefaultCurrency && (
                  <Button
                    className="btn btn-secondary btn-sm"
                    tabIndex={0}
                    disabled={!canEditDefaultCurrency}
                    onClick={toggleEdit}
                  >
                    <i className="icon-plus" />
                    {intl.get('BTN_ORG_EDIT')}
                  </Button>
                )
              )}
            </Col>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default DefaultCurrencyRow;
