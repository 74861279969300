import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Button, Col, Fade, Row } from 'reactstrap';

import mergeRefs from 'helpers/mergeRefs';
import ImageComponent from 'shared/components/image/ImageComponent';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import PendingStateAnim from 'shared/static/img/animations/pending-state.gif';

import styles from '../surveysPopups.module.scss';
import SurveySendSuccessPopupProps from './SurveySendSuccessPopupProps';

const SurveySendSuccessPopup: ReactFC<SurveySendSuccessPopupProps> = (
  props: SurveySendSuccessPopupProps
) => {
  const {
    display,
    displayWarningPopup,
    sendCount,
    recipientType,
    onClose,
    onUndo,
  } = props;

  return (
    <Fade
      mountOnEnter
      unmountOnExit
      in={display && !displayWarningPopup}
      className={styles.overlay}
    >
      <TrapFocus autoFocus>
        {({ firstFocus, lastFocus }): JSX.Element => (
          <div className={`${styles.confirmation} ${styles.success}`}>
            <Col>
              <Row>
                <Col xs="4" className="pt-3">
                  <ImageComponent
                    loading="eager"
                    src={PendingStateAnim}
                    alt="Create Message Success"
                    className={styles.anim}
                  />
                </Col>
                <Col xs="8">
                  <Row>
                    <Col xs="12" className="d-flex justify-content-end mb-3">
                      <button
                        className="btn btn-close shadow-none p-0"
                        type="button"
                        onClick={onClose}
                        style={{ height: '10px' }}
                      >
                        <i
                          className="icon-close"
                          style={{ fontSize: '10px' }}
                        />
                      </button>
                    </Col>
                    <Col xs="12">
                      <h2 className="text-14-semibold text-uppercase text-gray">
                        {intl.get(
                          'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_SUCCESS_POPUP_TITLE'
                        )}
                      </h2>
                    </Col>
                    <Col xs="12">
                      <div className="text-12-medium text-gray mb-3">
                        {recipientType === SurveyRecipientType.Groups
                          ? intl.getHTML(
                              'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_GROUPS_SUCCESS_POPUP_DESCRIPTION',
                              {
                                count: sendCount,
                              }
                            )
                          : intl.getHTML(
                              'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_MEMBERS_SUCCESS_POPUP_DESCRIPTION',
                              {
                                count: sendCount,
                              }
                            )}
                      </div>
                      <div className="text-12-medium text-gray">
                        {intl.get(
                          'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_SUCCESS_POPUP_DESCRIPTION_2'
                        )}
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      className="d-flex justify-content-end mt-3 pt-3"
                    >
                      <Button
                        innerRef={mergeRefs([firstFocus, lastFocus])}
                        className="btn btn-primary btn-sm text-14-semibold"
                        type="button"
                        onClick={onUndo}
                      >
                        {intl.get('BTN_SURVEYS_SURVEY_UNDO')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        )}
      </TrapFocus>
    </Fade>
  );
};

export default SurveySendSuccessPopup;
