const Misc = {
  EffectiveVisualDelay: 100, // milliseconds //https://www.nngroup.com/articles/response-times-3-important-limits/
  ViaProjectsQuery: 'viaProjects=true',
  /* 
    MaximumPreciseFractionDigits: For values that require precision with decimal places.
    DO NOT UPDATE!
  */
  MaximumPreciseFractionDigits: 1,
  /* 
    MaximumFractionDigits: For values that don't need to be precise. 
    NOTE: If required to round all values on Insights, update this to 0.
  */
  MaximumFractionDigits: 1,
  MinimumFractionDigits: 0,
  MaximumCurrencyFractionDigits: 2,
  MinimumCurrencyFractionDigits: 2,
  DefaultBackupStatusLastRefreshedDate: 'N/A',
  DefaultBackupStatusRefreshTime: '',
  WidgetPercentageDigitLimit: 6,
  CompactionBoundary: 999999,
};

export default Misc;
