/* eslint-disable react/no-array-index-key */
import { ReactNode, FC as ReactFC } from 'react';

import NewLineToBrProps from './NewLineToBrProps';

const NewLineToBr: ReactFC<NewLineToBrProps> = (props: NewLineToBrProps) => {
  const { children } = props;
  return (
    <>
      {children
        .split('\\n')
        .reduce(
          (arr: Array<ReactNode>, line: string, index: number) =>
            arr.concat(
              <span key={index}>{line}</span>,
              <br key={`br_${index}`} />
            ),
          []
        )}
    </>
  );
};

export default NewLineToBr;
