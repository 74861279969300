import * as intl from 'react-intl-universal';
import * as yup from 'yup';

import EditPermissionFormValues from './EditPermissionFormValues';

class EditPermissionFormValidation {
  /**
   * Defines a Yup validation schema for the permission form
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema<
      Partial<EditPermissionFormValues>
    > = yup.object().shape({
      title: yup
        .string()
        .trim()
        .required(intl.get('ERR_PERMISSION_EDIT_TITLE_REQUIRED_MESSAGE')),
      description: yup
        .string()
        .trim()
        .required(intl.get('ERR_PERMISSION_EDIT_DESCRIPTION_REQUIRED_MESSAGE')),
      features: yup
        .array()
        .of(yup.string())
        .test({
          test: (array) => array.length > 0,
          message: intl.get('ERR_PERMISSION_EDIT_FEATURES_REQUIRED_MESSAGE'),
        }),
    });

    return validationSchema;
  }
}

export default EditPermissionFormValidation;
