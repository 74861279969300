/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Charts from 'constants/Charts';
import { getCompactNumber, getFormattedNumber } from 'helpers/NumberFormat';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import LineChart from '../../line-chart/LineChart';
import AttendanceProps from './AttendanceProps';

const Attendance: React.FC<AttendanceProps> = (props) => {
  const { data, meta, xAxisConfig, filters } = props;

  /**
   * Formats tick values
   *
   * @param value Tick value
   * @returns {string} Formatted tick value
   */
  const tickFormatter = (value: any): string =>
    `${getCompactNumber(Number(value), true)}%`;

  const sliceRenderer = (value): string =>
    `${getFormattedNumber(Number(value), true)}%`;

  const legendLabelRenderer = (name): string => String(name);

  /**
   * Render chart legends
   *
   * @returns {JSX.Element} Chart legend elements
   */
  const renderChartLegend = (): JSX.Element => (
    <div className="chart-legend inline">
      {data.map((d) => {
        let legendLabel = d.id;
        if (typeof d.id === 'string') {
          legendLabel = d.id.substring(0, d.id.lastIndexOf('.'));
        }
        return (
          <div className="item" key={d.id}>
            <span className="color" style={{ backgroundColor: d.color }} />
            <span className="label">
              <EllipsisTooltip
                tag="span"
                data-place="left"
                data-for="insTooltip"
                data-tip={legendLabel}
                data-class="overflow-wrap"
                className="truncate"
              >
                {legendLabel}
              </EllipsisTooltip>
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <LineChart
        data={data}
        meta={meta}
        xAxisConfig={xAxisConfig}
        filters={filters}
        legendLabelRenderer={legendLabelRenderer}
        tickFormatter={tickFormatter}
        sliceRenderer={sliceRenderer}
        enableArea={false}
        hasSideView
        isFacilitatorMetricsGraph
        upperTickLimit={Charts.MediumChartTicksUpperLimitL}
        lowerTickLimit={Charts.MediumChartTicksLowerLimit}
      />
      <div className="chart-footer">{renderChartLegend()}</div>
    </>
  );
};

export default React.memo(Attendance);
