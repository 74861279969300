import { ReactNode, FC as ReactFC } from 'react';

import { Field, FieldProps, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Label,
  ModalBody,
  Row,
  ModalHeader,
} from 'reactstrap';

import ErrorCodes from 'constants/ErrorCodes';
import Constraints from 'constants/forms/Constraints';
import ModulePaths from 'constants/ModulePaths';
import { getLocalizedErrorString } from 'helpers/ErrorFormat';
import SetNewPasswordFormValues, {
  SetNewPasswordFormValueTypes,
} from 'modules/public/auth/containers/set-new-password-view/SetNewPasswordFormValues';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import ImageComponent from 'shared/components/image/ImageComponent';
import NewPasswordField from 'shared/components/ins-form-fields/new-password-field/NewPasswordField';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';
import SetNewPasswordIllustration from 'shared/static/img/set-new-password-illustration.svg';
import setNewPasswordThumb from 'shared/static/img/thumbs/set_new_password_thumb.png';

import styles from './setNewPassword.module.scss';
import SetNewPasswordFields from './SetNewPasswordFields';
import SetNewPasswordProps from './SetNewPasswordProps';

const SetNewPassword: ReactFC<SetNewPasswordProps> = (
  props: SetNewPasswordProps
) => {
  const {
    isSubmitting,
    status,
    email,
    emailResendStatus,
    expiryTimeString,
    onResendEmailClick,
  } = props;

  const maxLength = Constraints.MaxFieldLength;
  /**
   * Renders appropriate API errors for each form field
   *
   * @param name Field name under which the error has to be displayed
   * @returns {string | null} The error message from the locale
   */
  const renderStatusError = (
    name: string,
    { form }: FieldProps<SetNewPasswordFormValueTypes, SetNewPasswordFormValues>
  ): string | null => {
    const { status: errorStatus } = form;
    if (errorStatus && errorStatus.msg && !isEmpty(errorStatus.fields)) {
      const fields = Object.keys(errorStatus.fields);
      if (fields.includes(name)) {
        const errorCode = errorStatus.fields[name];
        return intl.get(getLocalizedErrorString(String(errorCode)));
      }
    }
    return null;
  };

  /**
   * Renders common API errors for the form
   *
   * @returns {ReactNode} The error message
   */
  const renderCommonErrors = (): ReactNode => {
    if (status && status.msg) {
      const fieldKeys = Object.keys(status.fields);
      const commonFields = ['code', 'email'];
      let error: null | string | JSX.Element = null;

      if (fieldKeys.length === 0) {
        error = status.msg;
      } else if (commonFields.includes(fieldKeys[0])) {
        const errorCode = status.fields[fieldKeys[0]];
        if (errorCode === ErrorCodes.ExpiredVerificationCode) {
          error = (
            <>
              {intl.get(getLocalizedErrorString(String(errorCode)), {
                expiry: expiryTimeString,
              })}{' '}
              <button
                type="button"
                onClick={(): void => onResendEmailClick(email)}
                disabled={emailResendStatus === Status.Loading}
              >
                {intl.get('BTN_FORGOT_PASSWORD_PENDING_RESEND_EMAIL')}
              </button>
            </>
          );
        } else if (errorCode === ErrorCodes.InvalidVerificationCode) {
          error = (
            <>
              {intl.get(getLocalizedErrorString(String(errorCode)))}
              {'  '}
              <InsLink
                to={`${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`}
              >
                {intl.get('BTN_GO_TO_LOGIN')}
              </InsLink>
            </>
          );
        } else {
          error = intl.get(getLocalizedErrorString(String(errorCode)));
        }
      } else {
        error = intl.get('ERR_SET_NEW_PASSWORD_FAILURE');
      }

      if (error) {
        return (
          <Row>
            <Col>
              <FormGroup>
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </FormGroup>
            </Col>
          </Row>
        );
      }
    }
    return null;
  };

  return (
    <BlockUi tag="div" blocking={isSubmitting}>
      <Form noValidate>
        <ModalHeader className="increase-font pb-0 border-bottom-0">
          {intl.get('LBL_SET_NEW_PASSWORD_TITLE')}
        </ModalHeader>
        <ModalBody>
          {renderCommonErrors()}
          <Row>
            <Col xs="auto" className={styles.image}>
              <ImageComponent
                loading="eager"
                src={SetNewPasswordIllustration}
                alt="Set New Password"
                thumb={setNewPasswordThumb}
              />
            </Col>
            <Col>
              <input
                type="text"
                name="username"
                value={email}
                autoComplete="username"
                onChange={noop}
                style={{ display: 'none' }}
              />
              <FormGroup>
                <Label htmlFor={SetNewPasswordFields.PASSWORD}>
                  {intl.get('LBL_SET_NEW_PASSWORD_NEW_PASSWORD')}
                </Label>
                <Field
                  autoComplete="new-password"
                  type="password"
                  name={SetNewPasswordFields.PASSWORD}
                  maxLength={maxLength}
                  tabIndex={0}
                  className="form-control"
                  component={NewPasswordField}
                />
                <EnhancedFormikError
                  name={SetNewPasswordFields.PASSWORD}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor={SetNewPasswordFields.CONFIRMED_PASSWORD}>
                  {intl.get('LBL_SET_NEW_PASSWORD_CONFIRM_NEW_PASSWORD')}
                </Label>
                <Field
                  autoComplete="new-password"
                  type="password"
                  name={SetNewPasswordFields.CONFIRMED_PASSWORD}
                  maxLength={maxLength}
                  tabIndex={0}
                  className="form-control"
                  component={NewPasswordField}
                />
                <EnhancedFormikError
                  name={SetNewPasswordFields.CONFIRMED_PASSWORD}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex">
            <Col className="d-flex justify-content-end align-items-center">
              <FormText className="text-muted">
                {intl.get('LBL_STILL_NEED_HELP')}{' '}
                <a
                  href="https://support.dreamstartlabs.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {intl.get('LBL_CONTACT_US')}
                </a>
              </FormText>
            </Col>
            <Col xs="auto">
              <Button
                type="submit"
                className="btn btn-primary"
                tabIndex={0}
                disabled={isSubmitting}
              >
                {intl.get('BTN_SAVE_PASSWORD')}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Form>
    </BlockUi>
  );
};

export default SetNewPassword;
