import { useEffect, FC as ReactFC } from 'react';

import * as d3 from 'd3';
import ReactTooltip from 'react-tooltip';

import { getColorFor } from 'helpers/ColorHelper';
import { getFormattedNumber } from 'helpers/NumberFormat';
import DonutProps from 'shared/components/ins-charts/donut/DonutProps';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

import styles from './Donut.module.scss';

const Donut: ReactFC<DonutProps> = (props) => {
  const defaultOpacity = 1;
  const selectedOpacity = 0.6;
  const donutThickness = 0.07;
  const animationDuration = 300;

  const { values, dimension, showCount = false } = props;
  const { ref, computedDimension } = useDimension(dimension);
  const getCurrencyString = useCurrency();
  const { width, height, boundedHeight, boundedWidth } = computedDimension;

  const innerRadius = Math.min(boundedHeight, boundedWidth) / 2;
  const outerRadius = innerRadius - height * donutThickness;

  useEffect(() => {
    const draw = (): void => {
      const svg = d3.select(ref.current).select('svg');

      const pieGenerator = d3
        .pie<InsChartValue>()
        .value((d) => d.count)
        .sort((d) => d.count);

      const arcGenerator = d3
        .arc<unknown>()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

      const tooltipGenerator = (value: InsChartValue): string => {
        const { name, percentage, count } = value;
        if (showCount) {
          return `
            <div class="text-center">
              <div class="tool-tip-label ${styles.primaryLabel}">${name}:</div>
              <div class="tool-tip-value ${
                styles.primaryValue
              }">${getFormattedNumber(percentage, true)}%</div>
              <div class="tool-tip-value ${
                styles.primaryValue
              }">${getCurrencyString(count, true)}</div>
            </div>
          `;
        }
        return `
          <div>
            <span class="tool-tip-label">${name}:</span>
            <span class="tool-tip-value">${getFormattedNumber(
              percentage,
              true
            )}%</span>
          </div>
        `;
      };

      // eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
      const showTooltip = function (this: any): void {
        d3.select(this)
          .transition()
          .duration(animationDuration)
          .attr('opacity', selectedOpacity);
      };

      // eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
      const hideTooltip = function (this: any): void {
        d3.select(this)
          .transition()
          .duration(animationDuration)
          .attr('opacity', defaultOpacity);
      };

      const data = pieGenerator(values);

      svg
        .select('g')
        .selectAll('path')
        .data(data)
        .join('path')
        .attr('d', arcGenerator)
        .attr('fill', (d) => getColorFor(d.data.group))
        .attr('opacity', defaultOpacity)
        .attr('data-for', 'svgTooltip')
        .attr('data-tip', (d) => tooltipGenerator(d.data))
        .on('mouseenter', showTooltip)
        .on('mouseleave', hideTooltip);

      ReactTooltip.rebuild();
    };

    if (values && ref != null) {
      draw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, innerRadius, outerRadius, ref, values, showCount, width]);

  return (
    <div
      ref={ref}
      className="ins-custom-chart-wrapper"
      style={{ height: `${height}px` }}
    >
      <ReactTooltip id="svgTooltip" type="light" html />
      <svg width={width} height={height}>
        <g
          transform={`translate(${width / 2}, ${height / 2})`}
          width={boundedWidth}
          height={boundedHeight}
        />
      </svg>
    </div>
  );
};

export default Donut;
