import { createBrowserHistory } from 'history';

import { pageViewGA } from 'helpers/GoogleAnalyticsHelper';

const history = createBrowserHistory();

/**
 * tracks google analytics page views check App.tsx for refresh
 * tracking as history would not register changes on refresh
 */
history.listen((location) => {
  pageViewGA(location.pathname + location.search);
});

export default history;
