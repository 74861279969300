import { useEffect, FC as ReactFC, useState } from 'react';

import * as d3 from 'd3';
import ReactTooltip from 'react-tooltip';

import Misc from 'constants/misc/Misc';
import { insightsColors } from 'helpers/ColorHelper';
import { getFormattedNumber } from 'helpers/NumberFormat';
import DonutAreaProps from 'shared/components/ins-charts/donut-area/DonutAreaProps';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

const DonutArea: ReactFC<DonutAreaProps> = (props) => {
  const donutThickness = 0.07;
  const angelFactor = Math.PI * 2;

  const { value, dimension, colorCoded = true } = props;
  const { ref, computedDimension } = useDimension(dimension);
  const { width, height, boundedHeight, boundedWidth } = computedDimension;

  const chartArea = 0.7;
  const innerRadius = (Math.min(boundedHeight, boundedWidth) / 2) * chartArea;
  const outerRadius = innerRadius - height * donutThickness;
  const centerPoint = innerRadius;
  const cornerRadius = 0;
  const startAngle = 0;

  const [digits, setDigits] = useState<number>(0);

  useEffect(() => {
    const draw = (): void => {
      const svg = d3.select(ref.current).select('svg');

      const colorFinder = (): string => {
        let colorCode = insightsColors.insightSecondary;

        if (colorCoded) {
          if (value.percentage <= 25) {
            colorCode = insightsColors.insightJaffa;
          } else if (value.percentage > 25 && value.percentage <= 50) {
            colorCode = insightsColors.insightYellow;
          }
        }

        return colorCode;
      };

      const textFormatter = (d: number): string => {
        setDigits(d.toString().length);
        return `${getFormattedNumber(d, false)}%`;
      };

      const arcGenerator = d3
        .arc<unknown>()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .cornerRadius(cornerRadius)
        .startAngle(startAngle);

      svg
        .select('g')
        .selectAll('path')
        .datum({ endAngle: angelFactor })
        .join('path')
        .attr('fill', insightsColors.insightSpringWood)
        .attr('d', arcGenerator);

      svg
        .select('g')
        .append('path')
        .datum({ endAngle: value.percentage * 0.01 * angelFactor })
        .attr('fill', colorFinder())
        .attr('d', arcGenerator);

      svg
        .select('g')
        .selectAll('text')
        .data([value.percentage])
        .join('text')
        .attr(
          'class',
          `label-value ${
            digits >= Misc.WidgetPercentageDigitLimit ? 'make-smaller' : ''
          }`
        )
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .text(textFormatter);

      ReactTooltip.rebuild();
    };

    if (value && ref != null) {
      draw();
    }
  }, [
    digits,
    angelFactor,
    centerPoint,
    height,
    innerRadius,
    outerRadius,
    ref,
    value,
    width,
    colorCoded,
  ]);

  return (
    <div
      ref={ref}
      className="ins-custom-chart-wrapper"
      style={{ height: `${height}px` }}
    >
      <ReactTooltip id="svgTooltip" type="light" html />
      <svg width={width} height={height}>
        <g
          transform={`translate(${width / 2}, ${height / 2})`}
          width={boundedWidth}
          height={boundedHeight}
        />
      </svg>
    </div>
  );
};

export default DonutArea;
