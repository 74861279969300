/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-types */
import * as Sentry from '@sentry/react';
import {
  AxiosError,
  AxiosRequestConfig,
  Canceler,
  CancelTokenSource,
  Method,
} from 'axios';
import _ from 'lodash';
import queryString from 'query-string';

import LoginResponse from 'api/auth/responses/LoginResponse';
import BaseResponse from 'api/common/responses/BaseResponse';
import ApiError from 'api/common/types/ApiError';
import StorageKeys from 'constants/StorageKeys';
import { getFirstError } from 'helpers/ErrorFormat';
import { sendExceptionGA } from 'helpers/GoogleAnalyticsHelper';
import InsightsLogger from 'helpers/logging/InsightsLogger';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import EventKey from 'shared/enums/EventKey';
import HttpStatus from 'shared/enums/HttpStatus';
import { EventBus } from 'shared/events/EventBus';

import Axios, { CancelToken } from './Axios';
import QueryInjector from './QueryInjector';
import {
  GetAsyncConfig,
  PostAsyncConfig,
  RefreshTokenConfig,
  TokenAsyncConfig,
} from './types/AxiosTypes';
import BrowserErrors from './types/BrowserErrors';

export default abstract class ApiBase {
  private baseUrl = '';

  cancelerMap: Map<string, Canceler>;

  constructor() {
    if (process.env.REACT_APP_API_URL) {
      this.baseUrl = `${process.env.REACT_APP_API_URL}`;
    }
    this.cancelerMap = new Map();
  }

  /**
   * Cancel specific request or all the ongoing requests
   *
   * @param keys @type {string | undefined} Cancellation key
   */
  cancelRequests(keys?: string | string[]): void {
    const cancelItem = (id: string): void => {
      const canceler = this.cancelerMap.get(id);
      if (canceler) {
        canceler();
        this.cancelerMap.delete(id);
      }
    };
    if (keys) {
      const items = Array.isArray(keys) ? keys : [keys];
      items.forEach(cancelItem);
    } else {
      this.cancelerMap.forEach((canceler) => canceler());
      this.cancelerMap.clear();
    }
  }

  async GetAsync<T extends BaseResponse>({
    action,
    anonymous,
    includeAuthToken,
    headers,
    tag,
    cancelSource,
    queryParams,
  }: GetAsyncConfig): Promise<T> {
    const config = {
      action,
      anonymous: anonymous ?? false,
      includeAuthToken: includeAuthToken ?? true,
      headers: headers ?? {},
      tag,
      cancelSource,
      queryParams,
    };

    return this.ExecuteAsync(
      config.action,
      'GET',
      config.anonymous,
      config.includeAuthToken,
      null,
      config.headers,
      config.cancelSource,
      config.queryParams,
      config.tag
    );
  }

  async PostAsync<T extends BaseResponse>({
    action,
    anonymous,
    includeAuthToken,
    body,
    headers,
    tag,
    cancelSource,
    queryParams,
  }: PostAsyncConfig): Promise<T> {
    const config = {
      action,
      anonymous: anonymous ?? false,
      includeAuthToken: includeAuthToken ?? true,
      body: body ?? {},
      headers: headers ?? {},
      tag,
      cancelSource,
      queryParams,
    };

    return this.ExecuteAsync(
      config.action,
      'POST',
      config.anonymous,
      config.includeAuthToken,
      config.body,
      config.headers,
      config.cancelSource,
      config.queryParams,
      config.tag
    );
  }

  async PutAsync<T extends BaseResponse>({
    action,
    anonymous,
    includeAuthToken,
    body,
    headers,
    tag,
    cancelSource,
    queryParams,
  }: PostAsyncConfig): Promise<T> {
    const config = {
      action,
      anonymous: anonymous ?? false,
      includeAuthToken: includeAuthToken ?? true,
      body: body ?? {},
      headers: headers ?? {},
      tag,
      cancelSource,
      queryParams,
    };

    return this.ExecuteAsync(
      config.action,
      'PUT',
      config.anonymous,
      config.includeAuthToken,
      config.body,
      config.headers,
      config.cancelSource,
      config.queryParams,
      config.tag
    );
  }

  async DeleteAsync<T extends BaseResponse>({
    action,
    anonymous,
    includeAuthToken,
    body,
    headers,
    tag,
    cancelSource,
    queryParams,
  }: PostAsyncConfig): Promise<T> {
    const config = {
      action,
      anonymous: anonymous ?? false,
      includeAuthToken: includeAuthToken ?? true,
      body: body ?? {},
      headers: headers ?? {},
      tag,
      cancelSource,
      queryParams,
    };

    return this.ExecuteAsync(
      config.action,
      'DELETE',
      config.anonymous,
      config.includeAuthToken,
      config.body,
      config.headers,
      config.cancelSource,
      config.queryParams,
      config.tag
    );
  }

  async GetAsyncBlob({
    action,
    anonymous,
    includeAuthToken,
    headers,
    tag,
    cancelSource,
    queryParams,
  }: GetAsyncConfig): Promise<Blob> {
    const config = {
      action,
      anonymous: anonymous ?? false,
      includeAuthToken: includeAuthToken ?? true,
      headers: headers ?? {},
      tag,
      cancelSource,
      queryParams,
    };

    return this.ExecuteAsyncBlob(
      config.action,
      'GET',
      config.anonymous,
      config.includeAuthToken,
      null,
      config.headers,
      config.cancelSource,
      config.queryParams,
      config.tag
    );
  }

  async TokenAsync({ body, tag }: TokenAsyncConfig): Promise<LoginResponse> {
    const config = {
      action: 'auth/login',
      anonymous: true,
      includeAuthToken: false,
      body: body ?? {},
      headers: {},
      cancelSource: undefined,
      queryParams: {},
      tag,
    };

    const response = await this.ExecuteAsync<LoginResponse>(
      config.action,
      'POST',
      config.anonymous,
      config.includeAuthToken,
      config.body,
      config.headers,
      config.cancelSource,
      config.queryParams,
      config.tag
    );

    AuthStorageService.OnLogin(response);
    return response;
  }

  async RefreshTokenAsync<T extends BaseResponse>(
    refreshTokenConfig?: RefreshTokenConfig
  ): Promise<T> {
    const method: Method = 'POST';
    const action = 'auth/refresh';

    try {
      const requestInfo: AxiosRequestConfig = {
        method,
      };

      const refreshToken = AuthStorageService.GetItem<string>(
        StorageKeys.RefreshKey
      );
      if (!refreshToken) {
        throw new Error('Refresh token not found');
      }

      const headers = { Authorization: `Bearer ${refreshToken}` };
      this.setDefaultHeaders(headers);
      requestInfo.headers = headers;

      if (refreshTokenConfig) {
        const { tag } = refreshTokenConfig;
        if (tag) {
          requestInfo.cancelToken = new CancelToken((canceler) => {
            this.cancelerMap.set(tag, canceler);
          });
        }
      }

      const url = `${this.baseUrl}${action}`;
      requestInfo.url = url;

      const response = await Axios(requestInfo);
      AuthStorageService.OnRefresh(response.data);

      return response.data;
    } catch (error) {
      // prettier-ignore
      let newApiError: ApiError = new ApiError(`Request failed with some error`);

      const { response } = error as AxiosError;
      if (response) {
        const { status, statusText } = response;

        newApiError = new ApiError(
          `Request failed with status ${status}`,
          status,
          statusText
        );

        if (response.data) {
          newApiError.response = response.data;
        }
      } else {
        newApiError = new ApiError(
          `Request failed with error: ${String(error)}`
        );
      }

      const errorCode = getFirstError(newApiError);
      // Session has expired.
      AuthStorageService.Logout(errorCode);

      sendExceptionGA(`${method}:${action} ${newApiError.message}`);

      return Promise.reject(newApiError);
    }
  }

  private static generateURL(
    action: string,
    queryParams?: Record<string, any> | string
  ): string {
    let params = '';
    if (queryParams) {
      if (typeof queryParams === 'string') {
        params += `?${queryParams}`;
      } else if (!_.isEmpty(queryParams)) {
        params += `?${queryString.stringify(queryParams)}`;
      }
    }

    return action + params;
  }

  // IMPORTANT : Please do not change the order of the params since this order is used inside the decorators
  @InsightsLogger.performanceWrap()
  @QueryInjector.inject()
  async ExecuteAsync<T extends BaseResponse>(
    action: string,
    method: Method,
    anonymous = false,
    includeAuthToken = true,
    body: {} | null = null,
    headers = {},
    cancelSource?: CancelTokenSource,
    queryParams?: Record<string, any> | string,
    tag?: string // Do not remove this param ❌
  ): Promise<T> {
    try {
      const requestInfo: AxiosRequestConfig = {
        method,
      };
      if (body) {
        requestInfo.data = JSON.stringify(body);
      }

      const newHeaders = { ...headers };
      if (!anonymous && includeAuthToken) {
        this.addAuthorizationTokenAsync(newHeaders);
      }

      if (cancelSource) {
        requestInfo.cancelToken = cancelSource.token;
      }

      this.setDefaultHeaders(newHeaders);
      requestInfo.headers = newHeaders;

      const url = `${this.baseUrl}${ApiBase.generateURL(action, queryParams)}`;
      requestInfo.url = url;

      const response = await Axios(requestInfo);

      return new Promise<T>((resolve, reject): void => {
        resolve(response.data);
      });
    } catch (error) {
      // prettier-ignore
      let newApiError: ApiError = new ApiError(`Request failed with some error ${String(error)}`);
      const { response } = error as AxiosError;
      if (response) {
        const { status, statusText } = response;
        newApiError = new ApiError(
          `Request failed with status ${status}`,
          status,
          statusText
        );

        return new Promise<T>((resolve, reject): void => {
          InsightsLogger.logError(
            newApiError,
            Sentry.Severity.Error,
            action,
            method
          );

          if (
            status === HttpStatus.UNAUTHORIZED &&
            AuthStorageService.IsLoggedIn()
          ) {
            if (response.data) {
              newApiError.response = response.data;
            }
            const errorCode = getFirstError(newApiError);
            // Session has expired.
            AuthStorageService.Logout(errorCode);
          } else if (status === HttpStatus.FORBIDDEN) {
            newApiError.response = response.data;
            reject(newApiError);
            // Fire an event when forbidden status occurred from API
            EventBus.getInstance().dispatch(EventKey.RequestForbidden);
          } else {
            newApiError.response = response.data;
            reject(newApiError);
          }
        });
      }

      const stringifiedError = String(error);

      if (stringifiedError === BrowserErrors.Cancel) {
        /* An unresolved promise is returned here, in the case of a browser request
        cancellation (which throws an error), so as to not trigger an
        error in the container/component that fired the request. In turn,
        the error toast will not be displayed to the user.
         */
        return new Promise(() => undefined);
      }

      newApiError = new ApiError(
        `Request failed with error: ${stringifiedError}`
      );
      InsightsLogger.logError(
        newApiError,
        Sentry.Severity.Critical,
        action,
        method
      );
      sendExceptionGA(`${method}:${action} ${newApiError.message}`);
      return new Promise<T>((resolve, reject): void => {
        reject(newApiError);
      });
    }
  }

  // IMPORTANT : Please do not change the order of the params since this order is used inside the decorators
  @QueryInjector.inject()
  async ExecuteAsyncBlob(
    action: string,
    method: Method,
    anonymous = false,
    includeAuthToken = true,
    body: {} | null = null,
    headers = {},
    cancelSource?: CancelTokenSource,
    queryParams?: Record<string, any> | string,
    tag?: string // Do not remove this param ❌
  ): Promise<Blob> {
    try {
      const requestInfo: AxiosRequestConfig = {
        method,
      };
      if (body) {
        requestInfo.data = JSON.stringify(body);
      }

      const newHeaders = { ...headers };
      if (!anonymous && includeAuthToken) {
        this.addAuthorizationTokenAsync(newHeaders);
      }

      if (cancelSource) {
        requestInfo.cancelToken = cancelSource.token;
      }

      this.setDefaultHeaders(newHeaders);
      requestInfo.headers = newHeaders;

      const url = `${this.baseUrl}${ApiBase.generateURL(action, queryParams)}`;
      requestInfo.url = url;

      requestInfo.responseType = 'blob';

      const response = await Axios(requestInfo);

      return new Promise<Blob>((resolve, reject): void => {
        resolve(new Blob([response.data]));
      });
    } catch (error) {
      // prettier-ignore
      let newApiError: ApiError = new ApiError(`Request failed with some error`);

      const { response } = error as AxiosError;
      if (response) {
        const { status, statusText } = response;
        newApiError = new ApiError(
          `Request failed with status ${status}`,
          status,
          statusText
        );

        return new Promise<Blob>((resolve, reject): void => {
          InsightsLogger.logError(
            newApiError,
            Sentry.Severity.Error,
            action,
            method
          );

          if (
            status === HttpStatus.UNAUTHORIZED &&
            AuthStorageService.IsLoggedIn()
          ) {
            if (response.data) {
              newApiError.response = response.data;
            }
            const errorCode = getFirstError(newApiError);
            // Session has expired.
            AuthStorageService.Logout(errorCode);
          } else if (status === HttpStatus.FORBIDDEN) {
            newApiError.response = response.data;
            reject(newApiError);
            // Fire an event when forbidden status occurs from API
            EventBus.getInstance().dispatch(EventKey.RequestForbidden);
          } else {
            newApiError.response = response.data;
            reject(newApiError);
          }
        });
      }

      newApiError = new ApiError(`Request failed with error: ${String(error)}`);
      InsightsLogger.logError(
        newApiError,
        Sentry.Severity.Critical,
        action,
        method
      );
      sendExceptionGA(`${method}:${action} ${newApiError.message}`);
      return new Promise<Blob>((resolve, reject): void => {
        reject(newApiError);
      });
    }
  }

  /* eslint-disable */
  private setDefaultHeaders(headers: any): void {
    if (!('Accept' in headers)) {
      headers.Accept = 'application/json';
    }
    if (!('Content-Type' in headers)) {
      headers['Content-Type'] = 'application/json';
    }
  }

  private addAuthorizationTokenAsync(headers: any): void {
    const token = AuthStorageService.GetItem<string>(StorageKeys.TokenKey);
    if (!token) {
      throw new Error('Token not found');
    }
    headers.Authorization = `Bearer ${token}`;
  }
  /* eslint-enable */
}
