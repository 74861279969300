type ImageFile = File & {
  image: HTMLImageElement;
};
class ProfileFormValues {
  firstName: string;

  lastName: string;

  phoneNumber: string;

  image: ImageFile | null;

  password: string;

  rememberMe: boolean;

  termsAccepted: boolean;

  /**
   *
   */
  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
    this.image = null;
    this.password = '';
    this.rememberMe = false;
    this.termsAccepted = false;
  }
}

export type ProfileFormValueTypes = boolean | string | File;

export default ProfileFormValues;
