import * as intl from 'react-intl-universal';
import * as yup from 'yup';

import SetNewPasswordFormValues from './SetNewPasswordFormValues';

class SetNewPasswordFormValidation {
  /**
   * Defines a Yup validation schema for the set new password form
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const passwordRegex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      `^(?=.*\\d)(?=.*[!"#$%&'()*+,./:;<=>?@\^_{|}~-])(?=.*[a-z])(?=.*[A-Z]).{4,}$`
    );

    const validationSchema: yup.ObjectSchema<
      Partial<SetNewPasswordFormValues>
    > = yup.object().shape({
      password: yup
        .string()
        .required(intl.get('ERR_FORM_PASSWORD_REQUIRED_MESSAGE'))
        .matches(
          passwordRegex,
          intl.get('ERR_REG_FORM_PASSWORD_STRENGTH_MESSAGE')
        ),
      confirmedPassword: yup
        .string()
        // .oneOf(
        //   [yup.ref('password'), null],
        //   intl.get('ERR_REG_FORM_CONFIRM_PW_MUST_MATCH')
        // )
        .required(intl.get('ERR_REG_FORM_CONFIRM_PW_CANNOT_BE_EMPTY'))
        .test(
          'oneOf',
          intl.get('ERR_REG_FORM_CONFIRM_PW_MUST_MATCH'),
          function oneOf(value): boolean | yup.ValidationError {
            const passwordMatches = passwordRegex.test(this.parent.password);
            if (passwordMatches) {
              return value === this.parent.password || value === null;
            }
            return true;
          }
        ),
    });

    return validationSchema;
  }
}

export default SetNewPasswordFormValidation;
