import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import styles from '../surveysHistoryDataTable.module.scss';
import DummyRowProps from './DummyRowProps';

const DummyRow: ReactFC<DummyRowProps> = (props: DummyRowProps) => {
  const { headers } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const headersList = headers[0].headers; // nosonar

  /**
   * Renders action buttons
   *
   * @param row row props from react-table
   * @returns JSX.ELement action buttons
   */
  const renderActions = (): JSX.Element => (
    <div className={`${styles.actions} justify-content-between`}>
      <button className="ml-auto mr-3 justify-content-start" disabled>
        <i className="icon-export" />
        {intl.get('BTN_MESSAGING_HISTORY_EXPORT')}
      </button>
      <a
        target="_blank"
        className={`btn btn-secondary ${styles.disabledLink} justify-content-end`}
        rel="noopener noreferrer"
        href={`${String(process.env.REACT_APP_KOBO_URL)}`}
      >
        {intl.get('BTN_SURVEYS_SURVEY_HISTORY_VIEW_MORE')}
      </a>
    </div>
  );

  return (
    <tr>
      {headersList.map((cell) => (
        <td
          key={cell.id}
          className={cell.className}
          data-title={cell.dataTitle}
        >
          <span
            className={`table-content text-14-semibold extend-length ${styles.wrapContent}`}
          >
            {cell.id === 'actions' ? renderActions() : intl.get('LBL_NA')}
          </span>
        </td>
      ))}
    </tr>
  );
};

export default DummyRow;
