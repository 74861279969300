enum MembershipMetricType {
  Groups = 'GROUPS',
  Members = 'MEMBERS',
  Dropout = 'DROPOUT',
  Attendance = 'ATTENDANCE',
  // Below keys are used internally in frontend
  AttendanceWithRep = 'ATTENDANCE_WITH_REP',
  GroupCount = 'GROUP_COUNT',
  MemberCount = 'MEMBER_COUNT',
  AttendanceCount = 'ATTENDANCE_COUNT',
  RepCount = 'REP_COUNT',
  DropoutCount = 'DROPOUT_COUNT',
  InactiveCount = 'INACTIVE_COUNT',
  Inactive = 'INACTIVE',
}

export default MembershipMetricType;
