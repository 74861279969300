import WidgetConfigs from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetConfigs';
import WidgetTargetConfigs from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetConfigs';
import WidgetTargetType from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetType';
import WidgetType from 'modules/private/dashboard/components/widget-metrics/widgets/WidgetType';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';

class WidgetPreferenceViewModel {
  position: number;

  targetTo: number | string;

  targetFrom: number | string;

  target: number | string;

  type: SelectFieldOption;

  targetType: SelectFieldOption;

  constructor() {
    this.position = 0;
    this.targetTo = '';
    this.targetFrom = '';
    this.target = '';

    // react-select requires an Option Object as the value.
    this.type = {
      value: WidgetType.Age,
      label: WidgetConfigs.GetWidgetConfig(WidgetType.Age).name,
      isDisabled: false,
    };
    this.targetType = {
      value: WidgetTargetType.None,
      label: WidgetTargetConfigs.GetWidgetTargetConfig(WidgetTargetType.None)
        .name,
      isDisabled: false,
    };
  }
}

export default WidgetPreferenceViewModel;
