class InviteUsersViewModel {
  id: string;

  jobRoleId: string;

  isImplementingPartner: boolean;

  projectId?: string;

  permission: string;

  countryDataAccess: string[];

  supervisorId: string | null;

  email: string;

  constructor(
    id: string,
    email: string,
    jobRoleId: string,
    isImplementingPartner: boolean,
    permission: string,
    countryDataAccess: string[],
    supervisorId: string,
    projectId?: string
  ) {
    this.id = id;
    this.email = email;
    this.jobRoleId = jobRoleId;
    this.isImplementingPartner = isImplementingPartner;
    this.permission = permission;
    this.countryDataAccess = countryDataAccess;
    this.supervisorId = supervisorId === 'NONE' ? null : supervisorId;
    this.projectId = projectId;
  }
}

export default InviteUsersViewModel;
