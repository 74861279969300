/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProps } from 'formik';
import { Props as ReactSelectProps } from 'react-select';

import Status from 'shared/enums/Status';
import JobRoleOption from 'shared/modules/users/components/invite-users-field-array/invite-users-form-item/job-role-async-select/JobRoleOption';
import PermissionOption from 'shared/modules/users/components/invite-users-field-array/invite-users-form-item/permission-select/PermissionOption';

import { UserProfileRestrictions } from '../../containers/user-profile-view/UserProfileViewState';
import UserProfileDetails, {
  CountryOption,
  ImageFile,
} from './UserProfileDetails';

interface UserProfileLeftPanelProps
  extends FormikProps<UserProfileLeftPanelFormValues> {
  data: UserProfileDetails;
  jobRoles: JobRoleOption[];
  jobRolesStatus: Status;
  onCreateJobRole: (value: string) => void;
  createJobRoleStatus: Status;
  createJobRoleError: null | string;
  clearJobRoleError: () => void;
  countries: CountryOption[];
  countriesStatus: Status;
  permissionLevels: PermissionOption[];
  permissionLevelsStatus: Status;
  canDeactivateUsers: boolean;
  restrictions: UserProfileRestrictions;
  togglePermissionsModal: () => void;
  toggleDeactivateModal: (id?: string) => void;
}

export interface UserProfileLeftPanelFormValues {
  name: string;
  image: ImageFile | null;
  jobRole: string;
  permissionLevel: string;
  countryDataAccess: string[];
  email: string;
  phoneNumber: string;
}

export enum UserProfileFields {
  NAME = 'name',
  IMAGE = 'image',
  JOB_ROLE = 'jobRole',
  PERMISSION_LEVEL = 'permissionLevel',
  COUNTRY_DATA_ACCESS = 'countryDataAccess',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  DEACTIVATE = 'deactivate',
  ADD_TO_PROJECT = 'addToProject',
  REMOVE_FROM_PROJECT = 'removeFromProject',
  UNASSIGN_SUPERVISEE = 'unassignSupervisee',
}

export interface UserProfileLeftPanelFormFieldError {
  id: string;
  fieldName: string;
  errorCode: string;
  errorMessage: string;
}

export type SelectOnChangeCurried = (
  field: string,
  isMulti?: boolean
) => ReactSelectProps['onChange'];

export default UserProfileLeftPanelProps;
