import { getFormattedNumber } from 'helpers/NumberFormat';

type GroupMetricPercentile =
  | 'LOWER_QUARTILE'
  | 'MEDIAN_QUARTILE'
  | 'UPPER_QUARTILE';

class GroupMetricQuartile {
  rangeFrom: number | string;

  rangeTo: number | string;

  percentile: GroupMetricPercentile;

  count: number | string;

  constructor(
    percentile?: GroupMetricPercentile,
    count?: number | string,
    rangeFrom?: number | string,
    rangeTo?: number | string
  ) {
    this.rangeFrom = rangeFrom || 0;
    this.rangeTo = rangeTo || 0;
    this.percentile = percentile || 'LOWER_QUARTILE';
    this.count = count || 0;
  }

  getCount(): string {
    if (typeof this.count === 'number') {
      return getFormattedNumber(this.count, false);
    }
    return this.count;
  }
}

export default GroupMetricQuartile;
