import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Col, Row, Button, Collapse } from 'reactstrap';

import IHaveNoGroup from 'shared/static/img/i-need-org-id.svg';
import IHaveGroup from 'shared/static/img/org-id.svg';

import styles from './getting-started-steps.module.scss';
import SettingUpGroupDataProps from './SettingUpGroupDataProps';

const SettingUpGroupData: ReactFC<SettingUpGroupDataProps> = (
  props: SettingUpGroupDataProps
) => {
  const {
    order,
    stepCompleted,
    active,
    toggle,
    haveGroups,
    onGroupOptionChange,
    handleReadClick,
  } = props;
  return (
    <Col xs="12" data-cy={`gs-collapse-${order}`}>
      {active !== order && (
        <Row
          onClick={(): void => toggle(order)}
          className="collapse-header"
          data-cy={`gs-head-${order}`}
        >
          <Col xs="auto">
            {stepCompleted ? (
              <span className="check">
                <i className="icon-check" />
              </span>
            ) : (
              <span className="number">{order}</span>
            )}
          </Col>
          <Col className="text-14-semibold">
            {intl.get('LBL_GSG_SETTING_UP_GROUP_DATA')}
          </Col>
        </Row>
      )}
      <Collapse isOpen={active === order}>
        <Row className="collapse-body">
          <Col xs="auto">
            {stepCompleted ? (
              <span className="check">
                <i className="icon-check" />
              </span>
            ) : (
              <span className="number">{order}</span>
            )}
          </Col>
          <Col className="col-4">
            {active === order && (
              <p className="text-14-semibold">
                {intl.get('LBL_GSG_SETTING_UP_GROUP_DATA')}
              </p>
            )}
            <p className="text-14-medium mb-0">
              {intl.get('LBL_GSG_SETTING_UP_GROUP_DATA_TEXT')}
            </p>
          </Col>
          <Col>
            <div
              className={`insight-card cursor-pointer ${styles.card} ${
                haveGroups ? styles.active : ''
              }`}
              onKeyPress={(): void => onGroupOptionChange(true)}
              onClick={(): void => onGroupOptionChange(true)}
              tabIndex={0}
              role="button"
            >
              <Row>
                <Col xs="auto pr-0">
                  <img src={IHaveGroup} alt="I have groups" />
                </Col>
                <Col className="d-flex align-items-center">
                  <div className={styles.title}>
                    <p className="text-14-semibold">
                      {intl.get('LBL_GSG_I_HAVE_GROUPS')}
                      <span className={styles.check}>
                        <i className="icon-check" />
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className={`insight-card cursor-pointer ${styles.card} ${
                haveGroups ? '' : styles.active
              }`}
              onClick={(): void => onGroupOptionChange(false)}
              onKeyPress={(): void => onGroupOptionChange(false)}
              tabIndex={0}
              role="button"
            >
              <Row>
                <Col xs="auto pr-0">
                  <img src={IHaveNoGroup} alt="I do not have groups" />
                </Col>
                <Col className="d-flex align-items-center">
                  <div className={styles.title}>
                    <p className="text-14-semibold">
                      {intl.get('LBL_GSG_I_DO_NOT_HAVE_GROUPS')}
                      <span className={styles.check}>
                        <i className="icon-check" />
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              color="primary"
              data-cy="gs-btn-read"
              onClick={handleReadClick}
            >
              {intl.get('BTN_GSG_READ_GUIDE')}
            </Button>
          </Col>
        </Row>
      </Collapse>
    </Col>
  );
};

export default SettingUpGroupData;
