const year = new Date().getFullYear();

const chartEmptyDummyData = [
  { x: `${year}-01-01`, y: null },
  { x: `${year}-02-01`, y: null },
  { x: `${year}-03-01`, y: null },
  { x: `${year}-04-01`, y: null },
  { x: `${year}-05-01`, y: null },
  { x: `${year}-06-01`, y: null },
  { x: `${year}-07-01`, y: null },
  { x: `${year}-08-01`, y: null },
  { x: `${year}-09-01`, y: null },
  { x: `${year}-10-01`, y: null },
  { x: `${year}-11-01`, y: null },
  { x: `${year}-12-01`, y: null },
];

export default chartEmptyDummyData;
