/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import orderBy from 'lodash/orderBy';
import { components, ValueContainerProps } from 'react-select';

import CountriesOption from 'shared/modules/users/components/invite-users-field-array/invite-users-form-item/countries-select/CountriesOption';

const CountriesValueContainer: ReactFC<
  ValueContainerProps<CountriesOption> & { children: any }
> = (props) => {
  const { children, ...rest } = props;
  let newChildren = children;
  try {
    const childrenClone = [...children];
    const [options] = childrenClone;
    if (Array.isArray(options)) {
      const sortedOptions = orderBy(
        options,
        (option) => option.props.data.isFixed,
        'desc'
      );
      childrenClone[0] = sortedOptions;
    }
    newChildren = childrenClone;
  } catch (error) {
    newChildren = children;
  }

  return (
    <components.ValueContainer {...rest}>
      {newChildren}
    </components.ValueContainer>
  );
};
export default CountriesValueContainer;
