/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, FC as ReactFC, useState } from 'react';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import styles from '../messageHistoryDataTable.module.scss';
import MessageHistoryRowProps from './MessageHistoryRowProps';

const MessageHistoryRow: ReactFC<MessageHistoryRowProps> = (
  props: MessageHistoryRowProps
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { prepareRow, row, statusReloadRow, details, onRowClick } = props;

  const [open, setOpen] = useState(false);

  const toggle = (e: React.MouseEvent<HTMLTableRowElement>): void => {
    const target = e.target as HTMLElement;
    onRowClick(row);
    if (target.tagName !== 'BUTTON') {
      setOpen(!open);
    }
  };

  prepareRow(row);
  const className = row.isSelected ? 'row-selected' : '';

  return (
    <Fragment key={row.id}>
      <tr {...row.getRowProps({ onClick: toggle })} className={className}>
        {row.cells.map((cell, index: number) => (
          <Fragment key={index}>
            <td
              {...cell.getCellProps({
                className: cell.column.className,
                'data-title': cell.column.dataTitle,
              })}
            >
              <EllipsisTooltip
                tag="span"
                data-place="bottom"
                data-for="insTooltip"
                data-tip={cell.value}
                data-class="overflow-wrap"
                className={`table-content text-14-semibold extend-length ${styles.wrapContent}`}
              >
                {cell.render('Cell')}
              </EllipsisTooltip>
            </td>
          </Fragment>
        ))}
      </tr>
    </Fragment>
  );
};

export default MessageHistoryRow;
