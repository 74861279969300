/* eslint-disable no-useless-constructor */
class DummyProject {
  constructor(
    public name: string,
    public code: string,
    public projectId: string
  ) {}
}

export default DummyProject;
