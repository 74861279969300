import { formatDate } from 'helpers/DateFormat';
import DateFormatType from 'shared/enums/DateFormatType';

class InviteProjectFacilitatorsViewModel {
  image: string | null;

  supervisorId: string | null;

  startDate: string | null;

  constructor(
    public facilitatorId: string,
    public firstName: string,
    public lastName: string,
    public location: string,
    public phoneNumber: string,
    supervisorId: string | null,
    startDate: string,
    public isImplementingPartner: boolean,
    public projectId: string,

    image?: string
  ) {
    this.supervisorId = supervisorId === 'NONE' ? null : supervisorId;

    this.startDate = startDate
      ? formatDate(startDate, DateFormatType.Iso)
      : null;

    this.image = image ?? null;
  }
}

export default InviteProjectFacilitatorsViewModel;
