import React, {
  ReactNode,
  useRef,
  FC as ReactFC,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';

import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer } from 'react-laag';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import TIMEOUTS from 'constants/Timeouts';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import styles from '../facilitatorMetrics.module.scss';
import SupervisorFilterSelectProps from './SupervisorFilterSelectProps';

const MAX_SUPERVISORS_SELECTED = Constraints.MaxSupervisorsInMetric;

const SupervisorFilterSelect: ReactFC<SupervisorFilterSelectProps> = (
  props: SupervisorFilterSelectProps
) => {
  const {
    supervisors,
    selectedSupervisors,
    onOpen,
    onSupervisorSearch,
    onClose,
  } = props;

  const parentRef = useRef<HTMLDivElement>(null);
  const [checkedSupervisors, setCheckedSupervisors] =
    useState(selectedSupervisors);

  useEffect(() => {
    setCheckedSupervisors(selectedSupervisors);
  }, [selectedSupervisors]);

  /**
   * Handle supervisor select
   *
   * @param event checkbox check event
   */
  const handleSupervisorSelect = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked, value } = event.target;
    if (checked) {
      setCheckedSupervisors([...checkedSupervisors, value]);
    } else {
      setCheckedSupervisors(
        checkedSupervisors.filter(
          (uncheckedSupervisor) => uncheckedSupervisor !== value
        )
      );
    }
  };

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <div
        ref={layerProps.ref}
        className="layer insight-dropdown"
        style={{
          ...layerProps.style,
          left: '50%',
          transform: 'translateX(-50%)',
          top: '10px',
        }}
      >
        <Arrow
          style={arrowStyle}
          layerSide={layerSide}
          backgroundColor="#ffffff"
          borderWidth={1}
          borderColor="#dbdce1"
          roundness={0.7}
        />
        <div className="search-textbox mb-2">
          <DebounceInput
            minLength={2}
            debounceTimeout={TIMEOUTS.SEARCH_DEBOUNCE_TIMEOUT}
            onChange={(event) => {
              onSupervisorSearch(event.target.value);
            }}
            type="search"
            className="form-control mb-3"
            placeholder={intl.get('LBL_FACILITATOR_METRICS_SEARCH_PLACEHOLDER')}
          />
        </div>
        <PerfectScrollbar className={styles.scroll}>
          <Form>
            {supervisors.length > 0 ? (
              supervisors.map((supervisor) => {
                const isChecked = checkedSupervisors.includes(supervisor.value);
                const selectionBlocked =
                  !isChecked &&
                  checkedSupervisors.length >= MAX_SUPERVISORS_SELECTED;
                return (
                  <FormGroup
                    key={supervisor.value}
                    className={`insight-checkbox-group ${styles.wrap}`}
                  >
                    <span
                      data-for="insTooltip"
                      data-place="bottom"
                      data-class="error bring-it-up"
                      data-tip={
                        selectionBlocked
                          ? intl.get(
                              'ERR_FACILITATOR_METRIC_SELECTION_SUPERVISORS_LIMIT_REACHED',
                              {
                                limit: MAX_SUPERVISORS_SELECTED,
                              }
                            )
                          : ''
                      }
                    >
                      <Label
                        className={`${isChecked ? 'checked-label' : ''} ${
                          selectionBlocked ? styles.reduceOpacity : ''
                        }`}
                      >
                        <span
                          className={`insight-checkbox mr-3 ${
                            selectionBlocked ? 'disabled-checkbox' : ''
                          }`}
                        >
                          <Input
                            type="checkbox"
                            tabIndex={0}
                            name={supervisor.value}
                            value={supervisor.value}
                            checked={isChecked}
                            onChange={handleSupervisorSelect}
                            disabled={selectionBlocked}
                          />
                          <i className="icon-check" />
                        </span>
                        {selectionBlocked ? (
                          <span className={`truncate ${styles.option}`}>
                            {supervisor.label}
                          </span>
                        ) : (
                          <EllipsisTooltip
                            tag="span"
                            data-place="right"
                            data-for="insTooltip"
                            data-tip={supervisor.label}
                            data-class="overflow-wrap"
                            className={`truncate ${styles.option} ${
                              isChecked ? styles.checked : ''
                            }`}
                          >
                            {supervisor.label}
                          </EllipsisTooltip>
                        )}
                      </Label>
                    </span>
                  </FormGroup>
                );
              })
            ) : (
              <FormGroup className="insight-checkbox-group">
                <Label
                  className="truncate"
                  style={{ maxWidth: '150px', textAlign: 'start' }}
                >
                  {intl.get('LBL_NO_OPTIONS')}
                </Label>
              </FormGroup>
            )}
          </Form>
        </PerfectScrollbar>
      </div>
    );

  return (
    <ToggleLayer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={parentRef.current!} // nosonar
      closeOnOutsideClick
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
      onOutsideClick={() => {
        onClose(checkedSupervisors);
      }}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => {
        const supervisorsList =
          checkedSupervisors.length > 0
            ? supervisors
                .filter((supervisor) =>
                  checkedSupervisors.includes(supervisor.value)
                )
                .map((supervisor) => supervisor.label)
                .filter(Boolean)
                .join(', ')
            : intl.get('LBL_ALL_SUPERVISORS');
        return (
          <>
            <EllipsisTooltip
              ref={triggerRef}
              className={`toggle-btn dropdown-btn ${
                isOpen ? 'dropdown-open' : ''
              }`}
              onClick={(): void => onOpen(toggle, isOpen)}
              tag="button"
              data-cy="supervisors-gf"
              data-place="bottom"
              data-for="insTooltip"
              data-tip={supervisorsList}
              data-class="overflow-wrap"
            >
              {supervisorsList}
            </EllipsisTooltip>
            <div
              ref={parentRef}
              className="position-relative"
              id="supervisor-parent"
            />
          </>
        );
      }}
    </ToggleLayer>
  );
};

export default React.memo(SupervisorFilterSelect);
