import { v4 as uuid } from 'uuid';

export type InviteUsersFormValues = { users: UserFormValue[] };

class UserFormValue {
  id: string;

  jobRole: string;

  isImplementingPartner: boolean;

  permission: string;

  countryDataAccess: string[];

  supervisorId: string;

  email: string;

  constructor() {
    this.id = uuid();
    this.email = '';
    this.isImplementingPartner = false;
    this.jobRole = '';
    this.supervisorId = '';
    this.permission = '';
    this.countryDataAccess = [];
  }
}

export default UserFormValue;
