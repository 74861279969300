/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useColumnOrder,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import DataTableFooter from 'shared/components/data-table-footer/DataTableFooter';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import DummyRow from './dummy-row/DummyRow';
import GroupActionBar from './group-action-bar/GroupActionBar';
import styles from './groupsDataTable.module.scss';
import GroupsDataTableProps from './GroupsDataTableProps';

const GroupsDataTable: ReactFC<GroupsDataTableProps> = (
  props: GroupsDataTableProps
) => {
  const {
    columns,
    data,
    controlledPageCount,
    pagination,
    hiddenColumns,
    columnOrder,
    initialPageSize,
    initialPageIndex,
    initialSortBy,
    selectedRows,
    unselectAll,
    customHooks,
    loading,
    error,
    facilitators,
    facilitatorsStatus,
    facilitatorsError,
    groupStatGroupsAssigned,
    assignToProjectStatus,
    assignToProjectError,
    assignFacilitatorsStatus,
    assignFacilitatorsError,
    assignGroupsStatusStatus,
    assignGroupsStatusError,
    fetchData,
    fetchFacilitators,
    onRowClick,
    setUnselectAll,
    setSelectAll,
    setSelectedRows,
    onAssignToProject,
    onAssignGroupsFacilitators,
    onAssignGroupsStatus,
    setAssignGroupsStatusStatus,
    setAssignToProjectStatus,
    setAssignFacilitatorsStatus,
  } = props;

  /**
   * Updates table state
   *
   * @param newState Updated table state
   * @param action Performed action
   * @returns {object} State object
   */
  const stateReducer = (newState, action): Record<string, unknown> => {
    const { type } = action;
    if (type === 'toggleAllRowsSelected') {
      return {
        ...newState,
        selectedRowIds: action.value ? selectedRows : {},
      };
    }
    return newState;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    page,
    pageCount,
    gotoPage,
    toggleAllRowsSelected,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: initialPageIndex,
        hiddenColumns,
        columnOrder,
        sortBy: initialSortBy,
        selectedRowIds: selectedRows,
      },
      stateReducer,
      manualSortBy: true,
      manualPagination: true,
      disableMultiSort: true,
      disableSortRemove: true,
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      // autoResetPage: false,
      autoResetHiddenColumns: false,
      autoResetSelectedRows: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    ...customHooks
  );

  const currentSelectedRows = Object.keys(selectedRows);

  /**
   * whenever the selectedRows or unselectAll changes checks
   * weather if unselectAll and unselects all, if not
   * toggleAllRowsSelected(true) which will select only the
   * rows which come in from `selectedRows` prop; check
   * useTable -> stateReducer
   */
  useEffect(() => {
    if (unselectAll) {
      toggleAllRowsSelected(false);
    } else {
      toggleAllRowsSelected(true);
    }
  }, [selectedRows, unselectAll]);

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy });
  }, [pageIndex, pageSize, JSON.stringify(sortBy)]);

  useEffect(() => {
    setSelectedRows(selectedRowIds);
  }, [setSelectedRows, JSON.stringify(selectedRowIds)]);

  useEffect(() => {
    setColumnOrder(columnOrder);
  }, [setColumnOrder, JSON.stringify(columnOrder)]);

  useEffect(() => {
    if (hiddenColumns && hiddenColumns.length !== undefined) {
      setHiddenColumns(hiddenColumns);
    }
  }, [setHiddenColumns, JSON.stringify(hiddenColumns)]);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [data]);

  /**
   * Handles assigning project to group
   *
   * @param projectId Project ID
   */
  const handleAssignToProject = (projectId: string): void => {
    onAssignToProject(projectId, currentSelectedRows);
  };

  /**
   * Handles assigning facilitator to group
   *
   * @param projectId Facilitator ID
   */
  const handleAssignFacilitator = (facilitator: string): void => {
    onAssignGroupsFacilitators(facilitator, currentSelectedRows);
  };

  /**
   * Handles assigning group status to group
   *
   * @param projectId Group Status ID
   */
  const handleAssignGroupStatus = (statusId: string): void => {
    onAssignGroupsStatus(statusId, currentSelectedRows);
  };

  return (
    <>
      <div className={`insight-table-container pb-0 ${styles.table}`}>
        {loading ? (
          <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
            <Skeleton height={40} />
            <Skeleton height={62} count={10} />
          </SkeletonTheme>
        ) : (
          <PerfectScrollbar className={styles.scroll}>
            <table className="insight-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column.id === 'selection') {
                        return <th {...column.getHeaderProps()} />;
                      }
                      return (
                        <th
                          {...column.getHeaderProps({
                            className:
                              column.headerClassName ?? column.className,
                          })}
                        >
                          <span
                            className="table-label"
                            {...column.getSortByToggleProps({ title: '' })}
                          >
                            {column.render('Header', { meta: pagination })}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    const className = row.isSelected ? 'row-selected' : '';
                    return (
                      <tr {...row.getRowProps()} className={className}>
                        {row.cells.map((cell) => {
                          const customProps =
                            onRowClick && cell.column.id !== 'selection'
                              ? {
                                  onClick: (): void => onRowClick(row),
                                  style: { cursor: 'pointer' },
                                }
                              : {};

                          return (
                            <td
                              {...cell.getCellProps({
                                className: cell.column.className,
                                'data-title': cell.column.dataTitle,
                                ...customProps,
                              })}
                            >
                              <EllipsisTooltip
                                tag="span"
                                data-for="insTooltip"
                                data-tip={cell.value || ''}
                                data-class="row-info overflow-wrap"
                                data-place="bottom"
                                className="table-content"
                              >
                                {cell.render('Cell')}
                              </EllipsisTooltip>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <DummyRow headers={headerGroups} isError={error} />
                )}
              </tbody>
            </table>
          </PerfectScrollbar>
        )}
        {!loading && (error || data.length <= 0) && (
          <div className={styles.empty}>
            <div className="no-data-message">
              <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
              <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
            </div>
          </div>
        )}

        <GroupActionBar
          currentSelectedRows={currentSelectedRows}
          facilitators={facilitators}
          facilitatorsStatus={facilitatorsStatus}
          facilitatorsError={facilitatorsError}
          groupStatGroupsAssigned={groupStatGroupsAssigned}
          assignToProjectStatus={assignToProjectStatus}
          assignToProjectError={assignToProjectError}
          assignFacilitatorsStatus={assignFacilitatorsStatus}
          assignFacilitatorsError={assignFacilitatorsError}
          assignGroupsStatusStatus={assignGroupsStatusStatus}
          assignGroupsStatusError={assignGroupsStatusError}
          totalResults={pagination.total}
          setUnselectAll={setUnselectAll}
          setSelectAll={setSelectAll}
          fetchFacilitators={fetchFacilitators}
          setAssignGroupsStatusStatus={setAssignGroupsStatusStatus}
          setAssignToProjectStatus={setAssignToProjectStatus}
          setAssignFacilitatorsStatus={setAssignFacilitatorsStatus}
          onAssignToProject={handleAssignToProject}
          onAssignGroupsFacilitators={handleAssignFacilitator}
          onAssignGroupsStatus={handleAssignGroupStatus}
        />

        <DataTableFooter
          loading={loading}
          totalResults={pagination.total}
          gotoPage={gotoPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageCount={pageCount}
          pageIndex={initialPageIndex}
        />
      </div>
    </>
  );
};

export default GroupsDataTable;
