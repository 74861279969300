import { FC as ReactFC } from 'react';

import { ErrorMessage, Field } from 'formik';
import * as intl from 'react-intl-universal';
import {
  Button,
  ModalBody,
  ModalHeader,
  FormGroup,
  Row,
  Col,
  Label,
  FormText,
} from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import ImageComponent from 'shared/components/image/ImageComponent';
import SignUpIllustration from 'shared/static/img/sign-up-illustration.svg';
import signUpThumb from 'shared/static/img/thumbs/sign_up_thumb.png';

import StepDots from '../step-dots/StepDots';
import styles from './registerWelcome.module.scss';
import RegisterWelcomeProps from './RegisterWelcomeProps';

const RegisterWelcome: ReactFC<RegisterWelcomeProps> = (
  props: RegisterWelcomeProps
) => {
  const { step } = props;

  const maxLength = Constraints.MaxFieldLength;
  return (
    <>
      <ModalHeader className="increase-font border-bottom-0 pb-0">
        {intl.get('LBL_CA_WELCOME')}
      </ModalHeader>
      <ModalBody className="pt-0">
        <Row>
          <Col xs="auto" className={styles.image}>
            <ImageComponent
              loading="eager"
              src={SignUpIllustration}
              alt="Sign Up"
              thumb={signUpThumb}
            />
          </Col>
          <Col>
            <FormGroup>
              <Label htmlFor="firstName">{intl.get('LBL_CA_FIRST_NAME')}</Label>
              <Field
                type="text"
                name="firstName"
                maxLength={maxLength}
                className="form-control"
              />
              <ErrorMessage
                className="form-error"
                component="label"
                name="firstName"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lastName">{intl.get('LBL_CA_LAST_NAME')}</Label>
              <Field
                type="text"
                name="lastName"
                maxLength={maxLength}
                className="form-control"
              />
              <ErrorMessage
                className="form-error"
                component="label"
                name="lastName"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="jobTitle">{intl.get('LBL_CA_JOB_TITLE')}</Label>
              <Field
                type="text"
                name="jobTitle"
                maxLength={maxLength}
                className="form-control"
              />
              <ErrorMessage
                className="form-error"
                component="label"
                name="jobTitle"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="organizationName">
                {intl.get('LBL_CA_ORGANIZATION_NAME')}
              </Label>
              <Field
                type="text"
                name="organizationName"
                maxLength={maxLength}
                className="form-control"
              />
              <ErrorMessage
                className="form-error"
                component="label"
                name="organizationName"
              />
            </FormGroup>
            <FormText>
              {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.dreamstartlabs.com/"
              >
                {intl.get('LBL_CA_CONTACT_SUPPORT')}
              </a>
            </FormText>
          </Col>
        </Row>
        <Row className="mt-3 pt-3">
          <Col
            xs={{ size: 4, offset: 4 }}
            className="d-flex align-items-center justify-content-center"
          >
            <StepDots count={3} step={step} />
          </Col>
          <Col md="4" className="d-flex justify-content-end">
            <Button color="primary" type="submit">
              {intl.get('BTN_CA_CONTINUE')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </>
  );
};

export default RegisterWelcome;
