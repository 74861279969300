import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import Status from 'shared/enums/Status';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveysFilter from 'shared/enums/SurveysFilter';
import SurveyType from 'shared/enums/SurveyType';

import SurveyStatsViewModel from '../SurveyStatsViewModel';
import DraftedSurveyViewModel from './DraftedSurveyViewModel';

export type FilterData = {
  data: Array<SelectFieldOption>;
  status: Status;
  error: Error | null;
};

type Members = {
  name: string;
  memberId: string;
};

export interface FilteredRecipients {
  name: string;
  groupId: string;
  members: Array<Members>;
  groupNumber: string;
}

export interface SurveyRecipients {
  totalGroupCount: number;
  totalMemberCount: number;
  filteredRecipients: Array<FilteredRecipients>;
}

export type AppliedFilters = {
  data: SurveyRecipients;
  status: Status;
  error: Error | null;
};

export type ExistingCollectId = {
  id: string;
  name: string;
};

export type PopupDetails = {
  sendCount: number;
  excludedMemberCount: number;
  excludedGroups: Array<FilteredRecipients>;
  isDuplicateSurvey: boolean;
  surveyType: SurveyType;
  recipientType: SurveyRecipientType;
  existingCollectIds: Array<ExistingCollectId>;
};

export type SelectedFilters = {
  countries: Array<SelectFieldOption>;
  projects: Array<SelectFieldOption>;
  facilitators: Array<SelectFieldOption>;
  groupStatus: Array<SelectFieldOption>;
  groupAge: Array<SelectFieldOption>;
  allMembers: Array<SelectFieldOption>;
  memberGender: Array<SelectFieldOption>;
  memberAge: Array<SelectFieldOption>;
  memberEducation: Array<SelectFieldOption>;
};

export type SelectedSurveyTypes = {
  [surveyId: string]: string;
};

export type SelectedRecipientTypes = {
  [surveyId: string]: string;
};

export enum SurveyChangeViewPoint {
  NewSurvey = 'NEW_SURVEY',
  GetNewSurveysButton = 'GET_NEW_SURVEYS_BUTTON',
}

export type FocusedSurvey = {
  status: Status;
  countriesFilter: FilterData;
  projectsFilter: FilterData;
  facilitatorsFilter: FilterData;
  selectedFilters: SelectedFilters;
  appliedFilters: AppliedFilters;
  popupDetails: PopupDetails;
  finalRecipients: Array<FilteredRecipients>;
  lastUpdatedFilter: SurveysFilter | null;
  displayWarningPopup: boolean;
  displaySuccessPopup: boolean;
  isSurveySendClicked: boolean;
  isItemExpanded: boolean;
  expandedRowId: string;
  tempRowId: string;
  tempRowExpanded: boolean;
  collapsingOwnSurveyWithFilters: boolean;
  isFilterSelectionDone: boolean;
};

type DraftedSurveys = {
  data: Array<DraftedSurveyViewModel>;
  status: Status;
  searchTerm: string;
  selectedSurveyTypes: SelectedSurveyTypes;
  selectedRecipientTypes: SelectedRecipientTypes;
  total: number;
  displaySurveyChangeWarningPopup: boolean;
  surveyChangeViewpoint: SurveyChangeViewPoint | null;
  next: string | null;
  previous: string | null;
  page: number;
  pageSize: number;
};

interface SurveysDraftViewState {
  status: Status;
  canCreateSurveys: boolean;
  canCollectIDs: boolean;
  draftedSurveys: DraftedSurveys;
  focusedSurvey: FocusedSurvey;
  surveyStats: SurveyStatsViewModel;
}

export default SurveysDraftViewState;
