import parsePhoneNumber from 'libphonenumber-js';

/**
 * Formats a phone number to the international format
 *
 * @param number Phone number to be formatted
 * @param hyphenate Flag specifying if the formatted number should be hyphenated
 * @returns {string | null} Formatted phone number
 */
const formatInternational = (
  number: string,
  hyphenate = false
): string | null => {
  if (number) {
    const phoneNumber = parsePhoneNumber(number);
    if (phoneNumber) {
      if (hyphenate) {
        return phoneNumber.formatInternational().replace(/\s+/g, '-');
      }
      return phoneNumber.formatInternational();
    }
    return formatCodeless(number, hyphenate);
  }
  return null;
};

/**
 * Formats a phone number to the national format
 *
 * @param number Phone number to be formatted
 * @param hyphenate Flag specifying if the formatted number should be hyphenated
 * @returns {string | null} Formatted phone number
 */
const formatNational = (number: string, hyphenate = false): string | null => {
  if (number) {
    const phoneNumber = parsePhoneNumber(number);
    if (phoneNumber) {
      if (hyphenate) {
        return phoneNumber
          .formatNational()
          .replace(/[()]/g, '') // Removes parentheses
          .replace(/\s+/g, '-'); // Replaces spaces with hyphens
      }
      return phoneNumber.formatNational();
    }
    return formatCodeless(number, hyphenate);
  }
  return null;
};

/**
 * Formats a phone number to the URI format
 *
 * @param number Phone number to be formatted
 * @returns {string | null} Formatted phone number
 */
const formatURI = (number: string): string | null => {
  if (number) {
    const phoneNumber = parsePhoneNumber(number);
    if (phoneNumber) {
      return phoneNumber.getURI();
    }
    return formatCodeless(number);
  }
  return null;
};

/**
 * Formats a country-codeless phone number by cleaning up unnecessary chars
 *
 * @param number Phone number to be formatted
 * @param hyphenate Flag specifying if the formatted number should be hyphenated
 * @returns {string} Formatted phone number
 */
const formatCodeless = (number: string, hyphenate = true): string => {
  const cleanedNumber = number.replace(/\D[^.]/g, '');
  const separator = hyphenate ? '-' : ' ';
  // prettier-ignore
  return `${cleanedNumber.slice(0, 3)}${separator}${cleanedNumber.slice(3, 6)}${separator}${cleanedNumber.slice(6)}`;
};

export default {};
export { formatInternational, formatNational, formatURI, formatCodeless };
