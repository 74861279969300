import FeatureKeys from 'constants/features/FeatureKeys';
import ModulePaths from 'constants/ModulePaths';
import { Feature } from 'context/AppProviderState';

class FeatureValidationUtil {
  private static mapping = {
    [ModulePaths.MessagingPath]: FeatureKeys.Messaging,
    [ModulePaths.SurveysPath]: FeatureKeys.Surveys,
  };

  /**
   * Check if user can access a feature in the application
   *
   * @param features List of application features and user privileges
   * @param resource URL resource
   *
   * @returns {boolean} Whether the user can access the feature or not
   */
  public static Can(features: Array<Feature>, resource: string): boolean {
    const rootResources = Object.keys(FeatureValidationUtil.mapping);
    if (rootResources.includes(resource)) {
      const accessingFeature = FeatureValidationUtil.mapping[resource];
      const receivedFeature = features.find(
        ({ feature }) => feature === accessingFeature
      );
      if (receivedFeature) {
        return receivedFeature.enabled;
      }
      // Enable the feature if the received features list don't contain the feature
      return true;
    }
    // Enable the feature if the mapping doesn't contain the feature
    return true;
  }
}

export default FeatureValidationUtil;
