import { SortableContainer } from 'react-sortable-hoc';

import SortableWidgetContainerProps from 'modules/private/dashboard/components/widget-metrics/sortable-widget-container/SortableWidgetContainerProps';
import SortableWidget from 'modules/private/dashboard/components/widget-metrics/sortable-widget/SortableWidget';

/**
 * Wrapping SortableWidget components with SortableContainer HOC to
 * enable drag and drop sorting.
 */
const SortableWidgetContainer = SortableContainer(
  ({
    widgetData,
    widgetsLoading,
    dashboardSetupInProgress,
    onUpdateWidgetData,
  }: SortableWidgetContainerProps) => (
    <div
      className={`row dashboard-row ${
        dashboardSetupInProgress
          ? 'active-sortable-widget-container'
          : 'sortable-widget-container'
      }`}
    >
      {widgetData.map((data, index, selectedWidgets) => (
        <SortableWidget
          index={index}
          key={data.type}
          widgetData={data}
          widgetsLoading={widgetsLoading}
          selectedWidgets={selectedWidgets}
          onUpdateWidgetData={onUpdateWidgetData}
          dashboardSetupInProgress={dashboardSetupInProgress}
          disabled={!dashboardSetupInProgress}
        />
      ))}
    </div>
  )
);

export default SortableWidgetContainer;
