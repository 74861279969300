import { ReactNode, useEffect, FC as ReactFC } from 'react';

import noop from 'lodash/noop';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Col, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';

import ImageComponent from 'shared/components/image/ImageComponent';
import Status from 'shared/enums/Status';
import EmptyStateIllustration from 'shared/static/img/no-existing-users-illustration.svg';
import emptyStateThumb from 'shared/static/img/thumbs/no_existing_users_thumb.png';

import ExistingUserListItem from './existing-user-list-item/ExistingUserListItem';
import styles from './existingUserList.module.scss';
import ExistingUserListProps from './ExistingUserListProps';

const ExistingUserList: ReactFC<ExistingUserListProps> = (
  props: ExistingUserListProps
) => {
  const {
    status,
    existingUsers,
    newUsers,
    popoverRef,
    togglePermissionsModal,
    removeNewUserFromList,
  } = props;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [existingUsers, newUsers]);

  const renderPermissionsPopover = (): ReactNode => (
    <UncontrolledPopover
      placement="bottom"
      container={popoverRef}
      fade={false}
      modifiers={{
        preventOverflow: { enabled: false },
        flip: { behavior: ['bottom'] },
        hide: { enabled: false },
      }}
      trigger="hover"
      popperClassName="popover existing-user-list"
      target="permissionsPopover"
      delay={{ show: 0, hide: 100 }}
    >
      <PopoverBody>
        <span className="text-14-medium text-gray">
          {intl.get('LBL_PROJECTS_USERS_ADD_LIST_PERMISSION_LEVEL_HINT_PART_1')}{' '}
          <button
            type="button"
            className="border-0 text-gray p-0 shadow-none bg-transparent"
            style={{ textDecoration: 'underline' }}
            onClick={togglePermissionsModal}
          >
            {intl.get('BTN_PROJECT_USERS_HERE')}
          </button>{' '}
          {intl.get('LBL_PROJECTS_USERS_ADD_LIST_PERMISSION_LEVEL_HINT_PART_2')}
        </span>
      </PopoverBody>
    </UncontrolledPopover>
  );

  /**
   * Renders the empty state for the existing user list
   *
   * @returns {ReactNode} ReactNode containing the empty state icon and message
   */
  const renderEmptyState = (): ReactNode =>
    status === Status.Loading ? (
      <div />
    ) : (
      <Row className={`d-flex justify-content-center ${styles.empty}`}>
        <Col xs="auto">
          <ImageComponent
            loading="eager"
            src={EmptyStateIllustration}
            alt="No Existing Users"
            thumb={emptyStateThumb}
          />
        </Col>
        <Col xs="4">
          <h4 className="text-14-semibold">
            {intl.get('LBL_PROJECTS_USERS_ADD_LIST_EMPTY_TITLE')}
          </h4>
          <p className="text-14-medium text-gray">
            {intl.get('LBL_PROJECTS_USERS_ADD_LIST_EMPTY_DESCRIPTION')}
          </p>
        </Col>
      </Row>
    );

  return (
    <div className={styles.wrap}>
      {existingUsers.length + newUsers.length > 3 ? (
        <span className={styles.track} />
      ) : (
        ''
      )}
      {existingUsers.length > 0 || newUsers.length > 0 ? (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          className={`${styles.scroll} ${
            existingUsers.length + newUsers.length > 3
              ? styles.addRightPadding
              : ''
          }`}
        >
          <table className={`insight-table ${styles.table}`}>
            <thead>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th />
                <th>
                  <span>
                    {intl.get('LBL_PROJECTS_USERS_ADD_LIST_NAME_HEADER')}
                  </span>
                </th>
                <th>
                  <span>
                    {intl.get(
                      'LBL_PROJECTS_USERS_ADD_LIST_PERMISSION_LEVEL_HEADER'
                    )}
                    <span className="help">
                      <i className="icon-help" id="permissionsPopover" />
                      {renderPermissionsPopover()}
                    </span>
                  </span>
                </th>
                <th>
                  <span>
                    {intl.get(
                      'LBL_PROJECTS_USERS_ADD_LIST_COUNTRY_DATA_ACCESS_HEADER'
                    )}
                    <span className="help">
                      <i
                        className="icon-help"
                        data-for="insTooltipClickable"
                        data-class="custom-tooltip-without-breaking-words"
                        data-tip={intl.get(
                          'LBL_PROJECTS_USERS_ADD_LIST_COUNTRY_DATA_ACCESS_HINT'
                        )}
                        data-place="bottom"
                      />
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {newUsers
                .map((newUser) => (
                  <ExistingUserListItem
                    key={newUser.userId}
                    user={newUser}
                    removeNewUserFromList={removeNewUserFromList}
                    isNewlyAdded
                  />
                ))
                .reverse()}
              {existingUsers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((existingUser) => (
                  <ExistingUserListItem
                    key={existingUser.userId}
                    user={existingUser}
                    removeNewUserFromList={noop}
                    isNewlyAdded={false}
                  />
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        renderEmptyState()
      )}
    </div>
  );
};

export default ExistingUserList;
