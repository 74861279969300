/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import {
  Align,
  ListOnItemsRenderedProps,
  ListOnScrollProps,
  ListProps,
  VariableSizeList as List,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Button, Col } from 'reactstrap';

import mergeRefs from 'helpers/mergeRefs';
import Status from 'shared/enums/Status';

import ProjectEmptyView from '../current-projects-empty/ProjectsEmptyView';
import ProjectListItem from '../project-list-item/ProjectListItem';
import UserProfileFieldWrapper from '../user-profile-left-panel/user-profile-field-wrapper/UserProfileFieldWrapper';
import { UserProfileFields } from '../user-profile-left-panel/UserProfileLeftPanelProps';
import AddProjectsModal from './add-project-modal/AddProjectsModal';
import UserProjectsHandle from './UsersProjectsHandle';
import styles from './usersProjectsPane.module.scss';
import UsersProjectsPaneProps from './UsersProjectsPaneProps';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/display-name
const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height)}px`,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
));

const MIN_PROJECT_ELEMENT_COUNT = 3;
const LIST_HEIGHT = 300;
const ELEMENT_HEIGHT = 100;

const UsersProjectsPane: React.ForwardRefRenderFunction<
  UserProjectsHandle,
  UsersProjectsPaneProps
> = (props: UsersProjectsPaneProps, ref) => {
  const {
    data: ProjectsData,
    page,
    pageCount,
    pageSize,
    loadNextPage,
    scrolling,
    status,
    projectsStatus,
    userCountryData,
    countries,
    onRemoveFromProject,
    onAddNewProjects,
    restrictions,
    showAddProjectModal,
    toggleAddUserToProjectsModal,
    displayCDAWarningPrompt,
    onCDAWarningPromptAction,
    userUnassignedCountries,
    projectCompatibilityStatus,
  } = props;

  const infiniteLoaderRef = useRef<InfiniteLoader>();
  const listRef = useRef<List>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listOuterRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listInnerRef = useRef<any>(null);

  const [listScrollOffset, setListScrollOffset] = useState<number>(0);

  const showEmpty =
    projectsStatus === Status.Success && ProjectsData.length <= 0;
  const hasProjects = ProjectsData.length !== 0;

  const maxHeight =
    (listInnerRef.current &&
      listInnerRef.current.style.height.replace('px', '')) ||
    900;
  const minHeight = 0;
  const pageOffset = 30;

  // pageCount - 1 is max page number; since pages start from 0
  const hasNextPage = page < pageCount - 1;

  const firstTimeLoading =
    projectsStatus === Status.Loading && ProjectsData.length <= 0;

  const itemCount = hasNextPage
    ? Number(ProjectsData.length) + 1
    : ProjectsData.length;

  const [pageUp, pageDown, arrowUp, arrowDown] = [33, 34, 38, 40];

  const scrollKeys = {
    [pageUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [pageDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
    [arrowUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [arrowDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
  };

  const isItemLoaded = useCallback(
    (index: number): boolean => !hasNextPage || index < ProjectsData.length,
    [hasNextPage, ProjectsData.length]
  );

  const handleItemsRendered =
    (onItemsRenderedIL: ListProps['onItemsRendered']) =>
    (listItemProps: ListOnItemsRenderedProps): void => {
      if (onItemsRenderedIL) {
        onItemsRenderedIL(listItemProps);
      }
    };

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    if (projectsStatus === Status.Loading) {
      return Promise.resolve();
    }
    return loadNextPage(startIndex, stopIndex);
  };

  /**
   * Handles onScroll event for list
   *
   * @param {ListOnScrollProps}
   */
  const handleListScroll = ({ scrollOffset }: ListOnScrollProps): void =>
    setListScrollOffset(scrollOffset);

  useLayoutEffect(() => {
    if (listOuterRef && listOuterRef.current) {
      listOuterRef.current.scrollTo({
        left: 0,
        top: listScrollOffset,
        behavior: 'auto',
      });
    }
  });

  useImperativeHandle(ref, () => ({
    resetloadMoreItemsCache(autoReload?: boolean): void {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(autoReload);
      }
    },
    scrollToItem(index: number, align?: Align): void {
      if (listRef.current) {
        listRef.current.scrollToItem(index, align);
      }
    },
  }));

  /**
   * Handles key down events on the list wrapper for scrolling purposes
   *
   * @param event Key down event
   */
  const handleKeyDown = ({ keyCode }: React.KeyboardEvent): void => {
    if (scrollKeys[keyCode]) {
      setListScrollOffset(scrollKeys[keyCode]);
    }
  };

  /**
   * Render the empty state of the project pane conditionally
   * @returns JSX.Element contains the empty state
   */
  const renderEmptyState = (): JSX.Element => {
    if (status === Status.Loading) {
      return <div />;
    }

    return (
      <ProjectEmptyView
        restrictions={restrictions}
        onAddToProjectClick={toggleAddUserToProjectsModal}
      />
    );
  };

  return (
    <BlockUi
      tag="div"
      blocking={status === Status.Loading || firstTimeLoading || scrolling}
      className="row dashboard-row"
    >
      <Col>
        <div className="content-box lower-margins">
          <div className="content-box-title  pb-4 pt-2">
            <h3 className="main-title text-uppercase">
              {intl.get('LBL_USER_PROFILE_CURRENT_PROJECTS_HEADER')}
            </h3>
            <div className="inline-form">
              {hasProjects && (
                <UserProfileFieldWrapper
                  value=""
                  fieldName={UserProfileFields.ADD_TO_PROJECT}
                  canEditProfile={restrictions.canEditProfile}
                  isOwnProfile={restrictions.isOwnProfile}
                  isOwner={restrictions.isOwner}
                  isNotInLeftPanel
                >
                  <Button
                    type="button"
                    onClick={toggleAddUserToProjectsModal}
                    className="btn btn-sm"
                  >
                    {intl.get('BTN_ADD_TO_PROJECT')}
                  </Button>
                </UserProfileFieldWrapper>
              )}
            </div>
          </div>
          {showEmpty ? (
            renderEmptyState()
          ) : (
            <Col xs="12" className="mt-2 p-0">
              <div className={styles.scroll}>
                <InfiniteLoader
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ref={infiniteLoaderRef}
                  threshold={pageSize}
                  minimumBatchSize={pageSize}
                  isItemLoaded={isItemLoaded}
                  loadMoreItems={loadMoreItems}
                  itemCount={itemCount}
                >
                  {({ onItemsRendered, ref: innerRef }): JSX.Element => (
                    <div role="list" tabIndex={0} onKeyDown={handleKeyDown}>
                      <List
                        ref={mergeRefs([innerRef, listRef])}
                        innerRef={listInnerRef}
                        outerRef={listOuterRef}
                        className="List"
                        height={LIST_HEIGHT}
                        width="100%"
                        itemCount={itemCount}
                        innerElementType={innerElementType}
                        useIsScrolling
                        itemSize={(): number => ELEMENT_HEIGHT}
                        itemData={{
                          list: ProjectsData,
                          isItemLoaded,
                          restrictions,
                          onRemoveProject: onRemoveFromProject,
                        }}
                        itemKey={(index, data): string =>
                          data?.[index]?.id ?? index
                        }
                        onItemsRendered={handleItemsRendered(onItemsRendered)}
                        onScroll={handleListScroll}
                      >
                        {ProjectListItem}
                      </List>
                    </div>
                  )}
                </InfiniteLoader>
                {itemCount > MIN_PROJECT_ELEMENT_COUNT ? (
                  <span className={styles.track} />
                ) : (
                  ''
                )}
              </div>
            </Col>
          )}
        </div>
      </Col>
      <AddProjectsModal
        countries={countries}
        userCountryData={userCountryData}
        projectsData={ProjectsData}
        displayCDAWarningPrompt={displayCDAWarningPrompt}
        projectCompatibilityStatus={projectCompatibilityStatus}
        userUnassignedCountries={userUnassignedCountries}
        onCDAWarningPromptAction={onCDAWarningPromptAction}
        onCreateClick={onAddNewProjects}
        toggleAddUserToProjectsModal={toggleAddUserToProjectsModal}
        showAddProjectModal={showAddProjectModal}
      />
    </BlockUi>
  );
};

export default forwardRef(UsersProjectsPane);
