import { useEffect, useState } from 'react';

import AuthApiInstance from 'api/auth/AuthApi';
import TooltipStatus from 'api/auth/types/currency/TooltipStatus';
import InsightsLogger from 'helpers/logging/InsightsLogger';
import TooltipType from 'shared/enums/TooltipType';

/**
 * This hook is used to get the tooltip status and update tooltip display statuses
 * @param toolTips Tooltip names array that needs to get the status
 * @returns tooltipStatus statuses of requested tooltips and updateTooltipStatus method to update the statuses
 */
const useTooltipStatus = (toolTips: TooltipType[]) => {
  const [tooltipStatus, setTooltipStatus] = useState<TooltipStatus[]>();
  /**
   * Fetch tooltip statuses
   */
  const getTooltipStatus = async (): Promise<void> => {
    try {
      const status = await AuthApiInstance.GetTooltipStatus(toolTips);
      setTooltipStatus(status.items);
    } catch (error: Error | unknown) {
      if (error instanceof Error) InsightsLogger.logWarn(error.message, error);
    }
  };

  /**
   * Update tooltip statuses
   */
  const updateTooltipStatus = async (status: TooltipStatus[]) => {
    try {
      await AuthApiInstance.UpdateTooltipStatus(status);
      getTooltipStatus();
    } catch (error: Error | unknown) {
      if (error instanceof Error) InsightsLogger.logWarn(error.message, error);
    }
  };

  useEffect(() => {
    getTooltipStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tooltipStatus, updateTooltipStatus };
};

export default useTooltipStatus;
