/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, FC as ReactFC } from 'react';

import NewPasswordFieldProps from './NewPasswordFieldProps';

const NewPasswordField: ReactFC<NewPasswordFieldProps> = (
  props: NewPasswordFieldProps
) => {
  const { field, form, meta, ...rest } = props;
  const [show, setShow] = useState(false);

  const showButtonRef = useRef<null | HTMLButtonElement>(null);

  const toggleShow = (): void => setShow((state) => !state);

  /**
   * Handle blur for form field
   *
   * @param onBlur Blur function
   */
  const getHandleBlur =
    (onBlur) =>
    (event): void => {
      event.persist();
      if (event.relatedTarget === showButtonRef.current) {
        return;
      }
      onBlur(event);
    };

  /**
   * Handle button blur
   */
  const getHandleButtonBlur = (): void => {
    form.setFieldTouched(field.name);
  };

  return (
    <div className="password-field">
      <input
        className="form-control"
        {...rest}
        {...field}
        type={show ? 'text' : rest.type}
        onBlur={getHandleBlur(field.onBlur)}
      />
      <button
        ref={showButtonRef}
        onClick={toggleShow}
        onBlur={getHandleButtonBlur}
        disabled={field.value.length <= 0}
        type="button"
      >
        {show ? <i className="icon-eye-hide" /> : <i className="icon-eye-on" />}
      </button>
    </div>
  );
};

export default NewPasswordField;
