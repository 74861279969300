/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import QueryObjectBuilder from 'api/common/QueryObjectBuilder';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import FacilitatorMetric from 'api/dashboard/types/facilitator/FacilitatorMetric';
import FinancialMetric from 'api/dashboard/types/financial/FinancialMetric';
import GettingStartedGuide from 'api/dashboard/types/GettingStartedGuide';
import GroupMetric from 'api/dashboard/types/group/GroupMetric';
import LastRefreshed from 'api/dashboard/types/LastRefreshed';
import MembershipMetric from 'api/dashboard/types/membership/MembershipMetric';
import Widget from 'api/dashboard/types/widget/Widget';
import WidgetPreference from 'api/dashboard/types/widget/WidgetPreference';
import Facilitator from 'api/messaging/types/group-filters/Facilitator';
import { DashBoardApiTags } from 'constants/request-tags/RequestTags';
import FacilitatorMetricsFilters from 'modules/private/dashboard/components/facilitator-metrics/FacilitatorMetricsFilter';
import FinancialMetricsFilter from 'modules/private/dashboard/components/financial-metrics/FinancialMetricsFilter';
import MembershipMetricsFilter from 'modules/private/dashboard/components/membership-metrics/MembershipMetricsFilter';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import CyclePhase from 'shared/enums/CyclePhase';

class DashboardApi extends ApiBase {
  async GetDashboardExcel(
    globalFilters: GlobalFilters,
    reportTime: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const action = 'dashboard/excel-export';

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);

    const queryParams = { ...globalFiltersQuery, reportTime };

    const excelData = this.GetAsyncBlob({
      action,
      tag: DashBoardApiTags.GetDashboardExcel,
      cancelSource: cancelToken,
      queryParams,
    });

    return excelData;
  }

  async GetWidgetData(
    filters: GlobalFilters,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Widget>> {
    const action = 'dashboard/widgets';

    const queryParams = QueryObjectBuilder.createGlobalFiltersQuery(filters);
    const widgetData = this.GetAsync<ListResponse<Widget>>({
      action,
      tag: DashBoardApiTags.GetWidgetData,
      cancelSource: cancelToken,
      queryParams,
    });

    return widgetData;
  }

  async GetDashboardFinancialMetricData(
    globalFilters: GlobalFilters,
    filters: FinancialMetricsFilter,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<FinancialMetric>> {
    const action = 'dashboard/metrics/financials';

    const globalFilterQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);

    const financialFilterQuery =
      QueryObjectBuilder.createFinancialFiltersQuery(filters);

    const queryParams = { ...globalFilterQuery, ...financialFilterQuery };

    const financialMetricsData = this.GetAsync<ListResponse<FinancialMetric>>({
      action,
      tag: DashBoardApiTags.GetDashboardFinancialMetricData,
      cancelSource: cancelToken,
      queryParams,
    });

    return financialMetricsData;
  }

  async GetMembershipMetricData(
    globalFilters: GlobalFilters,
    filters: MembershipMetricsFilter,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<MembershipMetric>> {
    const action = 'dashboard/metrics/membership';

    const globalFilterQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);
    const membershipFilterQuery =
      QueryObjectBuilder.createMembershipFiltersQuery(filters);

    const queryParams = { ...globalFilterQuery, ...membershipFilterQuery };

    const membershipMetricsData = this.GetAsync<ListResponse<MembershipMetric>>(
      {
        action,
        tag: DashBoardApiTags.GetMembershipMetricData,
        cancelSource: cancelToken,
        queryParams,
      }
    );

    return membershipMetricsData;
  }

  async GetGroupMetricsData(
    filters: GlobalFilters,
    cyclePhase: CyclePhase,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupMetric>> {
    const action = 'dashboard/metrics/groups';

    let queryParams: Record<string, any> =
      QueryObjectBuilder.createGlobalFiltersQuery(filters);

    queryParams = { ...queryParams, cyclePhase };

    const groupMetricsData = this.GetAsync<ListResponse<GroupMetric>>({
      action,
      tag: DashBoardApiTags.GetGroupMetricsData,
      cancelSource: cancelToken,
      queryParams,
    });

    return groupMetricsData;
  }

  async SetWidgetPreferences(
    preferences: WidgetPreference[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/preferences/dashboard/widgets';
    const result = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body: preferences,
      tag: DashBoardApiTags.SetWidgetPreferences,
      cancelSource: cancelToken,
    });

    return result;
  }

  async GetGettingStartedGuide(
    haveGroups: boolean,
    cancelToken?: CancelTokenSource
  ): Promise<ObjectResponse<GettingStartedGuide>> {
    const action = 'organizations/download-guide';
    const queryParams = { option: haveGroups ? 1 : 2 };
    const response = await this.GetAsync<ObjectResponse<GettingStartedGuide>>({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.GetGettingStartedGuide,
      cancelSource: cancelToken,
      queryParams,
    });
    return response;
  }

  async SetGettingStartedStatus(
    cancelToken?: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/get-started-status/complete';
    const response = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.SetGettingStartedStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  async GetBackupStatusLastRefreshed(
    cancelToken?: CancelTokenSource
  ): Promise<ObjectResponse<LastRefreshed>> {
    const action = 'dashboard/last-refreshed';
    const response = await this.GetAsync<ObjectResponse<LastRefreshed>>({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.GetBackupStatusLastRefreshed,
      cancelSource: cancelToken,
    });
    return response;
  }

  async GetFacilitatorMetricData(
    globalFilters: GlobalFilters,
    filters: FacilitatorMetricsFilters,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<FacilitatorMetric>> {
    const action = 'dashboard/metrics/facilitators';

    const globalFilterQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);
    const facilitatorFilterQuery =
      QueryObjectBuilder.createFacilitatorFiltersQuery(filters);

    const queryParams = { ...globalFilterQuery, ...facilitatorFilterQuery };

    const facilitatorMetricsData = this.GetAsync<
      ListResponse<FacilitatorMetric>
    >({
      action,
      tag: DashBoardApiTags.GetFacilitatorMetricData,
      cancelSource: cancelToken,
      queryParams,
    });

    return facilitatorMetricsData;
  }

  // eslint-disable-next-line class-methods-use-this
  async GetFacilitators(
    globalFilters: GlobalFilters,
    supervisors?: string[],
    cancelToken?: CancelTokenSource
  ): Promise<ListResponse<Facilitator>> {
    const globalFilterQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);

    const enableGroupFilter = { enableGroupFilter: true };

    let queryParams: Record<string, any> = {
      ...globalFilterQuery,
      ...enableGroupFilter,
    };

    const action = 'organizations/facilitators/lookup';

    if (supervisors?.length) {
      queryParams = { ...queryParams, supervisorId: supervisors };
    }

    const facilitatorsResponse = this.GetAsync<
      Promise<ListResponse<Facilitator>>
    >({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.GetFacilitators,
      cancelSource: cancelToken,
      queryParams,
    });

    return facilitatorsResponse;
  }
}

const DashboardApiInstance = new DashboardApi();

export default DashboardApiInstance;
