import ErrorCodes from 'constants/ErrorCodes';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ErrorStatus = {
  fields: {
    [x: string]: number;
  };
  msg: string;
  code?: number;
};

/**
 * Formats error object into error status object
 *
 * @param error Error object to be formatted
 * @param defaultMessage Default error message if no error message is included in supplied error object
 * @returns {ErrorStatus} Formatted error status object
 */
const getErrorStatus = (error: any, defaultMessage: string): ErrorStatus => {
  let fields = {};
  if (error.response && error.response.errors?.length > 0) {
    fields = error.response?.errors.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.fieldName]: currentValue.errorCode,
      }),
      {}
    );
  }
  return {
    fields,
    msg: error.response?.message ?? defaultMessage,
  };
};

/**
 * Gets first error code in error object with multiple errors
 *
 * @param error Error object
 * @returns {number} First error code
 */
const getFirstError = (error: any): number => {
  if (
    error.response &&
    error.response.errors &&
    error.response.errors.length > 0
  ) {
    const codes = error.response.errors.map((value) => value.errorCode);
    if (codes.length > 0) {
      return codes[0];
    }
  }
  return ErrorCodes.Generic;
};

const findErrorCode = (error: any, errorCode: number): boolean => {
  if (
    error.response &&
    error.response.errors &&
    error.response.errors.length > 0
  ) {
    return !!error.response.errors.find(
      (err) => Number(err.errorCode) === errorCode
    );
  }
  return false;
};

const getLocalizedErrorString = (errorCode: number | string): string =>
  `ERR_API_CODE_${errorCode}`;

export default {};
export {
  getErrorStatus,
  getFirstError,
  getLocalizedErrorString,
  findErrorCode,
};
