import { FC as ReactFC } from 'react';

import isEmpty from 'lodash/isEmpty';

import InlineEditSupervisorsSelect from '../inline-edit-supervisors-select/InlineEditSupervisorsSelect';
import SupervisorCellProps from './SupervisorCellProps';

const SupervisorCell: ReactFC<SupervisorCellProps> = (
  props: SupervisorCellProps
) => {
  const {
    editMode,
    selectedRows,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    onUpdateUser,
    ...cellProps
  } = props;

  if (editMode && isEmpty(selectedRows)) {
    return (
      <InlineEditSupervisorsSelect
        cellProps={cellProps}
        supervisors={supervisors}
        supervisorsFiltered={supervisorsFiltered}
        supervisorsStatus={supervisorsStatus}
        onUpdateUser={onUpdateUser}
      />
    );
  }
  return cellProps.value;
};
export default SupervisorCell;
