import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import MessagingToolbarProps from './MessagingToolbarProps';

const MessagingToolbar: ReactFC<MessagingToolbarProps> = (
  props: MessagingToolbarProps
) => {
  const { userDropdown } = props;

  return (
    <header className="main-header">
      <div className="header-col">
        <h2 className="main-title" style={{ display: 'flex' }}>
          {intl.get('LBL_MESSAGES_TITLE')}
        </h2>
      </div>
      <div className="header-col ml-auto">{userDropdown}</div>
    </header>
  );
};

export default MessagingToolbar;
