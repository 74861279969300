import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import Frequency from 'shared/enums/Frequency';

class FacilitatorMetricsFilter {
  supervisors: Array<string>;

  facilitators: Array<string>;

  frequency: SelectFieldOption;

  constructor() {
    this.supervisors = [];

    this.frequency = {
      value: Frequency.Monthly,
      label: 'Monthly',
      isDisabled: false,
    };
    this.facilitators = [];
  }
}

export default FacilitatorMetricsFilter;
