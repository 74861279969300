const ErrorCodes = {
  InvalidVerificationCode: 11012,
  ExpiredVerificationCode: 11013,
  JobRoleExists: 12001,
  InvalidCountryAccess: 15003,
  InvalidProjectsAccess: 15004,
  InvalidFacilitatorAccess: 15005,
  StatusToggleBlocked: 16004,
  UserEmailChangedOrDeactivated: 11026,
  UserSessionExpired: 11027,
  UnauthorizedUserProfile: 11028,
  Generic: -10000,
};

export default ErrorCodes;
