import { FC as ReactFC } from 'react';

import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import GroupBar from 'shared/components/ins-charts/group-bar/GroupBar';
import GroupBarValue from 'shared/components/ins-charts/group-bar/GroupBarValue';

const AgeWidget: ReactFC<{ data: WidgetViewModel }> = (props) => {
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const groupBarValues = new Map<string, GroupBarValue[]>();

  (values || []).forEach((barValue) => {
    const newItem = {
      name: barValue.group,
      value: barValue.percentage,
    } as GroupBarValue;
    if (groupBarValues.get(barValue.title)) {
      // eslint-disable-next-line no-unused-expressions
      groupBarValues.get(barValue.title)?.push(newItem);
    } else {
      groupBarValues.set(barValue.title, [newItem]);
    }
  });

  return (
    <div className="gender-widget">
      <div className="chart-container">
        <GroupBar
          isLoading={false}
          values={groupBarValues}
          dimension={dimensionSettings}
        />
      </div>
    </div>
  );
};

export default AgeWidget;
