import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Row,
} from 'reactstrap';

import primaryLogo from 'shared/static/img/primary-logo.svg';

import InsNavLink from '../../ins-nav-link/InsNavLink';
import styles from './modalBackground.module.scss';

const TopContent: ReactFC = () => (
  <Col>
    <div className={`${styles.bar} ${styles.big} w-50`} />
    <div className={`${styles.bar} ${styles.big} w-75`} />
  </Col>
);

const MidContent: ReactFC = () => (
  <Col>
    <div className={`${styles.bar} ${styles.small} w-50`} />
    <div className={`${styles.bar} ${styles.big} w-25`} />
    <div className={`${styles.bar} ${styles.tiny} w-25`} />
    <div className={`${styles.bar} ${styles.small} w-50`} />
    <div className={`${styles.bar} ${styles.big} w-25`} />
  </Col>
);

const BottomContent: ReactFC = () => (
  <Col className="mt-3 pt-3">
    <div className={`${styles.bar} ${styles.big} w-75`} />
    <div className={`${styles.bar} ${styles.big} w-50`} />
  </Col>
);

const ModalBackground: ReactFC = (props) => {
  const { children } = props;
  return (
    <>
      <aside className="main-sidebar">
        <section className="sidebar-section logo">
          <div className="logo-img">
            <img src={primaryLogo} alt="Logo" />
          </div>
        </section>

        <nav className="sidebar-section main-navigation">
          <ul className="sidebar-nav">
            <li className="active">
              <InsNavLink
                disabled
                to="/dashboard"
                disabledClass="disabled-nav-link"
              >
                <i className="icon-dashboard" />
                <span>{intl.get('BTN_DASHBOARD')}</span>
              </InsNavLink>
            </li>
            <li>
              <InsNavLink
                disabled
                to="/finances"
                disabledClass="disabled-nav-link"
              >
                <i className="icon-money" />
                <span>{intl.get('BTN_FINANCES')}</span>
              </InsNavLink>
            </li>
            <li>
              <InsNavLink
                disabled
                to="/groups"
                disabledClass="disabled-nav-link"
              >
                <i className="icon-users" />
                <span>{intl.get('BTN_GROUPS')}</span>
              </InsNavLink>
            </li>
            <li>
              <InsNavLink
                disabled
                to="/facilitators"
                disabledClass="disabled-nav-link"
              >
                <i className="icon-users" />
                <span>{intl.get('BTN_FACILITATORS')}</span>
              </InsNavLink>
            </li>
            <li>
              <InsNavLink
                disabled
                to="/projects"
                disabledClass="disabled-nav-link"
              >
                <i className="icon-users" />
                <span>{intl.get('BTN_PROJECTS')}</span>
              </InsNavLink>
            </li>
            <li>
              <InsNavLink
                disabled
                to="/settings"
                disabledClass="disabled-nav-link"
              >
                <i className="icon-users" />
                <span>{intl.get('BTN_SETTINGS')}</span>
              </InsNavLink>
            </li>
          </ul>
        </nav>
      </aside>
      <header className="main-header">
        <div className={`${styles.bar} ${styles.big} w-25 mb-0`} />
        <div className="header-col">
          <Dropdown disabled>
            <DropdownToggle
              disabled
              className="header-user"
              tag="button"
              data-toggle="dropdown"
            />
            <DropdownMenu className="user-dropdown-menu">
              <DropdownItem className="user-dropdown-btn">
                {intl.get('BTN_LOGOUT')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </header>
      <div className="content-container">
        {children}
        <Row className="mb-3 mt-3 pb-3">
          {[1, 2, 3, 4].map((element) => (
            <TopContent key={element} />
          ))}
        </Row>
        <Row className="mt-3 mb-3 pt-3 pb-3">
          {[1, 2, 3, 4].map((element) => (
            <MidContent key={element} />
          ))}
        </Row>
        <Row className="mt-3 pt-3">
          {[1, 2, 3, 4].map((element) => (
            <BottomContent key={element} />
          ))}
        </Row>
      </div>
    </>
  );
};

export default ModalBackground;
