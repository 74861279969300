/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
export interface Registry {
  unregister: () => void;
}

export interface Callable {
  [key: string]: Function;
}

export interface Subscriber {
  [key: string]: Callable;
}

export interface Event {
  dispatch<T>(event: string, arg?: T): void;
  register(event: string, callback: Function): Registry;
}

export class EventBus implements Event {
  public subscribers: Subscriber;

  private static nextId = 0;

  private static eventBusInstance?: EventBus = undefined;

  private constructor() {
    this.subscribers = {};
  }

  public static getInstance(): EventBus {
    if (this.eventBusInstance === undefined) {
      this.eventBusInstance = new EventBus();
    }

    return this.eventBusInstance;
  }

  public dispatch<T>(event: string, arg?: T): void {
    const subscriber = this.subscribers[event];

    if (subscriber === undefined) {
      return;
    }

    Object.keys(subscriber).forEach((key) => subscriber[key](arg));
  }

  public register(event: string, callback: Function): Registry {
    const id = this.getNextId();
    if (!this.subscribers[event]) this.subscribers[event] = {};

    this.subscribers[event][id] = callback;

    return {
      unregister: (): void => {
        delete this.subscribers[event][id];
        if (Object.keys(this.subscribers[event]).length === 0)
          delete this.subscribers[event];
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private getNextId(): number {
    const nextEvent = EventBus.nextId + 1;
    return nextEvent;
  }
}
