import { SortableElement } from 'react-sortable-hoc';

import AppContext from 'context/AppContext';

import GroupWidgets from '../widgets/group-widget/GroupWidgets';
import SortableWidgetProps from './SortableWidgetProps';

/**
 * Wrapping the standard Widget components with SortableElement HOC to
 * enable drag and drop sorting.
 */
const SortableWidget = SortableElement(
  ({ widgetData, widgetsLoading, updateWidgetData }: SortableWidgetProps) => (
    <AppContext.Consumer>
      {(appContext): JSX.Element => (
        <GroupWidgets
          data={widgetData}
          widgetsLoading={widgetsLoading}
          groupDetailsSetupInProgress={appContext.groupDetailsSetupInProgress}
          updateWidgetData={updateWidgetData}
          startGroupDetailsSetup={appContext.startGroupDetailsSetup}
        />
      )}
    </AppContext.Consumer>
  )
);

export default SortableWidget;
