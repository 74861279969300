const MessagesSortableColumns: Set<string> = new Set([
  'title',
  'creator',
  'status',
  'receiverStats.targetedCount',
  'receiverStats.receivedCount',
  'sendDate',
]);

const TableConfig = {
  defaultSort: 'sendDate',
  sortCols: MessagesSortableColumns,
  defaultSortDesc: true,
};

export default TableConfig;
