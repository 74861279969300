/* eslint-disable react/jsx-props-no-spreading */
import { useState, FC as ReactFC } from 'react';

import { ResizeObserver } from '@juggle/resize-observer';
import { Arrow, ToggleLayer, useHover } from 'react-laag';
import { components } from 'react-select';

import { insightsColors } from 'helpers/ColorHelper';
import TooltipOptionAnchor from 'shared/enums/TooltipOptionAnchor';

import TooltipOptionProps from './TooltipOptionProps';

const TooltipOption: ReactFC<TooltipOptionProps> = (props) => {
  const { anchor, tooltipBody } = props;

  const [open, setOpen] = useState(false);
  const hideLayer = (): void => setOpen(false);
  const showLayer = (): void => setOpen(true);

  const hoverProps = useHover({
    delayEnter: 100,
    delayLeave: 100,
    onShow: showLayer,
    onHide: hideLayer,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const placement: any = {
    anchor: anchor ?? TooltipOptionAnchor.RightCenter,
    possibleAnchors: [
      TooltipOptionAnchor.RightCenter,
      TooltipOptionAnchor.LeftCenter,
    ],
    triggerOffset: 10,
    autoAdjust: true,
    snapToAnchor: false,
    preferX: 'RIGHT',
  };

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }): JSX.Element => (
    <>
      {isOpen ? (
        <div
          ref={layerProps.ref}
          className="popover invite-user-permission"
          style={{
            ...layerProps.style,
          }}
          {...hoverProps}
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            backgroundColor="#ffffff"
            borderWidth={1}
            borderColor={insightsColors.insightSecondary}
            roundness={0.7}
          />
          {tooltipBody}
        </div>
      ) : null}
    </>
  );

  const renderLayerChild = ({ triggerRef }): JSX.Element => (
    <div ref={triggerRef} {...hoverProps}>
      <components.Option {...props} />
    </div>
  );

  return (
    <ToggleLayer
      fixed
      isOpen={open}
      ResizeObserver={ResizeObserver}
      renderLayer={renderLayer}
      placement={placement}
      closeOnOutsideClick
      onOutsideClick={hideLayer}
    >
      {renderLayerChild}
    </ToggleLayer>
  );
};

export default TooltipOption;
