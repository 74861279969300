/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CSSProperties } from 'react';

import { StylesConfig } from 'react-select';

class InlineEditStyles {
  public static GetCustomStyles(): StylesConfig {
    return {
      container: () => ({ minWidth: '200px' }),
      option: (base: CSSProperties) => ({
        ...base,
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 500,
      }),
      menuPortal: (base) => ({
        ...base,
        top: 'initial',
        left: 'initial',
        zIndex: 90,
      }),
    };
  }
}

export default InlineEditStyles;
