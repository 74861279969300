import { Component } from 'react';

import axios from 'axios';
import * as download from 'downloadjs';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { arrayMove, SortEnd } from 'react-sortable-hoc';

import ApiError from 'api/common/types/ApiError';
import GroupApiInstance from 'api/group/GroupApi';
import GroupWidgetPreference from 'api/group/types/group-widget/GroupWidgetPreference';
import GroupDetailsBalanceSheet from 'api/group/types/GroupDetailsBalanceSheet';
import ProjectsApiInstance from 'api/projects/ProjectsApi';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import ModulePaths from 'constants/ModulePaths';
import { formatDate } from 'helpers/DateFormat';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import GroupFinancialChartFilter from 'modules/private/group/components/group-financial-chart-container/GroupFinancialChartFilter';
import GroupWidgetViewModel from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetViewModel';
import BalanceSheet from 'shared/components/balance-sheet/BalanceSheet';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import DateFormatType from 'shared/enums/DateFormatType';
import EventKey from 'shared/enums/EventKey';
import FilterType from 'shared/enums/FilterType';
import HttpStatus from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import GroupDetails from '../../components/group-details-left-panel/GroupDetails';
import GroupDetailsLeftPanel from '../../components/group-details-left-panel/GroupDetailsLeftPanel';
import GroupFinancialChartChartTypes from '../../components/group-financial-chart-container/GroupFinancialChartChartTypes';
import GroupFinancialChartContainer from '../../components/group-financial-chart-container/GroupFinancialChartContainer';
import SortableWidgetContainer from '../../components/group-widgets/sortable-widget-container/SortableWidgetContainer';
import GroupDetailsViewProps from './GroupDetailsViewProps';
import GroupDetailsViewState from './GroupDetailsViewState';

class GroupDetailsView extends Component<
  GroupDetailsViewProps,
  GroupDetailsViewState
> {
  constructor(props) {
    super(props);
    this.state = {
      groupDetails: {
        data: new GroupDetails(),
        loading: false,
        error: null,
        facilitatorsStatus: Status.Idle,
        facilitators: [],
        facilitatorsError: '',
        assignToProjectStatus: Status.Idle,
        assignToProjectError: '',
        assignFacilitatorsStatus: Status.Idle,
        assignFacilitatorsError: '',
        assignGroupsStatusStatus: Status.Idle,
        assignGroupsStatusError: '',
      },
      widgets: {
        widgetData: [],
        widgetsLoading: true,
      },
      financialMetricsChart: {
        chartType: GroupFinancialChartChartTypes.CumulativeSavings,
        data: {},
        filters: new GroupFinancialChartFilter(),
        loading: true,
      },
      balanceSheet: {
        data: new GroupDetailsBalanceSheet(),
        loading: true,
        error: null,
      },
    };
  }

  componentDidMount(): void {
    const { match, appContext } = this.props;
    const { globalFilters, hideErrorToast, validateCurrencyToggle } =
      appContext;
    hideErrorToast();
    const groupNumber = (match?.params.groupNumber ?? '').toUpperCase();

    setPageTitle(`${intl.get('BTN_GROUPS')} - ${groupNumber}`);

    /* Validate currencies for group details page before fetching group details data */
    validateCurrencyToggle(globalFilters, groupNumber).then(() => {
      this.loadGroupDetails(groupNumber);
    });
  }

  componentDidUpdate(
    prevProps: GroupDetailsViewProps,
    prevState: GroupDetailsViewState
  ): void {
    const { appContext, match } = this.props;
    const { financialMetricsChart, groupDetails } = this.state;

    const groupNumber = (match?.params.groupNumber ?? '').toUpperCase();

    /* proceed with the rest only if group details request goes through; */
    /* and hopefully doesn't get a 404/403 and get redirected */
    if (
      prevState.groupDetails.loading !== groupDetails.loading &&
      groupDetails.loading === false
    ) {
      this.loadWidgetData(groupNumber);
      this.loadFinancialData(
        groupNumber,
        financialMetricsChart.chartType,
        financialMetricsChart.filters
      );
      this.loadBalanceSheetData(groupNumber);
    }

    const { setGroupDetailsSetupCallback, setExcelReportDownloadCallback } =
      appContext;
    setGroupDetailsSetupCallback(this.saveCallback);
    setExcelReportDownloadCallback(this.excelDownloadCallback);

    // prettier-ignore
    if (!isEqual(prevState.financialMetricsChart.filters, financialMetricsChart.filters)) {
        this.loadFinancialData(groupNumber, financialMetricsChart.chartType, financialMetricsChart.filters);
      }
    /* chart type change */
    // prettier-ignore
    if(prevState.financialMetricsChart.chartType !== financialMetricsChart.chartType) {
      this.loadFinancialData(groupNumber, financialMetricsChart.chartType, financialMetricsChart.filters);
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Sets group details to stat
   *
   * @param groupDetailsState Updated group details slice data
   */
  setGroupDetails = (
    groupDetailsState: Partial<GroupDetailsViewState['groupDetails']>
  ): void => {
    this.setState((state) => ({
      groupDetails: { ...state.groupDetails, ...groupDetailsState },
    }));
  };

  /**
   * Sets balance sheet data to state
   *
   * @param balanceSheetState Updated balance sheet slice data
   */
  setBalanceSheetData = (
    balanceSheetState: Partial<GroupDetailsViewState['balanceSheet']>
  ): void => {
    this.setState((state) => ({
      balanceSheet: { ...state.balanceSheet, ...balanceSheetState },
    }));
  };

  /**
   * Sets widgets data to state
   *
   * @param widgetState Updated widgets slice data
   */
  setWidgetsData = (
    widgetState: Partial<GroupDetailsViewState['widgets']>
  ): void => {
    this.setState((state) => ({
      widgets: { ...state.widgets, ...widgetState },
    }));
  };

  /**
   * Sets financial metrics chart data to state
   *
   * @param chartState Updated financial metrics chart slice data
   */
  setChartData = (
    chartState: Partial<GroupDetailsViewState['financialMetricsChart']>
  ): void => {
    this.setState((state) => ({
      financialMetricsChart: { ...state.financialMetricsChart, ...chartState },
    }));
  };

  /**
   * Fetch group details
   *
   * @param groupNumber Group ID
   */
  loadGroupDetails = async (groupNumber: string): Promise<void> => {
    const { history, location, appContext } = this.props;
    try {
      this.setGroupDetails({ loading: true });
      const response = await GroupApiInstance.GetGroupDetails(
        groupNumber,
        this.source
      );
      if (response.item) {
        this.setGroupDetails({
          loading: false,
          data: response.item,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error instanceof ApiError) {
        /* The user might be unauthorized to access certain groups(403) 
          as well as the group might not exist at all (404) */
        if (
          error.status === HttpStatus.NOT_FOUND ||
          error.status === HttpStatus.FORBIDDEN
        ) {
          history.replace({
            pathname: ModulePaths.GroupsPath,
            search: getGlobalFiltersQuery(location.search),
          });
        } else {
          this.setGroupDetails({ loading: false, error });
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        this.setGroupDetails({
          loading: false,
          error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
        });
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch all facilitators
   *
   * @param search Search value
   */
  fetchFacilitators = async (search?: string): Promise<void> => {
    this.setGroupDetails({ facilitatorsStatus: Status.Loading });
    try {
      const response = await SettingsApiInstance.LookupOrganizationFacilitators(
        this.source,
        search
      );
      this.setGroupDetails({
        facilitators: response.items,
        facilitatorsStatus: Status.Success,
        facilitatorsError: '',
      });
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
      }

      this.setGroupDetails({
        facilitatorsStatus: Status.Error,
        facilitatorsError: errorText,
        assignFacilitatorsError: '',
      });
    }
  };

  /**
   * Fetch balance sheet data
   *
   * @param groupNumber Group ID
   */
  loadBalanceSheetData = async (groupNumber: string): Promise<void> => {
    try {
      this.setBalanceSheetData({ loading: true });
      const response = await GroupApiInstance.GetGroupDetailsBalanceSheet(
        groupNumber,
        this.source
      );
      if (response.item) {
        this.setBalanceSheetData({
          loading: false,
          data: response.item,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setBalanceSheetData({
        loading: false,
        error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
      });
    }
  };

  /**
   * Fetch widgets data
   *
   * @param groupNumber Group ID
   */
  loadWidgetData = async (groupNumber: string): Promise<void> => {
    const { appContext } = this.props;
    try {
      this.setWidgetsData({ widgetsLoading: true });
      const result = await GroupApiInstance.GetGroupWidgetData(
        groupNumber,
        this.source
      );
      this.setWidgetsData({
        widgetsLoading: false,
        widgetData: result.items,
      });
    } catch (error) {
      this.setWidgetsData({ widgetsLoading: false });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      }
    }
  };

  /**
   * Fetch financial metric chart data
   *
   * @param groupNumber Group ID
   * @param chartType Type of financial chart
   * @param financialFilters Currently set chart filters
   */
  loadFinancialData = async (
    groupNumber: string,
    chartType: GroupFinancialChartChartTypes,
    financialFilters: GroupFinancialChartFilter
  ): Promise<void> => {
    const { appContext } = this.props;
    const { financialMetricsChart } = this.state;
    try {
      this.setChartData({ loading: true });
      const response = await GroupApiInstance.GetGroupFinancialMetrics(
        groupNumber,
        chartType,
        financialFilters,
        this.source
      );
      if (response.item) {
        const result = response.item;
        const data = { ...financialMetricsChart.data, [result.type]: result };
        // prettier-ignore
        this.setChartData({ loading: false, error: null, data });
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error instanceof ApiError) {
        this.setChartData({
          loading: false,
          error,
          data: {},
        });
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        this.setChartData({
          loading: false,
          error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
          data: {},
        });
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Update widget data
   *
   * @param data Updated widgets data
   */
  updateWidgetData = (data: GroupWidgetViewModel): void => {
    const { widgets } = this.state;
    const clonedData = clone(widgets.widgetData);
    const oldWidget = clonedData.find((x) => x.position === data.position);

    if (oldWidget) {
      clonedData[clonedData.indexOf(oldWidget)] = data;
      this.setWidgetsData({ widgetData: clonedData });
    }
  };

  /**
   * Reorder widgets based on user preference
   *
   * @param param0 Updated widgets sort order
   */
  reorderWidgets = ({ oldIndex, newIndex }: SortEnd): void => {
    const { widgets } = this.state;

    const orderedWidgetData = arrayMove(widgets.widgetData, oldIndex, newIndex);
    this.setWidgetsData({ widgetData: orderedWidgetData });
  };

  /**
   * Formats and prepares widget preferences
   *
   * @returns {Array<GroupWidgetPreference>} List of widget preferences
   */
  preparePreferences = (): Array<GroupWidgetPreference> => {
    const { widgets } = this.state;
    const preferences = widgets.widgetData.map(
      (widgetModel: GroupWidgetViewModel, index: number) => {
        const preference = new GroupWidgetPreference();
        preference.position = index + 1;
        preference.type = widgetModel.type;

        return preference;
      }
    );

    return preferences;
  };

  /**
   * Triggers analytics tracking for the widget preferences save event
   *
   * @param preferences Updated widget preferences
   */
  widgetPreferenceSaveEventGA = (preferences): void => {
    const types: string[] = [];
    preferences.forEach((preference): void => {
      types.push(LabelKeysGA[`WIDGET_${String(preference.type)}`]);
      sendEventGA(
        `${CategoryKeysGA.GroupDetailsWidgets}/${String(
          LabelKeysGA[`WIDGET_${String(preference.type)}`]
        )}`,
        ActionKeysGA.ReorderWidget,
        `Position: ${String(preference.position)}`
      );
    });
    sendEventGA(
      CategoryKeysGA.GroupDetailsWidgets,
      ActionKeysGA.SaveWidgetSetup,
      JSON.stringify(types)
    );
  };

  /**
   * Callback for saving widget preferences
   *
   * @param cancel Whether to cancel widget setup
   */
  saveCallback = async (cancel: boolean): Promise<void> => {
    const { match, appContext } = this.props;
    const groupNumber = (match?.params.groupNumber ?? '').toUpperCase();

    try {
      this.setWidgetsData({ widgetsLoading: true });
      if (cancel) {
        sendEventGA(
          CategoryKeysGA.GroupDetailsWidgets,
          ActionKeysGA.CancelWidgetSetup
        );
      } else {
        const preferences = this.preparePreferences();
        if (preferences.length < 9) {
          throw new Error('minimum widget count unmet');
        }
        await GroupApiInstance.SetWidgetPreferences(preferences, this.source);
        this.widgetPreferenceSaveEventGA(preferences);
      }
      const result = await GroupApiInstance.GetGroupWidgetData(
        groupNumber,
        this.source
      );
      this.setWidgetsData({
        widgetData: result.items,
        widgetsLoading: false,
      });
    } catch (error) {
      this.setWidgetsData({ widgetsLoading: false });
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      }
    }
  };

  /**
   * Callback for downloading the excel report
   */
  excelDownloadCallback = async (): Promise<void> => {
    const { match } = this.props;
    const groupNumber = (match?.params.groupNumber ?? '').toUpperCase();
    try {
      const reportTime = new Date();
      const formattedDate = formatDate(reportTime, DateFormatType.BlobRequest);

      const blob = await GroupApiInstance.GetGroupDetailsExcel(
        groupNumber,
        formattedDate,
        this.source
      );
      const dateTime = formatDate(reportTime, DateFormatType.ExcelFilename);
      const fileName = `DreamSaveInsight-GroupDetails-${groupNumber}_${dateTime}.xlsx`;
      download(blob, fileName);
      // prettier-ignore
      sendEventGA(CategoryKeysGA.GroupDetailsReports, ActionKeysGA.DownloadExcel, fileName);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      }
    }
  };

  /**
   * Assign a project to a list of groups
   *
   * @param projectId Selected project
   * @param groups Checked groups
   */
  postAssignGroupsToProject = async (projectId: string): Promise<void> => {
    const { groupDetails } = this.state;
    const { appContext } = this.props;
    this.setGroupDetails({ assignToProjectStatus: Status.Loading });
    try {
      await ProjectsApiInstance.AssignGroupsToProject(
        projectId,
        [groupDetails.data.id],
        this.source
      );
      this.setGroupDetails({
        assignToProjectStatus: Status.Success,
        assignToProjectError: '',
      });
      appContext.setSuccessToastText(intl.get('LBL_GD_PROJECT_ASSIGN_SUCCESS'));

      // load new data
      this.loadGroupDetails(groupDetails.data.number);

      sendEventGA(
        CategoryKeysGA.GroupsAssignDetails,
        ActionKeysGA.AssignToProject
      );
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
        if (error.status === HttpStatus.NOT_FOUND) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      }

      this.setGroupDetails({
        assignToProjectStatus: Status.Error,
        assignToProjectError: errorText,
      });
    }
  };

  /**
   * Assign a facilitator to a list of groups
   *
   * @param facilitator Selected facilitator
   * @param groups Checked groups
   */
  postAssignGroupsFacilitators = async (facilitator: string): Promise<void> => {
    const { groupDetails } = this.state;
    const { appContext } = this.props;
    this.setGroupDetails({ assignFacilitatorsStatus: Status.Loading });
    try {
      await GroupApiInstance.AssignFacilitatorsToProject(
        facilitator,
        [groupDetails.data.id],
        this.source
      );
      this.setGroupDetails({
        assignFacilitatorsStatus: Status.Success,
        assignFacilitatorsError: '',
      });
      appContext.setSuccessToastText(
        intl.get('LBL_GD_FACILITATOR_ASSIGN_SUCCESS')
      );

      // load new data
      this.loadGroupDetails(groupDetails.data.number);

      sendEventGA(
        CategoryKeysGA.GroupsAssignDetails,
        ActionKeysGA.AssignToFacilitator
      );
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
      }

      this.setGroupDetails({
        assignFacilitatorsStatus: Status.Error,
        assignFacilitatorsError: errorText,
        facilitatorsError: '',
      });
    }
  };

  /**
   * Assign a group status to a list of groups
   *
   * @param statusId Selected status
   * @param groups Checked groups
   */
  postAssignGroupsStatus = async (statusId: string): Promise<void> => {
    const { groupDetails } = this.state;
    const { appContext } = this.props;
    this.setGroupDetails({ assignGroupsStatusStatus: Status.Loading });
    try {
      await GroupApiInstance.AssignGroupStatusToGroups(
        statusId,
        [groupDetails.data.id],
        this.source
      );
      this.setGroupDetails({
        assignGroupsStatusStatus: Status.Success,
        assignGroupsStatusError: '',
      });
      appContext.setSuccessToastText(
        intl.get('LBL_GD_GROUP_STATUS_ASSIGN_SUCCESS')
      );

      // load new data
      this.loadGroupDetails(groupDetails.data.number);

      sendEventGA(
        CategoryKeysGA.GroupsAssignDetails,
        ActionKeysGA.AssignGroupStatus
      );
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = '';
        }
      }
      this.setGroupDetails({
        assignGroupsStatusStatus: Status.Error,
        assignGroupsStatusError: errorText,
      });
    }
  };

  /**
   * Sets status regarding group status assignment to state
   */
  setAssignGroupsStatusStatus = (status: Status): void =>
    this.setGroupDetails({ assignGroupsStatusStatus: status });

  /**
   * Sets status regarding project assignment to state
   */
  setAssignToProjectStatus = (status: Status): void =>
    this.setGroupDetails({ assignToProjectStatus: status });

  /**
   * Sets status regarding facilitator assignment to state
   */
  setAssignFacilitatorsStatus = (status: Status): void =>
    this.setGroupDetails({ assignFacilitatorsStatus: status });

  /**
   * Handle financial metric filter change event
   *
   * @param filter Updated financial metric filter value
   * @param type Type of filter
   */
  handleFinancialMetricFilterChange = (
    filter: SelectFieldOption,
    type: FilterType
  ): void => {
    this.setState((state) => ({
      financialMetricsChart: {
        ...state.financialMetricsChart,
        filters: {
          ...state.financialMetricsChart.filters,
          [type]: { ...filter },
        },
      },
    }));

    const { financialMetricsChart } = this.state;

    const eventCategory = `${
      CategoryKeysGA.GroupDetailsFinancialMetrics
    }/${String(
      LabelKeysGA[`GROUP_DETAILS_${financialMetricsChart.chartType}`]
    )}`;
    sendEventGA(
      eventCategory,
      ActionKeysGA.ChangeChartFilter,
      `${String(LabelKeysGA[type])}:${String(LabelKeysGA[filter.value])}`
    );
  };

  /**
   * Handle financial metric chart type change event
   *
   * @param type Updated financial metric chart type
   */
  handleFinancialMetricChartTypeChange = (
    type: GroupFinancialChartChartTypes
  ): void => {
    this.setState((state) => ({
      financialMetricsChart: {
        ...state.financialMetricsChart,
        chartType: type,
      },
    }));

    sendEventGA(
      CategoryKeysGA.GroupDetailsFinancialMetricChartType,
      ActionKeysGA.ChangeChartType,
      LabelKeysGA[`GROUP_DETAILS_${type}`]
    );
  };

  render(): JSX.Element {
    const { appContext } = this.props;
    const {
      groupDetailsSetupInProgress,
      projectListData,
      groupStatusData,
      getProjectList,
      getGroupStatuses,
    } = appContext;

    const { groupDetails, widgets, financialMetricsChart, balanceSheet } =
      this.state;
    const { reorderWidgets, updateWidgetData } = this;
    const data = Object.values(financialMetricsChart.data);
    return (
      <>
        <ScrollToTopOnMount />
        <div
          className="content-container group-item"
          style={{ padding: '0px' }}
        >
          <BlockUi
            tag="div"
            blocking={groupDetails.loading}
            className="group-details-left-outer"
          >
            <GroupDetailsLeftPanel
              groupDetailsSetupInProgress={groupDetailsSetupInProgress}
              data={groupDetails.data}
              projectListData={projectListData}
              facilitators={groupDetails.facilitators}
              facilitatorsError={groupDetails.facilitatorsError}
              facilitatorsStatus={groupDetails.facilitatorsStatus}
              assignToProjectStatus={groupDetails.assignToProjectStatus}
              assignToProjectError={groupDetails.assignToProjectError}
              assignFacilitatorsStatus={groupDetails.assignFacilitatorsStatus}
              assignFacilitatorsError={groupDetails.assignFacilitatorsError}
              assignGroupsStatusStatus={groupDetails.assignGroupsStatusStatus}
              assignGroupsStatusError={groupDetails.assignGroupsStatusError}
              groupStatusData={groupStatusData}
              getGroupStatuses={getGroupStatuses}
              fetchFacilitators={this.fetchFacilitators}
              getProjectList={getProjectList}
              postAssignGroupsToProject={this.postAssignGroupsToProject}
              postAssignGroupsFacilitators={this.postAssignGroupsFacilitators}
              postAssignGroupsStatus={this.postAssignGroupsStatus}
              setAssignGroupsStatusStatus={this.setAssignGroupsStatusStatus}
              setAssignToProjectStatus={this.setAssignToProjectStatus}
              setAssignFacilitatorsStatus={this.setAssignFacilitatorsStatus}
            />
          </BlockUi>
          <div className="group-item-content">
            <div className="container-fluid">
              <SortableWidgetContainer
                widgetData={widgets.widgetData}
                axis="xy"
                groupDetailsSetupInProgress={groupDetailsSetupInProgress}
                onSortEnd={reorderWidgets}
                widgetsLoading={widgets.widgetsLoading}
                updateWidgetData={updateWidgetData}
              />
              <GroupFinancialChartContainer
                data={data}
                filters={financialMetricsChart.filters}
                loading={financialMetricsChart.loading}
                chartType={financialMetricsChart.chartType}
                onFilterChange={this.handleFinancialMetricFilterChange}
                onChartTypeChange={this.handleFinancialMetricChartTypeChange}
              />

              <BalanceSheet
                data={balanceSheet.data}
                loading={balanceSheet.loading}
                error={balanceSheet.error}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GroupDetailsView;
