import { SortableElement } from 'react-sortable-hoc';

import AppContext from 'context/AppContext';
import SortableWidgetProps from 'modules/private/dashboard/components/widget-metrics/sortable-widget/SortableWidgetProps';
import Widget from 'modules/private/dashboard/components/widget-metrics/widgets/widget/Widget';

/**
 * Wrapping the standard Widget components with SortableElement HOC to
 * enable drag and drop sorting.
 */
const SortableWidget = SortableElement(
  ({
    widgetData,
    selectedWidgets,
    widgetsLoading,
    dashboardSetupInProgress,
    onUpdateWidgetData,
  }: SortableWidgetProps) => (
    <AppContext.Consumer>
      {(appContext): JSX.Element => (
        <Widget
          data={widgetData}
          widgetsLoading={widgetsLoading}
          selectedWidgets={selectedWidgets}
          dashboardSetupInProgress={dashboardSetupInProgress}
          updateWidgetData={onUpdateWidgetData}
          startDashboardSetup={appContext.startDashboardSetup}
        />
      )}
    </AppContext.Consumer>
  )
);

export default SortableWidget;
