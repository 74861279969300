import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import timeUnit from 'shared/enums/TimeUnit';

class GroupFinancialChartFilter {
  timeUnit: SelectFieldOption;

  constructor() {
    this.timeUnit = {
      value: timeUnit.PreviousYear,
      label: 'Previous Year',
      isDisabled: false,
    };
  }
}

export default GroupFinancialChartFilter;
