import { RouteComponentProps } from 'react-router-dom';

import AppContextProps from 'context/AppContextProps';

export enum ModalType {
  GettingStarted = 'GETTING_STARTED',
  None = 'NONE',
}

interface DashboardViewProps extends RouteComponentProps {
  appContext: AppContextProps;
  modal?: ModalType;
}

export default DashboardViewProps;
