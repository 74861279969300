/* eslint-disable react/jsx-props-no-spreading */
import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
  FC as ReactFC,
} from 'react';

import BlockUi from 'react-block-ui';
import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer, useTooltip } from 'react-laag';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';

import styles from './assignProjectMenu.module.scss';
import AssignProjectMenuProps from './AssignProjectMenuProps';
import DummyProject from './DummyProject';

const getDummyProjects = (): DummyProject[] => {
  const dummies: DummyProject[] = [];
  for (let index = 0; index < 4; index += 1) {
    const dummy = new DummyProject(
      intl.get('LBL_PROJECT'),
      '',
      index.toString()
    );
    dummies.push(dummy);
  }
  return dummies;
};

const AssignProjectMenu: ReactFC<AssignProjectMenuProps> = (
  props: AssignProjectMenuProps
) => {
  const {
    isProjectsOpen,
    projectListData,
    assignToProjectStatus,
    assignToProjectError,
    hideBackdrop,
    children,
    onCloseProjects,
    onAssignToProject,
    getProjectList,
    setAssignToProjectStatus,
  } = props;

  const {
    placement = {},
    tooltipPlacement = {},
    currentProject = null,
  } = props;
  const [keepOpen, setKeepOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(
    currentProject
  );

  useEffect(() => {
    const handleScroll = (): void => {
      if (keepOpen) {
        setKeepOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return (): void => window.removeEventListener('scroll', handleScroll);
  }, [keepOpen]);

  useEffect(() => {
    if (isProjectsOpen === true) {
      getProjectList();
    } else {
      setKeepOpen(false);
      setAssignToProjectStatus(Status.Idle);
    }
    setSelectedProject(currentProject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectsOpen]);

  const projectsEmpty = Object.values(projectListData.data).length <= 0;

  const projectsModded =
    projectListData.status !== Status.Loading && projectsEmpty
      ? getDummyProjects()
      : Object.values(projectListData.filtered);

  /**
   * Update local selectedProjects from user changes
   *
   * @param event ChangeEvent input
   */
  const handleUpdateProjects = (event: ChangeEvent<HTMLInputElement>): void => {
    onAssignToProject(event.target.value);
    setSelectedProject(event.target.value);
  };
  /**
   * render function for tooltip when facilitators available
   * @param renderLayerProps
   */
  const renderTooltip = ({
    isOpen,
    layerProps,
  }: RenderLayerProps): ReactNode => {
    const isTooltipOpen =
      projectListData.status === Status.Success &&
      projectsEmpty &&
      (isOpen || keepOpen);

    return (
      isTooltipOpen && (
        <div
          {...layerProps}
          style={{ ...layerProps.style, zIndex: 99999, paddingRight: 28 }}
          onMouseEnter={(): void => setKeepOpen(true)}
          onMouseLeave={(): void => setKeepOpen(false)}
        >
          <div className={styles.tooltip}>
            {intl.get('ERR_GROUPS_NO_PROJECTS')}
            <InsLink
              disabled={false}
              disabledClass="disabled"
              className="border-0 text-orange p-0 shadow-none bg-transparent"
              style={{ textDecoration: 'underline' }}
              to={ModulePaths.ProjectsPath}
            >
              {intl.get('BTN_HERE')}
            </InsLink>
            .
          </div>
        </div>
      )
    );
  };

  const [tooltipElement, { ref, ...triggerProps }] = useTooltip(renderTooltip, {
    delayEnter: 100,
    delayLeave: 100,
    hideOnScroll: true,
    placement: {
      anchor: 'LEFT_CENTER',
      possibleAnchors: ['LEFT_CENTER'],
      triggerOffset: 0,
      ...tooltipPlacement,
    },
  });

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <>
        <div
          ref={layerProps.ref}
          style={{ ...layerProps.style }}
          {...triggerProps}
          className="layer insight-dropdown"
          onMouseEnter={(): void => setKeepOpen(true)}
          onMouseLeave={(): void => setKeepOpen(false)}
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            backgroundColor="#ffffff"
            borderWidth={1}
            borderColor="#dbdce1"
            roundness={0.7}
          />
          {projectListData.status !== Status.Error && (
            <>
              <DebounceInput
                minLength={2}
                disabled={projectsEmpty}
                debounceTimeout={1000}
                onChange={(event): Promise<void> =>
                  getProjectList(event.target.value)
                }
                // disabled={projectListData.status === Status.Loading}
                className="form-control mb-3"
                placeholder={intl.get('LBL_SEARCH')}
                type="search"
              />
              <div {...triggerProps} ref={ref}>
                <BlockUi
                  blocking={
                    projectListData.status === Status.Loading ||
                    assignToProjectStatus === Status.Loading
                  }
                >
                  <PerfectScrollBar className={styles.scroll}>
                    <Form>
                      {projectsModded.length > 0 ||
                      projectListData.status === Status.Loading ? (
                        projectsModded.map((project) => (
                          <FormGroup
                            key={project.projectId}
                            className="insight-radio-group"
                          >
                            <Label
                              className={`${styles.option} ${
                                project.projectId === selectedProject
                                  ? 'checked-label'
                                  : ''
                              }`}
                            >
                              <span
                                className={`insight-radio mr-3 ${styles.radio}`}
                              >
                                <Input
                                  type="radio"
                                  name={project.name}
                                  value={project.projectId}
                                  disabled={!project.code}
                                  checked={
                                    project.projectId === selectedProject
                                  }
                                  onChange={handleUpdateProjects}
                                />
                                <i className="icon-radio" />
                              </span>
                              <EllipsisTooltip
                                tag="span"
                                data-place="right"
                                data-for="insTooltip"
                                data-tip={project.code ? project.name : ''}
                                data-class="overflow-wrap"
                                className={`truncate ${styles.name} ${
                                  project.code ? '' : styles.dummy
                                }`}
                              >
                                {project.name}
                              </EllipsisTooltip>
                            </Label>
                          </FormGroup>
                        ))
                      ) : (
                        <FormGroup className="insight-radio-group">
                          <Label className="dummy truncate">
                            {intl.get('LBL_NO_OPTIONS')}
                          </Label>
                        </FormGroup>
                      )}
                    </Form>
                  </PerfectScrollBar>
                </BlockUi>
              </div>
            </>
          )}
          {(assignToProjectError ||
            projectListData.status === Status.Error) && (
            <Alert
              isOpen={
                assignToProjectStatus === Status.Error ||
                projectListData.status === Status.Error
              }
              tag="div"
              fade
              className="alert alert-danger mt-3"
              role="alert"
            >
              {assignToProjectError || intl.get('OOPS_SOMETHING_WENT_WRONG')}
            </Alert>
          )}
        </div>
        {hideBackdrop ? null : <div className="backdrop" />}
      </>
    );

  return (
    <>
      {tooltipElement}
      <ToggleLayer
        isOpen={isProjectsOpen}
        closeOnOutsideClick
        onOutsideClick={onCloseProjects}
        placement={{
          anchor: 'TOP_CENTER',
          possibleAnchors: ['TOP_CENTER'],
          triggerOffset: 30,
          ...placement,
        }}
        renderLayer={renderLayer}
      >
        {children}
      </ToggleLayer>
    </>
  );
};

export default AssignProjectMenu;
