/* eslint-disable react/jsx-props-no-spreading */
import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
  FC as ReactFC,
} from 'react';

import BlockUi from 'react-block-ui';
import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer, useTooltip } from 'react-laag';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import Status from 'shared/enums/Status';

import styles from './defaultCurrencyMenu.module.scss';
import DefaultCurrencyMenuProps from './DefaultCurrencyMenuProps';

const DefaultCurrencyMenu: ReactFC<DefaultCurrencyMenuProps> = (
  props: DefaultCurrencyMenuProps
) => {
  const {
    isMenuOpen,
    defaultCurrencyData,
    status,
    error,
    hideBackdrop,
    placement = {},
    tooltipPlacement = {},
    currentDefaultCurrency = null,
    children,
    onClose,
    onSetDefaultCurrency,
    getDefaultCurrencyData,
    setStatus,
  } = props;

  const [keepOpen, setKeepOpen] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      if (keepOpen) {
        setKeepOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return (): void => window.removeEventListener('scroll', handleScroll);
  }, [keepOpen]);

  useEffect(() => {
    if (!isMenuOpen) {
      setKeepOpen(false);
      setStatus(Status.Idle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const defaultCurrenciesEmpty = defaultCurrencyData.length <= 0;

  /**
   * Update local selectedCurrency from user changes
   *
   * @param event ChangeEvent input
   */
  const handleUpdateDefaultCurrency = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    onSetDefaultCurrency(event.target.value);
  };

  /**
   * render function for tooltip when facilitators available
   * @param renderLayerProps
   */
  const renderTooltip = ({
    isOpen,
    layerProps,
  }: RenderLayerProps): ReactNode => {
    const isTooltipOpen =
      status === Status.Success &&
      defaultCurrenciesEmpty &&
      (isOpen || keepOpen);

    return (
      isTooltipOpen && (
        <div
          {...layerProps}
          style={{ ...layerProps.style, zIndex: 99999, paddingRight: 28 }}
          onMouseEnter={(): void => setKeepOpen(true)}
          onMouseLeave={(): void => setKeepOpen(false)}
        >
          <div className={styles.tooltip}>
            {intl.get('ERR_SETTINGS_NO_CURRENCIES')}
          </div>
        </div>
      )
    );
  };

  const [tooltipElement, { ref, ...triggerProps }] = useTooltip(renderTooltip, {
    delayEnter: 100,
    delayLeave: 100,
    hideOnScroll: true,
    placement: {
      anchor: 'LEFT_CENTER',
      possibleAnchors: ['LEFT_CENTER'],
      triggerOffset: 0,
      ...tooltipPlacement,
    },
  });

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <>
        <div
          ref={layerProps.ref}
          style={{ ...layerProps.style }}
          {...triggerProps}
          className="layer insight-dropdown"
          onMouseEnter={(): void => setKeepOpen(true)}
          onMouseLeave={(): void => setKeepOpen(false)}
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            backgroundColor="#ffffff"
            borderWidth={1}
            borderColor="#dbdce1"
            roundness={0.7}
          />
          {status !== Status.Error && (
            <>
              <DebounceInput
                minLength={2}
                disabled={defaultCurrenciesEmpty}
                debounceTimeout={1000}
                onChange={(event): Promise<void> =>
                  getDefaultCurrencyData(event.target.value)
                }
                className="form-control mb-3"
                placeholder={intl.get('LBL_SEARCH')}
                type="search"
              />
              <div {...triggerProps} ref={ref}>
                <BlockUi blocking={status === Status.Loading}>
                  <PerfectScrollBar className={styles.scroll}>
                    <Form>
                      {defaultCurrencyData.length > 0 ||
                      status === Status.Loading ? (
                        defaultCurrencyData.map((currency) => (
                          <FormGroup
                            key={currency.currencyCode}
                            className="insight-radio-group"
                          >
                            <Label
                              className={`cursor-pointer ${styles.option} ${
                                currency.currencyCode === currentDefaultCurrency
                                  ? 'checked-label'
                                  : ''
                              }`}
                            >
                              <span
                                className={`insight-radio mr-3 ${styles.radio}`}
                              >
                                <Input
                                  type="radio"
                                  name={currency.name}
                                  value={currency.currencyCode}
                                  disabled={!currency.currencyCode}
                                  checked={
                                    currency.currencyCode ===
                                    currentDefaultCurrency
                                  }
                                  onChange={handleUpdateDefaultCurrency}
                                />
                                <i className="icon-radio" />
                              </span>
                              <EllipsisTooltip
                                tag="span"
                                data-place="right"
                                data-for="insTooltip"
                                data-tip={
                                  currency.currencyCode
                                    ? `${String(currency.name)} (${String(
                                        currency.currencyCode
                                      )})`
                                    : ''
                                }
                                data-class="overflow-wrap"
                                className={`truncate ${
                                  currency.currencyCode ? '' : styles.dummy
                                }`}
                              >
                                {`${String(currency.name)} ${
                                  currency.currencyCode
                                    ? `(${String(currency.currencyCode)})`
                                    : ''
                                }`}
                              </EllipsisTooltip>
                            </Label>
                          </FormGroup>
                        ))
                      ) : (
                        <FormGroup className="insight-radio-group">
                          <Label className="dummy truncate text-14-medium">
                            {intl.get('LBL_NO_OPTIONS')}
                          </Label>
                        </FormGroup>
                      )}
                    </Form>
                  </PerfectScrollBar>
                </BlockUi>
              </div>
            </>
          )}
          {(error || status === Status.Error) && (
            <Alert
              isOpen={status === Status.Error}
              tag="div"
              fade
              className="alert alert-danger mt-3"
              role="alert"
            >
              {error || intl.get('OOPS_SOMETHING_WENT_WRONG')}
            </Alert>
          )}
        </div>
        {hideBackdrop ? null : <div className="backdrop" />}
      </>
    );

  return (
    <>
      {tooltipElement}
      <ToggleLayer
        isOpen={isMenuOpen}
        closeOnOutsideClick
        onOutsideClick={onClose}
        placement={{
          anchor: 'TOP_CENTER',
          possibleAnchors: ['TOP_CENTER'],
          triggerOffset: 30,
          ...placement,
        }}
        renderLayer={renderLayer}
      >
        {children}
      </ToggleLayer>
    </>
  );
};

export default DefaultCurrencyMenu;
