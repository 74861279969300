/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import PaginatedListResponse from 'api/common/responses/PaginatedListResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ListFilters from 'api/common/types/ListFilters';
import Country from 'api/messaging/types/group-filters/Country';
import Facilitator from 'api/messaging/types/group-filters/Facilitator';
import GroupFilters, {
  MessagingGroup,
} from 'api/messaging/types/group-filters/GroupFilters';
import GroupFiltersRequest from 'api/messaging/types/group-filters/GroupFiltersRequest';
import MessageDetails from 'api/messaging/types/message/MessageDetails';
import MessageListItem from 'api/messaging/types/message/MessageListItem';
import MessageProcessedStatus from 'api/messaging/types/message/MessageProcessedStatus';
import { PostMessageRequestBody } from 'api/messaging/types/message/PostMessageRequestBody';
import ProjectDataMinimal from 'api/projects/types/ProjectDataMinimal';
import { MessageApiTags } from 'constants/request-tags/RequestTags';

class MessagingApi extends ApiBase {
  /**
   * Compose message
   */
  async CreateMessage(
    payload: PostMessageRequestBody,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = 'messages';
    const postMessageResponse = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body: payload,
      tag: MessageApiTags.CreateMessage,
      cancelSource: cancelToken,
    });
    return postMessageResponse;
  }

  /**
   * Get a list of messages
   *
   * @param listFilters Applied list filters
   */
  async GetMessageListData(
    listFilters: ListFilters,
    cancelToken: CancelTokenSource
  ): Promise<PaginatedListResponse<MessageListItem>> {
    const { page, pageSize, sortBy, sort, search } = listFilters;

    let listFilterQuery: Record<string, any> = { page, pageSize, sortBy, sort };

    if (search && search.trim().length > 0) {
      listFilterQuery = { ...listFilterQuery, search };
    }

    const path = 'messages';

    const messageListResponse = this.GetAsync<
      Promise<PaginatedListResponse<MessageListItem>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetMessageListData,
      cancelSource: cancelToken,
      queryParams: listFilterQuery,
    });

    return messageListResponse;
  }

  /**
   * Get single message
   *
   * @param id Message ID
   */
  async GetMessage(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<MessageDetails>> {
    const path = `messages/details/${id}`;
    const messageDetailsResponse = this.GetAsync<
      ObjectResponse<MessageDetails>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetMessage,
      cancelSource: cancelToken,
    });
    return messageDetailsResponse;
  }

  /**
   * Get countries to populate filters
   */
  async GetCountries(
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Country>> {
    const path = `groups/countries`;
    const countriesResponse = this.GetAsync<Promise<ListResponse<Country>>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetCountries,
      cancelSource: cancelToken,
    });
    return countriesResponse;
  }

  /**
   * Get projects to populate projects filter
   *
   * @param countryCodes Country codes to filter by
   */
  async GetProjectsForCountry(
    countryCodes: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectDataMinimal>> {
    const queryParams = { countryCode: countryCodes };

    const path = 'projects/all-projects';
    const projectsResponse = this.GetAsync<
      Promise<ListResponse<ProjectDataMinimal>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetProjectsForCountry,
      cancelSource: cancelToken,
      queryParams,
    });
    return projectsResponse;
  }

  /**
   * Get facilitator to populate filters
   *
   * @param projectIds Project Ids to filter by
   */
  async GetFacilitatorsForProjects(
    projectIds: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Facilitator>> {
    const queryParams = { projectId: projectIds };
    const path = 'organizations/facilitators/lookup';

    const facilitatorsResponse = this.GetAsync<
      Promise<ListResponse<Facilitator>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetFacilitatorsForProjects,
      cancelSource: cancelToken,
      queryParams,
    });
    return facilitatorsResponse;
  }

  /**
   * Post group filters
   *
   * @param groupFiltersRequest Selected group filters
   */
  async PostGroupFilters(
    groupFilters: GroupFiltersRequest,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<GroupFilters>> {
    const path = 'messages/filtered-groups';
    const body = { ...groupFilters };
    const response = this.PostAsync<ObjectResponse<GroupFilters>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: MessageApiTags.PostGroupFilters,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Get message details as excel
   *
   * @param id Message ID
   */
  async GetMessagingExcel(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const action = `messages/${id}/export`;

    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const excelData = this.GetAsyncBlob({
      action,
      tag: MessageApiTags.GetMessagingExcel,
      cancelSource: cancelToken,
      queryParams: { timezone: clientTimezone },
    });

    return excelData;
  }

  /**
   * Get message processed status
   *
   * @param id Message ID
   */
  async GetMessageProcessedStatus(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<MessageProcessedStatus>> {
    const path = `messages/${id}/processed-status`;
    const response = this.GetAsync<ObjectResponse<MessageProcessedStatus>>({
      action: path,
      tag: MessageApiTags.GetMessageProcessedStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Check group message send history
   *
   * @param groups Selected groups
   */
  async CheckGroupStatus(
    groups: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<MessagingGroup>> {
    const path = 'messages/check-groups-status';
    const body = { groups };
    const response = this.PostAsync<Promise<ListResponse<MessagingGroup>>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: MessageApiTags.CheckGroupStatus,
      cancelSource: cancelToken,
    });

    return response;
  }
}

const MessagingApiInstance = new MessagingApi();
export default MessagingApiInstance;
