/* eslint-disable react/jsx-props-no-spreading */
import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
  FC as ReactFC,
} from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer, useTooltip } from 'react-laag';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ImageComponent from 'shared/components/image/ImageComponent';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import styles from './assignFacilitatorMenu.module.scss';
import AssignFacilitatorMenuProps from './AssignFacilitatorMenuProps';
import DummyFacilitator from './DummyFacilitator';

const getDummyFacilitators = (): DummyFacilitator[] => {
  const dummies: DummyFacilitator[] = [];
  for (let index = 0; index < 4; index += 1) {
    const dummy = new DummyFacilitator(
      index.toString(),
      intl.get('LBL_GD_FACILITATOR')
    );
    dummies.push(dummy);
  }
  return dummies;
};

const AssignFacilitatorMenu: ReactFC<AssignFacilitatorMenuProps> = (
  props: AssignFacilitatorMenuProps
) => {
  const {
    isFacilitatorsOpen,
    facilitators,
    facilitatorsStatus,
    assignFacilitatorsStatus,
    assignFacilitatorsError,
    facilitatorsError,
    hideBackdrop,
    children,
    onCloseFacilitators,
    fetchFacilitators,
    onAssignGroupsFacilitators,
    setAssignFacilitatorsStatus,
  } = props;

  const {
    placement = {},
    tooltipPlacement = {},
    currentFacilitator = null,
  } = props;

  const [keepOpen, setKeepOpen] = useState(false);
  const [selectedFacilitator, setSelectedFacilitator] = useState<string | null>(
    currentFacilitator
  );

  useEffect(() => {
    const handleScroll = (): void => {
      if (keepOpen) {
        setKeepOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return (): void => window.removeEventListener('scroll', handleScroll);
  }, [keepOpen]);

  useEffect(() => {
    if (isFacilitatorsOpen === true) {
      fetchFacilitators();
    } else {
      setKeepOpen(false);
      setAssignFacilitatorsStatus(Status.Idle);
    }
    setSelectedFacilitator(currentFacilitator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFacilitatorsOpen]);

  const facilitatorModded =
    facilitatorsStatus !== Status.Loading && facilitators.length <= 0
      ? getDummyFacilitators()
      : facilitators;

  /**
   * Update local selectedProjects from user changes
   *
   * @param event ChangeEvent input
   */
  const handleUpdateFacilitator = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedFacilitator(event.target.value);
    onAssignGroupsFacilitators(event.target.value);
  };

  /**
   * Render function for tooltip when facilitators available
   *
   * @param renderLayerProps
   */
  const renderTooltip = ({
    isOpen,
    layerProps,
  }: RenderLayerProps): ReactNode => {
    const isTooltipOpen =
      facilitatorsStatus === Status.Success &&
      facilitators.length <= 0 &&
      (isOpen || keepOpen);

    return (
      isTooltipOpen && (
        <div
          {...layerProps}
          style={{ ...layerProps.style, zIndex: 99999, paddingRight: 28 }}
          onMouseEnter={(): void => setKeepOpen(true)}
          onMouseLeave={(): void => setKeepOpen(false)}
        >
          <div className={styles.tooltip}>
            {intl.get('ERR_GROUPS_NO_FACILITATORS')}
            <InsLink
              disabled={false}
              disabledClass="disabled"
              className="border-0 text-orange p-0 shadow-none bg-transparent"
              style={{ textDecoration: 'underline' }}
              to={ModulePaths.ProjectsPath}
            >
              {intl.get('BTN_HERE')}
            </InsLink>
            .
          </div>
        </div>
      )
    );
  };

  const [tooltipElement, { ref, ...triggerProps }] = useTooltip(renderTooltip, {
    delayEnter: 100,
    delayLeave: 100,
    hideOnScroll: true,
    placement: {
      anchor: 'LEFT_CENTER',
      possibleAnchors: ['LEFT_CENTER'],
      triggerOffset: 0,
      ...tooltipPlacement,
    },
  });

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <>
        <div
          ref={layerProps.ref}
          style={{ ...layerProps.style }}
          {...triggerProps}
          className="layer insight-dropdown"
          onMouseEnter={(): void => setKeepOpen(true)}
          onMouseLeave={(): void => setKeepOpen(false)}
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            backgroundColor="#ffffff"
            borderWidth={1}
            borderColor="#dbdce1"
            roundness={0.7}
          />
          {facilitatorsStatus !== Status.Error && (
            <div {...triggerProps} ref={ref}>
              <BlockUi
                blocking={
                  facilitatorsStatus === Status.Loading ||
                  assignFacilitatorsStatus === Status.Loading
                }
              >
                <PerfectScrollbar className={styles.scroll}>
                  <Form>
                    {facilitatorModded.map((facilitator) => (
                      <FormGroup
                        key={facilitator.id}
                        className="insight-radio-group"
                      >
                        <Label
                          className={`${styles.option} ${
                            selectedFacilitator === facilitator.id
                              ? 'checked-label'
                              : ''
                          }`}
                        >
                          <span
                            className={`insight-radio mr-3 ${styles.radio}`}
                          >
                            <Input
                              type="radio"
                              name={facilitator.name}
                              value={facilitator.id}
                              checked={selectedFacilitator === facilitator.id}
                              disabled={facilitator.disabled}
                              onChange={handleUpdateFacilitator}
                            />
                            <i className="icon-radio" />
                          </span>
                          <ImageComponent
                            className="rounded-circle"
                            src={facilitator.image}
                            alt={facilitator.name}
                            fallbackSrc={blankAvatar}
                            width="25"
                            height="25"
                          />
                          <EllipsisTooltip
                            tag="span"
                            data-place="right"
                            data-for="insTooltip"
                            data-tip={
                              facilitator.disabled ? '' : facilitator.name
                            }
                            data-class="overflow-wrap"
                            className={`truncate ${styles.name} ${
                              facilitator.disabled ? styles.dummy : ''
                            }`}
                          >
                            {facilitator.name}
                          </EllipsisTooltip>
                        </Label>
                      </FormGroup>
                    ))}
                  </Form>
                </PerfectScrollbar>
              </BlockUi>
            </div>
          )}
          {(assignFacilitatorsError || facilitatorsError) && (
            <Alert
              isOpen={
                assignFacilitatorsStatus === Status.Error ||
                facilitatorsStatus === Status.Error
              }
              tag="div"
              fade
              className="alert alert-danger mt-2"
              role="alert"
            >
              {assignFacilitatorsError || facilitatorsError}
            </Alert>
          )}
        </div>
        {hideBackdrop ? null : <div className="backdrop" />}
      </>
    );

  return (
    <>
      {tooltipElement}
      <ToggleLayer
        isOpen={isFacilitatorsOpen}
        closeOnOutsideClick
        onOutsideClick={onCloseFacilitators}
        placement={{
          anchor: 'TOP_CENTER',
          possibleAnchors: ['TOP_CENTER'],
          triggerOffset: 30,
          ...placement,
        }}
        renderLayer={renderLayer}
      >
        {children}
      </ToggleLayer>
    </>
  );
};

export default AssignFacilitatorMenu;
