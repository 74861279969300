/* eslint-disable react/jsx-props-no-spreading */
import {
  CSSProperties,
  useCallback,
  useMemo,
  useState,
  FC as ReactFC,
} from 'react';

import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import Select from 'react-select';

import styles from '../phoneNumberField.module.scss';
import CountrySelectDropdown from './country-select-dropdown/CountrySelectDropdown';
import PhoneNumberCountrySelectProps from './PhoneNumberCountrySelectProps';

const selectStyles = {
  control: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: (): CSSProperties => ({
    boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
  }),
};

function getSelectedOption(
  options,
  value
): PhoneNumberCountrySelectProps['options'][0] {
  return options.find((option) => !option.divider && option.value === value);
}

const PhoneNumberCountrySelect: ReactFC<PhoneNumberCountrySelectProps> = (
  props: PhoneNumberCountrySelectProps
) => {
  const {
    value,
    options,
    onChange,
    onBlur,
    onFocus,
    iconComponent: Icon,
    unicodeFlags,
  } = props;

  const selectedOption = useMemo(
    () => getSelectedOption(options, value),
    [options, value]
  );

  const [isOpen, setOpen] = useState(false);

  /**
   * Handle field change event
   */
  const handleChange = useCallback(
    ({ value: current }) => {
      onChange(current === 'ZZ' ? undefined : current);
      setOpen((open) => !open);
    },
    [onChange]
  );

  const option = options.find((item) => {
    if (value) {
      return item.value === value;
    }
    return item.value === 'ZZ';
  });

  /**
   * Toggle select dropdown
   */
  const toggleOpen = (): void => setOpen((open) => !open);

  return (
    <div className={styles.countryInput}>
      <CountrySelectDropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <button
            type="button"
            className={`${styles.trigger} ${isOpen ? styles.open : ''}`}
            onClick={toggleOpen}
          >
            {unicodeFlags && value && (
              <div className="PhoneInputCountryIconUnicode">
                {getUnicodeFlagIcon(value)}
              </div>
            )}
            {/* Or an SVG flag icon. */}
            {!(unicodeFlags && value) && (
              <Icon
                country={value}
                label={selectedOption && selectedOption.label}
                aspectRatio={unicodeFlags ? 1 : undefined}
              />
            )}
            <span>
              <svg
                className={styles.arrow}
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
              </svg>
            </span>
          </button>
        }
      >
        <Select
          onBlur={onBlur}
          onFocus={onFocus}
          options={options}
          value={option}
          onChange={handleChange}
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          autoFocus
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          placeholder="Search..."
          styles={selectStyles}
          classNamePrefix="insight-select"
          tabSelectsValue={false}
        />
      </CountrySelectDropdown>
    </div>
  );
};

export default PhoneNumberCountrySelect;
