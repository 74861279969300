/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import PaginatedListResponse from 'api/common/responses/PaginatedListResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ApiError from 'api/common/types/ApiError';
import ListFilters from 'api/common/types/ListFilters';
import FacilitatorImageUploadUrl, {
  RequiredHeaders,
} from 'api/projects/types/FacilitatorImageUploadUrl';
import ProjectCode from 'api/projects/types/ProjectCode';
import ProjectCompatibility from 'api/projects/types/ProjectCompatibility';
import ProjectCreated from 'api/projects/types/ProjectCreated';
import ProjectDataMinimal from 'api/projects/types/ProjectDataMinimal';
import ProjectDetail from 'api/projects/types/ProjectDetail';
import ProjectFacilitator from 'api/projects/types/ProjectFacilitator';
import ProjectItem from 'api/projects/types/ProjectItem';
import ProjectOrgCountry from 'api/projects/types/ProjectOrgCountry';
import ProjectsFileItem from 'api/projects/types/ProjectsFileItem';
import ProjectsPagination from 'api/projects/types/ProjectsPagination';
import ProjectStats from 'api/projects/types/ProjectStats';
import ProjectUser from 'api/projects/types/ProjectUser';
import { ProjectsApiTags } from 'constants/request-tags/RequestTags';

class ProjectsApi extends ApiBase {
  async GetNewProjectId(
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<ProjectCode>> {
    const path = 'projects/project-code';
    const projectId = this.GetAsync<ObjectResponse<ProjectCode>>({
      action: path,
      tag: ProjectsApiTags.GetNewProjectId,
      cancelSource: cancelToken,
    });

    return projectId;
  }

  async GetProjectsListData(
    listFilters: Pick<ProjectsPagination, 'page' | 'pageSize'>,
    cancelToken: CancelTokenSource,
    userId?: string
  ): Promise<PaginatedListResponse<ProjectItem>> {
    const { page, pageSize } = listFilters;

    let listFilterQuery: any = { page, pageSize };

    if (userId) {
      listFilterQuery = { ...listFilterQuery, userId };
    }

    const path = 'projects';
    const listData = this.GetAsync<PaginatedListResponse<ProjectItem>>({
      action: path,
      tag: ProjectsApiTags.GetProjectsListData,
      cancelSource: cancelToken,
      queryParams: listFilterQuery,
    });
    return listData;
  }

  async GetProject(
    projectId: string,
    cancelToken?: CancelTokenSource
  ): Promise<ObjectResponse<ProjectItem>> {
    const path = `projects/${projectId}/details`;
    const action = `${path}`;
    const data = this.GetAsync<ObjectResponse<ProjectItem>>({
      action,
      tag: ProjectsApiTags.GetProject,
      cancelSource: cancelToken,
    });
    return data;
  }

  async CreateProject(
    details: Partial<ProjectDetail>,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<ProjectCreated>> {
    const path = 'projects';
    const result = this.PostAsync<ObjectResponse<ProjectCreated>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body: details,
      tag: ProjectsApiTags.CreateProject,
      cancelSource: cancelToken,
    });
    return result;
  }

  async UpdateProject(
    details: Partial<ProjectDetail>,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = 'projects';
    const result = this.PutAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body: details,
      tag: ProjectsApiTags.UpdateProject,
      cancelSource: cancelToken,
    });
    return result;
  }

  async DeleteProject(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `projects/${projectId}`;

    const result = this.DeleteAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: ProjectsApiTags.DeleteProject,
      cancelSource: cancelToken,
    });
    return result;
  }

  async GetProjectUsers(
    projectId: string,
    cancelToken: CancelTokenSource,
    listFilters?: Omit<ListFilters, 'search'>
  ): Promise<PaginatedListResponse<ProjectUser>> {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const path = `projects/${projectId}/users`;

    let queryParams: Record<string, any> = { timezone: clientTimezone };

    if (listFilters) {
      const { sortBy, sort, page, pageSize } = listFilters;
      queryParams = { ...queryParams, sort, sortBy, page, pageSize };
    }

    const action = `${path}`;

    const data = this.GetAsync<PaginatedListResponse<ProjectUser>>({
      action,
      tag: ProjectsApiTags.GetProjectUsers,
      cancelSource: cancelToken,
      queryParams,
    });
    return data;
  }

  async GetProjectFacilitators(
    projectId: string,
    cancelToken: CancelTokenSource,
    listFilters?: Omit<ListFilters, 'search'>
  ): Promise<PaginatedListResponse<ProjectFacilitator>> {
    let queryParams: Record<string, any> = {};

    if (listFilters) {
      const { sortBy, sort, page, pageSize } = listFilters;
      queryParams = { ...queryParams, sort, sortBy, page, pageSize };
    }
    const path = `projects/${projectId}/facilitators`;
    const action = `${path}`;
    const data = this.GetAsync<PaginatedListResponse<ProjectFacilitator>>({
      action,
      tag: ProjectsApiTags.GetProjectFacilitators,
      cancelSource: cancelToken,
      queryParams,
    });
    return data;
  }

  async GetAllProjects(
    text?: string
  ): Promise<ListResponse<ProjectDataMinimal>> {
    const search = text && text.trim().length > 0 ? text : '';
    const path = 'projects/all-projects';
    let queryParams: Record<string, any> = {};

    if (text && text.trim().length > 0) {
      queryParams = { search };
    }

    const allProjectData = this.GetAsync<ListResponse<ProjectDataMinimal>>({
      action: path,
      tag: ProjectsApiTags.GetAllProjects,
      queryParams,
    });
    return allProjectData;
  }

  async GetAllProjectUsers(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectUser>> {
    const path = `projects/${projectId}/all-users`;
    const allProjectUsersData = this.GetAsync<ListResponse<ProjectUser>>({
      action: path,
      tag: ProjectsApiTags.GetAllProjectUsers,
      cancelSource: cancelToken,
    });
    return allProjectUsersData;
  }

  async AddProjectUsers(
    projectId: string,
    users: string[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `projects/${projectId}/users`;
    const action = `${path}`;
    const body = {
      users,
    };

    const result = this.PostAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.AddProjectUsers,
      cancelSource: cancelToken,
    });

    return result;
  }

  async AddProjectFacilitators(
    projectId: string,
    facilitators: string[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `projects/${projectId}/facilitators`;
    const action = `${path}`;
    const body = {
      facilitators,
    };

    const result = this.PostAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.AddProjectFacilitators,
      cancelSource: cancelToken,
    });

    return result;
  }

  async GetUserProjectStats(
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<ProjectStats>> {
    const path = 'user/project-stats';
    const data = this.GetAsync<ObjectResponse<ProjectStats>>({
      action: path,
      tag: ProjectsApiTags.GetUserProjectStats,
      cancelSource: cancelToken,
    });
    return data;
  }

  async GetProjectOrgCountries(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectOrgCountry>> {
    const path = `projects/${id}/countries`;
    const projectOrgCountries = this.GetAsync<ListResponse<ProjectOrgCountry>>({
      action: path,
      tag: ProjectsApiTags.GetProjectOrgCountries,
      cancelSource: cancelToken,
    });
    return projectOrgCountries;
  }

  async GetUserProjectCompatibility(
    userId: string,
    projectIds: string[],
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<ProjectCompatibility>> {
    const path = `user/${userId}/project-compatibility`;
    const body = {
      projectIds,
    };
    const result = this.PostAsync<ObjectResponse<ProjectCompatibility>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.GetUserProjectCompatibility,
      cancelSource: cancelToken,
    });
    return result;
  }

  async GetFacilitatorImageUploadUrl(
    body: Array<ProjectsFileItem>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<FacilitatorImageUploadUrl>> {
    const path = `organizations/facilitators/profile-image/presigned`;

    const uploadUrlResponse = this.PostAsync<
      ListResponse<FacilitatorImageUploadUrl>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.GetFacilitatorImageUploadUrl,
      cancelSource: cancelToken,
    });

    return uploadUrlResponse;
  }

  async AssignGroupsToProject(
    projectId: string,
    groups: string[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `projects/${projectId}/groups`;
    const body = { groups };

    const response = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.AssignGroupsToProject,
      cancelSource: cancelToken,
    });

    return response;
  }

  // eslint-disable-next-line class-methods-use-this
  async UploadFacilitatorImage(
    url: string,
    requiredHeaders: RequiredHeaders,
    file: Blob
  ): Promise<Response> {
    const headers = {
      ...requiredHeaders,
      'Content-Type': 'application/octet-stream',
    };

    const uploadUrlResponse = await fetch(url, {
      method: 'PUT',
      headers,
      body: file,
    });

    const { ok, status, statusText } = uploadUrlResponse;
    return new Promise((resolve, reject) => {
      if (ok) {
        resolve(uploadUrlResponse);
      } else {
        const error = new ApiError(
          `Request failed with status ${status}`,
          status,
          statusText
        );
        reject(error);
      }
    });
  }

  async GetProjectUsersExcel(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const queryParams = { timezone: clientTimezone };

    const path = `projects/${projectId}/users/export`;

    const action = `${path}`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: ProjectsApiTags.GetProjectUsersExcel,
      cancelSource: cancelToken,
      queryParams,
    });

    return excelData;
  }

  async GetProjectFacilitatorsExcel(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = `projects/${projectId}/facilitators/export`;

    const excelData = this.GetAsyncBlob({
      action: path,
      tag: ProjectsApiTags.GetProjectFacilitatorsExcel,
      cancelSource: cancelToken,
    });

    return excelData;
  }

  async RemoveProjectUsers(
    projectId: string,
    users: string[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `projects/${projectId}/users`;
    const action = `${path}`;
    const body = {
      users,
    };
    const result = this.DeleteAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.RemoveProjectUsers,
      cancelSource: cancelToken,
    });
    return result;
  }

  async RemoveProjectFacilitators(
    projectId: string,
    facilitators: string[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `projects/${projectId}/facilitators`;
    const action = `${path}`;
    const body = {
      facilitators,
    };
    const result = this.DeleteAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.RemoveProjectFacilitators,
      cancelSource: cancelToken,
    });
    return result;
  }

  async RemoveUserFromProject(
    projectId: string,
    userId: string,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = `projects/${projectId}/user/${userId}`;
    const result = this.DeleteAsync<SuccessResponse>({
      action,
      tag: ProjectsApiTags.RemoveUserFromProject,
      cancelSource: cancelToken,
    });
    return result;
  }
}

const ProjectsApiInstance = new ProjectsApi();

export default ProjectsApiInstance;
