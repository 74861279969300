enum TooltipOptionAnchor {
  TopLeft = 'TOP_LEFT',
  TopCenter = 'TOP_CENTER',
  TopRight = 'TOP_RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  BottomCenter = 'BOTTOM_CENTER',
  BottomRight = 'BOTTOM_RIGHT',
  RightTop = 'RIGHT_TOP',
  RightCenter = 'RIGHT_CENTER',
  RightBottom = 'RIGHT_BOTTOM',
  LeftTop = 'LEFT_TOP',
  LeftCenter = 'LEFT_CENTER',
  LeftBottom = 'LEFT_BOTTOM',
}

export default TooltipOptionAnchor;
