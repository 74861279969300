/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, FC as ReactFC } from 'react';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import styles from '../collectIdsHistoryDataTable.module.scss';
import CollectIdsHistoryRowProps from './CollectIdsHistoryRowProps';

const CollectIdsHistoryRow: ReactFC<CollectIdsHistoryRowProps> = (
  props: CollectIdsHistoryRowProps
) => {
  const { prepareRow, row } = props;

  prepareRow(row);
  const className = row.isSelected ? 'row-selected' : '';

  return (
    <Fragment key={row.id}>
      <tr {...row.getRowProps()} className={className}>
        {row.cells.map((cell, index: number) => (
          <Fragment key={index}>
            <td
              {...cell.getCellProps({
                className: cell.column.className,
                'data-title': cell.column.dataTitle,
              })}
            >
              <EllipsisTooltip
                tag="span"
                data-place="bottom"
                data-for="insTooltip"
                data-tip={cell.value}
                data-class="overflow-wrap"
                className={`table-content  ${styles.wrapContent} ${
                  cell.column.id === 'name'
                    ? 'text-14-bold'
                    : 'text-14-semibold'
                }`}
              >
                {cell.render('Cell')}
              </EllipsisTooltip>
            </td>
          </Fragment>
        ))}
      </tr>
    </Fragment>
  );
};

export default CollectIdsHistoryRow;
