class CreateProfileViewModel {
  firstName: string;

  lastName: string;

  phoneNumber: string;

  password: string;

  image: string | null;

  email: string;

  code: string;

  rememberMe: boolean;

  /**
   *
   */
  constructor(
    email: string,
    code: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    password: string,
    rememberMe: boolean,
    image?: string
  ) {
    this.email = email;
    this.code = code;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.password = password;
    this.rememberMe = rememberMe;
    this.image = image ?? null;
  }
}

export default CreateProfileViewModel;
