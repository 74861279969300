/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import { Button, Col, Row } from 'reactstrap';
import { ObjectSchema } from 'yup';

import ApiError from 'api/common/types/ApiError';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import mergeRefs from 'helpers/mergeRefs';
import PermissionUtil from 'helpers/PermissionUtil';
import EventKey from 'shared/enums/EventKey';
import HttpStatus from 'shared/enums/HttpStatus';
import { EventBus } from 'shared/events/EventBus';
import InviteUsersFieldArray from 'shared/modules/users/components/invite-users-field-array/InviteUsersFieldArray';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import styles from './inviteProjectUsers.module.scss';
import InviteProjectUsersErrorStatus from './InviteProjectUsersErrorStatus';
import InviteProjectUsersProps from './InviteProjectUsersProps';
import InviteProjectUsersValidations from './InviteProjectUsersValidations';
import InviteProjectUsersViewModel from './InviteProjectUsersViewModel';
import ProjectUsersFormValue, {
  InviteProjectUsersFormValues,
} from './ProjectUsersFormValue';

const getInitialValues = (): InviteProjectUsersFormValues => ({
  users: [new ProjectUsersFormValue()],
});

const InviteProjectUsers: ReactFC<InviteProjectUsersProps> = (
  props: InviteProjectUsersProps
) => {
  const {
    innerFormikRef,
    isOpen,
    projectId,
    jobRoles,
    jobRolesStatus,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    countries,
    countriesStatus,
    permissionLevels,
    permissionLevelsStatus,
    createJobRoleStatus,
    createJobRoleError,
    appContext,
    onToggle,
    onCreateUsers,
    onFetchSupervisors,
    onFetchPermissionLevels,
    onCreateJobRole,
    onAddUsersSuccess,
    clearJobRoleError,
  } = props;

  const formikRef = useRef<FormikProps<InviteProjectUsersFormValues> | null>(
    null
  );

  const [initialValues, setInitValues] =
    useState<InviteProjectUsersFormValues>(getInitialValues);

  const defaultCountries = countries
    .filter((country) => country.isFixed)
    .map((country) => country.value);

  const { permissionsData, gettingStartedStates } = appContext;
  const { claims } = permissionsData;

  const canInviteUsers = PermissionUtil.Can(
    claims,
    ResourceKeys.SettingsUsersInviteUsers
  );

  const canAddPermissions = PermissionUtil.Can(
    claims,
    ResourceKeys.SettingsPermissionsAddNewLevel
  );

  const canEditPermissions = PermissionUtil.Can(
    claims,
    ResourceKeys.SettingsPermissionsItemEdit
  );

  const permissionsFirstTime = gettingStartedStates.data
    ? !gettingStartedStates.data.permissionCompleted
    : false;

  useEffect(() => {
    setInitValues({
      users: [new ProjectUsersFormValue(defaultCountries)],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultCountries)]);

  /**
   * cleanup to reset form on unmount, this effect
   * will run on tab change; thereby run on isOpen:false
   * since it will reset tab
   */
  useEffect(
    () =>
      function cleanup(): void {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
      },
    []
  );

  useEffect(() => {
    if (isOpen === false && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [isOpen]);

  /**
   * Handles form submission to invite (a) new user/s
   *
   * @param values Values entered into the invite users form
   * @param helpers Formik helpers for the invite users form
   */
  const handleSubmit = async (
    values: InviteProjectUsersFormValues,
    helpers: FormikHelpers<InviteProjectUsersFormValues>
  ): Promise<void> => {
    const formatted = values.users.map(
      (user) =>
        new InviteProjectUsersViewModel(
          user.id,
          user.email,
          user.jobRole,
          user.isImplementingPartner,
          user.permission,
          user.countryDataAccess,
          user.supervisorId,
          projectId
        )
    );
    try {
      await onCreateUsers(formatted);
      helpers.setSubmitting(false);

      const newInitialValues: InviteProjectUsersFormValues = getInitialValues();
      helpers.resetForm({ values: newInitialValues });

      appContext.hideErrorToast();
      onAddUsersSuccess(false, true);
      onToggle();
    } catch (error) {
      if (error instanceof ApiError) {
        if (!isEmpty(error.response)) {
          helpers.setStatus({
            errorCode: error.response?.errorCode,
            errorMessage: error.response?.errorMessage,
            errors: error.response?.errors,
          } as InviteProjectUsersErrorStatus);
        }

        if (error.status === undefined) {
          appContext.setErrorToastText(
            intl.get('ERR_PROJECT_USERS_INVITE_FAILURE')
          );
        } else if (error.status !== HttpStatus.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_INVITE_USER_FORM_ERRORS',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
          if (error.status === HttpStatus.NOT_FOUND) {
            /* If the project has been deleted concurrently, redirect to 
            the projects page and re-load the projects list */
            onAddUsersSuccess(false, true);
            onToggle();
          }
        }
      }
      helpers.setSubmitting(false);
    }
  };

  /**
   * handles addition of new form item
   */
  const handleAddNewItem = (): ProjectUsersFormValue =>
    new ProjectUsersFormValue(defaultCountries);

  const validationSchema =
    InviteProjectUsersValidations.GetValidationSchema() as ObjectSchema;

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      innerRef={mergeRefs([innerFormikRef, formikRef])}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors }): JSX.Element => (
        <Form noValidate>
          <div className={styles.wrapper}>
            <FieldArray
              name="users"
              render={(fieldArrayProps): JSX.Element => (
                <InviteUsersFieldArray
                  jobRoles={jobRoles}
                  jobRolesStatus={jobRolesStatus}
                  supervisors={supervisors}
                  supervisorsFiltered={supervisorsFiltered}
                  supervisorsStatus={supervisorsStatus}
                  onFetchSupervisors={onFetchSupervisors}
                  countries={countries}
                  countriesStatus={countriesStatus}
                  permissionLevels={permissionLevels}
                  permissionLevelsStatus={permissionLevelsStatus}
                  canInviteUsers={canInviteUsers}
                  canAddPermissions={canAddPermissions}
                  canEditPermissions={canEditPermissions}
                  permissionsFirstTime={permissionsFirstTime}
                  onCreateJobRole={onCreateJobRole}
                  createJobRoleStatus={createJobRoleStatus}
                  createJobRoleError={createJobRoleError}
                  onSubmitEnd={appContext.setInviteUsersSubmitting}
                  isSubmitting={isSubmitting}
                  validationSchema={validationSchema}
                  errors={errors}
                  setCommonError={appContext.setErrorToastText}
                  fetchPermissionLevels={onFetchPermissionLevels}
                  getGettingStartedState={appContext.getGettingStartedState}
                  onAddNewItem={handleAddNewItem}
                  clearJobRoleError={clearJobRoleError}
                  {...fieldArrayProps}
                />
              )}
            />
          </div>
          <Row className="mt-3 pt-3">
            <Col xs="auto">
              <Button onClick={onToggle}>{intl.get('BTN_CANCEL')}</Button>
            </Col>
            <Col className="d-flex align-items-center justify-content-center" />
            <Col xs="auto">
              <Button disabled={isSubmitting} color="primary" type="submit">
                {intl.get('BTN_PROJECT_USERS_INVITE_SEND')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default InviteProjectUsers;
