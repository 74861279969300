/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, FC as ReactFC } from 'react';

import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import { Option } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import usePrevious from 'shared/hooks/use-previous/UsePrevious';
import PermissionModal from 'shared/modules/permissions/components/permissions-modal/PermissionModal';

import InviteUsersFormItem from './invite-users-form-item/InviteUsersFormItem';
import styles from './inviteUsersFieldArray.module.scss';
import InviteUsersFieldArrayProps from './InviteUsersFieldArrayProps';
import UsersFormItemValue from './UsersFormItemValue';

const InviteUsersFieldArray: ReactFC<InviteUsersFieldArrayProps> = (
  props: InviteUsersFieldArrayProps
) => {
  const {
    form,
    name,
    canInviteUsers,
    canAddPermissions,
    canEditPermissions,
    permissionsFirstTime,
    jobRoles,
    jobRolesStatus,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    countries,
    countriesStatus,
    permissionLevels,
    permissionLevelsStatus,
    createJobRoleStatus,
    createJobRoleError,
    isSubmitting,
    validationSchema,
    errors,
    push,
    handleRemove,
    onAddNewItem,
    onFetchSupervisors,
    onCreateJobRole,
    onSubmitEnd,
    setCommonError,
    fetchPermissionLevels,
    getGettingStartedState,
    clearJobRoleError,
  } = props;

  const prevSubmitting = usePrevious(isSubmitting);

  const { length } = form.values[name];

  const isAddDisabled = length >= Constraints.MaxFormItemsInviteUsers;

  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isAddDisabled]);

  useEffect(() => {
    if (isSubmitting !== prevSubmitting && isSubmitting === false) {
      onSubmitEnd(false);
      if (!isEmpty(errors)) {
        setCommonError(
          intl.get('ERR_TOAST_INVITE_USER_FORM_VALIDATION_ERRORS')
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, prevSubmitting]);

  /**
   * Set field value customized function to format values to required format
   *
   * @param fieldName name of the @formik field
   * @param isMulti is multi select
   */
  const setFieldValue =
    (fieldName: string, isMulti?: boolean) =>
    (value: Option | Option[]): void => {
      let currentValue: string | string[];
      if (isMulti) {
        const items = value as Option[];
        currentValue = items.map((option) => option.value);
      } else {
        currentValue = (value as Option).value;
      }
      form.setFieldValue(fieldName, currentValue);
    };

  /**
   * Curried function to set @formik field touched
   *
   * @param fieldName name of the @formik field
   */
  const setFieldTouched = (fieldName: string) => (): void =>
    form.setFieldTouched(fieldName);

  /**
   * Adds new form section
   *
   * @param addCallback Callback function to be called after adding a new section
   */
  const handleAddItem = (addCallback) => (): void => {
    const newUser = onAddNewItem();
    addCallback(newUser);
  };

  /**
   * Toggle permissions modal
   */
  const togglePermissionsModal = (): void => {
    setIsPermissionsModalOpen(!isPermissionsModalOpen);
  };
  return (
    <>
      {form.values[name].map((_item: UsersFormItemValue, index: number) => (
        <InviteUsersFormItem
          key={_item.id}
          index={index}
          values={_item}
          length={length}
          status={form.status}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          handleRemove={handleRemove}
          jobRoles={jobRoles}
          jobRolesStatus={jobRolesStatus}
          supervisors={supervisors}
          supervisorsFiltered={supervisorsFiltered}
          supervisorsStatus={supervisorsStatus}
          onFetchSupervisors={onFetchSupervisors}
          countries={countries}
          countriesStatus={countriesStatus}
          permissionLevels={permissionLevels}
          onCreateJobRole={onCreateJobRole}
          createJobRoleStatus={createJobRoleStatus}
          createJobRoleError={createJobRoleError}
          permissionLevelsStatus={permissionLevelsStatus}
          validationSchema={validationSchema}
          clearJobRoleError={clearJobRoleError}
          togglePermissionsModal={togglePermissionsModal}
        />
      ))}
      {canInviteUsers && (
        <span
          className={styles.buttonWrap}
          data-for="insTooltip"
          data-class="error bring-it-up"
          data-tip={
            isAddDisabled
              ? intl.get('ERR_INVITE_USERS_ONLY_X_USERS', {
                  number: Constraints.MaxFormItemsInviteUsers,
                })
              : ''
          }
        >
          <Button
            disabled={isAddDisabled}
            type="button"
            data-tip-disable={false}
            className="btn btn-secondary btn-sm"
            onClick={handleAddItem(push)}
          >
            <i className="icon-plus" />
            {intl.get('BTN_SETTINGS_USERS_INVITE_USERS_ANOTHER')}
          </Button>
        </span>
      )}

      <PermissionModal
        isOpen={isPermissionsModalOpen}
        onToggle={togglePermissionsModal}
        fetchPermissionLevels={fetchPermissionLevels}
        canAddPermissions={canAddPermissions}
        canEditPermissions={canEditPermissions}
        permissionsFirstTime={permissionsFirstTime}
        getGettingStartedState={getGettingStartedState}
      />
    </>
  );
};

export default InviteUsersFieldArray;
