import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Col, Row } from 'reactstrap';

import ImageComponent from 'shared/components/image/ImageComponent';
import workingAtDeskThumb from 'shared/static/img/thumbs/working_at_desk_thumb.png';
import WorkingAtDeskIllustration from 'shared/static/img/working-at-desk.svg';

import styles from './getting-started-header.module.scss';

const GettingStartedHeader: ReactFC<{ userName: string }> = (props: {
  userName: string;
}) => {
  const { userName } = props;
  return (
    <div className={styles.header}>
      <Row>
        <Col>
          <h3>
            {`${intl.get('LBL_GSG_WELCOME')},
            ${userName}! ${intl.get('LBL_GSG_GET_STARTED_WITH_INSIGHT')}`}
          </h3>
          <p className="text-14-medium">
            {intl.get('LBL_GSG_GETTING_STARTED_HEADER_DESCRIPTION')}
          </p>
        </Col>
        <Col xs="auto">
          <ImageComponent
            loading="eager"
            src={WorkingAtDeskIllustration}
            alt="Getting Started"
            thumb={workingAtDeskThumb}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GettingStartedHeader;
