import { Fragment, useState, useEffect, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import ModalTypeKeysGA from 'constants/ga/ModalTypeKeysGA';
import {
  modalViewGA,
  sendEventGA,
  pageViewGA,
} from 'helpers/GoogleAnalyticsHelper';
import Status from 'shared/enums/Status';

import ManageColumnsModalProps from './ManageColumnsModalProps';

const ManageColumnsModal: ReactFC<ManageColumnsModalProps> = (props) => {
  const {
    save,
    toggle,
    show,
    columns,
    onSuccess,
    columnOrder,
    hiddenColumns: hiddenColsInitial,
  } = props;

  const location = useLocation();

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [submitStatus, setSubmitStatus] = useState<Status>(Status.Idle);

  useEffect(() => {
    if (show) setHiddenColumns(hiddenColsInitial);
    else setHiddenColumns([]);
  }, [hiddenColsInitial, show]);

  /**
   * Save and close the manage columns modal
   */
  const saveAndClose = async (): Promise<void> => {
    try {
      setSubmitStatus(Status.Loading);
      const params = columnOrder.map((item, index) => ({
        column: item,
        enabled: !hiddenColumns.includes(item),
        position: index,
      }));

      await save(params);

      const eventLabel = params
        .filter((param) => param.enabled)
        .map((param) => LabelKeysGA[param.column]);

      sendEventGA(
        CategoryKeysGA.GroupsListManageColumns,
        ActionKeysGA.SaveColumnPreference,
        JSON.stringify(eventLabel)
      );
      setSubmitStatus(Status.Success);
      onSuccess(hiddenColumns);
    } catch (error) {
      setSubmitStatus(Status.Error);
    }
  };

  const error =
    columns.length - hiddenColumns.length > 8 ||
    columns.length - hiddenColumns.length < 8
      ? intl.get('ERR_GROUPS_SELECT_SEVEN_COLUMNS')
      : '';

  /**
   * Handles checkbox change event
   * @param event Checkbox change event
   * @param colId Column ID
   */
  const onCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    colId: string
  ): void => {
    const { checked } = event.target;
    setHiddenColumns((state) => {
      let newHiddenCols = [...state];
      if (checked) {
        newHiddenCols = hiddenColumns.filter((col) => col !== colId);
      } else if (!hiddenColumns.includes(colId)) {
        newHiddenCols = [...hiddenColumns, colId];
      }
      return newHiddenCols;
    });
  };

  /**
   * Handles opening the modal
   */
  const handleOpened = (): void => {
    modalViewGA(
      `${location.pathname + location.search}/${ModalTypeKeysGA.ManageColumns}`
    );
  };

  /**
   * Handles closing the modal
   */
  const handleClosed = (): void => {
    pageViewGA(location.pathname);
  };

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      onOpened={handleOpened}
      onClosed={handleClosed}
      centered
    >
      <BlockUi tag="div" blocking={submitStatus === Status.Loading}>
        <ModalHeader>{intl.get('LBL_MANAGE_COLUMNS')}</ModalHeader>
        <ModalBody>
          <div className="insight-checkbox-group no-border">
            <PerfectScrollbar style={{ maxHeight: '450px' }}>
              {columns.map((column, index, array) => {
                const colId =
                  typeof column.accessor === 'string'
                    ? column.accessor
                    : column.id;
                /* group-name column will be always present */
                if (colId === 'name') {
                  return <Fragment key={column.dataTitle} />;
                }
                const checked = !hiddenColumns.includes(colId);
                return (
                  <Fragment key={column.dataTitle}>
                    <Label>
                      <span className="insight-checkbox">
                        <Input
                          type="checkbox"
                          checked={checked}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => onCheckboxChange(e, colId)}
                          name="backup-status"
                          value="NONE"
                        />
                        <i className="icon-check" />
                      </span>
                      {column.dataTitle}
                    </Label>
                    {index < array.length - 1 && <hr />}
                  </Fragment>
                );
              })}
            </PerfectScrollbar>
          </div>
          <div className="form-group" style={{ margin: 0 }}>
            {show && error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            onClick={saveAndClose}
            disabled={!!error || submitStatus === Status.Loading}
          >
            {intl.get('BTN_SAVE_AND_CLOSE')}
          </Button>
        </ModalFooter>
      </BlockUi>
    </Modal>
  );
};

export default ManageColumnsModal;
