/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { components } from 'react-select';
import { MenuListComponentProps } from 'react-select/src/components/Menu';

import mergeRefs from 'helpers/mergeRefs';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';

import styles from './userProfileSuperviseeProjectSelectMenuList.module.scss';

const UserProfileSuperviseeProjectSelectMenuList: ReactFC<
  MenuListComponentProps<SelectFieldOption>
> = (props) => {
  const { children, innerRef, className, selectProps } = props;
  return (
    <components.MenuList
      {...props}
      className={`${String(className)} ${styles.customScroll}`}
      innerRef={mergeRefs([innerRef, selectProps.menuListRef])}
    >
      {children}
    </components.MenuList>
  );
};

export default UserProfileSuperviseeProjectSelectMenuList;
