import { Component } from 'react';

import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import BlockUi from 'react-block-ui';
import intl from 'react-intl-universal';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ErrorCodes from 'constants/ErrorCodes';
import Constraints from 'constants/forms/Constraints';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import {
  ErrorStatus,
  getErrorStatus,
  getLocalizedErrorString,
} from 'helpers/ErrorFormat';
import { getGlobalFilters } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import AuthRouteProps from 'modules/public/PublicRouteProps';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import ModalBackground from 'shared/components/hoc/dummy-background/ModalBackground';
import ImageComponent from 'shared/components/image/ImageComponent';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';
import LoginIllustration from 'shared/static/img/login-illustration.svg';
import loginThumb from 'shared/static/img/thumbs/login_thumb.png';
import { LocationStateFrom } from 'shared/types/locationTypes';

import StorageKeys from '../../../../../constants/StorageKeys';
import styles from './login.module.scss';
import LoginFields from './LoginFields';
import LoginFormValidation from './LoginFormValidation';
import LoginFormValues, { LoginFormValueTypes } from './LoginFormValues';

class Login extends Component<AuthRouteProps, Record<string, unknown>> {
  componentDidMount(): void {
    const {
      appContext: { setErrorToastText },
    } = this.props;
    setPageTitle(intl.get('LBL_LOGIN_PAGE_TITLE'));

    /* Display error messages for errors from a previous expired session */
    const errorCode = AuthStorageService.GetItem<string>(
      StorageKeys.UnauthorizedErrorCode
    );
    if (errorCode) {
      const errorAsNumeric = Number(errorCode);
      if (
        errorAsNumeric === ErrorCodes.UserEmailChangedOrDeactivated ||
        errorAsNumeric === ErrorCodes.UserSessionExpired
      ) {
        setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
        AuthStorageService.RemoveItem(StorageKeys.UnauthorizedErrorCode);
      }
    }
  }

  maxLength = Constraints.MaxFieldLength;

  initialValues: LoginFormValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  /**
   * Handles form submission to log user into the application
   *
   * @param values The values entered into the login form
   * @param formikHelpers Form helpers provided by @formik
   */
  handleLogin = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ): Promise<void> => {
    const { appContext, location } = this.props;
    try {
      await AuthApiInstance.LoginAsync(
        values.email,
        values.password,
        values.rememberMe
      );

      sendEventGA(CategoryKeysGA.Account, ActionKeysGA.Login);

      const { from } = (location.state as LocationStateFrom) || {
        from: { pathname: ModulePaths.DashboardPath },
      };

      const globalFiltersSearch = getGlobalFilters(from.search || '');

      /**
       * Passing global filters from the redirecting URL that is saved on the history state in order to
       * avoid mismatch between current global filters and the redirected global filters
       */
      await appContext.onLogin(globalFiltersSearch);
      this.props.history.replace(from);
    } catch (error) {
      formikHelpers.setStatus(this.handleErrors(error));
    }
  };

  /**
   * Handles formatting API errors for app
   *
   * @param error The API error object
   * @returns {ErrorStatus} Formatted error object
   */
  handleErrors = (error): ErrorStatus =>
    getErrorStatus(error, intl.get('ERR_FORM_EMAIL_PASSWORD_INVALID_MESSAGE'));

  /**
   * Renders appropriate API errors for each form field
   *
   * @param name Field name under which the error has to be displayed
   * @returns {string | null} The error message from the locale
   */
  renderStatusError = (
    name: string,
    { form }: FieldProps<LoginFormValueTypes, LoginFormValues>
  ): string | null => {
    const { status } = form;
    if (status && status.msg && !isEmpty(status.fields)) {
      const fields = Object.keys(status.fields);
      if (fields.includes(name)) {
        const errorCode = status.fields[name];
        return intl.get(getLocalizedErrorString(String(errorCode)));
      }
    }
    return null;
  };

  /**
   * Renders the login form
   *
   * @returns {JSX.Element} JSX snippet containing the form component for login
   */
  render(): JSX.Element {
    return (
      <ModalBackground>
        <Modal size="lg" isOpen backdrop="static" centered>
          <Formik
            initialValues={this.initialValues}
            validationSchema={LoginFormValidation.GetValidationSchema()}
            onSubmit={this.handleLogin}
          >
            {({ isSubmitting }): JSX.Element => (
              <BlockUi tag="div" blocking={isSubmitting}>
                <ModalHeader className="increase-font pb-0 border-bottom-0">
                  {intl.get('LBL_LOGIN_TITLE')}
                </ModalHeader>
                <ModalBody>
                  <Form noValidate>
                    <Row>
                      <Col xs="auto" className={styles.image}>
                        <ImageComponent
                          loading="eager"
                          src={LoginIllustration}
                          alt="Log In"
                          thumb={loginThumb}
                        />
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor={LoginFields.EMAIL}>
                            {intl.get('LBL_LOGIN_EMAIL')}
                          </Label>
                          <Field
                            autoComplete="email"
                            type="email"
                            name={LoginFields.EMAIL}
                            maxLength={this.maxLength}
                            tabIndex={0}
                            className="form-control"
                            placeholder={intl.get(
                              'LBL_LOGIN_EMAIL_PLACEHOLDER'
                            )}
                          />
                          <EnhancedFormikError
                            name={LoginFields.EMAIL}
                            renderSecondaryError={this.renderStatusError}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor={LoginFields.PASSWORD}>
                            {intl.get('LBL_LOGIN_PASSWORD')}
                          </Label>
                          <Field
                            autoComplete="current-password"
                            type="password"
                            name={LoginFields.PASSWORD}
                            maxLength={this.maxLength}
                            tabIndex={0}
                            className="form-control"
                            placeholder={intl.get(
                              'LBL_LOGIN_PASSWORD_PLACEHOLDER'
                            )}
                          />
                          <EnhancedFormikError
                            name={LoginFields.PASSWORD}
                            renderSecondaryError={this.renderStatusError}
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <div className="insight-checkbox-group">
                              <Label
                                className={`text-14-medium ${styles.fitContent}`}
                              >
                                <span
                                  className="insight-checkbox mr-3"
                                  data-cy="terms-cb"
                                >
                                  <Field
                                    type="checkbox"
                                    name={LoginFields.REMEMBER_ME}
                                  />
                                  <i className="icon-check" />
                                </span>
                                {intl.get('LBL_REMEMBER_ME')}
                              </Label>
                              <EnhancedFormikError
                                name={LoginFields.REMEMBER_ME}
                                renderSecondaryError={this.renderStatusError}
                              />
                            </div>
                          </Col>
                          <Col xs="auto" className="d-flex align-items-center">
                            <InsNavLink
                              to={`${ModulePaths.AuthPath}${ModulePaths.AuthForgotPasswordPath}`}
                            >
                              <span className="text-14-semibold text-gray">
                                {intl.get('LBL_LOGIN_FORGOT_PASSWORD')}
                              </span>
                            </InsNavLink>
                          </Col>
                          <Col xs="12" className={styles.buttonContainer}>
                            <Button
                              type="submit"
                              className="btn btn-primary btn-login"
                              tabIndex={0}
                              disabled={isSubmitting}
                            >
                              {intl.get('BTN_LOGIN')}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </BlockUi>
            )}
          </Formik>
        </Modal>
      </ModalBackground>
    );
  }
}

export default Login;
