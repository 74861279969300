import Status from 'shared/enums/Status';

import UserViewModel from './UserViewModel';

enum UserTabFilters {
  INVITED = 'invited',
  USERS = 'users',
}
type Pagination = { total: number; page: number };

type InviteUsers = {
  resendStatus: Status;
  isOpen: boolean;
  createJobRoleStatus: Status;
};

type Users = {
  organizationId: string;
  data: UserViewModel[];
  status: Status;
  pageCount: number;
  showDeactivated: boolean;
  pagination: Pagination;
  hasDeactivatedUsers: boolean;
};

interface UsersViewState {
  inviteUsers: InviteUsers;
  users: Users;
}

export default UsersViewState;
export { UserTabFilters };
