import { ReactNode, useRef, FC as ReactFC } from 'react';

import get from 'lodash/get';
import BlockUi from 'react-block-ui';
import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer } from 'react-laag';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import GlobalFilters from 'constants/GlobalFilters';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import Status from 'shared/enums/Status';

import styles from '../filterToolbar.module.scss';
import ProjectsFilterSelectProps from './ProjectsFilterSelectProps';

const ProjectsFilterSelect: ReactFC<ProjectsFilterSelectProps> = (
  props: ProjectsFilterSelectProps
) => {
  const {
    appContext,
    onOpen,
    onChange,
    onClose,
    selectedProjects,
    maxSelected,
  } = props;

  const { dashboardSetupInProgress, getProjectList, projectListData } =
    appContext;

  const parentRef = useRef<HTMLDivElement>(null);

  const projectsEmpty = Object.values(projectListData.data).length <= 0;
  const filteredProject = Object.values(projectListData.filtered);

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <div
        ref={layerProps.ref}
        className="layer insight-dropdown"
        style={{
          ...layerProps.style,
          left: '50%',
          transform: 'translateX(-50%)',
          top: '10px',
        }}
      >
        <Arrow
          style={arrowStyle}
          layerSide={layerSide}
          backgroundColor="#ffffff"
          borderWidth={1}
          borderColor="#dbdce1"
          roundness={0.7}
        />
        <DebounceInput
          minLength={2}
          debounceTimeout={1000}
          onChange={(event): Promise<void> =>
            getProjectList(event.target.value)
          }
          className="form-control mb-3"
          placeholder={intl.get('LBL_SEARCH')}
          type="search"
          disabled={projectsEmpty}
        />
        <BlockUi blocking={projectListData.status === Status.Loading}>
          <PerfectScrollbar className={styles.scroll}>
            <Form>
              {filteredProject.length > 0 ? (
                filteredProject.map((project) => {
                  const isChecked = selectedProjects.includes(project.code);
                  const selectionBlocked = !isChecked && maxSelected;
                  return (
                    <FormGroup
                      key={project.code}
                      className={`insight-checkbox-group ${styles.wrap}`}
                    >
                      <span
                        data-for="insTooltip"
                        data-place="bottom"
                        data-class="error bring-it-up"
                        data-tip={
                          selectionBlocked
                            ? intl.get(
                                'ERR_GLOBAL_FILTER_PROJECT_SELECTION_LIMIT_REACHED',
                                {
                                  limit: GlobalFilters.MaxListSelectable,
                                }
                              )
                            : ''
                        }
                      >
                        <Label
                          className={`${isChecked ? 'checked-label' : ''} ${
                            selectionBlocked ? styles.reduceOpacity : ''
                          }`}
                        >
                          <span
                            className={`insight-checkbox mr-3 ${
                              selectionBlocked ? styles.defaultCursor : ''
                            }`}
                          >
                            <Input
                              type="checkbox"
                              tabIndex={0}
                              name={project.name}
                              value={project.code}
                              checked={isChecked}
                              onChange={onChange}
                              disabled={selectionBlocked}
                            />
                            <i className="icon-check" />
                          </span>
                          {selectionBlocked ? (
                            <span className={`truncate ${styles.option}`}>
                              {project.name}
                            </span>
                          ) : (
                            <EllipsisTooltip
                              tag="span"
                              data-place="right"
                              data-for="insTooltip"
                              data-tip={project.name}
                              data-class="overflow-wrap"
                              className={`truncate ${styles.option} ${
                                isChecked ? styles.checked : ''
                              }`}
                            >
                              {project.name}
                            </EllipsisTooltip>
                          )}
                        </Label>
                      </span>
                    </FormGroup>
                  );
                })
              ) : (
                <FormGroup className="insight-checkbox-group">
                  <Label
                    className="truncate"
                    style={{ maxWidth: '150px', textAlign: 'start' }}
                  >
                    {intl.get('LBL_NO_OPTIONS')}
                  </Label>
                </FormGroup>
              )}
            </Form>
          </PerfectScrollbar>
        </BlockUi>
      </div>
    );

  return (
    <ToggleLayer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={parentRef.current!} // nosonar
      closeOnOutsideClick
      onOutsideClick={onClose}
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => {
        const projectList =
          selectedProjects.length > 0
            ? selectedProjects
                .map((code) => get(projectListData, `data.${code}.name`, ''))
                .filter(Boolean)
                .join(', ')
            : intl.get('LBL_ALL_PROJECTS');

        return (
          <>
            <label>{intl.get('LBL_PROJECT')}</label>
            <EllipsisTooltip
              ref={triggerRef}
              className={`toggle-btn dropdown-btn ${
                isOpen ? 'dropdown-open' : ''
              }`}
              onClick={(): void => onOpen(toggle, isOpen)}
              disabled={dashboardSetupInProgress || projectsEmpty}
              tag="button"
              data-cy="projects-gf"
              data-place="bottom"
              data-for="insTooltip"
              data-tip={projectList}
              data-class="overflow-wrap"
            >
              {projectList}
            </EllipsisTooltip>
            <div
              ref={parentRef}
              className="position-relative"
              id="project-parent"
            />
          </>
        );
      }}
    </ToggleLayer>
  );
};

export default ProjectsFilterSelect;
