/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import Select from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';

import ChartTypeTooltipOption from './chart-type-tooltip-option/ChartTypeTooltipOption';

const ChartTypeSelect: ReactFC<SelectComponentsProps> = (
  props: SelectComponentsProps
) => (
  <div>
    <Select
      {...props}
      components={{
        Option: ChartTypeTooltipOption,
      }}
    />
  </div>
);

export default ChartTypeSelect;
