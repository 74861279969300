import { useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import styles from '../groupContainer.module.scss';
import NotificationTagProps from './NotificationTagProps';

const NotificationTag: ReactFC<NotificationTagProps> = (
  props: NotificationTagProps
) => {
  const { current, filtersLoading, onRemoveFilter } = props;
  const [focused, setFocused] = useState(false);

  const handleFocus = (): void => setFocused(true);
  const handleBlur = (): void => setFocused(false);

  return (
    <div
      className={`${styles.tag} ${filtersLoading ? styles.loading : ''}`}
      key={current.value}
    >
      <i className={`icon-warning text-orange ${styles.warning}`} />
      <span
        data-for="insTooltip"
        data-tip={intl.get(`ERR_GROUPS_${current.value}`)}
        data-place="bottom"
        data-class="error"
      >
        {current.label}
      </span>
      <button
        value={current.value}
        className={`insight-close-button ml-2 ${
          focused ? 'focus-visible' : ''
        }`}
        type="button"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={onRemoveFilter}
      >
        <i className="icon-close-small m-0" />
      </button>
    </div>
  );
};

export default NotificationTag;
