enum WidgetValueGroup {
  Female = 'FEMALE',
  Male = 'MALE',
  College = 'COLLEGE',
  PrimarySchool = 'PRIMARY_SCHOOL',
  NoFormalEducation = 'NO_FORMAL_EDUCATION',
  SecondarySchool = 'SECONDARY_SCHOOL',
  GreatProgress = 'GREAT_PROGRESS',
  GoodProgress = 'GOOD_PROGRESS',
  ALittleBehind = 'A_LITTLE_BEHIND',
  Business = 'BUSINESS',
  Education = 'EDUCATION',
  Household = 'HOUSEHOLD',
  Medical = 'MEDICAL',
  Land = 'LAND',
  Livestock = 'LIVESTOCK',
  Vehicle = 'VEHICLE',
  Technology = 'TECHNOLOGY',
  Travel = 'TRAVEL',
  Other = 'OTHER',
  GroupsFormed = 'GROUPS_FORMED',
  InsuranceFund = 'INSURANCE_FUND',
  EducationFund = 'EDUCATION_FUND',
  GroupBusinessFund = 'GROUP_BUSINESS_FUND',
  CelebrationFund = 'CELEBRATION_FUND',
  TechnologyFund = 'TECHNOLOGY_FUND',
  CustomFund = 'CUSTOM_FUND',
  OneDependent = 'ONE_DEPENDENT',
  TwoDependents = 'TWO_DEPENDENT',
  ThreeDependents = 'THREE_DEPENDENT',
  FourDependents = 'FOUR_DEPENDENT',
  FiveOrMoreDependents = 'FIVE_OR_MORE_DEPENDENT',
  MembersCaringForChildren = 'MEMBER_CARING_FOR_CHILDREN',
  TotalGroupMembers = 'TOTAL_MEMBERS',
}

export default WidgetValueGroup;
