/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import ReactTooltip from 'react-tooltip';

import AdditionalFundsWidget from 'modules/private/group/components/group-widgets/widgets/additional-funds-widget/AdditionalFundsWidget';
import AgeWidget from 'modules/private/group/components/group-widgets/widgets/age-widget/AgeWidget';
import AverageSavingsWidget from 'modules/private/group/components/group-widgets/widgets/average-savings-widget/AverageSavingsWidget';
import CumulativeSavingsWidget from 'modules/private/group/components/group-widgets/widgets/cumulative-savings-widget/CumulativeSavingsWidget';
import EducationWidget from 'modules/private/group/components/group-widgets/widgets/education-widget/EducationWidget';
import GenderWidget from 'modules/private/group/components/group-widgets/widgets/gender-widget/GenderWidget';
import GroupWidgetConfigs from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetConfigs';
import GroupWidgetProps from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetProps';
import GroupWidgetType from 'modules/private/group/components/group-widgets/widgets/GroupWidgetType';
import LoanFundWidget from 'modules/private/group/components/group-widgets/widgets/loan-fund-widget/LoadFundWidget';
import LoanTypesWidget from 'modules/private/group/components/group-widgets/widgets/loan-types-widget/LoanTypesWidget';
import MemberChildrenWidget from 'modules/private/group/components/group-widgets/widgets/member-children-widget/MemberChildrenWidget';
import PersonalGoalAchievementWidget from 'modules/private/group/components/group-widgets/widgets/personal-goal-achievement-widget/PersonalGoalAchievementWidget';
import PersonalGoalTypeWidget from 'modules/private/group/components/group-widgets/widgets/personal-goal-type-widget/PersonalGoalTypeWidget';

const GroupWidgets: ReactFC<GroupWidgetProps> = (props) => {
  const { data, groupDetailsSetupInProgress, widgetsLoading } = props;

  const name = (t: GroupWidgetType): string =>
    GroupWidgetConfigs.GetGroupWidgetConfig(t).name;
  const tooltip = (t: GroupWidgetType): string =>
    GroupWidgetConfigs.GetGroupWidgetConfig(t).tooltip;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  return (
    <div
      className={`col col-lg-6 col-xl-6 flex-col ${
        groupDetailsSetupInProgress
          ? 'active-dashboard-widget'
          : 'dashboard-widget'
      }`}
    >
      <BlockUi tag="div" blocking={widgetsLoading} className="flex-col-inner">
        <div className="content-box">
          <div className="content-box-title">
            <h3 className="widget-title">
              {name(data.type)}
              <span className="help">
                <i
                  className="icon-help"
                  data-for="insTooltip"
                  data-tip={tooltip(data.type)}
                />
              </span>
            </h3>
          </div>

          {!data.pendingChanges && data.type === GroupWidgetType.Gender && (
            <GenderWidget data={data} />
          )}
          {!data.pendingChanges && data.type === GroupWidgetType.Education && (
            <EducationWidget data={data} />
          )}
          {!data.pendingChanges &&
            data.type === GroupWidgetType.PersonalGoalAchievement && (
              <PersonalGoalAchievementWidget data={data} />
            )}
          {!data.pendingChanges && data.type === GroupWidgetType.LoanType && (
            <LoanTypesWidget data={data} />
          )}

          {!data.pendingChanges &&
            data.targetData &&
            data.type === GroupWidgetType.AverageSavings &&
            data.value && <AverageSavingsWidget data={data} />}

          {!data.pendingChanges &&
            data.targetData &&
            data.type === GroupWidgetType.CumulativeSavings &&
            data.value && <CumulativeSavingsWidget data={data} />}

          {!data.pendingChanges &&
            data.targetData &&
            data.type === GroupWidgetType.LoanFund &&
            data.value && <LoanFundWidget data={data} />}

          {!data.pendingChanges &&
            data.type === GroupWidgetType.PersonalGoalType && (
              <PersonalGoalTypeWidget data={data} />
            )}

          {!data.pendingChanges && data.type === GroupWidgetType.Age && (
            <AgeWidget data={data} />
          )}

          {!data.pendingChanges &&
            data.type === GroupWidgetType.MemberChildren && (
              <MemberChildrenWidget data={data} />
            )}

          {!data.pendingChanges &&
            data.type === GroupWidgetType.AdditionalFunds && (
              <AdditionalFundsWidget data={data} />
            )}

          {data.pendingChanges && (
            <div className="pending-changes">
              <i className="icon-analytics" />
              <span>Save Widgets to view new data</span>
            </div>
          )}

          {!data.targetData &&
            (data.type === GroupWidgetType.AverageSavings ||
              data.type === GroupWidgetType.CumulativeSavings ||
              data.type === GroupWidgetType.LoanFund) && (
              <div className="empty-chart-container" />
            )}
        </div>
      </BlockUi>
    </div>
  );
};

export default GroupWidgets;
