/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC as ReactFC } from 'react';

import clone from 'lodash/clone';
import * as intl from 'react-intl-universal';

import GroupWidgetTargetType from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetTargetType';
import GroupWidgetViewModel from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetViewModel';
import DonutArea from 'shared/components/ins-charts/donut-area/DonutArea';
import GaugeWidget from 'shared/components/ins-charts/gauge/GaugeWidget';
import InsNumberFormat from 'shared/components/ins-number-format/InsNumberFormat';

const AverageSavingsWidget: ReactFC<{ data: GroupWidgetViewModel }> = (
  props
) => {
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = clone(props);
  const { value, targetData } = data;

  let savingsData;

  if (value) {
    savingsData = {
      name: value.title,
      count: value.count,
      percentage: value.percentage,
      group: value.group,
    };
  }

  if (targetData) {
    savingsData.percentageRange = targetData.percentageRange;
    savingsData.fromTarget = targetData.targetFrom;
    savingsData.toTarget = targetData.targetTo;
  }

  return (
    <div className="gender-widget">
      <div className="chart-container">
        {!data.targetData ||
          (data.targetData &&
            data.targetData.targetType !== GroupWidgetTargetType.Range && (
              <DonutArea
                isLoading={false}
                value={savingsData}
                dimension={dimensionSettings}
              />
            ))}
        {data.targetData &&
          data.targetData.targetType === GroupWidgetTargetType.Range && (
            <GaugeWidget
              isLoading={false}
              isCurrency
              value={savingsData}
              dimension={dimensionSettings}
            />
          )}
      </div>
      <div className="chart-details">
        <span className="sub-title">{intl.get('LBL_CURRENT_SAVINGS')}</span>
        {savingsData && (
          <span className="value">
            <InsNumberFormat
              value={savingsData.count}
              type="currency"
              precise={false}
            />
          </span>
        )}
      </div>
      <div className="chart-details separator">
        <span className="sub-title">{intl.get('LBL_GROUP_GOAL')}</span>
        {data.targetData &&
          data.targetData.targetType === GroupWidgetTargetType.None && (
            <span className="value">{intl.get('LBL_NOT_SET')}</span>
          )}
        {data.targetData &&
          data.targetData.targetType === GroupWidgetTargetType.Value &&
          data.targetData.target !== null && (
            <span className="value">
              <InsNumberFormat
                value={data.targetData.target}
                type="currency"
                precise={false}
              />
            </span>
          )}
        {data.targetData &&
          data.targetData.targetType === GroupWidgetTargetType.Range &&
          data.targetData.targetFrom !== null &&
          data.targetData.targetTo !== null && (
            <span className="value">
              <InsNumberFormat
                value={data.targetData.targetFrom}
                type="currency"
                precise={false}
              />
              <span> - </span>
              <InsNumberFormat
                value={data.targetData.targetTo}
                type="currency"
                precise={false}
              />
            </span>
          )}
      </div>
    </div>
  );
};

export default AverageSavingsWidget;
