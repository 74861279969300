import * as intl from 'react-intl-universal';
import * as yup from 'yup';

import Constraints from 'constants/forms/Constraints';

import ComposeMessageFormValues from './ComposeMessageFormValues';

class ComposeMessageFormValidation {
  /**
   * Defines a Yup validation schema for the message compose form
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema<
      Partial<ComposeMessageFormValues>
    > = yup.object().shape({
      title: yup
        .string()
        .trim()
        .required(intl.get('ERR_MESSAGING_COMPOSE_TITLE_REQUIRED'))
        .max(
          Constraints.MaxMessageTitleLength,
          intl.get('ERR_MESSAGING_COMPOSE_TITLE_MAX_LENGTH', {
            length: Constraints.MaxMessageTitleLength,
          })
        ),
      body: yup
        .string()
        .trim()
        .required(intl.get('ERR_MESSAGING_COMPOSE_BODY_REQUIRED'))
        .max(
          Constraints.MaxMessageBodyLength,
          intl.get('ERR_MESSAGING_COMPOSE_BODY_MAX_LENGTH', {
            length: Constraints.MaxMessageBodyLength,
          })
        ),
    });

    return validationSchema;
  }
}

export default ComposeMessageFormValidation;
