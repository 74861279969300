/* eslint-disable react/jsx-props-no-spreading */
import {
  ReactElement,
  useEffect,
  useRef,
  FC as ReactFC,
  Children,
} from 'react';

import { IInfoWindowProps, InfoWindow } from 'google-maps-react';
import ReactDOM from 'react-dom';

import usePrevious from 'shared/hooks/use-previous/UsePrevious';

const InsInfoWindow: ReactFC<IInfoWindowProps> = ({ children, ...rest }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const infoWindowRef = useRef<any>();
  const contentElement = document.createElement<'div'>('div');

  const prevChildren = usePrevious(children);

  useEffect(() => {
    const typedChildren = children as ReactElement;
    if (children !== prevChildren && infoWindowRef && infoWindowRef.current) {
      ReactDOM.render(Children.only(typedChildren), contentElement);
      infoWindowRef.current.infowindow.setContent(contentElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, infoWindowRef]);

  return (
    <InfoWindow ref={infoWindowRef} {...rest}>
      {children}
    </InfoWindow>
  );
};

export default InsInfoWindow;
