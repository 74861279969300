import { ReactNode, useCallback, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { RenderLayerProps, ToggleLayer } from 'react-laag';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import { surveyTypes } from 'modules/private/surveys/containers/SurveyConstants';
import SurveyType from 'shared/enums/SurveyType';

import SurveyTypeSelectProps from './SurveyTypeSelectProps';

const SurveyTypeSelect: ReactFC<SurveyTypeSelectProps> = (
  props: SurveyTypeSelectProps
) => {
  const {
    canCreateSurveys,
    canCollectIDs,
    surveyId,
    selectedSurveyTypes,
    disabled,
    onChange,
  } = props;

  const [surveyTypeRef, setSurveyTypeRef] = useState<HTMLDivElement | null>(
    null
  );

  const setCallbackRef = useCallback((element) => {
    setSurveyTypeRef(element);
  }, []);

  const interactedSurveys = Object.keys(selectedSurveyTypes);
  const surveyRowExists = interactedSurveys.includes(surveyId);
  let selectedTypeLabel = '';

  if (surveyRowExists) {
    const selectedTypeValue = selectedSurveyTypes[surveyId];
    const moddedTypes = surveyTypes.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.value]: currentValue.label,
      }),
      {}
    );
    selectedTypeLabel = intl.get(moddedTypes[selectedTypeValue]);
  }

  const renderLayer = ({
    isOpen,
    layerProps,
    close,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <>
        <div
          ref={layerProps.ref}
          style={{
            ...layerProps.style,
            left: '50%',
            minWidth: '100%',
            transform: 'translateX(-50%)',
            top: '10px',
            zIndex: 90,
          }}
          className="layer insight-dropdown"
        >
          <Form>
            {surveyTypes.map((surveyType) => {
              let isSelected = false;
              if (surveyRowExists) {
                isSelected = selectedSurveyTypes[surveyId] === surveyType.value;
              }
              const selectionBlocked =
                (surveyType.value === SurveyType.Survey && !canCreateSurveys) ||
                (surveyType.value === SurveyType.CollectId && !canCollectIDs);
              return (
                <FormGroup
                  key={surveyType.value}
                  className="insight-radio-group"
                >
                  <Label
                    className={`surveys-select-label ${
                      selectionBlocked ? 'disabled-label' : ''
                    }`}
                  >
                    <span className="insight-radio">
                      <Input
                        type="radio"
                        tabIndex={0}
                        name="surveyType"
                        value={surveyType.value}
                        onChange={(e): void => onChange(e, close)}
                        className={`${isSelected ? 'active-radio' : ''}`}
                        disabled={selectionBlocked}
                      />
                      <i className="icon-radio" />
                    </span>
                    {intl.get(surveyType.label)}
                  </Label>
                </FormGroup>
              );
            })}
          </Form>
        </div>
      </>
    );

  const container = surveyTypeRef;

  return (
    <ToggleLayer
      container={container || undefined}
      closeOnOutsideClick
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => (
        <>
          <button
            ref={triggerRef}
            className={`calendar-button surveys-toggle text-gray ${
              isOpen ? 'calendar-open' : ''
            }`}
            data-cy="status-gf"
            onClick={toggle}
            disabled={disabled}
          >
            {selectedTypeLabel ||
              intl.get('LBL_SURVEYS_SURVEY_TYPE_PLACEHOLDER')}
          </button>
          <div
            ref={setCallbackRef}
            className="position-relative"
            id="recipient-type-select-parent"
          />
        </>
      )}
    </ToggleLayer>
  );
};

export default SurveyTypeSelect;
