import GroupDetailListItem from 'api/group/types/GroupDetailListItem';
import GroupDetailMapItem from 'api/group/types/GroupDetailMapItem';
import GroupMetricValue from 'api/group/types/GroupMetricValue';
import GroupFacilitatorViewModel from 'modules/private/group/components/assign-facilitator-menu/GroupFacilitatorViewModel';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import Status from 'shared/enums/Status';

import NotificationFilterOption from '../../components/group-container/notification-select/NotificationFilterOption';

export enum NotificationInitStatus {
  Idle = 'IDLE',
  ViaProjectsHasFilters = 'VIA_PROJECTS_HAS_FILTERS',
  ViaProjectsNoFilters = 'VIA_PROJECTS_NO_FILTERS',
  ViaGroups = 'VIA_GROUPS',
}
type GroupMetricsData = {
  data: Array<GroupMetricValue>;
  loading: boolean;
};

type GroupDetailMapData = {
  data: Array<GroupDetailMapItem>;
  loading: boolean;
};

type GroupDetailListData = {
  data: Array<GroupDetailListItem>;
  loading: boolean;
  error: null | string;
  pageCount: number;
  pageSize: number;
  pageIndex: number;
  sortBy: { id: string; desc: boolean }[];
  pagination: { total: number; page: number };
  hiddenColumns: string[];
  columnOrder: string[];
  selectedRows: { [key: string]: boolean };
  allRowIds: string[];
  unselectAllRows: boolean;
  showManageColsModal: boolean;
  facilitators: GroupFacilitatorViewModel[];
  facilitatorsStatus: Status;
  facilitatorsError: string;
  groupStatGroupsAssigned: boolean;
  assignToProjectStatus: Status;
  assignToProjectError: string;
  assignFacilitatorsStatus: Status;
  assignFacilitatorsError: string;
  assignGroupsStatusStatus: Status;
  assignGroupsStatusError: string;
  groupOverviewStatsStatus: Status;
};

interface GroupOverviewState {
  organizationId: string;
  viaProjects: boolean;
  filters: GlobalFilters;
  searchTerm: string;
  showGroupList: boolean;
  notificationsStatus: Status;
  notifications: NotificationFilterOption[];
  notificationFilters: string[];
  notificationInitial: NotificationInitStatus;
  groupMetricsData: GroupMetricsData;
  groupDetailMapData: GroupDetailMapData;
  groupDetailListData: GroupDetailListData;
}

export default GroupOverviewState;
