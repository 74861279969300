import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import BackupStatus from 'shared/enums/BackupStatus';

import BackupStatusTagProps from './BackupStatusTagProps';

const BackupStatusTag: ReactFC<BackupStatusTagProps> = (
  props: BackupStatusTagProps
) => {
  const { type } = props;
  let status: string;
  switch (type) {
    case BackupStatus.Never:
      status = 'blue';
      break;
    case BackupStatus.AllMeetings:
      status = 'green';
      break;
    case BackupStatus.OneMeetingNotBackedUp:
      status = 'yellow';
      break;
    case BackupStatus.TwoOrMoreMeetingsNotBackedUp:
      status = 'red';
      break;
    default:
      status = 'gray';
      break;
  }
  return (
    <span className={`backup-tag ${status}`}>
      {intl.get(`LBL_${type || BackupStatus.None}`)}
    </span>
  );
};

export default BackupStatusTag;
