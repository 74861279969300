import { useEffect, useState, FC as ReactFC } from 'react';

import { Location } from 'history';
import * as intl from 'react-intl-universal';
import { Prompt } from 'react-router-dom';
import { Button, Fade } from 'reactstrap';

import TrapFocus from 'shared/components/trap-focus/TrapFocus';

import styles from './routeChangeGuard.module.scss';
import RouteChangeGuardProps from './RouteChangeGuardProps';

const RouteChangeGuard: ReactFC<RouteChangeGuardProps> = (
  props: RouteChangeGuardProps
) => {
  const { when, shouldBlockNavigation, navigate } = props;

  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);

  /**
   * Validate whether to block user navigation from current location
   *
   * @param nextLocation Location user prepared to navigate to
   * @returns {boolean} Whether to block navigation
   */
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setAlertVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  /**
   * Handle confirm click
   */
  const onConfirmNavigationClick = (): void => {
    setAlertVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      navigate(lastLocation);
      setConfirmedNavigation(false);
      setLastLocation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Fade
        mountOnEnter
        unmountOnExit
        in={alertVisible}
        className={styles.overlay}
      >
        <TrapFocus autoFocus>
          {({ firstFocus, lastFocus }): JSX.Element => (
            <div className={styles.confirmation}>
              <div className="text-14-medium text-center">
                {intl.get('LBL_PROJECTS_USERS_ADD_UNSAVED_WARNING')}
              </div>
              <Button
                innerRef={firstFocus}
                className="btn btn-warning btn-sm"
                type="button"
                onClick={(): void => onConfirmNavigationClick()}
              >
                {intl.get('BTN_PROJECT_USERS_WARNING_CONTINUE')}
              </Button>
              <Button
                innerRef={lastFocus}
                className="btn btn-light btn-sm"
                onClick={(): void => setAlertVisible(false)}
              >
                {intl.get('BTN_CANCEL')}
              </Button>
            </div>
          )}
        </TrapFocus>
      </Fade>
    </>
  );
};

export default RouteChangeGuard;
