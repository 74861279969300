/* eslint-disable react/prefer-stateless-function */
import { Component } from 'react';

import * as intl from 'react-intl-universal';
import { Col, Row } from 'reactstrap';

import setPageTitle from 'helpers/setPageTitle';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import noAssignedProject from 'shared/static/img/password_new.svg';

import styles from './activityFeedView.module.scss';

class ActivityFeedView extends Component {
  componentDidMount(): void {
    setPageTitle(intl.get('BTN_SETTINGS'));
  }

  render(): JSX.Element {
    return (
      <div className="content-container d-flex align-items-center">
        <ScrollToTopOnMount />
        <div className={`${styles.empty} mx-auto`}>
          <Row>
            <Col xs="12" className="text-center">
              <h2 className="mb-4">
                {intl.get('LBL_SETTINGS_EMPTY_VIEW_TITLE')}
              </h2>
            </Col>
            <Col xs="12">
              <img
                loading="eager"
                alt="Nothing to see"
                src={noAssignedProject}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ActivityFeedView;
