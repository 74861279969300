import { ReactNode, useRef, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer } from 'react-laag';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import BackupStatus from 'shared/enums/BackupStatus';

import { BackupStatusConfigs } from '../GlobalFilterConfigs';
import BackupStatusFilterSelectProps from './BackupStatusFilterSelectProps';

const BackupStatusFilterSelect: ReactFC<BackupStatusFilterSelectProps> = (
  props: BackupStatusFilterSelectProps
) => {
  const { appContext, onChange, globalFilters } = props;

  const { dashboardSetupInProgress /* globalFilters */ } = appContext;

  const parentRef = useRef<HTMLDivElement>(null);

  const backupStatuses = [
    {
      value: BackupStatus.None,
      label: `LBL_ALL`,
    },
    {
      value: BackupStatus.Never,
      label: 'LBL_NEVER',
    },
    {
      value: BackupStatus.AllMeetings,
      label: 'LBL_ALL_MEETINGS',
    },
    {
      value: BackupStatus.OneMeetingNotBackedUp,
      label: 'LBL_ONE_MEETING_NOT_BACKED_UP',
    },
    {
      value: BackupStatus.TwoOrMoreMeetingsNotBackedUp,
      label: 'LBL_TWO_OR_MORE_MEETINGS_NOT_BACKED_UP',
    },
  ];

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
    close,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <div
        ref={layerProps.ref}
        className="layer insight-dropdown"
        style={{
          ...layerProps.style,
          left: '50%',
          transform: 'translateX(-50%)',
          top: '10px',
        }}
      >
        <Arrow
          style={arrowStyle}
          layerSide={layerSide}
          backgroundColor="#ffffff"
          borderWidth={1}
          borderColor="#dbdce1"
          roundness={0.7}
        />
        <Form>
          {backupStatuses.map(({ value, label }) => (
            <FormGroup key={value} className="insight-radio-group">
              <Label>
                <span className="insight-radio">
                  <Input
                    type="radio"
                    tabIndex={0}
                    name={`backup-status-${value}`}
                    value={value}
                    onChange={(e): void => onChange(e, close)}
                    className={
                      globalFilters.backupStatus === value ? 'active-radio' : ''
                    }
                  />
                  <i className="icon-radio" />
                </span>
                {intl.get(label)}
              </Label>
            </FormGroup>
          ))}
        </Form>
      </div>
    );

  return (
    <ToggleLayer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={parentRef.current!} // nosonar
      closeOnOutsideClick
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => (
        <>
          <label>{intl.get('LBL_TITLE_BACKUP_STATUS')}</label>
          <EllipsisTooltip
            ref={triggerRef}
            className={`toggle-btn dropdown-btn ${
              isOpen ? 'dropdown-open' : ''
            }`}
            onClick={toggle}
            disabled={dashboardSetupInProgress}
            tag="button"
            data-cy="backup-gf"
            data-place="bottom"
            data-for="insTooltip"
            data-tip={BackupStatusConfigs[globalFilters.backupStatus]}
            data-class="overflow-wrap"
          >
            {BackupStatusConfigs[globalFilters.backupStatus]}
          </EllipsisTooltip>
          <div
            ref={parentRef}
            className="position-relative"
            id="backup-status-parent"
          />
        </>
      )}
    </ToggleLayer>
  );
};

export default BackupStatusFilterSelect;
