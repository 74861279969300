import { useEffect, useState, FC as ReactFC } from 'react';

import find from 'lodash/find';
import * as intl from 'react-intl-universal';
import { ValueType } from 'react-select/src/types';

import { JobRole } from 'modules/private/projects/containers/projects-view/ProjectUsersViewModel';
import Status from 'shared/enums/Status';
import JobRoleOption from 'shared/modules/users/components/invite-users-field-array/invite-users-form-item/job-role-async-select/JobRoleOption';
import JobRoleSelect from 'shared/modules/users/components/invite-users-field-array/invite-users-form-item/job-role-async-select/JobRoleSelect';

import InlineEditStyles from '../InlineEditStyles';
import InlineEditJobRoleSelectProps from './InlineEditJobRoleSelectProps';

const InlineEditJobRoleSelect: ReactFC<InlineEditJobRoleSelectProps> = (
  props: InlineEditJobRoleSelectProps
) => {
  const {
    cellProps,
    jobRoles,
    jobRolesStatus,
    createJobRoleStatus,
    lastCreatedJobRoleId,
    lastJobRoleEditedRowId,
    createJobRoleError,
    clearJobRoleError,
    onCreateJobRole,
    onUpdateUser,
  } = props;

  const {
    row: { id: rowId, original },
    column: { id: columnId },
  } = cellProps;

  const [value, setValue] = useState<JobRoleOption | null>(null);

  useEffect(() => {
    setValue(() => find(jobRoles, { value: original.jobRole?.id }) ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellProps]);

  useEffect(() => {
    if (
      lastCreatedJobRoleId &&
      lastJobRoleEditedRowId &&
      lastJobRoleEditedRowId === rowId
    ) {
      const role = find(jobRoles, { value: lastCreatedJobRoleId }) ?? null;
      if (role) {
        onChange(role);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobRoles]);

  /**
   * Handles creating a new job role
   *
   * @param createdValue Newly created job role value
   */
  const handleCreateJobRole = (createdValue: string): void => {
    onCreateJobRole(rowId, createdValue);
  };

  /**
   * Handles change event for job roles
   *
   * @param option The selected job role
   */
  const onChange = (option: ValueType<JobRoleOption>): void => {
    const selected = option as JobRoleOption;
    const selectedJobRole: JobRole = {
      id: selected.value,
      role: selected.label,
    };
    onUpdateUser(rowId, columnId, selectedJobRole);
    setValue(selected);
  };

  return (
    <JobRoleSelect
      value={value}
      onChange={onChange}
      options={jobRoles}
      isLoading={
        jobRolesStatus === Status.Loading ||
        createJobRoleStatus === Status.Loading
      }
      onCreateJobRole={handleCreateJobRole}
      createJobRoleStatus={createJobRoleStatus}
      createJobRoleError={createJobRoleError}
      clearJobRoleError={clearJobRoleError}
      getOptionLabel={(option): string => option.label}
      getOptionValue={(option): string => option.value}
      name={`users.${String(rowId)}.jobRole`}
      isInlineEdit
      placeholder={intl.get('LBL_PROJECT_USERS_JOB_ROLE_PLACEHOLDER')}
      styles={InlineEditStyles.GetCustomStyles()}
      className="size-down mb-1 extend-width"
      classNamePrefix="insight-select"
    />
  );
};

export default InlineEditJobRoleSelect;
