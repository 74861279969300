export type Supervisor = {
  name: string;
  id: string;
};

class ProjectFacilitatorViewModel {
  id: string;

  name: string;

  phoneNumber: string;

  image: string;

  supervisor: Supervisor;

  facilitatorNumber?: string;

  location?: string;

  assignedGroupCount: number;

  constructor(
    id: string,
    name: string,
    phoneNumber: string,
    image: string,
    supervisor: Supervisor,
    facilitatorNumber?: string,
    location?: string,
    assignedGroupCount?: number
  ) {
    this.id = id;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.image = image;
    this.supervisor = supervisor;
    this.facilitatorNumber = facilitatorNumber;
    this.location = location;
    this.assignedGroupCount = assignedGroupCount ?? 0;
  }
}

export default ProjectFacilitatorViewModel;
