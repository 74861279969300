const ProjectUsersSortableColumns: Set<string> = new Set([
  'name',
  'userNumber',
  'jobRole',
  'supervisor',
  'lastLogin',
]);

const TableConfig = {
  defaultSort: 'name',
  sortCols: ProjectUsersSortableColumns,
  defaultSortDesc: false,
};

export default TableConfig;
