/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useContext, FC as ReactFC } from 'react';

import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { Row } from 'reactstrap';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import AppContext from 'context/AppContext';
import { formatDate } from 'helpers/DateFormat';
import {
  getFormattedNumber,
  getPercentageWithFormat,
} from 'helpers/NumberFormat';
import PermissionUtil from 'helpers/PermissionUtil';
import OffsetPaginationFooter from 'shared/components/data-table-footer/offset-pagination-footer/OffsetPaginationFooter';
import DateFormatType from 'shared/enums/DateFormatType';
import Status from 'shared/enums/Status';

import SurveysHistoryViewModel from '../../containers/surveys-history-view/SurveysHistoryViewModel';
import CollectIdsHistoryHeaderCell from './collect-ids-history-header-cell/CollectIdsHistoryHeaderCell';
import CollectIdsHistoryRow from './collect-ids-history-row/CollectIdsHistoryRow';
import styles from './collectIdsHistoryDataTable.module.scss';
import CollectIdsHistoryDataTableProps from './CollectIdsHistoryDataTableProps';
import TableConfig from './CollectIdsHistoryTableConfigs';
import DummyRow from './dummy-row/DummyRow';

const SortableColumns = TableConfig.sortCols;

const CollectIdsHistoryDataTable: ReactFC<CollectIdsHistoryDataTableProps> = (
  props: CollectIdsHistoryDataTableProps
) => {
  const {
    data,
    status,
    hasNext,
    hasPrevious,
    initialPage,
    initialPageSize,
    initialSortBy,
    total,
    statusReloadRow,
    showCollectIdGuideTooltip,
    onPageChange,
    onPageSizeChange,
    onSortByChange,
    onRefetchData,
    onReloadStatus,
    onStatusToggleChange,
    containerRef,
    onExportCorrelationData,
  } = props;

  const {
    permissionsData: { claims },
  } = useContext(AppContext);

  const canDownloadCSV = PermissionUtil.Can(claims, ResourceKeys.ExportCSV);

  const hundredPercentStringified = '100';

  /**
   * Renders collect ID name
   *
   * @param row row props from react-table
   * @returns JSX.ELement action buttons
   */
  const renderName = ({ row, value }): JSX.Element | string => {
    if (row.original.archived) {
      return (
        <div className="d-flex p-0 align-items-start">
          <i
            data-for="insTooltip"
            data-tip={intl.get('ERR_COLLECT_ID_ARCHIVED')}
            data-place="bottom"
            data-class="error"
            className="icon-warning text-orange mr-3 text-18-medium"
          />
          {String(value)}
        </div>
      );
    }
    return String(value);
  };

  /**
   * Renders the formatted survey type
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted survey type
   */
  const renderSurveyType = ({ value }): string =>
    value
      ? intl.get(`LBL_SURVEYS_SURVEY_HISTORY_SURVEY_TYPE_${String(value)}`)
      : intl.get('LBL_NA');

  /**
   * Renders the status toggle
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {JSX.Element} JSX snippet containing the status toggle
   */
  const renderStatus = ({ value, row }): JSX.Element => {
    const isArchived = row.original.archived;
    const isDisabled = isArchived || statusReloadRow !== null;
    return (
      <label className={`${styles.switch} ${isDisabled ? styles.disable : ''}`}>
        <input
          type="checkbox"
          checked={value}
          onChange={(e): void => onStatusToggleChange(e, row)}
          disabled={isDisabled}
        />
        <span
          className={`${styles.slider} ${isDisabled ? styles.disable : ''}`}
        >
          {value
            ? intl.get('LBL_SURVEYS_SURVEY_HISTORY_COLLECT_ID_STATUS_ON')
            : intl.get('LBL_SURVEYS_SURVEY_HISTORY_COLLECT_ID_STATUS_OFF')}
        </span>
        <span className={styles.track} />
      </label>
    );
  };

  /**
   * Renders the formatted delivery status
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted delivery status
   */
  const renderDeliveryStatus = (cellProps): string | JSX.Element => {
    const { value, row } = cellProps;
    const { processing, sent, failed } = value;

    const isArchived = row.original.archived;

    let totalCount = -1;
    if (
      typeof processing === 'number' &&
      typeof sent === 'number' &&
      typeof failed === 'number'
    ) {
      totalCount = Number(processing) + Number(sent) + Number(failed);
    }

    const processingPercentage = getPercentageWithFormat(
      Number(processing),
      totalCount
    );
    const sentPercentage = getPercentageWithFormat(Number(sent), totalCount);

    const failedPercentage = getPercentageWithFormat(
      Number(failed),
      totalCount
    );

    const isHidden =
      isArchived || processingPercentage !== hundredPercentStringified;

    if (value !== undefined && value !== null && !isEmpty(value)) {
      return (
        <>
          <div className={`d-flex justify-content-end ${styles.refresh}`}>
            <div
              className="col-3 p-0 mr-2"
              style={{
                visibility: isHidden ? 'hidden' : 'visible',
              }}
            >
              <button
                className="btn btn-secondary justify-content-center btn-icon mr-2"
                onClick={(e): Promise<void> => onReloadStatus(e, row)}
                disabled={statusReloadRow !== null}
              >
                <i className="icon-refresh m-0" />
              </button>
            </div>
            <div className="col-9 p-0">
              <span className="d-flex align-items-center justify-content-end pr-0">
                <span className="col-8 p-0 mr-1">
                  {intl.get('LBL_SURVEYS_SURVEY_HISTORY_STATUS_PROCESSING')}:
                </span>
                <span className="col-4 p-0">{processingPercentage}%</span>
              </span>
              <span className="d-flex align-items-center justify-content-end pr-0">
                <span className="col-8 p-0 mr-1">
                  {intl.get('LBL_MESSAGING_HISTORY_SENT')}:
                </span>
                <span className="col-4 p-0">{sentPercentage}%</span>
              </span>
              <span className="d-flex align-items-center justify-content-end pr-0">
                <span className="col-8 p-0 mr-1">
                  {intl.get('LBL_MESSAGING_HISTORY_FAILED')}:
                </span>
                <span className="col-4 p-0">{failedPercentage}%</span>
              </span>
            </div>
          </div>
        </>
      );
    }
    return intl.get('LBL_NA');
  };

  /**
   * Renders the formatted user name
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted user name
   */
  const renderCreatedBy = ({ value }): string =>
    value ? value.firstName : intl.get('LBL_NA');

  /**
   * Renders the formatted received percentage
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted received percentage
   */
  const renderReceived = ({ value }): string =>
    value !== undefined && value !== null
      ? `${String(value)}%`
      : intl.get('LBL_NA');

  /**
   * Renders the formatted date
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted last date
   */
  const renderSentDate = ({ value }): string =>
    value
      ? formatDate(value, DateFormatType.SurveyCreation)
      : intl.get('LBL_NA');

  /**
   * Renders action buttons
   *
   * @param row row props from react-table
   * @returns JSX.ELement action buttons
   */
  const renderActions = ({ row }): JSX.Element => (
    <div
      className={`${styles.actions} ${
        canDownloadCSV ? 'justify-content-between' : 'justify-content-end'
      }`}
      data-id={row.id}
    >
      {canDownloadCSV && (
        <button
          className="ml-auto mr-3 justify-content-start"
          onClick={(): Promise<void> => onExportCorrelationData(row.id)}
        >
          <i className="icon-export" />
          {intl.get('BTN_MESSAGING_HISTORY_EXPORT')}
        </button>
      )}
      <a
        target="_blank"
        className="btn btn-secondary justify-content-end"
        rel="noopener noreferrer"
        href={`${String(
          process.env.REACT_APP_KOBO_FORM_SUMMARY_URL_PART_A
        )}${String(row.original.surveyId)}${String(
          process.env.REACT_APP_KOBO_FORM_SUMMARY_URL_PART_B
        )}`}
      >
        {intl.get('BTN_SURVEYS_SURVEY_HISTORY_VIEW_MORE')}
      </a>
    </div>
  );

  /**
   * Handles disabling the column sort functionality
   *
   * @param column Name of the column
   * @param checkSelectedRows To consider selected row count
   * @param checkEditMode To consider edit mode
   * @returns {boolean}
   */
  const handleDisableSorting = (column: string): boolean =>
    !SortableColumns.has(column) || data.length === 0;

  const columns = useMemo(
    () => [
      {
        dataTitle: intl.get('LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_NAME'),
        Header: CollectIdsHistoryHeaderCell,
        accessor: 'name',
        className: 'text-bold truncate-long-words',
        disableSortBy: handleDisableSorting('name'),
        Cell: renderName,
      },
      {
        dataTitle: intl.get(
          'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_SENT_DATE'
        ),
        Header: CollectIdsHistoryHeaderCell,
        accessor: 'sendDate',
        className: 'text-bold text-center',
        disableSortBy: handleDisableSorting('sendDate'),
        Cell: renderSentDate,
      },
      {
        dataTitle: intl.get(
          'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_CREATOR'
        ),
        Header: CollectIdsHistoryHeaderCell,
        accessor: 'creator',
        className: 'text-bold text-center truncate-long-words',
        disableSortBy: handleDisableSorting('creator'),
        Cell: renderCreatedBy,
      },
      {
        dataTitle: intl.get('LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_TYPE'),
        Header: CollectIdsHistoryHeaderCell,
        accessor: 'surveyType',
        className: 'text-bold text-center truncate-long-words',
        disableSortBy: true,
        Cell: renderSurveyType,
      },
      {
        id: 'status',
        dataTitle: intl.get('LBL_MESSAGING_HISTORY_DELIVERY_STATUS'),
        Header: CollectIdsHistoryHeaderCell,
        accessor: 'status',
        className: 'text-bold text-right',
        disableSortBy: handleDisableSorting('status'),
        Cell: renderDeliveryStatus,
        helpText: intl.get('LBL_SURVEYS_HISTORY_DELIVERY_STATUS_HINT'),
      },
      {
        dataTitle: intl.get(
          'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_TARGETED'
        ),
        Header: CollectIdsHistoryHeaderCell,
        accessor: 'receiverStats.targetedCount',
        headerClassName: 'text-center',
        className: 'text-bold text-center',
        helpText: intl.get(
          'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_TARGET_HINT'
        ),
        disableSortBy: handleDisableSorting('receiverStats.targetedCount'),
        Cell: ({ value }): string => value ?? intl.get('LBL_NA'),
      },
      {
        id: 'receiverStats.receivedCount',
        dataTitle: intl.get(
          'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_RECEIVED'
        ),
        Header: CollectIdsHistoryHeaderCell,
        accessor: (row): string => {
          const { receivedCount, targetedCount } = row.receiverStats;
          if (
            typeof receivedCount === 'number' &&
            typeof targetedCount === 'number'
          ) {
            return getFormattedNumber(
              (receivedCount / targetedCount) * 100,
              false
            );
          }
          return intl.get('LBL_NA');
        },
        className: 'text-bold text-center',
        helpText: intl.get(
          'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_RECEIVED_HINT'
        ),
        disableSortBy: handleDisableSorting('receiverStats.receivedCount'),
        Cell: renderReceived,
      },
      {
        dataTitle: intl.get(
          'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_STATUS'
        ),
        Header: CollectIdsHistoryHeaderCell,
        accessor: 'activeCollectId',
        className: 'text-bold text-center',
        disableSortBy: true,
        Cell: renderStatus,
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        className: 'text-bold text-right',
        Cell: renderActions,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, statusReloadRow]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      getRowId: (row: SurveysHistoryViewModel) => row.id,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: initialPage,
        sortBy: initialSortBy,
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      autoResetPage: false,
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetHiddenColumns: false,
      autoResetSelectedRows: false,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const initialSortByValue = initialSortBy[0];

  const initSortId = initialSortByValue
    ? initialSortByValue.id
    : TableConfig.defaultSort;
  const initSortDesc = initialSortByValue
    ? initialSortByValue.desc
    : TableConfig.defaultSortDesc;

  useEffect(() => {
    const newSortBy = [{ id: initSortId, desc: initSortDesc }];
    setSortBy(newSortBy);
  }, [initSortDesc, initSortId, setSortBy]);

  useEffect(() => {
    gotoPage(initialPage);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPage]);

  useEffect(() => {
    setPageSize(initialPageSize);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageSize]);

  /**
   * This is a fix for an edge case where the backend sends an incorrect
   * pagination total (total users count) and there are no rows (list items)
   * when the user navigates to a new page (eg. actual row count and pageSize
   * are both 10, whereas the pagination total shows 11, and the user navigates
   * to the 2nd page).
   * In cases like this, the user will be navigated to the previous page.
   */
  useEffect(() => {
    const count = page.length;
    if (count === 0) {
      if (hasPrevious) {
        gotoPage(pageIndex - 1);
      } else {
        gotoPage(0);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [page]);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [data]);

  /**
   * Handle sortBy change event
   *
   * @param event Column header click event
   */
  const handleChangeSortBy = (
    event: React.MouseEvent<HTMLSpanElement>
  ): void => {
    const dataSet = event.currentTarget.dataset;
    if (dataSet.sort) {
      const { id, canSort, isSorted, isSortedDesc, sortDescFirst } = JSON.parse(
        dataSet.sort
      );
      if (canSort && id !== null && id !== undefined) {
        onSortByChange([
          {
            id,
            desc: isSorted ? !isSortedDesc : sortDescFirst,
          },
        ]);
      }
    }
  };

  /**
   * Render CollectId table row
   *
   * @param column Column data
   * @returns Table header row elements
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderCollectIdHeaderRow = (column: any): JSX.Element => {
    if (column.id === 'selection') {
      return <th key={column.id} {...column.getHeaderProps()} />;
    }
    return (
      <th
        key={column.id}
        {...column.getHeaderProps({
          className: column.headerClassName ?? column.className,
        })}
      >
        <span
          data-sort={JSON.stringify({
            id: column.id,
            canSort: column.canSort,
            isSorted: column.isSorted,
            isSortedDesc: column.isSortedDesc,
            sortDescFirst: column.sortDescFirst,
          })}
          className="table-label text-12-bold text-uppercase"
          {...column.getSortByToggleProps({
            title: '',
            onClick: handleChangeSortBy,
          })}
        >
          {column.render('Header', {
            meta: {
              total,
              showCollectIdGuideTooltip,
              containerRef,
            },
          })}
        </span>
      </th>
    );
  };

  /**
   * Render CollectId table
   *
   * @returns Table elements
   */
  const renderCollectIdTable = (): JSX.Element => {
    if (status === Status.Loading) {
      return (
        <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
          <Skeleton height={40} />
          <Skeleton height={62} count={10} />
        </SkeletonTheme>
      );
    }
    return (
      <PerfectScrollbar>
        <table
          className={`insight-table ${styles.table}`}
          data-testid="collect-id-history-list"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) =>
                  renderCollectIdHeaderRow(column)
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, index) => (
                <CollectIdsHistoryRow
                  key={index}
                  prepareRow={prepareRow}
                  row={row}
                />
              ))
            ) : (
              <DummyRow
                headers={headerGroups}
                isError={status === Status.Error}
              />
            )}
          </tbody>
        </table>
      </PerfectScrollbar>
    );
  };

  const isEmptyError =
    status !== Status.Loading && (status === Status.Error || data.length <= 0);

  return (
    <div className="insight-table-container pt-0">
      {renderCollectIdTable()}
      {isEmptyError && (
        <div className={`no-data-message ${styles.emptyAlert}`}>
          <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
          <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
          <hr className="divider" />
          <Row className="w-100 justify-content-center m-0">
            <button className="btn btn-secondary" onClick={onRefetchData}>
              <i className="icon-refresh" />
              {intl.get('BTN_SURVEYS_DRAFTED_EMPTY_RETRY')}
            </button>
          </Row>
        </div>
      )}
      <OffsetPaginationFooter
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        gotoNextPage={(): void => onPageChange(true)}
        gotoPreviousPage={(): void => onPageChange(false)}
        pageSize={initialPageSize}
        totalResults={total}
        loading={status === Status.Loading}
        pageIndex={initialPage}
        setPageSize={onPageSizeChange}
        pageSizeDisabled={isEmptyError}
      />
    </div>
  );
};

export default CollectIdsHistoryDataTable;
