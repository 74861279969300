import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import { GroupStatusDataItem } from 'context/AppProviderState';
import MessagingFilter from 'shared/enums/MessagingFilter';
import Status from 'shared/enums/Status';

import GroupsFilter from '../groups-filter/GroupsFilter';
import GroupStatusFilterProps from './GroupStatusFilterProps';

const GroupStatusFilter: ReactFC<GroupStatusFilterProps> = (
  props: GroupStatusFilterProps
) => {
  const { groupStatusData, isDisabled, selected, onChange } = props;

  const groupStatusesEmpty = Object.values(groupStatusData.data).length <= 0;

  const groupStatusesModded =
    groupStatusData.status !== Status.Loading && groupStatusesEmpty
      ? []
      : Object.values(groupStatusData.data).map(
          (groupStatus: GroupStatusDataItem) => ({
            value: groupStatus.id,
            label: groupStatus.status,
          })
        );

  return (
    <GroupsFilter
      title={intl.get('LBL_TITLE_GROUP_STATUS')}
      data={groupStatusesModded}
      onChange={(e, label: string): void =>
        onChange(e, MessagingFilter.GroupStatus, label)
      }
      isDisabled={isDisabled}
      selected={selected}
    />
  );
};

export default GroupStatusFilter;
