/* eslint-disable react/prefer-stateless-function */
import { Component } from 'react';

import axios from 'axios';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, Col, Row } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/types/ApiError';
import DashboardApiInstance from 'api/dashboard/DashboardApi';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import StorageKeys from 'constants/StorageKeys';
import { getFormattedNumber } from 'helpers/NumberFormat';
import PermissionUtil from 'helpers/PermissionUtil';
import setPageTitle from 'helpers/setPageTitle';
import DefaultCurrencyRow from 'modules/private/settings/components/default-currency-row/DefaultCurrencyRow';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import InsLink from 'shared/components/ins-link/InsLink';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import ExchangeRate from 'shared/enums/ExchangeRate';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import noAssignedProject from 'shared/static/img/password_new.svg';

import styles from './myOrgView.module.scss';
import MyOrgViewProps from './MyOrgViewProps';
import MyOrgViewState from './MyOrgViewState';
import OrganizationInfoViewModel from './OrganizationInfoViewModel';

class MyOrgView extends Component<MyOrgViewProps, MyOrgViewState> {
  constructor(props: MyOrgViewProps) {
    super(props);

    const { permissionsData } = props.appContext;
    const { claims } = permissionsData;

    const canViewOrgName = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsMyOrgOrgName
    );
    const canViewLinkedGroups = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsMyOrgLinkedSavingsGroups
    );
    const canEditDefaultCurrency = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsMyOrgDefaultCurrency
    );

    const organizationId = AuthStorageService.GetItem<string>(
      StorageKeys.OrganizationId
    );
    this.state = {
      organizationId,
      organizationInfo: new OrganizationInfoViewModel(),
      organizationInfoStatus: Status.Loading,
      canViewOrgName,
      canViewLinkedGroups,
      canEditDefaultCurrency,
      defaultCurrency: {
        status: Status.Idle,
        error: '',
        data: [],
        isCurrencyEditInProgress: false,
      },
    };
  }

  componentDidMount(): void {
    const { appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('BTN_SETTINGS'));
    this.fetchOrganizationInfo();
    this.fetchDefaultCurrencies();
    // this.fetchDefaultCurrencySettings();
  }

  componentDidUpdate(prevProps): void {
    const claims = get(this.props, 'appContext.permissionsData.claims');
    if (!isEqual(get(prevProps, 'appContext.permissionsData.claims'), claims)) {
      const canViewOrgName = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsMyOrgOrgName
      );
      const canViewLinkedGroups = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsMyOrgLinkedSavingsGroups
      );
      const canEditDefaultCurrency = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsMyOrgDefaultCurrency
      );
      this.updatePermissions(
        canViewOrgName,
        canViewLinkedGroups,
        canEditDefaultCurrency
      );
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Set/Update component state in the defaultCurrency slice
   *
   * @param updateState Updated state as an object
   */
  setDefaultCurrencyStateAttribute = (
    updateState: Partial<MyOrgViewState['defaultCurrency']>
  ): void =>
    this.setState((state) => ({
      ...state,
      defaultCurrency: { ...state.defaultCurrency, ...updateState },
    }));

  /**
   * Updates permissions for screen
   *
   * @param canViewOrgName Whether the user can view the organization name
   * @param canViewLinkedGroups Whether the user can view linked groups
   */
  updatePermissions = (
    canViewOrgName: boolean,
    canViewLinkedGroups: boolean,
    canEditDefaultCurrency: boolean
  ): void => {
    this.setState({
      canViewOrgName,
      canViewLinkedGroups,
      canEditDefaultCurrency,
    });
  };

  /**
   * Toggle default currency edit state
   */
  toggleCurrencyEdit = (): void => {
    const {
      defaultCurrency: { isCurrencyEditInProgress: current },
    } = this.state;
    this.setDefaultCurrencyStateAttribute({
      isCurrencyEditInProgress: !current,
    });
  };

  /**
   * Set the default currency save status
   *
   * @param status New status
   */
  setDefaultCurrencyStatus = (status: Status): void =>
    this.setDefaultCurrencyStateAttribute({ status });

  /**
   * Fetch organization data
   */
  fetchOrganizationInfo = async (): Promise<void> => {
    let { organizationId } = this.state;
    const { appContext } = this.props;
    this.setState({ organizationInfoStatus: Status.Loading });
    try {
      if (!organizationId) {
        const { organizationId: orgId } = await AuthApiInstance.GetUserInfo();
        organizationId = orgId;
        this.setState({ organizationId });
      }
      const response = await SettingsApiInstance.GetOrganizationInfo(
        organizationId,
        this.source
      );
      if (response.item) {
        this.setState({
          organizationInfoStatus: Status.Success,
          organizationInfo: response.item,
        });
      }
    } catch (error) {
      this.setState({
        organizationInfoStatus: Status.Error,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch default organization currencies
   *
   * @param query Search query
   */
  fetchDefaultCurrencies = async (query?: string): Promise<void> => {
    const { organizationId } = this.state;
    const { appContext } = this.props;
    this.setDefaultCurrencyStateAttribute({ status: Status.Loading });
    try {
      if (organizationId) {
        const response =
          await SettingsApiInstance.GetOrganizationDefaultCurrencies(
            organizationId,
            this.source,
            query
          );
        this.setDefaultCurrencyStateAttribute({
          status: Status.Success,
          data: response.items,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setDefaultCurrencyStateAttribute({
        status: Status.Error,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_SETTINGS_DEFAULT_CURRENCIES_FAILURE')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_SETTINGS_DEFAULT_CURRENCIES_FAILURE')
        );
      }
    }
  };

  /**
   * Set default organization currency settings
   *
   * @param code Selected currency code
   * @param rate Selected exchange rate mode
   */
  setDefaultCurrencySettings = async (
    code: string,
    rate: ExchangeRate
  ): Promise<void> => {
    const { organizationId } = this.state;
    const { appContext } = this.props;
    this.setDefaultCurrencyStateAttribute({ status: Status.Loading });
    try {
      if (organizationId) {
        await SettingsApiInstance.SetOrganizationCurrencySettings(
          organizationId,
          {
            currencyCode: code,
            exchangeRateMode: rate,
          },
          this.source
        );
        appContext.getUserInfo().then(() => {
          appContext.validateCurrencyToggle(appContext.globalFilters);
          appContext.setCurrencySettings({
            currentDefaultCurrency: code,
            currentExchangeRate: rate,
            settingsStatus: Status.Idle,
          });
        });
        this.toggleCurrencyEdit();
        this.setDefaultCurrencyStateAttribute({
          status: Status.Success,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setDefaultCurrencyStateAttribute({
        status: Status.Idle,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Handle downloading the getting started guide as PDF
   */
  handleLinkGroups = async (): Promise<void> => {
    const response = await DashboardApiInstance.GetGettingStartedGuide(false);
    if (response.item) {
      const { link: pdfLink } = response.item;
      const downloadLink = document.createElement('a');
      downloadLink.href = pdfLink;
      downloadLink.download = 'guide.pdf';
      downloadLink.target = '_blank';
      downloadLink.dispatchEvent(new MouseEvent('click'));
    }
  };

  render(): JSX.Element {
    const { appContext } = this.props;
    const {
      organizationInfoStatus,
      organizationInfo,
      canViewLinkedGroups,
      canViewOrgName,
      canEditDefaultCurrency,
      defaultCurrency,
    } = this.state;

    const linkedGroupsWarning =
      organizationInfoStatus !== Status.Loading &&
      organizationInfo.numberOfGroups <= 0;

    if (organizationInfoStatus === Status.Error) {
      return (
        <div className="content-container">
          <Row>
            <Col xs="12">
              <h3 className="text-18-semibold mb-0">
                {intl.get('LBL_ORG_MY_ORG_INFO')}
              </h3>
            </Col>
            <Col xs="12" className={styles.divider}>
              <hr className="divider" />
            </Col>
            <div className="no-data-message">
              <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
              <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
            </div>
          </Row>
        </div>
      );
    }

    return (
      <div className="content-container">
        <ScrollToTopOnMount />
        <Row>
          <Col xs="12">
            <h3 className="text-18-semibold mb-0">
              {intl.get('LBL_ORG_MY_ORG_INFO')}
            </h3>
          </Col>
          <Col xs="12" className={styles.divider}>
            <hr className="divider" />
          </Col>
          {organizationInfoStatus === Status.Loading ? (
            <Col xs="12">
              <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
                <Skeleton className={styles.skeleton} count={2} />
              </SkeletonTheme>
            </Col>
          ) : (
            <>
              {canViewOrgName && (
                <Col xs="12">
                  <div className={styles.item}>
                    <Row className="align-items-center">
                      <Col xs="3">
                        <p className="truncate text-16-semibold text-primary">
                          {intl.get('LBL_ORG_ORG_NAME')}
                        </p>
                      </Col>
                      <Col xs="3">
                        <EllipsisTooltip
                          tag="p"
                          data-place="bottom"
                          data-for="insTooltip"
                          data-class="overflow-wrap"
                          data-tip={organizationInfo.name}
                          className="truncate text-14-medium"
                        >
                          {organizationInfo.name}
                        </EllipsisTooltip>
                      </Col>
                      <Col xs="6">
                        <p className="text-14-medium">
                          {intl.get('LBL_ORG_PARTNER_ID', {
                            id: organizationInfo.partnerId,
                          })}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
              {canViewLinkedGroups && (
                <Col xs="12">
                  <div
                    className={`${styles.item} ${
                      linkedGroupsWarning ? styles.error : ''
                    }`}
                  >
                    <Row className="align-items-center">
                      <Col xs="3">
                        <p className="truncate text-16-semibold text-primary">
                          {intl.get('LBL_ORG_SAVINGS_GROUPS')}
                        </p>
                      </Col>
                      <Col xs="3">
                        {linkedGroupsWarning ? (
                          <p className="truncate text-14-medium">
                            {intl.get('LBL_ORG_GROUPS_LINKED', { count: 0 })}
                          </p>
                        ) : (
                          <InsLink
                            to={ModulePaths.GroupsPath}
                            className="truncate text-14-medium"
                          >
                            {intl.get('LBL_ORG_GROUPS_LINKED', {
                              count: getFormattedNumber(
                                organizationInfo.numberOfGroups,
                                false
                              ),
                            })}
                          </InsLink>
                        )}
                      </Col>
                      <Col xs="3">
                        {linkedGroupsWarning && (
                          <EllipsisTooltip
                            tag="p"
                            data-place="bottom"
                            data-for="insTooltip"
                            data-tip={intl.get('LBL_ORG_NO_GROUPS_WARNING')}
                            data-class="error overflow-wrap"
                            className="truncate text-14-medium"
                          >
                            <i className="icon-warning text-orange mr-3 icon-text-22" />
                            {intl.get('LBL_ORG_NO_GROUPS_WARNING')}
                          </EllipsisTooltip>
                        )}
                      </Col>
                      <Col xs="3" className="d-flex justify-content-end">
                        {linkedGroupsWarning ? (
                          <Button
                            className={`btn custom-focus btn-sm ${styles.btn}`}
                            onClick={this.handleLinkGroups}
                          >
                            {intl.get('BTN_ORG_LINK_GROUPS')}
                          </Button>
                        ) : (
                          <InsLink
                            className={`btn btn-sm ${styles.btn}`}
                            to={ModulePaths.GroupsPath}
                          >
                            <i className="icon-plus" />
                            {intl.get('BTN_ORG_VIEW_GROUPS')}
                          </InsLink>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
              <DefaultCurrencyRow
                status={defaultCurrency.status}
                error={defaultCurrency.error}
                defaultCurrencyData={defaultCurrency.data}
                currentDefaultCurrency={
                  appContext.currencySettings.currentDefaultCurrency
                }
                currentExchangeRate={
                  appContext.currencySettings.currentExchangeRate
                }
                isCurrencyEditInProgress={
                  defaultCurrency.isCurrencyEditInProgress
                }
                canEditDefaultCurrency={canEditDefaultCurrency}
                setStatus={this.setDefaultCurrencyStatus}
                getDefaultCurrencyData={this.fetchDefaultCurrencies}
                onSaveCurrencyInformation={this.setDefaultCurrencySettings}
                toggleCurrencyEdit={this.toggleCurrencyEdit}
              />
            </>
          )}
        </Row>
        {organizationInfoStatus === Status.Loading ? (
          <Col xs="12">
            <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
              <Skeleton className={styles.skeleton} count={5} />
            </SkeletonTheme>
          </Col>
        ) : (
          <div className="overlay-coming-soon-with-backdrop position-relative">
            <Row className="align-items-center">
              <Col xs="12">
                <div className={styles.item}>
                  <Row className="align-items-center">
                    <Col xs="3">
                      <p className="truncate text-16-semibold text-primary">
                        {intl.get('LBL_ORG_BILLING_PAYMENT')}
                      </p>
                    </Col>
                    <Col xs="3">
                      <p className="truncate text-14-medium" />
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end">
                      <Button size="sm" disabled>
                        <i className="icon-plus" />
                        {intl.get('BTN_ORG_EDIT')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs="12">
                <div className={styles.item}>
                  <Row className="align-items-center">
                    <Col xs="3">
                      <p className="truncate text-16-semibold text-primary">
                        {intl.get('LBL_ORG_MY_PLAN')}
                      </p>
                    </Col>
                    <Col xs="3">
                      <p className="truncate text-14-medium" />
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end">
                      <Button size="sm" disabled>
                        <i className="icon-plus" />
                        {intl.get('BTN_ORG_EDIT')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs="12">
                <div className={styles.item}>
                  <Row className="align-items-center">
                    <Col xs="3">
                      <p className="truncate text-16-semibold text-primary">
                        {intl.get('LBL_ORG_IMPLEMENTING_PARTNERS')}
                      </p>
                    </Col>
                    <Col xs="3">
                      <p className="truncate text-14-medium" />
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end">
                      <Button size="sm" disabled>
                        <i className="icon-plus" />
                        {intl.get('BTN_ORG_EDIT')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs="12">
                <div className={styles.item}>
                  <Row className="align-items-center">
                    <Col xs="3">
                      <p className="truncate text-16-semibold text-primary">
                        {intl.get('LBL_ORG_GROUP_STATUS')}
                        <span className="help">
                          <i
                            className="icon-help"
                            data-for="insTooltip"
                            data-tip=""
                          />
                        </span>
                      </p>
                    </Col>
                    <Col xs="3" className="d-flex align-items-center">
                      <p className="truncate text-14-medium" />
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end">
                      <Button size="sm" disabled>
                        <i className="icon-plus" />
                        {intl.get('BTN_ORG_EDIT')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <div
              className={`${styles.empty} position-absolute w-100 d-flex justify-content-center align-items-center`}
            >
              <Row>
                <Col xs="12" className="text-center">
                  <h2 className="mb-4">
                    {intl.get('LBL_SETTINGS_EMPTY_VIEW_TITLE')}
                  </h2>
                </Col>
                <Col xs="12">
                  <img
                    loading="eager"
                    alt="Nothing to see"
                    src={noAssignedProject}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default MyOrgView;
