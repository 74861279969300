/* eslint-disable no-useless-escape */
import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js/min';
import * as intl from 'react-intl-universal';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import Constraints from 'constants/forms/Constraints';

class CreateProfileValidation {
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema = yup.object().shape({
      firstName: yup
        .string()
        .required(intl.get('ERR_REG_FORM_FIRST_NAME_CANNOT_BE_EMPTY')),
      lastName: yup
        .string()
        .required(intl.get('ERR_REG_FORM_LAST_NAME_CANNOT_BE_EMPTY')),
      phoneNumber: yup
        .string()
        .required(intl.get('ERR_CP_PHONE_NUMBER_EMPTY'))
        .test(
          'isValid',
          intl.get('ERR_CP_PHONE_NUMBER_INVALID'),
          function validNumber(value: string) {
            try {
              const parsed = parseMin(value);
              if (parsed) {
                return parsed.isValid();
              }
              return false;
            } catch (error) {
              return false;
            }
          }
        ),
      password: yup
        .string()
        .required(intl.get('ERR_FORM_PASSWORD_REQUIRED_MESSAGE'))
        .matches(
          new RegExp(
            `^(?=.*\\d)(?=.*[!"#$%&'()*+,./:;<=>?@\^_{|}~-])(?=.*[a-z])(?=.*[A-Z]).{4,}$`
          ),
          intl.get('ERR_REG_FORM_PASSWORD_STRENGTH_MESSAGE')
        ),
      termsAccepted: yup
        .boolean()
        .oneOf([true], intl.get('ERR_REG_FORM_TERMS_SHOULD_ACCEPT')),
      image: yup
        .mixed()
        .test(
          'fileSize',
          intl.get('ERR_CP_FILE_TOO_LARGE', { mb: Constraints.MaxImageSizeMb }),
          function testSize(value): boolean | ValidationError {
            if (value) {
              if (value.size) {
                return value.size <= Constraints.MaxImageSize;
              }
              return new ValidationError(
                intl.get('ERR_CP_FILE_TOO_LARGE', {
                  mb: Constraints.MaxImageSizeMb,
                }),
                value,
                this.path
              );
            }
            return true;
          }
        )
        .test(
          'fileFormat',
          intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'),
          function testFormat(value): boolean | ValidationError {
            if (value) {
              if (value.name) {
                const imageNameParts = (value.name as string).split('.');
                const extension = imageNameParts.pop();
                return extension
                  ? Constraints.SupportedImageFormats.includes(extension)
                  : true;
              }
              if (value.type) {
                return Constraints.SupportedImageFormats.includes(value.type);
              }
              return new ValidationError(
                intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'),
                value,
                this.path
              );
            }
            return true;
          }
        ),
    });
    return validationSchema;
  }
}

export default CreateProfileValidation;
