import { FormikProps } from 'formik';

export enum ForgotPasswordEmailFields {
  EMAIL = 'email',
}

interface ForgotPasswordEmailProps {
  formikProps: FormikProps<{ email: string }>;
}

export default ForgotPasswordEmailProps;
