import {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
  FC as ReactFC,
} from 'react';

import * as intl from 'react-intl-universal';
import { Popover, PopoverBody } from 'reactstrap';

import AppContext from 'context/AppContext';
import { getFormattedNumber } from 'helpers/NumberFormat';
import PopperResizeContent from 'shared/components/hoc/popper-resize-content/PopperResizeContent';
import Status from 'shared/enums/Status';
import clearIcon from 'shared/static/img/ic_x.svg';

import AssignFacilitatorMenu from '../../../assign-facilitator-menu/AssignFacilitatorMenu';
import AssignGroupStatusMenu from '../../../assign-group-status-menu/AssignGroupStatusMenu';
import AssignProjectMenu from '../../../assign-project-menu/AssignProjectMenu';
import styles from './groupActionBar.module.scss';
import GroupActionBarProps from './GroupActionBarProps';

const GroupActionBar: ReactFC<GroupActionBarProps> = (
  props: GroupActionBarProps
) => {
  const {
    currentSelectedRows,
    facilitators,
    facilitatorsStatus,
    facilitatorsError,
    groupStatGroupsAssigned,
    assignToProjectStatus,
    assignToProjectError,
    assignFacilitatorsStatus,
    assignFacilitatorsError,
    assignGroupsStatusStatus,
    assignGroupsStatusError,
    totalResults,
    fetchFacilitators,
    setUnselectAll,
    setSelectAll,
    onAssignToProject,
    onAssignGroupsFacilitators,
    onAssignGroupsStatus,
    setAssignGroupsStatusStatus,
    setAssignToProjectStatus,
    setAssignFacilitatorsStatus,
  } = props;

  const appContext = useContext(AppContext);
  const { projectListData, getProjectList, getGroupStatuses, groupStatusData } =
    appContext;

  const noSelection = currentSelectedRows.length <= 0;

  const guideContainer = useRef<HTMLSpanElement | null>(null);
  const breakPointeContainer = useRef<HTMLDivElement | null>(null);
  const [guideOpen, setGuideOpen] = useState<boolean>(false);

  const [projectsOpen, setProjectsOpen] = useState<boolean>(false);
  const [facilitatorsOpen, setFacilitatorsOpen] = useState<boolean>(false);
  const [groupStatusOpen, setGroupStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    if (assignToProjectStatus === Status.Success) {
      setProjectsOpen(false);
    }
  }, [assignToProjectStatus]);

  useEffect(() => {
    if (assignFacilitatorsStatus === Status.Success) {
      setFacilitatorsOpen(false);
    }
  }, [assignFacilitatorsStatus]);

  useEffect(() => {
    if (assignGroupsStatusStatus === Status.Success) {
      setGroupStatusOpen(false);
    }
  }, [assignGroupsStatusStatus]);

  useEffect(() => {
    if (currentSelectedRows.length > 0) {
      setGuideOpen(true);
    } else {
      setGuideOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentSelectedRows)]);
  /**
   * maintain projects ToggleLayer open status
   */
  const handleCloseProjects = (): void => setProjectsOpen(false);

  /**
   * maintain projects ToggleLayer open status
   * @param {() => void} toggle toggle handler form ToggleLayer
   */
  const handleOpenProjects = (): void => {
    setProjectsOpen((isOpen) => !isOpen);
  };

  /**
   * maintain facilitators ToggleLayer open status
   */
  const handleCloseFacilitators = (): void => setFacilitatorsOpen(false);

  /**
   * maintain facilitators ToggleLayer open status
   * @param {() => void} toggle toggle handler form ToggleLayer
   */
  const handleOpenFacilitators = (): void =>
    setFacilitatorsOpen((isOpen) => !isOpen);

  /**
   * maintain group status ToggleLayer open status
   */
  const handleCloseGroupStatus = (): void => setGroupStatusOpen(false);

  /**
   * maintain groups status ToggleLayer open status
   * @param {() => void} toggle toggle handler form ToggleLayer
   */
  const handleOpenGroupStatus = (): void =>
    setGroupStatusOpen((isOpen) => !isOpen);

  const toggleGuide = (isOpen?: boolean): void => {
    setGuideOpen((open) => isOpen ?? !open);
  };

  /**
   * show tooltip when @param groupStatGroupsAssigned=false only
   * meaning the user has used the action bar before to assign
   * projects, facilitators and group statuses
   */
  const renderFistTimeGuide = (): JSX.Element => (
    <div style={{ visibility: 'hidden' }}>
      {groupStatGroupsAssigned === false && (
        <Popover
          placement="top"
          isOpen={guideOpen}
          container={guideContainer}
          hideArrow
          modifiers={{
            preventOverflow: { enabled: true, escapeWithReference: true },
            flip: { enabled: false },
          }}
          toggle={toggleGuide}
          trigger="manual"
          popperClassName="project-popper groups-popper action-bar-popper"
          target="selectedGroups"
          delay={{ show: 500, hide: 100 }}
        >
          {({ scheduleUpdate }): JSX.Element => (
            <PopperResizeContent
              observeElement={breakPointeContainer}
              onResize={scheduleUpdate}
            >
              <PopoverBody>
                <div>
                  <div className="text-center text-gray">
                    <h4 className="text-14-semibold">
                      {intl.get('LBL_GD_ACTION_BAR_GUIDE_TITLE')}
                    </h4>
                    <span className="text-14-light">
                      {intl.get('LBL_GD_ACTION_BAR_GUIDE_SUB')}
                    </span>
                  </div>
                  <div className="close-container invert">
                    <button
                      className="insight-close-button"
                      type="button"
                      onClick={(): void => toggleGuide(false)}
                    >
                      <img src={clearIcon} alt="Close" />
                    </button>
                  </div>
                </div>
              </PopoverBody>
            </PopperResizeContent>
          )}
        </Popover>
      )}
    </div>
  );

  const isAllSelected = currentSelectedRows.length === totalResults;

  return (
    <>
      <div
        ref={breakPointeContainer}
        className={`selected-row-info ${styles.bar}`}
      >
        <div className="row-col">
          <div className="col-text" style={{ textTransform: 'capitalize' }}>
            {intl.get('LBL_GROUPS_SELECTED')}{' '}
            <span
              className="color-secondary"
              id="selectedGroups"
              ref={guideContainer}
            >
              ({`${getFormattedNumber(currentSelectedRows.length ?? 0, false)}`}
              )
            </span>
          </div>
          <button
            className={`col-text text-14-medium border-0 color-white bg-transparent ${
              isAllSelected ? 'col-disable' : 'col-link'
            }`}
            disabled={isAllSelected}
            onClick={setSelectAll}
          >
            {intl.get('BTN_GROUPS_SELECT_ALL')}
          </button>
          <button
            className={`col-text text-14-medium border-0 color-white bg-transparent ${
              noSelection ? 'col-disable' : 'col-link'
            }`}
            disabled={noSelection}
            onClick={setUnselectAll}
          >
            {intl.get('BTN_GROUPS_UNSELECT_ALL')}
          </button>
        </div>
        <div className="row-col">
          <AssignProjectMenu
            isProjectsOpen={projectsOpen}
            assignToProjectStatus={assignToProjectStatus}
            projectListData={projectListData}
            assignToProjectError={assignToProjectError}
            onCloseProjects={handleCloseProjects}
            onAssignToProject={onAssignToProject}
            getProjectList={getProjectList}
            setAssignToProjectStatus={setAssignToProjectStatus}
          >
            {({ triggerRef }): ReactNode => (
              <button
                ref={triggerRef}
                disabled={noSelection}
                onClick={handleOpenProjects}
                className="btn btn-secondary"
              >
                <i className="icon-plus" />
                {intl.get('BTN_ASSIGN_TO_PROJECT')}
              </button>
            )}
          </AssignProjectMenu>
          <AssignFacilitatorMenu
            isFacilitatorsOpen={facilitatorsOpen}
            facilitators={facilitators}
            facilitatorsStatus={facilitatorsStatus}
            facilitatorsError={facilitatorsError}
            assignFacilitatorsStatus={assignFacilitatorsStatus}
            assignFacilitatorsError={assignFacilitatorsError}
            fetchFacilitators={fetchFacilitators}
            onCloseFacilitators={handleCloseFacilitators}
            onAssignGroupsFacilitators={onAssignGroupsFacilitators}
            setAssignFacilitatorsStatus={setAssignFacilitatorsStatus}
          >
            {({ triggerRef }): ReactNode => (
              <button
                ref={triggerRef}
                disabled={noSelection}
                className="btn btn-secondary"
                onClick={handleOpenFacilitators}
              >
                <i className="icon-plus" />
                {intl.get('BTN_ASSIGN_TO_FACILITATOR')}
              </button>
            )}
          </AssignFacilitatorMenu>
          <AssignGroupStatusMenu
            groupStatusOpen={groupStatusOpen}
            assignGroupsStatusStatus={assignGroupsStatusStatus}
            assignGroupsStatusError={assignGroupsStatusError}
            getGroupStatuses={getGroupStatuses}
            groupStatusData={groupStatusData}
            onCloseGroupStatus={handleCloseGroupStatus}
            onAssignGroupsStatus={onAssignGroupsStatus}
            setAssignGroupsStatusStatus={setAssignGroupsStatusStatus}
          >
            {({ triggerRef }): ReactNode => (
              <button
                ref={triggerRef}
                disabled={noSelection}
                className="btn btn-secondary"
                onClick={handleOpenGroupStatus}
              >
                <i className="icon-plus" />
                {intl.get('BTN_ASSIGN_GROUP_STATUS')}
              </button>
            )}
          </AssignGroupStatusMenu>
        </div>
      </div>
      {renderFistTimeGuide()}
    </>
  );
};

export default GroupActionBar;
