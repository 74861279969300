enum GroupWidgetType {
  Age = 'AGE',
  AverageSavings = 'AVERAGE_SAVINGS_PER_MEMBER',
  CumulativeSavings = 'CUMULATIVE_SAVINGS',
  Education = 'EDUCATION',
  Gender = 'GENDER',
  PersonalGoalAchievement = 'PERSONAL_GOAL_ACHIEVEMENT',
  LoanFund = 'LOAN_FUND_UTILIZATION',
  PersonalGoalType = 'PERSONAL_GOAL_TYPES',
  LoanType = 'LOAN_TYPES',
  MemberChildren = 'MEMBER_CARING',
  AdditionalFunds = 'ADDITIONAL_FUND',
  Placeholder = 'PLACEHOLDER', // Used internally in frontend.
}

export default GroupWidgetType;
