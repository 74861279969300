/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useEffect, useRef, useState } from 'react';

import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import ReactTooltip from 'react-tooltip';

import mergeRefs from 'helpers/mergeRefs';

import EllipsisTooltipProps, {
  CommonTooltipElements,
} from './EllipsisTooltipProps';

/**
 * Verify whether the element is truncated
 *
 * @param element Element reference
 * @param clamp Whether to clamp the element
 * @returns {boolean} Whether the element is truncated
 */
const isTruncated = (element, clamp): boolean => {
  if (element) {
    if (clamp) {
      return element.scrollHeight > element.clientHeight;
    }
    return element.scrollWidth > element.clientWidth;
  }
  return false;
};

const EllipsisTooltip = forwardRef<CommonTooltipElements, EllipsisTooltipProps>(
  (props: EllipsisTooltipProps, ref) => {
    const tagRef = useRef<CommonTooltipElements>(null);

    const {
      tag,
      'data-for': dataFor,
      'data-tip': dataTip,
      'data-place': dataPlace,
      'data-type': dataType,
      'data-effect': dataEffect,
      'data-event': dataEvent,
      'data-event-off': dataEventOff,
      'data-offset': dataOffset,
      'data-iscapture': dataIsCapture,
      'data-class': dataClass,
      'data-html': dataHTML,
      'data-delay-hide': dataDelayHide,
      'data-delay-show': dataDelayShow,
      'data-border': dataBorder,
      'data-text-color': dataTextColor,
      'data-background-color': dataBackgroundColor,
      'data-border-color': dataBorderColor,
      'data-arrow-color': dataArrowColor,
      'data-tip-disable': dataTipDisable,
      'data-scroll-hide': dataScrollHide,
      'data-delay-update': dataDelayUpdate,
      'data-multiline': dataMultiline,
      clamp,
      forceRebuild,
      ...rest
    } = props;

    let { tag: Tag } = props;
    Tag = Tag ?? 'span';

    const [tip, setTip] = useState<string | number>('');

    useEffect(() => {
      if (forceRebuild) {
        ReactTooltip.rebuild();
      }
    });

    useEffect(() => {
      setTip(isTruncated(tagRef.current, clamp) ? dataTip : '');
    }, [clamp, dataTip]);

    const dataAttributes = omitBy(
      {
        'data-place': dataPlace,
        'data-for': dataFor,
        'data-tip': tip,
        'data-multiline': dataMultiline,
        'data-type': dataType,
        'data-effect': dataEffect,
        'data-event': dataEvent,
        'data-event-off': dataEventOff,
        'data-offset': dataOffset,
        'data-iscapture': dataIsCapture,
        'data-class': dataClass,
        'data-html': dataHTML,
        'data-delay-hide': dataDelayHide,
        'data-delay-show': dataDelayShow,
        'data-border': dataBorder,
        'data-text-color': dataTextColor,
        'data-background-color': dataBackgroundColor,
        'data-border-color': dataBorderColor,
        'data-arrow-color': dataArrowColor,
        'data-tip-disable': dataTipDisable,
        'data-scroll-hide': dataScrollHide,
        'data-delay-update': dataDelayUpdate,
      },
      isNil
    );

    /**
     * Handle mouse enter event
     *
     * @param event Mouse enter event
     */
    const handleMouseEnter = (event: MouseEvent): void => {
      setTip(isTruncated(event.currentTarget, clamp) ? dataTip : '');
    };

    return (
      <Tag
        key={tip}
        {...rest}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={mergeRefs([tagRef, ref])}
        onMouseEnter={handleMouseEnter}
        {...dataAttributes}
      />
    );
  }
);

export default EllipsisTooltip;
