import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Button, Col, Fade, Row } from 'reactstrap';

import { SurveyChangeViewPoint } from 'modules/private/surveys/containers/surveys-draft-view/SurveysDraftViewState';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';

import styles from '../surveysPopups.module.scss';
import SurveyChangePopupProps from './SurveyChangePopupProps';

const SurveyChangePopup: ReactFC<SurveyChangePopupProps> = (
  props: SurveyChangePopupProps
) => {
  const { display, collapsingOwnSurvey, viewPoint, onCancel, onContinue } =
    props;

  const getMessage = (): string => {
    if (viewPoint === SurveyChangeViewPoint.NewSurvey) {
      if (collapsingOwnSurvey) {
        return intl.get(
          'LBL_SURVEYS_DRAFTED_SURVEYS_OWN_SURVEY_CHANGE_WARNING'
        );
      }
      return intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_CHANGE_WARNING');
    }
    if (viewPoint === SurveyChangeViewPoint.GetNewSurveysButton) {
      return intl.get(
        'LBL_SURVEYS_DRAFTED_SURVEYS_GET_NEW_SURVEYS_CHANGE_WARNING'
      );
    }
    return '';
  };

  return (
    <Fade mountOnEnter unmountOnExit in={display} className={styles.overlay}>
      <TrapFocus autoFocus>
        {({ firstFocus, lastFocus }): JSX.Element => (
          <div className={`${styles.confirmation} ${styles.warning}`}>
            <Row className="w-100">
              <Col
                xs="12"
                className="mb-3 text-14-medium text-center text-gray"
              >
                {getMessage()}
              </Col>
              <Col xs="12" className="justify-content-center d-flex mt-3 pt-3">
                <Button
                  innerRef={lastFocus}
                  className="btn btn-light btn-sm text-14-semibold mr-3"
                  onClick={onCancel}
                >
                  {intl.get('BTN_SURVEYS_NO_CANCEL')}
                </Button>
                <Button
                  innerRef={firstFocus}
                  className="btn btn-warning btn-sm text-14-semibold ml-3"
                  type="button"
                  onClick={onContinue}
                >
                  {intl.get('BTN_SURVEYS_YES_PROCEED')}
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center w-100" />
          </div>
        )}
      </TrapFocus>
    </Fade>
  );
};

export default SurveyChangePopup;
