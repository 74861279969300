/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useRef, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';

import AppContext from 'context/AppContext';
import OffsetPaginationFooter from 'shared/components/data-table-footer/offset-pagination-footer/OffsetPaginationFooter';
import Status from 'shared/enums/Status';

import DraftedSurveyGuidePopover from './drafted-survey-guide-popover/DraftedSurveyGuidePopover';
import DraftedSurveysListItem from './drafted-surveys-list-item/DraftedSurveysListItem';
import childrenStyles from './drafted-surveys-list-item/draftedSurveysListItem.module.scss';
import styles from './draftedSurveysList.module.scss';
import DraftedSurveysListProps from './DraftedSurveysListProps';

const DraftedSurveysList: ReactFC<DraftedSurveysListProps> = (
  props: DraftedSurveysListProps
) => {
  const {
    data,
    status,
    page,
    pageSize,
    total,
    hasNext,
    hasPrevious,
    focusedSurvey,
    selectedSurveyTypes,
    selectedRecipientTypes,
    showDraftedSurveyGuidePopovers,
    setPageSize,
    loadNextPage,
    loadPreviousPage,
    refreshList,
    onSurveyTypeChange,
    onRecipientTypeChange,
    onItemExpanded,
    onSendSurvey,
  } = props;

  const { permissionsData } = useContext(AppContext);

  const selectRecipientsRef = useRef<HTMLDivElement>(null);

  const recipientTypeRef = useRef<HTMLSpanElement>(null);

  const showEmpty =
    (status === Status.Success || status === Status.Error) && data.length <= 0;
  const loading =
    status === Status.Loading || focusedSurvey.status === Status.Loading;
  const isEmptyError =
    status !== Status.Loading && (status === Status.Error || data.length <= 0);

  const renderPaginationFooter = (): JSX.Element => (
    <div className={styles.footer}>
      <OffsetPaginationFooter
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        gotoNextPage={loadNextPage}
        gotoPreviousPage={loadPreviousPage}
        pageSize={pageSize}
        totalResults={total}
        loading={loading}
        pageIndex={page}
        setPageSize={setPageSize}
        pageSizeDisabled={isEmptyError}
      />
    </div>
  );

  /**
   * Renders the empty state for the drafted surveys list
   *
   * @returns {JSX.Element} JSX snippet containing the empty state
   */
  const renderEmptyContent = (): JSX.Element => (
    <Row className={styles.tableContentHeight}>
      <Col xs="12" className="pr-4">
        <Row className={childrenStyles.item}>
          <Col
            className={`d-flex align-items-center overflow-hidden ${styles.name}`}
          >
            <p>{intl.get('LBL_NA')}</p>
          </Col>
          <Col
            xs="auto"
            className={`d-flex align-items-center overflow-hidden ${styles.date}`}
          >
            <p>{intl.get('LBL_NA')}</p>
          </Col>
          <Col xs={2}>
            <button
              className="calendar-button surveys-toggle text-gray"
              disabled
            >
              {intl.get('LBL_SURVEYS_SURVEY_TYPE_PLACEHOLDER')}
            </button>
          </Col>
          <Col xs={2}>
            <button
              className="calendar-button surveys-toggle text-gray"
              disabled
            >
              {intl.get('LBL_SURVEYS_SURVEY_TYPE_PLACEHOLDER')}
            </button>
          </Col>
          <Col
            className={`d-flex align-items-center justify-content-around ${childrenStyles.actions}`}
          >
            <button
              className={`btn btn-secondary btn-sm ${childrenStyles.recipientsBtn}`}
              disabled
            >
              <i className="icon-plus" />
              {intl.get('BTN_SURVEYS_DRAFTED_SELECT_RECIPIENTS')}
            </button>
            <button className="btn btn-primary btn-sm" disabled>
              {intl.get('BTN_SURVEYS_DRAFTED_SEND')}
            </button>
          </Col>
        </Row>
        {renderPaginationFooter()}
      </Col>
      <div className={`no-data-message ${styles.emptyAlert}`}>
        <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
        <div>{intl.get('ERR_SURVEYS_DRAFTED_SURVEYS_EMPTY_DESC')}</div>
        <hr className="divider" />
        <Row className="w-100 justify-content-center m-0">
          <button
            className="btn btn-secondary"
            onClick={(): Promise<void> => refreshList()}
          >
            <i className="icon-refresh" />
            {intl.get('BTN_SURVEYS_DRAFTED_EMPTY_RETRY')}
          </button>
        </Row>
      </div>
    </Row>
  );

  /**
   * Renders the infinite-scrolling drafted surveys list
   *
   * @param draftedSurveyItems List of drafted surveys
   * @returns {JSX.Element} JSX snippet containing the list
   */
  const renderDraftedSurveysList = (): JSX.Element => (
    <>
      <div className={styles.list}>
        {data.map((draftedSurvey) => (
          <div key={draftedSurvey.id} className="mt-4">
            <DraftedSurveysListItem
              data={draftedSurvey}
              status={status}
              permissions={permissionsData}
              selectedSurveyTypes={selectedSurveyTypes}
              selectedRecipientTypes={selectedRecipientTypes}
              focusedSurvey={focusedSurvey}
              onSurveyTypeChange={onSurveyTypeChange}
              onRecipientTypeChange={onRecipientTypeChange}
              onItemExpanded={onItemExpanded}
              onSendSurvey={onSendSurvey}
            />
          </div>
        ))}
      </div>
      {renderPaginationFooter()}
    </>
  );

  return (
    <>
      <Row>
        <Col xs="12" className={`pr-4 ${styles.wrapper}`}>
          <Row className={styles.header}>
            <Col className={styles.name}>
              {intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_COLUMN_HEADER_NAME')}
            </Col>
            <Col xs="auto" className={styles.date}>
              {intl.get(
                'LBL_SURVEYS_DRAFTED_SURVEYS_COLUMN_HEADER_CREATED_DATE'
              )}
            </Col>
            <Col xs={2}>
              <span ref={recipientTypeRef}>
                {intl.get(
                  'LBL_SURVEYS_DRAFTED_SURVEYS_COLUMN_HEADER_SURVEY_TYPE'
                )}
              </span>
            </Col>
            {showDraftedSurveyGuidePopovers && (
              <>
                <DraftedSurveyGuidePopover
                  elementRef={recipientTypeRef}
                  popoverPlacement="top-start"
                  popoverBodyText="LBL_SURVEYS_CREATE_SURVEY_SELECT_RECIPIENT_TYPE_GUIDE"
                  popoverClass="recipient-popper"
                />
                <DraftedSurveyGuidePopover
                  elementRef={selectRecipientsRef}
                  popoverPlacement="top"
                  popoverBodyText="LBL_SURVEYS_CREATE_SURVEY_SELECT_RECIPIENT_GUIDE"
                  popoverClass="select-recipients"
                />
              </>
            )}
            <Col xs={2}>
              {intl.get(
                'LBL_SURVEYS_DRAFTED_SURVEYS_COLUMN_HEADER_RECIPIENT_TYPE'
              )}
            </Col>
            <Col className={styles.unnamedColumn}>
              <div ref={selectRecipientsRef} />
            </Col>
          </Row>
        </Col>
      </Row>
      <BlockUi tag="div" blocking={loading}>
        {loading && focusedSurvey.status !== Status.Loading ? (
          <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
            <Skeleton height={40} />
            <Skeleton height={62} count={10} />
          </SkeletonTheme>
        ) : showEmpty ? (
          renderEmptyContent()
        ) : (
          renderDraftedSurveysList()
        )}
      </BlockUi>
    </>
  );
};

export default DraftedSurveysList;
