import { FC as ReactFC, useContext, useEffect, useState } from 'react';

import * as intl from 'react-intl-universal';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import AppContext from 'context/AppContext';
import PermissionUtil from 'helpers/PermissionUtil';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import UserProfilePermissionHelper from '../../../../../../helpers/UserProfilePermissionHelper';
import UserProfileFieldWrapperProps from './UserProfileFieldWrapperProps';

const UserProfileFieldWrapper: ReactFC<UserProfileFieldWrapperProps> = (
  props: UserProfileFieldWrapperProps
) => {
  const {
    children,
    canEditProfile,
    isOwnProfile,
    isOwner,
    fieldName,
    value,
    classes,
    noEdit,
    isControl,
    isList,
    isNotInLeftPanel,
    isNotProfileEditMode,
  } = props;
  const { userProfileSetupInProgress, permissionsData } =
    useContext(AppContext);

  const [canEditField, setCanEditField] = useState<boolean>(false);

  const canEditUserProfile = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.UserProfileEdit
  );
  const canDeactivateUsers = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.UserProfileDeactivate
  );
  const { permissionLevel } = permissionsData;

  useEffect(() => {
    let can =
      fieldName !== undefined &&
      UserProfilePermissionHelper.CanEditUserProfileField(
        fieldName,
        canEditProfile,
        isOwnProfile,
        isOwner,
        permissionLevel ?? '',
        canEditUserProfile,
        canDeactivateUsers
      ) &&
      !noEdit;

    if (!isNotProfileEditMode) {
      can = can && userProfileSetupInProgress;
    }
    setCanEditField(can);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, canEditProfile, isOwner, isOwnProfile]);

  let displayValue = value || intl.get('LBL_NA');

  if (isControl) {
    displayValue = '';
  }

  if (isNotInLeftPanel) {
    return <>{canEditField ? children : <></>}</>;
  }
  return (
    <>
      {canEditField ? (
        children
      ) : (
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-class="overflow-wrap"
          data-tip={displayValue}
          className={`text-input ${String(classes)}`}
          style={isList ? { whiteSpace: 'pre-line' } : undefined}
        >
          {displayValue}
        </EllipsisTooltip>
      )}
    </>
  );
};

export default UserProfileFieldWrapper;
