/**
 * SVGs do not scale by default. This custom hook provides the updated dimensions
 * of an element when screen size changes. This is primarily used to resize chart
 * components.
 */
import { useRef, useState, useEffect } from 'react';

import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';

import ComputedDimension from 'shared/hooks/use-dimensions/ComputedDimension';
import Dimension from 'shared/hooks/use-dimensions/Dimension';
import DimensionResult from 'shared/hooks/use-dimensions/DimensionResult';

const minDimension = 0;
const defaultMargin = 0;

const initDims = (): ComputedDimension => ({
  ready: false,
  width: minDimension,
  height: minDimension,
  marginTop: defaultMargin,
  marginLeft: defaultMargin,
  marginRight: defaultMargin,
  marginBottom: defaultMargin,
  boundedWidth: minDimension,
  boundedHeight: minDimension,
});

const useDimension = (dims: Dimension): DimensionResult => {
  const ref = useRef<HTMLDivElement>(null);

  const [compDims, setCompDims] = useState<ComputedDimension>(initDims());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    const element = ref.current;
    const observer = new ResizeObserver((e: ResizeObserverEntry[]): void => {
      if (Array.isArray(e) && e.length) {
        const { contentRect } = e[0];

        let calculatedHights = 0;
        const width = Math.floor(contentRect.width);

        if (
          (contentRect.height === 0 && width > 0) ||
          (contentRect.height !== 0 && width < contentRect.height) ||
          (contentRect.height !== 0 &&
            width > Math.floor(width * dims.aspectRatioHeight))
        ) {
          calculatedHights = width * dims.aspectRatioHeight;
        } else {
          calculatedHights = contentRect.height;
        }

        if (dims.minHeight && dims.minHeight > calculatedHights) {
          calculatedHights = dims.minHeight;
        }

        const height = Math.floor(calculatedHights);
        const marginTop = Math.floor((height * dims.marginTop) / 100);
        const marginLeft = Math.floor((width * dims.marginLeft) / 100);
        const marginRight = Math.floor((width * dims.marginRight) / 100);
        const marginBottom = Math.floor((height * dims.marginBottom) / 100);
        const boundedWidth = Math.floor(width - marginLeft - marginRight);
        const boundedHeight = Math.floor(height - marginTop - marginBottom);

        setCompDims({
          ready: true,
          width,
          height,
          marginTop,
          marginLeft,
          marginRight,
          marginBottom,
          boundedWidth,
          boundedHeight,
        });
      }
    });

    if (element !== null) {
      observer.observe(element);
    }

    /**
     * The default clean up function required by the hook.
     * Refer to https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup.
     */
    return (): void => {
      if (element !== null) {
        observer.unobserve(element);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ref,
    computedDimension: compDims,
  };
};

export default useDimension;
