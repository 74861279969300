/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef } from 'react';

import AppContext from 'context/AppContext';
import { getCurrencyString } from 'helpers/NumberFormat';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';

const useCurrency = (): ((
  value: number,
  precise: boolean,
  notation?: IntlNumberNotation
) => string) => {
  const { selectedCurrency } = useContext(AppContext);
  const currencySymbol = useRef<string>(selectedCurrency.symbol ?? '');

  useEffect(() => {
    currencySymbol.current = selectedCurrency.symbol ?? '';
  }, [selectedCurrency]);

  return getCurrencyString(currencySymbol.current);
};

export default useCurrency;
