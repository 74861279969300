/* eslint-disable react/jsx-props-no-spreading */
import DatePickerRDP from 'react-date-picker';

const DatePicker = (props): JSX.Element => {
  const { value, name } = props;
  return (
    <DatePickerRDP
      {...props}
      value={(value && new Date(value)) || null}
      onChange={(val): void => {
        props.onChange(name, val);
      }}
    />
  );
};

export default DatePicker;
