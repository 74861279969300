import * as intl from 'react-intl-universal';
import * as yup from 'yup';

class InviteUsersValidations {
  /**
   * Defines a Yup validation schema for the invite users form
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema = yup.object().shape({
      users: yup.array().of(
        yup.object().shape({
          email: yup
            .string()
            .trim()
            .email(intl.get('ERR_FORM_EMAIL_INVALID_MESSAGE'))
            .required(intl.get('ERR_INVITE_USERS_EMAIL_CANNOT_BE_EMPTY')),
          jobRole: yup
            .string()
            .required(intl.get('ERR_INVITE_USERS_JOB_ROLE_BE_EMPTY')),
          supervisorId: yup
            .string()
            .required(intl.get('ERR_INVITE_USERS_SUPERVISOR_CANNOT_BE_EMPTY')),
          permission: yup
            .string()
            .required(
              intl.get('ERR_INVITE_USERS_PERMISSION_LEVEL_CANNOT_BE_EMPTY')
            ),
          countryDataAccess: yup
            .array()
            .of(yup.string())
            .min(
              1,
              intl.get('ERR_INVITE_USERS_COUNTRY_DATA_ACCESS_CANNOT_BE_EMPTY')
            )
            .required(
              intl.get('ERR_INVITE_USERS_COUNTRY_DATA_ACCESS_CANNOT_BE_EMPTY')
            ),
        })
      ),
    });

    return validationSchema;
  }
}

export default InviteUsersValidations;
