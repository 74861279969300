/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import SeekPaginatedListResponse from 'api/common/responses/SeekPaginatedListResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ProjectDataMinimal from 'api/projects/types/ProjectDataMinimal';
import DraftedSurvey from 'api/surveys/types/DraftedSurvey';
import Country from 'api/surveys/types/filters/Country';
import Facilitator from 'api/surveys/types/filters/Facilitator';
import SurveyFilters from 'api/surveys/types/filters/SurveyFilters';
import SurveyFiltersRequest from 'api/surveys/types/filters/SurveyFiltersRequest';
import RecipientsStatus from 'api/surveys/types/RecipientsStatus';
import RecipientsStatusRequestBody from 'api/surveys/types/RecipientsStatusRequestBody';
import SendSurveyRequestBody from 'api/surveys/types/SendSurveyRequestBody';
import SurveyHistory from 'api/surveys/types/survey-history/SurveyHistory';
import SurveyProcessedStatus from 'api/surveys/types/survey-history/SurveyProcessedStatus';
import SurveyHistoryRequest from 'api/surveys/types/survey-history/SurveysHistoryRequest';
import ToggleStatus from 'api/surveys/types/survey-history/ToggleStatus';
import SurveyStats from 'api/surveys/types/SurveyStats';
import { SurveysApiTags } from 'constants/request-tags/RequestTags';

class SurveysApi extends ApiBase {
  /**
   * Fetch drafted surveys list
   */
  async GetDraftedSurveysData(
    url: string | null,
    search: string,
    pageSize = 10,
    cancelToken: CancelTokenSource
  ): Promise<SeekPaginatedListResponse<DraftedSurvey>> {
    let queryParams: Record<string, any> | string = { pageSize };

    if (url === null) {
      queryParams = {
        ...queryParams,
        name:
          search && search.trim().length > 0
            ? encodeURIComponent(search.trim())
            : undefined,
      };
    } else {
      // eslint-disable-next-line prefer-destructuring
      queryParams = url.split('?')[1];
    }

    const path = 'surveys/drafts';
    const listData = this.GetAsync<SeekPaginatedListResponse<DraftedSurvey>>({
      action: path,
      tag: SurveysApiTags.GetDraftedSurveysData,
      cancelSource: cancelToken,
      queryParams,
    });
    return listData;
  }

  /**
   * Fetch latest deployed draft surveys from Kobo and save in Insights DB
   */
  async FetchLatestSurveys(
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = 'surveys/drafts/fetch';
    const response = this.PostAsync<SuccessResponse>({
      action: path,
      tag: SurveysApiTags.FetchLatestSurveys,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Get survey stats for surveys tooltips
   */
  async GetSurveysStatsData(
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<SurveyStats>> {
    const path = 'surveys/tooltip-status';
    const statsData = this.GetAsync<ObjectResponse<SurveyStats>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetSurveysStatsData,
      cancelSource: cancelToken,
    });
    return statsData;
  }

  /**
   * Get countries to populate filters
   */
  async GetCountries(
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Country>> {
    const path = `groups/countries`;
    const countriesResponse = this.GetAsync<Promise<ListResponse<Country>>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetCountries,
      cancelSource: cancelToken,
    });
    return countriesResponse;
  }

  /**
   * Get projects to populate projects filter
   *
   * @param countryCodes Country codes to filter by
   */
  async GetProjectsForCountry(
    countryCodes: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectDataMinimal>> {
    const queryParams = { countryCode: countryCodes };

    const path = 'projects/all-projects';
    const projectsResponse = this.GetAsync<
      Promise<ListResponse<ProjectDataMinimal>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetProjectsForCountry,
      cancelSource: cancelToken,
      queryParams,
    });
    return projectsResponse;
  }

  /**
   * Get facilitator to populate filters
   *
   * @param projectIds Project Ids to filter by
   */
  async GetFacilitatorsForProjects(
    projectIds: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Facilitator>> {
    const queryParams = { projectId: projectIds };
    const path = `organizations/facilitators/lookup`;

    const facilitatorsResponse = this.GetAsync<
      Promise<ListResponse<Facilitator>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetFacilitatorsForProjects,
      cancelSource: cancelToken,
      queryParams,
    });
    return facilitatorsResponse;
  }

  /**
   * Get survey recipients based on filters
   *
   * @param surveyFilters Selected groups and members filters
   */
  async GetSurveyRecipients(
    surveyFilters: SurveyFiltersRequest,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<SurveyFilters>> {
    const path = 'surveys/recipients';
    const body = { ...surveyFilters };
    const response = this.PostAsync<ObjectResponse<SurveyFilters>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SurveysApiTags.GetSurveyRecipients,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Fetch recipients status prior to send
   *
   * @param filteredRecipients Filtered recipients
   */
  async FetchRecipientsStatus(
    recipients: RecipientsStatusRequestBody,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<RecipientsStatus>> {
    const path = 'surveys/check-recipients-status';
    const body = { ...recipients };
    const response = this.PostAsync<ObjectResponse<RecipientsStatus>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SurveysApiTags.FetchRecipientsStatus,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Send survey
   *
   * @param surveyRequest Selected filters and selected survey details
   */
  async SendSurvey(
    surveyRequest: SendSurveyRequestBody,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = 'surveys/send';
    const body = { ...surveyRequest };
    const response = this.PostAsync<Promise<SuccessResponse>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SurveysApiTags.SendSurvey,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Get survey history list
   *
   * @param surveyHistoryRequest Get survey history
   */
  async GetSurveyHistoryList(
    surveyHistoryRequest: SurveyHistoryRequest,
    cancelToken: CancelTokenSource
  ): Promise<SeekPaginatedListResponse<SurveyHistory>> {
    const { pageSize, url, name, sortBy, surveyType, recipientType, sort } =
      surveyHistoryRequest;

    let queryParams: Record<string, any> | string = {
      pageSize,
      sort,
      sortBy,
      surveyType,
      recipientType,
    };

    if (url === null) {
      queryParams = {
        ...queryParams,
        name:
          name && name.trim().length > 0 ? encodeURIComponent(name.trim()) : '',
      };
    } else {
      // eslint-disable-next-line prefer-destructuring
      queryParams = url.split('?')[1];
    }

    const path = 'surveys';
    const response = this.GetAsync<
      Promise<SeekPaginatedListResponse<SurveyHistory>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetSurveyHistoryList,
      cancelSource: cancelToken,
      queryParams,
    });

    return response;
  }

  /**
   * Get survey processed status
   *
   * @param id Survey ID
   */
  async GetSurveyProcessedStatus(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<SurveyProcessedStatus>> {
    const path = `surveys/${id}/processed-status`;
    const response = this.GetAsync<ObjectResponse<SurveyProcessedStatus>>({
      action: path,
      tag: SurveysApiTags.GetSurveyProcessedStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Get Collect ID toggle recipient status
   *
   * @param id Survey ID
   */
  async GetCollectIdToggleStatus(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<RecipientsStatus>> {
    const path = `surveys/${id}/check-recipients-status`;
    const response = this.GetAsync<ObjectResponse<RecipientsStatus>>({
      action: path,
      tag: SurveysApiTags.GetCollectIdToggleStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Update Collect ID toggle status
   *
   * @param id Survey ID
   */
  async UpdateCollectIdToggleStatus(
    id: string,
    statusOn: boolean,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<ToggleStatus>> {
    const path = `surveys/${id}/toggle-status`;
    const queryParams = { statusOn: String(statusOn) };

    const response = this.PutAsync<ObjectResponse<ToggleStatus>>({
      action: path,
      tag: SurveysApiTags.UpdateCollectIdToggleStatus,
      cancelSource: cancelToken,
      queryParams,
    });
    return response;
  }

  /**
   * Get survey correlation data
   *
   * @param id Survey id
   */
  async GetSurveyCorrelationExcel(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = `surveys/${id}/generate-excel`;

    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = { timezone: clientTimezone };

    const excelData = this.GetAsyncBlob({
      action: path,
      tag: SurveysApiTags.GetSurveyCorrelationExcel,
      cancelSource: cancelToken,
      queryParams,
    });
    return excelData;
  }
}

const SurveysApiInstance = new SurveysApi();

export default SurveysApiInstance;
