/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { ReactNode, useEffect, useRef, useState, FC as ReactFC } from 'react';

import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Arrow, ToggleLayer } from 'react-laag';
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Col,
  Fade,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import ApiError from 'api/common/types/ApiError';
import Constraints from 'constants/forms/Constraints';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import { formatDate } from 'helpers/DateFormat';
import { getErrorStatus, getLocalizedErrorString } from 'helpers/ErrorFormat';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import mergeRefs from 'helpers/mergeRefs';
import ProjectInfoFormValues, {
  ProjectInfoFormValueTypes,
} from 'modules/private/projects/components/project-info/ProjectInfoFormValues';
import DatePicker from 'shared/components/date-picker/DatePicker';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';
import DateFormatType from 'shared/enums/DateFormatType';
import EventKey from 'shared/enums/EventKey';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import ProjectViewMode from 'shared/enums/ProjectViewMode';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';
import successAnimation from 'shared/static/img/animations/success.gif';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import styles from './projectInfo.module.scss';
import ProjectInfoProps, {
  DatePickerType,
  ProjectErrorStatus,
  ProjectInfoFields,
} from './ProjectInfoProps';
import ProjectInfoValidations from './ProjectInfoValidations';

const ProjectInfo: ReactFC<ProjectInfoProps> = (props: ProjectInfoProps) => {
  const {
    type,
    newProjectCode,
    data,
    createEditStatus,
    idError,
    loadError,
    showModal,
    toggleModal,
    handleCreateProject,
    handleEditProject,
    handleDeleteProject,
    onCreateSuccess,
    onEdit,
  } = props;

  const maxLength = Constraints.MaxFieldLength;

  // prettier-ignore
  const [isProjectCreationSuccess, setIsProjectCreationSuccess] = useState<boolean>(false);
  // prettier-ignore
  const [showConversionConfirm, setShowConversionConfirm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [modalType, setModalType] = useState<ProjectViewMode | undefined>(type);

  const startDateButtonRef = useRef<HTMLButtonElement>(null);
  const endDateButtonRef = useRef<HTMLButtonElement>(null);
  const startDatePickerRef = useRef<HTMLDivElement>(null);
  const endDatePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showConversionConfirm, showDeleteConfirmation]);

  /**
   * Handle modal close event
   */
  const handleOnModalClosed = (): void => {
    setIsProjectCreationSuccess(false);
    setShowConversionConfirm(false);
  };

  /**
   * Handle modal open event
   */
  const handleOnModalOpened = (): void => setModalType(type);

  /**
   * Handle form submission for creating a project
   *
   * @param values Form values
   * @param formikHelpers Helpers from @formik
   */
  const submitCreateProject = async (
    values: Omit<ProjectInfoFormValues, 'projectId'>,
    formikHelpers: FormikHelpers<ProjectInfoFormValues>
  ): Promise<void> => {
    const formattedStartDate = values.startDate
      ? formatDate(values.startDate, DateFormatType.Iso)
      : null;
    const formattedEndDate = values.endDate
      ? formatDate(values.endDate, DateFormatType.Iso)
      : null;
    try {
      formikHelpers.setSubmitting(true);
      const response = await handleCreateProject({
        ...values,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        isTestProject: values.isTestProject === 'true',
      });
      if (response.item && response.item.id) {
        setIsProjectCreationSuccess(true);
        onCreateSuccess(false, true);

        const projectType =
          values.isTestProject === 'true'
            ? LabelKeysGA.PROJECT_TYPE_PRACTICE
            : LabelKeysGA.PROJECT_TYPE_REAL;

        sendEventGA(
          CategoryKeysGA.ProjectsCreateProject,
          ActionKeysGA.CreateProject,
          projectType
        );
      }
      formikHelpers.setSubmitting(false);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorStatus = getErrorStatus(
            error,
            intl.get('ERR_PROJECTS_CREATION_FAILURE')
          );
          formikHelpers.setStatus({
            ...errorStatus,
            prevName: values.name,
          } as ProjectErrorStatus);
        }
      }
      formikHelpers.setSubmitting(false);
    }
  };

  /**
   * Handles the conversion of project type
   *
   * @param testToReal Whether a project has been converted from test to real
   */
  const handleConversionSubmit = (testToReal: boolean): void => {
    if (testToReal) {
      setShowConversionConfirm(true);
    }
  };

  /**
   * Handle form submission for editing a project
   *
   * @param values Form values
   * @param formikHelpers Helpers from @formik
   */
  const submitEditProject = async (
    values: Omit<ProjectInfoFormValues, 'projectId'>,
    formikHelpers: FormikHelpers<ProjectInfoFormValues>
  ): Promise<void> => {
    const formattedStartDate = values.startDate
      ? formatDate(values.startDate, DateFormatType.Iso)
      : null;
    const formattedEndDate = values.endDate
      ? formatDate(values.endDate, DateFormatType.Iso)
      : null;
    try {
      formikHelpers.setSubmitting(true);
      await handleEditProject({
        name: values.name,
        projectId: data.projectId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        isTestProject: values.isTestProject === 'true',
      });
      formikHelpers.setSubmitting(false);
      onEdit(false, true);

      const projectType =
        values.isTestProject === 'true'
          ? LabelKeysGA.PROJECT_TYPE_PRACTICE
          : LabelKeysGA.PROJECT_TYPE_REAL;

      sendEventGA(
        CategoryKeysGA.ProjectsEditProject,
        ActionKeysGA.EditProject,
        projectType
      );
      toggleModal();
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorStatus = getErrorStatus(
            error,
            intl.get('ERR_PROJECTS_EDIT_FAILURE')
          );
          formikHelpers.setStatus({
            ...errorStatus,
            prevName: values.name,
          } as ProjectErrorStatus);
          if (error.status === HTTP_STATUS.NOT_FOUND) {
            EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
              error,
              genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
              genericCallback: () => undefined,
              customCallback: () => undefined,
            } as CustomErrorArgs);
            /* If the project has been deleted concurrently, redirect to 
            the projects page and re-load the projects list */
            onEdit(false, true);
            toggleModal();
          }
        }
      }
      formikHelpers.setSubmitting(false);
    }
  };

  /**
   * Verify if the submit button should be disabled
   *
   * @param isSubmitting Whether the form is currently submitting
   * @param isFormDirty Whether the form is dirty
   * @returns {boolean} Whether submit button should be disabled
   */
  const isSubmitButtonDisabled = (
    isSubmitting: boolean,
    isFormDirty: boolean
  ): boolean => {
    const isProjectQuotaFull =
      modalType === ProjectViewMode.CREATE_PROJECT && idError.error !== null;
    return isSubmitting || !isFormDirty || isProjectQuotaFull;
  };

  /**
   * Handles displaying API errors for form fields
   *
   * @param name Field name
   * @param param1 Form helpers from @formik
   * @returns {string | null | JSX.Element} Field error
   */
  const renderStatusError = (
    name: string,
    { form }: FieldProps<ProjectInfoFormValueTypes, ProjectInfoFormValues>
  ): string | null | JSX.Element => {
    const { status: formStatus } = form;
    const typedFormStatus = formStatus as ProjectErrorStatus;
    const dotNameArray = name.split('.');
    const fieldName = dotNameArray[dotNameArray.length - 1];
    if (typedFormStatus) {
      const fieldKeys = Object.keys(typedFormStatus.fields);
      if (fieldKeys.length > 0 && typedFormStatus.fields[fieldName]) {
        return intl.get(
          getLocalizedErrorString(typedFormStatus.fields[fieldName]),
          {
            projectName: typedFormStatus.prevName,
          }
        );
      }
    }
    return null;
  };

  /**
   * Renders the project creation success screen
   *
   * @returns {JSX.Element} JSX snippet containing the success screen
   */
  const renderProjectCreationSuccessState = (): JSX.Element => (
    <>
      <ModalHeader className="increase-font text-center">
        {intl.get('LBL_PROJECTS_CREATE_PROJECT_SUCCESS')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col
            xs="12"
            className="d-flex justify-content-center mb-3 px-3 pb-3 pt-1"
          >
            <img src={successAnimation} alt="Success" className={styles.anim} />
          </Col>
        </Row>
        <Row className="justify-content-center mt-3 mb-3">
          <Col xs="auto">
            <Button color="primary" tabIndex={0} onClick={toggleModal}>
              {intl.get('BTN_PROJECTS_OKAY')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </>
  );

  /**
   * Render the project type radio buttons
   *
   * @param param0 Helpers from @formik
   * @returns {JSX.Element} JSX snippet containing the radio button
   */
  const renderRadioButton = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
  }): JSX.Element => (
    <Label className={`pb-2 ${styles.radioFitContent}`}>
      <span className="insight-radio">
        <Input
          name={name}
          id={id}
          type="radio"
          value={id}
          checked={id === value}
          onChange={onChange}
          onBlur={onBlur}
          tabIndex={0}
          className="radio-button"
          disabled={
            modalType === ProjectViewMode.EDIT_PROJECT &&
            data.isTestProject === 'false'
          }
        />
        <i className="icon-radio" />
      </span>
      {id === 'true' ? (
        <span
          data-for="insTooltip"
          data-tip={intl.get('LBL_PROJECTS_THIS_IS_A_PRACTICE_PROJECT_HINT')}
          data-class="overflow-wrap bring-it-up"
          data-place="bottom"
          className={`${id === value ? styles.radioActive : ''}`}
        >
          {label}
        </span>
      ) : (
        <span className={`${id === value ? styles.radioActive : ''}`}>
          {label}
        </span>
      )}
    </Label>
  );

  /**
   * Renders a date picker component
   *
   * @param datePickerType Date picker type
   * @param values Project data
   * @param setFieldValue @formik method to set form field value
   * @param reference Ref object for date picker button
   * @returns {JSX.Element} JSX snippet containing the date picker
   */
  const renderDatePicker = (
    datePickerType: DatePickerType,
    values: ProjectInfoFormValues,
    setFieldValue,
    reference: React.RefObject<HTMLButtonElement>
  ): JSX.Element => {
    /* react laag focus loses track a it is elevated to a different level in dom;
      manual focus will be needed to get it back on track */
    const handleClose = (closeHandler: () => void) => (): void => {
      if (reference.current) {
        reference.current.focus();
      }
      closeHandler();
    };
    return (
      <ToggleLayer
        fixed
        container={
          datePickerType === DatePickerType.START_DATE
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              startDatePickerRef.current! // nosonar
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              endDatePickerRef.current! // nosonar
        }
        renderLayer={({
          isOpen,
          layerProps,
          arrowStyle,
          layerSide,
          close,
        }): ReactNode =>
          isOpen && (
            <div
              ref={layerProps.ref}
              className="layer insight-dropdown"
              style={{
                ...layerProps.style,
              }}
            >
              <Arrow
                style={arrowStyle}
                layerSide={layerSide}
                backgroundColor="#ffffff"
                borderWidth={1}
                borderColor="#dbdce1"
                roundness={0.7}
              />
              <DatePicker
                autoFocus={false}
                format={DateFormatType.DatePicker}
                className="insight-date-picker"
                name={
                  datePickerType === DatePickerType.START_DATE
                    ? ProjectInfoFields.PROJECT_START_DATE
                    : ProjectInfoFields.PROJECT_END_DATE
                }
                value={
                  datePickerType === DatePickerType.START_DATE
                    ? values.startDate
                    : values.endDate
                }
                onChange={(name: string, date: Date | Date[]): void =>
                  setFieldValue(name, date ? date.toString() : '', true)
                }
                minDate={
                  values.startDate
                    ? datePickerType === DatePickerType.END_DATE
                      ? new Date(values.startDate)
                      : null
                    : null
                }
                maxDate={
                  values.endDate
                    ? datePickerType === DatePickerType.START_DATE
                      ? new Date(values.endDate)
                      : null
                    : null
                }
                isOpen
                onCalendarClose={handleClose(close)}
              />
            </div>
          )
        }
        placement={{
          anchor: 'BOTTOM_CENTER',
          possibleAnchors: ['BOTTOM_CENTER'],
          triggerOffset: 10,
        }}
        closeOnOutsideClick
      >
        {({ triggerRef, toggle, isOpen }): ReactNode => (
          <>
            <Label>
              {datePickerType === DatePickerType.START_DATE &&
                intl.get('LBL_PROJECTS_START_DATE')}
              {datePickerType === DatePickerType.END_DATE &&
                intl.get('LBL_PROJECTS_END_DATE')}
            </Label>
            <button
              type="button"
              tabIndex={0}
              className={`calendar-button ${isOpen ? 'calendar-open' : ''}`}
              ref={mergeRefs([triggerRef, reference])}
              onClick={toggle}
            >
              {datePickerType === DatePickerType.START_DATE &&
                (values.startDate ? (
                  <span className="text-12-medium">
                    {formatDate(values.startDate, DateFormatType.ShortYear)}
                  </span>
                ) : (
                  <span className="text-12-medium text-gray">
                    {intl.get('LBL_PROJECTS_SELECT_DATE')}
                  </span>
                ))}
              {datePickerType === DatePickerType.END_DATE &&
                (values.endDate ? (
                  <span className="text-12-medium">
                    {formatDate(values.endDate, DateFormatType.ShortYear)}
                  </span>
                ) : (
                  <span className="text-12-medium text-gray">
                    {intl.get('LBL_PROJECTS_SELECT_DATE')}
                  </span>
                ))}
            </button>
            <div
              ref={
                datePickerType === DatePickerType.START_DATE
                  ? startDatePickerRef
                  : endDatePickerRef
              }
            />
          </>
        )}
      </ToggleLayer>
    );
  };

  /**
   * Renders a confirmation alert when the user attempts to delete a project
   *
   * @returns {JSX.Element} JSX snippet containing the conversion confirmation alert
   */
  const renderDeleteConfirmation = (): JSX.Element => {
    const handleDelete = (): void => {
      setShowDeleteConfirmation(false);
      handleDeleteProject(data.code, data.projectId);
    };
    const handleCancel = (): void => {
      setShowDeleteConfirmation(false);
    };
    return (
      <Fade
        mountOnEnter
        unmountOnExit
        in={showDeleteConfirmation}
        className={styles.overlay}
      >
        <TrapFocus autoFocus>
          {({ firstFocus, lastFocus }): JSX.Element => (
            <div
              className={`${styles.confirmation} ${styles.deleteConfirmation}`}
            >
              <span className="text-12-bold text-center text-uppercase truncate">
                {intl.get('LBL_PROJECTS_EDIT_DELETE_CONFIRM_TITLE')}{' '}
                <EllipsisTooltip
                  tag="div"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-class="overflow-wrap bring-it-up"
                  data-tip={data.name}
                  className="truncate"
                >
                  {data.name}
                </EllipsisTooltip>
              </span>
              <div className="text-14-medium text-center text-gray">
                {intl.get('LBL_PROJECTS_EDIT_DELETE_CONFIRM_DESC')}
              </div>
              <div className="text-14-medium text-center text-gray">
                {intl.get('LBL_PROJECTS_EDIT_DELETE_CONFIRM_PROMPT')}
              </div>
              <div className="d-inline-flex justify-content-between">
                <Button
                  className="btn btn-warning btn-sm mr-4"
                  type="button"
                  innerRef={firstFocus}
                  onClick={handleDelete}
                >
                  {intl.get('BTN_PROJECTS_EDIT_DELETE_PROJECT_YES')}
                </Button>
                <Button
                  className="btn btn-light btn-sm"
                  innerRef={lastFocus}
                  onClick={handleCancel}
                >
                  {intl.get('BTN_PROJECTS_EDIT_DELETE_PROJECT_NO')}
                </Button>
              </div>
            </div>
          )}
        </TrapFocus>
      </Fade>
    );
  };

  /**
   * Renders a confirmation alert when the user attempts to convert the project type
   *
   * @param submitForm Form submission method
   * @param setFieldValue @formik method to set form field value
   * @returns {JSX.Element} JSX snippet containing the conversion confirmation alert
   */
  const renderConversionConfirm = (
    submitForm: () => Promise<void>,
    setFieldValue
  ): JSX.Element => {
    const handleCreate = (): void => {
      setShowConversionConfirm(false);
      submitForm();
    };
    const handleCancel = (): void => {
      setFieldValue(ProjectInfoFields.PROJECT_TYPE, 'true');
      setShowConversionConfirm(false);
    };
    return (
      <Fade
        mountOnEnter
        unmountOnExit
        in={showConversionConfirm}
        className={styles.overlay}
      >
        <div className={styles.confirmation}>
          <div className="text-14-medium text-center">
            {intl.get('LBL_PROJECTS_EDIT_CONVERSION_CONFIRM')}
          </div>
          <Button
            className="btn btn-warning btn-sm"
            type="button"
            onClick={handleCreate}
          >
            {intl.get('BTN_PROJECTS_EDIT_CONVERSION_YES')}
          </Button>
          <Button className="btn btn-light btn-sm" onClick={handleCancel}>
            {intl.get('BTN_PROJECTS_EDIT_CONVERSION_NO')}
          </Button>
        </div>
      </Fade>
    );
  };

  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={showModal}
      toggle={toggleModal}
      returnFocusAfterClose={false}
      onOpened={handleOnModalOpened}
      onClosed={handleOnModalClosed}
      centered
    >
      {isProjectCreationSuccess &&
      modalType === ProjectViewMode.CREATE_PROJECT ? (
        renderProjectCreationSuccessState()
      ) : (
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              code: data.code || newProjectCode,
              name: data.name,
              startDate: data.startDate,
              endDate: data.endDate,
              isTestProject: data.isTestProject,
            }}
            validationSchema={ProjectInfoValidations.GetValidationSchema()}
            onSubmit={
              type === ProjectViewMode.CREATE_PROJECT
                ? submitCreateProject
                : submitEditProject
            }
          >
            {({
              status,
              dirty,
              isSubmitting,
              values,
              submitForm,
              setFieldValue,
            }): JSX.Element => {
              const testToReal =
                data.isTestProject === 'true' &&
                values.isTestProject === 'false';
              return (
                <BlockUi
                  tag="div"
                  blocking={createEditStatus === Status.Loading || isSubmitting}
                >
                  <ModalHeader className="increase-font text-center">
                    {modalType === ProjectViewMode.EDIT_PROJECT
                      ? intl.get('LBL_PROJECTS_EDIT_PROJECT_INFO_TITLE')
                      : intl.get('LBL_PROJECTS_PROJECT_INFO_TITLE')}
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate>
                      {status &&
                        status.msg &&
                        Object.keys(status.fields).length === 0 && (
                          <Row>
                            <Col>
                              <FormGroup>
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  {status.msg}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      {idError.error !== null && (
                        <Row>
                          <Col>
                            <FormGroup>
                              <div className="alert alert-danger" role="alert">
                                {idError.message
                                  ? idError.message
                                  : intl.get(
                                      'ERR_PROJECTS_NEW_CODE_GENERATION'
                                    )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {loadError && (
                        <Row>
                          <Col>
                            <FormGroup>
                              <div className="alert alert-danger" role="alert">
                                {intl.get('ERR_PROJECTS_FETCH_DETAILS_FAILURE')}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <FormGroup>
                            <label htmlFor={ProjectInfoFields.PROJECT_NAME}>
                              {intl.get('LBL_PROJECTS_PROJECT_NAME')}
                            </label>
                            <Field
                              type="text"
                              name={ProjectInfoFields.PROJECT_NAME}
                              maxLength={maxLength}
                              tabIndex="0"
                              className="form-control bolded-text"
                            />
                            <EnhancedFormikError
                              name={ProjectInfoFields.PROJECT_NAME}
                              renderSecondaryError={renderStatusError}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <Row form>
                            <Col xs="6">
                              <FormGroup>
                                {renderDatePicker(
                                  DatePickerType.START_DATE,
                                  values,
                                  setFieldValue,
                                  startDateButtonRef
                                )}
                                <EnhancedFormikError
                                  name={ProjectInfoFields.PROJECT_START_DATE}
                                  renderSecondaryError={renderStatusError}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6">
                              <FormGroup>
                                {renderDatePicker(
                                  DatePickerType.END_DATE,
                                  values,
                                  setFieldValue,
                                  endDateButtonRef
                                )}
                                <EnhancedFormikError
                                  name={ProjectInfoFields.PROJECT_END_DATE}
                                  renderSecondaryError={renderStatusError}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <label htmlFor="code">
                              {intl.get('LBL_PROJECTS_PROJECT_NUMBER')}
                            </label>
                            <Field
                              type="text"
                              name={ProjectInfoFields.PROJECT_CODE}
                              className="form-control bolded-text"
                              tabIndex="0"
                              disabled
                            />
                            <EnhancedFormikError
                              name={ProjectInfoFields.PROJECT_CODE}
                              renderSecondaryError={renderStatusError}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="12">
                          <FormGroup className="insight-radio-group">
                            <Field
                              component={renderRadioButton}
                              name={ProjectInfoFields.PROJECT_TYPE}
                              id="false"
                              value="false"
                              label={intl.get(
                                'LBL_PROJECTS_THIS_IS_A_REAL_PROJECT'
                              )}
                            />
                            <Field
                              component={renderRadioButton}
                              name={ProjectInfoFields.PROJECT_TYPE}
                              id="true"
                              value="true"
                              label={intl.get(
                                'LBL_PROJECTS_THIS_IS_A_PRACTICE_PROJECT'
                              )}
                            />
                            <EnhancedFormikError
                              name={ProjectInfoFields.PROJECT_TYPE}
                              renderSecondaryError={renderStatusError}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs="12"
                          className="mt-3 justify-content-between d-flex"
                        >
                          <Col xs="4">
                            <Button
                              type="button"
                              className="btn"
                              tabIndex={0}
                              onClick={toggleModal}
                              disabled={isSubmitting}
                            >
                              {intl.get('BTN_CANCEL')}
                            </Button>
                          </Col>
                          <Col xs="8" className="justify-content-end d-flex">
                            {modalType === ProjectViewMode.EDIT_PROJECT && (
                              <Button
                                type="button"
                                onClick={(): void =>
                                  setShowDeleteConfirmation(true)
                                }
                                tabIndex={0}
                                className="btn btn-secondary danger-btn mr-3"
                                disabled={isSubmitting}
                              >
                                {intl.get('BTN_PROJECTS_EDIT_DELETE_PROJECT')}
                              </Button>
                            )}
                            <Button
                              type={testToReal ? 'button' : 'submit'}
                              onClick={(): void =>
                                handleConversionSubmit(testToReal)
                              }
                              tabIndex={0}
                              className="btn btn-primary btn-login"
                              disabled={isSubmitButtonDisabled(
                                isSubmitting,
                                dirty
                              )}
                            >
                              {modalType === ProjectViewMode.CREATE_PROJECT &&
                                intl.get('BTN_CREATE')}
                              {modalType === ProjectViewMode.EDIT_PROJECT &&
                                intl.get('BTN_SAVE_AND_CLOSE')}
                            </Button>
                          </Col>
                        </Col>
                      </Row>
                      {renderConversionConfirm(submitForm, setFieldValue)}
                      {renderDeleteConfirmation()}
                    </Form>
                  </ModalBody>
                </BlockUi>
              );
            }}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export default ProjectInfo;
