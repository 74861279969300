/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, FC as ReactFC } from 'react';

import Constraints from 'constants/forms/Constraints';

import FileInputFieldProps from './FileInputFieldProps';

const FileInputField: ReactFC<FileInputFieldProps> = (
  props: FileInputFieldProps
) => {
  const { field, form, onChange, ...rest } = props;
  const { value, onBlur, ...fieldProps } = field;
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!field.value) {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [field.value]);

  /**
   * Handle file change event
   *
   * @param event ChangeEvent
   */
  const handleChange = (event): void => {
    const { files } = event.currentTarget;
    let currentValue = null;
    if (files && files.length > 0) {
      currentValue = files;
    }
    if (onChange) {
      onChange(currentValue, event);
    } else {
      form.setFieldValue(field.name, currentValue);
    }
  };

  return (
    <input
      ref={fileInputRef}
      {...rest}
      {...fieldProps}
      onChange={handleChange}
      type="file"
      accept={Constraints.SupportedImageFormats.join(', ')}
      className="form-control"
    />
  );
};

export default FileInputField;
