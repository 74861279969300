enum GroupWidgetValueGroup {
  Female = 'FEMALE',
  Male = 'MALE',
  College = 'COLLEGE',
  PrimarySchool = 'PRIMARY_SCHOOL',
  NoFormalEducation = 'NO_FORMAL_EDUCATION',
  SecondarySchool = 'SECONDARY_SCHOOL',
  GreatProgress = 'GREAT_PROGRESS',
  GoodProgress = 'GOOD_PROGRESS',
  ALittleBehind = 'A_LITTLE_BEHIND',
  Business = 'BUSINESS',
  Education = 'EDUCATION',
  Household = 'HOUSEHOLD',
  Medical = 'MEDICAL',
  Land = 'LAND',
  Livestock = 'LIVESTOCK',
  Vehicle = 'VEHICLE',
  Technology = 'TECHNOLOGY',
  Travel = 'TRAVEL',
  Other = 'OTHER',
  GroupsFormed = 'GROUPS_FORMED',
  OneDependent = 'ONE_DEPENDENT',
  TwoDependents = 'TWO_DEPENDENT',
  MembersCaringForChildren = 'MEMBER_CARING_FOR_CHILDREN',
  TotalGroupMembers = 'TOTAL_MEMBERS',
  InsuranceFund = 'INSURANCE_FUND',
  EducationFund = 'EDUCATION_FUND',
  GroupBusinessFund = 'GROUP_BUSINESS_FUND',
  CelebrationFund = 'CELEBRATION_FUND',
  TechnologyFund = 'TECHNOLOGY_FUND',
  CustomFund1 = 'CUSTOM_FUND1',
  CustomFund2 = 'CUSTOM_FUND2',
  CustomFund3 = 'CUSTOM_FUND3',
}

export default GroupWidgetValueGroup;
