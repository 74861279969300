import { useEffect, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactTooltip from 'react-tooltip';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import { EventBus } from 'shared/events/EventBus';

import styles from './groupsFilter.module.scss';
import GroupsFilterProps from './GroupsFilterProps';

const GroupsFilter: ReactFC<GroupsFilterProps> = (props: GroupsFilterProps) => {
  const { data, title, isDisabled, selected, eventType } = props;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const values = selected.map(({ value }) => value);

  const inputName = title.replace(/\s+/g, '-').toLowerCase();

  const isEmpty = data.length <= 0;

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string
  ): void => {
    EventBus.getInstance().dispatch(eventType, { event, label });
  };

  return (
    <div className="mb-4">
      <span className="text-16-semibold">{title}</span>
      <PerfectScrollbar className={styles.scrollContainer}>
        <Form>
          {isEmpty ? (
            <FormGroup className="insight-checkbox-group pb-2 pt-2">
              <Label className={`${styles.reduceOpacity}`}>
                <span className={`insight-checkbox mr-3 ${styles.checkbox}`}>
                  <Input
                    type="checkbox"
                    tabIndex={-1}
                    name={`${inputName}-na`}
                    disabled
                  />
                  <i className="icon-check" />
                </span>
                <span className="text-12-medium">{intl.get('LBL_NA')}</span>
              </Label>
            </FormGroup>
          ) : (
            data.map(({ value, label }) => {
              const isChecked = values.includes(value);
              return (
                <FormGroup
                  key={value}
                  className="insight-checkbox-group pb-2 pt-2"
                >
                  <Label
                    className={`${isDisabled ? styles.reduceOpacity : ''}`}
                  >
                    <span
                      className={`insight-checkbox mr-3 ${styles.checkbox}`}
                    >
                      <Input
                        type="checkbox"
                        tabIndex={0}
                        name={`${inputName}-${value}`}
                        value={value}
                        checked={isChecked}
                        onChange={(e): void => handleOnChange(e, label)}
                        disabled={isDisabled}
                      />
                      <i className="icon-check" />
                    </span>
                    <EllipsisTooltip
                      tag="span"
                      data-place="right"
                      data-for="insTooltip"
                      data-tip={label}
                      data-class="overflow-wrap"
                      className="truncate text-14-medium"
                    >
                      {label}
                    </EllipsisTooltip>
                  </Label>
                </FormGroup>
              );
            })
          )}
        </Form>
      </PerfectScrollbar>
    </div>
  );
};

export default GroupsFilter;
