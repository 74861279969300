enum InviteUsersFormFields {
  EMAIL = 'email',
  JOB_ROLE = 'jobRole',
  SUPERVISOR = 'supervisorId',
  PERMISSION_LEVEL = 'permission',
  COUNTRY_DATA = 'countryDataAccess',
  IS_IMPLEMENTING_PARTNER = 'isImplementingPartner',
}

export default InviteUsersFormFields;
