import { FC as ReactFC, ReactNode, useState, useRef, useEffect } from 'react';

import { LocationDescriptorObject } from 'history';
import * as intl from 'react-intl-universal';
import { Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';

import Misc from 'constants/misc/Misc';
import ModulePaths from 'constants/ModulePaths';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';
import EventKey from 'shared/enums/EventKey';
import { EventBus } from 'shared/events/EventBus';

import { UserProfilePermissionArgs } from '../../../types/eventTypes';
import UserProfileToolbarProps from './UserProfileToolbarProps';

const UserProfileToolbar: ReactFC<UserProfileToolbarProps> = (
  props: UserProfileToolbarProps
) => {
  const { context, location, userDropdown } = props;
  const {
    userProfileSetupInProgress,
    startUserProfileSetup,
    endUserProfileSetup,
  } = context;

  const headerDivRef = useRef<HTMLDivElement>(null);
  const trackPointer = useRef<number | null>(null);

  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    EventBus.getInstance().register(
      EventKey.ShareUserProfilePermissions,
      (eventArgs: UserProfilePermissionArgs) => {
        const { canEditProfile } = eventArgs;
        setEditable(canEditProfile);
      }
    );
  });

  useEffect(() => {
    if (!editable && userProfileSetupInProgress) {
      endUserProfileSetup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  /**
   * Cancel project user profile setup
   */
  const cancelUserProfileSetupMode = (): void => {
    if (userProfileSetupInProgress) {
      endUserProfileSetup(true);
    }
  };

  /**
   * Toggle project user profile setup
   */
  const toggleUserProfileSetupMode = (): void => {
    if (userProfileSetupInProgress) {
      endUserProfileSetup();
    } else {
      startUserProfileSetup();
    }
  };

  /**
   * Handle mouse enter/leave events
   *
   * @param event Mouse event
   */
  const handleDivMouseEnterLeave = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    if (userProfileSetupInProgress === false) {
      trackPointer.current = null;
      return;
    }
    if (event.type === 'mouseenter') {
      trackPointer.current = event.clientY;
    } else if (event.type === 'mouseleave') {
      trackPointer.current = null;
    }
  };

  /**
   * Generates the previous users list path
   *
   * @returns {string} Previous users list path
   */
  const getPrevPath = (): LocationDescriptorObject => {
    const { from } = location.state || {
      from: {
        pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
      },
    };
    return from;
  };

  const dataTip = userProfileSetupInProgress
    ? intl.get('ERR_SAVE_CHANGES')
    : '';

  return (
    <header ref={headerDivRef} className="main-header">
      <div
        className="header-col"
        data-for="topBarError"
        // FIXME: fix with css and remove unnecessary ref use
        style={{ height: headerDivRef.current?.clientHeight }}
        data-tip={dataTip}
        data-class="error place-bottom"
        data-place="bottom"
        onMouseEnter={handleDivMouseEnterLeave}
        onMouseLeave={handleDivMouseEnterLeave}
      >
        <h2 className="main-title" style={{ display: 'flex' }}>
          <InsNavLink
            to={getPrevPath}
            disabled={userProfileSetupInProgress}
            disabledClass="disabled-nav-link"
          >
            <i className="icon-arrow-left" />
            {intl.get('LBL_BACK')}
          </InsNavLink>
        </h2>
      </div>
      <div className="header-col">
        <Switch>
          <Route
            path={`${ModulePaths.SettingsPath}${ModulePaths.SettingsUserProfilePath}`}
            render={(): JSX.Element => (
              <>
                {editable && userProfileSetupInProgress && (
                  <Button
                    className="btn header-btn cancel-btn"
                    onClick={cancelUserProfileSetupMode}
                  >
                    {intl.get('BTN_CANCEL')}
                  </Button>
                )}
                &nbsp;&nbsp;
                {editable && (
                  <Button
                    key={userProfileSetupInProgress ? 'in-progress' : 'default'}
                    className={`btn header-btn ${
                      userProfileSetupInProgress ? 'btn-primary' : ''
                    }`}
                    onClick={toggleUserProfileSetupMode}
                  >
                    {userProfileSetupInProgress
                      ? intl.get('BTN_SAVE_CHANGES')
                      : intl.get('BTN_EDIT')}
                  </Button>
                )}
              </>
            )}
          />
        </Switch>
        {userDropdown}
      </div>
      <ReactTooltip
        type="light"
        html
        effect="solid"
        arrowColor="transparent"
        id="topBarError"
        getContent={[
          (text): ReactNode =>
            userProfileSetupInProgress && trackPointer.current ? text : null,
          Misc.EffectiveVisualDelay,
        ]}
      />
    </header>
  );
};

export default UserProfileToolbar;
