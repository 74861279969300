class OrganizationInfoViewModel {
  numberOfGroups: number;

  partnerId: string;

  name: string;

  constructor() {
    this.numberOfGroups = 0;
    this.partnerId = '';
    this.name = '';
  }
}

export default OrganizationInfoViewModel;
