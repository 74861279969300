/* eslint-disable react/jsx-props-no-spreading */
import { Component } from 'react';

import axios from 'axios';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Modal } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import ModulePaths from 'constants/ModulePaths';
import TIMEOUTS from 'constants/Timeouts';
import {
  ErrorStatus,
  getErrorStatus,
  getFirstError,
  getLocalizedErrorString,
} from 'helpers/ErrorFormat';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import SetNewPasswordError from 'modules/public/auth/components/set-new-password-error/SetNewPasswordError';
import SetNewPasswordSuccess from 'modules/public/auth/components/set-new-password-success/SetNewPasswordSuccess';
import SetNewPassword from 'modules/public/auth/components/set-new-password/SetNewPassword';
import AuthRouteProps from 'modules/public/PublicRouteProps';
import ModalBackground from 'shared/components/hoc/dummy-background/ModalBackground';
import RedirectHandler from 'shared/components/hoc/redirect-handler/RedirectHandler';
import Status from 'shared/enums/Status';

import SetNewPasswordFormValidation from './SetNewPasswordFormValidation';
import SetNewPasswordFormValues from './SetNewPasswordFormValues';
import SetNewPasswordViewState from './SetNewPasswordViewState';

class SetNewPasswordView extends Component<
  AuthRouteProps,
  SetNewPasswordViewState
> {
  constructor(props: AuthRouteProps) {
    super(props);
    this.state = {
      userEmail: '',
      userCode: '',
      isInitialEmailResent: false,
      emailResendStatus: Status.Idle,
      userVerificationStatus: Status.Idle,
      setNewPasswordStatus: Status.Idle,
      errorStatus: null,
    };
  }

  componentDidMount(): void {
    setPageTitle(intl.get('LBL_SET_NEW_PASSWORD_PAGE_TITLE'));

    const { location } = this.props;

    const query = new URLSearchParams(location.search);
    const codeParam = query.get('code');
    const emailParam = query.get('email');
    this.expiryTime =
      query.get('expiry_time') ?? TIMEOUTS.AUTH_VERIFICATION_EXPIRY;
    this.handleUserVerification(codeParam, emailParam);
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  expiryTime = '';

  initialValues: SetNewPasswordFormValues = {
    password: '',
    confirmedPassword: '',
  };

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Get the appropriate child component for the set new password modal
   *
   * @param formikProps The props passed down from @formik
   * @returns {JSX.Element} JSX snippet containing the child component
   */
  getModalChildren = (
    formikProps: FormikProps<SetNewPasswordFormValues>
  ): JSX.Element => {
    const {
      setNewPasswordStatus,
      userEmail,
      isInitialEmailResent,
      emailResendStatus,
      errorStatus,
    } = this.state;
    if (setNewPasswordStatus === Status.Success) {
      return <SetNewPasswordSuccess />;
    }
    if (errorStatus) {
      return (
        <SetNewPasswordError
          email={userEmail}
          isEmailResent={isInitialEmailResent}
          emailResendStatus={emailResendStatus}
          errorStatus={errorStatus}
          expiryTimeString={this.expiryTime}
          onResendEmailClick={this.handleResendEmail}
        />
      );
    }
    return (
      <SetNewPassword
        {...formikProps}
        email={userEmail}
        emailResendStatus={emailResendStatus}
        expiryTimeString={this.expiryTime}
        onResendEmailClick={this.handleResendEmail}
      />
    );
  };

  /**
   * Handles re-sending the email link to reset the password
   *
   * @param email The email address to which to send the link
   */
  handleResendEmail = async (email: string): Promise<void> => {
    try {
      this.setState({ emailResendStatus: Status.Loading });
      const result = await AuthApiInstance.SendResetPasswordEmail(
        email,
        this.source
      );
      this.expiryTime = result.expiryTime ?? TIMEOUTS.AUTH_VERIFICATION_EXPIRY;
      this.setState({
        emailResendStatus: Status.Success,
        isInitialEmailResent: true,
      });
    } catch (error) {
      this.setState({ emailResendStatus: Status.Error });
    }
  };

  /**
   * Handles user verification when user is navigated to this page from the email
   *
   * @param code The unique code assigned to the user
   * @param email The email address the user registered with
   */
  handleUserVerification = async (
    code: string | null | undefined,
    email: string | null
  ): Promise<void> => {
    if (code && email) {
      this.setState({ userEmail: email, userCode: code });
      try {
        this.setState({ userVerificationStatus: Status.Loading });
        await AuthApiInstance.VerifyUserForPasswordReset(
          code,
          email,
          this.source
        );
        this.setState({ userVerificationStatus: Status.Success });
      } catch (error) {
        this.setState({ userVerificationStatus: Status.Error });
        const errorStatus = this.handleErrors(error);
        this.setState({ errorStatus });

        const errorCode = getFirstError(error);
        sendEventGA(
          CategoryKeysGA.AccountSetNewPassword,
          ActionKeysGA.Redirect,
          `${LabelKeysGA.Fail}: ${intl.get(getLocalizedErrorString(errorCode))}`
        );
      }
    } else {
      this.setState({
        redirectTo: `${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`,
      });
    }
  };

  /**
   * Handles form submission to reset the password
   *
   * @param values The values entered into the set new password form
   * @param formikHelpers Form helpers provided by @formik
   */
  handleResetPassword = async (
    values: SetNewPasswordFormValues,
    formikHelpers: FormikHelpers<SetNewPasswordFormValues>
  ): Promise<void> => {
    const { userEmail, userCode } = this.state;
    try {
      formikHelpers.setSubmitting(true);
      await AuthApiInstance.ResetPassword(
        userCode,
        userEmail,
        values.password,
        this.source
      );
      this.setState({ setNewPasswordStatus: Status.Success });

      sendEventGA(
        CategoryKeysGA.AccountSetNewPassword,
        ActionKeysGA.SetNewPassword,
        LabelKeysGA.Success
      );
    } catch (error) {
      this.setState({ setNewPasswordStatus: Status.Error });
      formikHelpers.setStatus(this.handleErrors(error));
    }
  };

  /**
   * Handles formatting API errors for app
   *
   * @param error The API error object
   * @returns {ErrorStatus} Formatted error object
   */
  handleErrors = (error): ErrorStatus =>
    getErrorStatus(error, intl.get('ERR_SET_NEW_PASSWORD_FAILURE'));

  render(): JSX.Element {
    const { location } = this.props;
    const { userVerificationStatus, redirectTo } = this.state;

    const isBlocking = userVerificationStatus === Status.Loading;

    return (
      <RedirectHandler
        redirect={!!redirectTo}
        to={{
          pathname: redirectTo,
          search: getGlobalFiltersQuery(location.search),
        }}
      >
        <ModalBackground>
          <Modal size="lg" isOpen backdrop="static" centered>
            <Formik
              initialValues={this.initialValues}
              validationSchema={SetNewPasswordFormValidation.GetValidationSchema()}
              onSubmit={this.handleResetPassword}
            >
              {(
                formikProps: FormikProps<SetNewPasswordFormValues>
              ): JSX.Element => (
                <BlockUi blocking={isBlocking}>
                  {this.getModalChildren(formikProps)}
                </BlockUi>
              )}
            </Formik>
          </Modal>
        </ModalBackground>
      </RedirectHandler>
    );
  }
}

export default SetNewPasswordView;
