export type Feature = {
  name: string;
  text: string;
  enable: boolean;
  groupName: string;
  metaData?: { name: string; selected: boolean; inputType: InputType };
  editable: boolean;
};
export type InputTypeFeature = {
  name: string;
  type: InputType;
  items: Feature[];
};

export enum InputType {
  RADIO = 'radio',
}

interface PermissionProps {
  defaultClaims: Feature[];
  dummyItemCollapsed: boolean;
  handleDummyItemEdit: () => void;
  handleDummyItemCancel: () => void;
  organizationId: string;
  onError: (text: string) => void;
  isFirstTime: boolean;
  fetchPermissions: () => void;
  removeDummyItem: () => void;
  isModalView?: boolean;
  getGettingStartedState: () => void;
}

export default PermissionProps;
