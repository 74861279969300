enum SurveysFilter {
  Countries = 'countries',
  Projects = 'projects',
  Facilitators = 'facilitators',
  GroupStatus = 'groupStatus',
  GroupAge = 'groupAge',
  AllMembers = 'allMembers',
  MemberGender = 'memberGender',
  MemberAge = 'memberAge',
  MemberEducation = 'memberEducation',
}

export default SurveysFilter;
