/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, FC as ReactFC } from 'react';

import { Point, ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import * as d3 from 'd3';
import isNil from 'lodash/isNil';

import Charts from 'constants/Charts';
import { getDateDiff } from 'helpers/DateFormat';
import ChartEmpty from 'shared/components/chart-empty/ChartEmpty';
import Frequency from 'shared/enums/Frequency';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

import LineChartProps from './LineChartProps';

const LineChart: ReactFC<LineChartProps> = (props: LineChartProps) => {
  const {
    data,
    xAxisConfig,
    filters,
    meta,
    tickFormatter,
    sliceRenderer,
    legendLabelRenderer,
    enableArea = true,
    hasSideView = false,
    enablePoints = true,
    isFacilitatorMetricsGraph = false,
    lowerTickLimit = Charts.DefaultChartTicksLowerLimit,
    upperTickLimit = Charts.DefaultChartTicksUpperLimit,
  } = props;

  const theme = {
    background: 'white',
    axis: {
      ticks: {
        text: {
          fill: '#738088',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '11px',
          lineHeight: '18px',
        },
        line: {
          stroke: '#DBDCE1',
        },
      },
    },
    grid: {
      line: {
        stroke: '#DBDCE1',
        strokeWidth: 1,
        strokeDasharray: '4 4',
      },
    },
  };

  const containerDimension = {
    minHeight: 334,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: hasSideView ? 0.35 : 0.25958333333333,
  };

  const { ref, computedDimension } = useDimension(containerDimension);
  const { height } = computedDimension;

  /**
   * Formats the tooltip label for a single data-point
   *
   * @param pointData Information regarding a single data-point
   * @returns {string} Formatted tooltip label
   */
  const formatTooltipLabel = (pointData: any): string => {
    let dateToFormat: Date;
    const dateParser = d3.timeParse('%Y-%m-%dT%H:%M:%S');
    const dateFormatter = d3.timeFormat('%b %Y');

    if (pointData.comparisonKey) {
      dateToFormat = dateParser(pointData.comparisonKey) || pointData.x;
    } else {
      dateToFormat = pointData.x;
    }

    return `${legendLabelRenderer(pointData.name)} ${dateFormatter(
      dateToFormat
    )}`;
  };

  /**
   * Limits the number of grid-lines and tick values on y axis to prevent
   * clutter following large number of ticks, specifically limits the number of
   * ticks to 6 including 0
   *
   * @param maxY Largest value on the y-axis
   * @param minY Smallest value on the y-axis
   * @returns {number[] | undefined} Array of y-axis tick values
   */
  const formatYTickValues = (
    maxY?: number,
    minY?: number
  ): number[] | undefined => {
    if (!isNil(maxY) && !isNil(minY)) {
      if (minY === maxY) return [maxY];
      const gap = (maxY - minY) / 5;
      const numberOfValues = 5;
      const yTickValues: number[] = minY < 0 ? [minY] : [0];
      for (let index = 1; index < numberOfValues; index += 1) {
        yTickValues.push(yTickValues[index - 1] + gap);
      }
      yTickValues.push(maxY);
      return yTickValues;
    }
    return undefined;
  };

  /**
   * Limits the number of grid-lines and tick values on x axis to prevent
   * clutter following large number of ticks, specifically limits the number of
   * ticks to 9
   *
   * @param maxX Latest date on the x-axis
   * @param minX Oldest date on the x-axis
   * @returns {Date[] | undefined} Array of x-axis tick values
   */
  const formatXTickValues = (maxX?: Date, minX?: Date): Date[] | undefined => {
    if (!isNil(maxX) && !isNil(minX)) {
      let xTickLimit = upperTickLimit;
      const totalTickCount = getTotalTickCount(
        filters.frequency.value,
        maxX,
        minX,
        xTickLimit
      );
      if (totalTickCount !== -1) {
        xTickLimit = totalTickCount;
      }
      const start = new Date(minX);
      const end = new Date(maxX);
      const x = d3.scaleTime().domain([start, end]);
      if (minX === maxX) return x.ticks(1);
      return x.ticks(xTickLimit).length > upperTickLimit
        ? x.ticks(lowerTickLimit)
        : x.ticks(xTickLimit);
    }
    return undefined;
  };

  /**
   * Get the total count of ticks for the x-axis
   *
   * @param frequency Time frequency for x-axis
   * @param max Latest date
   * @param min Oldest date
   * @param limit Ticks limit
   * @returns {number} Count of ticks
   */
  const getTotalTickCount = (
    frequency: Frequency,
    max: Date,
    min: Date,
    limit: number
  ): number => {
    switch (frequency) {
      case Frequency.Yearly: {
        const diffInYears = getDateDiff(min, max, 'years');
        return diffInYears < limit ? diffInYears : -1;
      }
      case Frequency.Quarterly: {
        const diffInQuarters = getDateDiff(min, max, 'quarters');
        return diffInQuarters < limit ? diffInQuarters : -1;
      }
      default: {
        const diffInMonths = getDateDiff(min, max, 'months');
        return diffInMonths < limit ? diffInMonths : -1;
      }
    }
  };

  const yTickValues = useMemo(
    () => formatYTickValues(meta.maxY, meta.minY),
    [meta.maxY, meta.minY]
  );

  const xTickValues = useMemo(
    () => formatXTickValues(meta.maxX, meta.minX),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [meta.maxX, meta.minX]
  );

  /**
   * Validate whether to display empty state
   *
   * @returns {boolean} Specifies whether the empty state is displayed or not
   */
  const showEmptyState = (): boolean =>
    !(data && data.length && data[0].data.length);

  /**
   * Renders the tooltip for a slice of data
   *
   * @param slice Slice data
   * @returns {JSX.Element} JSX snippet containing the slice tooltip
   */
  const renderSliceTooltip = ({ slice }: SliceTooltipProps): JSX.Element => {
    /* Sorts data-points in alphabetical order; the way they appear in the legend */
    const { points } = slice;
    const sortedPoints = [...points].sort((a: any, b: any) => {
      if (a.data.name && b.data.name) {
        return a.data.name.localeCompare(b.data.name);
      }
      return true;
    });
    return (
      <div className="chart-tooltip-container">
        {sortedPoints.map((point: Point) => (
          <div key={point.id}>
            <span className="label">{formatTooltipLabel(point.data)}</span>
            :&nbsp;
            <span
              className="value"
              style={{
                color: point.serieColor,
              }}
            >
              {sliceRenderer(point.data.y)}
            </span>
          </div>
        ))}
      </div>
    );
  };

  /**
   * Renders the tooltip for a point of data
   *
   * @param input Current point data
   * @returns {JSX.Element} JSX snippet containing the point tooltip
   */
  const renderFacilitatorMetricsTooltip = ({
    slice,
  }: SliceTooltipProps): JSX.Element => {
    /* Sorts data-points in alphabetical order; the way they appear in the legend */
    const { points } = slice;
    const sortedPoints = [...points].sort((a: any, b: any) => {
      if (a.data.name && b.data.name) {
        return a.data.name.localeCompare(b.data.name);
      }
      return true;
    });
    return (
      <div className="chart-tooltip-container">
        {sortedPoints.map((point: Point) => {
          const { serieColor, id, data: Data } = point;
          const updatedData: any = Data;
          const legendLabel = updatedData.name.substring(
            0,
            updatedData.name.lastIndexOf('.')
          );
          return (
            <div key={id}>
              <span className="inline">
                <span className="label truncate">
                  {legendLabelRenderer(legendLabel)}
                </span>
                :&nbsp;
                <span
                  className="value"
                  style={{
                    color: serieColor,
                  }}
                >
                  {sliceRenderer(Data.y)}
                </span>
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className="ins-custom-chart-wrapper chart-container line-area-char"
      style={{ height: `${height}px` }}
    >
      {showEmptyState() ? (
        <ChartEmpty />
      ) : (
        <ResponsiveLine
          data={data}
          margin={{
            top: 50,
            right: hasSideView ? 50 : 75,
            bottom: 50,
            left: 85,
          }}
          xScale={{
            type: 'time',
            useUTC: false,
            format: '%Y-%m-%dT%H:%M:%S',
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{ type: 'linear', stacked: false }}
          axisBottom={{ ...xAxisConfig, tickValues: xTickValues }}
          gridYValues={yTickValues}
          gridXValues={xTickValues}
          axisLeft={{ format: tickFormatter, tickValues: yTickValues }}
          curve="monotoneX"
          pointSize={7}
          pointBorderWidth={1}
          pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
          lineWidth={2}
          enableArea={enableArea}
          areaOpacity={enableArea ? 0.1 : undefined}
          colors={(d: any): string => d.color as string}
          useMesh
          enableSlices="x"
          sliceTooltip={
            isFacilitatorMetricsGraph
              ? renderFacilitatorMetricsTooltip
              : renderSliceTooltip
          }
          theme={theme}
          enablePoints={enablePoints}
        />
      )}
    </div>
  );
};

export default LineChart;
