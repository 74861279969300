/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  FC as ReactFC,
} from 'react';

import get from 'lodash/get';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { usePagination, useSortBy, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';

import { UserTabFilters } from 'modules/private/settings/containers/users-view/UsersViewState';
import UserViewModel from 'modules/private/settings/containers/users-view/UserViewModel';
import DataTableFooter from 'shared/components/data-table-footer/DataTableFooter';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import Status from 'shared/enums/Status';

import TableConfig from '../../containers/users-view/TableConfig';
import styles from '../users-data-table/usersDataTable.module.scss';
import UsersHeaderCell from '../users-header-cell/UsersHeaderCell';
import DummyRowInvited from './dummy-row-invited/DummyRowInvited';
import InvitedUsersDataTableProps from './InvitedUsersDataTableProps';

const SortableColumns = TableConfig[UserTabFilters.INVITED].sortCols;

const InvitedUsersDataTable: ReactFC<InvitedUsersDataTableProps> = (
  props: InvitedUsersDataTableProps
) => {
  const {
    data,
    updateSortBy,
    updatePageSize,
    updatePage,
    controlledPageCount,
    pagination,
    initialPageSize,
    initialPageIndex,
    initialSortBy,
    status,
    resendStatus,
    onResendInviteFor,
    canInviteUsers,
  } = props;

  /**
   * Render the resend invite column
   */
  const getRenderResendCell = useCallback(
    ({ row }) => {
      const { email } = row.values;
      return (
        <Button
          disabled={resendStatus === Status.Loading || !canInviteUsers}
          size="sm"
          onClick={onResendInviteFor(email)}
        >
          {intl.get('BTN_SETTINGS_USERS_RESEND_INVITE')}
        </Button>
      );
    },
    [onResendInviteFor, resendStatus, canInviteUsers]
  );

  /**
   * Formats and renders cell value
   *
   * @param param0 Cell props
   * @returns {string} Formatted cell value
   */
  const renderCell = ({ value }): string => value || '—';

  /**
   * Handles disabling the column sort functionality
   *
   * @param column Name of the column
   * @returns {boolean}
   */
  const handleDisableSorting = (column: string): boolean =>
    !SortableColumns.has(column) || data.length === 0;

  const columns = useMemo(
    () => [
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_EMAIL'),
        Header: UsersHeaderCell,
        accessor: 'email',
        spanClass: 'left-align',
        className: 'text-bold truncate-long-words',
        disableSortBy: handleDisableSorting('email'),
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_JOB_ROLE'),
        Header: UsersHeaderCell,
        id: 'jobRole',
        accessor: (row: UserViewModel): string => get(row, 'jobRole.role', '—'),
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_JOB_ROLE'),
        disableSortBy: handleDisableSorting('jobRole'),
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_IMP_PARTNER'),
        Header: UsersHeaderCell,
        accessor: 'implementingPartner',
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_IMPLEMENTING_PARTNER'),
        disableSortBy: handleDisableSorting('implementingPartner'),
        Cell: renderCell,
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_PERMISSION_LEVEL'),
        Header: UsersHeaderCell,
        accessor: 'permissionLevel',
        id: 'permissionLevel',
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_PERMISSION_LEVEL'),
        disableSortBy: handleDisableSorting('permissionLevel'),
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_COUNTRY_DATA'),
        Header: UsersHeaderCell,
        accessor: (row): string =>
          row.countryDataAccess ? row.countryDataAccess.join(', ') : '—',
        id: 'countryDataAccess',
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_COUNTRY_DATA_ACCESS'),
        disableSortBy: handleDisableSorting('countryDataAccess'),
        Cell: renderCell,
      },
      {
        Header: (): ReactNode => null, // No header
        id: 'resendInvite',
        Cell: getRenderResendCell,
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, getRenderResendCell]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: initialPageIndex,
        sortBy: initialSortBy,
      },
      manualSortBy: true,
      manualPagination: true,
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetSortBy: false,
      autoResetPage: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  const initialSortByValue = initialSortBy[0];

  const initSortId = initialSortByValue
    ? initialSortByValue.id
    : TableConfig[UserTabFilters.INVITED].defaultSort;
  const initSortDesc = initialSortByValue
    ? initialSortByValue.desc
    : TableConfig[UserTabFilters.INVITED].defaultSortDesc;

  useEffect(() => {
    const newSortBy = [{ id: initSortId, desc: initSortDesc }];
    setSortBy(newSortBy);
  }, [initSortDesc, initSortId, setSortBy]);

  useEffect(() => {
    gotoPage(initialPageIndex);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageIndex]);

  useEffect(() => {
    setPageSize(initialPageSize);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageSize]);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [data]);

  /**
   * Handle sortBy change event
   *
   * @param event Column header click event
   */
  const handleChangeSortBy = (
    event: React.MouseEvent<HTMLSpanElement>
  ): void => {
    const dataSet = event.currentTarget.dataset;
    if (dataSet.sort) {
      const { id, canSort, isSorted, isSortedDesc, sortDescFirst } = JSON.parse(
        dataSet.sort
      );
      if (canSort && id !== null && id !== undefined) {
        updateSortBy([
          {
            id,
            desc: isSorted ? !isSortedDesc : sortDescFirst,
          },
        ]);
      }
    }
  };

  const isEmptyError =
    status !== Status.Loading && (status === Status.Error || data.length <= 0);

  /**
   * Renders a table row
   *
   * @param cell Cell props passed down from react-table
   * @returns {JSX.Element} JSX snippet containing the row component
   */
  const renderTd = (cell): JSX.Element => (
    <td
      {...cell.getCellProps({
        className: cell.column.className,
        'data-title': cell.column.dataTitle,
      })}
    >
      <EllipsisTooltip
        tag="span"
        data-for="insTooltip"
        data-tip={cell.value || ''}
        data-class="row-info overflow-wrap width-auto"
        data-place="bottom"
        className={`table-content text-14-semibold wrap-content ${
          String(cell.column.spanClass) ?? ''
        } ${styles.wrapContent}`}
      >
        {cell.render('Cell')}
      </EllipsisTooltip>
    </td>
  );

  return (
    <>
      <div className="insight-table-container pt-0">
        {status === Status.Loading ? (
          <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
            <Skeleton height={40} />
            <Skeleton height={62} count={10} />
          </SkeletonTheme>
        ) : (
          <PerfectScrollbar>
            <table
              className={`insight-table ${styles.table}`}
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.id}
                        {...column.getHeaderProps({
                          className: column.headerClassName ?? column.className,
                        })}
                      >
                        <span
                          data-sort={JSON.stringify({
                            id: column.id,
                            canSort: column.canSort,
                            isSorted: column.isSorted,
                            isSortedDesc: column.isSortedDesc,
                            sortDescFirst: column.sortDescFirst,
                          })}
                          className="table-label text-12-bold text-uppercase"
                          {...column.getSortByToggleProps({
                            title: '',
                            onClick: handleChangeSortBy,
                          })}
                        >
                          {column.render('Header', { meta: pagination })}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr key={row.id} {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <Fragment key={index}>{renderTd(cell)}</Fragment>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <DummyRowInvited
                    headers={headerGroups}
                    isError={status === Status.Error}
                  />
                )}
              </tbody>
            </table>
          </PerfectScrollbar>
        )}
        {isEmptyError && (
          <div className="no-data-message">
            <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
            <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
          </div>
        )}
        <DataTableFooter
          pageSizeDisabled={isEmptyError}
          loading={status === Status.Loading}
          totalResults={pagination.total}
          gotoPage={updatePage}
          pageSize={initialPageSize}
          setPageSize={updatePageSize}
          pageCount={pageCount}
          pageIndex={initialPageIndex}
        />
      </div>
    </>
  );
};

export default InvitedUsersDataTable;
