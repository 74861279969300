import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import find from 'lodash/find';
import * as intl from 'react-intl-universal';
import Select, { ValueType } from 'react-select';

import { ProjectSupervisor } from 'modules/private/projects/containers/projects-view/ProjectUsersViewModel';
import SupervisorsViewModel from 'modules/private/projects/containers/projects-view/SupervisorsViewModel';
import { Option } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import Status from 'shared/enums/Status';

import InlineEditStyles from '../InlineEditStyles';
import InlineEditSupervisorsSelectProps from './InlineEditSupervisorsSelectProps';

const InlineEditSupervisorsSelect: ReactFC<InlineEditSupervisorsSelectProps> = (
  props: InlineEditSupervisorsSelectProps
) => {
  const {
    cellProps,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    onUpdateUser,
  } = props;

  const selectParentRef = useRef<HTMLDivElement>(null);

  const {
    row: { id: rowId, original },
    column: { id: columnId },
  } = cellProps;

  const [value, setValue] = useState<Option | null>(null);

  useEffect(() => {
    setValue(() => {
      let supervisor =
        find(supervisors, { value: original.supervisor?.id }) ?? null;
      if (!supervisor && supervisorsStatus !== Status.Error) {
        const noneOption = new SupervisorsViewModel();
        supervisor = { value: noneOption.id, label: noneOption.name };
      }
      return supervisor;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellProps]);

  /**
   * Handles change event for supervisors
   *
   * @param option The selected supervisor
   */
  const onChange = (option: ValueType<Option>): void => {
    const selected = option as Option;
    const selectedSupervisor: ProjectSupervisor = {
      id: selected.value,
      name: selected.label,
    };
    onUpdateUser(rowId, columnId, selectedSupervisor);
  };

  return (
    <div ref={selectParentRef}>
      <Select
        value={value}
        onChange={onChange}
        options={supervisorsFiltered}
        isLoading={supervisorsStatus === Status.Loading}
        getOptionLabel={(option): string => option.label}
        getOptionValue={(option): string => option.value}
        name={`users.${String(rowId)}.supervisor`}
        menuPortalTarget={selectParentRef.current}
        placeholder={intl.get('LBL_PROJECT_USERS_SUPERVISED_BY_PLACEHOLDER')}
        styles={InlineEditStyles.GetCustomStyles()}
        className="size-down mb-1 extend-width"
        classNamePrefix="insight-select"
      />
    </div>
  );
};

export default InlineEditSupervisorsSelect;
