/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, FC as ReactFC } from 'react';

import usePrevious from 'shared/hooks/use-previous/UsePrevious';

import styles from './imageComponent.module.scss';
import ImageComponentProps from './ImageComponentProps';
import ImageComponentState from './ImageComponentState';

/**
 * Returns a promise that resolves when image loads
 *
 * @param src source uri/url of the images to load
 */
const loadImageWithPromise = (src: string | null): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const image = new Image();
    image.onload = (): void => {
      resolve();
    };
    image.onerror = (): void => {
      const error = new Error('Unable to load image');
      reject(error);
    };
    image.src = src ?? '';
  });

const ImageComponent: ReactFC<ImageComponentProps> = (
  props: ImageComponentProps
) => {
  const {
    src,
    alt,
    fallbackSrc,
    className,
    fallbackClassName = '',
    thumb,
    switcher = false,
    ignoreBlur = false,
    ...rest
  } = props;

  const [{ loading, blurLoading, source }, setImageState] =
    useState<ImageComponentState>({
      source: null,
      loading: false,
      blurLoading: true,
    });

  const prevSrc = usePrevious(src);

  useEffect(() => {
    const loadImage = async (newSrc: string | null): Promise<void> => {
      const state: Partial<ImageComponentState> = {};
      try {
        await loadImageWithPromise(newSrc);
        if (thumb) {
          state.blurLoading = false;
        }
        state.loading = false;
      } catch (error) {
        state.source = null;
      }
      setImageState((prev) => ({ ...prev, ...state }));
    };

    if (src !== prevSrc) {
      const newSrc = src ?? null;
      setImageState({
        loading: switcher ? !prevSrc : true,
        blurLoading: true,
        source: newSrc,
      });
      loadImage(newSrc);
    }
  }, [src, prevSrc, thumb, switcher]);

  const imageClass = `${String(className)} ${
    source && !loading ? '' : fallbackClassName
  } ${ignoreBlur ? '' : thumb && blurLoading ? styles.blur : styles.clear}`;

  /**
   * give current image source depending on loading states
   *
   * @returns string | undefined
   */
  const getSource = (): string | undefined => {
    if (thumb) {
      return source && blurLoading ? thumb : source ?? fallbackSrc;
    }
    return source ?? fallbackSrc;
  };

  return <img src={getSource()} alt={alt} className={imageClass} {...rest} />;
};

export default ImageComponent;
