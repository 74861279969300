/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChangeEvent,
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import uniqBy from 'lodash/uniqBy';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import Select, { StylesConfig } from 'react-select';
import {
  Button,
  Col,
  Fade,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import AppContext from 'context/AppContext';
import { insightsColors } from 'helpers/ColorHelper';
import CountriesSelectMenuList from 'modules/private/projects/components/add-users-modal/add-existing-users/existing-user-list/existing-user-list-item/countries-select-menu-list/CountriesSelectMenuList';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';
import Status from 'shared/enums/Status';

import ProjectsSelect from '../projects-select/ProjectsSelect';
import styles from './addProjectsModal.module.scss';
import AddProjectModalProps from './AddProjectsModalProps';

const AddProjectsModal: React.FC<AddProjectModalProps> = ({
  countries,
  userCountryData,
  projectsData,
  showAddProjectModal,
  displayCDAWarningPrompt,
  userUnassignedCountries,
  onCreateClick,
  toggleAddUserToProjectsModal,
  onCDAWarningPromptAction,
  projectCompatibilityStatus,
}: AddProjectModalProps) => {
  const { getProjectList } = useContext(AppContext);

  const menuListRef = useRef<HTMLDivElement | null>(null);

  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectProjectsToggleOpen, setSelectProjectsToggleOpen] =
    useState(false);

  const uniqueCountriesUnion = uniqBy(
    [...userCountryData, ...countries],
    'value'
  );

  const accessibleCountries: Array<SelectFieldOption> = uniqueCountriesUnion
    .filter((item) =>
      userCountryData.map((cda) => cda.value).includes(item.value)
    )
    .map((country) => ({
      value: country.value,
      label: country.label,
      isDisabled: true,
    }));

  const countryPlaceholder =
    accessibleCountries.length > 0
      ? accessibleCountries.map((c) => c.label).join(', ')
      : intl.get('LBL_NONE');

  const customStyles: StylesConfig = {
    option: (base: CSSProperties) => ({
      ...base,
      color: insightsColors.insightGray,
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
      ':hover': {
        backgroundColor: insightsColors.insightMildYellow,
      },
      backgroundColor: 'none',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9990 }),
  };

  useEffect(() => {
    if (showAddProjectModal) {
      getProjectList();
    }
    setSelectedProjects([]);
  }, [showAddProjectModal]);

  /**
   * Update local selectedProjects from user changes
   *
   * @param event ChangeEvent input
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleUpdateProjects = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedProjects((state) => [...state, value]);
    } else {
      const newSelectedProjects = selectedProjects.filter(
        (code) => code !== value
      );
      setSelectedProjects(newSelectedProjects);
    }
  };

  /**
   * Close toggle layer
   */
  const handleCloseProjectsSelection = (): void =>
    setSelectProjectsToggleOpen(false);

  /**
   * Toggle project list open toggle layer
   */
  const handleProjectsSelectionToggle = (isOpen: boolean): void => {
    setSelectProjectsToggleOpen(!isOpen);
  };

  const handleCloseMenuOnScroll = (event: Event): boolean =>
    event.target !== menuListRef.current;

  /**
   * Renders a warning prompt when an attempt is made to add a user who does not
   * have access to countries involved in the project
   *
   * @returns {ReactNode | null} JSX snippet for the warning prompt
   */
  const renderCDAWarningPrompt = (): ReactNode | null => {
    if (userUnassignedCountries.length > 0) {
      const formattedCountries = userUnassignedCountries
        .map((country: string) => country.toUpperCase())
        .join(', ')
        .replace(/,\s([^,]+)$/, ' and $1');

      return (
        <Fade
          mountOnEnter
          unmountOnExit
          in={displayCDAWarningPrompt}
          className={styles.overlay}
        >
          <TrapFocus autoFocus>
            {({ firstFocus, lastFocus }): JSX.Element => (
              <div className={styles.confirmation}>
                <div className="text-14-medium text-center text-gray">
                  {userUnassignedCountries.length > 1
                    ? intl.getHTML(
                        'LBL_PROJECTS_USERS_CDA_MULTIPLE_COUNTRIES_WARNING',
                        {
                          countries: formattedCountries,
                        }
                      )
                    : intl.getHTML(
                        'LBL_PROJECTS_USERS_CDA_SINGLE_COUNTRY_WARNING',
                        {
                          country: formattedCountries,
                        }
                      )}
                </div>
                <Button
                  innerRef={firstFocus}
                  className="btn btn-warning btn-sm"
                  type="button"
                  onClick={(): void => onCDAWarningPromptAction(true)}
                >
                  {intl.get('BTN_PROJECT_USERS_WARNING_CONTINUE')}
                </Button>
                <Button
                  innerRef={lastFocus}
                  className="btn btn-light btn-sm"
                  onClick={(): void => onCDAWarningPromptAction(false)}
                >
                  {intl.get('BTN_CANCEL')}
                </Button>
              </div>
            )}
          </TrapFocus>
        </Fade>
      );
    }
    return null;
  };

  return (
    <Modal
      size="lg"
      isOpen={showAddProjectModal}
      toggle={toggleAddUserToProjectsModal}
      backdrop="static"
      centered
      keyboard={false}
      id="deactivateUserTarget"
    >
      <BlockUi
        tag="div"
        blocking={projectCompatibilityStatus === Status.Loading}
      >
        <ModalHeader className="increase-font truncate text-center">
          {intl.get('LBL_USER_PROFILE_CURRENT_PROJECTS_ADD_TO_PROJECT_HEADER')}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="8">
              <label className="text-12-semibold text-uppercase">
                {intl.get('LBL_USER_PROFILE_ADD_PROJECTS_LABEL')}
              </label>
              <div className="select-group">
                <ProjectsSelect
                  selectProjectsToggleOpen={selectProjectsToggleOpen}
                  onChange={handleUpdateProjects}
                  onClose={handleCloseProjectsSelection}
                  onToggle={handleProjectsSelectionToggle}
                  selectedProjects={selectedProjects}
                  currentProjects={projectsData.map((p) => p.projectId)}
                />
              </div>
            </Col>
            <Col xs="4">
              <label className="text-12-semibold text-uppercase">
                {intl.get('LBL_USER_PROFILE_COUNTRY_DATA_ACCESS_LABEL')}
              </label>
              <div className="insight-select-group">
                <Select
                  className="expand-height"
                  isSearchable={false}
                  options={accessibleCountries}
                  menuPortalTarget={document.body}
                  placeholder={countryPlaceholder}
                  menuPosition="fixed"
                  closeMenuOnScroll={handleCloseMenuOnScroll}
                  menuListRef={menuListRef}
                  isDisabled={!userCountryData}
                  name="countries"
                  styles={customStyles}
                  classNamePrefix="insight-select insight-select-projects"
                  components={{ MenuList: CountriesSelectMenuList }}
                />
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-5">
            <Button
              type="button"
              onClick={() => {
                toggleAddUserToProjectsModal();
              }}
              className="btn mr-3"
            >
              {intl.get('BTN_CANCEL')}
            </Button>
            <Button
              type="button"
              onClick={() => {
                handleCloseProjectsSelection();
                onCreateClick(selectedProjects);
              }}
              className="btn btn-primary"
              disabled={selectedProjects.length === 0}
            >
              {intl.get('BTN_CREATE')}
            </Button>
          </Row>
        </ModalBody>
        {renderCDAWarningPrompt()}
      </BlockUi>
    </Modal>
  );
};
export default AddProjectsModal;
