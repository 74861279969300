/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, FC as ReactFC } from 'react';

import * as d3 from 'd3';
import ReactTooltip from 'react-tooltip';

import { getColorFor } from 'helpers/ColorHelper';
import { getFormattedNumber } from 'helpers/NumberFormat';
import D3Value from 'shared/components/ins-charts/group-bar/D3Value';
import GroupBarProps from 'shared/components/ins-charts/group-bar/GroupBarProps';
import GroupBarValue from 'shared/components/ins-charts/group-bar/GroupBarValue';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

const GroupBar: ReactFC<GroupBarProps> = (props) => {
  const { values, dimension } = props;
  const { ref, computedDimension } = useDimension(dimension);
  const { width, height } = computedDimension;

  useEffect(() => {
    const draw = (): void => {
      const defaultOpacity = 1;
      const selectedOpacity = 0.6;
      const animationDuration = 300;
      const xAxisTextHeight = 18;
      const xAxisMargin = xAxisTextHeight + 10;
      const barTopRadius = 4;
      const chartBarHeight = (height > 320 ? height : 320) - xAxisMargin;

      const keys: string[] = Array.from(values.keys());
      const categories =
        (values.get(keys[0]) ? values?.get(keys[0])?.map((d) => d.name) : []) ||
        [];

      const interGroupGap = (width / 16 / keys.length) * 3;

      const x0: d3.ScaleBand<string> = d3
        .scaleBand()
        .range([0, width])
        .padding(0.05);
      const x1: d3.ScaleBand<string> = d3.scaleBand();
      const y: d3.ScaleLinear<number, number> = d3
        .scaleLinear()
        .rangeRound([0, chartBarHeight]);

      x0.domain(keys);
      x1.domain(categories).rangeRound([
        interGroupGap,
        x0.bandwidth() - interGroupGap,
      ]);
      y.domain([-0.5, 100]);

      const xAxis: d3.Axis<string> = d3.axisBottom(x0).tickValues(keys);

      const svg = d3
        .select(ref.current)
        .html('')
        .append('svg')
        .attr('width', width)
        .attr('height', chartBarHeight + xAxisMargin)
        .style('margin', '0px auto')
        .style('display', 'block');

      const svgg = svg.append('g').attr('transform', `translate(0,0)`);

      const xAxisG = svgg
        .append('g')
        .append('g')
        .attr('class', 'group-bar-x-axis')
        .attr(
          'transform',
          `translate(0,${chartBarHeight + xAxisMargin - xAxisTextHeight})`
        )
        .call(xAxis);

      xAxisG.select('path').style('display', 'none');
      xAxisG.selectAll('g').select('line').style('display', 'none ');

      const d3Values = keys.map((k) => ({
        key: k,
        values: values.get(k) || [],
      }));

      const getFirstLetterCapitalized = (text: string): string =>
        text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

      const tooltipGenerator = (val: GroupBarValue): string => {
        const { value, name } = val;
        const formattedName = getFirstLetterCapitalized(name);
        return `
        <div>
          <span class="tool-tip-label">${formattedName}:</span>
          <span class="tool-tip-value">${getFormattedNumber(
            value,
            true
          )}%</span>
        </div>
        `;
      };

      const showTooltip = function (this: any): void {
        d3.select(this)
          .transition()
          .duration(animationDuration)
          .attr('opacity', selectedOpacity);
      };

      const hideTooltip = function (this: any): void {
        d3.select(this)
          .transition()
          .duration(animationDuration)
          .attr('opacity', defaultOpacity);
      };

      const slice = svgg
        .selectAll<SVGGElement, D3Value>('.slice')
        .data(d3Values)
        .enter()
        .append('g')
        .attr('class', 'g')
        .attr('transform', (d) => `translate(${Number(x0(d.key))},0)`);

      let currentGroup = 0;

      slice
        .selectAll('.rect-g')
        .data((d) => d.values)
        .enter()
        .append('g')
        .attr('class', 'rect-g')
        .append('rect')
        .attr('data-for', 'svgTooltip')
        .attr('data-tip', (d) => tooltipGenerator(d))
        .on('mouseenter', showTooltip)
        .on('mouseleave', hideTooltip)
        .style('fill', (d) => getColorFor(d.name.toUpperCase()))
        .attr('width', x1.bandwidth())
        // values are assigned to not show bars (height = 0)
        // later transition will be applied with real values
        .attr('x', (d) => x1(d.name) || 0)
        .attr('y', (d) => chartBarHeight - (y(d.value) || 0))
        .attr('height', (d) => y(d.value))
        .attr('clip-path', (d, i: number) => {
          currentGroup += 1;
          return `url(#round-corner-${currentGroup}-${i})`;
        });

      // tracking current group to assign an unique id to clippath
      // and refer it from rectangle
      currentGroup = 0;

      //   creating a clipping path to get rounded top corners
      slice
        .selectAll<SVGGElement, GroupBarValue>('.rect-g')
        .append('defs')
        .append('clipPath')
        .attr('id', (d, i) => {
          currentGroup += 1;
          return `round-corner-${currentGroup}-${i}`;
        })
        .append('rect')
        .attr('width', x1.bandwidth() * 0.8)
        .attr('x', (d) => x1(d.name) || 0)
        .attr('y', (d) => chartBarHeight - (y(d.value) || 0))
        .attr('height', (d) => y(d.value) + barTopRadius)
        .attr('rx', barTopRadius)
        .attr('ry', barTopRadius);

      ReactTooltip.rebuild();
    };
    if (values && ref != null) {
      draw();
    }
  }, [height, ref, values, width]);

  return (
    <div ref={ref} className="ins-custom-chart-wrapper">
      <ReactTooltip type="light" id="svgTooltip2" html />
      <svg width={width} />
    </div>
  );
};

export default GroupBar;
