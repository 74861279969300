/* eslint-disable no-nested-ternary */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import history from 'router-history';
import VerificationError from 'shared/enums/VerificationError';
import emailSentAnim from 'shared/static/img/email-sent-animation.svg';

import styles from './verifyAccountError.module.scss';
import VerifyAccountErrorProps from './VerifyAccountErrorProps';

const VerifyAccountError: ReactFC<VerifyAccountErrorProps> = (
  props: VerifyAccountErrorProps
) => {
  const location = useLocation();
  const { emailSent, errorType, loading, expiryTimeString, handleEmailResend } =
    props;

  /**
   * Returns appropriate error message
   *
   * @returns {string} Error message
   */
  const renderErrorMessage = (): string => {
    if (errorType === VerificationError.EMAIL_DOES_NOT_EXIST) {
      return 'ERR_CA_VERIFY_ACCOUNT_EMAIL_DOES_NOT_EXIST';
    }
    return 'ERR_CA_VERIFY_ACCOUNT_CODE_INVALID';
  };

  /**
   * Handles navigating to dashboard
   */
  const handleNavigateToDashboard = (): void => {
    history.replace({
      pathname: `${ModulePaths.DashboardPath}`,
      search: getGlobalFiltersQuery(location.search),
    });
  };

  return (
    <>
      {errorType && errorType === VerificationError.EXPIRED_CODE ? (
        <>
          <ModalHeader className="increase-font text-center">
            {emailSent
              ? intl.get('LBL_CA_WE_SENT_VERIFICATION_CODE')
              : intl.get('LBL_CA_VERIFY_ACCOUNT_TITLE')}
          </ModalHeader>
          <ModalBody>
            {emailSent ? (
              <Row>
                <Col xs="12" className="d-flex justify-content-center">
                  <img alt="Email Sent" src={emailSentAnim} />
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col
                    xs="12"
                    className="d-flex justify-content-center mb-3 p-3"
                  >
                    <span className={styles.circle} />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <p className="form-title text-center text-gray">
                      {intl.get('ERR_CA_VERIFY_ACCOUNT_CODE_EXPIRED', {
                        expiry: expiryTimeString,
                      })}
                    </p>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              {emailSent && (
                <Col xs="12" className="mb-3 pb-3">
                  <p className="form-title w-50 text-center d-block m-auto">
                    {intl.get('LBL_CA_SENT_VERIFICATION_DESCRIPTION', {
                      expiry: expiryTimeString,
                    })}
                  </p>
                </Col>
              )}
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" className="mb-3 pb-3">
                <Button
                  color="primary"
                  onClick={handleEmailResend}
                  disabled={loading}
                >
                  {intl.get('BTN_CA_RESEND_EMAIL')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="text-center mt-3 pt-3">
                <small>
                  {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
                  <a
                    href="https://support.dreamstartlabs.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {intl.get('LBL_CA_CONTACT_SUPPORT')}
                  </a>
                </small>
              </Col>
            </Row>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader className="increase-font text-center">
            {errorType === VerificationError.EMAIL_ALREADY_VERIFIED
              ? intl.get('LBL_CA_VERIFY_ACCOUNT_EMAIL_ALREADY_VERIFIED')
              : intl.get('LBL_CA_VERIFY_ACCOUNT_TITLE')}
          </ModalHeader>
          <ModalBody>
            {errorType === VerificationError.EMAIL_ALREADY_VERIFIED ? (
              <Row className="justify-content-center mt-3 mb-3">
                <Col xs="auto">
                  <Button color="primary" onClick={handleNavigateToDashboard}>
                    {intl.get('BTN_CA_CONTINUE')}
                  </Button>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col
                    xs="12"
                    className="d-flex justify-content-center mb-3 p-3"
                  >
                    <span className={styles.circle} />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <p className="form-title text-center text-gray">
                      {intl.get(`${renderErrorMessage()}`)}
                    </p>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs="12" className="text-center mt-3 pt-3">
                <small>
                  {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
                  <a
                    href="https://support.dreamstartlabs.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {intl.get('LBL_CA_CONTACT_SUPPORT')}
                  </a>
                </small>
              </Col>
            </Row>
          </ModalBody>
        </>
      )}
    </>
  );
};

export default VerifyAccountError;
