enum MessagingViewpoint {
  UNKNOWN = 'UNKNOWN',
  OPENING_SUMMARY = 'OPENING_SUMMARY',
  END_MEETING = 'END_MEETING',
  MEMBER_ADDED = 'MEMBER_ADDED',
  MEMBER_REMOVED = 'MEMBER_REMOVED',
  MEETING_CREATE = 'MEETING_CREATE',
}

export default MessagingViewpoint;
