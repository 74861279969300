import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import styles from '../groupsFilterPanel.module.scss';
import GroupsFilterProps from './GroupsFilterProps';

const GroupsFilter: ReactFC<GroupsFilterProps> = (props: GroupsFilterProps) => {
  const { onChange, data, title, isDisabled, selected } = props;

  const values = selected.map(({ value }) => value);

  const inputName = title.replace(/\s+/g, '-').toLowerCase();

  const isEmpty = data.length <= 0;

  return (
    <div className="mb-4" data-cy="group-filter">
      <span className="text-12-bold">{title}</span>
      <Form className="mt-2">
        {isEmpty ? (
          <FormGroup className="insight-checkbox-group pb-2 pt-2">
            <Label className={`${styles.reduceOpacity}`}>
              <span className={`insight-checkbox mr-3 ${styles.checkbox}`}>
                <Input
                  type="checkbox"
                  tabIndex={-1}
                  name={`${inputName}-na`}
                  disabled
                />
                <i className="icon-check" />
              </span>
              <span className="text-12-medium">{intl.get('LBL_NA')}</span>
            </Label>
          </FormGroup>
        ) : (
          data.map(({ value, label }) => {
            const isChecked = values.includes(value);
            return (
              <FormGroup
                key={value}
                className="insight-checkbox-group pb-2 pt-2"
              >
                <Label className={`${isDisabled ? styles.reduceOpacity : ''}`}>
                  <span className={`insight-checkbox mr-3 ${styles.checkbox}`}>
                    <Input
                      type="checkbox"
                      tabIndex={0}
                      name={`${inputName}-${value}`}
                      value={value}
                      checked={isChecked}
                      onChange={(e): void => onChange(e, label)}
                      disabled={isDisabled}
                    />
                    <i className="icon-check" />
                  </span>
                  <EllipsisTooltip
                    tag="span"
                    data-place="left"
                    data-for="insTooltip"
                    data-tip={label}
                    data-class="overflow-wrap"
                    className="truncate text-12-medium"
                  >
                    {label}
                  </EllipsisTooltip>
                </Label>
              </FormGroup>
            );
          })
        )}
      </Form>
    </div>
  );
};

export default GroupsFilter;
