enum MessagingFilter {
  Countries = 'countries',
  Projects = 'projects',
  Facilitators = 'facilitators',
  GroupStatus = 'groupStatus',
  GroupAge = 'groupAge',
  LastMessaged = 'lastMessaged',
}

export default MessagingFilter;
