import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import SurveysToolbarProps from './SurveysToolbarProps';

const SurveysToolbar: ReactFC<SurveysToolbarProps> = (
  props: SurveysToolbarProps
) => {
  const { userDropdown } = props;

  return (
    <header className="main-header">
      <div className="header-col">
        <h2 className="main-title" style={{ display: 'flex' }}>
          {intl.get('LBL_SURVEYS_TITLE')}
        </h2>
      </div>
      <div className="header-col ml-auto">{userDropdown}</div>
    </header>
  );
};

export default SurveysToolbar;
