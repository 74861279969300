/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';

import WidgetPreferenceModal from 'modules/private/dashboard/components/widget-metrics/widget-preference-modal/WidgetPreferenceModal';
import AdditionalFundsWidget from 'modules/private/dashboard/components/widget-metrics/widgets/additional-funds-widget/AdditionalFundsWidget';
import AgeWidget from 'modules/private/dashboard/components/widget-metrics/widgets/age-widget/AgeWidget';
import AverageSavingsWidget from 'modules/private/dashboard/components/widget-metrics/widgets/average-savings-widget/AverageSavingsWidget';
import CumulativeSavingsWidget from 'modules/private/dashboard/components/widget-metrics/widgets/cumulative-savings-widget/CumulativeSavingsWidget';
import DependentsWidget from 'modules/private/dashboard/components/widget-metrics/widgets/dependents-widget/DependentsWidget';
import DreamStarLeaderBoardWidget from 'modules/private/dashboard/components/widget-metrics/widgets/dream-star-leader-board/DreamStarLeaderBoard';
import EducationWidget from 'modules/private/dashboard/components/widget-metrics/widgets/education-widget/EducationWidget';
import GenderWidget from 'modules/private/dashboard/components/widget-metrics/widgets/gender-widget/GenderWidget';
import GroupsFormedWidget from 'modules/private/dashboard/components/widget-metrics/widgets/groups-formed-widget/GroupsFormedWidget';
import LoanFundWidget from 'modules/private/dashboard/components/widget-metrics/widgets/loan-fund-widget/LoadFundWidget';
import LoanTypesWidget from 'modules/private/dashboard/components/widget-metrics/widgets/loan-types-widget/LoanTypesWidget';
import MemberChildrenWidget from 'modules/private/dashboard/components/widget-metrics/widgets/member-children-widget/MemberChildrenWidget';
import PersonalGoalAchievementWidget from 'modules/private/dashboard/components/widget-metrics/widgets/personal-goal-achievement-widget/PersonalGoalAchievementWidget';
import PersonalGoalTypeWidget from 'modules/private/dashboard/components/widget-metrics/widgets/personal-goal-type-widget/PersonalGoalTypeWidget';
import SavingsLeaderBoardWidget from 'modules/private/dashboard/components/widget-metrics/widgets/savings-leader-board/SavingsLeaderBoard';
import WidgetConfigs from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetConfigs';
import WidgetProps from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetProps';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import WidgetType from 'modules/private/dashboard/components/widget-metrics/widgets/WidgetType';

const Widget: ReactFC<WidgetProps> = (props) => {
  const {
    data,
    dashboardSetupInProgress,
    widgetsLoading,
    selectedWidgets,
    updateWidgetData,
    startDashboardSetup,
  } = props;

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  /**
   * Get widget name
   *
   * @param t Widget type
   * @returns {string} Widget name
   */
  const name = (t: WidgetType): string => WidgetConfigs.GetWidgetConfig(t).name;

  /**
   * Get widget tooltip label
   *
   * @param t Widget type
   * @returns {string} Widget tooltip label
   */
  const tooltip = (t: WidgetType): string =>
    WidgetConfigs.GetWidgetConfig(t).tooltip;

  /**
   * Handles updating widget data and closing settings modal
   *
   * @param d Widget data
   */
  const saveAndCloseSettings = (d: WidgetViewModel): void => {
    updateWidgetData(d);
    setShowSettingsModal(false);
  };

  /**
   * Handles opening settings modal
   */
  const showSettings = (): void => {
    setShowSettingsModal(true);
  };

  /**
   * Handles setting goals
   */
  const setGoal = (): void => {
    setShowSettingsModal(true);
    startDashboardSetup();
  };

  /**
   * Handles toggling settings modal
   */
  const toggleModal = (): void => setShowSettingsModal(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  /**
   * Renders settings button
   *
   * @returns {JSX.Element} JSX snippet containing the settings button
   */
  const renderSettingsButton = (): JSX.Element => {
    if (dashboardSetupInProgress) {
      const button = (
        <Button className="btn btn-icon icon-settings" onClick={showSettings} />
      );
      if (
        !data.targetData &&
        (data.type === WidgetType.Groups ||
          data.type === WidgetType.AverageSavings ||
          data.type === WidgetType.CumulativeSavings ||
          data.type === WidgetType.LoanFund)
      ) {
        return <></>;
      }
      return button;
    }
    return <></>;
  };

  return (
    <div
      className={`col col-xl-3 col-lg-6 flex-col ${
        dashboardSetupInProgress
          ? 'active-dashboard-widget'
          : 'dashboard-widget'
      }`}
    >
      <BlockUi tag="div" blocking={widgetsLoading} className="flex-col-inner">
        <div className="content-box">
          <div className="content-box-title">
            <h3
              className={
                dashboardSetupInProgress ? 'main-title' : 'widget-title'
              }
            >
              {name(data.type)}
              <span className="help">
                <i
                  className="icon-help"
                  data-for="insTooltip"
                  data-tip={tooltip(data.type)}
                />
              </span>
            </h3>

            {renderSettingsButton()}
          </div>

          {!data.pendingChanges && data.type === WidgetType.Gender && (
            <GenderWidget data={data} />
          )}
          {!data.pendingChanges && data.type === WidgetType.Education && (
            <EducationWidget data={data} />
          )}
          {!data.pendingChanges &&
            data.type === WidgetType.PersonalGoalAchievement && (
              <PersonalGoalAchievementWidget data={data} />
            )}
          {!data.pendingChanges && data.type === WidgetType.LoanType && (
            <LoanTypesWidget data={data} />
          )}
          {!data.pendingChanges &&
            data.type === WidgetType.SavingsLeaderBoard && (
              <SavingsLeaderBoardWidget data={data} />
            )}
          {!data.pendingChanges &&
            data.type === WidgetType.DreamStartLeaderBoard && (
              <DreamStarLeaderBoardWidget data={data} />
            )}

          {!data.pendingChanges &&
            data.targetData &&
            data.type === WidgetType.Groups &&
            data.value && (
              <GroupsFormedWidget
                data={data}
                setupInProgress={dashboardSetupInProgress}
              />
            )}

          {!data.pendingChanges &&
            data.targetData &&
            data.type === WidgetType.AverageSavings &&
            data.value && (
              <AverageSavingsWidget
                data={data}
                setupInProgress={dashboardSetupInProgress}
              />
            )}

          {!data.pendingChanges &&
            data.targetData &&
            data.type === WidgetType.CumulativeSavings &&
            data.value && (
              <CumulativeSavingsWidget
                data={data}
                setupInProgress={dashboardSetupInProgress}
              />
            )}

          {!data.pendingChanges &&
            data.targetData &&
            data.type === WidgetType.LoanFund &&
            data.value && (
              <LoanFundWidget
                data={data}
                setupInProgress={dashboardSetupInProgress}
              />
            )}

          {!data.pendingChanges &&
            data.type === WidgetType.PersonalGoalType && (
              <PersonalGoalTypeWidget data={data} />
            )}

          {!data.pendingChanges && data.type === WidgetType.Age && (
            <AgeWidget data={data} />
          )}

          {!data.pendingChanges && data.type === WidgetType.Dependents && (
            <DependentsWidget data={data} />
          )}

          {!data.pendingChanges && data.type === WidgetType.MemberChildren && (
            <MemberChildrenWidget data={data} />
          )}

          {!data.pendingChanges && data.type === WidgetType.AdditionalFunds && (
            <AdditionalFundsWidget data={data} />
          )}

          {data.pendingChanges && (
            <div className="pending-changes">
              <i className="icon-analytics" />
              <span>{intl.get('LBL_SAVE_WIDGETS_TO_VIEW_NEW_DATA')}</span>
            </div>
          )}

          {!data.targetData &&
            (data.type === WidgetType.Groups ||
              data.type === WidgetType.AverageSavings ||
              data.type === WidgetType.CumulativeSavings ||
              data.type === WidgetType.LoanFund) && (
              <div className="empty-chart-container">
                <Button className="btn header-btn" onClick={setGoal}>
                  <i className="icon-settings" />
                  {intl.get('BTN_SET_A_GOAL')}
                </Button>
              </div>
            )}
        </div>
        <WidgetPreferenceModal
          data={data}
          show={showSettingsModal}
          save={saveAndCloseSettings}
          toggle={toggleModal}
          selectedWidgets={selectedWidgets}
        />
      </BlockUi>
    </div>
  );
};

export default Widget;
