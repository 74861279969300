import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import styles from '../usersDataTable.module.scss';
import DummyRowUserProps from './DummyRowUserProps';

const DummyRowUser: ReactFC<DummyRowUserProps> = (props: DummyRowUserProps) => {
  const { headers, isError } = props;

  const headersList = headers[0].headers;

  return (
    <tr>
      {headersList.map((cell) => {
        if (cell.id === 'name') {
          return (
            <td
              key={cell.id}
              className={cell.className}
              data-title={cell.dataTitle}
            >
              <span className={`wrap-content left-align ${styles.wrapContent}`}>
                <span
                  className="table-content text-14-semibold"
                  style={{ overflow: 'visible' }}
                >
                  {isError
                    ? intl.get('LBL_NA')
                    : intl.get('LBL_NO_USERS_ADDED')}
                </span>
              </span>
            </td>
          );
        }
        return (
          <td
            key={cell.id}
            className={cell.className}
            data-title={cell.dataTitle}
          >
            <span className={`wrap-content ${styles.wrapContent}`}>
              <span className="table-content text-14-semibold">
                {isError ? intl.get('LBL_NA') : '—'}
              </span>
            </span>
          </td>
        );
      })}
    </tr>
  );
};

export default DummyRowUser;
