import { useContext, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import ModalTypeKeysGA from 'constants/ga/ModalTypeKeysGA';
import AppContext from 'context/AppContext';
import { modalViewGA, pageViewGA } from 'helpers/GoogleAnalyticsHelper';
import PermissionsListView from 'shared/modules/permissions/containers/permissions-list-view/PermissionsListView';

import styles from './permissionModal.module.scss';
import PermissionModalProps from './PermissionModalProps';

const PermissionModal: ReactFC<PermissionModalProps> = (
  props: PermissionModalProps
) => {
  const {
    isOpen,
    onToggle,
    fetchPermissionLevels,
    canAddPermissions,
    canEditPermissions,
    permissionsFirstTime,
    getGettingStartedState,
  } = props;

  // here using useContext because this is an isolated component
  const appContext = useContext(AppContext);

  const location = useLocation();

  /**
   * Handle open modal
   */
  const handleOpened = (): void => {
    modalViewGA(
      `${location.pathname + location.search}/${ModalTypeKeysGA.Permissions}`
    );
  };

  /**
   * Handle close modal
   */
  const handleClosed = (): void => {
    fetchPermissionLevels();
    pageViewGA(location.pathname);
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      centered
      toggle={onToggle}
      returnFocusAfterClose
      onOpened={handleOpened}
      onClosed={handleClosed}
      backdrop="static"
    >
      <ModalHeader className="border-0 increase-font" toggle={onToggle}>
        {intl.get('LBL_SETTINGS_PERMISSION_LEVELS')}
      </ModalHeader>
      <ModalBody className={styles.body}>
        <div>
          <PermissionsListView
            isModalOpen={isOpen}
            canAddPermissions={canAddPermissions}
            canEditPermissions={canEditPermissions}
            isFirstTime={permissionsFirstTime}
            togglePermissionsModal={onToggle}
            isModalView
            getGettingStartedState={getGettingStartedState}
            appContext={appContext}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PermissionModal;
