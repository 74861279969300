enum TooltipType {
  // Surveys tooltips
  SendSurvey = 'SEND_SURVEY',
  HistoryCollectIDStatusChange = 'HISTORY_COLLECT_ID_STATUS_CHANGE',

  // Project Tooltips
  AssignUser = 'ASSIGN_USER',
  AssignFacilitator = 'ASSIGN_FACILITATOR',
  AssignGroup = 'ASSIGN_GROUP',

  // Currency Tooltips
  CurrencyToggle = 'CURRENCY_TOGGLE_CLICKED',
}

export default TooltipType;
