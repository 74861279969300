import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import MessagingFilter from 'shared/enums/MessagingFilter';

import GroupsFilter from '../groups-filter/GroupsFilter';
import ProjectsFilterProps from './ProjectsFilterProps';

const ProjectsFilter: ReactFC<ProjectsFilterProps> = (
  props: ProjectsFilterProps
) => {
  const { projectsData, isDisabled, selected, onChange } = props;

  return (
    <GroupsFilter
      title={intl.get('LBL_MESSAGING_COMPOSE_PROJECTS_FILTER_TITLE')}
      data={projectsData.data}
      onChange={(e, label: string): void =>
        onChange(e, MessagingFilter.Projects, label)
      }
      isDisabled={isDisabled}
      selected={selected}
    />
  );
};

export default ProjectsFilter;
