import { FC as ReactFC } from 'react';

import _ from 'lodash';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { FormGroup, Label } from 'reactstrap';

import Status from 'shared/enums/Status';

import styles from '../../../containers/messaging-view/messagingView.module.scss';
import GroupFilterTag from './group-filter-tag/GroupFilterTag';
import GroupsFilterTagContainerProps from './GroupsFilterTagContainerProps';

const GroupsFilterTagContainer: ReactFC<GroupsFilterTagContainerProps> = (
  props: GroupsFilterTagContainerProps
) => {
  const { status, totalCount, groupFilters, isMessageSendClicked } = props;

  return (
    <>
      <FormGroup className="mb-0">
        <Label>
          {intl.get('LBL_MESSAGING_COMPOSE_SELECTED_GROUPS')}
          <span className="text-14-bold">
            {intl.get('LBL_MESSAGING_COMPOSE_SELECTED_GROUPS_COUNT', {
              selectedGroupCount: totalCount,
            })}
          </span>
        </Label>
        <BlockUi tag="div" blocking={status === Status.Loading}>
          <div>
            <div className={styles.groupFiltersTagWrapper}>
              {_.isEmpty(groupFilters) ? (
                <span className={`${styles.filtersPlaceholder} text-16-medium`}>
                  {intl.get(
                    'LBL_MESSAGING_COMPOSE_SELECTED_FILTERS_PLACEHOLDER'
                  )}
                </span>
              ) : (
                groupFilters.map((group, index) => (
                  <GroupFilterTag
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}_${group.value}`}
                    groupFilter={group}
                  />
                ))
              )}
            </div>
            {totalCount === 0 && isMessageSendClicked && (
              <label className="form-error">
                {intl.get('ERR_MESSAGING_SELECTED_GROUP_COUNT_ZERO')}
              </label>
            )}
          </div>
        </BlockUi>
      </FormGroup>
    </>
  );
};

export default GroupsFilterTagContainer;
