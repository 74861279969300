import { ErrorStatus } from 'helpers/ErrorFormat';
import Status from 'shared/enums/Status';

export enum SetNewPasswordStatusError {
  ExpiredCode = 11013,
  InvalidCode = 11012,
  NonexistentEmail = 11006,
  DefaultError = 10000,
}

interface SetNewPasswordErrorProps {
  email: string;
  isEmailResent: boolean;
  emailResendStatus: Status;
  errorStatus: ErrorStatus;
  expiryTimeString: string;
  onResendEmailClick: (email: string) => void;
}

export default SetNewPasswordErrorProps;
