import { ReactNode, useEffect, FC as ReactFC } from 'react';

import noop from 'lodash/noop';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Col, Row } from 'reactstrap';

import ImageComponent from 'shared/components/image/ImageComponent';
import Status from 'shared/enums/Status';
import EmptyStateIllustration from 'shared/static/img/no-existing-users-illustration.svg';
import emptyStateThumb from 'shared/static/img/thumbs/no_existing_users_thumb.png';

import ExistingFacilitatorsListItem from './existing-facilitators-list-item/ExistingFacilitatorsListItem';
import styles from './existingFacilitatorsList.module.scss';
import ExistingFacilitatorsListProps from './ExistingFacilitatorsListProps';

const ExistingFacilitatorsList: ReactFC<ExistingFacilitatorsListProps> = (
  props: ExistingFacilitatorsListProps
) => {
  const {
    status,
    existingFacilitators,
    newFacilitators,
    removeNewFacilitatorFromList,
  } = props;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [existingFacilitators, newFacilitators]);

  /**
   * Renders the empty state for the existing user list
   *
   * @returns {ReactNode} JSX snippet containing the empty state icon and message
   */
  const renderEmptyState = (): ReactNode =>
    status === Status.Loading ? (
      <div />
    ) : (
      <Row className={`d-flex justify-content-center ${styles.empty}`}>
        <Col xs="auto">
          <ImageComponent
            loading="eager"
            src={EmptyStateIllustration}
            alt="No Existing Facilitators"
            thumb={emptyStateThumb}
          />
        </Col>
        <Col xs="4">
          <h4 className="text-14-semibold">
            {intl.get('LBL_PROJECTS_FACILITATORS_ADD_LIST_EMPTY_TITLE')}
          </h4>
          <p className="text-14-medium text-gray">
            {intl.get('LBL_PROJECTS_FACILITATORS_ADD_LIST_EMPTY_DESCRIPTION')}
          </p>
        </Col>
      </Row>
    );

  return (
    <div className={styles.wrap}>
      {existingFacilitators.length + newFacilitators.length > 3 ? (
        <span className={styles.track} />
      ) : (
        ''
      )}
      {existingFacilitators.length > 0 || newFacilitators.length > 0 ? (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          className={`${styles.scroll} ${
            existingFacilitators.length + newFacilitators.length > 3
              ? styles.addRightPadding
              : ''
          }`}
        >
          <table className={`insight-table ${styles.table}`}>
            <thead>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th />
                <th>
                  <span>
                    {intl.get('LBL_PROJECTS_FACILITATORS_ADD_LIST_NAME_HEADER')}
                  </span>
                </th>
                <th>
                  <span>
                    {intl.get(
                      'LBL_PROJECTS_FACILITATORS_ADD_LIST_SUPERVISED_BY_HEADER'
                    )}
                  </span>
                </th>
                <th>
                  <span>
                    {intl.get(
                      'LBL_PROJECTS_FACILITATORS_ADD_LIST_TELEPHONE_NUMBER_HEADER'
                    )}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {newFacilitators
                .map((newFacilitator) => (
                  <ExistingFacilitatorsListItem
                    key={newFacilitator.id}
                    facilitator={newFacilitator}
                    removeNewFacilitatorFromList={removeNewFacilitatorFromList}
                    isNewlyAdded
                  />
                ))
                .reverse()}
              {existingFacilitators
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((existingFacilitator) => (
                  <ExistingFacilitatorsListItem
                    key={existingFacilitator.id}
                    facilitator={existingFacilitator}
                    removeNewFacilitatorFromList={noop}
                    isNewlyAdded={false}
                  />
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        renderEmptyState()
      )}
    </div>
  );
};

export default ExistingFacilitatorsList;
