/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import Select, { ValueType } from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Label } from 'reactstrap';

import NotificationOption from './notification-option/NotificationOption';
import Option from './NotificationFilterOption';
import NotificationSelectProps from './NotificationSelectProps';

const NotificationSelect: ReactFC<NotificationSelectProps> = (
  props: NotificationSelectProps
) => {
  const { value, isLoading, placeholder, onChange, options } = props;

  const normalized = (value as Option[]) ?? [];

  const currentPlaceholder =
    normalized.length > 0
      ? normalized.map((item) => item.label).join(', ')
      : placeholder;

  /**
   * Handles notification select change event
   * @param values Selected notification values
   */
  const handleChange = (values: ValueType<Option>): void => {
    if (values) {
      const currentValues = (values as Option[]).map((key) => key.value);
      onChange(currentValues);
    } else {
      onChange([]);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [options.length]);

  const optionsWithoutZeros = options.filter(
    (option: Option) => option.count > 0
  );

  /**
   * error when all non-zero count notifications are not selected
   */
  const hasError = optionsWithoutZeros.some(
    (option: Option) =>
      !value.map((item: Option) => item.value).includes(option.value)
  );

  const controlDisabled = isLoading || optionsWithoutZeros.length <= 0;

  return (
    <div className="form-group insight-select-group">
      <Label
        htmlFor="notificationFilter"
        className="d-flex align-items-center mb-2"
      >
        {intl.get('LBL_GD_NOTIFICATIONS')}
        {hasError && (
          <i
            data-for="insTooltip"
            data-tip={intl.get('ERR_GROUPS_NEED_ATTENTION')}
            data-place="bottom"
            data-class="error"
            className="icon-warning text-orange ml-3 icon-text-22"
          />
        )}
      </Label>
      <Select
        {...props}
        classNamePrefix="insight-select"
        className={`${hasError ? 'select-error' : ''} custom-menu-2 new-styles`}
        options={optionsWithoutZeros}
        onChange={handleChange}
        isMulti
        isSearchable={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        isDisabled={controlDisabled}
        isClearable={false}
        placeholder={currentPlaceholder}
        components={{
          Option: NotificationOption,
        }}
      />
    </div>
  );
};

export default NotificationSelect;
