import { ReactNode, useRef, useState, FC as ReactFC } from 'react';

import { FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import {
  Button,
  Fade,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import PermissionUtil from 'helpers/PermissionUtil';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';
import Status from 'shared/enums/Status';
import PermissionModal from 'shared/modules/permissions/components/permissions-modal/PermissionModal';

import AddExistingUsers from './add-existing-users/AddExistingUsers';
import styles from './addUsersModal.module.scss';
import AddUsersModalProps from './AddUsersModalProps';
import AddUsersType from './AddUsersType';
import InviteProjectUsers from './invite-project-users/InviteProjectUsers';
import { InviteProjectUsersFormValues } from './invite-project-users/ProjectUsersFormValue';

const AddUsersModal: ReactFC<AddUsersModalProps> = (
  props: AddUsersModalProps
) => {
  const {
    appContext,
    projectId,
    projectName,
    existingUsers,
    addExistingStatus,
    addExistingError,
    isOpen,
    createUsersStatus,
    jobRoles,
    jobRolesStatus,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    countries,
    countriesStatus,
    permissionLevels,
    permissionLevelsStatus,
    createJobRoleStatus,
    displayCDAWarningPrompt,
    canAddUserToList,
    selectedUser,
    userCompatibilityStatus,
    userUnassignedCountries,
    createJobRoleError,
    fetchOrgUsers,
    onAddExistingUsers,
    onToggle,
    onSuccess,
    onCreateUsers,
    onFetchPermissionLevels,
    onCreateJobRole,
    onFetchSupervisors,
    onCDAWarningPromptAction,
    onAddUserToListSuccess,
    onFetchUserProjectCompatibility,
    clearJobRoleError,
  } = props;

  const { permissionsData, gettingStartedStates, getGettingStartedState } =
    appContext;
  const { claims } = permissionsData;

  const [selectedTab, setSelectedTab] = useState<AddUsersType>(
    AddUsersType.AddExistingUsers
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [displayWarningPrompt, setDisplayWarningPrompt] =
    useState<boolean>(false);

  const [isPermissionsModalOpen, setIsPermissionsModalOpen] =
    useState<boolean>(false);

  const togglePermissionsModal = (): void => {
    setIsPermissionsModalOpen(!isPermissionsModalOpen);
  };

  const canAddPermissions = PermissionUtil.Can(
    claims,
    ResourceKeys.SettingsPermissionsAddNewLevel
  );

  const canEditPermissions = PermissionUtil.Can(
    claims,
    ResourceKeys.SettingsPermissionsItemEdit
  );

  const permissionsFirstTime = gettingStartedStates.data
    ? !gettingStartedStates.data.permissionCompleted
    : false;

  const innerFormikRef =
    useRef<FormikProps<InviteProjectUsersFormValues> | null>(null);

  /**
   * Handles switching the tab between adding existing users and inviting new users
   *
   * @param tab The tab to be switched to
   */
  const onTabSwitch = (tab: AddUsersType): void => {
    /**
     * here InviteProjectUsers form dirty status is directly
     * checked off of a formik ref @innerFormikRef , @hasUnsavedChanges
     * is used for AddExistingUsers dirty status check; room to improve
     */
    const formikData = innerFormikRef.current;
    if (hasUnsavedChanges) {
      setDisplayWarningPrompt(true);
    } else if (formikData && formikData.dirty) {
      /**
       * here we explicitly compare initial users array and most recent users array
       * specifically without {id} property; because id is a dynamic property and
       * not a piece of actual form data it will also be counted as a change making
       * dirty check not enough to determine this; this issue occurs specially when
       * a users add multiple form items and removes the top form item; leaving only
       * one form item, making it practically identical top initial values; except
       * for the changed {id} property which the user knows nothing about
       */
      // prettier-ignore
      const initial = formikData.initialValues?.users?.map(({ id, ...rest }) => rest) ?? [];
      // prettier-ignore
      const current = formikData.values?.users?.map(({ id, ...rest }) => rest) ?? [];

      if (isEqual(initial, current)) {
        setSelectedTab(tab);
      } else {
        setDisplayWarningPrompt(true);
      }
    } else {
      setSelectedTab(tab);
    }
  };

  /**
   * Handles switching the tab when user confirms that existing work can be reset
   */
  const onConfirmTabSwitch = (): void => {
    setHasUnsavedChanges(false);
    setDisplayWarningPrompt(false);
    if (selectedTab === AddUsersType.AddExistingUsers) {
      setSelectedTab(AddUsersType.InviteNewUsers);
    } else {
      setSelectedTab(AddUsersType.AddExistingUsers);
    }
  };

  /**
   * Handles closing the warning prompt
   */
  const onCancelPrompt = (): void => {
    setDisplayWarningPrompt(false);
  };

  /**
   * reset component state on exit
   */
  const handleClosed = (): void => {
    setIsPermissionsModalOpen(false);
    setSelectedTab(AddUsersType.AddExistingUsers);
    setDisplayWarningPrompt(false);
    setHasUnsavedChanges(false);
  };

  /**
   * Renders a warning prompt when an attempt is made to add a user who does not
   * have access to countries involved in the project
   *
   * @returns {ReactNode | null} JSX snippet for the warning prompt
   */
  const renderCDAWarningPrompt = (): ReactNode | null => {
    if (userUnassignedCountries.length > 0) {
      const formattedCountries = userUnassignedCountries
        .map((country: string) => country.toUpperCase())
        .join(', ')
        .replace(/,\s([^,]+)$/, ' and $1');

      return (
        <Fade
          mountOnEnter
          unmountOnExit
          in={displayCDAWarningPrompt}
          className={styles.overlay}
        >
          <TrapFocus autoFocus>
            {({ firstFocus, lastFocus }): JSX.Element => (
              <div className={styles.confirmation}>
                <div className="text-14-medium text-center text-gray">
                  {userUnassignedCountries.length > 1
                    ? intl.getHTML(
                        'LBL_PROJECTS_USERS_CDA_MULTIPLE_COUNTRIES_WARNING',
                        {
                          countries: formattedCountries,
                        }
                      )
                    : intl.getHTML(
                        'LBL_PROJECTS_USERS_CDA_SINGLE_COUNTRY_WARNING',
                        {
                          country: formattedCountries,
                        }
                      )}
                </div>
                <Button
                  innerRef={firstFocus}
                  className="btn btn-warning btn-sm"
                  type="button"
                  onClick={(): void => onCDAWarningPromptAction(true)}
                >
                  {intl.get('BTN_PROJECT_USERS_WARNING_CONTINUE')}
                </Button>
                <Button
                  innerRef={lastFocus}
                  className="btn btn-light btn-sm"
                  onClick={(): void => onCDAWarningPromptAction(false)}
                >
                  {intl.get('BTN_CANCEL')}
                </Button>
              </div>
            )}
          </TrapFocus>
        </Fade>
      );
    }
    return null;
  };

  /**
   * Renders a warning prompt when the user attempts to switch the tab with existing changes
   *
   * @returns {ReactNode} JSX snippet for the warning prompt
   */
  const renderWarningPrompt = (): ReactNode => (
    <Fade
      mountOnEnter
      unmountOnExit
      in={displayWarningPrompt}
      className={styles.overlay}
    >
      <TrapFocus autoFocus>
        {({ firstFocus, lastFocus }): JSX.Element => (
          <div className={styles.confirmation}>
            <div className="text-14-medium text-center">
              {intl.get('LBL_PROJECTS_USERS_ADD_UNSAVED_WARNING')}
            </div>
            <Button
              innerRef={firstFocus}
              className="btn btn-warning btn-sm"
              type="button"
              onClick={onConfirmTabSwitch}
            >
              {intl.get('BTN_PROJECT_USERS_WARNING_CONTINUE')}
            </Button>
            <Button
              innerRef={lastFocus}
              className="btn btn-light btn-sm"
              onClick={onCancelPrompt}
            >
              {intl.get('BTN_CANCEL')}
            </Button>
          </div>
        )}
      </TrapFocus>
    </Fade>
  );

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        toggle={onToggle}
        backdrop="static"
        centered
        autoFocus={false}
        trapFocus
        onClosed={handleClosed}
        keyboard={false}
        id="warningPromptTarget"
      >
        <BlockUi
          tag="div"
          blocking={
            addExistingStatus === Status.Loading ||
            createUsersStatus === Status.Loading ||
            userCompatibilityStatus === Status.Loading
          }
        >
          <ModalHeader className="increase-font text-center border-0 pb-0">
            {projectName && (
              <>
                {intl.get('LBL_PROJECT_USERS_ADD_MODAL_TITLE')}
                <EllipsisTooltip
                  tag="span"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-class="overflow-wrap bring-it-up"
                  data-tip={projectName}
                  className="truncate"
                >
                  {`'${projectName}'`}
                </EllipsisTooltip>
              </>
            )}
          </ModalHeader>
          <ModalBody>
            <Row className="insight-tab">
              <ul>
                <li>
                  <Button
                    className="text-18-semibold"
                    onClick={(): void =>
                      onTabSwitch(AddUsersType.AddExistingUsers)
                    }
                    active={selectedTab === AddUsersType.AddExistingUsers}
                  >
                    {intl.get('LBL_PROJECT_USERS_ADD_EXISTING_TAB_TITLE')}
                  </Button>
                </li>
                <li>
                  <Button
                    className="text-18-semibold"
                    onClick={(): void =>
                      onTabSwitch(AddUsersType.InviteNewUsers)
                    }
                    active={selectedTab === AddUsersType.InviteNewUsers}
                  >
                    {intl.get('LBL_PROJECT_USERS_INVITE_NEW_TITLE')}
                  </Button>
                </li>
              </ul>
            </Row>
            <TabContent activeTab={selectedTab}>
              <TabPane
                className={styles.wrapper}
                tabId={AddUsersType.AddExistingUsers}
              >
                {selectedTab === AddUsersType.AddExistingUsers && (
                  <AddExistingUsers
                    projectId={projectId}
                    selectedUser={selectedUser}
                    existingUsers={existingUsers}
                    status={addExistingStatus}
                    error={addExistingError}
                    canAddUserToList={canAddUserToList}
                    countryDataWarningOpen={displayCDAWarningPrompt}
                    fetchOrgUsers={fetchOrgUsers}
                    onAddUserToListSuccess={onAddUserToListSuccess}
                    onFetchUserProjectCompatibility={
                      onFetchUserProjectCompatibility
                    }
                    handleAddUsers={onAddExistingUsers}
                    onAddUsersSuccess={onSuccess}
                    onFetchPermissionLevels={onFetchPermissionLevels}
                    hasUnsavedChanges={hasUnsavedChanges}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    togglePermissionsModal={togglePermissionsModal}
                    onToggle={onToggle}
                  />
                )}
              </TabPane>
              <TabPane
                className={styles.wrapper}
                tabId={AddUsersType.InviteNewUsers}
              >
                {selectedTab === AddUsersType.InviteNewUsers && (
                  <InviteProjectUsers
                    innerFormikRef={innerFormikRef}
                    isOpen={isOpen}
                    projectId={projectId}
                    onToggle={onToggle}
                    onCreateUsers={onCreateUsers}
                    jobRoles={jobRoles}
                    jobRolesStatus={jobRolesStatus}
                    supervisors={supervisors}
                    supervisorsFiltered={supervisorsFiltered}
                    supervisorsStatus={supervisorsStatus}
                    countries={countries}
                    countriesStatus={countriesStatus}
                    permissionLevels={permissionLevels}
                    permissionLevelsStatus={permissionLevelsStatus}
                    onFetchPermissionLevels={onFetchPermissionLevels}
                    onCreateJobRole={onCreateJobRole}
                    createJobRoleStatus={createJobRoleStatus}
                    createJobRoleError={createJobRoleError}
                    onFetchSupervisors={onFetchSupervisors}
                    appContext={appContext}
                    onAddUsersSuccess={onSuccess}
                    clearJobRoleError={clearJobRoleError}
                  />
                )}
              </TabPane>
            </TabContent>
          </ModalBody>
        </BlockUi>
        {renderWarningPrompt()}
        {renderCDAWarningPrompt()}
      </Modal>
      <PermissionModal
        isOpen={isPermissionsModalOpen}
        onToggle={togglePermissionsModal}
        fetchPermissionLevels={onFetchPermissionLevels}
        canAddPermissions={canAddPermissions}
        canEditPermissions={canEditPermissions}
        permissionsFirstTime={permissionsFirstTime}
        getGettingStartedState={getGettingStartedState}
      />
    </>
  );
};

export default AddUsersModal;
