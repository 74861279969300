/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react';
import { Integrations, SpanStatus } from '@sentry/tracing';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Severity,
  Transaction,
  TransactionContext,
  SpanContext,
} from '@sentry/types';

import ApiError from 'api/common/types/ApiError';
import history from 'router-history';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import LoggingLevel from 'shared/enums/LoggingLevel';

import StorageKeys from '../../constants/StorageKeys';

type User = {
  username: string;
  firstName: string;
  lastName: string;
  orgId: string;
};

class SentryLogger {
  /**
   * Initialize sentry error logging and performance tracking
   *
   * @param loggingLevel Level of the logging enabled environment
   */
  init(loggingLevel: LoggingLevel): void {
    const user = this.fetchUser();
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN ?? '',
      enabled: true,
      environment: process.env.NODE_ENV,
      release: `dsl-ins-web@${String(process.env.REACT_APP_VERSION)}`,
      initialScope: {
        user: {
          ...user,
        },
        tags: {
          error: 'render',
        },
      },
      integrations:
        loggingLevel <= LoggingLevel.INFO
          ? [
              new Integrations.BrowserTracing({
                tracingOrigins: [process.env.REACT_APP_API_URL ?? ''],

                routingInstrumentation:
                  Sentry.reactRouterV5Instrumentation(history),
              }),
            ]
          : [],
      tracesSampleRate: 1.0,
      beforeSend: (event: Sentry.Event): Sentry.Event | null => {
        if (
          event &&
          event.exception &&
          event.exception.values &&
          event.exception.values[0].value?.includes('ResizeObserver')
        ) {
          return null;
        }
        return event;
      },
    });
  }

  /**
   * Return currently logged-in user's details
   *
   * @returns {User} Logged in user
   */
  fetchUser(): User {
    const username =
      AuthStorageService.GetItem<string>(StorageKeys.UserNameKey) ?? '';
    const firstName =
      AuthStorageService.GetItem<string>(StorageKeys.FirstNameKey) ?? '';
    const lastName =
      AuthStorageService.GetItem<string>(StorageKeys.LastNameKey) ?? '';
    const orgId =
      AuthStorageService.GetItem<string>(StorageKeys.OrganizationId) ?? '';
    return { username, firstName, lastName, orgId };
  }

  /**
   * Log API errors to Sentry
   *
   * @param error Thrown error
   * @param severity Severity of the error event
   * @param action Request action
   * @param method Request method
   */
  logError(
    error: ApiError | Error,
    severity: Sentry.Severity,
    action?: string,
    method?: string
  ): void {
    const { username, firstName, lastName, orgId } = this.fetchUser();

    const scope = new Sentry.Scope();
    if (action && method) {
      scope.setTransactionName(`${method} ${action}`);
    }
    scope.setTags({
      error: 'api',
      action: action ?? undefined,
      method: method ?? undefined,
    });
    scope.setLevel(severity);
    if (username && firstName && lastName && orgId) {
      scope.setUser({
        username,
        firstName,
        lastName,
        orgId,
      });
    }
    Sentry.captureException(error, scope);
  }

  /**
   * Log messages
   *
   * @param logMessage Message to be logged
   * @param loggingLevel Severity of the logging
   * @param args Additional data
   */
  logMessages({
    logMessage,
    loggingLevel,
    args = [],
  }: {
    logMessage: string;
    loggingLevel: LoggingLevel;
    args?: any[];
  }): void {
    const { username, firstName, lastName, orgId } = this.fetchUser();

    const sentrySeverity = this.mapLoggingLevelToSentrySeverity(loggingLevel);

    const scope = new Sentry.Scope();
    scope.setTransactionName(logMessage);
    scope.setLevel(sentrySeverity);
    scope.setTags({
      logging: sentrySeverity,
    });

    if (username && firstName && lastName && orgId) {
      scope.setUser({
        username,
        firstName,
        lastName,
        orgId,
      });
    }

    scope.setExtras({ data: JSON.stringify(args) });
    scope.setContext('data', { args });
    Sentry.captureMessage(logMessage, scope);
  }

  /**
   * Mapping Insight logging level to Sentry severity
   *
   * @param loggingLevel Insights logging level
   */
  mapLoggingLevelToSentrySeverity(loggingLevel: LoggingLevel): Severity {
    switch (loggingLevel) {
      case LoggingLevel.ERROR:
        return Severity.Error;
      case LoggingLevel.WARN:
        return Severity.Warning;
      case LoggingLevel.INFO:
        return Severity.Info;
      case LoggingLevel.DEBUG:
        return Severity.Debug;
      case LoggingLevel.TRACE:
        return Severity.Log;
      default:
        return Severity.Error;
    }
  }

  /**
   * Additional performance tracking method using a transaction
   *
   * @param promise Wrapped promise
   * @param context Transaction context data
   */
  logPerformanceTransaction = <T>(
    promise: Promise<T>,
    context: TransactionContext
  ): Promise<T> => {
    const transaction = Sentry.startTransaction(context);

    return promise
      .catch((e) => {
        transaction.setStatus(SpanStatus.UnknownError);

        throw e;
      })
      .finally(() => {
        transaction.finish();
      });
  };

  /**
   * Additional performance tracking method with spans
   *
   * @param promise Wrapped promise
   * @param context Span context data
   * @param _transaction Available transaction if theres any
   */
  logPerformanceSpan = <P>(
    promise: Promise<P>,
    context: SpanContext,
    _transaction?: Transaction | null
  ): Promise<P> => {
    const transaction =
      _transaction ?? Sentry.getCurrentHub()?.getScope()?.getTransaction();

    const span = transaction?.startChild(context);

    return promise
      .catch((e) => {
        span?.setStatus(SpanStatus.UnknownError);

        throw e;
      })
      .finally(() => {
        span?.finish();
      });
  };
}

export default new SentryLogger();
