import { CSSProperties, memo, useContext, useEffect, useRef } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import Select, { StylesConfig } from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Button, Col, Row } from 'reactstrap';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import AppContext from 'context/AppContext';
import { insightsColors } from 'helpers/ColorHelper';
import PermissionUtil from 'helpers/PermissionUtil';
import UserProfilePermissionHelper from 'helpers/UserProfilePermissionHelper';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ImageComponent from 'shared/components/image/ImageComponent';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import UserProfileFieldWrapper from '../../user-profile-left-panel/user-profile-field-wrapper/UserProfileFieldWrapper';
import { UserProfileFields } from '../../user-profile-left-panel/UserProfileLeftPanelProps';
import UserProfileSupervisee from '../UserProfileSupervisee';
import MenuList from './user-profile-supervisee-project-select-menu-list/UserProfileSuperviseeProjectSelectMenuList';
import styles from './userProfileSuperviseeItem.module.scss';
import UserProfileSuperviseeItemProps from './UserProfileSuperviseeItemProps';

const UserProfileSuperviseeItem: React.FC<UserProfileSuperviseeItemProps> = (
  props: UserProfileSuperviseeItemProps
) => {
  const {
    data: { list, restrictions, isItemLoaded, toggleUnassignModal },
    index,
    style,
  } = props;
  const { canEditProfile, isOwnProfile, isOwner } = restrictions;

  const { userProfileSetupInProgress, permissionsData } =
    useContext(AppContext);

  const canEditUserProfile = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.UserProfileEdit
  );
  const canDeactivateUsers = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.UserProfileDeactivate
  );
  const { permissionLevel } = permissionsData;

  const canEditField =
    UserProfilePermissionHelper.CanEditUserProfileField(
      UserProfileFields.UNASSIGN_SUPERVISEE,
      canEditProfile,
      isOwnProfile,
      isOwner,
      permissionLevel ?? '',
      canEditUserProfile,
      canDeactivateUsers
    ) && userProfileSetupInProgress;

  const supervisee = list[index];
  const loaded = isItemLoaded(index);

  const rowRef = useRef<HTMLDivElement | null>(null);
  const menuListRef = useRef<HTMLDivElement | null>(null);

  const currentSupervisee: UserProfileSupervisee =
    supervisee ?? new UserProfileSupervisee();

  const projectOptions: Array<SelectFieldOption> =
    currentSupervisee.projects?.map((project) => ({
      value: project.projectId,
      label: `${String(project.name)} (#${String(project.code)})`,
      isDisabled: true,
    }));

  const name = currentSupervisee.name
    ? currentSupervisee.name
    : intl.get('LBL_NA');

  const jobRole =
    currentSupervisee.jobRole && currentSupervisee.jobRole.role
      ? currentSupervisee.jobRole.role
      : intl.get('LBL_NA');

  const email = currentSupervisee.email
    ? currentSupervisee.email
    : intl.get('LBL_NA');

  const handleCloseMenuOnScroll = (event: Event): boolean =>
    event.target !== menuListRef.current;

  const projectPlaceholder =
    currentSupervisee.projects.length > 0
      ? currentSupervisee.projects
          .map(
            (project) => `${String(project.name)} (#${String(project.code)})`
          )
          .join(', ')
      : intl.get('LBL_NONE');

  const customStyles: StylesConfig = {
    option: (base: CSSProperties) => ({
      ...base,
      color: insightsColors.insightGray,
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
      ':hover': {
        backgroundColor: insightsColors.insightMildYellow,
      },
      backgroundColor: 'none',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div
      style={{
        ...style,
        top: style.top ?? 0,
        height: style.height ?? 0,
      }}
      ref={rowRef}
    >
      <BlockUi blocking={!loaded} tag="div">
        <Col xs="12" className="pl-0">
          <div className={styles.item}>
            <Row className="align-items-center">
              <Col className="d-flex align-items-center" xs="3">
                <ImageComponent
                  className="rounded-circle object-fit-cover mr-3"
                  ignoreBlur
                  src={currentSupervisee.imageURL ?? ''}
                  alt={currentSupervisee.name}
                  fallbackSrc={blankAvatar}
                  width={25}
                  height={25}
                />
                <EllipsisTooltip
                  tag="div"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-tip={name}
                  data-class="overflow-wrap bring-it-up"
                  className="text-12-bold truncate shorten"
                >
                  {name}
                </EllipsisTooltip>
              </Col>
              <Col xs={canEditField ? '2' : '3'}>
                <EllipsisTooltip
                  tag="div"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-tip={jobRole}
                  data-class="overflow-wrap bring-it-up"
                  className="text-12-bold truncate shorten"
                >
                  {jobRole}
                </EllipsisTooltip>
              </Col>
              <Col xs="3">
                <div className="text-12-bold">
                  <div className="insight-select-group">
                    <Select
                      className="size-down"
                      isSearchable={false}
                      options={projectOptions}
                      menuPortalTarget={document.body}
                      placeholder={projectPlaceholder}
                      menuPosition="fixed"
                      closeMenuOnScroll={handleCloseMenuOnScroll}
                      menuListRef={menuListRef}
                      isDisabled={!currentSupervisee.projects}
                      name="supervisee-projects"
                      styles={customStyles}
                      classNamePrefix="insight-select insight-select-projects"
                      components={{
                        MenuList,
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={canEditField ? '2' : '3'}>
                <EllipsisTooltip
                  tag="div"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-tip={email}
                  data-class="overflow-wrap bring-it-up"
                  className="text-12-bold truncate shorten"
                >
                  {email}
                </EllipsisTooltip>
              </Col>
              <UserProfileFieldWrapper
                value=""
                fieldName={UserProfileFields.UNASSIGN_SUPERVISEE}
                canEditProfile={canEditProfile}
                isOwnProfile={isOwnProfile}
                isOwner={isOwner}
                isNotInLeftPanel
              >
                <Col xs="2">
                  <Button
                    type="button"
                    onClick={() => toggleUnassignModal(currentSupervisee.id)}
                    className="btn btn-sm"
                  >
                    {intl.get('BTN_UNASSIGN')}
                  </Button>
                </Col>
              </UserProfileFieldWrapper>
            </Row>
          </div>
        </Col>
      </BlockUi>
    </div>
  );
};

/** suggested by react-window to prevent unnecessary re-renders
 *  https://react-window.now.sh/#/api/FixedSizeList */
export default memo(UserProfileSuperviseeItem);
