import Misc from 'constants/misc/Misc';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';

/**
 * Curried function to initialize the number formatter and format given value
 *
 * @param locale Locale that the internationalization library is initialized with
 * @param options Optional set of options for number formatter
 * @param value Number to be formatted
 * @returns {string} Formatted value
 */
const getNumberFormatter =
  (locale?: string, options?: Intl.NumberFormatOptions) =>
  (value: number): string => {
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
  };

/**
 * Formats numbers with thousand separators and fraction digits based on locale
 *
 * @param value Number to be formatted
 * @param precise Should decimal places take priority
 * @returns {string} Formatted value
 */
const getFormattedNumber = (value: number, precise: boolean): string =>
  getNumberFormatter('en-US', {
    maximumFractionDigits: precise
      ? Misc.MaximumPreciseFractionDigits
      : Misc.MaximumFractionDigits,
    minimumFractionDigits: Misc.MinimumFractionDigits,
  })(value);

/**
 * Formats numbers into a compact format with fraction digits based on locale
 *
 * @param value Number to be formatted
 * @param precise Should decimal places take priority
 * @returns {string} Formatted value
 */
const getCompactNumber = (value: number, precise: boolean): string =>
  getNumberFormatter('en-US', {
    notation: IntlNumberNotation.Compact,
    compactDisplay: 'short',
    maximumFractionDigits: precise
      ? Misc.MaximumPreciseFractionDigits
      : Misc.MaximumFractionDigits,
    minimumFractionDigits: Misc.MinimumFractionDigits,
  })(value);

/**
 * Formats numbers into a currency string based on locale
 *
 * @param symbol The currency symbol to be prefixed
 * @param value Number to be formatted
 * @param precise Should decimal places take priority
 * @param notation Specifies if formatted string should be compact or in standard notation
 * @returns {string} Formatted currency string
 */
const getCurrencyString =
  (symbol: string) =>
  (value: number, precise: boolean, notation?: IntlNumberNotation): string =>
    /* We have used the character code for non-breaking space here to 
    avoid line breaks happening between the currency symbol and the 
    value */
    `${String(symbol ?? '')}\xa0${getNumberFormatter('en-US', {
      notation: notation ?? IntlNumberNotation.Standard,
      maximumFractionDigits: Misc.MaximumCurrencyFractionDigits,
      minimumFractionDigits: Misc.MinimumCurrencyFractionDigits,
    })(value)}`;

/**
 * Get percentage with formatted value
 *
 * @param numberValue Number to get the percentage
 * @param totalValue Total value
 *
 * @returns {string} Percentage value
 */
const getPercentageWithFormat = (
  numberValue: number,
  totalValue: number
): string => {
  let percentage = '0';
  if (totalValue !== 0 && totalValue !== -1) {
    percentage = getFormattedNumber((numberValue / totalValue) * 100, false);
  }

  return percentage;
};

export {
  getFormattedNumber,
  getCurrencyString,
  getCompactNumber,
  getPercentageWithFormat,
};
