import { useContext, FC as ReactFC, memo } from 'react';

import isEmpty from 'lodash/isEmpty';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Alert, Button, Col, Row } from 'reactstrap';

import AppContext from 'context/AppContext';
import { GroupStatusDataItem } from 'context/AppProviderState';
import {
  allMembers,
  groupAges,
  memberAges,
  memberEducation,
  memberGenders,
} from 'modules/private/surveys/containers/SurveyConstants';
import Status from 'shared/enums/Status';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveysFilter from 'shared/enums/SurveysFilter';
import SurveyType from 'shared/enums/SurveyType';

import GroupsFilter from '../groups-filter/GroupsFilter';
import MembersFilter from '../members-filter/MembersFilter';
import styles from './collapseItem.module.scss';
import CollapseItemProps from './CollapseItemProps';

const CollapseItem: ReactFC<CollapseItemProps> = (props: CollapseItemProps) => {
  const {
    focusedSurvey,
    selectedRecipientType,
    selectedSurveyType,
    displayAlert,
    onItemExpanded,
  } = props;
  const { groupStatusData } = useContext(AppContext);
  const {
    countriesFilter: { status: CountryStatus },
    projectsFilter: { status: ProjectsStatus },
    facilitatorsFilter: { status: FacilitatorsStatus },
    appliedFilters: {
      status: AppliedFilterState,
      data: { totalGroupCount, totalMemberCount },
    },
    selectedFilters,
  } = focusedSurvey;

  const groupStatusesEmpty = Object.values(groupStatusData.data).length <= 0;

  const groupStatusesModded =
    groupStatusData.status !== Status.Loading && groupStatusesEmpty
      ? []
      : Object.values(groupStatusData.data).map(
          (groupStatus: GroupStatusDataItem) => ({
            value: groupStatus.id,
            label: groupStatus.status,
          })
        );

  const groupAgesTranslated = groupAges.map(({ value, label }) => ({
    value,
    label: intl.get(label),
  }));

  const isMemberFiltersDisabled =
    isEmpty(selectedFilters.countries) || totalGroupCount <= 0;

  const isLoading =
    AppliedFilterState === Status.Loading ||
    CountryStatus === Status.Loading ||
    ProjectsStatus === Status.Loading ||
    FacilitatorsStatus === Status.Loading;

  return (
    <BlockUi tag="div" blocking={isLoading}>
      <Row className={styles.collapseItem}>
        <Col xs="12">
          <Row>
            <Col>
              <Alert
                isOpen={displayAlert}
                tag="div"
                fade
                className="alert alert-danger"
                role="alert"
              >
                {intl.get('ERR_ALERT_INVALID_RECIPIENT_COUNT')}
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <span className="text-12-semibold text-gray text-uppercase">
                {intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_GROUPS_FILTER_TITLE')}
              </span>
            </Col>
            <Col xs="auto">
              <button
                className="btn-icon btn-close"
                type="button"
                onClick={(): void =>
                  onItemExpanded(false, focusedSurvey.expandedRowId)
                }
              >
                <i className="icon-chevron-up" />
              </button>
            </Col>
          </Row>
          <Row>
            <Col className="mb-1">
              <span
                className={`text-13-semibold text-uppercase ${styles.countColor}`}
              >
                {intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_GROUPS_FILTER_COUNT', {
                  count: totalGroupCount,
                })}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr className="divider mt-1 mb-2" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="2">
              <GroupsFilter
                title={intl.get('LBL_SURVEYS_COUNTRIES_FILTER_TITLE')}
                data={focusedSurvey.countriesFilter.data}
                isDisabled={false}
                selected={focusedSurvey.selectedFilters.countries}
                eventType={SurveysFilter.Countries}
              />
            </Col>
            <Col xs="2">
              <GroupsFilter
                title={intl.get('LBL_SURVEYS_COMPOSE_PROJECTS_FILTER_TITLE')}
                data={focusedSurvey.projectsFilter.data}
                isDisabled={focusedSurvey.selectedFilters.countries.length <= 0}
                selected={focusedSurvey.selectedFilters.projects}
                eventType={SurveysFilter.Projects}
              />
            </Col>
            <Col xs="2">
              <GroupsFilter
                title={intl.get(
                  'LBL_SURVEYS_COMPOSE_FACILITATORS_FILTER_TITLE'
                )}
                data={focusedSurvey.facilitatorsFilter.data}
                isDisabled={focusedSurvey.selectedFilters.projects.length <= 0}
                selected={focusedSurvey.selectedFilters.facilitators}
                eventType={SurveysFilter.Facilitators}
              />
            </Col>
            <Col xs="2">
              <GroupsFilter
                title={intl.get(
                  'LBL_SURVEYS_COMPOSE_GROUP_STATUS_FILTER_TITLE'
                )}
                data={groupStatusesModded}
                isDisabled={focusedSurvey.selectedFilters.countries.length <= 0}
                selected={focusedSurvey.selectedFilters.groupStatus}
                eventType={SurveysFilter.GroupStatus}
              />
            </Col>
            {selectedSurveyType !== SurveyType.CollectId && (
              <Col xs="2">
                <GroupsFilter
                  title={intl.get('LBL_SURVEYS_COMPOSE_GROUP_AGE_FILTER_TITLE')}
                  data={groupAgesTranslated}
                  isDisabled={
                    focusedSurvey.selectedFilters.countries.length <= 0
                  }
                  selected={focusedSurvey.selectedFilters.groupAge}
                  eventType={SurveysFilter.GroupAge}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col
          xs="12"
          className={
            selectedRecipientType === null ||
            selectedRecipientType !== SurveyRecipientType.Members
              ? 'd-none'
              : 'mt-4'
          }
        >
          <Row>
            <Col className="mb-2">
              <span className="text-12-semibold text-gray text-uppercase">
                {intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_MEMBERS_FILTER_TITLE')}
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="mb-1">
              <span
                className={`text-13-semibold text-uppercase ${styles.countColor}`}
              >
                {intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_MEMBERS_FILTER_COUNT', {
                  count: totalMemberCount,
                })}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr className="divider mt-2 mb-2" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="2">
              <MembersFilter
                title={intl.get(
                  'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_ALL_MEMBERS'
                )}
                data={allMembers}
                isDisabled={isMemberFiltersDisabled}
                selected={focusedSurvey.selectedFilters.allMembers}
                eventType={SurveysFilter.AllMembers}
              />
            </Col>
            <Col xs="2">
              <MembersFilter
                title={intl.get(
                  'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_GENDER_TITLE'
                )}
                data={memberGenders}
                isDisabled={isMemberFiltersDisabled}
                selected={focusedSurvey.selectedFilters.memberGender}
                eventType={SurveysFilter.MemberGender}
              />
            </Col>
            <Col xs="2">
              <MembersFilter
                title={intl.get(
                  'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_AGE_TITLE'
                )}
                data={memberAges}
                isDisabled={isMemberFiltersDisabled}
                selected={focusedSurvey.selectedFilters.memberAge}
                eventType={SurveysFilter.MemberAge}
              />
            </Col>
            <Col xs="2">
              <MembersFilter
                title={intl.get(
                  'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_FILTER_EDUCATION_TITLE'
                )}
                data={memberEducation}
                isDisabled={isMemberFiltersDisabled}
                selected={focusedSurvey.selectedFilters.memberEducation}
                eventType={SurveysFilter.MemberEducation}
              />
            </Col>
          </Row>
        </Col>
        <Col xs="12" className="d-flex justify-content-end">
          <Button
            color="secondary"
            onClick={(): void =>
              onItemExpanded(false, focusedSurvey.expandedRowId)
            }
          >
            {intl.get('BTN_SURVEYS_DRAFTED_CANCEL')}
          </Button>
        </Col>
      </Row>
    </BlockUi>
  );
};
export default memo(CollapseItem);
