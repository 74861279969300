import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import MessagingFilter from 'shared/enums/MessagingFilter';

import GroupsFilter from '../groups-filter/GroupsFilter';
import CountriesFilterProps from './CountriesFilterProps';

const CountriesFilter: ReactFC<CountriesFilterProps> = (
  props: CountriesFilterProps
) => {
  const { countriesData, isDisabled, selected, onChange } = props;

  return (
    <GroupsFilter
      title={intl.get('LBL_MESSAGING_COMPOSE_COUNTRIES_FILTER_TITLE')}
      data={countriesData.data}
      onChange={(e, label: string): void =>
        onChange(e, MessagingFilter.Countries, label)
      }
      isDisabled={isDisabled}
      selected={selected}
    />
  );
};

export default CountriesFilter;
