/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Route, RouteChildrenProps, Switch } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import GroupOverview from 'modules/private/group/containers/group-overview/GroupOverview';

import GroupDetailsView from './containers/group-details-view/GroupDetailsView';
import GroupOverviewProps from './containers/group-overview/GroupOverviewProps';

const GroupRoutes: ReactFC<GroupOverviewProps> = (
  props: GroupOverviewProps
) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.path}${ModulePaths.GroupDetailsPath}`}>
        {(
          routeProps: RouteChildrenProps<{ groupNumber: string }>
        ): JSX.Element => <GroupDetailsView {...props} {...routeProps} />}
      </Route>

      <Route path={`${match.path}/`}>
        <GroupOverview {...props} />
      </Route>
    </Switch>
  );
};

export default GroupRoutes;
