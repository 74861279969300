import { ReactNode, useRef, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Arrow, RenderLayerProps, ToggleLayer } from 'react-laag';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';

import GlobalFilters from 'constants/GlobalFilters';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import Status from 'shared/enums/Status';

import styles from '../filterToolbar.module.scss';
import MoreFiltersSelectProps from './MoreFiltersSelectProps';

const MoreFiltersSelect: ReactFC<MoreFiltersSelectProps> = (
  props: MoreFiltersSelectProps
) => {
  const {
    appContext,
    onOpen,
    onChange,
    onClose,
    selectedGroupStatuses,
    maxSelected,
  } = props;

  const { dashboardSetupInProgress, groupStatusData } = appContext;
  const parentRef = useRef<HTMLDivElement>(null);

  const groupStatusesEmpty = Object.values(groupStatusData.data).length <= 0;

  const groupStatusesModded =
    groupStatusData.status !== Status.Loading && groupStatusesEmpty
      ? []
      : Object.values(groupStatusData.data);

  const renderLayer = ({
    isOpen,
    layerProps,
    arrowStyle,
    layerSide,
  }: RenderLayerProps): ReactNode =>
    isOpen && (
      <>
        <div
          ref={layerProps.ref}
          style={{
            ...layerProps.style,
            left: '50%',
            transform: 'translateX(-50%)',
            top: '10px',
          }}
          className="layer insight-dropdown"
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            backgroundColor="#ffffff"
            borderWidth={1}
            borderColor="#dbdce1"
            roundness={0.7}
          />
          <div className="text-11-semibold text-uppercase text-gray pb-1 mb-2">
            {intl.get('LBL_TITLE_GROUP_STATUS')}
          </div>
          {groupStatusData.status !== Status.Error && (
            <BlockUi blocking={groupStatusData.status === Status.Loading}>
              <PerfectScrollBar style={{ maxHeight: '250px' }}>
                <Form>
                  {groupStatusesModded.length > 0 ? (
                    groupStatusesModded.map((groupStatus) => {
                      const isChecked = selectedGroupStatuses.includes(
                        groupStatus.id
                      );
                      const selectionBlocked = !isChecked && maxSelected;
                      return (
                        <FormGroup
                          key={groupStatus.id}
                          className={`insight-checkbox-group ${styles.wrap}`}
                        >
                          <span
                            data-for="insTooltip"
                            data-place="bottom"
                            data-class="error bring-it-up"
                            data-tip={
                              selectionBlocked
                                ? intl.get(
                                    'ERR_GLOBAL_FILTER_GROUP_STATUS_SELECTION_LIMIT_REACHED',
                                    {
                                      limit: GlobalFilters.MaxListSelectable,
                                    }
                                  )
                                : ''
                            }
                          >
                            <Label
                              className={`truncate ${
                                isChecked ? 'checked-label' : ''
                              } ${
                                selectionBlocked ? styles.reduceOpacity : ''
                              }`}
                              style={{ maxWidth: '150px' }}
                            >
                              <span
                                className={`insight-checkbox mr-3 ${
                                  selectionBlocked ? styles.defaultCursor : ''
                                }`}
                              >
                                <Input
                                  type="checkbox"
                                  tabIndex={0}
                                  name={groupStatus.status}
                                  value={groupStatus.id}
                                  checked={isChecked}
                                  onChange={onChange}
                                  disabled={selectionBlocked}
                                />
                                <i className="icon-check" />
                              </span>
                              {selectionBlocked ? (
                                <span className="truncate">
                                  {groupStatus.status}
                                </span>
                              ) : (
                                <EllipsisTooltip
                                  tag="span"
                                  data-place="right"
                                  data-for="insTooltip"
                                  data-tip={groupStatus.status}
                                  data-class="overflow-wrap"
                                  className="truncate"
                                >
                                  {groupStatus.status}
                                </EllipsisTooltip>
                              )}
                            </Label>
                          </span>
                        </FormGroup>
                      );
                    })
                  ) : (
                    <FormGroup className="insight-checkbox-group">
                      <Label
                        className="truncate"
                        style={{ maxWidth: '150px', textAlign: 'start' }}
                      >
                        {intl.get('LBL_NO_OPTIONS')}
                      </Label>
                    </FormGroup>
                  )}
                </Form>
              </PerfectScrollBar>
            </BlockUi>
          )}
          <Alert
            isOpen={groupStatusData.status === Status.Error}
            tag="div"
            fade
            className="alert alert-danger mt-2"
            role="alert"
          >
            {groupStatusData.error}
          </Alert>
        </div>
      </>
    );

  return (
    <ToggleLayer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={parentRef.current!} // nosonar
      closeOnOutsideClick
      onOutsideClick={onClose}
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      renderLayer={renderLayer}
    >
      {({ triggerRef, toggle, isOpen }): ReactNode => (
        <>
          <>
            <label>{intl.get('LBL_MORE_FILTERS')}</label>
            <button
              ref={triggerRef}
              className={`toggle-btn dropdown-btn ${
                isOpen ? 'dropdown-open' : ''
              }`}
              data-cy="status-gf"
              onClick={(): void => onOpen(toggle, isOpen)}
              disabled={dashboardSetupInProgress}
            >
              {intl.get('LBL_MORE_FILTERS')}
            </button>
            <div
              ref={parentRef}
              className="position-relative"
              id="more-filters-parent"
            />
          </>
        </>
      )}
    </ToggleLayer>
  );
};

export default MoreFiltersSelect;
