import * as intl from 'react-intl-universal';
import * as yup from 'yup';

import ForgotPasswordFormValues from './ForgotPasswordFormValues';

class ForgotPasswordFormValidation {
  /**
   * Defines a Yup validation schema for the forgot password form
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema<
      Partial<ForgotPasswordFormValues>
    > = yup.object().shape({
      email: yup
        .string()
        .trim()
        .email(intl.get('ERR_FORM_EMAIL_INVALID_MESSAGE'))
        .required(intl.get('ERR_FORM_EMAIL_REQUIRED_MESSAGE')),
    });

    return validationSchema;
  }
}

export default ForgotPasswordFormValidation;
