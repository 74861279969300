/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, FC as ReactFC } from 'react';

import noop from 'lodash/noop';

import PaginationProps from './PaginationProps';

const Pagination: ReactFC<PaginationProps> = (props: PaginationProps) => {
  const {
    siblingCount,
    pageCount,
    boundaryCount,
    gotoPage,
    pageIndex,
    disabled,
  } = props;
  const initial = (pageIndex ?? 0) + 1;
  const [selected, setSelected] = useState(initial);

  useEffect(() => {
    setSelected(initial);
  }, [initial]);

  useEffect(() => {
    gotoPage(selected - 1);
  }, [selected, gotoPage]);

  /**
   * Return range of values given first and last
   *
   * @param start First value in range
   * @param end Last value in range
   * @returns {number[]} Range of values
   */
  const range = (start: number, end: number): number[] => {
    const length = end - start + 1;
    return Array.from({ length }, (_, index) => Number(start) + index);
  };

  /**
   * if pageCount < boundaryCount then pages will be displayed without ellipsis
   */
  const startPages = range(1, Math.min(boundaryCount, pageCount));

  const endPages = range(
    Math.max(pageCount - boundaryCount + 1, boundaryCount + 1),
    pageCount
  );

  const siblingsStart = Math.max(
    Math.min(
      selected - siblingCount,
      pageCount - boundaryCount - siblingCount * 2 - 1
    ),
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(selected + siblingCount, boundaryCount + siblingCount * 2 + 2),
    endPages[0] - 2
  );

  const itemList = [
    ...startPages,
    ...(siblingsStart > boundaryCount + 2
      ? ['ellipsis']
      : boundaryCount + 1 < pageCount - boundaryCount
      ? [boundaryCount + 1]
      : []),

    ...range(siblingsStart, siblingsEnd),

    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < pageCount - boundaryCount - 1
      ? ['ellipsis']
      : pageCount - boundaryCount > boundaryCount
      ? [pageCount - boundaryCount]
      : []),
    ...endPages,
  ];

  const newItems = itemList.map((item) => {
    if (typeof item === 'number') {
      return {
        type: 'page',
        page: item,
        onClick: (): void => setSelected(item),
        selected: item === selected,
      };
    }
    return {
      type: 'ellipsis',
      page: item,
      onClick: noop,
      selected: false,
    };
  });

  const canLeft = selected <= 1;
  const canRight = selected >= pageCount;

  return (
    <div className="pagination-col pagination-buttons">
      <button
        disabled={canLeft || disabled}
        onClick={(): void => setSelected(1)}
        className={`btn-pagination small ${canLeft ? 'disabled' : ''}`}
      >
        <i className="icon-double-chevron-left" />
      </button>
      <button
        disabled={canLeft || disabled}
        onClick={(): void => setSelected((state) => state - 1)}
        className={`btn-pagination small ${canLeft ? 'disabled' : ''}`}
      >
        <i className="icon-chevron-left" />
      </button>
      {newItems.map(
        (
          { page, type, selected: selectedItem, ...item },
          index
        ): JSX.Element => {
          if (type === 'page') {
            return (
              <button
                {...item}
                key={index}
                disabled={disabled}
                className={`btn-pagination ${selectedItem ? 'active' : ''}`}
              >
                {page}
              </button>
            );
          }

          return (
            <span {...item} key={index} className="more">
              ...
            </span>
          );
        }
      )}
      <button
        disabled={canRight || disabled}
        onClick={(): void => setSelected((state) => state + 1)}
        className={`btn-pagination small  ${canRight ? 'disabled' : ''}`}
      >
        <i className="icon-chevron-right" />
      </button>
      <button
        disabled={canRight || disabled}
        className={`btn-pagination small  ${canRight ? 'disabled' : ''}`}
        onClick={(): void => setSelected(pageCount)}
      >
        <i className="icon-double-chevron-right" />
      </button>
    </div>
  );
};

export default Pagination;
