/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react';
import { TransactionContext } from '@sentry/types';
import * as loglevel from 'loglevel';

import ApiError from 'api/common/types/ApiError';
import LoggingLevel from 'shared/enums/LoggingLevel';

import SentryLogger from './SentryLogger';

class InsightsLogger {
  private loggingLevel;

  constructor() {
    this.loggingLevel = process.env.REACT_APP_LOGGING_LEVEL;
  }

  /**
   * Initialize logging
   */
  initLogging(): void {
    SentryLogger.init(this.loggingLevel);
    loglevel.setLevel(
      Number(this.loggingLevel) as loglevel.LogLevelNumbers,
      false
    );
  }

  /**
   * Check whether the message can be logged
   *
   * @param logLevel The level of the logging message
   */
  private loggingEnabled(logLevel: LoggingLevel): boolean {
    return logLevel >= this.loggingLevel;
  }

  /**
   * Logging
   *
   * @param logMessage Message
   * @param args Additional data
   */
  private logMessages(
    logLevel: LoggingLevel,
    logMessage: string,
    args?: any[]
  ): void {
    this.mapInsightLoggingLevelToLogLevel(logLevel, logMessage, args);
  }

  /**
   * Log Error
   *
   * @param error Thrown error
   * @param severity Severity of the error event
   * @param action Request action
   * @param method Request method
   */
  logError(
    error: ApiError | Error,
    severity: Sentry.Severity,
    action?: string,
    method?: string
  ): void {
    SentryLogger.logError(error, severity, action, method);
    this.mapInsightLoggingLevelToLogLevel(LoggingLevel.ERROR, error.message, [
      error,
      action,
      method,
    ]);
  }

  /**
   * Mapping Insight logging level to loglevel
   *
   * @param loggingLevel Insights logging level
   */
  mapInsightLoggingLevelToLogLevel(
    loggingLevel: LoggingLevel,
    logMessage: string,
    ...args: any[]
  ): void {
    switch (loggingLevel) {
      case LoggingLevel.ERROR:
        loglevel.error(logMessage, args);
        break;
      case LoggingLevel.WARN:
        loglevel.warn(logMessage, ...args);
        break;
      case LoggingLevel.INFO:
        loglevel.info(logMessage, ...args);
        break;
      case LoggingLevel.DEBUG:
        loglevel.debug(logMessage, ...args);
        break;
      case LoggingLevel.TRACE:
        loglevel.trace(logMessage, ...args);
        break;
      default:
        loglevel.log(logMessage, ...args);
        break;
    }
  }

  /**
   * Log Warn
   *
   * @param logMessage
   * @param args Additional data
   */
  logWarn(logMessage: string, ...args: any[]): void {
    this.logMessages(LoggingLevel.WARN, logMessage, args);
  }

  /**
   * Log Info
   *
   * @param logMessage
   * @param args Additional data
   */
  logInfo(logMessage: string, ...args: any[]): void {
    this.logMessages(LoggingLevel.INFO, logMessage, args);
  }

  /**
   * Log Debug
   *
   * @param logMessage
   * @param args Additional data
   */
  logDebug(logMessage: string, ...args: any[]): void {
    this.logMessages(LoggingLevel.DEBUG, logMessage, args);
  }

  /**
   * Log Trace
   *
   * @param logMessage
   * @param args Additional data
   */
  logTrace(logMessage: string, ...args: any[]): void {
    this.logMessages(LoggingLevel.TRACE, logMessage, args);
  }

  /**
   * Log Performance
   *
   * @param promise
   * @param context Transaction Context
   */
  logPerformance<T>(
    promise: Promise<T>,
    context: TransactionContext
  ): Promise<T> {
    if (this.loggingEnabled(LoggingLevel.INFO))
      return SentryLogger.logPerformanceTransaction(promise, context);

    return promise;
  }

  /**
   * Decorator for wrap api methods
   *
   * @param transactionKey Transaction key
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  performanceWrap(transactionKey?: string) {
    const isLoggingEnabled = this.loggingEnabled(LoggingLevel.INFO);
    // eslint-disable-next-line func-names
    return function (
      target: Record<string, any>,
      key: string | symbol,
      descriptor: PropertyDescriptor
    ): PropertyDescriptor {
      const original = descriptor.value;

      descriptor.value = async function (...args: any[]): Promise<unknown> {
        if (isLoggingEnabled) {
          const result = await SentryLogger.logPerformanceTransaction(
            original.apply(this, args),
            { name: transactionKey || args.pop() }
          );
          return result;
        }
        return original.apply(this, args);
      };

      return descriptor;
    };
  }
}

export default new InsightsLogger();
