/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, FC as ReactFC } from 'react';

import { ErrorMessage, Field } from 'formik';
import * as intl from 'react-intl-universal';
import {
  Button,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  FormGroup,
} from 'reactstrap';

import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import INeedOrgId from 'shared/static/img/i-need-org-id.svg';
import OrgId from 'shared/static/img/org-id.svg';

import StepDots from '../step-dots/StepDots';
import styles from './registerPartnerId.module.scss';
import RegisterPartnerIdProps from './RegisterPartnerIdProps';

const RegisterPartnerId: ReactFC<RegisterPartnerIdProps> = (
  props: RegisterPartnerIdProps
) => {
  const { onBackClick, setFieldValue, step, status, setStatus, loading } =
    props;
  const [partnerIdStatus, setPartnerIdStatus] = useState(1);

  /**
   * Resets form submit state
   */
  const resetSubmitState = (): void => {
    if (status?.submit) {
      setStatus({ submit: null });
    }
  };

  /**
   * Resets partner ID status
   */
  const resetPartnerIdStatus = (): void => {
    if (status?.partnerId) {
      setStatus({ partnerId: null });
    }
  };

  /**
   * Sets availability of partner ID
   */
  const setHasPartnerId = (): void => setPartnerIdStatus(1);

  /**
   * Sets unavailability of partner ID
   */
  const setNeedPartnerId = (): void => {
    resetSubmitState();
    setPartnerIdStatus(2);
  };

  /**
   * Handles back click
   */
  const handleBackClick = (): void => {
    resetPartnerIdStatus();
    resetSubmitState();
    onBackClick();
  };

  /**
   * Handles changing partner ID
   *
   * @param event Change event for partner ID field
   */
  const handleChangePartnerId = (event): void => {
    resetPartnerIdStatus();
    const { value } = event.target;
    if (/^[0-9]*$/.test(value)) {
      setFieldValue('partnerId', value);
    }
  };

  /**
   * Tracks forgot partner ID link click for analytics
   */
  const trackForgotPartnerIdGA = (): void => {
    sendEventGA(
      CategoryKeysGA.AccountCreateAccountStep3,
      ActionKeysGA.ForgotPartnerId
    );
  };

  /**
   * Tracks request partner ID link click for analytics
   */
  const trackRequestPartnerIdGA = (): void => {
    sendEventGA(
      CategoryKeysGA.AccountCreateAccountStep3,
      ActionKeysGA.RequestPartnerId
    );
  };

  /**
   * Renders the correct tab content as a child component
   *
   * @returns {JSX.Element} JSX snippet containing the appropriate tab content
   */
  const renderTabContent = (): JSX.Element => {
    if (partnerIdStatus === 1) {
      return (
        <Row className="mt-3 pt-3">
          <Col xs="12">
            <p className="text-14-medium text-gray">
              {intl.get('LBL_CA_ENTER_PARTNER_ID_BELOW')}
            </p>
          </Col>
          <Col xs="12">
            <FormGroup>
              <label htmlFor="partnerId">
                {intl.get('LBL_CA_ENTER_PARTNER_ID')}
              </label>
              <Field
                name="partnerId"
                autoComplete="off"
                pattern="[0-9.]+"
                type="text"
                maxLength="4"
                className="form-control"
                onChange={handleChangePartnerId}
              />
              <ErrorMessage
                component="label"
                className="form-error"
                name="partnerId"
              />
              {status && status.partnerId && (
                <label className="form-error">{status.partnerId}</label>
              )}
              <div className="mt-3">
                <a
                  target="_blank"
                  className="text-muted"
                  rel="noopener noreferrer"
                  onClick={trackForgotPartnerIdGA}
                  href="https://support.dreamstartlabs.com/"
                >
                  <u className="cursor-pointer">
                    {intl.get('BTN_CA_FORGOT_PARTNER_ID')}
                  </u>
                </a>
              </div>
            </FormGroup>
          </Col>
          <Col xs="4">
            <Button onClick={handleBackClick}>{intl.get('BTN_CA_BACK')}</Button>
          </Col>
          <Col
            xs="4"
            className="d-flex justify-content-center align-items-center"
          >
            <StepDots count={3} step={step} />
          </Col>
          <Col xs="4">
            <Button disabled={loading} color="primary" type="submit">
              {intl.get('BTN_CA_CREATE_MY_ACCOUNT')}
            </Button>
          </Col>
          <Col xs="12" className="text-center mt-3 ">
            <small className="form-text text-muted">
              {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.dreamstartlabs.com/"
              >
                {intl.get('LBL_CA_CONTACT_SUPPORT')}
              </a>
            </small>
          </Col>
        </Row>
      );
    }
    return (
      <Row className="mt-3 pt-3">
        <Col xs="12">
          <p className="text-14-medium text-gray">
            {intl.get('LBL_CA_CLICK_TO_REQUEST_PARTNER_ID')}
          </p>
        </Col>
        <Col xs="12" className="pb-3 mb-3">
          <Row>
            <Col xs="auto">
              <FormGroup>
                <a
                  className="btn btn-secondary btn-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={trackRequestPartnerIdGA}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfzUucF5-MslvMtIiePqtzNtlQT_0uczdkRs_JOUtDn7E8sOw/viewform"
                >
                  {intl.get('LBL_CA_REQUEST_PARTNER_ID')}
                </a>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col xs="3">
          <Button onClick={onBackClick}>{intl.get('BTN_CA_BACK')}</Button>
        </Col>
        <Col
          xs="6"
          className="d-flex justify-content-center align-items-center"
        >
          <StepDots count={3} step={step} />
        </Col>
        <Col xs="12" className="text-center mt-3 pt-3">
          <small>
            {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.dreamstartlabs.com/"
            >
              {intl.get('LBL_CA_CONTACT_SUPPORT')}
            </a>
          </small>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <ModalHeader className="text-center increase-font">
        {intl.get('LBL_CA_REGISTER_PARTNER_ID')}
        <small>{intl.get('LBL_CA_CHOOSE_HOW_TO_SET_UP')}</small>
      </ModalHeader>

      <ModalBody>
        <div className={styles.wrapper}>
          <div className="form-group" style={{ margin: 0 }}>
            {status && status.submit && (
              <div className="alert alert-danger" role="alert">
                {status.submit}
              </div>
            )}
          </div>
          <Row>
            <Col>
              <div
                className={`${styles.card} insight-card cursor-pointer ${
                  partnerIdStatus === 1 ? styles.active : ''
                }`}
                onKeyPress={setHasPartnerId}
                onClick={setHasPartnerId}
                tabIndex={0}
                role="button"
              >
                <Row>
                  <Col xs="auto" className={styles.image}>
                    <img alt="have partner id" src={OrgId} />
                  </Col>
                  <Col>
                    <div
                      className={`d-flex align-items-center ${styles.title}`}
                    >
                      <h4>{intl.get('LBL_CA_I_HAVE_PARTNER_ID')}</h4>
                      <span className={styles.check}>
                        <i className="icon-check" />
                      </span>
                    </div>
                    <div className={styles.text}>
                      {intl.get('LBL_CA_HAVE_PARTNER_ID_DESCRIPTION')}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col>
              <div
                className={`${styles.card} insight-card cursor-pointer ${
                  partnerIdStatus === 2 ? styles.active : ''
                }`}
                onKeyPress={setNeedPartnerId}
                onClick={setNeedPartnerId}
                tabIndex={0}
                role="button"
              >
                <Row>
                  <Col xs="auto" className={styles.image}>
                    <img alt="need partner id" src={INeedOrgId} />
                  </Col>
                  <Col>
                    <div
                      className={`d-flex align-items-center ${styles.title}`}
                    >
                      <h4>{intl.get('LBL_CA_I_NEED_PARTNER_ID')}</h4>
                      <span className={styles.check}>
                        <i className="icon-check" />
                      </span>
                    </div>
                    <div className={styles.text}>
                      {intl.get('LBL_CA_I_NEED_PARTNER_ID_DESCRIPTION')}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {renderTabContent()}
        </div>
      </ModalBody>
    </>
  );
};

export default RegisterPartnerId;
