/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useRef, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { ValueType } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';
import { Button, Col, FormGroup, Row } from 'reactstrap';

import ApiError from 'api/common/types/ApiError';
import OrganizationFacilitator from 'api/settings/types/facilitators/OrganizationFacilitator';
import Constraints from 'constants/forms/Constraints';
import { getLocalizedErrorString } from 'helpers/ErrorFormat';
import ProjectFacilitatorViewModel from 'modules/private/projects/containers/projects-view/ProjectFacilitatorViewModel';
import ImageComponent from 'shared/components/image/ImageComponent';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import usePrevious from 'shared/hooks/use-previous/UsePrevious';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import AddExistingFacilitatorsProps from './AddExistingFacilitatorsProps';
import ExistingFacilitatorsList from './existing-facilitators-list/ExistingFacilitatorsList';
import ProjectFacilitatorsOption from './ProjectFacilitatorsOption';
import SearchBarNoOptionsMessage from './search-bar/SearchBarNoOptionsMessage';
import SearchBarStyles from './search-bar/SearchBarStyles';
import SearchBarValueContainer from './search-bar/SearchBarValueContainer';

const AddExistingFacilitators: ReactFC<AddExistingFacilitatorsProps> = (
  props: AddExistingFacilitatorsProps
) => {
  const {
    existingFacilitators,
    hasUnsavedChanges,
    status,
    error: errorStatus,
    fetchOrgFacilitators,
    onAddExistingFacilitators,
    onAddFacilitatorsSuccess,
    setHasUnsavedChanges,
    warningPromptOpen,
    onShowSuccessState,
    onToggle,
  } = props;

  const selectRef = useRef<Async<ProjectFacilitatorsOption> | null>(null);

  const prevWarning = usePrevious(warningPromptOpen);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectText, setSelectText] = useState('');
  const [lookupErrorCode, setLookupErrorCode] = useState<number | null>(null);

  const [newProjectFacilitators, setNewProjectFacilitators] = useState<
    Array<ProjectFacilitatorViewModel>
  >([]);

  /**
   * Focus the search input once the user
   * is prompted with warning and
   * is proceeded with either cancel or continue
   */
  useEffect(() => {
    if (
      prevWarning === true &&
      warningPromptOpen === false &&
      selectRef.current
    ) {
      selectRef.current.focus();
    }
  }, [warningPromptOpen, prevWarning]);

  useEffect(() => {
    setHasUnsavedChanges(newProjectFacilitators.length > 0 ?? false);
  }, [newProjectFacilitators]);

  useEffect(() => {
    if (!hasUnsavedChanges) {
      setNewProjectFacilitators([]);
    }
  }, [hasUnsavedChanges]);

  /**
   * Handles removal of a new organization facilitator from the list
   *
   * @param id ID of the facilitator in question
   */
  const removeNewFacilitatorFromList = (id: string): void => {
    setNewProjectFacilitators(
      newProjectFacilitators.filter(
        (facilitator: ProjectFacilitatorViewModel) => facilitator.id !== id
      )
    );
  };

  /**
   * Validate if a specific facilitator option is disabled in the search bar
   *
   * @param userId ID of the facilitator in question
   * @returns {boolean} Returns true if the facilitator option should be disabled
   */
  const isOptionDisabled = (userId: string): boolean =>
    // prettier-ignore
    existingFacilitators.find((existingFacilitator) => existingFacilitator.id === userId) !== undefined ||
    newProjectFacilitators.find((newFacilitator) => newFacilitator.id === userId) !== undefined;

  /**
   * Fetches and loads the search bar with organization facilitator filtered by the input query
   *
   * @param inputValue Query to filter the organization facilitators by
   * @returns {Promise<Array<ProjectFacilitatorsOption>>} List of filtered organization facilitator options
   */
  const loadOptions = async (
    inputValue: string
  ): Promise<Array<ProjectFacilitatorsOption>> => {
    try {
      const orgFacilitators = await fetchOrgFacilitators(inputValue);
      const options: Array<ProjectFacilitatorsOption> =
        orgFacilitators.items.map((facilitator: OrganizationFacilitator) => ({
          value: facilitator.id,
          label: facilitator.name,
          image: facilitator.image,
          supervisor: facilitator.supervisor,
          phoneNumber: facilitator.phoneNumber,
          isDisabled: isOptionDisabled(facilitator.id),
        }));
      return options;
    } catch (error) {
      if (error instanceof ApiError) {
        setLookupErrorCode(error.status ?? null);
      } else {
        setLookupErrorCode(null);
      }
      return [];
    }
  };

  /**
   * Handles the onChange event for the organization facilitator search bar
   *
   * @param selected Value of the selected facilitator option
   */
  const handleSelectChange = (
    selected: ValueType<ProjectFacilitatorsOption>
  ): void => {
    const selectedOption = selected as ProjectFacilitatorsOption;
    const facilitator = new ProjectFacilitatorViewModel(
      selectedOption.value,
      selectedOption.label,
      selectedOption.phoneNumber,
      selectedOption.image,
      selectedOption.supervisor
    );
    // prettier-ignore
    if (newProjectFacilitators.find((newFacilitator) => newFacilitator.id === facilitator.id) === undefined) {
      setNewProjectFacilitators([...newProjectFacilitators, facilitator]);
    }
    /**
     * Focuses the search bar once a user is added to the list
     */
    if (selectRef.current) {
      /**
       * here if we do not blur() and then focus()
       * the input stays focused but the cursor
       * becomes invisible; this is done as a workaround
       * for that issue; this might be related to
       * issue: https://github.com/JedWatson/react-select/issues/3819
       */
      selectRef.current.blur();
      selectRef.current.focus();
    }
  };

  /**
   * Renders an option for the organization user facilitator bar
   *
   * @param option Data for the search option
   * @returns {JSX.Element} JSX snippet for the search option
   */
  const renderOption = (option: ProjectFacilitatorsOption): JSX.Element => {
    const { value, label, isDisabled, image, supervisor } = option;
    return (
      <Row
        className="m-0 pt-1 pb-1"
        style={
          isDisabled
            ? {
                opacity: '0.7',
                pointerEvents: 'none',
              }
            : {}
        }
        id={`facilitator.${String(value)}`}
      >
        <Col xs="auto" className="pt-1">
          <ImageComponent
            className="rounded-circle object-fit-cover border-light"
            ignoreBlur
            src={image}
            alt={image}
            fallbackSrc={blankAvatar}
            width="25"
            height="25"
          />
        </Col>
        <Col className="pl-0">
          <div className="text-14-semibold">{label}</div>
          <div className={`text-14-light ${isDisabled ? '' : 'text-gray'}`}>
            <small>{supervisor?.name ?? intl.get('LBL_NONE')}</small>
          </div>
        </Col>
      </Row>
    );
  };

  /**
   * Renders common API errors for the screen
   *
   * @returns {ReactNode} JSX snippet containing the error message
   */
  const renderCommonErrors = (): ReactNode => {
    if (errorStatus && errorStatus.msg) {
      const fieldKeys = Object.keys(errorStatus.fields);
      const commonFields = ['facilitators'];
      let error: null | string | JSX.Element = null;

      if (fieldKeys.length === 0) {
        error = errorStatus.msg;
      } else if (commonFields.includes(fieldKeys[0])) {
        const errorCode = errorStatus.fields[fieldKeys[0]];
        error = intl.get(getLocalizedErrorString(errorCode));
      } else {
        error = intl.get('ERR_PROJECT_FACILITATORS_ADD_FAILURE');
      }

      if (error) {
        return (
          <Row>
            <Col>
              <FormGroup>
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </FormGroup>
            </Col>
          </Row>
        );
      }
    }
    return null;
  };

  /**
   * Handles adding new organization facilitators to the project
   */
  const handleSubmit = async (): Promise<void> => {
    try {
      await onAddExistingFacilitators(newProjectFacilitators);
      onAddFacilitatorsSuccess(false, true);
      onShowSuccessState(
        newProjectFacilitators.length,
        newProjectFacilitators.length === 1
          ? newProjectFacilitators[0].name
          : undefined
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const submitDisabled =
    status === Status.Loading || newProjectFacilitators.length === 0;

  /**
   * handles menuOpen state depending on wether or not input is empty
   * done to avoid issue: https://github.com/JedWatson/react-select/issues/3819
   * issue of cursor not visible when openMenuOnFocus prop is false after selecting
   * a value. In order to fix; as a workaround openMenuOnFocus has to be set to
   * true and the behavior of that prop should be implemented in a different way
   * hence the need of manually controlled menuIsOpen prop
   * @param text inputValue
   */
  const handleInputChange = (text: string): void => {
    setSelectText(text);
    if (text && text.length > 0) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  };

  const onMenuClose = (): void => setMenuIsOpen(false);

  return (
    <form>
      <Row>
        <Col xs="12">
          <FormGroup>
            <AsyncSelect
              ref={(ref): void => {
                selectRef.current = ref;
              }}
              autoFocus
              isSearchable
              inputProps={{ maxLength: Constraints.MaxFieldLength }}
              hasPermission={lookupErrorCode !== HTTP_STATUS.FORBIDDEN}
              defaultOptions={[]}
              value={null}
              loadOptions={loadOptions}
              onChange={handleSelectChange}
              getOptionLabel={(e): string => e.label}
              getOptionValue={(e): string => e.value}
              openMenuOnFocus
              inputValue={selectText}
              onInputChange={handleInputChange}
              openMenuOnClick={false}
              controlShouldRenderValue={false}
              tabSelectsValue={false}
              menuIsOpen={menuIsOpen}
              onMenuClose={onMenuClose}
              components={{
                ValueContainer: SearchBarValueContainer,
                NoOptionsMessage: SearchBarNoOptionsMessage,
                DropdownIndicator: (): null => null,
                IndicatorSeparator: (): null => null,
              }}
              formatOptionLabel={renderOption}
              isDisabled={status === Status.Loading}
              styles={SearchBarStyles.GetCustomStyles()}
              placeholder={intl.get(
                'LBL_PROJECTS_USERS_ADD_SEARCH_PLACEHOLDER'
              )}
              name="existing-facilitators"
              classNamePrefix="insight-select-no-border insight-select-no-border-projects"
            />
          </FormGroup>
        </Col>
      </Row>
      {renderCommonErrors()}
      <Row>
        <Col xs="12">
          <ExistingFacilitatorsList
            existingFacilitators={existingFacilitators}
            status={status}
            newFacilitators={newProjectFacilitators}
            removeNewFacilitatorFromList={removeNewFacilitatorFromList}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="mt-3 justify-content-between d-flex">
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={onToggle}
          >
            {intl.get('BTN_CANCEL')}
          </Button>
          <Button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            {intl.get('BTN_ADD')}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default AddExistingFacilitators;
