import { UserTabFilters } from 'modules/private/settings/containers/users-view/UsersViewState';

const InvitedSortableColumns: Set<string> = new Set([
  'email',
  'jobRole',
  'implementingPartner',
  'permissionLevel',
  'countryDataAccess',
]);

const UsersSortableColumns: Set<string> = new Set([
  'name',
  'email',
  'jobRole',
  'implementingPartner',
  'permissionLevel',
  'countryDataAccess',
]);

const TableConfig = {
  [UserTabFilters.INVITED]: {
    defaultSort: 'email',
    sortCols: InvitedSortableColumns,
    defaultSortDesc: false,
  },
  [UserTabFilters.USERS]: {
    defaultSort: 'name',
    sortCols: UsersSortableColumns,
    defaultSortDesc: false,
  },
};

export default TableConfig;
