/* eslint-disable react/no-array-index-key */
import { useEffect, useState, FC as ReactFC, memo } from 'react';

import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Col, Collapse, Fade, Row } from 'reactstrap';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import { formatDate } from 'helpers/DateFormat';
import PermissionUtil from 'helpers/PermissionUtil';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import DateFormatType from 'shared/enums/DateFormatType';
import Status from 'shared/enums/Status';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveyType from 'shared/enums/SurveyType';

import CollapseItem from './collapse-item/CollapseItem';
import styles from './draftedSurveysListItem.module.scss';
import DraftedSurveysListItemProps from './DraftedSurveysListItemProps';
import RecipientTypeSelect from './recipient-type-select/RecipientTypeSelect';
import SurveyTypeSelect from './survey-type-select/SurveyTypeSelect';

const DraftedSurveysListItem: ReactFC<DraftedSurveysListItemProps> = (
  props: DraftedSurveysListItemProps
) => {
  const {
    data,
    status,
    permissions,
    selectedSurveyTypes,
    selectedRecipientTypes,
    focusedSurvey,
    onSurveyTypeChange,
    onRecipientTypeChange,
    onItemExpanded,
    onSendSurvey,
  } = props;

  const { claims } = permissions;
  const {
    selectedFilters: { allMembers, memberAge, memberEducation, memberGender },
  } = focusedSurvey;

  const [displayInvalidCountAlert, setDisplayInvalidCountAlert] =
    useState<boolean>(false);
  const [displayCollapseItem, setDisplayCollapseItem] =
    useState<boolean>(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, []);

  useEffect(() => {
    if (!focusedSurvey.isItemExpanded) {
      setTimeout(() => setDisplayCollapseItem(false), 500);
    }
  }, [focusedSurvey.isItemExpanded]);

  /* IDs of rows that have survey types selected */
  const surveyTypeSelectedRows = Object.keys(selectedSurveyTypes);
  /* IDs of rows that have recipient types selected */
  const recipientTypeSelectedRows = Object.keys(selectedRecipientTypes);
  /* Selected survey type for current row */
  let selectedSurveyType: SurveyType | null = null;
  /* Selected recipient type for current row */
  let selectedRecipientType: SurveyRecipientType | null = null;
  if (surveyTypeSelectedRows.includes(data.id)) {
    selectedSurveyType = selectedSurveyTypes[data.id] as SurveyType;
  }
  if (recipientTypeSelectedRows.includes(data.id)) {
    selectedRecipientType = selectedRecipientTypes[
      data.id
    ] as SurveyRecipientType;
  }

  /**
   * Expand row filters section
   */
  const expandFilters = (): void => {
    onItemExpanded(true, data.id);
    setDisplayCollapseItem(true);
    setDisplayInvalidCountAlert(false);
  };

  /**
   * Handle click event for send button
   */
  const handleSendClick = (): void => {
    const {
      appliedFilters: {
        data: { totalGroupCount, totalMemberCount },
      },
    } = focusedSurvey;
    if (
      (selectedRecipientType === SurveyRecipientType.Groups &&
        totalGroupCount <= 0) ||
      (selectedRecipientType === SurveyRecipientType.Members &&
        (totalGroupCount <= 0 || totalMemberCount <= 0))
    ) {
      setDisplayInvalidCountAlert(true);
    } else {
      setDisplayInvalidCountAlert(false);
      onSendSurvey(selectedSurveyType, selectedRecipientType);
    }
  };

  const canCreateSurveys = PermissionUtil.Can(
    claims,
    ResourceKeys.SurveysCreate
  );
  const canCollectIDs = PermissionUtil.Can(
    claims,
    ResourceKeys.SurveysCollectAdditionalID
  );

  const selectedRecipientsButtonDisabled =
    focusedSurvey.expandedRowId === data.id ||
    selectedSurveyType === null ||
    selectedRecipientType === null ||
    status === Status.Loading;

  const hasMemberFilterSelected =
    allMembers.length !== 0 ||
    memberAge.length !== 0 ||
    memberEducation.length !== 0 ||
    memberGender.length !== 0;

  const sendButtonDisabled =
    !focusedSurvey.isItemExpanded ||
    focusedSurvey.expandedRowId !== data.id ||
    selectedSurveyType === null ||
    selectedRecipientType === null ||
    status === Status.Loading ||
    focusedSurvey.selectedFilters.countries.length === 0 ||
    (selectedRecipientType === SurveyRecipientType.Members &&
      !hasMemberFilterSelected);

  return (
    <>
      <Row>
        <Col xs="12" className="pr-4">
          <Row className={styles.item}>
            <Col
              className={`d-flex align-items-center overflow-hidden ${styles.name}`}
            >
              {data.name ? (
                <EllipsisTooltip
                  tag="p"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-class="overflow-wrap"
                  data-tip={data.name}
                  className="truncate shorten"
                >
                  {data.name}
                </EllipsisTooltip>
              ) : (
                <p>{intl.get('LBL_NA')}</p>
              )}
            </Col>
            <Col
              xs="auto"
              className={`d-flex align-items-center overflow-hidden ${styles.date}`}
            >
              <p>
                {data.createdDate
                  ? formatDate(data.createdDate, DateFormatType.SurveyCreation)
                  : intl.get('LBL_NA')}
              </p>
            </Col>
            <Col xs={2}>
              <SurveyTypeSelect
                canCreateSurveys={canCreateSurveys}
                canCollectIDs={canCollectIDs}
                surveyId={data.id}
                disabled={!canCreateSurveys && !canCollectIDs}
                selectedSurveyTypes={selectedSurveyTypes}
                onChange={(e, close): void =>
                  onSurveyTypeChange(e, data.id, close)
                }
              />
            </Col>
            <Col xs={2}>
              <RecipientTypeSelect
                surveyId={data.id}
                disabled={!canCreateSurveys && !canCollectIDs}
                selectedRecipientTypes={selectedRecipientTypes}
                onChange={(e, close): void =>
                  onRecipientTypeChange(e, data.id, close)
                }
              />
            </Col>
            <Col
              className={`d-flex align-items-center justify-content-around ${styles.actions}`}
            >
              <button
                className={`btn btn-secondary btn-sm ${styles.recipientsBtn}`}
                onClick={expandFilters}
                disabled={selectedRecipientsButtonDisabled}
              >
                <i className="icon-plus" />
                {intl.get('BTN_SURVEYS_DRAFTED_SELECT_RECIPIENTS')}
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={handleSendClick}
                disabled={sendButtonDisabled}
              >
                {intl.get('BTN_SURVEYS_DRAFTED_SEND')}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="pr-4">
          <Collapse
            isOpen={
              focusedSurvey.isItemExpanded &&
              data.id === focusedSurvey.expandedRowId
            }
            className={styles.collapse}
          >
            <Fade
              timeout={500}
              in={
                focusedSurvey.isItemExpanded &&
                data.id === focusedSurvey.expandedRowId
              }
            >
              {displayCollapseItem && (
                <CollapseItem
                  focusedSurvey={focusedSurvey}
                  selectedSurveyType={selectedSurveyType}
                  selectedRecipientType={selectedRecipientType}
                  displayAlert={displayInvalidCountAlert}
                  onItemExpanded={onItemExpanded}
                />
              )}
            </Fade>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default memo(DraftedSurveysListItem);
