/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useEffect, useRef, useState, FC as ReactFC } from 'react';

import { Col, Row, Toast as ToastRS } from 'reactstrap';

import Notifications from 'constants/Notifications';
import usePrevious from 'shared/hooks/use-previous/UsePrevious';
import successAnimation from 'shared/static/img/animations/success.gif';

import styles from './toast.module.scss';
import ToastProps, { TextData } from './ToastProps';

const defaultProps = {
  type: 'success',
  delay: Notifications.ToastHideDelay,
  mountOnEnter: true,
  unmountOnExit: true,
};

const defaultTextData: TextData = {
  header: undefined,
  text: undefined,
};

const Toast: ReactFC<ToastProps> = (props: ToastProps) => {
  const {
    isOpen,
    delay = defaultProps.delay,
    hideToast,
    type = defaultProps.type,
    header,
    text,
    mountOnEnter = defaultProps.mountOnEnter,
    unmountOnExit = defaultProps.unmountOnExit,
    ...rest
  } = props;

  const [textData, setTextData] = useState<TextData>(defaultTextData);
  const prevOpen = usePrevious(isOpen);

  const toastTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isOpen === true) {
      setTextData({ header, text });
    }
  }, [isOpen, header, text]);

  useEffect(() => {
    if (isOpen && prevOpen !== isOpen) {
      if (toastTimeoutRef && toastTimeoutRef.current) {
        // Clear the timeout if one already exists from a previous toast instance
        clearTimeout(toastTimeoutRef.current);
      }
      toastTimeoutRef.current = setTimeout(() => {
        hideToast();
      }, delay);
    }
  }, [isOpen, delay, hideToast, prevOpen]);

  const handleExited = (): void => setTextData(defaultTextData);

  /**
   * Renders the toast image based on type
   *
   * @param toastType Type of toast
   * @returns {ReactNode} JSX snippet containing the toast image
   */
  const renderType = (toastType?: string): ReactNode => {
    switch (toastType) {
      case 'success':
        return <img src={successAnimation} alt="Success" />;
      case 'error':
        return <span className={styles.circle} />;

      default:
        return null;
    }
  };

  return (
    <>
      <ToastRS
        className={styles.toast}
        isOpen={isOpen}
        transition={{
          timeout: 150,
          mountOnEnter,
          unmountOnExit,
          onExited: handleExited,
        }}
        {...rest}
      >
        <Col xs="12" className="position-relative">
          <button onClick={hideToast} className={styles.close}>
            {/* <img src={closeIcon} alt="Close" /> */}
            <i className="icon-close-small" />
          </button>
          <Row>
            <Col sm="auto">
              <div className={styles.frame}>{renderType(type)}</div>
            </Col>
            <Col>
              <div className={styles.message}>
                <div className="text-14-semibold text-uppercase">
                  {textData.header}
                </div>
                <div className="text-12-medium">{textData.text}</div>
              </div>
            </Col>
          </Row>
        </Col>
      </ToastRS>
    </>
  );
};

export default Toast;
