import GroupAge from 'shared/enums/GroupAge';
import LastMessagedDate from 'shared/enums/LastMessagedDate';

export const groupAges = [
  {
    value: GroupAge.One,
    label: 'LBL_GF_FIRST_CYCLE',
  },
  {
    value: GroupAge.Two,
    label: 'LBL_GF_SECOND_CYCLE',
  },
  {
    value: GroupAge.ThreeOrMore,
    label: 'LBL_GF_THREE_OR_MORE_CYCLE',
  },
];

export const lastMessagedDates = [
  {
    value: LastMessagedDate.FifteenOrLess,
    label: 'LBL_MESSAGING_COMPOSE_LMD_FIFTEEN_OR_LESS',
  },
  {
    value: LastMessagedDate.FifteenToThirty,
    label: 'LBL_MESSAGING_COMPOSE_LMD_SIXTEEN_TO_THIRTY',
  },
  {
    value: LastMessagedDate.ThirtyToFortyFive,
    label: 'LBL_MESSAGING_COMPOSE_LMD_THIRTY_ONE_TO_FORTY_FIVE',
  },
  {
    value: LastMessagedDate.MoreThanFortyFive,
    label: 'LBL_MESSAGING_COMPOSE_LMD_MORE_THAN_FORTY_FIVE',
  },
];

export const filterTypesMap = new Map<string, string>([
  ['GROUP_AGE', 'LBL_GROUP_AGE'],
  ['GROUP_STATUS', 'LBL_TITLE_GROUP_STATUS'],
  ['COUNTRY', 'LBL_MESSAGING_COMPOSE_COUNTRIES_FILTER_TITLE'],
  ['PROJECT', 'LBL_MESSAGING_COMPOSE_PROJECTS_FILTER_TITLE'],
  ['FACILITATOR', 'LBL_MESSAGING_COMPOSE_FACILITATORS_FILTER_TITLE'],
  ['LAST_MESSAGED', 'LBL_MESSAGING_COMPOSE_LMD_FILTER_TITLE'],
]);
