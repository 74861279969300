/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CSSProperties } from 'react';

import { StylesConfig } from 'react-select';

class SearchBarStyles {
  public static GetCustomStyles(): StylesConfig {
    return {
      control: (base: CSSProperties) => ({
        ...base,
        backgroundColor: '#FAFAF5',
        border: 0,
        borderRadius: '4px',
        boxShadow: 'none',
        height: '44px',
        '&:hover': {
          border: 0,
        },
      }),
      valueContainer: (base: CSSProperties) => ({
        ...base,
        padding: '0 14px',
      }),
      placeholder: (base: CSSProperties) => ({
        ...base,
        color: '#DBDCE1',
        fontSize: '12px',
        fontWeight: 700,
        paddingLeft: 26,
      }),
      option: (base: CSSProperties, state) => ({
        ...base,
        fontSize: '12px',
        fontWeight: 700,
      }),
    };
  }
}

export default SearchBarStyles;
