/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import QueryObjectBuilder from 'api/common/QueryObjectBuilder';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ListFilters from 'api/common/types/ListFilters';
import GroupListResponse from 'api/group/responses/GroupListResponse';
import BackupStatusSummary from 'api/group/types/BackupStatusSummary';
import GroupWidget from 'api/group/types/group-widget/GroupWidget';
import GroupWidgetPreference from 'api/group/types/group-widget/GroupWidgetPreference';
import GroupDetailListItem from 'api/group/types/GroupDetailListItem';
import GroupDetailMapItem from 'api/group/types/GroupDetailMapItem';
import GroupDetails from 'api/group/types/GroupDetails';
import GroupDetailsBalanceSheet from 'api/group/types/GroupDetailsBalanceSheet';
import GroupFinancialMetrics from 'api/group/types/GroupFinancialMetrics';
import GroupMetricValue from 'api/group/types/GroupMetricValue';
import GroupNotifications from 'api/group/types/GroupNotifications';
import GroupOverviewStats from 'api/group/types/GroupOverviewStats';
import TablePreferences from 'api/group/types/TablePreferences';
import { GroupApiTags } from 'constants/request-tags/RequestTags';
import GroupFinancialChartChartTypes from 'modules/private/group/components/group-financial-chart-container/GroupFinancialChartChartTypes';
import GroupFinancialChartFilter from 'modules/private/group/components/group-financial-chart-container/GroupFinancialChartFilter';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import Frequency from 'shared/enums/Frequency';

class GroupApi extends ApiBase {
  async GetGroupsExcel(
    globalFilters: GlobalFilters,
    reportTime: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const action = 'groups/excel-export';

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);

    const queryParams = { ...globalFiltersQuery, reportTime };

    const excelData = this.GetAsyncBlob({
      action,
      tag: GroupApiTags.GetGroupsExcel,
      cancelSource: cancelToken,
      queryParams,
    });

    return excelData;
  }

  async GetGroupListData(
    filters: GlobalFilters,
    listFilters: ListFilters,
    notificationFilters: string[],
    cancelToken: CancelTokenSource
  ): Promise<GroupListResponse<GroupDetailListItem>> {
    const action = 'groups/list';

    const { page, pageSize, sortBy, sort, search } = listFilters;

    const listFilterQuery = { page, pageSize, sortBy, sort };

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(filters);

    let queryParams: Record<string, any> = {
      ...globalFiltersQuery,
      ...listFilterQuery,
    };

    if (notificationFilters.length) {
      queryParams = { ...queryParams, notificationFilters };
    }

    if (search && search.trim().length > 0) {
      queryParams = { ...queryParams, search };
    }

    const listData = this.GetAsync<GroupListResponse<GroupDetailListItem>>({
      action,
      tag: GroupApiTags.GetGroupListData,
      cancelSource: cancelToken,
      queryParams,
    });

    return listData;
  }

  async GetGroupsAllIds(
    filters: GlobalFilters,
    search: string,
    notificationFilters: string[],
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<string>> {
    const action = 'groups/list-all';

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(filters);

    let queryParams: Record<string, any> = {
      ...globalFiltersQuery,
    };

    if (notificationFilters.length) {
      queryParams = { ...queryParams, notificationFilters };
    }

    if (search && search.trim().length > 0) {
      queryParams = { ...queryParams, search };
    }

    const listData = this.GetAsync<ListResponse<string>>({
      action,
      tag: GroupApiTags.GetGroupsAllIds,
      cancelSource: cancelToken,
      queryParams,
    });

    return listData;
  }

  async GetGroupNotifications(
    filters: GlobalFilters,
    cancelToken: CancelTokenSource,
    search?: string
  ): Promise<ObjectResponse<GroupNotifications>> {
    const action = 'groups/notifications';

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(filters);

    let queryParams: Record<string, any> = { ...globalFiltersQuery };

    if (search && search.trim().length > 0) {
      queryParams = { ...queryParams, search };
    }
    const listData = this.GetAsync<ObjectResponse<GroupNotifications>>({
      action,
      tag: GroupApiTags.GetGroupNotifications,
      cancelSource: cancelToken,
      queryParams,
    });

    return listData;
  }

  async GetGroupOverviewStats(
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<GroupOverviewStats>> {
    const action = 'user/group-overview-stats';
    const result = await this.GetAsync<ObjectResponse<GroupOverviewStats>>({
      action,
      tag: GroupApiTags.GetGroupOverviewStats,
      cancelSource: cancelToken,
    });

    return result;
  }

  async SetTablePreferences(
    preferences: TablePreferences[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/preferences/groups/group-table';
    const result = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body: preferences,
      tag: GroupApiTags.SetTablePreferences,
      cancelSource: cancelToken,
    });

    return result;
  }

  async GetBackupStatusSummaryData(
    filters: GlobalFilters,
    cancelToken?: CancelTokenSource
  ): Promise<ObjectResponse<BackupStatusSummary>> {
    const action = 'groups/backupstatus-summery';

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(filters);

    const data = this.GetAsync<ObjectResponse<BackupStatusSummary>>({
      action,
      tag: GroupApiTags.GetBackupStatusSummaryData,
      cancelSource: cancelToken,
      queryParams: globalFiltersQuery,
    });

    return data;
  }

  async GetGroupMetricData(
    filters: GlobalFilters,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupMetricValue>> {
    const action = 'groups/cards';

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(filters);

    const data = this.GetAsync<ListResponse<GroupMetricValue>>({
      action,
      tag: GroupApiTags.GetGroupMetricData,
      cancelSource: cancelToken,
      queryParams: globalFiltersQuery,
    });

    return data;
  }

  async GetGroupMapData(
    globalFilters: GlobalFilters,
    searchTerm = '',
    notificationFilters: string[],
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupDetailMapItem>> {
    const action = 'groups/locations';

    const globalFiltersQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);

    let queryParams: Record<string, any> = {
      ...globalFiltersQuery,
    };

    if (notificationFilters.length) {
      queryParams = { ...queryParams, notificationFilters };
    }

    if (searchTerm && searchTerm.trim().length > 0) {
      queryParams = { ...queryParams, search: searchTerm };
    }

    const data = this.GetAsync<ListResponse<GroupDetailMapItem>>({
      action,
      tag: GroupApiTags.GetGroupMapData,
      cancelSource: cancelToken,
      queryParams,
    });

    return data;
  }

  /* group details api client methods */

  async GetGroupDetailsExcel(
    groupNumber: string,
    reportTime: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const action = `groups/details/${groupNumber}/excel-export`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: GroupApiTags.GetGroupDetailsExcel,
      cancelSource: cancelToken,
      queryParams: { reportTime },
    });

    return excelData;
  }

  async GetGroupDetails(
    groupNumber: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<GroupDetails>> {
    const path = `groups/details/${groupNumber}`;
    const action = `${path}`;
    const data = this.GetAsync<ObjectResponse<GroupDetails>>({
      action,
      tag: GroupApiTags.GetGroupDetails,
      cancelSource: cancelToken,
    });

    return data;
  }

  async GetGroupWidgetData(
    groupNumber: string,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupWidget>> {
    const action = `groups/details/${groupNumber}/widgets`;
    const widgetData = this.GetAsync<ListResponse<GroupWidget>>({
      action,
      tag: GroupApiTags.GetGroupWidgetData,
      cancelSource: cancelToken,
    });

    return widgetData;
  }

  async GetGroupFinancialMetrics(
    groupNumber: string,
    chartType: GroupFinancialChartChartTypes,
    filters: GroupFinancialChartFilter,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<GroupFinancialMetrics>> {
    const action = `groups/details/${groupNumber}/metrics`;

    const queryParams = {
      type: String(chartType),
      dateComparison: String(filters.timeUnit.value),
      frequency: Frequency.Monthly,
    };

    const data = this.GetAsync<ObjectResponse<GroupFinancialMetrics>>({
      action,
      tag: GroupApiTags.GetGroupFinancialMetrics,
      cancelSource: cancelToken,
      queryParams,
    });

    return data;
  }

  async GetGroupDetailsBalanceSheet(
    groupNumber: string,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<GroupDetailsBalanceSheet>> {
    const path = `groups/details/${groupNumber}/balance-sheet`;
    const action = `${path}`;
    const data = this.GetAsync<ObjectResponse<GroupDetailsBalanceSheet>>({
      action,
      tag: GroupApiTags.GetGroupDetailsBalanceSheet,
      cancelSource: cancelToken,
    });

    return data;
  }

  async AssignGroupStatusToGroups(
    statusId: string,
    groups: string[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `groups/status/bulk`;
    const body = { groups, statusId };

    const response = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: GroupApiTags.AssignGroupStatusToGroups,
      cancelSource: cancelToken,
    });

    return response;
  }

  async AssignFacilitatorsToProject(
    facilitator: string,
    groups: string[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = `organizations/facilitators/${facilitator}/groups`;
    const body = { groups };

    const response = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: GroupApiTags.AssignFacilitatorsToProject,
      cancelSource: cancelToken,
    });

    return response;
  }

  async SetWidgetPreferences(
    preferences: GroupWidgetPreference[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/preferences/group-details/widgets';
    const result = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body: preferences,
      tag: GroupApiTags.SetWidgetPreferences,
      cancelSource: cancelToken,
    });

    return result;
  }
}

const GroupApiInstance = new GroupApi();

export default GroupApiInstance;
