import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Col, Fade, Row } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';

import styles from '../messagingPopups.module.scss';
import MessageWarningPopupProps from './MessageWarningPopupProps';

const MessageWarningPopup: ReactFC<MessageWarningPopupProps> = (
  props: MessageWarningPopupProps
) => {
  const { display, filteredGroups, onCancel, onContinue } = props;

  const warnedGroups = filteredGroups.filter(
    (group) => group.messagedRecently === true
  );

  return (
    <Fade mountOnEnter unmountOnExit in={display} className={styles.overlay}>
      <TrapFocus autoFocus>
        {({ firstFocus, lastFocus }): JSX.Element => (
          <div
            className={`${styles.confirmation} ${styles.warning}`}
            data-cy="warning"
          >
            <Row className="w-100">
              <Col
                xs="12"
                className="mb-3 text-14-semibold text-center text-gray"
              >
                {intl.get('LBL_MESSAGING_COMPOSE_WARNING_POPUP_PART_1')}
              </Col>
              <Col
                xs="12"
                className="mb-3 text-14-medium text-center text-gray"
              >
                {intl.get('LBL_MESSAGING_COMPOSE_WARNING_POPUP_PART_2')}
              </Col>
              <Col xs="12" className="p-3">
                <PerfectScrollbar className={styles.scrollContainer}>
                  <div className="text-14-medium text-gray mb-0">
                    <ul className="pl-0">
                      {warnedGroups.map((group) => (
                        <li key={group.id}>
                          <EllipsisTooltip
                            tag="div"
                            data-place="right"
                            data-for="insTooltip"
                            data-tip={group.name}
                            data-class="overflow-wrap"
                            className="truncate"
                          >
                            {group.name}
                          </EllipsisTooltip>
                        </li>
                      ))}
                    </ul>
                  </div>
                </PerfectScrollbar>
              </Col>
              <Col xs="12" className="justify-content-center d-flex mt-3 pt-3">
                <Button
                  innerRef={lastFocus}
                  className="btn btn-light btn-sm text-14-semibold mr-3"
                  onClick={onCancel}
                >
                  {intl.get('BTN_MESSAGING_NO_CANCEL')}
                </Button>
                <Button
                  innerRef={firstFocus}
                  className="btn btn-warning btn-sm text-14-semibold ml-3"
                  type="button"
                  onClick={onContinue}
                >
                  {intl.get('BTN_MESSAGING_YES_SEND')}
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center w-100" />
          </div>
        )}
      </TrapFocus>
    </Fade>
  );
};

export default MessageWarningPopup;
