import { SortableContainer } from 'react-sortable-hoc';

import SortableWidget from '../sortable-widget/SortableWidget';
import SortableWidgetContainerProps from './SortableWidgetContainerProps';

/**
 * Wrapping SortableWidget components with SortableContainer HOC to
 * enable drag and drop sorting.
 */
const SortableWidgetContainer = SortableContainer(
  ({
    widgetData,
    widgetsLoading,
    updateWidgetData,
    groupDetailsSetupInProgress,
  }: SortableWidgetContainerProps) => (
    <div
      className={`row dashboard-row ${
        groupDetailsSetupInProgress
          ? 'active-sortable-widget-container'
          : 'sortable-widget-container'
      }`}
    >
      {widgetData.map((data, index) => (
        <SortableWidget
          index={index}
          key={data.type}
          widgetData={data}
          widgetsLoading={widgetsLoading}
          updateWidgetData={updateWidgetData}
          disabled={!groupDetailsSetupInProgress}
        />
      ))}
    </div>
  )
);

export default SortableWidgetContainer;
