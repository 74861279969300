/* eslint-disable @typescript-eslint/no-explicit-any */
import FacilitatorMetricsFilters from 'modules/private/dashboard/components/facilitator-metrics/FacilitatorMetricsFilter';
import FinancialMetricsFilter from 'modules/private/dashboard/components/financial-metrics/FinancialMetricsFilter';
import MembershipMetricsFilter from 'modules/private/dashboard/components/membership-metrics/MembershipMetricsFilter';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';

import GlobalFiltersQueryTypes from './types/GlobalFiltersQueryTypes';

class QueryObjectBuilder {
  static createGlobalFiltersQuery({
    backupStatus,
    groupAge,
    groupStatuses,
    projects,
    fromDate,
    toDate,
  }: GlobalFilters): GlobalFiltersQueryTypes {
    let globalQueries: GlobalFiltersQueryTypes = {
      backupStatus,
      groupAge,
      projects: [],
      groupStatuses: [],
    };

    if (fromDate && toDate) {
      globalQueries = {
        ...globalQueries,
        dateFrom: fromDate,
        dateTo: toDate,
      };
    }

    if (groupStatuses && groupStatuses.length > 0) {
      globalQueries = {
        ...globalQueries,
        groupStatuses,
      };
    }

    if (projects && projects.length > 0) {
      globalQueries = {
        ...globalQueries,
        projects,
      };
    }

    return globalQueries;
  }

  static createFinancialFiltersQuery({
    cyclePhase,
    frequency,
    timeUnit,
  }: FinancialMetricsFilter) {
    return {
      cyclePhase: String(cyclePhase.value),
      frequency: String(frequency.value),
      dateComparison: String(timeUnit.value),
    };
  }

  static createMembershipFiltersQuery({
    frequency,
    timeUnit,
  }: MembershipMetricsFilter) {
    return {
      frequency: String(frequency.value),
      dateComparison: String(timeUnit.value),
    };
  }

  static createFacilitatorFiltersQuery({
    facilitators,
    frequency,
  }: FacilitatorMetricsFilters) {
    let facilitatorFilter: Record<string, any> = {
      frequency: String(frequency.value),
    };

    if (facilitators.length) {
      facilitatorFilter = { ...facilitatorFilter, facilitators };
    }

    return facilitatorFilter;
  }
}

export default QueryObjectBuilder;
