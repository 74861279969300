/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC, useEffect, useState } from 'react';

import BlockUi from 'react-block-ui';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import ModulePaths from 'constants/ModulePaths';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import DashboardRoutes from 'modules/private/dashboard/DashboardRoutes';
import FinancialMetricsView from 'modules/private/finances/containers/FinancialMetricsView';
import { FinancialMetricsLocationState } from 'modules/private/finances/containers/FinancialMetricsViewProps';
import GroupRoutes from 'modules/private/group/GroupRoutes';
import ProjectsRoutes from 'modules/private/projects/ProjectsRoutes';
import SettingsRoutes from 'modules/private/settings/SettingsRoutes';
import HeaderToolbar from 'shared/components/header-toolbar/HeaderToolbar';
import LeftNavigation from 'shared/components/left-navigation/LeftNavigation';
import EventKey from 'shared/enums/EventKey';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';

import AccountRoutesProps from './AccountRouteProps';
import { MessagingViewLocationState } from './messaging/containers/messaging-view/MessagingViewProps';
import MessagingRoutes from './messaging/MessagingRoutes';
import SurveysLocationState from './surveys/containers/SurveysLocationState';
import SurveysRoutes from './surveys/SurveysRoutes';

const AccountRoutes: ReactFC<AccountRoutesProps> = (
  props: AccountRoutesProps
) => {
  const { appContext, ...routeChildrenProps } = props;
  const { permissionsData, fetchedCurrency, selectedCurrency } = appContext;

  const isPermissionLoaded =
    permissionsData.status !== Status.Loading ||
    permissionsData.silent === true;

  const [containerKey, setContainerKey] = useState<string>(uuid());

  useEffect(() => {
    EventBus.getInstance().register(EventKey.RemountComponentTree, () => {
      setContainerKey(uuid());
    });
  });

  useEffect(() => {
    setContainerKey(uuid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  return (
    <>
      {isPermissionLoaded && (
        <>
          <LeftNavigation context={appContext} {...routeChildrenProps} />
          <HeaderToolbar context={appContext} />
          <BlockUi
            tag="div"
            className="block-container"
            blocking={fetchedCurrency.status === Status.Loading}
          >
            <div key={containerKey}>
              <Switch>
                <Route
                  path={ModulePaths.DashboardPath}
                  render={(routeProps): JSX.Element => (
                    <DashboardRoutes {...routeProps} appContext={appContext} />
                  )}
                />

                <Route path={ModulePaths.FinancesPath}>
                  {(
                    routeProps: RouteChildrenProps<
                      {},
                      FinancialMetricsLocationState
                    >
                  ): JSX.Element => (
                    <FinancialMetricsView
                      {...routeProps}
                      appContext={appContext}
                    />
                  )}
                </Route>

                <Route
                  path={ModulePaths.GroupsPath}
                  render={(routeProps): JSX.Element => (
                    <GroupRoutes {...routeProps} appContext={appContext} />
                  )}
                />

                <Route
                  path={ModulePaths.ProjectsPath}
                  render={(routeProps): JSX.Element => (
                    <ProjectsRoutes {...routeProps} appContext={appContext} />
                  )}
                />

                <Route
                  path={ModulePaths.SettingsPath}
                  render={(routeProps): JSX.Element => (
                    <SettingsRoutes {...routeProps} appContext={appContext} />
                  )}
                />

                <Route path={ModulePaths.MessagingPath}>
                  {(
                    routeProps: RouteChildrenProps<
                      {},
                      MessagingViewLocationState
                    >
                  ): JSX.Element => (
                    <MessagingRoutes {...routeProps} appContext={appContext} />
                  )}
                </Route>

                <Route path={ModulePaths.SurveysPath}>
                  {(
                    routeProps: RouteChildrenProps<{}, SurveysLocationState>
                  ): JSX.Element => (
                    <SurveysRoutes {...routeProps} appContext={appContext} />
                  )}
                </Route>

                <Redirect
                  exact
                  from="/"
                  to={{
                    pathname: ModulePaths.DashboardPath,
                    search: getGlobalFiltersQuery(
                      routeChildrenProps.location.search
                    ),
                  }}
                />

                {/* No match; could show a 404 */}
                <Route path="*">
                  <Redirect
                    to={{
                      pathname: ModulePaths.DashboardPath,
                      search: getGlobalFiltersQuery(
                        routeChildrenProps.location.search
                      ),
                    }}
                  />
                </Route>
              </Switch>
            </div>
          </BlockUi>
        </>
      )}
    </>
  );
};

export default AccountRoutes;
