enum FinancialMetricsChartTypes {
  LoanFundBalance = 'LOAN_FUND_BALANCE',
  LoanFundOutstanding = 'LOAN_FUND_OUTSTANDING',
  CumulativeSavings = 'CUMULATIVE_SAVINGS',
  AverageSavingsPerMember = 'AVERAGE_SAVINGS_PER_MEMBER',
  LoanFundUtilization = 'LOAN_FUND_UTILIZATION',
  ReturnOnSavings = 'RETURN_ON_SAVINGS',
  AverageNumOfLoansGiven = 'AVERAGE_NO_OF_LOANS_GIVEN',
  PortfolioAtRisk = 'PORTFOLIO_AT_RISK',
  PercentageOfMembersWhoHaveTakenALoan = 'PERCENTAGE_OF_MEMBERS_TAKEN_LOANS',
  LoanRepaymentRate = 'LOAN_REPAYMENT_RATE',
  SocialFundBalance = 'SOCIAL_FUND_BALANCE',
  EstimatedGroupShareOut = 'ESTIMATED_GROUP_SHAREOUT',
  NetProfit = 'NET_PROFIT',
}

export default FinancialMetricsChartTypes;
