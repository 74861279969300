import { Component } from 'react';

import axios from 'axios';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Modal } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import TIMEOUTS from 'constants/Timeouts';
import { ErrorStatus, getErrorStatus } from 'helpers/ErrorFormat';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import ForgotPasswordEmail from 'modules/public/auth/components/forgot-password-email/ForgotPasswordEmail';
import ForgotPasswordPending from 'modules/public/auth/components/forgot-password-pending/ForgotPasswordPending';
import AuthRouteProps from 'modules/public/PublicRouteProps';
import ModalBackground from 'shared/components/hoc/dummy-background/ModalBackground';
import Status from 'shared/enums/Status';

import ForgotPasswordFormValidation from './ForgotPasswordFormValidation';
import ForgotPasswordFormValues from './ForgotPasswordFormValues';
import ForgotPasswordViewState from './ForgotPasswordViewState';

class ForgotPasswordView extends Component<
  AuthRouteProps,
  ForgotPasswordViewState
> {
  constructor(props: AuthRouteProps) {
    super(props);
    this.state = {
      emailStatus: Status.Idle,
      emailResendStatus: Status.Idle,
      expiryTimeString: TIMEOUTS.AUTH_VERIFICATION_EXPIRY,
    };
  }

  componentDidMount(): void {
    setPageTitle(intl.get('LBL_FORGOT_PASSWORD_PAGE_TITLE'));
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  initialValues: ForgotPasswordFormValues = {
    email: '',
  };

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Handles form submission to send the email link for resetting the password
   *
   * @param values The values entered into the login form
   * @param formikHelpers Form helpers provided by @formik
   */
  handleSendEmail = async (
    values: { email: string },
    formikHelpers: FormikHelpers<{ email: string }>
  ): Promise<void> => {
    try {
      formikHelpers.setSubmitting(true);
      const result = await AuthApiInstance.SendResetPasswordEmail(
        values.email,
        this.source
      );
      formikHelpers.setSubmitting(false);
      this.setState({
        emailStatus: Status.Success,
        expiryTimeString: result.expiryTime,
      });

      sendEventGA(
        CategoryKeysGA.AccountForgotPassword,
        ActionKeysGA.ForgotPassword,
        LabelKeysGA.Success
      );
    } catch (error) {
      this.setState({ emailStatus: Status.Error });
      formikHelpers.setStatus(this.handleErrors(error));
    }
  };

  /**
   * Handles re-sending the email link to reset the password
   *
   * @param email The email address to which to send the link
   */
  handleResendEmail = async (email: string): Promise<void> => {
    try {
      this.setState({ emailResendStatus: Status.Loading });
      const result = await AuthApiInstance.SendResetPasswordEmail(
        email,
        this.source
      );
      this.setState({
        emailResendStatus: Status.Success,
        expiryTimeString: result.expiryTime,
      });

      sendEventGA(
        CategoryKeysGA.AccountForgotPassword,
        ActionKeysGA.ResendEmail
      );
    } catch (error) {
      this.setState({ emailResendStatus: Status.Error });
    }
  };

  /**
   * Handles formatting API errors for app
   *
   * @param error The API error object
   * @returns {ErrorStatus} Formatted error object
   */
  handleErrors = (error): ErrorStatus =>
    getErrorStatus(error, intl.get('ERR_FORGOT_PASSWORD_EMAIL_FAILURE'));

  /**
   * Renders the forgot password email field
   *
   * @returns {JSX.Element} JSX snippet containing the form component for forgot password
   */
  render(): JSX.Element {
    const { emailStatus, emailResendStatus, expiryTimeString } = this.state;
    return (
      <ModalBackground>
        <Modal size="lg" isOpen backdrop="static" centered>
          <Formik
            initialValues={this.initialValues}
            validationSchema={ForgotPasswordFormValidation.GetValidationSchema()}
            onSubmit={this.handleSendEmail}
          >
            {(
              formikProps: FormikProps<ForgotPasswordFormValues>
            ): JSX.Element => (
              <BlockUi tag="div" blocking={formikProps.isSubmitting}>
                {emailStatus === Status.Success ? (
                  <ForgotPasswordPending
                    email={formikProps.values.email}
                    status={emailResendStatus}
                    expiryTimeString={expiryTimeString}
                    onResendEmailClick={this.handleResendEmail}
                  />
                ) : (
                  <ForgotPasswordEmail formikProps={formikProps} />
                )}
              </BlockUi>
            )}
          </Formik>
        </Modal>
      </ModalBackground>
    );
  }
}

export default ForgotPasswordView;
