import { ReactNode, FC as ReactFC } from 'react';

import { Field } from 'formik';
import { isEmpty } from 'lodash';

import EnhancedFormikErrorProps from './EnhancedFormikErrorProps';

const EnhancedFormikError: ReactFC<EnhancedFormikErrorProps> = (
  props: EnhancedFormikErrorProps
) => {
  const { name, renderSecondaryError, classes, fullWidth, center } = props;

  return (
    <Field name={name}>
      {({ meta, field, form }): ReactNode =>
        meta.touched && meta.error ? (
          <label
            className={`form-error ${center ? 'text-center' : ''} ${
              fullWidth ? 'w-100' : ''
            } ${String(classes)}`}
          >
            {meta.error}
          </label>
        ) : (
          <label
            className={`form-error ${String(classes)}`}
            style={
              form.status && !isEmpty(form.status.fields)
                ? { display: 'inline-block' }
                : { display: 'none' }
            }
          >
            {renderSecondaryError(name, { meta, field, form })}
          </label>
        )
      }
    </Field>
  );
};

export default EnhancedFormikError;
