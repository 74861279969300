/* eslint-disable @typescript-eslint/no-explicit-any */
import * as intl from 'react-intl-universal';
import * as yup from 'yup';

import WidgetTargetType from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetType';
import WidgetType from 'modules/private/dashboard/components/widget-metrics/widgets/WidgetType';

class WidgetPreferenceValidation {
  public static GetValidationScheme(): any {
    const validationMessages = {
      positive: intl.get('ERR_WIDGETS_POSITIVE'),
      typeError: intl.get('ERR_WIDGETS_TYPE_ERROR'),
      required: intl.get('ERR_WIDGETS_REQUIRED'),
      targetMax: intl.get('ERR_WIDGETS_TARGET_MAX'),
      targetFromMax: intl.get('ERR_WIDGETS_TARGET_FROM_MAX'),
      targetFromMaxCommon: intl.get('ERR_WIDGETS_TARGET_FROM_MAX_COMMON'),
      targetToMax: intl.get('ERR_WIDGETS_TARGET_TO_MAX'),
      integer: intl.get('ERR_WIDGETS_INTEGER'),
    };

    const schema = yup.object().shape({
      target: yup.number().when(['targetType', 'type'], (targetType, type) => {
        let rules: any = yup.string().nullable().notRequired();

        if (targetType.value === WidgetTargetType.Value) {
          if (type.value === WidgetType.LoanFund) {
            const max = 100;
            rules = yup
              .number()
              .integer(validationMessages.integer)
              .max(max, validationMessages.targetMax)
              .positive(validationMessages.positive)
              .typeError(validationMessages.typeError)
              .required(validationMessages.required);
          } else {
            rules = yup
              .number()
              .integer(validationMessages.integer)
              .positive(validationMessages.positive)
              .typeError(validationMessages.typeError)
              .required(validationMessages.required);
          }
        }

        return rules;
      }),
      targetFrom: yup
        .number()
        .when(
          ['targetType', 'type', 'targetTo'],
          (targetType, type, targetTo) => {
            let rules: any = yup.string().nullable().notRequired();

            if (targetType.value === WidgetTargetType.Range) {
              if (type.value === WidgetType.LoanFund) {
                const max = targetTo;
                rules = yup
                  .number()
                  .integer(validationMessages.integer)
                  .max(max, validationMessages.targetFromMax)
                  .positive(validationMessages.positive)
                  .typeError(validationMessages.typeError)
                  .required(validationMessages.required);
              } else {
                const max = targetTo || Number.MAX_SAFE_INTEGER;

                rules = yup
                  .number()
                  .integer(validationMessages.integer)
                  .max(max, validationMessages.targetFromMaxCommon)
                  .positive(validationMessages.positive)
                  .typeError(validationMessages.typeError)
                  .required(validationMessages.required);
              }
            }

            return rules;
          }
        ),
      targetTo: yup
        .number()
        .when(['targetType', 'type'], (targetType, type) => {
          let rules: any = yup.string().nullable().notRequired();

          if (targetType.value === WidgetTargetType.Range) {
            if (type.value === WidgetType.LoanFund) {
              const max = 100;
              rules = yup
                .number()
                .integer(validationMessages.integer)
                .max(max, validationMessages.targetToMax)
                .positive(validationMessages.positive)
                .typeError(validationMessages.typeError)
                .required(validationMessages.required);
            } else {
              rules = yup
                .number()
                .integer(validationMessages.integer)
                .positive(validationMessages.positive)
                .typeError(validationMessages.typeError)
                .required(validationMessages.required);
            }
          }

          return rules;
        }),
    });

    return schema;
  }
}

export default WidgetPreferenceValidation;
