import { ReactNode, useRef, FC as ReactFC, useContext } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { RenderLayerProps, ToggleLayer } from 'react-laag';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import AppContext from 'context/AppContext';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import Status from 'shared/enums/Status';

import styles from '../usersProjectsPane.module.scss';
import ProjectsSelectProps from './ProjectsSelectProps';

const ProjectsSelect: ReactFC<ProjectsSelectProps> = ({
  selectProjectsToggleOpen,
  onToggle,
  onChange,
  onClose,
  selectedProjects,
  currentProjects,
}: ProjectsSelectProps) => {
  const { getProjectList, projectListData } = useContext(AppContext);

  const parentRef = useRef<HTMLDivElement>(null);

  const filteredProject = Object.values(projectListData.filtered).filter(
    (f) => !currentProjects.includes(f.projectId)
  );

  const renderLayer = ({ isOpen, layerProps }: RenderLayerProps): ReactNode =>
    isOpen && (
      <div
        ref={layerProps.ref}
        className="layer insight-dropdown"
        style={{
          ...layerProps.style,
          left: '0%',
          transform: 'translateX(-0%)',
          top: '2px',
        }}
      >
        <BlockUi blocking={projectListData.status === Status.Loading}>
          <PerfectScrollbar className={styles.scroll}>
            <Form>
              {filteredProject.length > 0 ? (
                filteredProject.map((project) => {
                  const isChecked = selectedProjects.includes(
                    project.projectId
                  );
                  return (
                    <FormGroup
                      key={project.projectId}
                      className={`insight-checkbox-group ${styles.wrap}`}
                    >
                      <span
                        data-for="insTooltip"
                        data-place="bottom"
                        data-class="error bring-it-up"
                      >
                        <Label
                          className={`${isChecked ? 'checked-label' : ''} `}
                        >
                          <span className={`insight-checkbox mr-3 `}>
                            <Input
                              type="checkbox"
                              tabIndex={0}
                              name={project.name}
                              value={project.projectId}
                              checked={isChecked}
                              onChange={onChange}
                            />
                            <i className="icon-check" />
                          </span>
                          <EllipsisTooltip
                            tag="span"
                            data-place="right"
                            data-for="insTooltip"
                            data-tip={project.name}
                            data-class="overflow-wrap"
                            className={`truncate ${styles.option} ${
                              isChecked ? styles.checked : ''
                            }`}
                          >
                            {project.name}
                          </EllipsisTooltip>
                        </Label>
                      </span>
                    </FormGroup>
                  );
                })
              ) : (
                <FormGroup className="insight-checkbox-group">
                  <Label
                    className="truncate"
                    style={{ maxWidth: '150px', textAlign: 'start' }}
                  >
                    {intl.get('LBL_NO_OPTIONS')}
                  </Label>
                </FormGroup>
              )}
            </Form>
          </PerfectScrollbar>
        </BlockUi>
      </div>
    );

  return (
    <ToggleLayer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container={parentRef.current!} // nosonar
      onOutsideClick={onClose}
      placement={{
        anchor: 'BOTTOM_CENTER',
        possibleAnchors: ['BOTTOM_CENTER'],
      }}
      isOpen={selectProjectsToggleOpen}
      renderLayer={renderLayer}
    >
      {({ triggerRef, isOpen }): ReactNode => (
        <>
          <input
            ref={triggerRef}
            minLength={2}
            onChange={(event): Promise<void> =>
              getProjectList(event.target.value)
            }
            className="form-control mb-3"
            placeholder={intl.get('LBL_SEARCH')}
            type="search"
            onClick={(): void => onToggle(isOpen)}
          />
          <div
            ref={parentRef}
            className="position-relative"
            id="project-parent"
          />
        </>
      )}
    </ToggleLayer>
  );
};

export default ProjectsSelect;
