import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import MessagingFilter from 'shared/enums/MessagingFilter';

import { lastMessagedDates } from '../../../../containers/messaging-view/MessagingConstants';
import GroupsFilter from '../groups-filter/GroupsFilter';
import LastMessagedFilterProps from './LastMessagedFilterProps';

const LastMessagedFilter: ReactFC<LastMessagedFilterProps> = (
  props: LastMessagedFilterProps
) => {
  const { isDisabled, selected, onChange } = props;

  const lastMessagedDatesTranslated = lastMessagedDates.map(
    ({ value, label }) => ({ value, label: intl.get(label) })
  );

  return (
    <GroupsFilter
      title={intl.get('LBL_MESSAGING_COMPOSE_LMD_FILTER_TITLE')}
      data={lastMessagedDatesTranslated}
      onChange={(e, label: string): void =>
        onChange(e, MessagingFilter.LastMessaged, label)
      }
      isDisabled={isDisabled}
      selected={selected}
    />
  );
};

export default LastMessagedFilter;
