import * as intl from 'react-intl-universal';

import WidgetTargetType from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetType';

type WidgetTargetConfig = {
  name: string;
};

class WidgetTargetConfigs {
  public static GetWidgetTargetConfig(
    type: WidgetTargetType
  ): WidgetTargetConfig {
    const widgetTargetConfigs: { [key: string]: WidgetTargetConfig } = {
      [WidgetTargetType.None]: {
        name: intl.get('LBL_WID_TARGET_NO_TARGET'),
      },
      [WidgetTargetType.Range]: {
        name: intl.get('LBL_WID_TARGET_RANGE'),
      },
      [WidgetTargetType.Value]: {
        name: intl.get('LBL_WID_TARGET_SPECIFIC'),
      },
    };

    return widgetTargetConfigs[type];
  }
}

export default WidgetTargetConfigs;
