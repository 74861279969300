import { useRef, useEffect } from 'react';

/**
 * checks and returns if first render emulating that of
 * component did mount effect in class components
 * @returns returns true if it is the first render
 */
const useFirstRender = (): boolean => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

export default useFirstRender;
