import { useContext, useEffect, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormGroup, Label } from 'reactstrap';

import AppContext from 'context/AppContext';
import Status from 'shared/enums/Status';

import CountriesFilter from './countries-filter/CountriesFilter';
import FacilitatorsFilter from './facilitators-filter/FacilitatorsFilter';
import GroupAgeFilter from './group-age-filter/GroupAgeFilter';
import GroupStatusFilter from './group-status-filter/GroupStatusFilter';
import styles from './groupsFilterPanel.module.scss';
import GroupsFilterPanelProps from './GroupsFilterPanelProps';
import LastMessagedFilter from './last-messaged-filter/LastMessagedFilter';
import ProjectsFilter from './projects-filter/ProjectsFilter';

const GroupsFilterPanel: ReactFC<GroupsFilterPanelProps> = (
  props: GroupsFilterPanelProps
) => {
  const {
    countriesData,
    projectsData,
    facilitatorsData,
    selectedFilters,
    onFiltersChanged,
    groupFiltersStatus,
    handleSetErrorToast,
    status,
  } = props;

  const { groupStatusData, getGroupStatuses } = useContext(AppContext);

  useEffect(() => {
    getGroupStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groupStatusData.status === Status.Error)
      handleSetErrorToast(
        intl.get('ERR_TOAST_MESSAGING_GROUP_STATUS_FILTER_ERRORS')
      );
  }, [groupStatusData.status, handleSetErrorToast]);

  const filtersLoading =
    countriesData.status === Status.Loading ||
    projectsData.status === Status.Loading ||
    facilitatorsData.status === Status.Loading ||
    groupStatusData.status === Status.Loading ||
    groupFiltersStatus === Status.Loading ||
    status === Status.Loading;

  return (
    <PerfectScrollbar className={styles.scrollContainer}>
      <BlockUi tag="div" blocking={filtersLoading}>
        <div className={`${styles.panel} content-box mb-0`}>
          <div>
            <FormGroup>
              <Label>
                {intl.get('LBL_MESSAGING_COMPOSE_GROUPS_FILTER_TITLE')}
              </Label>
            </FormGroup>
            <div>
              <CountriesFilter
                countriesData={countriesData}
                isDisabled={false}
                selected={selectedFilters.countries}
                onChange={onFiltersChanged}
              />
              <ProjectsFilter
                projectsData={projectsData}
                isDisabled={selectedFilters.countries.length <= 0}
                selected={selectedFilters.projects}
                onChange={onFiltersChanged}
              />
              <FacilitatorsFilter
                facilitatorsData={facilitatorsData}
                isDisabled={selectedFilters.projects.length <= 0}
                selected={selectedFilters.facilitators}
                onChange={onFiltersChanged}
              />
              <GroupStatusFilter
                groupStatusData={groupStatusData}
                isDisabled={selectedFilters.countries.length <= 0}
                selected={selectedFilters.groupStatus}
                onChange={onFiltersChanged}
              />
              <GroupAgeFilter
                isDisabled={selectedFilters.countries.length <= 0}
                selected={selectedFilters.groupAge}
                onChange={onFiltersChanged}
              />
              <LastMessagedFilter
                isDisabled={selectedFilters.countries.length <= 0}
                selected={selectedFilters.lastMessaged}
                onChange={onFiltersChanged}
              />
            </div>
          </div>
        </div>
      </BlockUi>
    </PerfectScrollbar>
  );
};
export default GroupsFilterPanel;
