import { FC as ReactFC } from 'react';

import GoogleMap from './GoogleMap';
import GroupMapProps from './GroupMapProps';

const GroupMap: ReactFC<GroupMapProps> = (props) => {
  const { data, loading } = props;

  return (
    <div className="row dashboard-row">
      <div className="col" style={{ width: '100%', height: '55vh' }}>
        <GoogleMap data={data} loading={loading} />
      </div>
    </div>
  );
};

export default GroupMap;
