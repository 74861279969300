/** this enum is used to manage app-wide UI and data states */
enum Status {
  Idle = 'IDLE',
  Success = 'SUCCESS',
  Error = 'ERROR',
  Loading = 'LOADING',
  Done = 'DONE',
}

export default Status;
