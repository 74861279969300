/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC as ReactFC } from 'react';

import BalanceSheet from 'shared/components/balance-sheet/BalanceSheet';

import FinancialBenefits from './financial-benefits/FinancialBenefits';
import KeyRatios from './key-ratios/KeyRatios';
import KeyMetricsProps from './KeyMetricsProps';

const KeyMetrics: ReactFC<KeyMetricsProps> = (props: KeyMetricsProps) => {
  const { data, loading, error } = props;
  return (
    <div className="row dashboard-row">
      <div className="col-md-6">
        <BalanceSheet data={data} loading={loading} error={error} />
      </div>
      <div className="col-md-6">
        <KeyRatios data={data} loading={loading} error={error} />
        <FinancialBenefits data={data} loading={loading} error={error} />
      </div>
    </div>
  );
};

export default KeyMetrics;
