const ProjectFacilitatorsSortableColumns: Set<string> = new Set([
  'name',
  'facilitatorNumber',
  'supervisor',
  'location',
  'assignedGroupCount',
]);

const TableConfig = {
  defaultSort: 'name',
  sortCols: ProjectFacilitatorsSortableColumns,
  defaultSortDesc: false,
};

export default TableConfig;
