import { FC as ReactFC } from 'react';

import { Field, FieldProps, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Label,
  ModalBody,
  Row,
  ModalHeader,
} from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import ModulePaths from 'constants/ModulePaths';
import { getLocalizedErrorString } from 'helpers/ErrorFormat';
import ForgotPasswordFormValues, {
  ForgotPasswordFormValueTypes,
} from 'modules/public/auth/containers/forgot-password-view/ForgotPasswordFormValues';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import ImageComponent from 'shared/components/image/ImageComponent';
import InsLink from 'shared/components/ins-link/InsLink';
import ForgotPasswordIllustration from 'shared/static/img/forgot-password-illustration.svg';
import forgotPasswordThumb from 'shared/static/img/thumbs/forgot_password_thumb.png';

import styles from './forgotPasswordEmail.module.scss';
import ForgotPasswordEmailProps, {
  ForgotPasswordEmailFields,
} from './ForgotPasswordEmailProps';

const ForgotPasswordEmail: ReactFC<ForgotPasswordEmailProps> = (
  props: ForgotPasswordEmailProps
) => {
  const maxLength = Constraints.MaxFieldLength;
  const { formikProps } = props;
  const { isSubmitting } = formikProps;

  /**
   * Renders appropriate API errors for each form field
   *
   * @param name Field name under which the error has to be displayed
   * @returns {string | null} The error message from the locale
   */
  const renderStatusError = (
    name: string,
    { form }: FieldProps<ForgotPasswordFormValueTypes, ForgotPasswordFormValues>
  ): string | null => {
    const { status } = form;
    if (status && status.msg && !isEmpty(status.fields)) {
      const fields = Object.keys(status.fields);
      if (fields.includes(name)) {
        const errorCode = status.fields[name];
        return intl.get(getLocalizedErrorString(errorCode));
      }
    }
    return null;
  };

  return (
    <>
      <ModalHeader className="increase-font pb-0 border-bottom-0">
        {intl.get('LBL_FORGOT_PASSWORD_TITLE')}
      </ModalHeader>
      <ModalBody>
        <Form noValidate>
          <Row>
            <Col xs="auto" className={styles.image}>
              <ImageComponent
                loading="eager"
                src={ForgotPasswordIllustration}
                alt="Forgot Password"
                thumb={forgotPasswordThumb}
              />
            </Col>
            <Col>
              <Row>
                <Col className={styles.textContainer}>
                  <span className="text-14-medium text-gray">
                    {intl.getHTML('LBL_FORGOT_PASSWORD_DESCRIPTION')}
                  </span>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor={ForgotPasswordEmailFields.EMAIL}>
                  {intl.get('LBL_FORGOT_PASSWORD_EMAIL')}
                </Label>
                <Field
                  autoComplete="email"
                  type="email"
                  name={ForgotPasswordEmailFields.EMAIL}
                  maxLength={maxLength}
                  tabIndex={0}
                  className="form-control"
                />
                <EnhancedFormikError
                  fullWidth
                  name={ForgotPasswordEmailFields.EMAIL}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <InsLink
                to={`${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`}
                onClick={(): void => formikProps.resetForm()}
                className="btn btn-secondary"
              >
                {intl.get('BTN_FORGOT_PASSWORD_BACK')}
              </InsLink>
            </Col>
            <Col className="text-right d-flex align-items-center justify-content-end">
              <FormText>
                {intl.get('LBL_STILL_NEED_HELP')}{' '}
                <a
                  href="https://support.dreamstartlabs.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {intl.get('LBL_CONTACT_US')}
                </a>
              </FormText>
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <Button
                type="submit"
                className="btn btn-primary"
                tabIndex={0}
                disabled={isSubmitting}
              >
                {intl.get('BTN_RESET_PASSWORD')}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </>
  );
};

export default ForgotPasswordEmail;
