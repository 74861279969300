import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import { formatInternational } from 'helpers/PhoneNumberFormat';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ImageComponent from 'shared/components/image/ImageComponent';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import ExistingFacilitatorsListItemProps from './ExistingFacilitatorsListItemProps';

const ExistingFacilitatorsListItem: ReactFC<ExistingFacilitatorsListItemProps> =
  (props: ExistingFacilitatorsListItemProps) => {
    const { facilitator, isNewlyAdded, removeNewFacilitatorFromList } = props;

    /**
     * Handle removing a facilitator from the list
     */
    const handleRemoveFacilitator = (): void =>
      removeNewFacilitatorFromList(facilitator.id);

    return (
      <tr>
        <td>
          {isNewlyAdded && (
            <button
              className="btn btn-close shadow-none p-0"
              type="button"
              onClick={handleRemoveFacilitator}
            >
              <i className="icon-close" />
            </button>
          )}
        </td>
        <td className="truncate-long-words">
          <div className="d-flex align-items-center">
            <ImageComponent
              className="rounded-circle object-fit-cover"
              ignoreBlur
              src={facilitator.image}
              alt={facilitator.name}
              fallbackSrc={blankAvatar}
              width={25}
              height={25}
            />
            <EllipsisTooltip
              tag="span"
              data-place="bottom"
              data-for="insTooltip"
              data-tip={facilitator.name}
              data-class="overflow-wrap bring-it-up"
              className="table-content extend-length text-12-bold ml-3"
            >
              {facilitator.name}
            </EllipsisTooltip>
          </div>
        </td>
        <td className="truncate-long-words">
          <EllipsisTooltip
            tag="span"
            data-place="bottom"
            data-for="insTooltip"
            data-tip={facilitator.supervisor?.name ?? intl.get('LBL_NONE')}
            data-class="overflow-wrap bring-it-up"
            className="table-content text-12-bold"
          >
            {facilitator.supervisor?.name ?? intl.get('LBL_NONE')}
          </EllipsisTooltip>
        </td>
        <td>
          <span className="text-12-bold">
            {formatInternational(facilitator.phoneNumber, true) ??
              intl.get('LBL_NA')}
          </span>
        </td>
      </tr>
    );
  };

export default ExistingFacilitatorsListItem;
