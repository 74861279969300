/* eslint-disable react/jsx-props-no-spreading */
import {
  forwardRef,
  useRef,
  useState,
  useEffect,
  MutableRefObject,
} from 'react';

import IndeterminateCheckboxProps from './IndeterminateCheckboxProps';

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: IndeterminateCheckboxProps, ref) => {
    const defaultRef = useRef();
    const resolvedRef: MutableRefObject<HTMLInputElement> = (ref ||
      defaultRef) as MutableRefObject<HTMLInputElement>;

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    useEffect(() => {
      setIsDisabled(resolvedRef.current && resolvedRef.current.disabled);
    }, [resolvedRef]);

    return (
      <>
        <label
          className={`insight-checkbox ${
            isDisabled ? 'disabled-checkbox' : ''
          }`}
        >
          <input type="checkbox" ref={resolvedRef} {...rest} />
          <i className="icon-check" />
        </label>
      </>
    );
  }
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';
export default IndeterminateCheckbox;
// export default React.forwardRef(IndeterminateCheckbox);
