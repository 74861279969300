/* eslint-disable */
import StorageKeys from 'constants/StorageKeys';
import { Currency } from 'context/AppProviderState';
import ExchangeRate from 'shared/enums/ExchangeRate';
import Status from 'shared/enums/Status';
import StorageService from './StorageService';

class CurrencyStorageService extends StorageService {

  private static GenericCurrencyValue: Currency = {
    name: '',
    currencyCode: '',
    symbol: '',
    exchangeRateMode: ExchangeRate.Current,
    status: Status.Idle,
  };

  /**
   * Sets the currency that the currently logged-in user has toggled
   *
   * @param currency Currency object
   */
  static SetSelectedCurrency = (currency: Currency): void => {
    this.SetItemToSession(StorageKeys.SelectedCurrency, JSON.stringify(currency));
  };

  /**
   * Fetches the currency that the currently logged-in user has toggled
   */
  static GetSelectedCurrency = (): Currency =>
    JSON.parse(this.GetItemFromSession(StorageKeys.SelectedCurrency) ?? JSON.stringify(this.GenericCurrencyValue));

  /**
   * Sets the default currency
   *
   * @param currency Currency object
   */
  static SetDefaultCurrency = (currency: Currency): void => {
    this.SetItemToSession(StorageKeys.DefaultCurrency, JSON.stringify(currency));
  };

  /**
   * Fetches the default currency
   */
  static GetDefaultCurrency = (): Currency => {
    return JSON.parse(this.GetItemFromSession(StorageKeys.DefaultCurrency) ?? JSON.stringify(this.GenericCurrencyValue));
  }
    
}

export default CurrencyStorageService;
