import { ReactNode, useEffect, useRef, useState, FC as ReactFC } from 'react';

import { Field, Form } from 'formik';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Label,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import { MessagingTab } from 'modules/private/messaging/containers/messaging-view/MessagingViewState';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import PopperResizeContent from 'shared/components/hoc/popper-resize-content/PopperResizeContent';
import Status from 'shared/enums/Status';

import styles from './messageForm.module.scss';
import MessageFormProps from './MessageFormProps';

const MessageForm: ReactFC<MessageFormProps> = (props: MessageFormProps) => {
  const {
    status,
    renderStatusError,
    showTranslationTooltip,
    error,
    values,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    isValid,
    tab,
  } = props;

  const formRef = useRef<HTMLDivElement>(null);

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  useEffect(() => {
    if (formRef && formRef.current) {
      setTooltipOpen(!tooltipOpen && showTranslationTooltip);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTranslationTooltip, formRef]);

  const isSendingButtonDisabled = !isValid || status === Status.Loading;

  /**
   * Renders the tooltip guide for messaging form
   *
   * @returns {ReactNode | null} The JSX snippet containing the tooltip
   */
  const renderTooltipGuide = (): ReactNode | null => (
    <Popover
      isOpen={tooltipOpen}
      placement="bottom-start"
      container={formRef}
      hideArrow
      // positionFixed
      modifiers={{
        preventOverflow: { enabled: false },
        flip: { enabled: false },
      }}
      popperClassName="messaging-popper"
      target={formRef}
      delay={{ show: 500, hide: 100 }}
    >
      {({ scheduleUpdate }): JSX.Element => (
        <PopperResizeContent observeElement={formRef} onResize={scheduleUpdate}>
          <PopoverBody>
            <div>
              <Row className="text-gray">
                <span className="text-14-medium">
                  {intl.getHTML('LBL_MESSAGING_COMPOSE_TOOLTIP_GUIDE')}
                </span>
              </Row>
              <Row className="justify-content-center mt-4">
                <Button
                  size="sm"
                  color="primary"
                  tabIndex={0}
                  onClick={(): void => setTooltipOpen(false)}
                >
                  {intl.get('BTN_MESSAGING_TOOLTIP_GUIDE_GOT_IT')}
                </Button>
              </Row>
            </div>
          </PopoverBody>
        </PopperResizeContent>
      )}
    </Popover>
  );

  return (
    <>
      <Form>
        <BlockUi tag="div" blocking={status === Status.Loading}>
          <div ref={formRef} className="content-box mb-0 pb-2">
            {!!error && (
              <Row>
                <Col>
                  <FormGroup>
                    <Alert
                      isOpen
                      tag="div"
                      className="alert alert-danger"
                      role="alert"
                    >
                      {error}
                    </Alert>
                  </FormGroup>
                </Col>
              </Row>
            )}

            <FormGroup>
              <Row form>
                <Col xs="12" className="mb-1">
                  <Label for="type">
                    {intl.get('LBL_MESSAGING_COMPOSE_TITLE')}
                  </Label>
                  <Field
                    autoComplete="off"
                    type="text"
                    name="title"
                    tabIndex={0}
                    maxLength={Constraints.MaxMessageTitleLength}
                    placeholder={intl.get(
                      'LBL_MESSAGING_COMPOSE_TITLE_PLACEHOLDER'
                    )}
                    className="form-control"
                  />
                </Col>
                <Col>
                  <EnhancedFormikError
                    name="title"
                    renderSecondaryError={renderStatusError}
                  />
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <div className="text-right">
                    <span
                      className={`text-10-medium font-italic ${styles.textBlackOpacity40}`}
                    >{`${values.title.length}/${
                      Constraints.MaxMessageTitleLength
                    } ${intl.get(
                      'LBL_MESSAGING_COMPOSE_CHARACTER_COUNT'
                    )}`}</span>
                  </div>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row form>
                <Col xs="12" className="mb-1">
                  <Label for="type">
                    {intl.get('LBL_MESSAGING_COMPOSE_BODY')}
                  </Label>
                  <Field
                    as="textarea"
                    autoComplete="off"
                    name="body"
                    tabIndex={0}
                    maxLength={Constraints.MaxMessageBodyLength}
                    placeholder={intl.get(
                      'LBL_MESSAGING_COMPOSE_BODY_PLACEHOLDER'
                    )}
                    className={`form-control ${styles.bodyHeight}`}
                  />
                </Col>
                <Col>
                  <EnhancedFormikError
                    name="body"
                    renderSecondaryError={renderStatusError}
                  />
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <div className="text-right">
                    <span
                      className={`text-10-medium font-italic ${styles.textBlackOpacity40}`}
                    >{`${values.body.length}/${
                      Constraints.MaxMessageBodyLength
                    } ${intl.get(
                      'LBL_MESSAGING_COMPOSE_CHARACTER_COUNT'
                    )}`}</span>
                  </div>
                </Col>
              </Row>
            </FormGroup>
            {tab === MessagingTab.Compose && renderTooltipGuide()}
          </div>
        </BlockUi>
        <Row className="justify-content-end mr-0 mt-3">
          <Button
            size="lg"
            color="primary"
            type="submit"
            disabled={isSendingButtonDisabled}
          >
            {intl.get('BTN_MESSAGING_SEND_MESSAGE')}
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default MessageForm;
