import { useEffect, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Input, Label, Button } from 'reactstrap';

import NewLineToBr from '../new-line-to-br/NewLineToBr';
import styles from './mobilePreview.module.scss';
import MobilePreviewProps from './MobilePreviewProps';

const MobilePreview: ReactFC<MobilePreviewProps> = (
  props: MobilePreviewProps
) => {
  const { title, body, orgName } = props;
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [previewBody, setPreviewBody] = useState<string>('');

  useEffect(() => {
    setPreviewTitle(
      title || intl.get('LBL_MESSAGING_COMPOSE_TITLE_PLACEHOLDER')
    );
    setPreviewBody(body || intl.get('LBL_MESSAGING_COMPOSE_BODY_PLACEHOLDER'));
  }, [title, body]);

  return (
    <div className={`${styles.previewContainer}`}>
      <div className={styles.header}>
        <div className={styles.newMessage}>
          {intl.get('LBL_MESSAGING_COMPOSE_MOBILE_PREVIEW_NEW_MESSAGE')}
        </div>
        <div className="text-11-light text-white">
          {intl.get('LBL_MESSAGING_COMPOSE_MOBILE_PREVIEW_ORG_PREFIX')}
        </div>
        <div className={`text-14-light text-mild-yellow ${styles.orgName}`}>
          {orgName}
        </div>
      </div>
      <div className={styles.warning}>
        <span className="text-13-light">
          {intl.get('LBL_MESSAGING_COMPOSE_MOBILE_PREVIEW_INSTRUCTION')}
        </span>
      </div>
      <div className={styles.body}>
        <div
          className={`text-14-semibold mb-2 ${styles.title}`}
          data-cy="title-preview"
        >
          {previewTitle}
        </div>
        <div
          className={`text-13-light ${styles.message}`}
          data-cy="body-preview"
        >
          <NewLineToBr>{previewBody}</NewLineToBr>
        </div>
        <hr className="divider mb-3" />
        <div>
          <Label className={`${styles.staticFormControl}`}>
            <span className="insight-checkbox mr-3 mt-1">
              <Input type="checkbox" tabIndex={-1} checked readOnly />
              <i className="icon-check" />
            </span>
            <span className="text-13-light">
              {intl.get('LBL_MESSAGING_COMPOSE_MOBILE_PREVIEW_CHECKBOX_LABEL')}
            </span>
          </Label>
        </div>
        <div>
          <Button
            className={`${styles.noPointerEvents} ${styles.button}`}
            size="lg"
            color="primary"
            tabIndex={-1}
          >
            {intl.get('BTN_OK')}
          </Button>
        </div>
        <div className="text-11-light">
          <b>{intl.get('LBL_MESSAGING_COMPOSE_MOBILE_PREVIEW_NOTE_PREFIX')}</b>
          {intl.get('LBL_MESSAGING_COMPOSE_MOBILE_PREVIEW_NOTE_CONTENT')}
        </div>
      </div>
    </div>
  );
};

export default MobilePreview;
