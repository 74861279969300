import { useEffect, useState, CSSProperties, FC as ReactFC } from 'react';

import { AxisProps } from '@nivo/axes';
import { Serie } from '@nivo/line';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { FormGroup, Label } from 'reactstrap';

import Charts from 'constants/Charts';
import { getColorFor } from 'helpers/ColorHelper';
import { getCompactNumber, getFormattedNumber } from 'helpers/NumberFormat';
import FinancialMetricsChart from 'shared/components/financial-metrics-chart/FinancialMetricsChart';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import CyclePhase from 'shared/enums/CyclePhase';
import FilterType from 'shared/enums/FilterType';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';
import TimeUnit from 'shared/enums/TimeUnit';
import TooltipOptionAnchor from 'shared/enums/TooltipOptionAnchor';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';

import ChartTypeSelect from '../../../../../shared/components/ins-form-fields/chart-type-select/ChartTypeSelect';
import FinancialMetricsChartTypeOption from '../../../../../shared/components/ins-form-fields/chart-type-select/FinancialMetricsChartTypeOption';
import FinancialMetricsChartContainerProps from './FinancialMetricsChartContainerProps';
import ChartData from './FinancialMetricsChartData';
import FinancialMetricsChartTypes from './FinancialMetricsChartTypes';

const FinancialMetricsChartContainer: ReactFC<FinancialMetricsChartContainerProps> =
  (props) => {
    const {
      data,
      filters,
      loading,
      onFilterChange,
      chartType,
      onChartTypeChange,
    } = props;

    const getCurrencyString = useCurrency();

    const defaultChartData: ChartData = Object.keys(
      FinancialMetricsChartTypes
    ).reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [FinancialMetricsChartTypes[currentValue]]: {
          data: Array<Serie>(),
          meta: {},
        },
      }),
      {} as ChartData
    );

    /**
     * Validate if chart type involves percentages
     *
     * @param selectedChartType Chart type
     * @returns {boolean} Whether the chart type involves percentages
     */
    const isPercentage = (
      selectedChartType: FinancialMetricsChartTypes
    ): boolean =>
      [
        FinancialMetricsChartTypes.LoanFundUtilization,
        FinancialMetricsChartTypes.LoanRepaymentRate,
        FinancialMetricsChartTypes.PercentageOfMembersWhoHaveTakenALoan,
        FinancialMetricsChartTypes.PortfolioAtRisk,
        FinancialMetricsChartTypes.ReturnOnSavings,
      ].includes(selectedChartType);

    /**
     * Validate if chart type involves counts
     *
     * @param selectedChartType Chart type
     * @returns {boolean} Whether the chart type involves counts
     */
    const isCount = (selectedChartType: FinancialMetricsChartTypes): boolean =>
      [FinancialMetricsChartTypes.AverageNumOfLoansGiven].includes(
        selectedChartType
      );

    const bottomAxisConfig: AxisProps = {
      format: '%Y, %b',
    };

    const leftAxisConfig: AxisProps = {
      format: (number): string => {
        if (isPercentage(chartType)) {
          return `${getCompactNumber(Number(number), true)}%`;
        }
        if (isCount(chartType)) {
          return getCompactNumber(Number(number), true);
        }
        return getCurrencyString(
          Number(number),
          true,
          IntlNumberNotation.Compact
        );
      },
    };

    /**
     * Formats tooltip values
     *
     * @param value Value to be formatted
     * @returns {string} Formatted tooltip value
     */
    const tooltipFormatter = (value: number): string => {
      if (isPercentage(chartType)) {
        return `${getFormattedNumber(value, true)}%`;
      }
      if (isCount(chartType)) {
        return getFormattedNumber(value, true);
      }
      return getCurrencyString(value, true);
    };

    const [timeUnits, setTimeUnits] = useState<SelectFieldOption[]>([]);
    const [chartTypes, setChartTypes] = useState<
      FinancialMetricsChartTypeOption[]
    >([]);
    const [cyclePhases, setCyclePhases] = useState<SelectFieldOption[]>([]);
    const [chartData, setChartData] = useState<ChartData>(defaultChartData);

    useEffect(() => {
      const chartTypeOptions = Object.values(FinancialMetricsChartTypes).map(
        (value) => {
          if (value === FinancialMetricsChartTypes.ReturnOnSavings) {
            return {
              value,
              label: intl.get(`LBL_FCT_${value}`),
              hint: intl.get(`LBL_FCT_${value}_HINT`),
              isDisabled: true,
            };
          }
          return {
            value,
            label: intl.get(`LBL_FCT_${value}`),
            hint: intl.get(`LBL_FCT_${value}_HINT`),
          };
        }
      );

      const cyclePhaseOptions = Object.values(CyclePhase).map((value) => ({
        value,
        label: intl.get(`LBL_CP_${value}`),
      }));

      const timeUnitOptions = Object.values(TimeUnit).map((value) => ({
        value,
        label: intl.get(`BTN_${value}`),
      }));

      setChartTypes(chartTypeOptions);
      setCyclePhases(cyclePhaseOptions);
      setTimeUnits(timeUnitOptions);
    }, []);

    useEffect(() => {
      const classifiedChartData: ChartData = defaultChartData;
      data.forEach((d) => {
        const dataPoints = d.dataPoints.map((dp) => ({
          x: dp.key,
          y: dp.value || 0,
        }));
        const comparisonPoints = d.comparisonDataPoints.map((cp) => ({
          x: cp.key,
          y: cp.value || 0,
          comparisonKey: cp.comparisonKey,
        }));

        const dataPointsSerie: Serie = {
          id: d.type,
          data: dataPoints,
          color: getColorFor(d.type),
        };

        const comparisonPointsSerie: Serie = {
          id: `${d.type}_COMP`,
          data: comparisonPoints,
          color: getColorFor(`${d.type}_COMP`),
        };

        classifiedChartData[d.type] = {
          data: [comparisonPointsSerie, dataPointsSerie],
          meta: d.meta,
        };
      });
      setChartData(classifiedChartData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
      ReactTooltip.rebuild();
    }, []);

    const chartTypeValue = chartTypes.find(
      (ct) => ct.value === chartType
    ) as SelectFieldOption;

    const chartDataObject = chartData[chartType];
    return (
      <div className="contant-container">
        <BlockUi tag="div" blocking={loading} className="row dashboard-row">
          <div className="col">
            <div className="content-box">
              <div className="content-box-title has-dropdowns pb-4 pt-2">
                <div className="inline-form">
                  <FormGroup className="insight-select-group">
                    <Label htmlFor="chartType" className="chart-filter-label">
                      {intl.get('LBL_CHART_FILTER_CHART_TYPE')}
                    </Label>
                    <ChartTypeSelect
                      name="chartType"
                      value={chartTypeValue}
                      options={chartTypes}
                      onChange={(
                        option: FinancialMetricsChartTypeOption
                      ): void => onChartTypeChange(option.value)}
                      styles={{
                        control: (styles): CSSProperties => ({
                          ...styles,
                          minWidth: '280px',
                        }),
                      }}
                      tooltipAnchor={TooltipOptionAnchor.RightCenter}
                      classNamePrefix="insight-select-no-border"
                    />
                  </FormGroup>
                </div>
                <div className="inline-form">
                  <FormGroup className="insight-select-group">
                    <Label htmlFor="cyclePhase" className="chart-filter-label">
                      {intl.get('LBL_CHART_FILTER_CYCLE_PHASE')}
                    </Label>
                    <Select
                      name="cyclePhase"
                      value={filters.cyclePhase}
                      options={cyclePhases}
                      onChange={(value): void =>
                        onFilterChange(
                          value as SelectFieldOption,
                          FilterType.CyclePhase
                        )
                      }
                      classNamePrefix="insight-select-no-border"
                    />
                  </FormGroup>
                  <FormGroup className="insight-select-group">
                    <Label
                      htmlFor="dateComparison"
                      className="chart-filter-label"
                    >
                      {intl.get('LBL_CHART_FILTER_DATE_COMPARISON')}
                    </Label>
                    <Select
                      name="dateComparison"
                      value={filters.timeUnit}
                      options={timeUnits}
                      onChange={(value): void =>
                        onFilterChange(
                          value as SelectFieldOption,
                          FilterType.TimeUnit
                        )
                      }
                      classNamePrefix="insight-select-no-border"
                    />
                  </FormGroup>
                  <FormGroup className="insight-select-group">
                    <Label htmlFor="compare" className="chart-filter-label">
                      {intl.get('LBL_CHART_FILTER_COMPARE')}
                    </Label>
                    <Select
                      name="compare"
                      value={{ value: 'Tanzania', label: 'Compare: Tanzania' }}
                      options={[
                        { value: 'Tanzania', label: 'Compare: Tanzania' },
                      ]}
                      isDisabled
                      styles={{
                        control: (styles, { isDisabled }): CSSProperties => {
                          if (isDisabled) {
                            return {
                              ...styles,
                              opacity: '0.4',
                              cursor: 'not-allowed',
                              pointerEvents: 'none',
                              backgroundColor: 'white',
                            };
                          }
                          return styles;
                        },
                      }}
                      classNamePrefix="insight-select-no-border"
                    />
                  </FormGroup>
                </div>
              </div>
              <FinancialMetricsChart
                loading={loading}
                chartTypeLabel={chartTypeValue?.label ?? ''}
                data={chartDataObject.data}
                meta={chartDataObject.meta}
                xAxisConfig={bottomAxisConfig}
                yAxisConfig={leftAxisConfig}
                tooltipFormatter={tooltipFormatter}
                filters={filters}
                upperTickLimit={Charts.DefaultChartTicksUpperLimit}
                lowerTickLimit={Charts.DefaultChartTicksLowerLimit}
              />
            </div>
          </div>
        </BlockUi>
      </div>
    );
  };

export default FinancialMetricsChartContainer;
