import FinancialMetricType from 'modules/private/dashboard/components/financial-metrics/FinancialMetricType';
import MembershipMetricType from 'modules/private/dashboard/components/membership-metrics/MembershipMetricType';
import WidgetValueGroup from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetValueGroup';
import FinancialMetricsChartTypes from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartTypes';
import GroupWidgetValueGroup from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetValueGroup';
import BackupStatus from 'shared/enums/BackupStatus';
import styles from 'shared/static/styles/exports.module.scss';

const insightsColors: { [key: string]: string } = {
  insightPrimary: styles.primary,
  insightBlue: styles.blue,
  insightSecondary: styles.secondary,
  insightSecondaryDark: styles.secondaryDark,
  insightLight: styles.light,
  insightDark: styles.dark,
  insightOrange: styles.orange,
  insightYellow: styles.yellow,
  insightMildYellow: styles.mildYellow,
  insightLightYellow: styles.lightYellow,
  insightBgYellow: styles.bgYellow,
  insightGray: styles.gray,
  insightLightGray: styles.lightGray,
  insightLavenderMagenta: styles.lavenderMagenta,
  insightCornflower: styles.cornflower,
  insightSpringWood: styles.springWood,
  insightJaffa: styles.jaffa,
  insightError: styles.error,
};

const colorMappings: { [key: string]: string } = {
  [WidgetValueGroup.Male]: insightsColors.insightSecondary,
  [WidgetValueGroup.Female]: insightsColors.insightMildYellow,
  [WidgetValueGroup.PrimarySchool]: insightsColors.insightMildYellow,
  [WidgetValueGroup.NoFormalEducation]: insightsColors.insightSecondary,
  [WidgetValueGroup.SecondarySchool]: insightsColors.insightOrange,
  [WidgetValueGroup.College]: insightsColors.insightLightGray,
  [WidgetValueGroup.GreatProgress]: insightsColors.insightSecondary,
  [WidgetValueGroup.GoodProgress]: insightsColors.insightMildYellow,
  [WidgetValueGroup.ALittleBehind]: insightsColors.insightOrange,
  [WidgetValueGroup.Business]: insightsColors.insightMildYellow,
  [WidgetValueGroup.Education]: insightsColors.insightSecondary,
  [WidgetValueGroup.Household]: insightsColors.insightOrange,
  [WidgetValueGroup.Medical]: insightsColors.insightLavenderMagenta,
  [WidgetValueGroup.Land]: insightsColors.insightLavenderMagenta,
  [WidgetValueGroup.Livestock]: insightsColors.insightLightGray,
  [WidgetValueGroup.Vehicle]: insightsColors.insightGray,
  [WidgetValueGroup.Technology]: insightsColors.insightYellow,
  [WidgetValueGroup.Travel]: insightsColors.insightCornflower,
  [WidgetValueGroup.Other]: insightsColors.insightCornflower,

  [WidgetValueGroup.OneDependent]: insightsColors.insightMildYellow,
  [WidgetValueGroup.TwoDependents]: insightsColors.insightSecondary,
  [WidgetValueGroup.ThreeDependents]: insightsColors.insightOrange,
  [WidgetValueGroup.FourDependents]: insightsColors.insightLavenderMagenta,
  [WidgetValueGroup.FiveOrMoreDependents]: insightsColors.insightCornflower,

  [WidgetValueGroup.InsuranceFund]: insightsColors.insightMildYellow,
  [WidgetValueGroup.EducationFund]: insightsColors.insightSecondary,
  [WidgetValueGroup.GroupBusinessFund]: insightsColors.insightOrange,
  [WidgetValueGroup.CelebrationFund]: insightsColors.insightLavenderMagenta,
  [WidgetValueGroup.CustomFund]: insightsColors.insightCornflower,
  [WidgetValueGroup.TechnologyFund]: insightsColors.insightGray,
  [GroupWidgetValueGroup.CustomFund1]: insightsColors.insightLightGray,
  [GroupWidgetValueGroup.CustomFund2]: insightsColors.insightBlue,
  [GroupWidgetValueGroup.CustomFund3]: insightsColors.insightJaffa,

  [FinancialMetricType.CumulativeSavings]: insightsColors.insightSecondary,
  [BackupStatus.None]: insightsColors.insightLightGray,
  [BackupStatus.Never]: '#5AB4E7',
  [BackupStatus.AllMeetings]: insightsColors.insightSecondary,
  [BackupStatus.OneMeetingNotBackedUp]: insightsColors.insightYellow,
  [BackupStatus.TwoOrMoreMeetingsNotBackedUp]: insightsColors.insightOrange,
  [`${FinancialMetricType.CumulativeSavings}_COMP`]:
    insightsColors.insightYellow,
  [FinancialMetricType.AverageSavings]: insightsColors.insightSecondary,
  [`${FinancialMetricType.AverageSavings}_COMP`]: insightsColors.insightYellow,
  [FinancialMetricType.LoanFundUtilization]: insightsColors.insightSecondary,
  [`${FinancialMetricType.LoanFundUtilization}_COMP`]:
    insightsColors.insightYellow,
  [MembershipMetricType.Groups]: insightsColors.insightSecondary,
  [`${MembershipMetricType.Groups}_COMP`]: insightsColors.insightYellow,
  [MembershipMetricType.Members]: insightsColors.insightSecondary,
  [`${MembershipMetricType.Members}_COMP`]: insightsColors.insightYellow,
  [MembershipMetricType.Attendance]: insightsColors.insightSecondary,
  [`${MembershipMetricType.Attendance}_COMP`]: insightsColors.insightYellow,
  [MembershipMetricType.AttendanceWithRep]: insightsColors.insightCornflower,
  [`${MembershipMetricType.AttendanceWithRep}_COMP`]:
    insightsColors.insightLavenderMagenta,
  [MembershipMetricType.Dropout]: insightsColors.insightSecondary,
  [`${MembershipMetricType.Dropout}_COMP`]: insightsColors.insightYellow,
  [MembershipMetricType.Inactive]: insightsColors.insightCornflower,
  [`${MembershipMetricType.Inactive}_COMP`]:
    insightsColors.insightLavenderMagenta,

  [FinancialMetricsChartTypes.LoanFundBalance]: insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.LoanFundBalance}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.LoanFundOutstanding]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.LoanFundOutstanding}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.CumulativeSavings]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.CumulativeSavings}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.AverageSavingsPerMember]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.AverageSavingsPerMember}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.LoanFundUtilization]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.LoanFundUtilization}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.ReturnOnSavings]: insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.ReturnOnSavings}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.AverageNumOfLoansGiven]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.AverageNumOfLoansGiven}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.PortfolioAtRisk]: insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.PortfolioAtRisk}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.PercentageOfMembersWhoHaveTakenALoan]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.PercentageOfMembersWhoHaveTakenALoan}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.LoanRepaymentRate]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.LoanRepaymentRate}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.SocialFundBalance]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.SocialFundBalance}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.EstimatedGroupShareOut]:
    insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.EstimatedGroupShareOut}_COMP`]:
    insightsColors.insightYellow,

  [FinancialMetricsChartTypes.NetProfit]: insightsColors.insightSecondary,
  [`${FinancialMetricsChartTypes.NetProfit}_COMP`]:
    insightsColors.insightYellow,

  LTB: '',
  LTE: '',
  LTH: '',
  LTM: '',
  LTO: '',
};

const randomColorMappings = [
  insightsColors.insightBlue,
  insightsColors.insightSecondary,
  insightsColors.insightOrange,
  insightsColors.insightGray,
  insightsColors.insightLavenderMagenta,
  insightsColors.insightPrimary,
  insightsColors.insightYellow,
  insightsColors.insightCornflower,
  insightsColors.insightSecondaryDark,
  insightsColors.insightJaffa,
  insightsColors.insightDark,
  insightsColors.insightError,
];

/**
 * Get color styles for component type
 *
 * @param type Component type
 * @returns {string} Hex string
 */
const getColorFor = (type: string): string => {
  const color = colorMappings[type];
  return color || insightsColors.insightPrimary;
};

/**
 * Get random color styles for component type
 *
 * @param type Component type
 * @returns {string} Hex string
 */
const getRandomColorFor = (index: number): string => {
  const color = randomColorMappings[index];
  return color || insightsColors.insightPrimary;
};

export { getColorFor, insightsColors, getRandomColorFor };
