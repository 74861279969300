const SurveysHistorySortableColumns: Set<string> = new Set([
  'name',
  'sendDate',
  'creator',
  'surveyType',
  'status',
  'receiverStats.targetedCount',
  'receiverStats.receivedCount',
]);

const SurveysHistoryTableConfigs = {
  defaultSort: 'sendDate',
  sortCols: SurveysHistorySortableColumns,
  defaultSortDesc: true,
};

export default SurveysHistoryTableConfigs;
