import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import Frequency from 'shared/enums/Frequency';
import TimeUnit from 'shared/enums/TimeUnit';

class MembershipMetricsFilter {
  timeUnit: SelectFieldOption;

  frequency: SelectFieldOption;

  constructor() {
    this.frequency = {
      value: Frequency.Monthly,
      label: 'Monthly',
      isDisabled: false,
    };
    this.timeUnit = {
      value: TimeUnit.PreviousYear,
      label: 'Previous Year',
      isDisabled: false,
    };
  }
}

export default MembershipMetricsFilter;
