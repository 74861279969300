/* eslint-disable class-methods-use-this */
import ValidatedCurrency from 'api/auth/types/currency/ValidatedCurrency';
import { Currency } from 'context/AppProviderState';
import CurrencyStorageService from 'services/storage-services/CurrencyStorageService';
import Status from 'shared/enums/Status';

class CurrencySelectionService {
  /**
   * Handles currency selection based on the circumstances
   *
   * @param fetchedCurrency Received currency value from the API
   * @param isGroupDetails Inside group details page
   * @param isInitial Initial login state
   */
  static select(
    fetchedCurrency: ValidatedCurrency,
    isGroupDetails: boolean,
    isInitial: boolean
  ): Currency | undefined {
    let chooseCurrency: Currency | undefined;

    const selectedCurrencyPersisted =
      CurrencyStorageService.GetSelectedCurrency();
    const defaultCurrencyPersisted =
      CurrencyStorageService.GetDefaultCurrency();

    if (
      defaultCurrencyPersisted.currencyCode === fetchedCurrency.currencyCode &&
      selectedCurrencyPersisted.currencyCode !==
        defaultCurrencyPersisted.currencyCode
    ) {
      /**
       * In an event where the local currency is selected and the validation
       * fetches the default currency (viewing data for groups from multiple
       * countries), the default currency has to be toggled on automatically.
       */

      chooseCurrency = defaultCurrencyPersisted;
    } else if (
      selectedCurrencyPersisted.currencyCode ===
        defaultCurrencyPersisted.currencyCode &&
      !isGroupDetails &&
      isInitial
    ) {
      const newCurrency = {
        status: Status.Success,
        currencyCode: fetchedCurrency.currencyCode,
        exchangeRateMode: fetchedCurrency.exchangeRateMode,
        name: fetchedCurrency.name,
        symbol: fetchedCurrency.symbol,
      };
      chooseCurrency = newCurrency;
    } else if (
      fetchedCurrency.currencyCode !== defaultCurrencyPersisted.currencyCode &&
      fetchedCurrency.currencyCode !== selectedCurrencyPersisted.currencyCode &&
      selectedCurrencyPersisted.currencyCode !==
        defaultCurrencyPersisted.currencyCode
    ) {
      /**
       * When local toggle is enabled when validate is fired and a new
       * local currency is fetched, persist the toggle state and
       * update the selected currency.
       *
       * */
      const newSelectedCurrency: Currency = {
        ...fetchedCurrency,
        status: Status.Success,
      };
      chooseCurrency = newSelectedCurrency;
      CurrencyStorageService.SetSelectedCurrency(newSelectedCurrency);
    }

    return chooseCurrency;
  }

  /**
   * Handles currency toggle by the user
   *
   * @param fetchedCurrency Received currency value from the API
   * @param selectedCurrency Currently selected currency
   * @param defaultCurrency Default currency value
   */
  static toggle(
    fetchedCurrency: Currency,
    selectedCurrency: Currency,
    defaultCurrency: Currency
  ): Currency {
    let selected = selectedCurrency;

    if (selectedCurrency.currencyCode === defaultCurrency.currencyCode) {
      selected = fetchedCurrency;
    } else {
      selected = defaultCurrency;
    }

    return selected;
  }
}

export default CurrencySelectionService;
