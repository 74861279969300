/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import PhoneNumberCountrySelect from './phone-number-country-select/PhoneNumberCountrySelect';
import styles from './phoneNumberField.module.scss';
import PhoneNumberFieldProps from './PhoneNumberFieldProps';

const PhoneNumberField: ReactFC<PhoneNumberFieldProps> = (
  props: PhoneNumberFieldProps
) => {
  const { form, field } = props;

  const handleChange = (inputValue: string): void => {
    form.setFieldValue(field.name, inputValue, true);
  };

  const handleBlur = (event): void => {
    form.setFieldTouched(field.name);
    field.onBlur(event);
  };

  return (
    <PhoneInput
      {...field}
      onBlur={handleBlur}
      onChange={handleChange}
      className={styles.phoneInputCustom}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      countrySelectComponent={PhoneNumberCountrySelect}
    />
  );
};

export default PhoneNumberField;
