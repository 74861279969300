import ProjectUsersViewModel from 'modules/private/projects/containers/projects-view/ProjectUsersViewModel';
import GroupNotification from 'shared/enums/GroupNotification';

type Notifications = {
  [GroupNotification.UnassignedToFacilitator]: number;
  [GroupNotification.UnassignedGroupStatus]: number;
};
class ProjectListItemViewModel {
  startDate: string | null = null;

  endDate: string | null = null;

  isTestProject = false;

  name = '';

  code = '';

  users: Array<ProjectUsersViewModel> = [];

  facilitators: Array<ProjectUsersViewModel> = [];

  projectId = '';

  usersCount = 0;

  facilitatorsCount = 0;

  groupCount = 0;

  countries: string[] = [];

  createdBy = '';

  createdById = '';

  notifications: Notifications = {
    [GroupNotification.UnassignedToFacilitator]: 0,
    [GroupNotification.UnassignedGroupStatus]: 0,
  };
}

export default ProjectListItemViewModel;
