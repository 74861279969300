import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import CommonErrorProps from './CommonErrorProps';

const CommonError: ReactFC<CommonErrorProps> = (props: CommonErrorProps) => {
  const { error } = props;
  return (
    <>
      {error && (
        <div className="no-data-message" style={{ zIndex: 1 }}>
          <div className="title">{intl.get('ERR_KM_COMMON_TITLE')}</div>
          <div>{intl.get('ERR_KM_COMMON_MESSAGE')}</div>
        </div>
      )}
    </>
  );
};

export default CommonError;
