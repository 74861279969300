import { RouteChildrenProps } from 'react-router-dom';

import ObjectResponse from 'api/common/responses/ObjectResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ProjectCreated from 'api/projects/types/ProjectCreated';
import ProjectDetail from 'api/projects/types/ProjectDetail';
import AppContextProps from 'context/AppContextProps';
import { ErrorStatus } from 'helpers/ErrorFormat';
import ProjectInfoFormValues from 'modules/private/projects/components/project-info/ProjectInfoFormValues';
import { DetailedError } from 'modules/private/projects/containers/projects-view/ProjectsViewState';
import ProjectViewMode from 'shared/enums/ProjectViewMode';
import Status from 'shared/enums/Status';

export enum DatePickerType {
  START_DATE = 'start-date',
  END_DATE = 'end-date',
}

export enum ProjectInfoFields {
  PROJECT_CODE = 'code',
  PROJECT_NAME = 'name',
  PROJECT_START_DATE = 'startDate',
  PROJECT_END_DATE = 'endDate',
  PROJECT_TYPE = 'isTestProject',
}

export type ProjectErrorStatus = ErrorStatus & {
  prevName: string;
};

interface ProjectInfoProps extends RouteChildrenProps {
  type: ProjectViewMode | undefined;
  data: ProjectInfoFormValues;
  newProjectCode: string;
  createEditStatus: Status;
  idError: DetailedError;
  loadError: Error | null;
  showModal: boolean;
  toggleModal: () => void;
  handleCreateProject: (
    details: Partial<ProjectDetail>
  ) => Promise<ObjectResponse<ProjectCreated>>;
  handleEditProject: (
    details: Partial<ProjectDetail>
  ) => Promise<SuccessResponse>;
  handleDeleteProject: (
    projectCode: string,
    projectId?: string
  ) => Promise<void>;
  onCreateSuccess: (silent?: boolean, resetScroll?: boolean) => Promise<void>;
  onEdit: (silent?: boolean, resetScroll?: boolean) => Promise<void>;
  appContext: AppContextProps;
}

export default ProjectInfoProps;
