import { FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Col, ModalBody, ModalHeader, Row } from 'reactstrap';

import styles from './verifyAccountStatusHandler.module.scss';
import VerifyAccountStatusHandlerProps from './VerifyAccountStatusHandlerProps';

const VerifyAccountStatusHandler: ReactFC<VerifyAccountStatusHandlerProps> = (
  props: VerifyAccountStatusHandlerProps
) => {
  const { loading, children } = props;

  return (
    <>
      {loading ? (
        <>
          <ModalHeader className="increase-font text-center">
            {intl.get('LBL_CA_VERIFYING_ACCOUNT')}
          </ModalHeader>
          <ModalBody>
            <BlockUi tag="div" blocking={loading}>
              <Row>
                <Col xs="12" className="text-center mt-2 pt-3">
                  <span className={styles.circleLoading}>
                    <i className="icon-refresh" />
                  </span>
                </Col>
              </Row>
            </BlockUi>
            <Row>
              <Col xs="12" className="text-center mt-2 pt-3">
                <small>
                  {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
                  <a
                    href="https://support.dreamstartlabs.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {intl.get('LBL_CA_CONTACT_SUPPORT')}
                  </a>
                </small>
              </Col>
            </Row>
          </ModalBody>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default VerifyAccountStatusHandler;
