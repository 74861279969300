import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Button } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import PermissionUtil from 'helpers/PermissionUtil';
import InsLink from 'shared/components/ins-link/InsLink';

import InviteUsersToolbarProps from './InviteUsersToolbarProps';

const InviteUsersToolbar: ReactFC<InviteUsersToolbarProps> = (
  props: InviteUsersToolbarProps
) => {
  const { context, userDropdown } = props;
  const { permissionsData, setInviteUsersSubmitting, inviteUsersSubmitting } =
    context;
  const { claims } = permissionsData;
  const canInviteUsers = PermissionUtil.Can(
    claims,
    ResourceKeys.SettingsUsersInviteUsers
  );

  return (
    <header className="main-header">
      <div className="header-col">
        <h2 className="main-title" style={{ display: 'flex' }}>
          {intl.get('LBL_TITLE_INVITE_USERS_TO_INSIGHTS')}
        </h2>
      </div>
      <div className="header-col ml-auto">
        <InsLink
          replace
          to={`${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`}
          className="btn header-btn btn-secondary"
        >
          {intl.get('BTN_CANCEL')}
        </InsLink>
        &nbsp;&nbsp;
        {canInviteUsers && (
          <Button
            disabled={inviteUsersSubmitting}
            onClick={(): void => setInviteUsersSubmitting(true)}
            className="btn header-btn btn-primary"
          >
            {intl.get('BTN_SETTINGS_USERS_INVITE_SEND')}
          </Button>
        )}
        &nbsp;&nbsp;
        {userDropdown}
      </div>
    </header>
  );
};

export default InviteUsersToolbar;
