import { JobRole } from 'api/projects/types/ProjectUser';

export type Project = {
  projectId: string;
  code: string;
  name: string;
};

class UserProfileSupervisee {
  id: string;

  name: string;

  email: string;

  imageURL: string | null;

  phoneNumber: string;

  jobRole: JobRole;

  projects: Project[];

  constructor() {
    this.id = '';
    this.name = '';
    this.email = '';
    this.imageURL = null;
    this.phoneNumber = '';
    this.jobRole = {
      id: '',
      role: '',
    };
    this.projects = [];
  }
}

export default UserProfileSupervisee;
