/* eslint-disable no-nested-ternary */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Button, Col, ModalBody, ModalHeader, Row } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';

import styles from './createProfileVerification.module.scss';
import CreateProfileVerificationProps, {
  StatusError,
} from './CreateProfileVerificationProps';

const CreateProfileVerification: ReactFC<CreateProfileVerificationProps> = (
  props: CreateProfileVerificationProps
) => {
  const {
    errorCode: errorType,
    resendStatus,
    expiryTimeString,
    onResendEmail,
  } = props;

  return (
    <>
      {errorType && errorType === StatusError.ExpiredCode ? (
        <>
          <ModalHeader className="increase-font text-center">
            {intl.get('LBL_CA_VERIFY_ACCOUNT_TITLE')}
          </ModalHeader>
          <ModalBody>
            <>
              <Row>
                <Col xs="12" className="d-flex justify-content-center mb-3 p-3">
                  <span className={styles.circle} />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <p className="form-title text-center text-gray">
                    {intl.get(
                      `ERR_API_CODE_CREATE_PROFILE_${StatusError.ExpiredCode}`,
                      {
                        expiry: expiryTimeString,
                      }
                    )}
                  </p>
                </Col>
              </Row>
            </>
            <Row className="justify-content-center">
              <Col xs="auto" className="mb-3 pb-3">
                <Button
                  color="primary"
                  onClick={onResendEmail}
                  disabled={resendStatus === Status.Loading}
                >
                  {intl.get('BTN_CA_RESEND_EMAIL')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="text-center mt-3 pt-3">
                <small>
                  {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
                  <a
                    href="https://support.dreamstartlabs.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {intl.get('LBL_CA_CONTACT_SUPPORT')}
                  </a>
                </small>
              </Col>
            </Row>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader className="increase-font text-center">
            {errorType === StatusError.AlreadyCreated
              ? intl.get(
                  `ERR_API_CODE_CREATE_PROFILE_${StatusError.AlreadyCreated}`
                )
              : intl.get('LBL_CA_VERIFY_ACCOUNT_TITLE')}
          </ModalHeader>
          <ModalBody>
            {errorType === StatusError.AlreadyCreated ? (
              <Row className="justify-content-center mt-3 mb-3">
                <Col xs="auto">
                  <InsLink
                    disabledClass="disabled"
                    to={ModulePaths.DashboardPath}
                    className="btn btn-primary"
                  >
                    {intl.get('BTN_CA_CONTINUE')}
                  </InsLink>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col
                    xs="12"
                    className="d-flex justify-content-center mb-3 p-3"
                  >
                    <span className={styles.circle} />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <p className="form-title text-center text-gray">
                      {intl.get(
                        `ERR_API_CODE_CREATE_PROFILE_${Number(errorType)}`
                      )}
                    </p>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs="12" className="text-center mt-3 pt-3">
                <small>
                  {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
                  <a
                    href="https://support.dreamstartlabs.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {intl.get('LBL_CA_CONTACT_SUPPORT')}
                  </a>
                </small>
              </Col>
            </Row>
          </ModalBody>
        </>
      )}
    </>
  );
};

export default CreateProfileVerification;
