import { FC as ReactFC } from 'react';

import intl from 'react-intl-universal';

import ChartErrorProps from './ChartErrorProps';

const ChartError: ReactFC<ChartErrorProps> = ({
  error,
  styles,
}: ChartErrorProps) => (
  <>
    {!!error && (
      <div className="no-data-message" style={styles}>
        <div className="title">
          {intl.get('ERR_CHART_EMPTY_TITLE')}
          {/* {error.message || 'The data is unavailable'} */}
        </div>
        <div>{intl.get('ERR_CHART_EMPTY_MESSAGE')}</div>
      </div>
    )}
  </>
);
export default ChartError;
