import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import MessagingFilter from 'shared/enums/MessagingFilter';

import GroupsFilter from '../groups-filter/GroupsFilter';
import FacilitatorsFilterProps from './FacilitatorsFilterProps';

const FacilitatorsFilter: ReactFC<FacilitatorsFilterProps> = (
  props: FacilitatorsFilterProps
) => {
  const { facilitatorsData, isDisabled, selected, onChange } = props;

  return (
    <GroupsFilter
      title={intl.get('LBL_MESSAGING_COMPOSE_FACILITATORS_FILTER_TITLE')}
      data={facilitatorsData.data}
      onChange={(e, label: string): void =>
        onChange(e, MessagingFilter.Facilitators, label)
      }
      isDisabled={isDisabled}
      selected={selected}
    />
  );
};

export default FacilitatorsFilter;
