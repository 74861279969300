import { FC as ReactFC } from 'react';

import orderBy from 'lodash/orderBy';

import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import HorizontalBar from 'shared/components/ins-charts/horizontal-bar/HorizontalBar';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';

const PersonalGoalTypeWidget: ReactFC<{ data: WidgetViewModel }> = (props) => {
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const values = data.values || [];

  const horizontalBarValues: InsChartValue[] = [];

  orderBy(values, 'percentage', 'desc').forEach((value) => {
    if (value) {
      horizontalBarValues.push({
        name: value.title,
        count: value.count,
        percentage: parseFloat(value.percentage.toFixed(1)),
        group: value.group,
      });
    }
  });

  return (
    <div className="gender-widget">
      <div className="chart-container">
        <HorizontalBar
          isLoading={false}
          values={horizontalBarValues}
          dimension={dimensionSettings}
        />
      </div>
    </div>
  );
};

export default PersonalGoalTypeWidget;
