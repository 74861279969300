const CategoryKeysGA = {
  Filter: 'Filter',
  Account: 'Account',
  AccountCreateAccountStep1: 'Account/CreateAccount/Step1',
  AccountCreateAccountStep2: 'Account/CreateAccount/Step2',
  AccountCreateAccountStep3: 'Account/CreateAccount/Step3',
  AccountCreateAccountComplete: 'Account/CreateAccount/Complete',
  AccountCreateAccountVerification: 'Account/CreateAccount/Verification',
  AccountCreateProfile: 'Account/CreateProfile',
  AccountForgotPassword: 'Account/ForgotPassword',
  AccountSetNewPassword: 'Account/SetNewPassword',

  GlobalFilters: 'GlobalFilters',
  GlobalFiltersGroupAge: 'GlobalFilters/GroupAge',
  GlobalFiltersBackupStatus: 'GlobalFilters/BackupStatus',
  GlobalFiltersDateRange: 'GlobalFilters/DateRange',

  GettingStarted: 'GettingStarted',
  GettingStartedSettingUpGroupData: 'GettingStarted/SettingUpGroupData',
  GettingStartedInviteUsers: 'GettingStarted/InviteUsers',
  GettingStartedCreateProject: 'GettingStarted/CreateProject',
  GettingStartedViewProjects: 'GettingStarted/ViewProjects',

  DashboardReports: 'Dashboard/Reports',
  DashboardWidgets: 'Dashboard/Widgets',
  DashboardFinancialMetrics: 'Dashboard/FinancialMetrics',
  DashboardMembershipMetrics: 'Dashboard/MembershipMetrics',
  DashboardGroupMetrics: 'Dashboard/GroupMetrics',
  DashboardGroupMetricsCyclePhase: 'Dashboard/GroupMetrics/cyclePhase',

  FinancesFinancialMetrics: 'Finances/FinancialMetrics',
  FinancesFinancialMetricChartType: 'Finances/FinancialMetrics/ChartType',

  GroupsAssign: 'Groups/Assign',
  GroupsAssignDetails: 'Groups/Assign/GroupDetails',
  GroupsReports: 'Groups/Reports',
  GroupsList: 'Groups/List',
  GroupsMap: 'Groups/Map',
  GroupsNotifications: 'Groups/Notifications',
  GroupsListManageColumns: 'Groups/List/ManageColumns',

  GroupDetailsReports: 'GroupDetails/Reports',
  GroupDetailsWidgets: 'GroupDetails/Widgets',
  GroupDetailsFinancialMetrics: 'GroupDetails/FinancialMetrics',
  GroupDetailsFinancialMetricChartType:
    'GroupDetails/FinancialMetrics/ChartType',

  ProjectsCreateProject: 'Projects/CreateProject',
  ProjectsEditProject: 'Projects/EditProject',
  ProjectsUsersAdd: 'Projects/Users/Add',
  ProjectsUsersList: 'Projects/Users/List',
  ProjectsFacilitatorsAdd: 'Projects/Facilitators/Add',
  ProjectsFacilitatorsList: 'Projects/Facilitators/List',

  SettingsUsers: 'Settings/Users',
  SettingsUsersInvite: 'Settings/Users/Invite',
  SettingsUsersInvitedUsers: 'Settings/Users/InvitedUsers',
  SettingsPermissions: 'Settings/Permissions',
};
export default CategoryKeysGA;
