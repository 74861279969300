import * as intl from 'react-intl-universal';

import GroupWidgetType from '../GroupWidgetType';

type WidgetConfig = {
  name: string;
  tooltip: string;
  configurable: boolean;
  targetSettings: boolean;
};

class GroupWidgetConfigs {
  public static GetGroupWidgetConfig(type: GroupWidgetType): WidgetConfig {
    const groupWidgetConfigs: { [key: string]: WidgetConfig } = {
      [GroupWidgetType.Age]: {
        name: intl.get('LBL_WID_AGE'),
        tooltip: intl.get('LBL_WID_AGE_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [GroupWidgetType.AverageSavings]: {
        name: intl.get('LBL_WID_AVERAGE_SAVINGS_MEMBER'),
        tooltip: intl.get('LBL_WID_AVERAGE_SAVINGS_MEMBER_HINT'),
        configurable: true,
        targetSettings: true,
      },
      [GroupWidgetType.CumulativeSavings]: {
        name: intl.get('LBL_WID_CUMULATIVE_SAVINGS'),
        tooltip: intl.get('LBL_WID_CUMULATIVE_SAVINGS_HINT'),
        configurable: true,
        targetSettings: true,
      },
      [GroupWidgetType.Education]: {
        name: intl.get('LBL_WID_EDUCATION'),
        tooltip: intl.get('LBL_WID_EDUCATION_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [GroupWidgetType.Gender]: {
        name: intl.get('LBL_WID_GENDER'),
        tooltip: intl.get('LBL_WID_GENDER_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [GroupWidgetType.PersonalGoalAchievement]: {
        name: intl.get('LBL_WID_PERSONAL_GOAL_ACHIEVEMENT'),
        tooltip: intl.get('LBL_WID_PERSONAL_GOAL_ACHIEVEMENT_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [GroupWidgetType.LoanFund]: {
        name: intl.get('LBL_WID_LOAN_FUND_UTILIZATION'),
        tooltip: intl.get('LBL_WID_LOAN_FUND_UTILIZATION_HINT'),
        configurable: true,
        targetSettings: true,
      },
      [GroupWidgetType.PersonalGoalType]: {
        name: intl.get('LBL_WID_PERSONAL_GOAL_TYPES'),
        tooltip: intl.get('LBL_WID_PERSONAL_GOAL_TYPES_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [GroupWidgetType.LoanType]: {
        name: intl.get('LBL_WID_LOAN_TYPES'),
        tooltip: intl.get('LBL_WID_LOAN_TYPES_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [GroupWidgetType.MemberChildren]: {
        name: intl.get('LBL_WID_MEMBERS_CARING_FOR_CHILDREN'),
        tooltip: intl.get('LBL_WID_MEMBERS_CARING_FOR_CHILDREN_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [GroupWidgetType.AdditionalFunds]: {
        name: intl.get('LBL_WID_ADDITIONAL_FUNDS'),
        tooltip: intl.get('LBL_WID_GROUP_ADDITIONAL_FUNDS_HINT'),
        configurable: false,
        targetSettings: false,
      },
    };

    return groupWidgetConfigs[type];
  }
}

export default GroupWidgetConfigs;
