import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import Select, { Theme } from 'react-select';

import Tables from 'constants/Tables';

import DataTableFooterProps from './DataTableFooterProps';
import Pagination from './pagination/Pagination';

const DataTableFooter: ReactFC<DataTableFooterProps> = (
  props: DataTableFooterProps
) => {
  // prettier-ignore
  const { gotoPage, pageSize, setPageSize, pageCount, pageIndex, totalResults, loading, pageSizeDisabled } = props;
  const resultsPerPage = pageIndex * pageSize;
  const offsetStart = resultsPerPage + 1;
  const offsetEnd =
    resultsPerPage + pageSize > totalResults
      ? totalResults
      : resultsPerPage + pageSize;

  const options = Tables.PageSizes.map((size) => ({
    value: size,
    label: size,
  }));

  return (
    <div className="insight-table-pagination">
      <div className="pagination-col pagination-label">
        Results per page:&nbsp;
        <div className="inline-form">
          <Select
            name="Page Size"
            isDisabled={loading || pageSizeDisabled}
            className="table-select-container"
            classNamePrefix="table-select"
            value={options.filter((option) => option.value === pageSize)}
            options={options}
            theme={(theme): Theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#fbf3df',
                primary: '#68c7c2',
              },
            })}
            onChange={(option): void => {
              const optionNew = option as { value: number; label: number };
              setPageSize(Number(optionNew.value));
            }}
          />
        </div>
      </div>
      <Pagination
        disabled={loading}
        pageCount={pageCount}
        pageIndex={pageIndex}
        siblingCount={1}
        boundaryCount={1}
        gotoPage={gotoPage}
      />

      <div className="pagination-col pagination-label align-right">
        {intl.get('LBL_FOOTER_SHOWING')} &nbsp;
        <span className="color-secondary">
          {offsetStart}-{offsetEnd}
        </span>
        &nbsp; {intl.get('LBL_FOOTER_OF')} &nbsp;
        <span className="color-secondary">{totalResults}</span>
      </div>
    </div>
  );
};

export default DataTableFooter;
