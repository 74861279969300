import ReactGA, { FieldsObject } from 'react-ga';

import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';

const initGA = (): void => {
  const cookieFlags = process.env.REACT_APP_GA_COOKIE_FLAGS ?? undefined;
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID ?? '';

  ReactGA.initialize(trackingId, {
    debug: false,
    gaOptions: {
      cookieFlags,
    },
  });
};

/**
 * Tracks page views
 *
 * @param page Visited page
 * @param options Optional object of options
 */
const pageViewGA = (page: string, options?: FieldsObject): void => {
  ReactGA.set({ page, ...options });
  ReactGA.pageview(page);
};

/**
 * Tracks modal views
 *
 * @param modal Visited modal
 */
const modalViewGA = (modal: string): void => {
  ReactGA.set({ page: modal });
  ReactGA.modalview(modal);
};

/**
 * Tracks thrown exceptions
 *
 * @param description Description of thrown exception
 */
const sendExceptionGA = (description: string): void => {
  ReactGA.exception({ description });
};

/**
 * userId must not be as PII (personally identifiable information)
 * emails, tax numbers etc are not allowed. This is simply for the sake of
 * getting multiple logins from same credentials counted as one in GA
 *
 * @param userId The logged-in user's ID
 */
const setUserIdGA = (userId: string): void => {
  ReactGA.set({ userId });
};

/**
 * Tracks application events
 *
 * @param category Category of application event
 * @param action Action of application event
 * @param label Optional label for event data
 * @param value Optional value
 * @param nonInteraction Specifies if event is non-interactive
 */
const sendEventGA = (
  category: typeof CategoryKeysGA[keyof typeof CategoryKeysGA] | string,
  action: typeof ActionKeysGA[keyof typeof ActionKeysGA],
  label?: string,
  value?: number,
  nonInteraction?: boolean
): void => {
  ReactGA.event({
    category, // Required
    action, // Required
    label: label ?? '',
    value: value ?? 0,
    nonInteraction: nonInteraction ?? false,
  });
};

export {
  initGA,
  pageViewGA,
  modalViewGA,
  sendEventGA,
  setUserIdGA,
  sendExceptionGA,
};
