/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, FC as ReactFC } from 'react';

import get from 'lodash/get';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';

import Misc from 'constants/misc/Misc';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';

import GroupMetricQuartilesTableProps from './GroupMetricQuartilesTableProps';
import QuartilesTBodyEmpty from './quartiles-tbody-empty/QuartilesTBodyEmpty';

const GroupMetricQuartilesTable: ReactFC<GroupMetricQuartilesTableProps> = ({
  title,
  firstColumn,
  secondColumn,
  quartiles,
}) => {
  const getCurrencyString = useCurrency();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [quartiles]);

  /**
   * Renders compacted values for quartile table
   *
   * @param value Value to be formatted
   * @returns {JSX.Element} JSX snippet containing the formatted value
   */
  const renderValueForRange = (value: number | string): JSX.Element => {
    if (typeof value === 'number') {
      const isMillion = value > Misc.CompactionBoundary;
      return (
        <span
          data-for={isMillion ? 'insTooltip' : ''}
          data-tip={getCurrencyString(value, true)}
        >
          {getCurrencyString(
            value,
            true,
            isMillion ? IntlNumberNotation.Compact : undefined
          )}
        </span>
      );
    }
    return <span>{intl.get('LBL_NA')}</span>;
  };

  return (
    <div className="chart-table-container">
      <div className="title">{intl.get(`${title}`)}</div>
      <div className="content">
        <table className="chart-table">
          <thead>
            <tr>
              <th>{intl.get('LBL_QUARTILE_TABLE_PERCENTILE_TITLE')}</th>
              <th className="align-right">{intl.get(`${firstColumn}`)}</th>
              <th className="align-right">{intl.get(`${secondColumn}`)}</th>
            </tr>
          </thead>
          {get(quartiles, 'length', 0) > 0 ? (
            <tbody>
              {quartiles &&
                quartiles
                  .slice(0)
                  .reverse()
                  .map((quartile) => (
                    <tr key={quartile.percentile}>
                      <td>{intl.get(`LBL_${quartile.percentile}`)}</td>
                      <td className="value">{quartile.getCount()}</td>
                      <td className="value">
                        {quartile.rangeFrom === quartile.rangeTo ? (
                          renderValueForRange(quartile.rangeFrom)
                        ) : (
                          <span>
                            {renderValueForRange(quartile.rangeFrom)} -{' '}
                            {renderValueForRange(quartile.rangeTo)}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          ) : (
            <QuartilesTBodyEmpty />
          )}
        </table>
      </div>
    </div>
  );
};

export default GroupMetricQuartilesTable;
