/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Col, Fade, Row } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveySendViewPoint from 'shared/enums/SurveySendViewPoint';
import SurveyType from 'shared/enums/SurveyType';

import styles from '../surveysPopups.module.scss';
import WarningStrings from './SurveySendWarningPopupConstants';
import SurveySendWarningPopupProps from './SurveySendWarningPopupProps';

const SurveySendWarningPopup: ReactFC<SurveySendWarningPopupProps> = (
  props: SurveySendWarningPopupProps
) => {
  const {
    display,
    sendCount,
    excludedMemberCount,
    excludedGroups,
    surveyType,
    recipientType,
    isDuplicateSurvey,
    viewPoint,
    conflictingRequests,
    onCancel,
    onContinue,
  } = props;

  const isSurvey = surveyType === SurveyType.Survey;
  const isCollectIdRequest = surveyType === SurveyType.CollectId;
  const displayDuplicateCollectIdRequestMessage =
    isCollectIdRequest && isDuplicateSurvey;
  const displayDuplicateCollectIdRequestSendPrompt =
    (viewPoint === SurveySendViewPoint.Drafts && sendCount > 0) ||
    viewPoint === SurveySendViewPoint.History;
  const cannotSendCollectIdRequest =
    viewPoint === SurveySendViewPoint.Drafts && sendCount <= 0;
  const displaySendCountZero =
    (isCollectIdRequest && cannotSendCollectIdRequest) ||
    (isSurvey && sendCount <= 0);

  /**
   * Builds send count message
   *
   * @returns {string} Send count message
   */
  const getSendCountMessage = (): string =>
    intl.get(WarningStrings[surveyType][recipientType][viewPoint].SendCount, {
      count: sendCount,
    });

  /**
   * Renders a message for when the send count is 0
   *
   * @returns {JSX.Element} Send count 0 message
   */
  const renderSendCountZeroMessage = (): JSX.Element => (
    <>
      <Col xs="12">
        <hr className="divider mt-1" />
      </Col>
      <Col
        xs="12"
        className={`mb-3 text-14-medium text-center ${styles.textDanger}`}
      >
        {intl.get(WarningStrings[surveyType].InvalidSendCount)}
      </Col>
    </>
  );

  /**
   * Renders a message for when the filter criteria in a
   * Collect ID request matches a previous request
   *
   * @returns {JSX.Element} Duplicate request message
   */
  const renderDuplicateCollectIdRequestMessage = (): JSX.Element => (
    <>
      <Col xs="12">
        <hr className="divider mt-1" />
      </Col>
      <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
        {intl.get(WarningStrings[surveyType].DuplicateWarning, {
          count: conflictingRequests.length,
        })}
      </Col>
      {conflictingRequests.length > 0 && (
        <Col xs="12" className="p-3">
          <PerfectScrollbar className={styles.scrollContainer}>
            <div className="text-14-medium text-gray mb-0">
              <ul className="pl-0">
                {conflictingRequests.map((request) => (
                  <li key={request.id}>
                    <EllipsisTooltip
                      tag="div"
                      data-place="right"
                      data-for="insTooltip"
                      data-tip={request.name}
                      data-class="overflow-wrap"
                      className="truncate"
                    >
                      {request.name}
                    </EllipsisTooltip>
                  </li>
                ))}
              </ul>
            </div>
          </PerfectScrollbar>
        </Col>
      )}
      {displayDuplicateCollectIdRequestSendPrompt && (
        <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
          {intl.get(WarningStrings[surveyType].DuplicatePrompt)}
        </Col>
      )}
    </>
  );

  /**
   * Renders excluded groups/members message
   *
   * @returns {JSX.Element} JSX snippet containing the exclusions content
   */
  const renderExclusionContent = (): JSX.Element => (
    <>
      {recipientType === SurveyRecipientType.Groups &&
        excludedGroups.length > 0 && (
          <>
            <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
              {intl.get(WarningStrings[surveyType][recipientType].Exclusion)}
            </Col>
            <Col xs="12" className="p-3">
              <PerfectScrollbar className={styles.scrollContainer}>
                <div className="text-14-medium text-gray mb-0">
                  <ul className="pl-0">
                    {excludedGroups.map((group) => (
                      <li key={group.groupId}>
                        <EllipsisTooltip
                          tag="div"
                          data-place="right"
                          data-for="insTooltip"
                          data-tip={group.name}
                          data-class="overflow-wrap"
                          className="truncate"
                        >
                          {group.name}
                        </EllipsisTooltip>
                      </li>
                    ))}
                  </ul>
                </div>
              </PerfectScrollbar>
            </Col>
          </>
        )}
      {recipientType === SurveyRecipientType.Members &&
        excludedMemberCount > 0 && (
          <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
            {intl.get(WarningStrings[surveyType][recipientType].Exclusion, {
              count: excludedMemberCount,
            })}
          </Col>
        )}
    </>
  );

  return (
    <Fade mountOnEnter unmountOnExit in={display} className={styles.overlay}>
      <TrapFocus autoFocus>
        {({ firstFocus, lastFocus }): JSX.Element => (
          <div className={`${styles.confirmation} ${styles.warning}`}>
            <Row className="w-100">
              <Col
                xs="12"
                className="mb-3 text-14-medium text-center text-gray"
              >
                {intl.get(WarningStrings[surveyType].Intro)}
              </Col>
              <Col
                xs="12"
                className="mb-3 text-14-medium text-center text-gray"
              >
                {getSendCountMessage()}
              </Col>
              {renderExclusionContent()}
              {displayDuplicateCollectIdRequestMessage &&
                renderDuplicateCollectIdRequestMessage()}
              {displaySendCountZero && renderSendCountZeroMessage()}
              <Col xs="12" className="justify-content-center d-flex mt-3 pt-3">
                <Button
                  innerRef={lastFocus}
                  className="btn btn-light btn-sm text-14-semibold mr-3"
                  onClick={onCancel}
                >
                  {intl.get(WarningStrings[surveyType].CancelButton)}
                </Button>
                <Button
                  innerRef={firstFocus}
                  className="btn btn-warning btn-sm text-14-semibold ml-3"
                  type="button"
                  disabled={cannotSendCollectIdRequest}
                  onClick={onContinue}
                >
                  {intl.get(WarningStrings[surveyType].SendButton)}
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center w-100" />
          </div>
        )}
      </TrapFocus>
    </Fade>
  );
};

export default SurveySendWarningPopup;
