const Charts = {
  DefaultChartTicksUpperLimit: 10,
  DefaultChartTicksLowerLimit: 8,
  MediumChartTicksUpperLimitL: 8,
  MediumChartTicksLowerLimit: 6,
  SmallChartTicksUpperLimit: 5,
  SmallChartTicksLowerLimit: 3,
};

export default Charts;
