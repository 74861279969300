import { RouteChildrenProps } from 'react-router-dom';

import AppContextProps from 'context/AppContextProps';

export enum ModalType {
  None = 'NONE',
  InviteUsers = 'INVITE_USERS',
}
interface UsersViewProps extends RouteChildrenProps {
  modal: ModalType;
  appContext: AppContextProps;
}
export default UsersViewProps;
