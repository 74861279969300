/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, FC as ReactFC } from 'react';

import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import { Button, Col, ModalBody, ModalHeader, Row } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';

import ForgotPasswordPending from '../forgot-password-pending/ForgotPasswordPending';
import styles from './setNewPasswordError.module.scss';
import SetNewPasswordErrorProps, {
  SetNewPasswordStatusError,
} from './SetNewPasswordErrorProps';

const SetNewPasswordError: ReactFC<SetNewPasswordErrorProps> = (
  props: SetNewPasswordErrorProps
) => {
  const {
    email,
    isEmailResent,
    emailResendStatus,
    errorStatus,
    expiryTimeString,
    onResendEmailClick,
  } = props;

  const [errorType, setErrorType] = useState<SetNewPasswordStatusError>(
    SetNewPasswordStatusError.InvalidCode
  );

  useEffect(() => {
    if (errorStatus && errorStatus.msg && !isEmpty(errorStatus.fields)) {
      const fieldKeys = Object.keys(errorStatus.fields);
      const errorFields = ['code', 'email'];
      if (errorFields.includes(fieldKeys[0])) {
        const errorCode = errorStatus.fields[fieldKeys[0]];
        setErrorType(errorCode as SetNewPasswordStatusError);
      }
    }
  }, [errorStatus]);

  /**
   * Returns locale string for appropriate verification error
   *
   * @returns {string} Locale string for error message
   */
  const getErrorLocaleString = (): string => {
    if (errorType === SetNewPasswordStatusError.NonexistentEmail) {
      return 'ERR_SET_NEW_PASSWORD_EMAIL_DOES_NOT_EXIST';
    }
    return 'ERR_SET_NEW_PASSWORD_VERIFICATION_CODE_INVALID';
  };

  /**
   * Renders the footer for the verification error state modal
   *
   * @returns {JSX.Element} JSX snippet for the error footer
   */
  const renderFooter = (): JSX.Element => (
    <Row>
      <Col xs="12" className="text-center mt-3 pt-3">
        <small>
          {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
          <a
            href="https://support.dreamstartlabs.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {intl.get('LBL_CA_CONTACT_SUPPORT')}
          </a>
        </small>
      </Col>
    </Row>
  );

  return (
    <>
      {errorType && errorType === SetNewPasswordStatusError.ExpiredCode ? (
        <>
          {isEmailResent ? (
            <ForgotPasswordPending
              email={email}
              status={emailResendStatus}
              expiryTimeString={expiryTimeString}
              onResendEmailClick={onResendEmailClick}
            />
          ) : (
            <>
              <ModalHeader className="increase-font text-center">
                {intl.get('LBL_SET_NEW_PASSWORD_ERROR_TITLE')}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col
                    xs="12"
                    className="d-flex justify-content-center mb-3 p-3"
                  >
                    <span className={styles.circle} />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <p className="form-title text-center text-gray">
                      {intl.get(
                        'ERR_SET_NEW_PASSWORD_VERIFICATION_CODE_EXPIRED',
                        {
                          expiry: expiryTimeString,
                        }
                      )}
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="auto" className="mb-3 pb-3">
                    <Button
                      color="primary"
                      onClick={(): void => onResendEmailClick(email)}
                      disabled={emailResendStatus === Status.Loading}
                    >
                      {intl.get('BTN_FORGOT_PASSWORD_PENDING_RESEND_EMAIL')}
                    </Button>
                  </Col>
                </Row>
                {renderFooter()}
              </ModalBody>
            </>
          )}
        </>
      ) : (
        <>
          <ModalHeader className="increase-font text-center">
            {intl.get('LBL_SET_NEW_PASSWORD_ERROR_TITLE')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12" className="d-flex justify-content-center mb-3 p-3">
                <span className={styles.circle} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <p className="form-title text-center text-grey">
                  {intl.get(`${getErrorLocaleString()}`)}
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3 mb-3">
              <Col xs="auto">
                <InsLink
                  className="btn btn-primary"
                  to={`${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`}
                >
                  {intl.get('BTN_GO_TO_LOGIN')}
                </InsLink>
              </Col>
            </Row>
            {renderFooter()}
          </ModalBody>
        </>
      )}
    </>
  );
};

export default SetNewPasswordError;
