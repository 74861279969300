/* eslint-disable @typescript-eslint/no-explicit-any */
class ApiError extends Error {
  status?: number;

  statusText: string;

  response?: any;

  constructor(
    message: string,
    status?: number,
    statusText = '',
    response?: any
  ) {
    super(message);

    this.status = status;
    this.statusText = statusText;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.response = response;
  }
}

export default ApiError;
