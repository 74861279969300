import React, { useEffect, useState } from 'react';

import Misc from 'constants/misc/Misc';
import { getCompactNumber, getFormattedNumber } from 'helpers/NumberFormat';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';

import EllipsisTooltip from '../ellipsis-tooltip/EllipsisTooltip';
import InsNumberFormatProps from './InsNumberFormatProps';

const InsNumberFormat: React.FC<InsNumberFormatProps> = (
  props: InsNumberFormatProps
) => {
  const {
    value,
    precise,
    type = 'regular',
    notation = IntlNumberNotation.Standard,
    maxFractionDigits = Misc.MaximumFractionDigits,
    minFractionDigits = Misc.MinimumFractionDigits,
    tooltip = false,
    tooltipPlacement = 'bottom',
    tooltipClasses = 'overflow-wrap',
  } = props;

  const [displayValue, setDisplayValue] = useState<string>();
  const getCurrencyString = useCurrency();

  useEffect(() => {
    buildString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, type, precise, notation, maxFractionDigits, minFractionDigits]);

  /**
   * Build string based on display type
   */
  const buildString = (): void => {
    const formattedValue = Number(value || 0);

    let displayString =
      notation === IntlNumberNotation.Compact
        ? getCompactNumber(formattedValue, precise)
        : getFormattedNumber(formattedValue, precise);

    switch (type) {
      case 'currency':
        displayString = getCurrencyString(formattedValue, precise, notation);
        break;
      case 'percentage':
        displayString = `${displayString}%`;
        break;
      default:
        break;
    }
    setDisplayValue(displayString);
  };

  return (
    <>
      {tooltip ? (
        <EllipsisTooltip
          tag="span"
          data-class={tooltipClasses}
          data-place={tooltipPlacement}
          data-for="insTooltip"
          data-tip={displayValue ?? ''}
          className="truncate"
        >
          {displayValue}
        </EllipsisTooltip>
      ) : (
        <>{displayValue}</>
      )}
    </>
  );
};

export default InsNumberFormat;
