/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { MutableRefObject, RefCallback, LegacyRef } from 'react';

/**
 * A utility function to merge multiple refs.
 * Useful in places where you will have to have parent ref as well as a forward ref
 *
 * @param refs List of refs to be merged
 * @returns {RefCallback}
 */
function mergeRefs<T = any>(
  refs: Array<MutableRefObject<T> | LegacyRef<T>>
): RefCallback<T> {
  return (value): void => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export default mergeRefs;
