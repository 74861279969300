import { useEffect, FC as ReactFC } from 'react';

import * as d3 from 'd3';
import ReactTooltip from 'react-tooltip';

import { insightsColors } from 'helpers/ColorHelper';
import GaugeProps from 'shared/components/ins-charts/gauge/GaugeProps';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

const GaugeWidget: ReactFC<GaugeProps> = (props) => {
  const donutThickness = 0.07;
  const angelFactor = Math.PI * 2;

  const { value, dimension, isCurrency } = props;
  const { fromTarget = 0, toTarget = 0, count } = value;
  const { ref, computedDimension } = useDimension(dimension);
  const getCurrencyString = useCurrency();
  const { width, height, boundedHeight, boundedWidth } = computedDimension;

  const chartArea = 0.7;
  const innerRadius = (Math.min(boundedHeight, boundedWidth) / 2) * chartArea;
  const outerRadius = innerRadius - height * donutThickness;
  const centerPoint = innerRadius;

  const maxAngle = 130;
  const minAngle = -130;
  const angleRange = maxAngle - minAngle;
  const radius = Math.min(boundedHeight, boundedWidth) / 2;
  const pointerHeadLengthPercent = 0.5;
  const pointerHeadLength = Math.round(radius * pointerHeadLengthPercent);

  const pointerWidth = innerRadius * 0.05;
  const pointerTailLength = innerRadius * 0.025;

  const targetRange = fromTarget + toTarget;
  const rangeFrom = 0;
  const rangeTo = 1;
  const domainFrom = 0;
  const domainTo = targetRange > count ? targetRange : count;

  const gaugeValueYAxis = innerRadius * 0.75;

  useEffect(() => {
    const pointerArrow = [
      [pointerWidth / 2, 0],
      [0, -pointerHeadLength],
      [-(pointerWidth / 2), 0],
      [0, pointerTailLength],
      [pointerWidth / 2, 0],
    ];

    const draw = (): void => {
      const degreeToRadius = (deg: number): number => (deg * Math.PI) / 180;

      const centerCx = (): string => `translate(${width / 2},${height / 2})`;

      const pointerLine = d3.line<unknown>().curve(d3.curveLinear);

      const angleGenerator = (d: number): number =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        minAngle + scale(d)! * angleRange;

      const scale = d3
        .scaleLinear()
        .range([rangeFrom, rangeTo])
        .domain([domainFrom, domainTo]);

      const arcGenerator = d3
        .arc<unknown>()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .startAngle((d: any, i: any): number => {
          const ratio = d * i;
          return degreeToRadius(minAngle + ratio * angleRange);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .endAngle((d: any, i: any): number => {
          const ratio = d * (Number(i) + 1);
          return degreeToRadius(minAngle + ratio * angleRange);
        });

      const transformIndicators = (d: number): string => {
        const ratio = scale(d);
        const newAngle = minAngle + ratio * angleRange;
        const position = outerRadius;

        return `rotate(${newAngle}) translate(0,${-position})`;
      };

      const tooltipGenerator = (d: number): string =>
        isCurrency
          ? `
          <div>
            <span class="">${getCurrencyString(d, true)}</span>
          </div>`
          : `
          <div>
            <span class="">${d3.format(',')(d)}</span>
          </div>
        `;

      const colorFinder = (): string => {
        let colorCode = insightsColors.insightSecondary;
        const lowMargin = fromTarget * 0.25;
        const highMargin = fromTarget * 1.0;

        if (value.count <= lowMargin) {
          colorCode = insightsColors.insightJaffa;
        } else if (value.count > lowMargin && value.count < highMargin) {
          colorCode = insightsColors.insightYellow;
        }
        return colorCode;
      };

      const textFormatter = (d: number): string => {
        const formattedValue = `${d3.format(',')(d)}`;
        const regularFormat = isCurrency
          ? getCurrencyString(d, false)
          : formattedValue;
        return value.percentageRange ? `${formattedValue}%` : regularFormat;
      };

      // Start drawing the chart on the SVG.
      const svg = d3.select(ref.current).select('svg');
      const g = svg.select('g').attr('transform', centerCx);

      const fixedPercentage = value.percentage > 100 ? 100 : value.percentage;

      g.selectAll('path')
        .data([rangeTo])
        .attr('class', 'background-arc')
        .join('path')
        .attr('fill', insightsColors.insightBgYellow)
        .attr('d', arcGenerator);

      g.selectAll('path.foreground-arc')
        // Getting the values from 0 to 1 range
        .data([fixedPercentage * 0.01])
        .join('path')
        .attr('class', 'foreground-arc')
        .attr('fill', colorFinder())
        .attr('d', arcGenerator);

      g.append('path')
        .data([pointerArrow])
        .attr('class', 'arrow-pointer')
        .attr('d', pointerLine)
        .attr('fill', insightsColors.insightPrimary)
        .attr('transform', `rotate(${angleGenerator(value.count)})`);

      g.selectAll('text.gauge-value')
        .data([value.count])
        .join('text')
        .attr('class', 'gauge-value')
        .attr('text-anchor', 'middle')
        .attr('y', gaugeValueYAxis)
        .attr('dominant-baseline', 'hanging')
        .text(textFormatter);

      svg
        .select('g.indicator')
        .selectAll('line.indicator')
        .data([fromTarget, toTarget])
        .join('line')
        .attr('class', 'indicator')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', outerRadius - innerRadius)
        .attr('data-for', 'svgTooltip')
        .attr('data-tip', tooltipGenerator)
        .attr('style', `stroke:${insightsColors.insightGray};stroke-width:1.5`)
        .attr('transform', transformIndicators);

      ReactTooltip.rebuild();
    };

    if (value && ref != null) {
      draw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pointerHeadLength,
    pointerTailLength,
    gaugeValueYAxis,
    pointerWidth,
    innerRadius,
    outerRadius,
    angelFactor,
    centerPoint,
    angleRange,
    fromTarget,
    domainTo,
    minAngle,
    toTarget,
    height,
    radius,
    value,
    isCurrency,
    width,
    ref,
  ]);

  return (
    <div
      ref={ref}
      className="ins-custom-chart-wrapper"
      style={{ height: `${height}px` }}
    >
      <ReactTooltip id="svgTooltip" type="light" html />
      <svg width={width} height={height}>
        <g
          width={boundedWidth}
          height={boundedHeight}
          transform={`translate(${width / 2}, ${height / 2})`}
        />
        {/* This group is used for indicators */}
        <g
          width={boundedWidth}
          height={boundedHeight}
          transform={`translate(${width / 2}, ${height / 2})`}
          className="indicator"
        />
      </svg>
    </div>
  );
};

export default GaugeWidget;
