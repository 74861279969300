import { FC as ReactFC } from 'react';

import orderBy from 'lodash/orderBy';

import { getColorFor } from 'helpers/ColorHelper';
import GroupWidgetViewModel from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetViewModel';
import Donut from 'shared/components/ins-charts/donut/Donut';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';
import InsNumberFormat from 'shared/components/ins-number-format/InsNumberFormat';

const GenderWidget: ReactFC<{ data: GroupWidgetViewModel }> = (props) => {
  const maxItemCount = 2;
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const femaleData = values?.find((value) => value.group === 'FEMALE');
  const maleData = values?.find((value) => value.group === 'MALE');

  const donutValues = Array<InsChartValue>();

  if (femaleData && maleData) {
    donutValues.push({
      name: femaleData.title,
      count: femaleData.count,
      percentage: femaleData.percentage,
      group: femaleData.group,
    });
    donutValues.push({
      name: maleData.title,
      count: maleData.count,
      percentage: maleData.percentage,
      group: maleData.group,
    });
  }

  return (
    <div className="gender-widget">
      <div className="chart-container">
        <Donut
          isLoading={false}
          values={donutValues}
          dimension={dimensionSettings}
        />
      </div>
      {donutValues.length === maxItemCount && (
        <div className="chart-legend">
          <div className="chart-legend-inner">
            {orderBy(donutValues, 'percentage', 'desc').map((value) => (
              <div key={value.name} className="item">
                <div className="item-col color-container">
                  <span
                    className="color"
                    style={{ backgroundColor: getColorFor(value.group) }}
                  />
                </div>
                <div className="item-col label">
                  <span className="value">
                    <InsNumberFormat
                      value={value.percentage}
                      type="percentage"
                      precise={false}
                    />
                  </span>
                </div>
                <div className="item-col label">
                  <span>&nbsp;- </span>
                  <span className="name">{value.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GenderWidget;
