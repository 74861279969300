const BackupStatusConfigs: { [key: string]: string } = {
  NONE: 'All',
  NEVER: 'Never',
  ALL_MEETINGS: 'All meetings backed up',
  ONE_MEETING_NOT_BACKED_UP: '1 meeting not backed up',
  TWO_OR_MORE_MEETINGS_NOT_BACKED_UP: '2+ meetings not backed up',
};

const GroupAgeConfigs: { [key: string]: string } = {
  NONE: 'All',
  ONE: '1st cycle',
  TWO: '2nd cycle',
  THREE_OR_MORE: '3+ cycle',
};

export { BackupStatusConfigs, GroupAgeConfigs };
