import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import PermissionUtil from 'helpers/PermissionUtil';
import InsLinkButtonBlur from 'shared/components/ins-link-button-blur/InsLinkButtonBlur';

import ProjectsToolbarProps from './ProjectToolbarProps';

const ProjectsToolbar: ReactFC<ProjectsToolbarProps> = (
  props: ProjectsToolbarProps
) => {
  const { match, context, userDropdown } = props;

  const { permissionsData } = context;
  const { claims } = permissionsData;
  const showCreateProjectButton = PermissionUtil.Can(
    claims,
    ResourceKeys.ProjectsCreateNewProject
  );

  const url = match?.url ?? '/projects';

  return (
    <header className="main-header">
      <h2 className="main-title">{intl.get('LBL_TITLE_PROJECTS')}</h2>
      <div className="header-col ml-auto">
        {showCreateProjectButton && (
          <InsLinkButtonBlur
            to={`${url}/create`}
            className="btn header-btn btn-secondary"
          >
            <i className="icon-plus" />
            {intl.get('BTN_PROJECTS_CREATE_PROJECT')}
          </InsLinkButtonBlur>
        )}
        &nbsp;&nbsp;
        {userDropdown}
      </div>
    </header>
  );
};

export default ProjectsToolbar;
