import { FC as ReactFC } from 'react';

import orderBy from 'lodash/orderBy';

import { getColorFor } from 'helpers/ColorHelper';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import Donut from 'shared/components/ins-charts/donut/Donut';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';
import InsNumberFormat from 'shared/components/ins-number-format/InsNumberFormat';

const PersonalGoalAchievementWidget: ReactFC<{
  data: WidgetViewModel;
}> = (props) => {
  const maxItemCount = 3;
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const greatProgressData = values?.find(
    (value) => value.group === 'GREAT_PROGRESS'
  );
  const goodProgressData = values?.find(
    (value) => value.group === 'GOOD_PROGRESS'
  );
  const littleBehindData = values?.find(
    (value) => value.group === 'A_LITTLE_BEHIND'
  );

  const donutValues = Array<InsChartValue>();

  if (greatProgressData && goodProgressData && littleBehindData) {
    donutValues.push({
      name: greatProgressData.title,
      count: greatProgressData.count,
      percentage: greatProgressData.percentage,
      group: greatProgressData.group,
    });
    donutValues.push({
      name: goodProgressData.title,
      count: goodProgressData.count,
      percentage: goodProgressData.percentage,
      group: goodProgressData.group,
    });
    donutValues.push({
      name: littleBehindData.title,
      count: littleBehindData.count,
      percentage: littleBehindData.percentage,
      group: littleBehindData.group,
    });
  }

  return (
    <div className="personal-goal-achievement-widget">
      <div className="chart-container">
        <Donut
          isLoading={false}
          values={donutValues}
          dimension={dimensionSettings}
        />
      </div>
      {donutValues.length === maxItemCount && (
        <div className="chart-legend">
          <div className="chart-legend-inner">
            {orderBy(donutValues, 'percentage', 'desc').map((value) => (
              <div key={value.name} className="item">
                <div className="item-col color-container">
                  <span
                    className="color"
                    style={{
                      backgroundColor: getColorFor(value.group),
                    }}
                  />
                </div>
                <div className="label item-col">
                  <span className="value">
                    <InsNumberFormat
                      value={value.percentage}
                      type="percentage"
                      precise={false}
                    />
                  </span>
                </div>
                <div className="label item-col">
                  <span>&nbsp;- </span>
                  <span className="name">{value.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalGoalAchievementWidget;
