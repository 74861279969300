/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { FC as ReactFC } from 'react';

import OffsetPaginationProps from './OffsetPaginationProps';

const OffsetPagination: ReactFC<OffsetPaginationProps> = (
  props: OffsetPaginationProps
) => {
  const { hasPrevious, hasNext, gotoNextPage, gotoPreviousPage, disabled } =
    props;

  return (
    <div className="pagination-col pagination-buttons">
      <button
        disabled={!hasPrevious || disabled}
        onClick={(): void => gotoPreviousPage()}
        className={`btn-pagination small ${hasPrevious ? '' : 'disabled'}`}
      >
        <i className="icon-chevron-left" />
      </button>
      <button
        disabled={!hasNext || disabled}
        onClick={(): void => gotoNextPage()}
        className={`btn-pagination small  ${hasNext ? '' : 'disabled'}`}
      >
        <i className="icon-chevron-right" />
      </button>
    </div>
  );
};

export default OffsetPagination;
