import { useEffect, FC as ReactFC } from 'react';

import * as d3 from 'd3';
import ReactTooltip from 'react-tooltip';

import { getColorFor } from 'helpers/ColorHelper';
import { getFormattedNumber } from 'helpers/NumberFormat';
import HorizontalBarProps from 'shared/components/ins-charts/horizontal-bar/HorizontalBarProps';
import HorizontalBarValue from 'shared/components/ins-charts/horizontal-bar/HorizontalBarValue';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

const HorizontalBar: ReactFC<HorizontalBarProps> = (props) => {
  const barTopRadius = 3;

  const { values, dimension } = props;
  const { ref, computedDimension } = useDimension(dimension);
  const { width, height } = computedDimension;
  const barWidth = 16;
  const barMarginBottom = 25;

  useEffect(() => {
    const draw = (): void => {
      const labelLeft = width / 2;

      const y = (index: number): number => index * (barWidth + barMarginBottom);

      const x: d3.ScaleLinear<number, number> = d3
        .scaleLinear()
        .range([0, labelLeft]); // Bar height is halfway of chart width

      // Scale the range of the data in the domains
      if (width < 200) {
        x.domain([-2, 100]);
      } else {
        x.domain([-1, 100]);
      }

      const svg = d3
        .select(ref.current)
        .select('svg')
        .html('')
        .append('g')
        .attr('transform', `translate(0,0)`);

      // append the g elements for rectangles of the bar chart
      const bars = svg
        .selectAll<SVGGElement, HorizontalBarValue>('.bar')
        .data(values)
        .enter()
        .append('g');

      // creating a clipping path to get rounded top corners
      bars
        .append('rect')
        .attr('class', 'bar')
        .style('margin-top', '40px')
        .style('fill', (d): string => getColorFor(d.name.toUpperCase()))
        .attr('width', (d) => x(d.percentage))
        .attr('y', (d, i) => y(i))
        .attr('height', barWidth)
        .attr('clip-path', (d) => `url(#round-corner-${d.name})`);

      bars
        .append('text')
        .attr('class', 'horizontal-bar-label')
        // y position of the label is halfway down the bar
        .attr('y', (d, i) => y(i) + barWidth / 2 + 4)
        // x position is 1 pixels to the right of the bar
        .attr('x', labelLeft + 1)
        .html(
          (d) =>
            `<tspan class="value">${getFormattedNumber(
              d.percentage,
              true
            )}%  </tspan><tspan class="label">- ${d.name}</tspan>`
        );

      bars
        .append('defs')
        .append('clipPath')
        .attr('id', (d) => `round-corner-${d.name}`)
        .append('rect')
        .attr('y', (d, i) => y(i))
        .attr('height', barWidth)
        .attr('x', -barTopRadius)
        .attr('width', (d) => x(d.percentage) + barTopRadius)
        .attr('rx', barTopRadius)
        .attr('ry', barTopRadius);
    };

    if (values && ref != null && height > 0) {
      draw();
    }
  }, [height, ref, values, width]);

  return (
    <div ref={ref} className="ins-custom-chart-wrapper horizontal-chart-div">
      <ReactTooltip type="light" id="svgTooltip" html />
      <svg
        width={width}
        height={(barWidth + barMarginBottom) * values.length}
      />
    </div>
  );
};

export default HorizontalBar;
