/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { components, ValueContainerProps } from 'react-select';

import icon from 'shared/static/img/search-icon-light.svg';

import ProjectUserOption from '../ProjectUserOption';

const { ValueContainer } = components;

const SearchBarValueContainer: ReactFC<ValueContainerProps<ProjectUserOption>> =
  (props: ValueContainerProps<ProjectUserOption>) => {
    const { children } = props;
    return (
      <ValueContainer {...props}>
        {!!children && (
          <img
            src={icon}
            style={{ marginRight: 10 }}
            width="15"
            height="15"
            alt="Search bar"
          />
        )}
        {children}
      </ValueContainer>
    );
  };

export default SearchBarValueContainer;
