/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import { Formik, FormikHelpers } from 'formik';
import groupBy from 'lodash/groupBy';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Button, Col, Collapse, Fade, Row } from 'reactstrap';

import ApiError from 'api/common/types/ApiError';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import { getErrorStatus } from 'helpers/ErrorFormat';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import HttpStatus from 'shared/enums/HttpStatus';

import styles from '../permissionsList.module.scss';
import EditPermissionForm from './edit-permission-form/EditPermissionForm';
import EditPermissionFormValidation from './EditPermissionFormValidation';
import EditPermissionFormValues from './EditPermissionFormValues';
import EditPermissionsListItemProps from './EditPermissionsListItemProps';

const EditPermissionsListItem: ReactFC<EditPermissionsListItemProps> = (
  props: EditPermissionsListItemProps
) => {
  const {
    active,
    handleEdit,
    handleCancel,
    permission,
    canEditPermissions,
    defaultClaims,
    organizationId,
    isModalView,
    onEditSuccess,
    removeDummyItem,
  } = props;

  const formikRef = useRef<FormikHelpers<EditPermissionFormValues>>(null);

  const [commonError, setCommonError] = useState<null | string>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, []);

  useEffect(() => {
    if (active !== permission.key && formikRef.current) {
      formikRef.current.resetForm();
      setCommonError(null);
    }
  }, [active, permission.key]);

  const initialFeatures = permission.features;
  const groupedFeatures = groupBy(initialFeatures, 'groupName');

  const enabledFeatures = initialFeatures
    .filter((feature) => feature.enable)
    .map((feature) => feature.name);

  /**
   * Handle permission level edit form submit.
   *
   * @param values form values
   */
  const handleSubmit = async (
    values: EditPermissionFormValues,
    formikHelpers: FormikHelpers<EditPermissionFormValues>
  ): Promise<void> => {
    const featuresArray = defaultClaims.map((claim) => {
      if (values.features.includes(claim.name)) {
        return {
          name: claim.name,
          enable: true,
        };
      }
      return {
        name: claim.name,
        enable: false,
      };
    });
    const permissionObject = {
      permissions: [
        {
          key: values.key,
          title: values.title,
          description: values.description,
          features: featuresArray,
        },
      ],
    };
    try {
      await SettingsApiInstance.UpdatePermissionLevel(
        organizationId,
        permissionObject
      );

      sendEventGA(
        CategoryKeysGA.SettingsPermissions,
        ActionKeysGA.EditPermission
      );

      handleCancel();
      removeDummyItem();
      onEditSuccess(values.key);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          setCommonError(intl.get('ERR_PERMISSION_EDIT_FORM_ERRORS'));
        }
      } else {
        setCommonError(intl.get('ERR_PERMISSION_EDIT_FORM_ERRORS'));
      }
      formikHelpers.setStatus(
        getErrorStatus(error, intl.get('OOPS_SOMETHING_WENT_WRONG'))
      );
    }
  };

  /**
   * Render collapsed permission list item with edit button
   */
  const renderPermissionRow = (): JSX.Element => (
    <Col xs="12" className={`col-12 ${styles.itemGap}`}>
      <div className={styles.item}>
        <Row>
          <Col xs="3" className="d-flex align-items-center">
            <EllipsisTooltip
              tag="p"
              data-place="bottom"
              data-for="insTooltip"
              data-tip={permission.title}
              data-class="overflow-wrap"
              className="truncate text-16-semibold"
            >
              {permission.title}
            </EllipsisTooltip>
          </Col>
          <Col xs="6" className="d-flex align-items-center">
            <EllipsisTooltip
              tag="p"
              clamp
              data-place="bottom"
              data-for="insTooltip"
              data-tip={permission.description}
              data-class="overflow-wrap"
              className="truncate two-lines text-12-medium"
            >
              {permission.description}
            </EllipsisTooltip>
          </Col>
          <Col xs="3" className="d-flex align-items-center justify-content-end">
            <Button size="sm" onClick={(): void => handleEdit(permission.key)}>
              <span className="pr-2">
                {intl.get(
                  permission.isDefault || !canEditPermissions
                    ? 'BTN_VIEW'
                    : 'BTN_EDIT'
                )}
              </span>
              <i className={`icon-chevron-down ${styles.reduceIconSize}`} />
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  );

  const initialValues: EditPermissionFormValues = {
    key: permission.key,
    title: permission.title,
    description: permission.description,
    features: enabledFeatures,
  };

  return (
    <>
      {!expanded && active !== permission.key && renderPermissionRow()}
      <Collapse
        isOpen={active === permission.key}
        className={`w-100 ${styles.collapseGap}`}
        timeout={300}
        onEntering={(): void => setExpanded(true)}
        onEntered={(): void => setExpanded(false)}
        onExiting={(): void => setExpanded(true)}
        onExited={(): void => setExpanded(false)}
      >
        <Fade timeout={320} in={active === permission.key}>
          <Col xs="12">
            <Formik
              // @ts-ignore
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={EditPermissionFormValidation.GetValidationSchema()}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting, dirty, errors }): JSX.Element => (
                <EditPermissionForm
                  values={values}
                  errors={errors}
                  dirty={dirty}
                  commonError={commonError}
                  onError={setCommonError}
                  isSubmitting={isSubmitting}
                  groupedFeatures={groupedFeatures}
                  onCancel={handleCancel}
                  isModalView={isModalView}
                  canEditPermissions={canEditPermissions}
                  permission={permission}
                  initialValues={initialValues}
                />
              )}
            </Formik>
          </Col>
        </Fade>
      </Collapse>
    </>
  );
};

export default EditPermissionsListItem;
