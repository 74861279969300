/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import Select from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';

import PermissionTooltipOption from 'shared/modules/users/components/invite-users-field-array/invite-users-form-item/permission-select/permission-tooltip-option/PermissionTooltipOption';

import PermissionMenuList from './permission-menu-list/PermissionMenuList';
import PermissionMenu from './permission-menu/PermissionMenu';

const PermissionSelect: ReactFC<SelectComponentsProps> = (
  props: SelectComponentsProps
) => (
  <div>
    <Select
      {...props}
      components={{
        Option: PermissionTooltipOption,
        MenuList: PermissionMenuList,
        Menu: PermissionMenu,
      }}
    />
  </div>
);

export default PermissionSelect;
