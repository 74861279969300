/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState /* ReactElement */,
  CSSProperties,
  FC as ReactFC,
} from 'react';

import { AxisProps } from '@nivo/axes';
import { Serie } from '@nivo/line';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { FormGroup, Label } from 'reactstrap';

import Charts from 'constants/Charts';
import { getColorFor } from 'helpers/ColorHelper';
import { getCompactNumber, getFormattedNumber } from 'helpers/NumberFormat';
import FinancialMetricsChart from 'shared/components/financial-metrics-chart/FinancialMetricsChart';
import ChartTypeSelect from 'shared/components/ins-form-fields/chart-type-select/ChartTypeSelect';
import FinancialMetricsChartTypeOption from 'shared/components/ins-form-fields/chart-type-select/FinancialMetricsChartTypeOption';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import FilterType from 'shared/enums/FilterType';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';
import TimeUnit from 'shared/enums/TimeUnit';
import TooltipOptionAnchor from 'shared/enums/TooltipOptionAnchor';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';

import GroupFinancialChartChartTypes from './GroupFinancialChartChartTypes';
import GroupFinancialChartContainerProps from './GroupFinancialChartContainerProps';
import ChartData from './GroupFinancialChartData';

const GroupFinancialChartContainer: ReactFC<GroupFinancialChartContainerProps> =
  (props) => {
    const {
      data,
      filters,
      loading,
      onFilterChange,
      chartType,
      onChartTypeChange,
    } = props;

    const getCurrencyString = useCurrency();

    const defaultChartData: ChartData = Object.keys(
      GroupFinancialChartChartTypes
    ).reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [GroupFinancialChartChartTypes[currentValue]]: {
          data: Array<Serie>(),
          meta: {},
        },
      }),
      {} as ChartData
    );

    /**
     * Returns if selected chart type's left axis is percentage-based
     *
     * @param selectedChartType Selected chart type
     * @returns {boolean} Whether selected chart type's left axis is percentage-based
     */
    const isPercentage = (
      selectedChartType: GroupFinancialChartChartTypes
    ): boolean =>
      [
        GroupFinancialChartChartTypes.LoanFundUtilization,
        GroupFinancialChartChartTypes.LoanRepaymentRate,
        GroupFinancialChartChartTypes.PercentageOfMembersWhoHaveTakenALoan,
        GroupFinancialChartChartTypes.PortfolioAtRisk,
        GroupFinancialChartChartTypes.ReturnOnSavings,
      ].includes(selectedChartType);

    /**
     * Validate if chart type's left axis involves counts
     *
     * @param selectedChartType Chart type
     * @returns {boolean} Whether the chart type's left axis involves counts
     */
    const isCount = (
      selectedChartType: GroupFinancialChartChartTypes
    ): boolean =>
      [GroupFinancialChartChartTypes.AverageNumOfLoansGiven].includes(
        selectedChartType
      );

    const leftAxisConfig: AxisProps = {
      format: (number): string => {
        if (isPercentage(chartType)) {
          return `${getCompactNumber(Number(number), true)}%`;
        }
        if (isCount(chartType)) {
          return getCompactNumber(Number(number), true);
        }
        return getCurrencyString(
          Number(number),
          true,
          IntlNumberNotation.Compact
        );
      },
    };

    /**
     * Formats tooltip label
     *
     * @param value Value to be formatted
     * @returns {string} Formatted axis value
     */
    const tooltipFormatter = (value: number): string => {
      if (isPercentage(chartType)) {
        return `${getFormattedNumber(value, true)}%`;
      }
      if (isCount(chartType)) {
        return getFormattedNumber(value, true);
      }
      return getCurrencyString(value, true);
    };

    const [timeUnits, setTimeUnits] = useState<SelectFieldOption[]>([]);
    const [chartTypes, setChartTypes] = useState<SelectFieldOption[]>([]);
    const [chartData, setChartData] = useState<ChartData>(defaultChartData);

    useEffect(() => {
      const chartTypeOptions = Object.values(GroupFinancialChartChartTypes).map(
        (value) => {
          if (value === GroupFinancialChartChartTypes.ReturnOnSavings) {
            return {
              value,
              label: intl.get(`LBL_GFM_${value}`),
              hint: intl.get(`LBL_GFM_${value}_HINT`),
              isDisabled: true,
            };
          }
          return {
            value,
            label: intl.get(`LBL_GFM_${value}`),
            hint: intl.get(`LBL_GFM_${value}_HINT`),
          };
        }
      );

      const timeUnitOptions = Object.values(TimeUnit).map((value) => ({
        value,
        label: intl.get(`BTN_${value}`),
      }));

      setChartTypes(chartTypeOptions);
      setTimeUnits(timeUnitOptions);
    }, []);

    useEffect(() => {
      const classifiedChartData: ChartData = defaultChartData;
      data.forEach((d) => {
        const dataPoints = d.dataPoints.map((dp) => ({
          x: dp.key,
          y: dp.value || 0,
        }));
        const comparisonPoints = d.comparisonDataPoints.map((cp) => ({
          x: cp.key,
          y: cp.value || 0,
          comparisonKey: cp.comparisonKey,
        }));

        const dataPointsSerie: Serie = {
          id: d.type,
          data: dataPoints,
          color: getColorFor(d.type),
        };

        const comparisonPointsSerie: Serie = {
          id: `${d.type}_COMP`,
          data: comparisonPoints,
          color: getColorFor(`${d.type}_COMP`),
        };

        classifiedChartData[d.type] = {
          data: [comparisonPointsSerie, dataPointsSerie],
          meta: d.meta,
        };
      });
      setChartData(classifiedChartData);
    }, [data]);

    useEffect(() => {
      ReactTooltip.rebuild();
    }, []);

    const chartTypeValue = chartTypes.find(
      (ct) => ct.value === chartType
    ) as SelectFieldOption;

    const chartDataObject = chartData[chartType];

    const bottomAxisConfig: AxisProps = {
      format: '%Y, %b',
    };

    return (
      <div className="contant-container">
        <BlockUi tag="div" blocking={loading} className="row dashboard-row">
          <div className="col">
            <div className="content-box">
              <div className="content-box-title has-dropdowns pb-4 pt-2">
                <h3 className="main-title">
                  {intl.get('LBL_FINANCIAL_METRICS')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_GD_FINANCIAL_METRICS_HELP_TEXT')}
                    />
                  </span>
                </h3>
                <div className="inline-form">
                  <FormGroup className="insight-select-group">
                    <Label htmlFor="chartType" className="chart-filter-label">
                      {intl.get('LBL_CHART_FILTER_CHART_TYPE')}
                    </Label>
                    <ChartTypeSelect
                      name="chartType"
                      value={chartTypeValue}
                      options={chartTypes}
                      onChange={(
                        option: FinancialMetricsChartTypeOption
                      ): void => onChartTypeChange(option.value)}
                      styles={{
                        control: (styles): CSSProperties => ({
                          ...styles,
                          minWidth: '280px',
                        }),
                      }}
                      tooltipAnchor={TooltipOptionAnchor.LeftCenter}
                      classNamePrefix="insight-select-no-border"
                    />
                  </FormGroup>
                  <FormGroup className="insight-select-group">
                    <Label
                      htmlFor="dateComparison"
                      className="chart-filter-label"
                    >
                      {intl.get('LBL_CHART_FILTER_DATE_COMPARISON')}
                    </Label>
                    <Select
                      name="dateComparison"
                      value={filters.timeUnit}
                      options={timeUnits}
                      onChange={(value): void =>
                        onFilterChange(
                          value as SelectFieldOption,
                          FilterType.TimeUnit
                        )
                      }
                      classNamePrefix="insight-select-no-border"
                    />
                  </FormGroup>
                </div>
              </div>
              <FinancialMetricsChart
                loading={loading}
                chartTypeLabel={chartTypeValue?.label ?? ''}
                data={chartDataObject.data}
                meta={chartDataObject.meta}
                xAxisConfig={bottomAxisConfig}
                yAxisConfig={leftAxisConfig}
                tooltipFormatter={tooltipFormatter}
                dimensions={{ aspectRatioHeight: 0.342624647604 }}
                filters={filters}
                upperTickLimit={Charts.SmallChartTicksUpperLimit}
                lowerTickLimit={Charts.SmallChartTicksLowerLimit}
              />
            </div>
          </div>
        </BlockUi>
      </div>
    );
  };

export default GroupFinancialChartContainer;
