import CyclePhase from 'shared/enums/CyclePhase';

class GroupMetricsFilters {
  cyclePhase: CyclePhase;

  constructor() {
    this.cyclePhase = CyclePhase.None;
  }
}

export default GroupMetricsFilters;
