import { useEffect, useMemo, useState, FC as ReactFC } from 'react';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import clone from 'lodash/clone';
import find from 'lodash/find';
import map from 'lodash/map';
import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import ModalTypeKeysGA from 'constants/ga/ModalTypeKeysGA';
import { modalViewGA, pageViewGA } from 'helpers/GoogleAnalyticsHelper';
import WidgetPreferenceModalProps from 'modules/private/dashboard/components/widget-metrics/widget-preference-modal/WidgetPreferenceModalProps';
import WidgetPreferenceViewModel from 'modules/private/dashboard/components/widget-metrics/widget-preference-modal/WidgetPreferenceViewModel';
import WidgetConfigs from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetConfigs';
import WidgetTarget from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTarget';
import WidgetTargetConfigs from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetConfigs';
import WidgetTargetType from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetType';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import WidgetType from 'modules/private/dashboard/components/widget-metrics/widgets/WidgetType';
import CurrencyStorageService from 'services/storage-services/CurrencyStorageService';
import SelectField from 'shared/components/ins-form-fields/select-field/SelectField';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import WidgetTargetClass from 'shared/enums/WidgetTargetClass';

import WidgetPreferenceValidation from './WidgetPreferenceValidation';

const WidgetPreferenceModal: ReactFC<WidgetPreferenceModalProps> = (props) => {
  const { save, toggle, show, data, selectedWidgets } = props;

  const location = useLocation();

  const widgetData = clone(data);

  const selectedCurrency = CurrencyStorageService.GetSelectedCurrency();

  // prettier-ignore
  const [updatedPreferences, setUpdatedPreferences] = useState<WidgetPreferenceViewModel>(new WidgetPreferenceViewModel());

  /**
   * Returns target type options
   *
   * @returns {SelectFieldOption[]} List of target type options
   */
  const getTargetTypes = (): SelectFieldOption[] => {
    const targetTypes = Object.values(WidgetTargetType).map((k) => ({
      value: k,
      label: WidgetTargetConfigs.GetWidgetTargetConfig(k).name,
    }));

    return targetTypes;
  };

  /**
   * Returns widget type options
   *
   * @param widgets List of widgets
   * @param current Current widget type
   * @returns {SelectFieldOption[]} List of widget type options
   */
  // prettier-ignore
  const getWidgetTypes = (
    widgets: WidgetViewModel[],
    current: WidgetType
  ): SelectFieldOption[] => {
    const selectedTypes = map(widgets, 'type');

    const widgetTypes = Object.values(WidgetType)
      .filter((t) => t !== WidgetType.Placeholder)
      .map((k) => ({
        value: k,
        label: WidgetConfigs.GetWidgetConfig(k).name,
        isDisabled: selectedTypes.includes(k) && k !== current,
      }));

    return widgetTypes;
  };

  /**
   * Validates whether to display target types for provided widget type
   *
   * @param t Widget type
   * @returns {boolean} Specifies whether to display target types
   */
  const showTargetTypes = (t: WidgetType): boolean =>
    WidgetConfigs.GetWidgetConfig(t).targetSettings;

  /**
   * Getting available widget types to display.
   */
  const targetTypes = useMemo(() => getTargetTypes(), []);

  /**
   * Getting available widget target types to display.
   */
  const widgetTypes = useMemo(
    () => getWidgetTypes(selectedWidgets, widgetData.type),
    [selectedWidgets, widgetData.type]
  );

  /**
   * Based on the Widget Type Selection, it's required to discard the values
   * provided for the dependent fields.
   *
   * @param setFieldValue The Formik helper function.
   * @param selection The current selection of the Select Field.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTargetTypeChange = (setFieldValue: any, selection: any): void => {
    if (selection.value === WidgetTargetType.None) {
      setFieldValue('target', undefined);
      setFieldValue('targetTo', undefined);
      setFieldValue('targetFrom', undefined);
    } else if (selection.value === WidgetTargetType.Value) {
      setFieldValue('targetTo', undefined);
      setFieldValue('targetFrom', undefined);
    } else if (selection.value === WidgetTargetType.Range) {
      setFieldValue('target', undefined);
    }
  };

  /**
   * Handles saving widget preferences
   *
   * @param values Widget preferences object
   */
  const saveAndClose = (values: WidgetPreferenceViewModel): void => {
    const updatedData = clone(data);
    const { targetTo, targetFrom, target, targetType, type } = values;

    let targetData: WidgetTarget | null = null;

    if (showTargetTypes(type.value)) {
      targetData = {
        targetType: targetType.value,
        target: Number(target) || null,
        targetTo: Number(targetTo) || null,
        targetFrom: Number(targetFrom) || null,
        percentageRange: updatedData.targetData?.percentageRange || false,
      };
    }

    updatedData.targetData = targetData;
    updatedData.type = values.type.value;
    updatedData.pendingChanges = true;

    save(updatedData);
  };

  useEffect(() => {
    if (show) {
      const usedWidget = find(widgetTypes, { value: widgetData.type });

      if (usedWidget) {
        const preference = new WidgetPreferenceViewModel();

        preference.position = widgetData.position;
        preference.type = usedWidget;

        if (widgetData.targetData) {
          const { target, targetTo, targetFrom, targetType } =
            widgetData.targetData;

          const usedTarget = find(targetTypes, { value: targetType });

          preference.target = target || preference.target;
          preference.targetTo = targetTo || preference.targetTo;
          preference.targetType = usedTarget || preference.targetType;
          preference.targetFrom = targetFrom || preference.targetFrom;
        }

        setUpdatedPreferences(preference);
      }
    }
  }, [
    widgetData.targetData,
    widgetData.position,
    widgetData.type,
    widgetTypes,
    targetTypes,
    show,
  ]);

  /**
   * Handles opening the widget preference modal
   */
  const handleOpened = (): void => {
    modalViewGA(
      `${location.pathname + location.search}/${widgetData.type}/${
        ModalTypeKeysGA.WidgetPreferences
      }`
    );
  };

  /**
   * Handles closing the widget preference modal
   */
  const handleClosed = (): void => {
    pageViewGA(location.pathname);
  };

  const isCurrencyWidget =
    WidgetConfigs.GetWidgetConfig(data.type).targetClass ===
    WidgetTargetClass.Currency;

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      onOpened={handleOpened}
      onClosed={handleClosed}
      backdrop="static"
      centered
    >
      <Formik
        initialValues={updatedPreferences}
        validationSchema={WidgetPreferenceValidation.GetValidationScheme()}
        onSubmit={saveAndClose}
        enableReinitialize
      >
        {({ values, isValid }): JSX.Element => (
          <Form noValidate>
            <ModalHeader>{intl.get('LBL_WID_SETUP_TITLE')}</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="type">
                  {intl.get('LBL_WID_SETUP_METRIC_TYPE')}
                </Label>
                <SelectField name="type" options={widgetTypes} />
              </FormGroup>
              {showTargetTypes(values.type.value) && (
                <>
                  <FormGroup>
                    <Label for="targetType">
                      {intl.get('LBL_WID_SETUP_SET_PROJECT_TARGET')}
                    </Label>
                    <SelectField
                      name="targetType"
                      options={targetTypes}
                      onChange={onTargetTypeChange}
                    />
                  </FormGroup>
                  <FormGroup row>
                    {values.targetType.value === WidgetTargetType.Value && (
                      <>
                        <Label for="target" sm={isCurrencyWidget ? 4 : 3}>
                          <span>{intl.get('LBL_WID_SETUP_TARGET_LABEL')}</span>{' '}
                          {isCurrencyWidget && (
                            <span className="transform-none">
                              ({selectedCurrency.symbol})
                            </span>
                          )}
                        </Label>
                        <Col sm={isCurrencyWidget ? 8 : 9}>
                          <Field
                            type="number"
                            name="target"
                            className="form-control"
                          />
                          <ErrorMessage
                            component="span"
                            className="form-error"
                            name="target"
                          />
                        </Col>
                      </>
                    )}
                  </FormGroup>

                  {values.targetType.value === WidgetTargetType.Range && (
                    <>
                      <FormGroup row>
                        <Label for="from" sm={isCurrencyWidget ? 4 : 3}>
                          <span>
                            {intl.get('LBL_WID_SETUP_TARGET_FROM_LABEL')}
                          </span>{' '}
                          {isCurrencyWidget && (
                            <span className="transform-none">
                              ({selectedCurrency.symbol})
                            </span>
                          )}
                        </Label>
                        <Col sm={isCurrencyWidget ? 8 : 9}>
                          <Field
                            type="number"
                            name="targetFrom"
                            className="form-control"
                          />
                          <ErrorMessage
                            component="span"
                            className="form-error"
                            name="targetFrom"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="to" sm={isCurrencyWidget ? 4 : 3}>
                          <span>
                            {intl.get('LBL_WID_SETUP_TARGET_TO_LABEL')}
                          </span>{' '}
                          {isCurrencyWidget && (
                            <span className="transform-none">
                              ({selectedCurrency.symbol})
                            </span>
                          )}
                        </Label>
                        <Col sm={isCurrencyWidget ? 8 : 9}>
                          <Field
                            type="number"
                            name="targetTo"
                            className="form-control"
                          />
                          <ErrorMessage
                            component="span"
                            className="form-error"
                            name="targetTo"
                          />
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter
              style={{ justifyContent: 'space-between', padding: '32px' }}
            >
              <Button onClick={toggle} type="button" color="secondary">
                {intl.get('BTN_CANCEL')}
              </Button>
              <Button type="submit" color="primary" disabled={!isValid}>
                {intl.get('BTN_SAVE_AND_CLOSE')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default WidgetPreferenceModal;
