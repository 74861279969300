import orderBy from 'lodash/orderBy';

import { getColorFor } from 'helpers/ColorHelper';
import GroupWidgetValueGroup from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetValueGroup';
import GroupWidgetViewModel from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetViewModel';
import Donut from 'shared/components/ins-charts/donut/Donut';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';
import InsNumberFormat from 'shared/components/ins-number-format/InsNumberFormat';

const AdditionalFundsWidget: React.FC<{ data: GroupWidgetViewModel }> = (
  props
) => {
  const maxItemCount = 8;
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const insuranceFundData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.InsuranceFund
  );
  const educationFundData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.EducationFund
  );
  const groupBusinessFundData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.GroupBusinessFund
  );
  const celebrationFundData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.CelebrationFund
  );
  const technologyFundData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.TechnologyFund
  );
  const customFund1Data = values?.find(
    (value) => value.group === GroupWidgetValueGroup.CustomFund1
  );
  const customFund2Data = values?.find(
    (value) => value.group === GroupWidgetValueGroup.CustomFund2
  );
  const customFund3Data = values?.find(
    (value) => value.group === GroupWidgetValueGroup.CustomFund3
  );

  const donutValues = Array<InsChartValue>();

  if (
    insuranceFundData &&
    educationFundData &&
    groupBusinessFundData &&
    celebrationFundData &&
    technologyFundData &&
    customFund1Data &&
    customFund2Data &&
    customFund3Data
  ) {
    donutValues.push({
      name: insuranceFundData.title,
      count: insuranceFundData.count,
      percentage: insuranceFundData.percentage,
      group: insuranceFundData.group,
    });
    donutValues.push({
      name: educationFundData.title,
      count: educationFundData.count,
      percentage: educationFundData.percentage,
      group: educationFundData.group,
    });
    donutValues.push({
      name: groupBusinessFundData.title,
      count: groupBusinessFundData.count,
      percentage: groupBusinessFundData.percentage,
      group: groupBusinessFundData.group,
    });
    donutValues.push({
      name: celebrationFundData.title,
      count: celebrationFundData.count,
      percentage: celebrationFundData.percentage,
      group: celebrationFundData.group,
    });
    donutValues.push({
      name: technologyFundData.title,
      count: technologyFundData.count,
      percentage: technologyFundData.percentage,
      group: technologyFundData.group,
    });
    donutValues.push({
      name: customFund1Data.title,
      count: customFund1Data.count,
      percentage: customFund1Data.percentage,
      group: customFund1Data.group,
    });
    donutValues.push({
      name: customFund2Data.title,
      count: customFund2Data.count,
      percentage: customFund2Data.percentage,
      group: customFund2Data.group,
    });
    donutValues.push({
      name: customFund3Data.title,
      count: customFund3Data.count,
      percentage: customFund3Data.percentage,
      group: customFund3Data.group,
    });
  }

  return (
    <div className="gender-widget">
      <div className="chart-container">
        <Donut
          isLoading={false}
          values={donutValues}
          dimension={dimensionSettings}
          showCount
        />
      </div>
      {donutValues.length === maxItemCount && (
        <div className="chart-legend">
          <div className="chart-legend-inner">
            {orderBy(donutValues, 'percentage', 'desc').map((value) => (
              <div key={value.name} className="item">
                <div className="item-col color-container">
                  <span
                    className="color"
                    style={{
                      backgroundColor: getColorFor(value.group),
                    }}
                  />
                </div>
                <div className="label item-col">
                  <span className="value">
                    <InsNumberFormat
                      value={value.percentage}
                      type="percentage"
                      precise={false}
                    />
                  </span>
                </div>
                <div className="label item-col">
                  <span>&nbsp;- </span>
                  <span className="name">{value.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdditionalFundsWidget;
