import * as intl from 'react-intl-universal';

class SupervisorsViewModel {
  name: string;

  id: string;

  permissionLevel?: string;

  image?: string;

  email?: string;

  countries?: Array<string>;

  constructor(name?: string, id?: string) {
    this.name = name ?? intl.get('LBL_NONE') ?? 'None';
    this.id = id ?? 'NONE';
  }
}

export default SupervisorsViewModel;
